import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import { Link } from "../../../components/Tailwind/Link.js";
import { PaginatedDataTable } from "../../../components/Tailwind/PaginatedDataTable.js";
import { withBreadcrumb } from "../../../context/Breadcrumbs/withBreadcrumbs.js";
import { GetEmptyUnitsDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import * as React from "../../../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../../../_snowpack/pkg/react-router.js";
import { IoCheckmarkSharp } from "../../../../../_snowpack/pkg/react-icons/io5.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const Circle = styled.div(() => [{
  "height": "2.4rem",
  "width": "2.4rem",
  "borderRadius": "9999px",
  "--tw-shadow": "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "position": "relative",
  "--tw-text-opacity": "1",
  "color": "rgba(243, 244, 246, var(--tw-text-opacity))",
  "display": "flex",
  "justifyContent": "center",
  "alignItems": "center",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(16, 185, 129, var(--tw-bg-opacity))"
}]);

const renderAddress = (history, item) => {
  return __cssprop(Link, {
    action: {
      kind: "ClickAction",
      event: () => {
        history.push(`/unit/${item.id}`);
      }
    },
    text: `${item.subAddress ? `${item.subAddress}-` : ""}${item?.buildingAddress}`
  });
};

const renderClient = (history, item) => {
  return __cssprop(Link, {
    action: {
      kind: "ClickAction",
      event: () => {
        history.push(`/clients/${item.building.project.id}`);
      }
    },
    text: `${item.building.project.name}`
  });
};

export const EmptyUnitsList = withBreadcrumb(() => {
  const {
    t
  } = useI18n();
  const history = useHistory();

  const renderBody = item => {
    return {
      buildingAddress: renderAddress(history, item),
      project: renderClient(history, item),
      listings: item.listing?.id ? __cssprop(Circle, null, __cssprop(IoCheckmarkSharp, null)) : null
    };
  };

  const columns = [{
    Header: t("building-form-address"),
    accessor: "buildingAddress",
    disableSortBy: true
  }, {
    Header: t("project"),
    accessor: "project",
    disableSortBy: true
  }, {
    Header: t("listings-title"),
    accessor: "listings",
    disableSortBy: true
  }];
  return __cssprop(PaginatedDataTable, {
    title: t("widget-tab-empty-unit-plurial"),
    query: GetEmptyUnitsDocument,
    headers: columns,
    renderItem: renderBody,
    resourceName: {
      plural: t("leases"),
      singular: t("leases")
    }
  });
});
export default EmptyUnitsList;