import { GetBuildingDocument, GetLeaseDocument, GetProvidersDocument, GetSingleProjectDocument, GetTenantDocument } from "../graphql/documents.js";
export const queriesObject = {
  userId: {
    query: GetTenantDocument,
    name: "tenant",
    link: "tenants"
  },
  provider: {
    query: GetProvidersDocument,
    name: "providers",
    link: "providers"
  },
  leaseId: {
    query: GetLeaseDocument,
    name: "lease",
    link: "lease"
  },
  projectId: {
    query: GetSingleProjectDocument,
    name: "project",
    link: "clients"
  },
  spaceId: {
    query: GetBuildingDocument,
    name: "building",
    link: "buildings"
  }
};
export const getResource = (resourceItem, key) => {
  switch (key) {
    case "userId":
      return resourceItem.name;

    case "provider":
      return resourceItem.name;

    case "spaceId":
      return resourceItem.sector;

    case "projectId":
      return resourceItem.name;

    case "leaseId":
      return resourceItem.unit.buildingAddress;

    default:
      break;
  }

  return null;
};
export const typeOfEntities = [{
  label: "Ticket",
  value: "TicketGQL"
}];
export const typeOfTickets = [{
  label: "New ticket",
  value: "new"
}, {
  label: "Existing ticket",
  value: "existing"
}];
export const getCalendarDate = date => {
  return `DTSTART:${date.getFullYear()}${date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}${date.getDate() - 1 >= 10 ? date.getDate() - 1 : `0${date.getDate() - 1}`}T210000Z RRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1`;
};