function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../../_snowpack/pkg/react.js";
import ReactSelect from "../../../../_snowpack/pkg/react-select.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]); // This component is not name Select because i will have confict with our react select library
// witch his default export is named Select


export const Selector = props => {
  const {
    options,
    selectedItem,
    placeholder,
    defaultValue,
    error,
    label,
    disabled = false,
    isMulti = false,
    isClearable = true,
    onChange,
    id,
    isLoading,
    loadingMessage,
    noOptionsMessage
  } = props;
  return __cssprop("div", null, label && __cssprop(StyledItemsLabel, null, label), __cssprop(ReactSelect, _extends({}, props, {
    id: id,
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: error ? "rgb(222, 53, 11)" : "hsl(0,0%,80%)"
      })
    },
    isClearable: isClearable,
    isMulti: isMulti,
    placeholder: placeholder,
    onChange: onChange,
    options: options,
    menuContainerStyle: {
      zIndex: 25
    },
    value: selectedItem,
    defaultValue: defaultValue,
    isDisabled: disabled,
    isLoading: isLoading,
    loadingMessage: loadingMessage,
    noOptionsMessage: noOptionsMessage
  })), error && __cssprop("span", {
    css: {
      "paddingTop": "0.8rem",
      "--tw-text-opacity": "1",
      "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
    }
  }, error?.errorMessage));
};