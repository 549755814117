export const SNOWPACK_PUBLIC_API_URL = "https://api.simpleloyer.ca/graphql";
export const SNOWPACK_PUBLIC_APP_ENVIRONMENT = "production";
export const SNOWPACK_PUBLIC_FIREBASE_CONFIGS = "{\"apiKey\":\"AIzaSyCUknZeYJb7ujV6lHPMdzHwy6oid064HLE\",\"authDomain\":\"simpleloyer-production.firebaseapp.com\",\"databaseURL\":\"https://simpleloyer-production.firebaseio.com\",\"projectId\":\"simpleloyer-production\",\"storageBucket\":\"simpleloyer-production.appspot.com\",\"messagingSenderId\":\"357879191591\",\"appId\":\"1:357879191591:web:a02fb648ba2806077c5602\",\"measurementId\":\"G-ZGCSKXNY9V\"}";
export const SNOWPACK_PUBLIC_IS_PROD = "true";
export const SNOWPACK_PUBLIC_LISTING_API_TOKEN = "8i3THKzp#u1D7FS0hKGadA4n!d!&cZO4J7fL0&pHskI";
export const SNOWPACK_PUBLIC_LISTING_API_URL = "https://listings.simpleloyer.ca/";
export const SNOWPACK_PUBLIC_MAPBOX_TOKEN = "pk.eyJ1IjoiaWthcnVzZGV2IiwiYSI6ImNrZG9yMmdoZzE2bWIydHBlbzRraXk4ODEifQ.Et6dO0TuGefmMEVFiUl2rg";
export const SNOWPACK_PUBLIC_PACKAGE_VERSION = "1.9.41";
export const MODE = "production";
export const NODE_ENV = "production";
export const SSR = false;