/* eslint-disable jsx-a11y/label-has-associated-control */
import { Loader } from "../../components/Loader.js";
import { Button } from "../../components/Tailwind/Button.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../store/artifacts/Building/types.js";
import React, { useEffect } from "../../../../_snowpack/pkg/react.js";
import { DescriptionLayout, Layout } from "../../components/Tailwind/Layout.js";
import { Page } from "../../components/Tailwind/Page.js";
import { DetailsPane } from "./components/DetailsPane.js";
import { AmenitiesSection } from "./components/sections/AmenitiesSection.js";
import { BuildingInfoSection } from "./components/sections/BuildingInfoSection.js";
import { ProjectSelectionSection } from "./components/sections/ProjectSelectionSection.js";
import { TagsSection } from "./components/sections/TagsSection.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const BuildingFormPage = withBreadcrumb(({
  history,
  crumbs,
  setCrumb,
  location
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const isLoading = useSelector(Selectors.status.isLoading("building"));
  const isSubmiting = useSelector(Selectors.status.isSubmiting("building"));
  const id = useSelector(Selectors.building.getId);
  const errors = useSelector(Selectors.building.getErrors);
  useEffect(() => {
    setCrumb({
      title: t("building"),
      path: "/building",
      level: 4
    });
  }, []);

  if (isLoading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  return __cssprop(React.Fragment, null, __cssprop(Page, {
    breadcrumbs: crumbs,
    title: id ? t("building-form-modify-building") : t("building-form-add-building"),
    primaryAction: __cssprop(React.Fragment, null, __cssprop(Button, {
      primary: true,
      loading: isSubmiting,
      disabled: Object.keys(errors).length !== 0,
      onAction: () => dispatch({
        type: BuildingActionsTypes.BUILDING_SUBMIT,
        payload: null
      })
    }, id ? t("building-form-modify-building") : t("building-form-add-building")))
  }, __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop(DescriptionLayout, null, __cssprop(BuildingInfoSection, null), __cssprop(AmenitiesSection, null), __cssprop(TagsSection, null), __cssprop(ProjectSelectionSection, null))), __cssprop(Layout, {
    oneThird: true
  }, __cssprop(DetailsPane, null)))));
});
export default BuildingFormPage;