/* eslint-disable no-underscore-dangle */

/* eslint-disable import/no-cycle */
import { createReducer } from "../../helpers/actions.js";
import { initialState, TenantActionsTypes } from "./types.js";
export const setInitialState = (data, state) => {
  if (data.payload.kind === "WithTenantId") {
    return { ...state,
      id: data.payload.tenantId
    };
  }

  if (data.payload.kind === "WithContext") {
    return { ...state,
      ...data.payload.tenant
    };
  }

  if (data.payload.kind === "NoContext") {
    return { ...initialState
    };
  }

  return state;
};
export const setField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};
export const addTenantNotes = (data, state) => {
  return { ...state,
    notes: [...state.notes, ...data.payload]
  };
};
export const removeTenantNotes = (data, state) => {
  return { ...state,
    notes: state.notes.filter(note => note.id !== data.payload)
  };
};
export default createReducer(initialState, {
  [TenantActionsTypes.TENANT_INITIALIZE]: setInitialState,
  [TenantActionsTypes.TENANT_SET_FIELD]: setField,
  [TenantActionsTypes.TENANT_ADD_NOTES]: addTenantNotes,
  [TenantActionsTypes.TENANT_REMOVE_NOTES]: removeTenantNotes
});