function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { PhoneInput } from "../../components/PhoneInput/index.js";
import { OrganizationSelector } from "../../components/Selectors/OrganizationSelector.js";
import { Selector } from "../../components/Selectors/Selector.js";
import { Button } from "../../components/Tailwind/Button.js";
import { FileDropZone } from "../../components/Tailwind/FileDropZone.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import { getPhoneValidationForForm } from "../../utils/index.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

const getSelectedOptions = (options, selectedOne) => {
  return options.find(o => o.value === selectedOne);
};

export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);

const options = t => [{
  label: t("management"),
  value: "FullyManaged"
}, {
  label: t("rental"),
  value: "RentalOnly"
}];

export const ProjectSideBar = props => {
  const {
    description,
    showOrgPicker
  } = props;
  const {
    t
  } = useI18n();
  const isLoading = useSelector(Selectors.status.isSubmiting("project"));
  const id = useSelector(Selectors.project.getId);
  const email = useSelector(Selectors.project.getEmail);
  const name = useSelector(Selectors.project.getName);
  const legalName = useSelector(Selectors.project.getLegalName);
  const contactName = useSelector(Selectors.project.getContactName);
  const managementType = useSelector(Selectors.project.getManagementType);
  const phone = useSelector(Selectors.project.getPrimaryPhone);
  const [files, setFiles] = useState(useSelector(Selectors.project.getFiles));
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();

  const onFileDrop = pFiles => {
    setFiles([...files, ...pFiles]);
  };

  const onFileDelete = fileToDelete => {
    setFiles(prev => {
      return prev.filter(file => file.fileName !== fileToDelete.fileName);
    });
  };

  const onSubmit = data => {
    props.onDoneSubmit({
      project: {
        id,
        name: data.name,
        legalName: data.legalName,
        contactName: data.contactName,
        phoneInfo: {
          primaryPhone: data.phone
        },
        managementType: data.managementType?.value || managementType,
        email: data.email,
        files,
        notes: data.notes
      },
      parentOrgId: data.parentOrgId?.value
    });
  };

  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, id ? t("project-form-modify") : t("project-form-add")), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "maxWidth": "67.2rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, description)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "name",
    control: control,
    defaultValue: name,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "name", t("project-form-error-name")),
      id: "name"
    }, field, {
      label: t("project-form-name"),
      placeholder: t("project-form-placeholder-my-company")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "legalName",
    control: control,
    defaultValue: legalName,
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "legalName", ""),
      id: "legalName"
    }, field, {
      label: t("project-form-legal-name"),
      placeholder: "John"
    }))
  })), showOrgPicker && __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("project-parent-company")), __cssprop(Controller, {
    name: "parentOrgId",
    rules: {
      required: true
    },
    control: control,
    render: ({
      field
    }) => __cssprop(OrganizationSelector, _extends({}, field, {
      inform: true
    }))
  }), showError(errors, "parentOrgId", t("project-form-error-management-type")) && __cssprop("div", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(220, 38, 38, var(--tw-text-opacity))"
    }
  }, t("choose-option")))), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("project-form-management-type")), __cssprop(Controller, {
    name: "managementType",
    control: control,
    rules: {
      validate: {
        isValid: v => {
          if (v?.value === "FullyManaged" || v?.value === "RentalOnly" || managementType) return true;
          return false;
        }
      }
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      id: "managementType",
      defaultValue: getSelectedOptions(options(t), managementType),
      options: options(t),
      isClearable: false,
      error: showError(errors, "managementType", t("project-form-error-management-type"))
    }))
  }))), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "contactName",
    control: control,
    defaultValue: contactName,
    rules: {
      required: false
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "contactName", t("project-form-error-contact-name")),
      id: "contactName"
    }, field, {
      label: t("project-form-contact-name"),
      placeholder: "John Doe"
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "phone",
    control: control,
    defaultValue: phone,
    rules: getPhoneValidationForForm(),
    render: ({
      field
    }) => __cssprop(PhoneInput, _extends({}, field, {
      label: t("project-form-primary-phone"),
      errorLabel: showError(errors, "phone", t("user-info-error-phone-required"))?.errorMessage,
      zIndex: 30
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "email",
    control: control,
    defaultValue: email,
    rules: {
      required: false
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "email", t("user-info-error-email-invalid")),
      id: "email"
    }, field, {
      label: t("project-form-email"),
      placeholder: "John@gmail.com"
    }))
  })), __cssprop(StyledItems, null, __cssprop(FileDropZone, {
    files: files,
    inForm: true,
    onFilesDrop: onFileDrop,
    onFilesDelete: onFileDelete
  }))), __cssprop(Button, {
    submit: true,
    loading: isLoading,
    disabled: Object.keys(errors).length !== 0,
    primary: true
  }, id ? t("save") : t("project-form-add"))));
};
export default ProjectSideBar;