import { DatePicker } from "../../../../components/DatePicker.js";
import { ProjectSelector } from "../../../../components/Selectors/ProjectSelector.js";
import { Card } from "../../../../components/Tailwind/Card.js";
import { DescriptionLayoutItem } from "../../../../components/Tailwind/Layout.js";
import { showError } from "../../../../helpers/functions.js";
import useI18n from "../../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../../store/artifacts/Building/types.js";
import React from "../../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";
export const ProjectSelectionSection = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const managementStartDate = useSelector(Selectors.building.getManagementStartDate);
  const project = useSelector(Selectors.building.getProject);
  const errors = useSelector(Selectors.building.getErrors);
  return __cssprop(DescriptionLayoutItem, {
    title: t("company-form-title"),
    description: t("company-form-description")
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(ProjectSelector, {
    isMulti: false,
    isForm: true,
    onChange: projectData => {
      dispatch({
        type: BuildingActionsTypes.BUILDING_SET_PROJECT_FIELD,
        payload: {
          id: projectData.value,
          name: projectData.label
        }
      });
    },
    selectedProject: project ? {
      label: project.name,
      value: project.id
    } : null,
    defaultValue: project ? {
      label: project.name,
      value: project.id
    } : null,
    error: showError(errors, "project", t) //   disabled={isDisabled}

  })), __cssprop(Card.Section, null, __cssprop(DatePicker, {
    id: "managementStartDate",
    defaultValue: managementStartDate,
    onChange: date => dispatch({
      type: BuildingActionsTypes.BUILDING_SET_FIELD,
      payload: {
        managementStartDate: date
      }
    }),
    title: t("company-form-start-date"),
    error: showError(errors, "managementStartDate", t("contract-form-error-date"))
  }))));
};