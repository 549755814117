import React from "../../../../_snowpack/pkg/react.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import { Link } from "../../components/Tailwind/Link.js";
import { Page } from "../../components/Tailwind/Page.js";
import { EventActionTypes } from "../../store/artifacts/Events/types.js";
import { PaginatedDataTable } from "../../components/Tailwind/PaginatedDataTable.js";
import { GetEventsDocument } from "../../graphql/documents.js";
import { Badge } from "../../components/Tailwind/Badge.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { useAction } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { AddEventPanel } from "../../components/Panels/AddEventPanel.js";
import useI18n from "../../hooks/useI18n.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const intervalBadge = (interval, t) => {
  switch (interval) {
    case "HOURLY":
      return __cssprop(Badge, {
        primary: true
      }, t("event-interval-badge-hourly"));

    case "DAILY":
      return __cssprop(Badge, {
        critical: true
      }, t("event-interval-badge-daily"));

    case "WEEKLY":
      return __cssprop(Badge, {
        warning: true
      }, t("event-interval-badge-weekly"));

    case "MONTHLY":
      return __cssprop(Badge, {
        success: true
      }, t("event-interval-badge-monthly"));

    case "YEARLY":
      return __cssprop(Badge, {
        blue: true
      }, t("event-interval-badge-yearly"));

    default:
      return null;
  }
};

export const Events = () => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  return __cssprop(React.Fragment, null, __cssprop(AddEventPanel, null), __cssprop(Page, {
    title: t("events-page-title"),
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Event",
        permission: "mutate:create"
      },
      primary: true,
      onAction: () => {
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: "event-create"
        });
      }
    }, t("events-page-create-button"))
  }, __cssprop(EventsView, null)));
};

const EventsView = () => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();

  const renderBody = item => {
    const {
      id,
      title,
      parsedRRule,
      RRule,
      description,
      entity,
      createdAt
    } = item;

    const handleOnEventTitleClick = () => {
      dispatch({
        type: EventActionTypes.EVENT_SET_FIELD,
        payload: { ...item
        }
      });
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: "event-create"
      }); // history.push(`/event/${id}`);
    };

    const intervalEndPeriod = parsedRRule?.count ? `${t("events-list-end-period-first-part")}${parsedRRule?.count}${t("events-list-end-period-second-part")}` : t("events-list-end-period-never");
    return {
      title: __cssprop(Link, {
        action: {
          kind: "ClickAction",
          event: handleOnEventTitleClick
        },
        text: title
      }),
      frequency: intervalBadge(parsedRRule?.freq, t),
      interval: parsedRRule?.interval,
      startAt: DateTime.fromISO(`${parsedRRule?.dtstart}`).toFormat("dd LLL yyyy hh:mm"),
      endsAt: parsedRRule?.until ? DateTime.fromISO(`${parsedRRule?.until}`).toFormat("dd LLL yyyy hh:mm") : intervalEndPeriod,
      runLimit: `${parsedRRule?.count || t("events-list-no-limit")}`
    };
  };

  const columns = [{
    Header: t("events-list-column-title"),
    accessor: "title",
    disableSortBy: true
  }, {
    Header: t("events-list-column-interval"),
    accessor: "interval",
    disableSortBy: true
  }, {
    Header: t("events-list-column-frequency"),
    accessor: "frequency",
    disableSortBy: true
  }, {
    Header: t("events-list-column-start"),
    accessor: "startAt",
    disableSortBy: true
  }, {
    Header: t("events-list-column-end"),
    accessor: "endsAt",
    disableSortBy: true
  }];
  return __cssprop(PaginatedDataTable, {
    title: "Events",
    query: GetEventsDocument,
    headers: columns,
    renderItem: renderBody,
    resourceName: {
      plural: t("events-list-plural"),
      singular: t("events-list-singular")
    }
  });
};

export default Events;