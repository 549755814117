import React, { useState, useMemo } from "../../../_snowpack/pkg/react.js";
import useModal from "../hooks/useModal.js";
import useI18n from "../hooks/useI18n.js";
import { Document, Page, pdfjs } from "../../../_snowpack/pkg/react-pdf.js";
import { BiMinusCircle, BiPlusCircle } from "../../../_snowpack/pkg/react-icons/bi.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileModalViewer = ({
  file,
  Modal
}) => {
  const {
    t
  } = useI18n();
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1.25);
  const currentPage = useMemo(() => {
    if (totalPage >= pageNumber + 2) {
      return pageNumber + 2;
    }

    if (totalPage >= pageNumber + 1) {
      return pageNumber + 1;
    }

    return pageNumber || (totalPage ? 1 : "--");
  }, [totalPage, pageNumber]);

  function onDocumentLoadSuccess({
    numPages
  }) {
    setTotalPage(numPages);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-3);
  }

  function nextPage() {
    changePage(3);
  }

  function onZoomIn() {
    setZoomLevel(prevZoomLevel => prevZoomLevel + 0.5);
  }

  function onZoomOut() {
    setZoomLevel(prevZoomLevel => prevZoomLevel - 0.5);
  }

  return __cssprop(Modal, {
    title: file.fileName,
    primaryAction: {
      content: t("next"),
      onAction: nextPage,
      disabled: pageNumber >= totalPage || totalPage <= 3
    },
    secondaryActions: [// showing page number in the footer of the modal using a button
    {
      content: t("list-pagination", {
        currentPage,
        totalPage
      }),
      onAction: null
    }, {
      content: t("zoomOut"),
      onAction: onZoomOut,
      icon: BiMinusCircle
    }, {
      content: t("zoomIn"),
      onAction: onZoomIn,
      icon: BiPlusCircle
    }, {
      content: t("previous"),
      onAction: previousPage,
      disabled: pageNumber <= 1
    }]
  }, __cssprop("div", {
    tw: "flex content-around "
  }, __cssprop(Document, {
    file: file.url,
    onLoadSuccess: onDocumentLoadSuccess
  }, __cssprop(Page, {
    pageNumber: pageNumber,
    scale: zoomLevel
  }), totalPage >= pageNumber + 1 ? __cssprop(Page, {
    pageNumber: pageNumber + 1,
    scale: zoomLevel
  }) : null, totalPage >= pageNumber + 2 ? __cssprop(Page, {
    pageNumber: pageNumber + 2,
    scale: zoomLevel
  }) : null)));
};

const useFileModalViewer = () => {
  const [Modal, openModal, closeModal] = useModal();
  const [file, setFile] = useState({
    url: ""
  });
  return [() => {
    return __cssprop(FileModalViewer, {
      Modal: Modal,
      file: file
    });
  }, f => {
    setFile(f);
    openModal();
  }];
};

export default useFileModalViewer;