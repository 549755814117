import { useQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { Attachments } from "../../Tailwind/Attachments.js";
import { Card } from "../../Tailwind/Card.js";
import { DescriptionList } from "../../Tailwind/DescriptionList.js";
import { FileDropZone } from "../../Tailwind/FileDropZone.js";
import { Notes } from "../../Tailwind/Notes.js";
import { GetRelatedTicketsDocument, SortDirection, TicketSortingOptions } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { useAction } from "../../../hooks/useRedux.js";
import { Selectors } from "../../../store/index.js";
import { TicketActionTypes } from "../../../store/artifacts/Tickets/types.js";
import { TagsSelection } from "../../../views/Tickets/components/TagsSelection.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useEffect, useState } from "../../../../../_snowpack/pkg/react.js";
import { useSelector } from "../../../../../_snowpack/pkg/react-redux.js";
import { useParams } from "../../../../../_snowpack/pkg/react-router.js";
import TicketInfoRelatedTickets from "./TicketInfoRelatedTickets.js";
import TicketInfoSidebarSelector from "./TicketInfoSidebarSelector.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const TicketInfoSidebar = ({
  data
}) => {
  const {
    t
  } = useI18n();
  const {
    id
  } = useParams();
  const [mappedFiles, setMappedFiles] = useState([]);
  const {
    dispatch
  } = useAction();
  const [relatedResource, setRelatedResource] = useState([]);
  const files = useSelector(Selectors.ticket.getFiles);
  const notes = useSelector(Selectors.ticket.getNotes);
  const noteLoading = useSelector(Selectors.status.isSubmiting("note"));
  const isClosed = Boolean(useSelector(Selectors.ticket.getClosedAt));
  const {
    space,
    tenant,
    project
  } = useSelector(Selectors.ticket.getResource);
  const {
    refetch
  } = useQuery(GetRelatedTicketsDocument, {
    variables: {
      resource: {
        projectIds: project ? [project.id] : [],
        spaceIds: space ? [space.id] : [],
        tenantIds: tenant ? [tenant.id] : []
      },
      take: 5,
      sortBy: {
        direction: SortDirection.Asc,
        option: TicketSortingOptions.CreatedAt
      }
    },
    onCompleted: responseData => {
      setRelatedResource(responseData.tickets.items.filter(x => x.id !== id));
    }
  });
  useEffect(() => {
    setMappedFiles(files.map(attachment => ({
      fileName: attachment.fileName,
      url: attachment.kind === "local" ? attachment.preview : attachment?.url,
      fileType: attachment.fileType
    })));
  }, [files]);
  useEffect(() => {
    setRelatedResource([]);
    refetch();
  }, [space, tenant, project]);

  const handleAddNote = pNotes => {
    dispatch({
      payload: {
        resourceId: id,
        resourceType: "ticket",
        actionType: TicketActionTypes.TICKET_ADD_NOTES,
        content: pNotes.note,
        fromPage: true
      },
      type: TicketActionTypes.TICKET_ADD_NOTES_FORMLESS
    });
  };

  const handleDeleteNote = pNotes => {
    dispatch({
      payload: {
        noteId: pNotes.id,
        actionType: TicketActionTypes.TICKET_REMOVE_NOTES,
        fromPage: true
      },
      type: TicketActionTypes.TICKET_REMOVE_NOTES_FORMLESS
    });
  };

  const renderNotes = React.useMemo(() => {
    return [...new Set(notes)];
  }, [notes]);
  return __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "2rem"
    }
  }, data.isRecurring && __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(DescriptionList, null, __cssprop(DescriptionList.Item, {
    title: t("ticket-recurring-event")
  }, t("recurring-on", {
    date: DateTime.now().toFormat("MMMM dd, yyyy")
  }))))), __cssprop(Card, {
    title: "Resource"
  }, __cssprop(Card.Section, {
    greyed: true
  }, __cssprop(TicketInfoSidebarSelector, null))), relatedResource?.length > 0 && __cssprop(Card, {
    title: "Related tickets"
  }, __cssprop(Card.Section, {
    greyed: true
  }, __cssprop(TicketInfoRelatedTickets, {
    data: relatedResource
  }))), __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(TagsSelection, null))), id && __cssprop(React.Fragment, null, (!isClosed || mappedFiles.length > 0) && __cssprop("div", null, __cssprop(Card, null, __cssprop(Card.Section, null, isClosed ? __cssprop(Attachments, {
    visualOnly: true,
    files: mappedFiles
  }) : __cssprop(FileDropZone, {
    files: files,
    onDropDispatch: TicketActionTypes.TICKET_ADD_FILES_FORMLESS,
    onDeleteDispatch: TicketActionTypes.TICKET_REMOVE_FILES_FORMLESS
  })))), (!isClosed || renderNotes.length > 0) && __cssprop(Notes, {
    notes: renderNotes,
    permission: {
      kind: "Building",
      permission: "view:notes"
    },
    loading: noteLoading,
    addNoteEvent: handleAddNote,
    removeNoteEvent: handleDeleteNote,
    hideNewNote: isClosed,
    isViewOnly: isClosed
  })));
};

export default TicketInfoSidebar;