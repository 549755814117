import { useApolloClient } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { EmptyState } from "../../../components/Tailwind/EmptyState.js";
import { ProtectedActions } from "../../../components/Tailwind/ProtectedComponents.js";
import useDeleteModal, { RESOURCE } from "../../../forms/useDeleteModal.js";
import { FilterKind, GetProjectUsersDocument, UserActingPositions } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../../store/artifacts/Application/types.js";
import { ProjectActionsTypes } from "../../../store/artifacts/Project/types.js";
import { UserActionsTypes } from "../../../store/artifacts/User/types.js";
import React, { useCallback } from "../../../../../_snowpack/pkg/react.js";
import { HiUserCircle } from "../../../../../_snowpack/pkg/react-icons/hi.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const UserRender = ({
  user,
  t,
  openDeleteModal,
  deleteModalAction
}) => {
  const role = useSelector(Selectors.session.getUserRole);
  const {
    dispatch
  } = useAction();
  const actions = ProtectedActions(role, [{
    onAction: () => {
      dispatch({
        type: UserActionsTypes.USER_INITIALIZE,
        payload: {
          kind: "WithContext",
          user
        }
      });
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: "user"
      });
    },
    content: t("view"),
    permission: {
      kind: "User",
      permission: "view:info"
    }
  }, { ...deleteModalAction,
    permission: {
      kind: "User",
      permission: "mutate:delete"
    },
    onAction: () => {
      openDeleteModal(user.id);
    }
  }]);
  return __cssprop(React.Fragment, null, __cssprop("li", {
    key: user.email,
    css: {
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1.6rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop("div", {
    css: {
      "flexShrink": "0"
    }
  }, user.profileImage ? __cssprop("img", {
    css: {
      "height": "3.2rem",
      "width": "3.2rem",
      "borderRadius": "9999px"
    },
    id: "base64image",
    src: user.profileImage,
    alt: ""
  }) : __cssprop(HiUserCircle, {
    css: {
      "height": "3.2rem",
      "width": "3.2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "borderRadius": "9999px"
    },
    "aria-hidden": "true"
  })), __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "minWidth": "0px"
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, user.name), __cssprop("p", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, user.email)))));
};

const Users = ({
  data,
  deleteModalAction,
  openDeleteModal
}) => {
  const {
    t
  } = useI18n();

  if (data === null || data.length === 0 || !data) {
    return __cssprop(EmptyState, {
      description: t("project-info-responsible-selector-empty-state-description"),
      heading: t("project-info-responsible-selector-empty-state-title"),
      image: "/img/notFound.svg"
    });
  }

  return __cssprop("ul", {
    css: {
      "marginTop": "-2rem",
      "marginBottom": "-2rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, data?.map((user, i) => __cssprop(UserRender, {
    user: user,
    key: user.id,
    openDeleteModal: openDeleteModal,
    deleteModalAction: deleteModalAction,
    t: t
  })));
};

export const ClientWidget = () => {
  const {
    dispatch
  } = useAction();
  const users = useSelector(Selectors.project.getUsers);
  const projectId = useSelector(Selectors.project.getId);
  const client = useApolloClient();
  const [DeleteModal, openDeleteModal, deleteModalAction] = useDeleteModal(projectId, RESOURCE.ProjectUser);
  const updateSelection = useCallback(async selected => {
    dispatch({
      type: ProjectActionsTypes.PROJECT_ADD_EXISTING_USER,
      payload: {
        email: selected.email,
        id: selected.value,
        name: selected.name
      }
    });
  }, []);
  const inviteNewUser = useCallback(async email => {
    dispatch({
      type: ProjectActionsTypes.PROJECT_INVITE_NEW_USER,
      payload: email
    });
  }, []);
  const promiseOptions = useCallback(async inputValue => {
    try {
      const dat = await client.query({
        query: GetProjectUsersDocument,
        variables: {
          take: 1000,
          query: inputValue,
          skip: 0,
          rolesFilter: {
            roles: [UserActingPositions.Landlord],
            kind: FilterKind.Exclusive
          }
        },
        fetchPolicy: "no-cache"
      });
      return dat.data?.users?.items?.map(user => ({
        value: user.id,
        label: `${user?.name} (${user?.email})`,
        email: user?.email,
        name: user?.name
      }));
    } catch (e) {
      throw new Error(e);
    }
  }, [client]);
  return __cssprop(React.Fragment, null, __cssprop(DeleteModal, null), __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, "Users"), __cssprop("div", {
    css: {
      "marginTop": "0.8rem",
      "maxWidth": "57.6rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, __cssprop("p", null, "Control which external users can view and edit this project")), __cssprop("div", {
    css: {
      "display": "flex",
      "marginTop": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "marginRight": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "flow-root",
      "marginTop": "2.4rem"
    }
  }, __cssprop(Users, {
    data: users || [],
    deleteModalAction: deleteModalAction,
    openDeleteModal: openDeleteModal
  })))))));
};