import React, { useState } from "../../../../../_snowpack/pkg/react.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { Selectors, useSelector } from "../../../hooks/useRedux.js";
import useI18n from "../../../hooks/useI18n.js";
import { FullAmenitiesPicker } from "../../../components/Tailwind/Amenities/FullAmenitiesPicker.js";
import { AmenitiesTagCloud } from "../../../components/Tailwind/Amenities/AmenitiesTagCloud.js";
import { LeaseActionsTypes } from "../../../store/artifacts/Lease/types.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const AmenitiesView = () => {
  const {
    t
  } = useI18n();
  const [errors, setError] = useState({});
  const amenities = useSelector(Selectors.lease.getAmenities);
  const isLocked = useSelector(Selectors.lease.isLocked);

  const clearErrors = () => {
    setError({});
  };

  const handleErrors = (item, errorMessage) => {
    setError(pValue => {
      return { ...pValue,
        [item]: errorMessage
      };
    });
  };

  if (isLocked && amenities.length === 0) {
    return __cssprop(Card, null, __cssprop(Card.Section, null, t("amenities-empty-state")));
  }

  if (isLocked) {
    return __cssprop(AmenitiesTagCloud, {
      title: t("amenities-title"),
      list: amenities
    });
  }

  return __cssprop(React.Fragment, null, __cssprop(Card, {
    title: t("lease-page-amenities-select-title")
  }, __cssprop(Card.Section, null, __cssprop(FullAmenitiesPicker, {
    amenities: amenities || [],
    resourceType: "Lease",
    setHookError: handleErrors,
    error: errors,
    clearErrors: clearErrors,
    dispatchType: LeaseActionsTypes.LEASE_SET_FIELD
  }), __cssprop("p", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      "fontStyle": "italic",
      "marginTop": "0.8rem"
    }
  }, t("lease-page-amenities-select-description")))));
};