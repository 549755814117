import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const initialState = {
  amount: null,
  project: null,
  creationDate: DateTime.utc().toJSDate(),
  depositBy: null,
  depositFor: null,
  depositDate: DateTime.utc().toJSDate(),
  files: [],
  notes: [],
  serverDeposit: {
    kind: "NotAsked"
  },
  id: null
};
// =========== ACTIONS CREATORS =========== //
export let DepositActionsTypes;

(function (DepositActionsTypes) {
  DepositActionsTypes["DEPOSIT_INITIALIZE"] = "DEPOSIT_INITIALIZE";
  DepositActionsTypes["DEPOSIT_SUBMIT"] = "DEPOSIT_SUBMIT";
  DepositActionsTypes["DEPOSIT_SET_FIELD"] = "DEPOSIT_SET_FIELD";
  DepositActionsTypes["DEPOSIT_SET_SERVER_DEPOSIT_STATUS"] = "DEPOSIT_SET_SERVER_DEPOSIT_STATUS";
  DepositActionsTypes["DEPOSIT_ADD_NOTES"] = "DEPOSIT_ADD_NOTES";
  DepositActionsTypes["DEPOSIT_REMOVE_NOTES"] = "DEPOSIT_REMOVE_NOTES";
  DepositActionsTypes["DEPOSIT_DELETE"] = "DEPOSIT_DELETE";
})(DepositActionsTypes || (DepositActionsTypes = {}));