/* eslint-disable react/require-default-props */
import { UserSelector } from "../../components/Selectors/UserSelector.js";
import { UserActingPositions } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const LeaseAgentSelector = props => {
  const {
    t
  } = useI18n();
  const {
    leaseAgent,
    onSelectCallback,
    title,
    error
  } = props;
  const [agent, setAgent] = useState(leaseAgent?.id ? {
    value: leaseAgent.id,
    label: leaseAgent.name
  } : undefined);

  const handleOnChange = v => {
    onSelectCallback({
      id: v.value,
      name: v.label
    });
    return setAgent(v);
  };

  const RenderUserSelector = () => {
    return __cssprop(UserSelector, {
      selected: agent,
      onChange: handleOnChange,
      placeholder: t("applicant-request-form-no-visit-agent"),
      roles: [UserActingPositions.PropertyManager, UserActingPositions.VisitAgent],
      error: error
    });
  };

  return __cssprop("div", {
    tw: "flex items-center space-x-2"
  }, __cssprop("div", {
    tw: "flex-1 leading-6 font-medium text-gray-900 "
  }, title), __cssprop(RenderUserSelector, null));
};