import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { SimpleTagEntry } from "../../forms/sections/SimpleTagEntry.js";
import { SimpleTenantSelector } from "../../forms/sections/SimpleTenantSelector.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { convertToRaw, EditorState } from "../../../../_snowpack/pkg/draft-js.js";
import { draftToMarkdown } from "../../../../_snowpack/pkg/markdown-draft-js.js";
import React, { useCallback, useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import DraftEditor from "../../../../_snowpack/pkg/react-draft-wysiwyg.js";
import "../../../../_snowpack/pkg/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { HiOfficeBuilding, HiUserGroup, HiUsers } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { EmailTemplateSelector } from "../Selectors/EmailTemplateSelector.js";
import { ProjectSelector } from "../Selectors/ProjectSelector.js";
import { SimpleBuildingSelector, SimpleUnitSelector } from "../SimpleBuildingSelector.js";
import { Button } from "../Tailwind/Button.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { TextField } from "../Tailwind/TextField.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const Category = _styled.div(({
  selected
}) => [{
  "position": "relative",
  "height": "9.6rem",
  "width": "9.6rem",
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "center",
  "alignItems": "center",
  "cursor": "pointer",
  ":hover": {
    "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))"
  },
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
}, selected && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))",
  "fontWeight": "700",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
}]);

const SELECTORS = t => ({
  Project: t("notification-panel-select-clients"),
  Tag: t("notification-panel-select-tags"),
  Building: t("notification-panel-select-buildings"),
  Tenant: t("notification-panel-select-tenants")
});

const LANGUAGE_SELECT_OPTION = t => [{
  label: t("fr"),
  value: "fr"
}, {
  label: t("en"),
  value: "en"
}];

export const NotificationPanel = () => {
  const activeDrawer = useSelector(Selectors.app.getDrawerState);
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const [category, setCategory] = useState("Project");
  const [options, setOptions] = useState();
  const [units, setUnits] = useState();
  const [subject, setSubject] = useState();
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [errors, setErrors] = useState({
    options: false,
    subject: {
      errorMessage: null
    },
    body: false
  }); // const [msgLang, setMsgLang] = useState<string>(userLang);

  const onSelectCategory = useCallback(cat => () => setCategory(cat), [setCategory]);
  const onContinue = useCallback(() => {
    const raw = convertToRaw(editorState.getCurrentContent());
    const md = draftToMarkdown(raw, {
      preserveNewLines: true
    });
    const isUnits = category === "Building" && selectedUnits?.length > 0;
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_ON_MASS_MESSAGE,
      payload: {
        category: isUnits ? "Unit" : category,
        values: isUnits ? selectedUnits : options,
        type: "markdown",
        content: md,
        subject // language: msgLang,

      }
    });
  }, [options, selectedUnits, editorState]);
  const onSetValues = useCallback(values => {
    if (values === null) {
      setOptions([]);
      return;
    }

    setOptions((Array.isArray(values) ? [...values] : [values]).map(v => v?.value));
  }, [setOptions]);
  useEffect(() => {
    if (options?.length === 0) {
      setErrors(prev => {
        return { ...prev,
          options: true
        };
      });
    } else {
      setErrors(prev => {
        return { ...prev,
          options: false
        };
      });
    }

    if (!subject || subject?.length === 0) {
      setErrors(prev => {
        return { ...prev,
          subject: {
            errorMessage: t("communication-subject-required")
          }
        };
      });
    } else {
      setErrors(prev => {
        return { ...prev,
          subject: null
        };
      });
    }

    if (draftToMarkdown(convertToRaw(editorState.getCurrentContent()), {
      preserveNewLines: true
    })?.length === 0) {
      setErrors(prev => {
        return { ...prev,
          body: true
        };
      });
    } else {
      setErrors(prev => {
        return { ...prev,
          body: false
        };
      });
    }
  }, [selectedUnits, options, subject, editorState]);
  return __cssprop(SlideOver, {
    title: t("notification-panel-title"),
    isOpen: activeDrawer === "notifications",
    onClose: () => dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    }),
    size: "lg"
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "justifyContent": "space-between",
      "height": "100%"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginBottom": "0.8rem"
    }
  }, t("notification-panel-subtitle")), __cssprop("div", {
    css: {
      "display": "flex",
      "justifyContent": "center",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(Category, {
    onClick: onSelectCategory("Project"),
    selected: category === "Project"
  }, __cssprop(HiUserGroup, {
    css: {
      "height": "4.8rem",
      "width": "4.8rem"
    }
  }), t("notification-panel-cat-clients")), __cssprop(Category, {
    onClick: onSelectCategory("Building"),
    selected: category === "Building"
  }, __cssprop(HiOfficeBuilding, {
    css: {
      "height": "4.8rem",
      "width": "4.8rem"
    }
  }), t("notification-panel-cat-buildings")), __cssprop(Category, {
    onClick: onSelectCategory("Tenant"),
    selected: category === "Tenant"
  }, __cssprop(HiUsers, {
    css: {
      "height": "4.8rem",
      "width": "4.8rem"
    }
  }), t("notification-panel-cat-tenants"))), __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginTop": "0.8rem",
      "marginBottom": "0.8rem"
    }
  }, SELECTORS(t)[category]), category === "Project" && __cssprop(ProjectSelector, {
    error: errors.options,
    onChange: onSetValues,
    placeholder: t("notification-panel-placeholder-clients")
  }), category === "Tag" && __cssprop(SimpleTagEntry, {
    onChange: onSetValues,
    placeholder: t("notification-panel-placeholder-tags")
  }), category === "Building" && __cssprop(React.Fragment, null, __cssprop(SimpleBuildingSelector, {
    errors: errors.options,
    onChange: opt => {
      if (opt === null) {
        setOptions([]);
        setUnits(null);
        setSelectedUnits([]);
        return;
      }

      setOptions([opt.id]);
      setUnits(opt.units);
      setSelectedUnits([]);
    }
  }), __cssprop("div", {
    css: {
      "paddingTop": "0.8rem"
    }
  }, __cssprop(SimpleUnitSelector, {
    errors: errors.options,
    units: units,
    onSelect: setSelectedUnits,
    isMulti: true
  }))), category === "Tenant" && __cssprop(SimpleTenantSelector, {
    errors: errors.options,
    onChange: onSetValues
  })), __cssprop("div", {
    css: {
      "paddingTop": "1.2rem",
      "paddingBottom": "1.2rem"
    }
  }, __cssprop(EmailTemplateSelector, {
    changeContent: setEditorState,
    changeSubject: setSubject
  })), __cssprop("div", null, __cssprop(TextField, {
    id: "subject",
    error: errors.subject,
    label: "Sujet",
    onChange: setSubject,
    defaultValue: subject
  })), __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginTop": "0.8rem",
      "marginBottom": "0.8rem"
    }
  }, t("notification-panel-rich-text-title")), __cssprop("div", {
    css: {
      "borderWidth": "1px",
      "marginTop": "0.8rem",
      "flex": "1 1 0%"
    }
  }, __cssprop(DraftEditor.Editor, {
    editorState: editorState,
    onEditorStateChange: setEditorState,
    editorStyle: {
      height: "calc(100% - 132px)",
      paddingLeft: "0.5em",
      paddingRight: "0.5em"
    },
    wrapperStyle: {
      height: "100%",
      border: errors.body ? "solid red 1px" : ""
    }
  })), __cssprop(Button, {
    css: {
      "marginTop": "0.8rem"
    },
    onAction: onContinue,
    disabled: errors.subject?.errorMessage === null || errors.body || errors.options
  }, t("notification-panel-action"))));
};