import { useTranslation } from "../../../_snowpack/pkg/react-i18next.js";
export default function useI18n() {
  const {
    t
  } = useTranslation();
  return {
    t: (key, options) => {
      return t(key, options);
    }
  };
}