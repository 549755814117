function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

/* eslint-disable import/no-cycle */
import { canPerform } from "../../hooks/usePermission.js";
import { useSelector, Selectors } from "../../hooks/useRedux.js";
import React from "../../../../_snowpack/pkg/react.js";
import { Button } from "./Button.js";
import { Card } from "./Card.js";
import { Link } from "./Link.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const ProtectedBase = ({
  children,
  permission,
  Container
}) => {
  const role = useSelector(Selectors.session.getUserRole);

  if (!canPerform(role, permission)) {
    console.log(`Cannot perform, ${role}, ${JSON.stringify(permission)}`);
    return null;
  }

  return __cssprop(Container, null, children);
};
export const ProtectedDiv = props => __cssprop(ProtectedBase, _extends({}, props, {
  Container: () => __cssprop("div", props)
}));
export const ProtectedCard = props => __cssprop(ProtectedBase, _extends({}, props, {
  Container: () => __cssprop(Card, props)
}));
export const ProtectedCardSection = props => __cssprop(ProtectedBase, _extends({}, props, {
  Container: () => __cssprop(Card.Section, props)
}));
export const ProtectedButton = props => __cssprop(ProtectedBase, _extends({}, props, {
  Container: () => __cssprop(Button, props)
}));
export const ProtectedLink = props => __cssprop(ProtectedBase, _extends({}, props, {
  Container: () => __cssprop(Link, props)
}));
export function ProtectedTab(role, actions) {
  if (!role) {
    return [];
  }

  const filtered = actions.filter(action => canPerform(role, action.permission));
  return filtered;
}
export function ProtectedFunction(role, action, permission, fallback) {
  if (!canPerform(role, permission)) {
    if (fallback) {
      return fallback;
    }

    return () => {
      /* Do nothing, ESLint is triggered if this is empty */
    };
  }

  return action;
}
export function ProtectedNavigationItem(role, actions) {
  if (!role) {
    return [];
  }

  const filtered = actions.filter(action => canPerform(role, action.permission));
  return filtered;
}
export function ProtectedActions(role, actions) {
  if (!role) {
    return [];
  }

  const filtered = actions.filter(action => {
    if (action.condition !== undefined) {
      return canPerform(role, action.permission) && action.condition;
    }

    return canPerform(role, action.permission);
  });
  return filtered;
}
export default {
  Base: ProtectedBase,
  Card: ProtectedCard,
  Button: ProtectedButton,
  Function: ProtectedFunction,
  Actions: ProtectedActions
};