import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from "../../../../_snowpack/pkg/react.js";
import { Transition } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { css } from "../../../../_snowpack/pkg/@emotion/css.js";
import { StyledSpanSelected, StyledItemSpanSelected, StyledItemSelected, StyledLabel, StyledUl, StyledSpan } from "./styles.js";
import { Checkmark } from "../Icons.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const SelectBox = _styled.button(({
  selected,
  disabled
}) => [{
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "position": "relative",
  "width": "100%",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
  "borderRadius": "0.6rem",
  "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "paddingLeft": "1.2rem",
  "paddingRight": "4rem",
  "paddingTop": "1.6rem",
  "paddingBottom": "1.6rem",
  "textAlign": "left",
  "cursor": "default",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
  },
  "@media (min-width: 640px)": {
    "fontSize": "1.4rem",
    "lineHeight": "2rem"
  }
}, selected ? {
  "paddingTop": "0.8rem",
  "paddingBottom": "0.8rem"
} : {
  "paddingTop": "1.6rem",
  "paddingBottom": "2rem"
}, disabled ? {
  "pointerEvents": "none"
} : `pointer-events-auto`]);

export const Select = ({
  label,
  onSelect,
  loading,
  items,
  selected,
  placeholder,
  disabled
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleFocus = e => {
    if (!isOpen) {
      return;
    }

    setTimeout(() => {
      console.log(e.type);
      setIsOpen(e.type !== "blur");
    }, 150);
  };

  const onItemSelect = useCallback(item => () => {
    onSelect(item);
    setIsOpen(false);
  }, [onSelect, setIsOpen]);
  return __cssprop("div", {
    css: {
      "minWidth": "100%"
    }
  }, __cssprop(StyledLabel, null, label), __cssprop("div", {
    css: {
      "marginTop": "0.4rem"
    }
  }, __cssprop("div", {
    css: {
      "position": "relative"
    }
  }, __cssprop(SelectBox, {
    type: "button",
    "aria-haspopup": "listbox",
    "aria-expanded": "true",
    "aria-labelledby": "listbox-label",
    onBlur: handleFocus,
    onClick: () => setIsOpen(o => !o),
    selected: selected,
    disabled: disabled
  }, selected?.label && __cssprop(StyledSpan, null, selected.label), !selected && placeholder && __cssprop("span", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "left": "0px",
      "display": "flex",
      "alignItems": "center",
      "paddingLeft": "0.8rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    }
  }, placeholder)), !disabled && __cssprop("span", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "right": "0px",
      "display": "flex",
      "alignItems": "center",
      "paddingRight": "0.8rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-x-reverse": 0,
        "borderRightWidth": "calc(2px * var(--tw-divide-x-reverse))",
        "borderLeftWidth": "calc(2px * calc(1 - var(--tw-divide-x-reverse)))"
      },
      "zIndex": "10"
    }
  }, selected && __cssprop("div", {
    css: {
      "paddingLeft": "0.4rem",
      "paddingRight": "0.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
      },
      "cursor": "pointer"
    },
    onClick: event => {
      event.stopPropagation();
      onSelect(undefined);
    }
  }, __cssprop("svg", {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    height: "20",
    width: "20",
    viewBox: "0 0 20 20",
    "aria-hidden": "true",
    focusable: "false",
    fill: "currentColor"
  }, __cssprop("path", {
    d: "M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
  }))), __cssprop("div", {
    css: {
      "paddingLeft": "0.4rem",
      "paddingRight": "0.4rem"
    },
    onClick: event => {
      event.stopPropagation();
      setIsOpen(o => !o);
    }
  }, __cssprop("svg", {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    "aria-hidden": "true"
  }, __cssprop("path", {
    fillRule: "evenodd",
    d: "M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z",
    clipRule: "evenodd"
  }))))), __cssprop(Transition, {
    enter: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "75ms"
    }),
    enterFrom: css({
      "opacity": "0"
    }),
    enterTo: css({
      "opacity": "1"
    }),
    show: isOpen,
    leave: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "100ms"
    }),
    leaveFrom: css({
      "opacity": "1"
    }),
    leaveTo: css({
      "opacity": "0"
    })
  }, __cssprop("div", {
    css: {
      "position": "absolute",
      "marginTop": "0.4rem",
      "borderRadius": "0.6rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "zIndex": "10"
    }
  }, __cssprop(StyledUl, null, __cssprop(ItemsUI, {
    items: items,
    onSelect: onItemSelect,
    selected: selected
  }))))));
};

const ItemsUI = props => {
  const {
    items,
    onSelect,
    selected
  } = props;
  const [mouseOn, setMouseOn] = useState(selected?.value);

  const handleMouseMouvement = (type, item) => {
    if (type === "enter") {
      setMouseOn(item.value);
    } else {
      setMouseOn("");
    }
  };

  return __cssprop(React.Fragment, null, items?.map((item, index) => {
    const isSelected = item.value === selected?.value;
    return __cssprop(StyledItemSelected, {
      onMouseEnter: () => handleMouseMouvement("enter", item),
      onMouseLeave: () => handleMouseMouvement("leave", item),
      key: item.value,
      id: `listbox-option-${index}`,
      highlighted: mouseOn === item.value,
      onClick: onSelect(item)
    }, __cssprop(StyledSpanSelected, {
      isSelected: isSelected
    }, item.label), __cssprop(StyledItemSpanSelected, null, isSelected && __cssprop(Checkmark, null)));
  }));
};