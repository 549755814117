const initialSearchState = {
  buildings: {
    resultsPerPage: 25,
    page: 0,
    query: "",
    filter: null,
    searchMethod: "fuzzy"
  }
};
// todo do a feature flag clean up
export const initialFeaturesState = {
  applicantRequest: true,
  deleteRegistration: true,
  leasePage: true,
  tickets: true,
  userNotifications: false,
  amenities: true,
  payment: false,
  // 4 new feature flag
  applicantAddTenant: true,
  applicantAgent: true,
  applicantDeleteTenant: true,
  applicantSendToOwner: true,
  applicantVisitDate: true,
  applicantShowSmallIcon: true
};
export const initialState = {
  showDrawer: null,
  showModal: null,
  confirmModalStatus: false,
  resettingPassword: {
    kind: "NotAsked"
  },
  search: initialSearchState,
  features: initialFeaturesState,
  crumbs: []
};
export let ApplicationActionsTypes;

(function (ApplicationActionsTypes) {
  ApplicationActionsTypes["APPLICATION_SET_DRAWER_STATE"] = "APPLICATION_SET_DRAWER_STATE";
  ApplicationActionsTypes["APPLICATION_RESET_DRAWER_STATE"] = "APPLICATION_RESET_DRAWER_STATE";
  ApplicationActionsTypes["APPLICATION_ON_MASS_MESSAGE"] = "APPLICATION_ON_MASS_MESSAGE";
  ApplicationActionsTypes["ON_LOAD"] = "ON_LOAD";
  ApplicationActionsTypes["SERVER_ERRORS"] = "SERVER_ERRORS";
  ApplicationActionsTypes["SEARCH_SAVE"] = "SEARCH_SAVE";
  ApplicationActionsTypes["SET_REMEMBER_ME_EMAIL"] = "SET_REMEMBER_ME_EMAIL";
  ApplicationActionsTypes["RESET_FEATURE_TOGGLES"] = "RESET_FEATURE_TOGGLES";
  ApplicationActionsTypes["ADD_CRUMBS"] = "ADD_CRUMBS";
  ApplicationActionsTypes["REMOVE_CRUMBS"] = "REMOVE_CRUMBS";
  ApplicationActionsTypes["APPLICATION_SET_MODAL_STATE"] = "APPLICATION_SET_MODAL_STATE";
  ApplicationActionsTypes["APPLICATION_SET_MODAL_STATUS"] = "APPLICATION_SET_MODAL_STATUS";
  ApplicationActionsTypes["INIT_UPDATE_INTERCOM"] = "INIT_UPDATE_INTERCOM";
  ApplicationActionsTypes["UPDATE_INTERCOM_USER"] = "UPDATE_INTERCOM_USER";
})(ApplicationActionsTypes || (ApplicationActionsTypes = {}));