/* eslint-disable react/no-unused-prop-types */

/* eslint-disable react/require-default-props */
import React from "../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
export const ChevronLeft = ({
  className
}) => __cssprop("svg", {
  className: className,
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}, __cssprop("path", {
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2",
  d: "M9 5l7 7-7 7"
}));
export const ChevronRight = ({
  className
}) => __cssprop("svg", {
  className: className,
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}, __cssprop("path", {
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2",
  d: "M15 19l-7-7 7-7"
}));
export const ChevronStart = ({
  className
}) => __cssprop("svg", {
  className: className,
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}, __cssprop("path", {
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2",
  d: "M11 19l-7-7 7-7m8 14l-7-7 7-7"
}));
export const ChevronEnd = ({
  className
}) => __cssprop("svg", {
  className: className,
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}, __cssprop("path", {
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: "2",
  d: "M13 5l7 7-7 7M5 5l7 7-7 7"
}));
export const Loading = ({
  className,
  color = "#fff"
}) => __cssprop("svg", {
  className: className,
  viewBox: "0 0 38 38",
  role: "progressbar"
}, __cssprop("defs", null, __cssprop("linearGradient", {
  x1: "8.042%",
  y1: "0%",
  x2: "65.682%",
  y2: "23.865%",
  id: "tail-spin_svg__a"
}, __cssprop("stop", {
  stopColor: color,
  stopOpacity: "0",
  offset: "0%"
}), __cssprop("stop", {
  stopColor: color,
  stopOpacity: "0.631",
  offset: "63.146%"
}), __cssprop("stop", {
  stopColor: color,
  offset: "100%"
}))), __cssprop("g", {
  transform: "translate(1 1)",
  fill: "none",
  fillRule: "evenodd"
}, __cssprop("path", {
  d: "M36 18c0-9.94-8.06-18-18-18",
  stroke: "url(#tail-spin_svg__a)",
  strokeWidth: "2"
}, __cssprop("animateTransform", {
  attributeName: "transform",
  type: "rotate",
  from: "0 18 18",
  to: "360 18 18",
  dur: "0.9s",
  repeatCount: "indefinite"
})), __cssprop("circle", {
  fill: color,
  cx: "36",
  cy: "18",
  r: "1"
}, __cssprop("animateTransform", {
  attributeName: "transform",
  type: "rotate",
  from: "0 18 18",
  to: "360 18 18",
  dur: "0.9s",
  repeatCount: "indefinite"
}))));
export const ThreeDotsVerticalIcon = ({
  className,
  color = "currentColor"
}) => {
  return __cssprop("svg", {
    className: className,
    fill: "none",
    viewBox: "0 0 24 24",
    stroke: color
  }, __cssprop("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2,
    d: "M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
  }));
};
export const SearchIcon = ({
  className,
  color = "currentColor"
}) => __cssprop("svg", {
  className: className,
  viewBox: "0 0 20 20",
  focusable: "false",
  "aria-hidden": "true",
  fill: color
}, __cssprop("path", {
  d: "M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8m9.707 4.293l-4.82-4.82A5.968 5.968 0 0 0 14 8 6 6 0 0 0 2 8a6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414",
  fillRule: "evenodd"
}));
export const EmailIcon = ({
  className,
  color = "currentColor"
}) => __cssprop("svg", {
  className: className,
  viewBox: "0 0 20 20",
  fill: color,
  "aria-hidden": "true"
}, __cssprop("path", {
  d: "M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
}), __cssprop("path", {
  d: "M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
}));
export const EmailResendIcon = ({
  className,
  color = "currentColor"
}) => __cssprop("svg", {
  className: className,
  viewBox: "0 0 100 100",
  fill: color,
  "aria-hidden": "true"
}, __cssprop("switch", null, __cssprop("g", null, __cssprop("path", {
  d: "M89.9 50c0-12.7-6.1-24.7-16.4-32.2-1.1-.8-2.7-.6-3.6.6-.8 1.1-.6 2.7.6 3.6 9 6.5 14.3 17 14.3 28.1 0 19.2-15.6 34.8-34.8 34.8h-.7l3.3-3.3c1-1 1-2.6 0-3.6S50 77 49 78l-7.6 7.6c-1 1-1 2.6 0 3.6l7.6 7.6c.5.5 1.1.7 1.8.7s1.3-.2 1.8-.7c1-1 1-2.6 0-3.6l-3.3-3.3h.7C72 89.9 89.9 72 89.9 50zM15.2 50c0-19.2 15.6-34.8 34.8-34.8h.7l-3.3 3.3c-1 1-1 2.6 0 3.6.5.5 1.1.7 1.8.7s1.3-.2 1.8-.7l7.6-7.6c1-1 1-2.6 0-3.6L51 3.2c-1-1-2.6-1-3.6 0s-1 2.6 0 3.6l3.3 3.3H50C28 10.1 10.1 28 10.1 50c0 12.7 6.1 24.7 16.4 32.2.5.3 1 .5 1.5.5.8 0 1.6-.4 2.1-1 .8-1.1.6-2.7-.6-3.6-8.9-6.5-14.3-17-14.3-28.1z"
}), __cssprop("path", {
  d: "M27.9 36.6v26.8c0 1.3 1 2.3 2.3 2.3h39.7c1.3 0 2.3-1 2.3-2.3V36.6c0-1.3-1-2.3-2.3-2.3H30.1c-1.2 0-2.2 1-2.2 2.3zm4.5 24.5V41.8l16.1 12.8c.5.3 1 .4 1.5.4s1-.2 1.4-.5l16.1-12.8V61H32.4zm3.8-22.2h27.7L50 49.8 36.2 38.9z"
}))));
export const ErrorIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  className: className,
  viewBox: "0 0 20 20",
  fill: color,
  "aria-hidden": "true"
}, __cssprop("path", {
  fillRule: "evenodd",
  d: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z",
  clipRule: "evenodd"
}));
export const CreditCardIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  className: className,
  viewBox: "0 0 212.293 212.293",
  fill: color,
  "aria-hidden": "true"
}, __cssprop("path", {
  d: "M204.793,25.117H7.5c-4.143,0-7.5,3.358-7.5,7.5V69.12v20.569v89.986c0,4.142,3.357,7.5,7.5,7.5h197.293 c4.143,0,7.5-3.358,7.5-7.5V89.689V69.12V32.617C212.293,28.475,208.936,25.117,204.793,25.117z M197.293,40.117V61.62H15V40.117 H197.293z M15,172.176V97.189h182.293v74.986H15z"
}), __cssprop("path", {
  d: "M90.391,114.095H33.38c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h57.011c4.143,0,7.5-3.358,7.5-7.5 C97.891,117.453,94.533,114.095,90.391,114.095z"
}));
export const FileIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: color,
  stroke: "currentColor",
  className: className
}, __cssprop("path", {
  d: "M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
}), __cssprop("polyline", {
  points: "14 2 14 8 20 8"
}), __cssprop("line", {
  x1: "16",
  y1: "13",
  x2: "8",
  y2: "13"
}), __cssprop("line", {
  x1: "16",
  y1: "17",
  x2: "8",
  y2: "17"
}), __cssprop("polyline", {
  points: "10 9 9 9 8 9"
}));
export const FilesIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  id: "Layer_1",
  "data-name": "Layer 1",
  fill: color,
  viewBox: "0 0 512 512"
}, __cssprop("path", {
  className: className,
  d: "M307.39,0H217.6a64,64,0,0,0-62.88,52.32,64,64,0,0,0-51.2,51.2A64,64,0,0,0,51.2,166.4V448a64,64,0,0,0,64,64H294.4a64,64,0,0,0,62.88-52.32,64,64,0,0,0,51.2-51.2A64,64,0,0,0,460.8,345.6v-192Zm104.1,140.8H358.4A38.44,38.44,0,0,1,320,102.4V49.08ZM294.4,486.4H115.2A38.44,38.44,0,0,1,76.8,448V166.4a38.43,38.43,0,0,1,25.6-36.16V396.8a64,64,0,0,0,64,64H330.56A38.43,38.43,0,0,1,294.4,486.4Zm51.2-51.2H166.4A38.44,38.44,0,0,1,128,396.8V115.2A38.43,38.43,0,0,1,153.6,79V345.6a64,64,0,0,0,64,64H381.76A38.43,38.43,0,0,1,345.6,435.2Zm89.6-89.6A38.44,38.44,0,0,1,396.8,384H217.6a38.44,38.44,0,0,1-38.4-38.4V64a38.44,38.44,0,0,1,38.4-38.4h76.8v76.8a64,64,0,0,0,64,64h76.8Zm-192-128A12.8,12.8,0,0,1,256,204.8h51.2a12.8,12.8,0,1,1,0,25.6H256A12.8,12.8,0,0,1,243.2,217.6ZM358.4,256a12.8,12.8,0,0,1,0,25.6H256a12.8,12.8,0,0,1,0-25.6Zm12.42,64A12.8,12.8,0,0,1,358,332.8H256a12.8,12.8,0,0,1,0-25.6H358A12.8,12.8,0,0,1,370.82,320Z"
}));
export const AlertIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  viewBox: "0 0 20 20",
  className: className
}, __cssprop("path", {
  d: "M10 18a8 8 0 110-16 8 8 0 010 16zM9 9a1 1 0 002 0V7a1 1 0 10-2 0v2zm0 4a1 1 0 102 0 1 1 0 00-2 0z",
  fill: "#bf0711"
}));
export const Checkmark = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  tw: "h-5 w-5",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  fill: "currentColor",
  "aria-hidden": "true"
}, __cssprop("path", {
  fillRule: "evenodd",
  d: "M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z",
  clipRule: "evenodd"
})); // can we use this icons ? because im not sure
// https://www.flaticon.com/free-icon/building_2196647?term=visit&page=1&position=1&page=1&position=1&related_id=2196647&origin=tag

export const VisitIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  tw: "h-6 w-6",
  xmlns: "http://www.w3.org/2000/svg",
  id: "building-pin-locations",
  viewBox: "0 0 64 64",
  fill: "currentColor"
}, __cssprop("path", {
  d: "M43,41a5,5,0,1,0,5-5A5.006,5.006,0,0,0,43,41Zm8,0a3,3,0,1,1-3-3A3,3,0,0,1,51,41Z"
}), __cssprop("path", {
  d: "M51.48,55.31C54.52,51.39,59,44.94,59,41a11.021,11.021,0,0,0-9-10.81V8h2a1,1,0,0,0,1-1V3a1,1,0,0,0-1-1H6A1,1,0,0,0,5,3V7A1,1,0,0,0,6,8H8V48H6a1,1,0,0,0-1,1v3a1,1,0,0,0,1,1H42.79c.6.83,1.19,1.61,1.73,2.31C42.11,55.77,40,56.77,40,58.5c0,2.4,4.15,3.5,8,3.5s8-1.1,8-3.5C56,56.77,53.89,55.77,51.48,55.31ZM7,6V4H51V6ZM45,22H39a1,1,0,0,0-1,1v7H37v2h4.7A10.976,10.976,0,0,0,37,41c0,1.93,1.08,4.46,2.53,7H35V43a6,6,0,0,0-12,0v5H10V8H48V30a11.023,11.023,0,0,0-2,.19V23A1,1,0,0,0,45,22Zm-1,2v6H40V24ZM33,43v5H25V43a4,4,0,0,1,8,0ZM7,51V50H40.74c.22.34.44.67.66,1ZM39,41a9,9,0,0,1,18,0c0,3.87-5.93,11.77-9,15.46C44.93,52.77,39,44.87,39,41Zm9,19c-3.84,0-5.88-1.1-6-1.48.09-.31,1.37-1.1,3.96-1.39.71.87,1.2,1.43,1.29,1.53a1,1,0,0,0,1.5,0c.09-.1.58-.66,1.29-1.53,2.59.29,3.87,1.08,3.96,1.35C53.88,58.9,51.84,60,48,60Z"
}), __cssprop("path", {
  d: "M46,18V11a1,1,0,0,0-1-1H39a1,1,0,0,0-1,1v7H37v2H47V18Zm-2,0H40V12h4Z"
}), __cssprop("path", {
  d: "M33,18V11a1,1,0,0,0-1-1H26a1,1,0,0,0-1,1v7H24v2H34V18Zm-2,0H27V12h4Z"
}), __cssprop("path", {
  d: "M20,18V11a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1v7H11v2H21V18Zm-2,0H14V12h4Z"
}), __cssprop("path", {
  d: "M20,30V23a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1v7H11v2H21V30Zm-2,0H14V24h4Z"
}), __cssprop("path", {
  d: "M33,30V23a1,1,0,0,0-1-1H26a1,1,0,0,0-1,1v7H24v2H34V30Zm-2,0H27V24h4Z"
}));
export const HourglassIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  version: "1.1",
  id: "Capa_1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 297 297",
  xmlSpace: "preserve",
  fill: color
}, __cssprop("path", {
  d: "M250.929,276.619h-17.463v-31.301c0-31.668-17.379-60.777-48.936-81.967c-3.924-2.635-6.363-8.324-6.363-14.852  s2.439-12.215,6.365-14.852c31.555-21.188,48.934-50.297,48.936-81.965V20.381h17.461c5.627,0,10.189-4.562,10.189-10.191  C261.118,4.561,256.556,0,250.929,0H46.071c-5.627,0-10.19,4.561-10.19,10.189c0,5.629,4.563,10.191,10.19,10.191h17.463v31.303  c0,31.668,17.377,60.777,48.936,81.965c3.924,2.637,6.363,8.324,6.363,14.852c0,6.529-2.439,12.217-6.365,14.854  c-31.557,21.188-48.934,50.297-48.934,81.965v31.301H46.071c-5.627,0-10.19,4.563-10.19,10.191c0,5.629,4.563,10.19,10.19,10.19  h204.857c5.627,0,10.189-4.56,10.189-10.19C261.118,281.182,256.556,276.619,250.929,276.619z M83.915,245.318  c0-33.275,25.006-55.035,39.914-65.043c9.633-6.467,15.385-18.346,15.385-31.775c0-13.428-5.752-25.307-15.385-31.773  c-14.908-10.008-39.914-31.768-39.914-65.043V20.381h129.17l-0.002,31.303c0,33.275-25.006,55.035-39.912,65.041  c-9.635,6.469-15.387,18.348-15.387,31.775c0,13.43,5.752,25.307,15.385,31.775c14.908,10.008,39.914,31.768,39.916,65.043v31.301  H83.915V245.318z"
})); // https://www.flaticon.com/free-icon/maps-and-flags_447031?term=map&page=1&position=1&page=1&position=1&related_id=447031&origin=search

export const MapPinIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
  version: "1.1",
  id: "Layer_1",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  xmlSpace: "preserve",
  fill: color
}, __cssprop("path", {
  d: "M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035    c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719    c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"
})); // https://www.flaticon.com/free-icon/home_3203071?term=pin%20house&page=1&position=1&page=1&position=1&related_id=3203071&origin=search

export const MapPinWithHouseIcon = ({
  className,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  height: "20",
  viewBox: "0 0 512 512",
  width: "20",
  xmlns: "http://www.w3.org/2000/svg"
}, __cssprop("g", {
  id: "_01-home",
  "data-name": "01-home"
}, __cssprop("g", {
  id: "glyph"
}, __cssprop("path", {
  d: "m256 4c-108.075 0-196 87.925-196 196 0 52.5 31.807 119.92 94.537 200.378a1065.816 1065.816 0 0 0 93.169 104.294 12 12 0 0 0 16.588 0 1065.816 1065.816 0 0 0 93.169-104.294c62.73-80.458 94.537-147.878 94.537-200.378 0-108.075-87.925-196-196-196zm0 336c-77.2 0-140-62.8-140-140s62.8-140 140-140 140 62.8 140 140-62.8 140-140 140z"
}), __cssprop("path", {
  d: "m352.072 183.121-88-80a12 12 0 0 0 -16.144 0l-88 80a12.006 12.006 0 0 0 -2.23 15.039 12.331 12.331 0 0 0 10.66 5.84h11.642v76a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12v-44a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v44a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12v-76h11.642a12.331 12.331 0 0 0 10.66-5.84 12.006 12.006 0 0 0 -2.23-15.039z"
}))));
export const ExternalLinkIcon = ({
  style,
  _tw,
  color = "currentColor"
}) => __cssprop("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  x: "0px",
  y: "0px",
  width: "16",
  height: "16",
  viewBox: "0 0 512 512",
  fill: color,
  style: style
}, __cssprop("g", null, __cssprop("g", null, __cssprop("path", {
  d: "M392.857,292.354h-18.274c-2.669,0-4.859,0.855-6.563,2.573c-1.718,1.708-2.573,3.897-2.573,6.563v91.361    c0,12.563-4.47,23.315-13.415,32.262c-8.945,8.945-19.701,13.414-32.264,13.414H82.224c-12.562,0-23.317-4.469-32.264-13.414    c-8.945-8.946-13.417-19.698-13.417-32.262V155.31c0-12.562,4.471-23.313,13.417-32.259c8.947-8.947,19.702-13.418,32.264-13.418    h200.994c2.669,0,4.859-0.859,6.57-2.57c1.711-1.713,2.566-3.9,2.566-6.567V82.221c0-2.662-0.855-4.853-2.566-6.563    c-1.711-1.713-3.901-2.568-6.57-2.568H82.224c-22.648,0-42.016,8.042-58.102,24.125C8.042,113.297,0,132.665,0,155.313v237.542    c0,22.647,8.042,42.018,24.123,58.095c16.086,16.084,35.454,24.13,58.102,24.13h237.543c22.647,0,42.017-8.046,58.101-24.13    c16.085-16.077,24.127-35.447,24.127-58.095v-91.358c0-2.669-0.856-4.859-2.574-6.57    C397.709,293.209,395.519,292.354,392.857,292.354z"
}), __cssprop("path", {
  d: "M506.199,41.971c-3.617-3.617-7.905-5.424-12.85-5.424H347.171c-4.948,0-9.233,1.807-12.847,5.424    c-3.617,3.615-5.428,7.898-5.428,12.847s1.811,9.233,5.428,12.85l50.247,50.248L198.424,304.067    c-1.906,1.903-2.856,4.093-2.856,6.563c0,2.479,0.953,4.668,2.856,6.571l32.548,32.544c1.903,1.903,4.093,2.852,6.567,2.852    s4.665-0.948,6.567-2.852l186.148-186.148l50.251,50.248c3.614,3.617,7.898,5.426,12.847,5.426s9.233-1.809,12.851-5.426    c3.617-3.616,5.424-7.898,5.424-12.847V54.818C511.626,49.866,509.813,45.586,506.199,41.971z"
}))));