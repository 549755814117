/* eslint-disable import/no-cycle */
export const getTicket = state => state?.ticket;
export default {
  getTicket,
  getId: state => getTicket(state).id,
  getTitle: state => getTicket(state).title,
  getOrigin: state => getTicket(state).origin,
  getCreatedBy: state => getTicket(state).createdBy,
  getModifiedBy: state => getTicket(state).modifiedBy,
  getModifiedAt: state => getTicket(state).modifiedAt,
  getCategory: state => getTicket(state).category,
  getPriority: state => getTicket(state).priority,
  getResource: state => getTicket(state).resource,
  getTasks: state => getTicket(state).tasks,
  getCreatedAt: state => getTicket(state).createdAt,
  getClosedAt: state => getTicket(state).closedAt,
  getDeletedAt: state => getTicket(state).deletedAt,
  getFiles: state => getTicket(state).files,
  getNotes: state => getTicket(state).notes,
  getConversation: state => getTicket(state).conversations,
  getTags: state => getTicket(state).tags,
  getIsRecurring: state => getTicket(state).isRecurring
};