import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledBadge = _styled.span(({
  primary,
  white,
  success,
  warning,
  critical,
  blue,
  purple,
  pink
}) => [{
  "display": "inline-flex",
  "alignItems": "center",
  "paddingLeft": "1.2rem",
  "paddingRight": "1.2rem",
  "paddingTop": "0.2rem",
  "paddingBottom": "0.2rem",
  "borderRadius": "9999px",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
  "whiteSpace": "nowrap"
}, primary && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(199, 210, 254, var(--tw-bg-opacity))"
}, white && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))"
}, success && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(209, 250, 229, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(6, 95, 70, var(--tw-text-opacity))"
}, warning && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(254, 243, 199, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(146, 64, 14, var(--tw-text-opacity))"
}, critical && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(254, 226, 226, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(153, 27, 27, var(--tw-text-opacity))"
}, blue && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(219, 234, 254, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(30, 64, 175, var(--tw-text-opacity))"
}, purple && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(237, 233, 254, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(91, 33, 182, var(--tw-text-opacity))"
}, pink && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(252, 231, 243, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(157, 23, 77, var(--tw-text-opacity))"
}]);

export const Badge = props => {
  const {
    children
  } = props;
  return __cssprop(StyledBadge, props, children);
};

const StyledBadgeWithRemove = _styled.span(({
  primary,
  white,
  success,
  warning,
  critical
}) => [{
  "display": "inline-flex",
  "borderRadius": "9999px",
  "alignItems": "center",
  "paddingTop": "0.2rem",
  "paddingBottom": "0.2rem",
  "paddingLeft": "1rem",
  "paddingRight": "0.4rem",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
}, primary && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(199, 210, 254, var(--tw-bg-opacity))"
}, white && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))"
}, success && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(209, 250, 229, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(6, 95, 70, var(--tw-text-opacity))"
}, warning && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(254, 243, 199, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(146, 64, 14, var(--tw-text-opacity))"
}, critical && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(254, 226, 226, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(153, 27, 27, var(--tw-text-opacity))"
}]);

export const BadgeWithRemove = props => {
  const {
    children
  } = props;
  return __cssprop(StyledBadgeWithRemove, props, children, __cssprop("button", {
    onClick: () => props.onDelete(props.id),
    type: "button",
    css: {
      "flexShrink": "0",
      "marginLeft": "0.2rem",
      "height": "1.6rem",
      "width": "1.6rem",
      "borderRadius": "9999px",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "--tw-text-opacity": "1",
      "color": "rgba(129, 140, 248, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(199, 210, 254, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Remove tags"), __cssprop("svg", {
    css: {
      "height": "0.8rem",
      "width": "0.8rem"
    },
    stroke: "currentColor",
    fill: "none",
    viewBox: "0 0 8 8"
  }, __cssprop("path", {
    strokeLinecap: "round",
    strokeWidth: "1.5",
    d: "M1 1l6 6m0-6L1 7"
  }))));
};