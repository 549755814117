import useI18n from "../hooks/useI18n.js";
import { getFormattedAmount } from "../store/artifacts/Lease/util.js";
import { DateTime } from "../../../_snowpack/pkg/luxon.js";
import * as React from "../../../_snowpack/pkg/react.js";
import { GrNotes } from "../../../_snowpack/pkg/react-icons/gr.js";
import { DualColumnItem } from "./Tailwind/ResourceList.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
export const DepositBody = ({
  item
}) => {
  const {
    t
  } = useI18n();
  const {
    createdBy,
    depositBy,
    depositFor,
    amount,
    depositDate,
    files
  } = item;
  const isDepositByTheSameUser = createdBy && depositBy ? createdBy.id === depositBy.id : false;
  const depositByText = isDepositByTheSameUser ? t("deposit-enter-by") : t("deposit-enter-deposit-by");
  return __cssprop(DualColumnItem, {
    primary: {
      heading: getFormattedAmount(amount),
      content: depositFor.name
    },
    secondary: {
      heading: __cssprop("div", {
        css: {
          "display": "flex"
        }
      }, __cssprop("div", {
        css: {
          "display": "flex",
          "flex": "1 1 0%"
        }
      }, __cssprop("div", {
        css: {
          "display": "block"
        }
      }, createdBy && `${depositByText} ${createdBy.name}`, !isDepositByTheSameUser && depositBy && __cssprop(React.Fragment, null, t("deposit-deposit-by"), " ", depositBy.name), __cssprop("div", null, DateTime.fromISO(depositDate).toUTC().toISODate()))), __cssprop("div", {
        css: {
          "display": "flex",
          "paddingLeft": "1.2rem",
          "alignSelf": "center",
          "alignItems": "center"
        }
      }, __cssprop(GrNotes, null), __cssprop("div", {
        css: {
          "paddingLeft": "0.4rem"
        }
      }, files ? files.length : 0)))
    }
  });
};
export default DepositBody;