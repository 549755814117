import { LocationMarkerIcon } from "../../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { Badge } from "../../../components/Tailwind/Badge.js";
import { ProtectedCard } from "../../../components/Tailwind/ProtectedComponents.js";
import RemoteList from "../../../components/Tailwind/RemoteList.js";
import { DualColumnItem, ResourceList } from "../../../components/Tailwind/ResourceList.js";
import { GetProjectBuildingsDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../store/artifacts/Building/types.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { RiErrorWarningLine } from "../../../../../_snowpack/pkg/react-icons/ri.js";
import { useHistory } from "../../../../../_snowpack/pkg/react-router.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const ProjectBuildingList = () => {
  const {
    t
  } = useI18n();
  const history = useHistory();
  const {
    dispatch
  } = useAction();
  const id = useSelector(Selectors.project.getId);

  const renderBuilding = item => {
    let text;
    const url = "/buildings/";
    const tags = item.tags && item.tags.length > 0 ? item.tags.map(tag => __cssprop(Badge, null, tag)) : null;
    const buildDate = item.buildDate ? `(${DateTime.fromISO(item.buildDate).year})` : "";

    const itemBody = __cssprop(DualColumnItem, {
      primary: {
        heading: `${item.civicNumber} ${item.streetName} ${buildDate}`,
        content: __cssprop("div", {
          css: {
            "@media (min-width: 640px)": {
              "display": "flex"
            }
          }
        }, __cssprop("p", {
          css: {
            "display": "flex",
            "alignItems": "center",
            "fontSize": "1.4rem",
            "lineHeight": "2rem",
            "--tw-text-opacity": "1",
            "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
          }
        }, __cssprop("p", {
          css: {
            "marginTop": "0.8rem",
            "display": "flex",
            "alignItems": "center",
            "fontSize": "1.4rem",
            "lineHeight": "2rem",
            "--tw-text-opacity": "1",
            "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
            "@media (min-width: 640px)": {
              "marginTop": "0px"
            }
          }
        }, __cssprop(LocationMarkerIcon, {
          css: {
            "flexShrink": "0",
            "marginRight": "0.6rem",
            "height": "1.6rem",
            "width": "1.6rem",
            "--tw-text-opacity": "1",
            "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
          },
          "aria-hidden": "true"
        }), item.city, " ", item.province)))
      },
      secondary: {
        heading: __cssprop("div", {
          css: {
            "display": "flex",
            "alignItems": "center"
          }
        }, tags, text && __cssprop("p", {
          css: {
            "display": "flex",
            "alignItems": "center",
            "fontSize": "1.4rem",
            "lineHeight": "2rem",
            "--tw-text-opacity": "1",
            "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
            "@media (min-width: 640px)": {
              "marginTop": "0px",
              "marginLeft": "2.4rem"
            }
          }
        }, __cssprop(Badge, {
          white: true
        }, __cssprop(RiErrorWarningLine, {
          css: {
            "flexShrink": "0",
            "marginRight": "0.6rem",
            "height": "1.6rem",
            "width": "1.6rem",
            "--tw-text-opacity": "1",
            "color": "rgba(251, 191, 36, var(--tw-text-opacity))"
          },
          "aria-hidden": "true"
        }), text)))
      }
    });

    return __cssprop("div", null, __cssprop(ResourceList.Item, {
      id: item.id,
      accessibilityLabel: t("see-details-for", {
        for: item.streetName
      }),
      onClick: () => {
        dispatch({
          type: BuildingActionsTypes.BUILDING_INITIALIZE,
          payload: {
            kind: "WithBuildingId",
            buildingId: item.id
          }
        });
        history.push(`${url}${item.id}`);
      } // backgroundImage={item?.image?.url}

    }, itemBody));
  };

  return __cssprop(ProtectedCard, {
    permission: {
      kind: "Project",
      permission: "view:projectBuilding"
    },
    title: t("project-info-list-buildings")
  }, __cssprop(RemoteList, {
    noShadow: true,
    query: GetProjectBuildingsDocument,
    name: "project.buildings",
    renderItem: renderBuilding,
    resourceName: {
      plural: t("buildings"),
      singular: t("building")
    },
    extraVariables: {
      id
    }
  }));
};