import { ListingSideBar } from "../../forms/SideBar/ListingSideBar/index.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { ListingActionsTypes } from "../../store/artifacts/Listing/types.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React from "../../../../_snowpack/pkg/react.js";
import { HiPencil } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { ListBox } from "../Tailwind/Listbox.js";
import { ProtectedButton } from "../Tailwind/ProtectedComponents.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const ListingPanel = ({
  refetch,
  isOpen,
  listingId,
  handleOnClose,
  unitId
}) => {
  const {
    t
  } = useI18n();
  return __cssprop(SlideOver, {
    title: t("rental-form-modify-ad"),
    isOpen: isOpen,
    onClose: handleOnClose
  }, __cssprop(ListingSideBar, {
    refetch: refetch,
    listingId: listingId,
    unitId: unitId
  }));
};
export const ListingButton = ({
  listingId,
  isPublished,
  availability,
  setIsListingPanelOpen
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const listingOptions = [{
    name: t("rental-publish-ad"),
    description: t("rental-publish-ad-description"),
    kind: "Positive",
    display: t("rental-publised-since", {
      date: DateTime.fromISO(availability).toUTC().toISODate()
    })
  }, {
    name: t("rental-remove-ad"),
    description: t("rental-remove-ad-description"),
    kind: "Neutral",
    display: t("rental-ad-no-longer-publised")
  }];
  const onClick = React.useCallback(evt => {
    if (evt.kind === "SecondaryAction") {
      // open listing panel
      setIsListingPanelOpen(true);
      return;
    }

    dispatch({
      type: ListingActionsTypes.LISTING_PUBLISH,
      payload: {
        id: listingId,
        status: evt.option.name === listingOptions[0].name
      }
    });
  }, [dispatch]); // if id exists then listing exists

  if (listingId) {
    return __cssprop(ListBox, {
      options: listingOptions,
      selected: isPublished ? listingOptions[0] : listingOptions[1],
      secondaryAction: {
        icon: HiPencil
      },
      onClick: onClick
    });
  }

  return __cssprop(ProtectedButton, {
    grouped: true,
    permission: {
      kind: "Rental",
      permission: "mutate:create"
    },
    onAction: () => setIsListingPanelOpen(true)
  }, t("unit-create-technical-sheet"));
};