/* eslint-disable import/no-cycle */
export const getTask = state => state?.task;
export default {
  getTask,
  getId: state => getTask(state).id,
  getTitle: state => getTask(state).title,
  getCreatedBy: state => getTask(state).createdBy,
  getModifiedBy: state => getTask(state).modifiedBy,
  getModifiedAt: state => getTask(state).modifiedAt,
  getCreatedAt: state => getTask(state).createdAt,
  getClosedAt: state => getTask(state).closedAt,
  getDueDate: state => getTask(state).dueDate,
  getAssignee: state => getTask(state).assignee,
  getInvoice: state => getTask(state).invoice,
  getTicket: state => getTask(state).ticket
};