export const mapFile = (file, idx, kind) => ({ ...file,
  order: idx,
  kind: kind || "remote"
});
export const mapFiles = (files, kind) => {
  if (files !== undefined && files !== null) {
    return files.map((item, idx) => mapFile(item, idx, kind || "remote"));
  }

  return [];
};
export const getUniqueFiles = files => {
  const setFiles = new Set();
  const uniqueFiles = files.filter(file => {
    if (!setFiles.has(file.fileName)) {
      setFiles.add(file.fileName);
      return true;
    }

    return false;
  }, setFiles);
  return uniqueFiles;
};
export const mapFileInput = (file, idx) => ({ ...file,
  order: idx
});
export const mapFilesInput = files => {
  if (files !== undefined && files !== null) {
    return files.map(mapFileInput);
  }

  return [];
};
export const mapFileToBase64 = files => "";