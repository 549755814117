import { useAction } from "../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../store/artifacts/Building/types.js";
import { isRentableUnitGQL } from "../../utils/unit.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../../_snowpack/pkg/react-router.js";
import { Link } from "../Tailwind/Link.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const BuildingAddressRender = ({
  buildingId,
  unit
}) => {
  const history = useHistory();
  const {
    dispatch
  } = useAction();

  if (isRentableUnitGQL(unit)) {
    return __cssprop("div", null, __cssprop(Link, {
      text: `${unit.buildingAddress}`,
      action: {
        kind: "ClickAction",
        event: e => {
          e.stopPropagation();
          dispatch({
            type: BuildingActionsTypes.BUILDING_INITIALIZE,
            payload: {
              buildingId,
              kind: "WithBuildingId"
            }
          });
          history.push(`/buildings/${buildingId}`);
        }
      },
      key: unit.id
    }));
  }

  return __cssprop("div", null, __cssprop(Link, {
    text: `${unit?.subAddress}-${unit?.buildingAddress}`,
    action: {
      kind: "ClickAction",
      event: e => {
        e.stopPropagation();
        history.push(`/unit/${unit?.id}`);
      }
    },
    key: unit?.id
  }));
};