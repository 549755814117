import { en } from "./en.js";
import { fr } from "./fr.js"; // the translations

export default {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
};