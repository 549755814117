import { RentalApplicationRejectionReason } from "../graphql/documents.js";
export const cancelChoice = (t, renderChildren) => [{
  label: t("applicant-request-option-bad-credit-score"),
  id: RentalApplicationRejectionReason.BadCredit
}, {
  label: t("applicant-request-option-refusal-of-owner"),
  id: RentalApplicationRejectionReason.OwnerRefused
}, {
  label: t("applicant-request-option-refusal-of-visit"),
  id: RentalApplicationRejectionReason.VisitRefused
}, {
  label: t("applicant-request-option-listing-no-longer-available"),
  id: RentalApplicationRejectionReason.ListingNoLongerAvailable
}, {
  label: t("applicant-request-option-other"),
  id: RentalApplicationRejectionReason.Other,
  renderChildren
}];
export const applicantStatusOptions = t => [{
  label: t("applicant-request-status-active"),
  value: "Active"
}, {
  label: t("applicant-request-status-approved"),
  value: "Approved"
}, {
  label: t("applicant-request-status-fulfilled"),
  value: "Fulfilled"
}, {
  label: t("applicant-request-status-cancelled"),
  value: "Cancelled"
}, {
  label: t("applicant-request-status-denied"),
  value: "Denied"
}];
export const applicantStatusOptionsV2 = t => [{
  label: t("applicant-request-status-active"),
  value: "active",
  defaults: true
}, {
  label: t("applicant-request-status-fulfilled"),
  value: "completed"
}, {
  label: t("applicant-request-status-incomplete"),
  value: "incomplete"
}, {
  label: t("applicant-request-status-denied"),
  value: "rejected"
}];
export const applicantProgressOptionsV2 = t => [{
  label: t("applicant-request-progress-approved-internally"),
  value: "approvedInternally"
}, {
  label: t("applicant-request-progress-visit-accepted"),
  value: "visitAccepted"
}, {
  label: t("applicant-request-progress-owner-approved"),
  value: "ownerApproved"
}, {
  label: t("applicant-request-progress-documents-uploaded"),
  value: "documentsUploaded"
}, {
  label: t("applicant-request-no-progress"),
  value: "noProgress"
}];
export const applicantv2FileCategory = t => ({
  signature_required: t("applicants-v2-file-tab-signature_required"),
  other: t("applicants-v2-file-tab-other"),
  lease: t("applicants-v2-file-tab-lease"),
  insurance: t("applicants-v2-file-tab-insurance"),
  utility: t("applicants-v2-file-tab-utility"),
  building_regulation: t("applicants-v2-file-tab-building_regulation"),
  check: t("applicants-v2-file-tab-check")
});
export const cancelReason = t => ({
  badAuthorityFolder: t("applicant-request-option-housing-authority-file"),
  badCredit: t("applicant-request-option-bad-credit-score"),
  badDebtRatio: t("applicant-request-option-debt-ratio"),
  wrongReference: t("applicant-request-option-wrong-reference"),
  ownerRefusal: t("applicant-request-option-refusal-of-owner"),
  withdrawalCandidate: t("applicant-request-option-withdrawal-candidate"),
  incomplete: t("applicant-request-option-incomplete-file"),
  other: t("applicant-request-option-other")
});