import { EmptyState } from "../../components/Tailwind/EmptyState.js";
import RemoteList from "../../components/Tailwind/RemoteList.js";
import { DualColumnItem, ResourceList } from "../../components/Tailwind/ResourceList.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import { GetTenantsDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { TenantActionsTypes } from "../../store/artifacts/Tenant/types.js";
import { formatPhoneNumber } from "../../utils/tenant.js";
import React, { useEffect, useRef, useState } from "../../../../_snowpack/pkg/react.js";
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedCard } from "../../components/Tailwind/ProtectedComponents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Tenants = withBreadcrumb(({
  history,
  setCrumb
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  useEffect(() => {
    setCrumb({
      title: t("tenants"),
      path: "/tenants",
      level: 0
    });
  }, []);
  const tabs = [{
    content: t("tenant-tab-all"),
    id: "all",
    permission: {
      kind: "Tenant",
      permission: "view:list"
    }
  }, {
    content: t("tenant-tab-active"),
    id: "active",
    permission: {
      kind: "Tenant",
      permission: "view:list"
    }
  }, {
    content: t("tenant-tab-past"),
    id: "previous",
    permission: {
      kind: "Tenant",
      permission: "view:list"
    }
  }];

  const getEmailAndPhone = tenant => {
    if (tenant.email && tenant?.primaryPhone) {
      return `${tenant.email} (${formatPhoneNumber(tenant?.primaryPhone)})`;
    }

    if (tenant.email) {
      return `${tenant.email}`;
    }

    if (tenant?.primaryPhone) {
      return `${formatPhoneNumber(tenant?.primaryPhone)}`;
    }

    return "";
  };

  const renderTenants = tenant => {
    const itemBody = __cssprop(DualColumnItem, {
      primary: {
        heading: tenant.name
      },
      secondary: {
        heading: __cssprop("div", {
          tw: "flex items-center"
        }, getEmailAndPhone(tenant))
      }
    });

    return __cssprop("div", null, __cssprop(ResourceList.Item, {
      id: tenant.id,
      accessibilityLabel: t("tenant-accessibility-label", {
        name: tenant.name
      }),
      onClick: () => {
        dispatch({
          type: TenantActionsTypes.TENANT_INITIALIZE,
          payload: {
            kind: "WithTenantId",
            tenantId: tenant.id
          }
        });
        history.push(`/tenants/${tenant.id}`);
      },
      persistActions: true
    }, itemBody));
  };

  const tenantRef = useRef();
  const [selectedTab, setSelectedTab] = useState(0);
  return __cssprop(React.Fragment, null, __cssprop(Page, {
    title: t("tenants"),
    tabs: {
      tabs,
      onSelect: setSelectedTab,
      selected: selectedTab
    }
  }, __cssprop(ProtectedCard, {
    permission: {
      kind: "Tenant",
      permission: "view:list"
    }
  }, __cssprop(RemoteList, {
    searchPlaceholder: t("lease-page-form-tenant-select-placeholder"),
    ref: tenantRef,
    query: GetTenantsDocument,
    name: "tenants",
    renderItem: renderTenants,
    resourceName: {
      plural: t("tenants"),
      singular: t("tenant")
    },
    extraVariables: tabs[selectedTab].id === "all" ? {} : {
      status: tabs[selectedTab].id
    },
    emptyState: __cssprop(EmptyState, {
      image: "/img/noTenantsSelectedForLease.svg",
      heading: t("tenant-selector-empty-state-title")
    })
  }))));
});
export default Tenants;