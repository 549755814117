/* eslint-disable no-underscore-dangle */
import { createReducer } from "../../helpers/actions.js";
import sagas from "./sagas.js";
import { initialState } from "./types.js";
export const getProject = state => state?.project;
export default {
  sagas: sagas.executers,
  reducer: createReducer(initialState, sagas.reducers),
  selectors: {
    getId: state => getProject(state).id,
    getName: state => getProject(state).name,
    getPhoneInfo: state => getProject(state).phoneInfo,
    getPrimaryPhone: state => getProject(state).phoneInfo?.primaryPhone,
    getPhoneIsMobile: state => getProject(state).phoneInfo?.phoneIsMobile,
    getSecondaryPhone: state => getProject(state).phoneInfo?.secondaryPhone,
    getSecondaryPhoneIsMobile: state => getProject(state).phoneInfo?.secondaryPhoneIsMobile,
    getEmail: state => getProject(state).email,
    getManagementType: state => getProject(state).managementType,
    getContactName: state => getProject(state).contactName,
    getLegalName: state => getProject(state).legalName,
    getOrganization: state => "",
    getStatistics: state => getProject(state).statistics,
    getFiles: state => getProject(state).files,
    getNotes: state => getProject(state).notes,
    getBuildings: state => getProject(state).buildings,
    getDeposits: state => getProject(state).deposits,
    getUsers: state => getProject(state).users.items,
    getApplication: state => getProject(state).applications,
    getTickets: state => getProject(state).tickets.items
  }
};