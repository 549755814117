import { css as _css } from "../../../../_snowpack/pkg/@emotion/react.js";
import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { Global as _globalImport } from "../../../../_snowpack/pkg/@emotion/react.js";

const _GlobalStyles = () => __cssprop(_globalImport, {
  styles: _css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  :root {
    -moz-tab-size: 4;
    tab-size: 4;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
  }

  body {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  }

  hr {
    height: 0;
    color: inherit;
  }

  abbr[title] {
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  table {
    text-indent: 0;
    border-color: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  legend {
    padding: 0;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }


  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  button {
    background-color: transparent;
    background-image: none;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.5;
  }

  body {
    font-family: inherit;
    line-height: inherit;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }

  hr {
    border-top-width: 1px;
  }

  img {
    border-style: solid;
  }

  textarea {
    resize: vertical;
  }

  input::placeholder,
  textarea::placeholder {
    color: #9ca3af;
  }

  button,
  [role="button"] {
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }


      @keyframes spin {
          to { 
            transform: rotate(360deg);
          }
        }
      @keyframes ping {
          75%, 100% { 
            transform: scale(2); opacity: 0;
          }
        }
      @keyframes pulse {
          50% { 
            opacity: .5;
          }
        }
      @keyframes bounce {
          0%, 100% { 
            transform: translateY(-25%); animationTimingFunction: cubic-bezier(0.8,0,1,1);
          }
        
          50% { 
            transform: none; animationTimingFunction: cubic-bezier(0,0,0.2,1);
          }
        }
* {
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
  }
* {
  --tw-shadow: 0 0 #0000; }

select {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
background-position: right 0.5rem center;
background-repeat: no-repeat;
background-size: 1.5em 1.5em;
padding-right: 2.5rem;
color-adjust: exact;
        }
[multiple] {
background-image: initial;
background-position: initial;
background-repeat: unset;
background-size: initial;
padding-right: 0.75rem;
color-adjust: unset;
        }
[type='file'] {
background: unset;
border-color: inherit;
border-width: 0;
border-radius: 0;
padding: 0;
font-size: unset;
line-height: inherit;
        }
[type='radio'] {
border-radius: 100%;
        }
[type='checkbox'] {
border-radius: 0px;
        }
[type='file']:focus {
outline: 1px auto -webkit-focus-ring-color;
        }
[type='radio']:checked {
background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
        }
[type='checkbox']:checked {
background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
        }
::-webkit-date-and-time-value {
min-height: 1.5em;
        }
[type='checkbox']:indeterminate {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
border-color: transparent;
background-color: currentColor;
background-size: 100% 100%;
background-position: center;
background-repeat: no-repeat;
        }
::-webkit-datetime-edit-fields-wrapper {
padding: 0;
        }
input::placeholder,textarea::placeholder {
color: #6b7280;
opacity: 1;
        }
[type='checkbox'],[type='radio'] {
appearance: none;
padding: 0;
color-adjust: exact;
display: inline-block;
vertical-align: middle;
background-origin: border-box;
user-select: none;
flex-shrink: 0;
height: 1rem;
width: 1rem;
color: #2563eb;
background-color: #fff;
border-color: #6b7280;
border-width: 1px;
        }
[type='checkbox']:focus,[type='radio']:focus {
outline: 2px solid transparent;
outline-offset: 2px;
--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
--tw-ring-offset-width: 2px;
--tw-ring-offset-color: #fff;
--tw-ring-color: #2563eb;
--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
        }
[type='checkbox']:checked,[type='radio']:checked {
border-color: transparent;
background-color: currentColor;
background-size: 100% 100%;
background-position: center;
background-repeat: no-repeat;
        }
[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus {
border-color: transparent;
background-color: currentColor;
        }
[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus {
border-color: transparent;
background-color: currentColor;
        }
[type='text']:focus,[type='email']:focus,[type='url']:focus,[type='password']:focus,[type='number']:focus,[type='date']:focus,[type='datetime-local']:focus,[type='month']:focus,[type='search']:focus,[type='tel']:focus,[type='time']:focus,[type='week']:focus,[multiple]:focus,textarea:focus,select:focus {
outline: 2px solid transparent;
outline-offset: 2px;
--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
--tw-ring-offset-width: 0px;
--tw-ring-offset-color: #fff;
--tw-ring-color: #2563eb;
--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
border-color: #2563eb;
        }
[type='text'],[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select {
appearance: none;
background-color: #fff;
border-color: #6b7280;
border-width: 1px;
border-radius: 0px;
padding-top: 0.5rem;
padding-right: 0.75rem;
padding-bottom: 0.5rem;
padding-left: 0.75rem;
font-size: 1rem;
line-height: 1.5rem;
        }`
});

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState } from "../../../../_snowpack/pkg/react.js";
import { Dialog, Menu, Transition } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { HiBell, HiMenu, HiX, HiSearch, HiUserCircle } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { AiOutlineUserSwitch } from "../../../../_snowpack/pkg/react-icons/ai.js";
import { useFeatureToggle } from "../../hooks/useFeatureToggle.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import { css } from "../../../../_snowpack/pkg/@emotion/css.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const TopNavLogo = __cssprop("div", {
  css: {
    "position": "absolute",
    "top": "0px",
    "bottom": "0px",
    "left": "0px",
    "width": "11.2rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))",
    "@media (min-width: 768px)": {
      "position": "static",
      "flexShrink": "0"
    }
  }
}, __cssprop("a", {
  href: "#",
  css: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "height": "6.4rem",
    ":focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-inset": "inset",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgba(79, 70, 229, var(--tw-ring-opacity))"
    }
  }
}, __cssprop("img", {
  css: {
    "marginLeft": "auto",
    "marginRight": "auto",
    "height": "4.8rem",
    "width": "auto"
  },
  src: "/img/icon-white.svg",
  alt: "SimpleLoyer"
})));

const MenuButtonArea = onMobileMenuClick => {
  return __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "right": "0px",
      "paddingRight": "1.6rem",
      "display": "flex",
      "alignItems": "center",
      "@media (min-width: 640px)": {
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "display": "none"
      }
    }
  }, __cssprop("button", {
    type: "button",
    css: {
      "marginRight": "-0.8rem",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "padding": "0.8rem",
      "borderRadius": "0.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-inset": "inset",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(79, 70, 229, var(--tw-ring-opacity))"
      }
    },
    onClick: onMobileMenuClick
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Open main menu"), __cssprop(HiMenu, {
    css: {
      "display": "block",
      "height": "2.4rem",
      "width": "2.4rem"
    },
    "aria-hidden": "true"
  })));
};

const searchBar = showSearchBar => {
  return __cssprop("div", {
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%"
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "67.2rem",
      "position": "relative",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ":focus-within": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
      }
    }
  }, showSearchBar && __cssprop(React.Fragment, null, __cssprop("label", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    },
    htmlFor: "search"
  }, "Search"), __cssprop("input", {
    id: "search",
    type: "search",
    placeholder: "Search",
    css: {
      "display": "block",
      "width": "100%",
      "borderColor": "transparent",
      "paddingLeft": "4.8rem",
      "::placeholder": {
        "--tw-placeholder-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-placeholder-opacity))"
      },
      ":focus": {
        "borderColor": "transparent",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)"
      },
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }
  }), __cssprop("div", {
    css: {
      "pointerEvents": "none",
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "left": "0px",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "paddingLeft": "1.6rem"
    }
  }, __cssprop(HiSearch, {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  })))));
};

const navItems = topBarNavigation => {
  return __cssprop("nav", {
    "aria-label": "Global",
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(3.2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, topBarNavigation.map((i, idx) => {
    return __cssprop("a", {
      key: idx,
      onClick: i.onClick,
      className: "group",
      "aria-hidden": "true",
      css: {
        "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
        "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
        "transitionDuration": "150ms",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "cursor": "pointer",
        "display": "flex",
        "alignItems": "center",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
        }
      }
    }, i.icon && __cssprop(i.icon, {
      css: {
        "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
        "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
        "transitionDuration": "150ms",
        "height": "2rem",
        "width": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "marginRight": "0.4rem",
        ".group:hover &": {
          "--tw-text-opacity": "1",
          "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
        }
      }
    }), i.label);
  }));
};

const notificationsButton = __cssprop("span", {
  css: {
    "display": "inline-flex"
  }
}, __cssprop("a", {
  href: "#",
  css: {
    "marginLeft": "-0.4rem",
    "marginRight": "-0.4rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
    "padding": "0.4rem",
    "borderRadius": "9999px",
    "--tw-text-opacity": "1",
    "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
    ":hover": {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }
}, __cssprop("span", {
  css: {
    "position": "absolute",
    "width": "1px",
    "height": "1px",
    "padding": "0",
    "margin": "-1px",
    "overflow": "hidden",
    "clip": "rect(0, 0, 0, 0)",
    "whiteSpace": "nowrap",
    "borderWidth": "0"
  }
}, "View notifications"), __cssprop(HiBell, {
  css: {
    "height": "2.4rem",
    "width": "2.4rem"
  },
  "aria-hidden": "true"
})));

const orgSelectorMenu = orgSelectorMenuNavigation => {
  if (!orgSelectorMenuNavigation || orgSelectorMenuNavigation?.length < 2) return null;
  const title = orgSelectorMenuNavigation.filter(item => item.description === true)[0].label;
  return __cssprop(Menu, {
    as: "div",
    css: {
      "position": "relative",
      "display": "inline-block",
      "textAlign": "left"
    }
  }, ({
    open
  }) => __cssprop(React.Fragment, null, __cssprop(Menu.Button, {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderRadius": "9999px",
      "display": "flex",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(79, 70, 229, var(--tw-ring-opacity))"
      },
      "alignItems": "center"
    }
  }, __cssprop(AiOutlineUserSwitch, {
    css: {
      "height": "3.2rem",
      "width": "3.2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, title)), __cssprop(Transition, {
    show: open,
    as: Fragment,
    enter: "transition ease-out duration-100",
    enterFrom: "transform opacity-0 scale-95",
    enterTo: "transform opacity-100 scale-100",
    leave: "transition ease-in duration-75",
    leaveFrom: "transform opacity-100 scale-100",
    leaveTo: "transform opacity-0 scale-95"
  }, __cssprop(Menu.Items, {
    static: true,
    css: {
      "transformOrigin": "top right",
      "position": "absolute",
      "zIndex": "30",
      "right": "0px",
      "marginTop": "0.8rem",
      "width": "38.4rem",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "--tw-ring-opacity": "0.05",
      "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      }
    }
  }, __cssprop("div", {
    css: {
      "borderRadius": "0.8rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "--tw-ring-opacity": "0.05",
      "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
      "overflow": "hidden"
    }
  }, __cssprop("div", {
    css: {
      "position": "relative",
      "display": "grid",
      "gap": "2.4rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "2rem",
      "paddingRight": "2rem",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "@media (min-width: 640px)": {
        "gap": "3.2rem",
        "padding": "3.2rem"
      }
    }
  }))))));
};

const menu = (flyoutMenuNavigation, user, appVersion) => {
  return __cssprop(Menu, {
    as: "div",
    css: {
      "position": "relative",
      "display": "inline-block",
      "textAlign": "left"
    }
  }, ({
    open
  }) => __cssprop(React.Fragment, null, __cssprop(Menu.Button, {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderRadius": "9999px",
      "display": "flex",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(79, 70, 229, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Open user menu"), user?.profileImage ? __cssprop("img", {
    css: {
      "height": "3.2rem",
      "width": "3.2rem",
      "borderRadius": "9999px"
    },
    src: user.profileImage,
    alt: ""
  }) : __cssprop(HiUserCircle, {
    css: {
      "height": "3.2rem",
      "width": "3.2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })), __cssprop(Transition, {
    show: open,
    as: Fragment,
    enter: "transition ease-out duration-100",
    enterFrom: "transform opacity-0 scale-95",
    enterTo: "transform opacity-100 scale-100",
    leave: "transition ease-in duration-75",
    leaveFrom: "transform opacity-100 scale-100",
    leaveTo: "transform opacity-0 scale-95"
  }, __cssprop(Menu.Items, {
    static: true,
    css: {
      "transformOrigin": "top right",
      "position": "absolute",
      "zIndex": "30",
      "right": "0px",
      "marginTop": "0.8rem",
      "width": "38.4rem",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "--tw-ring-opacity": "0.05",
      "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      }
    }
  }, __cssprop("div", {
    css: {
      "borderRadius": "0.8rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "--tw-ring-opacity": "0.05",
      "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
      "overflow": "hidden"
    }
  }, __cssprop("div", {
    css: {
      "position": "relative",
      "display": "grid",
      "gap": "2.4rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "2rem",
      "paddingRight": "2rem",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "@media (min-width: 640px)": {
        "gap": "3.2rem",
        "padding": "3.2rem"
      }
    }
  }, flyoutMenuNavigation.main.map(item => __cssprop(Menu.Item, {
    key: item.label
  }, __cssprop("a", {
    onClick: item.onClick,
    "aria-hidden": true,
    css: {
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms",
      "margin": "-1.2rem",
      "padding": "1.2rem",
      "display": "flex",
      "alignItems": "flex-start",
      "borderRadius": "0.8rem",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      },
      "cursor": "pointer"
    }
  }, __cssprop("div", {
    css: {
      "flexShrink": "0",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "height": "4rem",
      "width": "4rem",
      "borderRadius": "0.6rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "height": "4.8rem",
        "width": "4.8rem"
      }
    }
  }, __cssprop(item.icon, {
    css: {
      "height": "2.4rem",
      "width": "2.4rem"
    },
    "aria-hidden": "true"
  })), __cssprop("div", {
    css: {
      "marginLeft": "1.6rem"
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, item.label), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, item.description)))))), __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "128rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))"
      },
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem",
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "center",
      "@media (min-width: 640px)": {
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(0px * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(0px * var(--tw-space-y-reverse))",
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(4rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(4rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem"
      },
      "@media (min-width: 1024px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, flyoutMenuNavigation.alternative.map(item => __cssprop(Menu.Item, {
    key: item.label
  }, __cssprop("div", {
    key: item.label,
    css: {
      "display": "flow-root"
    }
  }, __cssprop("a", {
    onClick: item.onClick,
    "aria-hidden": true,
    css: {
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms",
      "margin": "-0.8rem",
      "padding": "0.8rem",
      "display": "flex",
      "alignItems": "center",
      "borderRadius": "0.6rem",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      "cursor": "pointer"
    }
  }, item.icon && __cssprop(item.icon, {
    css: {
      "flexShrink": "0",
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "marginLeft": "1.2rem"
    }
  }, item.label))))), __cssprop("span", {
    css: {
      "marginLeft": "1.2rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "letterSpacing": "0.025em",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "v.", appVersion))))))));
};

const TopNav = ({
  showSearchBar,
  topBarNavigation,
  onMobileMenuClick,
  flyoutMenuNavigation,
  appVersion
}) => {
  const notificationsEnabled = useFeatureToggle("userNotifications");
  const user = useSelector(Selectors.session.getUser);
  return __cssprop("header", {
    css: {
      "flexShrink": "0",
      "position": "relative",
      "height": "6.4rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "display": "flex",
      "alignItems": "center"
    }
  }, TopNavLogo, MenuButtonArea(onMobileMenuClick), __cssprop("div", {
    css: {
      "display": "none",
      "position": "relative",
      "@media (min-width: 768px)": {
        "minWidth": "0px",
        "flex": "1 1 0%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between"
      }
    }
  }, searchBar(showSearchBar), __cssprop("div", {
    css: {
      "marginLeft": "4rem",
      "paddingRight": "1.6rem",
      "flexShrink": "0",
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(3.2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, navItems(topBarNavigation), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(3.2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, notificationsEnabled && notificationsButton, menu(flyoutMenuNavigation, user, appVersion)))));
};

const MobileNav = ({
  sideBarNavigation,
  topBarNavigation,
  isOpened,
  onClose,
  flyoutMenuNavigation
}) => {
  const flyoutNavItems = [...flyoutMenuNavigation.main, ...flyoutMenuNavigation.alternative];

  const handleClick = onClick => {
    onClick();
    onClose();
  };

  return __cssprop(Transition.Root, {
    show: isOpened,
    as: Fragment
  }, __cssprop(Dialog, {
    as: "div",
    static: true,
    css: {
      "position": "fixed",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "zIndex": "40",
      "@media (min-width: 768px)": {
        "display": "none"
      }
    },
    open: isOpened,
    onClose: onClose
  }, __cssprop(Transition.Child, {
    as: Fragment,
    enter: css({
      "transitionProperty": "opacity",
      "transitionTimingFunction": "linear",
      "transitionDuration": "300ms"
    }),
    enterFrom: css({
      "opacity": "0"
    }),
    enterTo: css({
      "opacity": "1"
    }),
    leave: css({
      "transitionProperty": "opacity",
      "transitionTimingFunction": "linear",
      "transitionDuration": "300ms"
    }),
    leaveFrom: css({
      "opacity": "1"
    }),
    leaveTo: css({
      "opacity": "0"
    })
  }, __cssprop(Dialog.Overlay, {
    css: {
      "display": "none",
      "@media (min-width: 640px)": {
        "display": "block",
        "position": "fixed",
        "top": "0px",
        "right": "0px",
        "bottom": "0px",
        "left": "0px",
        "--tw-bg-opacity": "0.75",
        "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))"
      }
    }
  })), __cssprop(Transition.Child, {
    as: Fragment,
    enter: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
      "transitionDuration": "150ms",
      "@media (min-width: 640px)": {
        "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
        "transitionDuration": "300ms"
      }
    }),
    enterFrom: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1.1",
      "--tw-scale-y": "1.1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0",
      "@media (min-width: 640px)": {
        "--tw-translate-x": "100%",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "opacity": "1"
      }
    }),
    enterTo: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1",
      "@media (min-width: 640px)": {
        "--tw-translate-x": "0px",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "opacity": "1"
      }
    }),
    leave: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "150ms",
      "@media (min-width: 640px)": {
        "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
        "transitionDuration": "300ms"
      }
    }),
    leaveFrom: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1",
      "@media (min-width: 640px)": {
        "--tw-translate-x": "0px",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "opacity": "1"
      }
    }),
    leaveTo: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1.1",
      "--tw-scale-y": "1.1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0",
      "@media (min-width: 640px)": {
        "--tw-translate-x": "100%",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "opacity": "1"
      }
    })
  }, __cssprop("nav", {
    css: {
      "position": "fixed",
      "zIndex": "40",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "height": "100%",
      "width": "100%",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "@media (min-width: 640px)": {
        "top": "0px",
        "bottom": "0px",
        "left": "auto",
        "right": "0px",
        "maxWidth": "38.4rem",
        "width": "100%",
        "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
      }
    },
    "aria-label": "Global"
  }, __cssprop("div", {
    css: {
      "height": "6.4rem",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, __cssprop("a", {
    href: "#"
  }, __cssprop("img", {
    css: {
      "display": "block",
      "height": "4rem",
      "width": "auto"
    },
    src: "/img/icon.svg",
    alt: "SimpleLoyer"
  })), __cssprop("button", {
    type: "button",
    css: {
      "marginRight": "-0.8rem",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "padding": "0.8rem",
      "borderRadius": "0.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-inset": "inset",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(79, 70, 229, var(--tw-ring-opacity))"
      }
    },
    onClick: onClose
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Close main menu"), __cssprop(HiX, {
    css: {
      "display": "block",
      "height": "2.4rem",
      "width": "2.4rem"
    },
    "aria-hidden": "true"
  }))), __cssprop("div", {
    css: {
      "maxWidth": "89.6rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingTop": "1.2rem",
      "paddingBottom": "1.2rem",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem"
      }
    }
  }, sideBarNavigation.map(item => __cssprop(Fragment, {
    key: item.label
  }, __cssprop("a", {
    onClick: () => handleClick(item.onClick),
    "aria-hidden": true,
    css: {
      "display": "block",
      "borderRadius": "0.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      }
    }
  }, item.label)))), __cssprop("div", {
    css: {
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.2rem"
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "89.6rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem"
      }
    }
  }, topBarNavigation.map(item => __cssprop(Fragment, {
    key: item.label
  }, __cssprop("a", {
    onClick: () => handleClick(item.onClick),
    "aria-hidden": true,
    css: {
      "display": "block",
      "borderRadius": "0.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      }
    }
  }, item.label))))), __cssprop("div", {
    css: {
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.2rem"
    }
  }, __cssprop("div", {
    css: {
      "marginTop": "1.2rem",
      "maxWidth": "89.6rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem"
      }
    }
  }, flyoutNavItems.map(item => __cssprop("a", {
    key: item.label,
    "aria-hidden": true,
    onClick: () => handleClick(item.onClick),
    css: {
      "display": "block",
      "borderRadius": "0.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }
  }, item.label))))))));
};

const SideNavItem = _styled.a(({
  selected
}) => [{
  "width": "100%",
  "padding": "1.2rem",
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "fontSize": "1.2rem",
  "lineHeight": "1.6rem",
  "fontWeight": "500",
  "cursor": "pointer"
}, selected ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  "borderLeftWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
} : {
  "--tw-text-opacity": "1",
  "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))"
  }
}]);

const WarnIcon = _styled.span(({
  selected
}) => [{
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(153, 27, 27, var(--tw-bg-opacity))",
  "position": "absolute",
  "borderRadius": "9999px"
}, _css`
    font-size: 1rem;
    padding: 0.6rem;
    margin: 0;
    line-height: 1rem;
    top: 0;
    left: 15px;
  `]);

const DesktopNav = ({
  sideBarNavigation
}) => {
  return __cssprop("div", {
    css: {
      "width": "11.2rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))",
      "overflowY": "auto",
      "display": "none",
      "justifyContent": "space-between",
      "flexDirection": "column",
      "@media (min-width: 768px)": {
        "display": "flex"
      }
    }
  }, __cssprop("div", {
    css: {
      "width": "100%",
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "center"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "width": "100%"
    }
  }, sideBarNavigation.map(item => __cssprop(SideNavItem, {
    key: item.label,
    onClick: item.onClick,
    "aria-hidden": "true",
    selected: item.active,
    "aria-current": item.active ? "page" : undefined
  }, __cssprop("div", {
    css: {
      "position": "relative"
    }
  }, __cssprop(item.icon, {
    css: {
      "height": "2.4rem",
      "width": "2.4rem"
    },
    "aria-hidden": "true"
  }), !!item.warn && item.warn !== 0 && __cssprop(WarnIcon, {
    selected: item.active
  }, item.warn > 99 ? "99+" : item.warn)), __cssprop("span", {
    css: {
      "marginTop": "0.8rem",
      "textAlign": "center"
    },
    style: {
      fontSize: "1.1rem"
    }
  }, item.label))))));
};

export const AppFrame = ({
  children,
  showSearchBar,
  sideBarNavigation,
  topBarNavigation,
  appVersion,
  flyoutMenuNavigation
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return __cssprop(React.Fragment, null, __cssprop(_GlobalStyles, null), __cssprop("div", {
    css: {
      "height": "100vh",
      "overflow": "hidden",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
      "display": "flex",
      "flexDirection": "column"
    }
  }, __cssprop(TopNav, {
    showSearchBar: showSearchBar,
    topBarNavigation: topBarNavigation,
    onMobileMenuClick: () => setMobileMenuOpen(true),
    appVersion: appVersion,
    flyoutMenuNavigation: flyoutMenuNavigation
  }), __cssprop(MobileNav, {
    sideBarNavigation: sideBarNavigation,
    topBarNavigation: topBarNavigation,
    isOpened: mobileMenuOpen,
    onClose: () => setMobileMenuOpen(false),
    flyoutMenuNavigation: flyoutMenuNavigation
  }), __cssprop("div", {
    css: {
      "minHeight": "0px",
      "flex": "1 1 0%",
      "display": "flex",
      "overflow": "hidden"
    }
  }, __cssprop(DesktopNav, {
    sideBarNavigation: sideBarNavigation
  }), __cssprop("main", {
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%",
      "@media (min-width: 1024px)": {
        "display": "flex"
      }
    }
  }, children))));
};