import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { createSelector } from "../../../../../_snowpack/pkg/reselect.js";

const getLease = state => state?.lease;

const getId = state => getLease(state).id;

const getTenants = state => getLease(state).tenants;

const getNotes = state => getLease(state).notes;

const getFilteredNotes = state => getLease(state).notes.filter(note => note.kind !== "removed");

const getFiles = state => getLease(state).files;

const getAmenities = state => getLease(state).amenities;

const getLeaseId = state => getLease(state).id;

const getBillDay = state => getLease(state).billDay;

const getDownPaymentAmount = state => getLease(state).downPaymentAmount;

const getStartDate = state => DateTime.fromISO(getLease(state).startDate);

const getCreatedAt = state => DateTime.fromISO(getLease(state).createdAt);

const getEndDate = state => DateTime.fromISO(getLease(state).endDate);

const isLocked = state => getLease(state).isLocked;

const getDuration = state => getLease(state)?.duration;

const getMonthlyPrice = state => getLease(state).monthlyPrice;

const getCancelled = state => getLease(state).cancelled;

const getDelivered = state => !!getLease(state).deliveryFinalizedAt;

const getUnit = state => getLease(state).unit;

const getUnitId = state => getUnit(state)?.id;

const getUnitCurrentLease = state => getUnit(state)?.currentLease;

const getBuilding = state => getLease(state)?.building;

const getBuildingId = state => getBuilding(state)?.id;

const getTickets = state => getLease(state).tickets.items;

const getFullAddress = createSelector(getUnit, unit => {
  if (!unit) {
    return "";
  }

  if (!unit.subAddress && unit.buildingAddress) {
    return `${unit.buildingAddress}`;
  }

  if (unit.subAddress && unit.buildingAddress) {
    return `${unit.subAddress} - ${unit.buildingAddress}`;
  }

  return undefined;
});
const isExistingLease = createSelector(getId, id => {
  return !!id && id !== "";
});
const getPaymentMethod = createSelector(getTenants, tenants => {
  return tenants.find(t => t.isPayee)?.paymentMethod;
});
const hasCompletedDetails = createSelector(getUnit, getTenants, (unit, tenants) => {
  return !!unit && tenants.length > 0;
});
const getUnitLeasesInTheFuture = createSelector(getUnit, unit => {
  const leases = unit?.leases?.items?.filter(l => DateTime.now() <= DateTime.fromISO(l.endDate)) || [];
  return leases;
});
export default {
  getLease,
  getUnit,
  getUnitId,
  getId,
  getTenants,
  getNotes,
  getFiles,
  getAmenities,
  getLeaseId,
  getBillDay,
  getStartDate,
  getEndDate,
  getMonthlyPrice,
  getBuilding,
  getBuildingId,
  getFullAddress,
  getDuration,
  getDelivered,
  isExistingLease,
  getPaymentMethod,
  hasCompletedDetails,
  isLocked,
  getCreatedAt,
  getUnitCurrentLease,
  getUnitLeasesInTheFuture,
  getFilteredNotes,
  getCancelled,
  getDownPaymentAmount,
  getTickets
};