/* eslint-disable import/no-cycle */
import { TYPES } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { all, getContext, put, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { CreateListingDocument, ListingDetailsDocument, SetListingStatusDocument, UpdateListingDocument } from "../../../graphql/documents.js";
import { ApplicationActionsTypes } from "../Application/types.js";
import { uploadDocuments } from "../Files/sagas.js";
import StatusActions from "../Status/actions.js";
import { fromGetListing, fromListingStateToCreateInput, fromListingStateToInput } from "./mapper.js";
import { getListing } from "./selectors.js";
import { ListingActionsTypes } from "./types.js";

// =========== REDUCERS =========== //
// =========== SAGAS =========== //
function* getListingData(listing, isCreate) {
  const galleryItems = yield uploadDocuments(listing.gallery, RESOURCES.Listing, TYPES.Images);
  const featuredItem = yield uploadDocuments([listing.thumbnail], RESOURCES.Listing, TYPES.Images);

  if (isCreate) {
    return fromListingStateToCreateInput({ ...listing,
      gallery: galleryItems,
      thumbnail: featuredItem[0]
    });
  }

  return fromListingStateToInput({ ...listing,
    gallery: galleryItems,
    thumbnail: featuredItem[0]
  });
}

function* updateExistingListing(id, listing) {
  yield put(StatusActions.setResourceToSubmit("listing"));
  const client = yield getContext("client");
  const listingData = yield getListingData(listing, false);

  try {
    const query = client.query({
      query: UpdateListingDocument,
      fetchPolicy: "no-cache",
      variables: {
        listing: {
          id,
          listing: listingData
        }
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("listing", "rental-publish-error"));
      return;
    }

    yield put({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
    yield put(StatusActions.setResourceToSuccess("listing", "rental-modify-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("listing", "rental-publish-error"));
  }
}

function* createNewListing(listing) {
  yield put(StatusActions.setResourceToSubmit("listing"));
  const client = yield getContext("client");
  const listingData = yield getListingData(listing, true);

  try {
    const query = client.query({
      query: CreateListingDocument,
      fetchPolicy: "no-cache",
      variables: {
        listing: listingData
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("listing", "rental-publish-error"));
      return;
    }

    yield put({
      type: ListingActionsTypes.LISTING_SET_FIELD,
      payload: { ...data.addListing
      }
    });
    yield put({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
    yield put(StatusActions.setResourceToSuccess("listing", "rental-publish-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("listing", "rental-publish-error"));
  }
} // the consent came from the data


function* onSubmitForm(data) {
  const listing = yield select(getListing); // add consent in the listing object

  listing.consents = data.payload.consent.map(c => {
    if (c.cid === "is_non_smoking") {
      return {
        consentType: "is_non_smoking",
        consentValue: "true"
      };
    }

    return {
      consentType: c.cid,
      consentValue: c.value
    };
  });

  if (listing.id) {
    yield updateExistingListing(listing.id, listing);
  } else {
    yield createNewListing(listing);
  }
}

function* onPublishSubmit($data) {
  const client = yield getContext("client");
  const t = yield getContext("t");

  if (!$data.payload.id) {
    throw new Error(t("rental-ad-not-created"));
  }

  try {
    const query = client.query({
      query: SetListingStatusDocument,
      fetchPolicy: "no-cache",
      variables: {
        id: $data.payload.id,
        publishStatus: $data.payload.status
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("listing", "rental-publish-error"));
      return;
    }

    yield put({
      type: ListingActionsTypes.LISTING_SET_FIELD,
      payload: { ...data.setPublishStatus
      }
    });
    yield put(StatusActions.setResourceToSuccess("listing", data.setPublishStatus.published ? "rental-publish-success" : "rental-unpublish-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("listing", "rental-publish-error"));
  }
}

function* onInitializeListing($data) {
  const client = yield getContext("client");
  const {
    payload
  } = $data;

  switch (payload.kind) {
    case "WithContext":
      yield put({
        type: ListingActionsTypes.LISTING_SET_UNIT_FIELD,
        payload: payload.property
      });

      if (payload.activeLeaseEndDate) {
        yield put({
          type: ListingActionsTypes.LISTING_SET_FIELD,
          payload: {
            availability: payload.activeLeaseEndDate
          }
        });
      }

      break;

    case "WithListingId":
      {
        yield put(StatusActions.setResourceToLoading("listing"));
        const query = client.query({
          query: ListingDetailsDocument,
          fetchPolicy: "no-cache",
          variables: {
            listingId: payload.listingId
          }
        });
        const {
          data,
          error
        } = yield query;

        if (error) {
          yield put(StatusActions.setResourceToError("listing", "generic-fetch-error-message"));
        }

        yield put({
          type: ListingActionsTypes.LISTING_SET_FIELD,
          payload: { ...fromGetListing(data)
          }
        });
        yield put(StatusActions.setResourceToIdle("listing"));
        break;
      }

    case "NoContext":
    default:
      // State is already changed from reducer.
      break;
  }
}

export default {
  *executers() {
    yield all([yield takeLatest(ListingActionsTypes.LISTING_INITIALIZE, onInitializeListing), yield takeLatest(ListingActionsTypes.LISTING_SAVE_FORM, onSubmitForm), yield takeLatest(ListingActionsTypes.LISTING_PUBLISH, onPublishSubmit)]);
  }

};