export const consentKeyValue = t => [{
  label: t("consent-value-pet"),
  value: "maximum_number_of_pets"
}, {
  label: t("consent-value-tenant"),
  value: "maximum_number_of_tenants"
}, {
  label: t("consent-value-salary"),
  value: "minimum_salary"
}, {
  label: t("consent-value-parking"),
  value: "parking_spots_included_in_rent"
}, {
  label: t("consent-value-smoking"),
  value: "is_non_smoking"
}];
export const consentKeyValueObjSelector = t => ({
  maximum_number_of_pets: t("consent-value-pet"),
  maximum_number_of_tenants: t("consent-value-tenant"),
  minimum_salary: t("consent-value-salary"),
  parking_spots_included_in_rent: t("consent-value-parking"),
  is_non_smoking: t("consent-value-smoking")
});
export const consentKeyValueObjInput = t => ({
  maximum_number_of_pets: t("consent-value-pet-input"),
  maximum_number_of_tenants: t("consent-value-tenant-input"),
  minimum_salary: t("consent-value-salary-input"),
  parking_spots_included_in_rent: t("consent-value-parking-input"),
  is_non_smoking: t("consent-value-smoking-input")
});