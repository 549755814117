import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export let PaymentStatus;

(function (PaymentStatus) {
  PaymentStatus["Tentative"] = "Tentative";
  PaymentStatus["Erroneous"] = "Erroneous";
  PaymentStatus["Processing"] = "Processing";
  PaymentStatus["Completed"] = "Completed";
})(PaymentStatus || (PaymentStatus = {}));

export let PaymentResourceType;

(function (PaymentResourceType) {
  PaymentResourceType["Organization"] = "Organization";
  PaymentResourceType["User"] = "User";
  PaymentResourceType["Tenant"] = "Tenant";
})(PaymentResourceType || (PaymentResourceType = {}));

export const initialState = {
  amountDue: 0,
  paymentProcessingDate: DateTime.now(),
  paymentStatus: PaymentStatus.Erroneous,
  serverPayment: {
    kind: "NotAsked"
  }
};
// =========== ACTIONS CREATORS =========== //
export let PaymentActionsTypes;

(function (PaymentActionsTypes) {
  PaymentActionsTypes["PAYMENT_INITIALIZE"] = "PAYMENT_INITIALIZE";
  PaymentActionsTypes["PAYMENT_SUBMIT"] = "PAYMENT_SUBMIT";
  PaymentActionsTypes["PAYMENT_SET_FIELD"] = "PAYMENT_SET_FIELD";
  PaymentActionsTypes["PAYMENT_SET_SERVER_PAYMENT_STATUS"] = "PAYMENT_SET_SERVER_PAYMENT_STATUS";
})(PaymentActionsTypes || (PaymentActionsTypes = {}));