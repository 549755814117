import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const initialState = {
  id: null,
  status: "Unpublished",
  description: {
    fr: "",
    en: ""
  },
  price: 0,
  published: null,
  availability: DateTime.now(),
  gallery: [],
  thumbnail: null,
  responsible: null,
  unit: null,
  isCreditCheckRequired: true,
  consents: [],
  isUrgent: false,
  note: null
};
// =========== ACTIONS CREATORS =========== //
export let ListingActionsTypes;

(function (ListingActionsTypes) {
  ListingActionsTypes["LISTING_SET_FIELD"] = "LISTING_SET_FIELD";
  ListingActionsTypes["LISTING_SET_UNIT_FIELD"] = "LISTING_SET_UNIT_FIELD";
  ListingActionsTypes["LISTING_INITIALIZE"] = "LISTING_INITIALIZE";
  ListingActionsTypes["LISTING_ADD_GALLERY_ITEMS"] = "LISTING_ADD_GALLERY_ITEMS";
  ListingActionsTypes["LISTING_REMOVE_GALLERY_ITEMS"] = "LISTING_REMOVE_GALLERY_ITEMS";
  ListingActionsTypes["LISTING_SET_FEATURED_IMAGE"] = "LISTING_SET_FEATURED_IMAGE";
  ListingActionsTypes["LISTING_UNSET_FEATURED_IMAGE"] = "LISTING_UNSET_FEATURED_IMAGE";
  ListingActionsTypes["LISTING_SAVE_FORM"] = "LISTING_SAVE_FORM";
  ListingActionsTypes["LISTING_PUBLISH"] = "LISTING_PUBLISH";
})(ListingActionsTypes || (ListingActionsTypes = {}));