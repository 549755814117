export const initialState = {
  name: null,
  firstName: null,
  lastName: null,
  currentLeases: undefined,
  id: undefined,
  email: "",
  creationDate: undefined,
  leases: {
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    items: []
  },
  tickets: {
    items: []
  },
  archivalStatus: false,
  language: "EN"
};
// =========== ACTIONS CREATORS =========== //
export let TenantActionsTypes;

(function (TenantActionsTypes) {
  TenantActionsTypes["TENANT_INITIALIZE"] = "TENANT_INITIALIZE";
  TenantActionsTypes["TENANT_SET_DATA"] = "TENANT_SET_DATA";
  TenantActionsTypes["TENANT_SUBMIT"] = "TENANT_SUBMIT";
  TenantActionsTypes["TENANT_SET_FIELD"] = "TENANT_SET_FIELD";
  TenantActionsTypes["TENANT_SET_SERVER_TENANT_STATUS"] = "TENANT_SET_SERVER_TENANT_STATUS";
  TenantActionsTypes["TENANT_ADD_NOTES_FORMLESS"] = "TENANT_ADD_NOTES_FORMLESS";
  TenantActionsTypes["TENANT_REMOVE_NOTES_FORMLESS"] = "TENANT_REMOVE_NOTES_FORMLESS";
  TenantActionsTypes["TENANT_ADD_NOTES"] = "TENANT_ADD_NOTES";
  TenantActionsTypes["TENANT_REMOVE_NOTES"] = "TENANT_REMOVE_NOTES";
})(TenantActionsTypes || (TenantActionsTypes = {}));