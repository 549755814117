function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "../../../_snowpack/pkg/react.js";
import { Redirect, Route } from "../../../_snowpack/pkg/react-router.js";
import { useSelector, Selectors } from "../hooks/useRedux.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const PublicRoute = ({
  redirect = true,
  component: Component,
  ...rest
}) => {
  const isLoggedIn = useSelector(Selectors.session.getIsLoggedIn);

  const getComponent = props => isLoggedIn && redirect ? __cssprop(Redirect, {
    to: "/properties"
  }) : __cssprop(Component, props);

  return __cssprop(Route, _extends({}, rest, {
    component: getComponent
  }));
};

export default PublicRoute;