import _styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import { TaskInvoicePanel } from "../../Panels/TaskInvoicePanel.js";
import { TaskPanel } from "../../Panels/TaskPanel.js";
import { Badge } from "../../Tailwind/Badge.js";
import { DropDownActions } from "../../Tailwind/DropDownActions.js";
import { ProtectedActions } from "../../Tailwind/ProtectedComponents.js";
import useI18n from "../../../hooks/useI18n.js";
import { useAction } from "../../../hooks/useRedux.js";
import { Selectors } from "../../../store/index.js";
import { ApplicationActionsTypes } from "../../../store/artifacts/Application/types.js";
import { TaskActionTypes } from "../../../store/artifacts/Tasks/types.js";
import { TicketActionTypes } from "../../../store/artifacts/Tickets/types.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useState } from "../../../../../_snowpack/pkg/react.js";
import { HiUserCircle } from "../../../../../_snowpack/pkg/react-icons/hi.js";
import { IoCheckmarkCircle } from "../../../../../_snowpack/pkg/react-icons/io5.js";
import { useSelector } from "../../../../../_snowpack/pkg/react-redux.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const TaskListItem = ({
  assignedTo,
  completedDate,
  dueDate,
  id,
  title,
  billing,
  ticketId,
  billingDate
}) => {
  const {
    t
  } = useI18n();
  const role = useSelector(Selectors.session.getUserRole);
  const {
    dispatch
  } = useAction();
  const [isTaskPanelOpen, setIsTaskPanelOpen] = useState(false);
  const [isTaskInvoicePanelOpen, setIsTaskInvoicePanelOpen] = useState(false);

  const handleClose = () => {
    if (!completedDate) {
      dispatch({
        type: TaskActionTypes.TASK_CLOSE,
        payload: {
          taskId: id
        }
      });
    }
  };

  const handleOpenInvoiceDrawer = () => {
    dispatch({
      type: TaskActionTypes.TASK_SET_FIELD,
      payload: {
        id
      }
    });
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: "task-invoice"
    });
  };

  const refetch = () => {
    dispatch({
      type: TicketActionTypes.TICKET_INITIALIZE,
      payload: {
        kind: "WithTicketId",
        ticketId
      }
    });
    setIsTaskPanelOpen(false);
  };

  const getActions = () => {
    const actions = [ProtectedActions(role, [{
      content: t("task-invoice-title"),
      destructive: false,
      onAction: () => setIsTaskInvoicePanelOpen(true),
      permission: {
        kind: "Lease",
        permission: "view:lease"
      }
    }, {
      content: t("task-edit-section-title"),
      destructive: false,
      onAction: () => setIsTaskPanelOpen(true),
      permission: {
        kind: "Lease",
        permission: "view:lease"
      },
      condition: !completedDate // task information is same on form and page so no need to view task after closed

    }, {
      content: t("task-edit-close-task"),
      destructive: true,
      onAction: handleClose,
      condition: !completedDate,
      permission: {
        kind: "Lease",
        permission: "view:lease"
      }
    }])];
    return __cssprop(DropDownActions, {
      content: "open",
      dots: true,
      actions: actions
    });
  };

  return __cssprop("div", {
    css: {
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "borderRadius": "0.2rem",
      "borderWidth": "1px",
      "paddingLeft": "2rem",
      "paddingRight": "2rem",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
    }
  }, __cssprop(TaskPanel, {
    refetch: refetch,
    taskId: id,
    isEdit: true,
    isOpen: isTaskPanelOpen,
    handleOnClose: () => setIsTaskPanelOpen(false)
  }), __cssprop(TaskInvoicePanel, {
    refetch: refetch,
    taskId: id,
    isOpen: isTaskInvoicePanelOpen,
    handleOnClose: () => setIsTaskInvoicePanelOpen(false)
  }), __cssprop("div", {
    css: {
      "justifyContent": "space-between",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))",
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "@media (min-width: 768px)": {
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(0px * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(0px * var(--tw-space-y-reverse))"
        }
      }
    }
  }, assignedTo ? __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "flex-start",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1.2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "@media (min-width: 768px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(1.6rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "width": "3.6rem",
      "height": "3.6rem",
      "flexShrink": "1",
      "marginTop": "0.4rem"
    }
  }, __cssprop(HiUserCircle, {
    css: {
      "height": "3.6rem",
      "width": "3.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })), __cssprop("div", null, __cssprop("div", {
    css: {
      "display": "inline-flex",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))"
    }
  }, title), __cssprop("div", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem"
    }
  }, t("task-assign-to", {
    assigned: assignedTo
  })))) : __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "flex-start",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1.2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "padding": "0.8rem",
      "@media (min-width: 768px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(1.6rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "inline-flex",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))"
    }
  }, title)), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1.6rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "paddingLeft": "4rem",
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, !completedDate && dueDate && __cssprop("div", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "fontStyle": "italic",
      "whiteSpace": "nowrap"
    }
  }, t("task-due", {
    date: DateTime.fromISO(dueDate).toFormat("dd LLL yyyy")
  })), !!completedDate && __cssprop("div", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "fontStyle": "italic",
      "whiteSpace": "nowrap"
    }
  }, t("tickets-form-completed-date"), " ", DateTime.fromISO(completedDate).toFormat("dd LLL yyyy")), billing && __cssprop(Badge, {
    warning: true
  }, t("task-billed-for", {
    bill: billing,
    bill_date: billingDate
  })), completedDate && __cssprop("div", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(209, 213, 219, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      },
      "position": "relative"
    }
  }, __cssprop(IoCheckmarkCircle, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(52, 211, 153, var(--tw-text-opacity))"
    }
  })), __cssprop("div", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(209, 213, 219, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      },
      "position": "relative"
    }
  }, getActions()))));
};

export const StyledSpan = _styled.span(({
  active
}) => [{
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "0",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "pointerEvents": "none",
  "display": "inline-block",
  "height": "2rem",
  "width": "2rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)"
}, active ? {
  "--tw-translate-x": "2rem"
} : {
  "--tw-translate-x": "0px"
}]);

const TicketInfoMainTasks = ({
  task,
  ticketId
}) => {
  return __cssprop(TaskListItem, {
    assignedTo: task.assignee?.name,
    completedDate: task.closedAt ? DateTime.fromISO(task.closedAt).toUTC() : null,
    dueDate: task.dueDate ? DateTime.fromISO(task.dueDate).toUTC() : null,
    id: task.id,
    title: task.title,
    billing: task.invoice?.amount,
    billingDate: task.invoice?.date ? DateTime.fromISO(task.invoice?.date).toFormat("dd LLL yyyy") : null // showing invoice date because calendar component on form is broken
    ,
    ticketId: ticketId
  });
};

export default TicketInfoMainTasks;