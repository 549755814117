import { Link } from "../../../components/Tailwind/Link.js";
import { PaginatedDataTable } from "../../../components/Tailwind/PaginatedDataTable.js";
import { withBreadcrumb } from "../../../context/Breadcrumbs/withBreadcrumbs.js";
import { GetBuildingsDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { useAction } from "../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../store/artifacts/Building/types.js";
import * as React from "../../../../../_snowpack/pkg/react.js";
import { useEffect } from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const Buildings = withBreadcrumb(({
  setCrumb,
  history
}) => {
  const {
    t
  } = useI18n();
  useEffect(() => {
    setCrumb({
      title: t("buildings"),
      path: "/buildings",
      level: 0
    });
  }, []);
  return __cssprop(PropertiesList, {
    history: history,
    setCrumb: setCrumb
  });
});
const url = "/buildings/";
export const PropertiesList = withBreadcrumb(({
  setCrumb,
  history
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();

  const renderBody = item => {
    const unitText = `${item.statistics.totalUnits} ${t("units")}`;
    return {
      buildings: __cssprop(Link, {
        text: `${item.civicNumber} ${item.streetName}`,
        action: {
          kind: "ClickAction",
          event: () => {
            dispatch({
              type: BuildingActionsTypes.BUILDING_INITIALIZE,
              payload: {
                kind: "WithBuildingId",
                buildingId: item.id
              }
            });
            history.push(`${url}${item.id}`);
          }
        }
      }),
      sector: item.sector,
      city: item.city,
      units: unitText
    };
  };

  const columns = [{
    Header: t("building-form-address"),
    accessor: "buildings",
    disableSortBy: true
  }, {
    Header: t("building-form-sector"),
    accessor: "sector",
    disableSortBy: true
  }, {
    Header: t("building-form-city"),
    accessor: "city",
    disableSortBy: true
  }, {
    Header: t("buildings-units"),
    accessor: "units",
    disableSortBy: true
  }];
  return __cssprop(PaginatedDataTable, {
    title: t("buildings"),
    query: GetBuildingsDocument,
    headers: columns,
    renderItem: renderBody,
    resourceName: {
      plural: t("buildings"),
      singular: t("building")
    }
  });
});
export default Buildings;