import React, { useEffect, useState } from "../../../../../_snowpack/pkg/react.js";
import "../../../../../_snowpack/pkg/@fullcalendar/react/dist/vdom.js";
import FullCalendar from "../../../../../_snowpack/pkg/@fullcalendar/react.js";
import dayGridPlugin from "../../../../../_snowpack/pkg/@fullcalendar/daygrid.js";
import interactionPlugin from "../../../../../_snowpack/pkg/@fullcalendar/interaction.js";
import timeGridPlugin from "../../../../../_snowpack/pkg/@fullcalendar/timegrid.js";
import rrulePlugin from "../../../../../_snowpack/pkg/@fullcalendar/rrule.js";
import "../../../../../_snowpack/pkg/@fullcalendar/daygrid/main.css";
import Select from "../../../../../_snowpack/pkg/react-select.js";
import useI18n from "../../../hooks/useI18n.js";
import { useAction, useSelector } from "../../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../../store/artifacts/Application/types.js";
import { CalendarActionTypes } from "../../../store/artifacts/Calendar/types.js";
import { Selectors } from "../../../store/index.js";
import { EventActionTypes } from "../../../store/artifacts/Events/types.js";
import { TICKET_SIDEBAR_SELECTORS } from "../../../utils/ticket.js";
import { SimpleSelector } from "../../../components/TicketInfo/TicketInfoSidebar/SimpleSelector.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { FilterButton } from "../../../components/Tailwind/FilterComponents.js";
import { getCalendarDate } from "../../../utils/event.js";
import { UserSelector } from "../../../components/Selectors/UserSelector.js";
import { renderCalendarEventContent } from "./calendarRenderer.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
const typeSelectorValues = ["space", "project", "tenant"];
const filterRequestFields = {
  space: "spaceIds",
  project: "projectIds",
  tenant: "tenantIds"
};

const taskStatusOptions = t => [{
  value: "withClosed",
  label: t("tickets-status-badge-closed")
}];

const initialResourceState = {
  tenantIds: [],
  projectIds: [],
  spaceIds: []
};
const initialStatusState = {};

const CalendarComponent = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const [selectedAssignee, setSelectedAssignee] = useState();
  const drawerState = useSelector(Selectors.app.getDrawerState);
  const events = useSelector(Selectors.calendar.getFormattedEvents);
  const [resource, setResource] = useState(initialResourceState);
  const [status, setStatus] = useState(initialStatusState);
  const [dateRange, setDateRange] = useState({
    startRangeDate: null,
    endRangeDate: null
  });
  const [isFiltersActive, setIsFiltersActive] = useState(false);

  const handleStatusChange = e => {
    const withClosed = e.find(item => item.value === "withClosed");
    setStatus({
      withClosed: Boolean(withClosed)
    });
  };

  const handleCreateButtonClick = e => {
    const {
      date
    } = e;
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        RRule: getCalendarDate(date)
      }
    });
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: "event-create"
    });
  };

  const handleSelectResourceFilter = (e, id) => {
    setResource(prev => ({ ...prev,
      [id]: e.map(element => element.value)
    }));
  };

  const handleClearFilters = () => {
    setResource(initialResourceState);
    setStatus(initialStatusState);
    setIsFiltersActive(false);
  };

  const handleFilterButtonClick = () => {
    if (isFiltersActive) {
      handleClearFilters();
    } else {
      setIsFiltersActive(prev => !prev);
    }
  };

  const handleEventChange = event => {
    const isEventRangeInsideCurrentRange = event.startStr > dateRange.startRangeDate && event.startStr < dateRange.endRangeDate || dateRange.startRangeDate > event.startStr && dateRange.startRangeDate < event.endStr;
    const shouldSetDateRange = !isEventRangeInsideCurrentRange || dateRange.startRangeDate === null;

    if (shouldSetDateRange) {
      setDateRange({
        startRangeDate: event.startStr,
        endRangeDate: event.endStr
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: CalendarActionTypes.CALENDAR_INITIALIZE,
      payload: {
        selectedAssignee,
        resource,
        ...status,
        ...dateRange
      }
    });
  }, [resource, status, dateRange, drawerState, selectedAssignee]);
  return __cssprop("div", null, __cssprop("div", {
    css: {
      "marginBottom": "2.4rem"
    }
  }, __cssprop(Card, {
    title: t("calendar-filters"),
    CustomAction: __cssprop(FilterButton, {
      onClear: handleClearFilters,
      numberFilters: 0,
      onClick: handleFilterButtonClick
    }),
    sectionned: true
  }, isFiltersActive && __cssprop("div", {
    css: {
      "width": "100%",
      "marginBottom": "4rem",
      "zIndex": "50",
      "display": "flex",
      "justifyContent": "flex-end",
      "gap": "1.6rem",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "width": "16.666667%"
    }
  }, __cssprop(UserSelector, {
    selected: selectedAssignee,
    onChange: setSelectedAssignee,
    placeholder: t("tickets-form-assigned-to"),
    roles: []
  })), __cssprop("div", {
    css: {
      "width": "16.666667%"
    }
  }, __cssprop(Select, {
    options: taskStatusOptions(t),
    placeholder: t("task-status"),
    isMulti: true,
    onChange: handleStatusChange
  })), typeSelectorValues.map(selector => {
    return __cssprop("div", {
      css: {
        "width": "16.666667%"
      }
    }, __cssprop(SimpleSelector, {
      key: TICKET_SIDEBAR_SELECTORS(t)[selector].name,
      labelPattern: TICKET_SIDEBAR_SELECTORS(t)[selector].labelPattern,
      query: TICKET_SIDEBAR_SELECTORS(t)[selector].query,
      label: TICKET_SIDEBAR_SELECTORS(t)[selector].label,
      name: TICKET_SIDEBAR_SELECTORS(t)[selector].name,
      isClearable: false,
      extraValues: TICKET_SIDEBAR_SELECTORS(t)[selector].extraValues,
      onChange: e => handleSelectResourceFilter(e, filterRequestFields[selector]),
      isMulti: true
    }));
  })))), __cssprop(FullCalendar, {
    plugins: [rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: "dayGridMonth",
    events: events,
    height: "85vh",
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: "dayGridMonth,dayGridWeek,dayGridDay"
    },
    selectable: true,
    selectMirror: true,
    dateClick: handleCreateButtonClick,
    eventContent: renderCalendarEventContent,
    datesSet: handleEventChange
  }));
};

export default CalendarComponent;