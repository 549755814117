import { useMutation } from "../../../../../../_snowpack/pkg/@apollo/client.js";
import { NumberTextField } from "../../../../components/Tailwind/TextField.js";
import { SetRentalApplicationDownPaymentAmountDocument } from "../../../../graphql/documents.js";
import useI18n from "../../../../hooks/useI18n.js";
import React, { useCallback, useState } from "../../../../../../_snowpack/pkg/react.js";
import { toast } from "../../../../../../_snowpack/pkg/react-toastify.js";
import { ModifyButton } from "./modifyButton.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";
export const DownPayment = props => {
  const {
    t
  } = useI18n();
  const {
    pDPM,
    refetch,
    disabled,
    rentalApplicationId,
    totalDownPaymentAmount
  } = props;
  const [isEditDownPayment, setIsEditDownPayment] = useState(false);
  const [downPaymentAmount, setDownPaymentAmount] = useState(pDPM || 0);
  const [totalDownpayment, setTotalDownPaymentAmount] = useState(totalDownPaymentAmount);
  const [setDownPaymentAmountQuery, {
    loading: setDownPaymentAmountLoading
  }] = useMutation(SetRentalApplicationDownPaymentAmountDocument, {
    fetchPolicy: "no-cache"
  });
  const handleOnSaveDownPaymentAmount = useCallback(async () => {
    try {
      const downPaimentAmountResponse = await setDownPaymentAmountQuery({
        variables: {
          input: {
            rentalApplicationId,
            downPaymentAmount,
            totalDownpayment
          }
        }
      });

      if (downPaimentAmountResponse.errors) {
        toast.warn(`${t("server-error")} ${downPaimentAmountResponse.errors}`);
      }

      if (downPaimentAmountResponse && downPaimentAmountResponse.data) {
        setIsEditDownPayment(!downPaimentAmountResponse.data);
        refetch();
      }
    } catch (e) {
      toast.warn(`${t("server-error")} ${e}`);
    }
  }, [rentalApplicationId, downPaymentAmount, totalDownpayment]);

  if (isEditDownPayment) {
    return __cssprop("div", {
      css: {
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }, __cssprop(NumberTextField, {
      label: t("downpayment"),
      id: "downpayment",
      onChange: d => {
        setDownPaymentAmount(d);
      },
      value: downPaymentAmount,
      placeholder: "800"
    }), __cssprop(NumberTextField, {
      label: t("total-downpayment"),
      id: "totalDownPayment",
      onChange: d => {
        setTotalDownPaymentAmount(d);
      },
      value: totalDownpayment,
      placeholder: "2500"
    }), __cssprop(ModifyButton, {
      disabled: disabled,
      loading: setDownPaymentAmountLoading,
      inEdit: isEditDownPayment,
      setEdit: setIsEditDownPayment,
      handleOnSave: handleOnSaveDownPaymentAmount
    }));
  }

  if (downPaymentAmount || totalDownpayment) {
    return __cssprop(React.Fragment, null, __cssprop("span", {
      css: {
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "fontSize": "1.2rem",
        "lineHeight": "1.6rem",
        "fontWeight": "500"
      }
    }, t("applicant-request-list-down-payment-amount-received"), " : ", pDPM, " / ", totalDownpayment), __cssprop(ModifyButton, {
      disabled: disabled,
      loading: setDownPaymentAmountLoading,
      inEdit: isEditDownPayment,
      setEdit: setIsEditDownPayment,
      handleOnSave: handleOnSaveDownPaymentAmount
    }));
  }

  return __cssprop(React.Fragment, null, __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, t("applicant-request-form-down-payment-amount-empty")), __cssprop(ModifyButton, {
    disabled: disabled,
    loading: setDownPaymentAmountLoading,
    inEdit: isEditDownPayment,
    setEdit: setIsEditDownPayment,
    handleOnSave: handleOnSaveDownPaymentAmount
  }));
};