import { Badge } from "../../../components/Tailwind/Badge.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { DescriptionList } from "../../../components/Tailwind/DescriptionList.js";
import { Link } from "../../../components/Tailwind/Link.js";
import { Notes } from "../../../components/Tailwind/Notes.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { LeaseActionsTypes } from "../../../store/artifacts/Lease/types.js";
import { getFormattedAmount } from "../../../store/artifacts/Lease/util.js";
import { LEASE_BILLING } from "../../../utils/unit.js";
import TicketInfoRelatedTickets from "../../../components/TicketInfo/TicketInfoSidebar/TicketInfoRelatedTickets.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const renderBadgeStatus = (startDate, endDate, cancelled, t) => {
  const listOfBadge = [];

  if (endDate < DateTime.utc()) {
    listOfBadge.push(__cssprop(Badge, null, t("ended")));
  }

  if (DateTime.utc() >= startDate && DateTime.utc() <= endDate) {
    listOfBadge.push(__cssprop(Badge, {
      success: true
    }, t("current")));
  }

  if (cancelled) {
    listOfBadge.push(__cssprop(Badge, {
      critical: true
    }, t("canceled")));
  }

  return listOfBadge;
};

export const DetailsPane = () => {
  const {
    t
  } = useI18n();
  const monthlyPrice = useSelector(Selectors.lease.getMonthlyPrice);
  const startDate = useSelector(Selectors.lease.getStartDate);
  const tenants = useSelector(Selectors.lease.getTenants);
  const leaseId = useSelector(Selectors.lease.getId);
  const paymentMethod = useSelector(Selectors.lease.getPaymentMethod);
  const endDate = useSelector(Selectors.lease.getEndDate);
  const amenities = useSelector(Selectors.lease.getAmenities);
  const unit = useSelector(Selectors.lease.getUnit);
  const canelled = useSelector(Selectors.lease.getCancelled);
  const building = useSelector(Selectors.lease.getBuilding);
  const fullAddress = useSelector(Selectors.lease.getFullAddress);
  const billingDate = useSelector(Selectors.lease.getBillDay);
  const isLocked = useSelector(Selectors.lease.isLocked);
  const notes = useSelector(Selectors.lease.getFilteredNotes);
  const tickets = useSelector(Selectors.lease.getTickets);
  const downPaymentAmount = useSelector(Selectors.lease.getDownPaymentAmount);
  const role = useSelector(Selectors.session.getUserRole);
  const noteLoading = useSelector(Selectors.status.isSubmiting("note"));
  const {
    dispatch
  } = useAction();
  const total = monthlyPrice;
  return __cssprop(React.Fragment, null, __cssprop(Card, {
    title: t("lease-page-details-lease-detail")
  }, __cssprop(Card.Section, null, __cssprop(DescriptionList, null, __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("property")
  }, (building?.id || unit?.id) && fullAddress ? __cssprop(Link, {
    action: {
      kind: "InternalLink",
      to: building?.units?.length > 1 ? `/unit/${unit.id}` : `/buildings/${building.id}`
    },
    text: fullAddress
  }) : "N/A"), __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("contract-form-start-date")
  }, startDate?.toUTC().toISODate()), __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("contract-form-end-date")
  }, endDate?.toUTC().toISODate()), __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("status")
  }, renderBadgeStatus(startDate, endDate, canelled, t)), __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("unit-lease-info-label-bill-day")
  }, billingDate), __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("unit-lease-info-label-payment-method")
  }, LEASE_BILLING(t)[paymentMethod]), __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("tenants")
  }, t("lease-page-details-pane-selected", {
    number: tenants?.length || 0
  })), __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("amenities-title")
  }, t("lease-page-details-pane-selected", {
    number: amenities?.length || 0
  })), __cssprop(DescriptionList.Item, {
    inline: true,
    title: t("applicant-request-list-down-payment-amount-received-as-down-payment")
  }, downPaymentAmount || 0))), __cssprop(Card.Section, {
    greyed: true
  }, __cssprop("h2", {
    css: {
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500"
    }
  }, t("lease-page-details-pricing")), __cssprop(DescriptionList, null, __cssprop(DescriptionList.Item, {
    inline: true,
    title: __cssprop("div", null, t("applicant-request-form-section-information-address-rent"), __cssprop("br", null), t("lease-page-details-addons"))
  }, __cssprop("div", {
    css: {
      "textAlign": "right",
      "fontSize": "1.4rem",
      "lineHeight": "2rem"
    }
  }, getFormattedAmount(monthlyPrice), " / ", t("month"), __cssprop("br", null), getFormattedAmount(0), " / ", t("month"))), __cssprop(DescriptionList.Item, {
    inline: true,
    title: "Total"
  }, __cssprop("span", {
    css: {
      "fontWeight": "700"
    }
  }, getFormattedAmount(total), " / ", t("month")))))), tickets.length > 0 && __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(TicketInfoRelatedTickets, {
    data: tickets
  }))), isLocked ? __cssprop(Notes, {
    notes: notes,
    loading: noteLoading,
    permission: {
      kind: "Lease",
      permission: "view:notes"
    },
    addNoteEvent: pNotes => {
      dispatch({
        payload: {
          resourceId: leaseId,
          resourceType: "lease",
          actionType: LeaseActionsTypes.LEASE_ADD_NOTES,
          content: pNotes.note,
          fromPage: true
        },
        type: LeaseActionsTypes.LEASE_ADD_NOTES_FORMLESS
      });
    },
    hideNewNote: role === "Client" || role === "User",
    removeNoteEvent: pNotes => {
      dispatch({
        payload: {
          noteId: pNotes.id,
          actionType: LeaseActionsTypes.LEASE_REMOVE_NOTES,
          fromPage: true
        },
        type: LeaseActionsTypes.LEASE_REMOVE_NOTES_FORMLESS
      });
    }
  }) : __cssprop(Notes, {
    notes: notes,
    loading: noteLoading,
    permission: {
      kind: "Lease",
      permission: "view:notes"
    },
    addNoteEvent: pNotes => {
      const newNote = {
        kind: "local",
        content: pNotes.note,
        id: `${notes.length + 1}`
      };
      dispatch({
        payload: [newNote],
        type: LeaseActionsTypes.LEASE_ADD_NOTES
      });
    },
    hideNewNote: role === "Client" || role === "User",
    removeNoteEvent: pNotes => {
      dispatch({
        payload: pNotes.id,
        type: LeaseActionsTypes.LEASE_REMOVE_NOTES
      });
    }
  }));
};