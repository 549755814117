import _styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { useMutation } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { Loader } from "../../../components/Loader.js";
import { Button } from "../../../components/Tailwind/Button.js";
import { ConsentTypes, CreateListingDocument, UpdateListingDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { FormProvider, useForm } from "../../../../../_snowpack/pkg/react-hook-form.js";
import { toast } from "../../../../../_snowpack/pkg/react-toastify.js";
import { DescriptionList } from "../../../components/Tailwind/DescriptionList.js";
import { TYPES, uploadDocumentsNoneSaga } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { b64toBlob } from "../../../utils/file.js";
import { PriceField } from "./PriceField.js";
import { ThumbnailField } from "./ThumbnailField.js";
import { DateField } from "./DateField.js";
import { VideoField } from "./VideoUrlField.js";
import { DescriptionFields } from "./DescriptionFields.js";
import { NoteField } from "./NoteField.js";
import { GalleryField } from "./GalleryField.js";
import { ConsentPicker } from "./ConsentPicker.js";
import { ToggleField } from "./IsUrgentField.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);
export const ListingSideBarFormView = props => {
  const {
    t
  } = useI18n();
  const {
    listingId,
    unitId,
    refetch,
    listingProp,
    address,
    published
  } = props;
  const defaultProps = {
    availability: DateTime.utc(),
    consents: [{
      consentType: ConsentTypes.IsNonSmoking,
      consentValue: "true"
    }, // default non smoking
    {
      consentType: ConsentTypes.MaximumNumberOfPets,
      consentValue: "0"
    } // default no pets
    ],
    description: {
      EN: "",
      FR: ""
    },
    gallery: [],
    isCreditCheckRequired: true,
    isUrgent: false,
    price: 0,
    thumbnail: null,
    note: "",
    videoLink: null
  };
  const defaultListingProps = listingProp ?? defaultProps;
  const methods = useForm({
    defaultValues: defaultListingProps
  });
  const {
    handleSubmit
  } = methods;
  const [createListing, {
    loading: createListingLoading
  }] = useMutation(CreateListingDocument, {
    fetchPolicy: "no-cache"
  });
  const [updateListing, {
    loading: updateListingLoading
  }] = useMutation(UpdateListingDocument, {
    fetchPolicy: "no-cache"
  });
  const onSubmit = handleSubmit(async listing => {
    try {
      // we need to convert blob to base64 because RHF doesn't allow it in setValue
      const galleryOrBlob = listing.gallery.map(g => {
        if (g.kind === "remote") return g;
        return { ...g,
          blob: b64toBlob(g.blob.substr(g.blob.indexOf(",") + 1))
        };
      });
      const galleryItems = await uploadDocumentsNoneSaga(galleryOrBlob, RESOURCES.Listing, TYPES.Images);
      const thumbnailOrBlob = listing.thumbnail.kind === "local" ? { ...listing.thumbnail,
        blob: b64toBlob(listing.thumbnail.blob.substr(listing.thumbnail.blob.indexOf(",") + 1))
      } : listing.thumbnail;
      const featuredItem = await uploadDocumentsNoneSaga([thumbnailOrBlob], RESOURCES.Listing, TYPES.Images);
      const listingInput = {
        description: Object.entries(listing.description).map(([key, value]) => ({
          locale: key,
          value
        })),
        availability: listing.availability.toUTC().toISO(),
        gallery: galleryItems,
        thumbnail: featuredItem[0],
        consents: listing.consents.map(c => {
          return {
            consentType: c.consentType,
            consentValue: c.consentValue
          };
        }),
        isCreditCheckRequired: listing.isCreditCheckRequired,
        price: listing.price,
        videoLink: listing.videoLink,
        isUrgent: listing.isUrgent,
        note: listing.note
      };
      let listingSaveResponse;

      if (listingId) {
        listingSaveResponse = await updateListing({
          variables: {
            listing: {
              id: listingId,
              listing: listingInput
            }
          }
        });
      } else {
        if (!unitId) {
          throw new Error("UnitId missing");
        }

        listingSaveResponse = await createListing({
          variables: {
            listing: {
              listing: listingInput,
              unitId
            }
          }
        });
      }

      if (listingSaveResponse.errors) {
        toast.warn(t("generic-fetch-error-message"));
      }

      if (listingSaveResponse && listingSaveResponse.data) {
        toast.success(t("rental-modify-success"));
        refetch();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("ListingSection", e);
      toast.warn(t("generic-fetch-error-message"));
    }
  });
  const isLoading = createListingLoading || updateListingLoading;

  if (isLoading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  return __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "1.6rem"
    }
  }, __cssprop(FormProvider, methods, __cssprop("form", {
    onSubmit: onSubmit,
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(ThumbnailField, {
    address: address,
    published: published
  })), __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginBottom": "0.4rem"
    }
  }, t("listing-information")), __cssprop(DescriptionList, null, __cssprop(PriceField, null), __cssprop(DateField, null), __cssprop(VideoField, null))), __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginBottom": "0.4rem"
    }
  }, t("listing-description")), __cssprop(DescriptionList, null, __cssprop(DescriptionFields, null))), __cssprop(StyledItems, null, __cssprop(GalleryField, null)), __cssprop(StyledItems, null, __cssprop(ToggleField, {
    title: t("urgent"),
    name: "isUrgent"
  })), __cssprop(StyledItems, null, __cssprop(ToggleField, {
    title: t("consent-value-credit-input"),
    name: "isCreditCheckRequired"
  })), __cssprop(StyledItems, null, __cssprop(ConsentPicker, null)), __cssprop(StyledItems, null, __cssprop(NoteField, null)), __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "row",
      "width": "100%",
      "justifyContent": "space-evenly"
    }
  }, __cssprop(Button, {
    submit: true,
    primary: true
  }, t("save")))))));
};