import { FeatureType } from "../app/graphql/documents.js";
const generic = {
  "clear-all": "Clear all",
  "server-error": "Server responded with the following error message:",
  "data-not-found": "We couldn't find data",
  "change-filters-settings": "Try changing your filters if you can't find anything",
  "change-filters": "Change filters",
  attachments: "Attachments",
  "settings-title": "Settings",
  building: "Building",
  phone: "Phone",
  phones: "Phones",
  email: "Email",
  emails: "Emails",
  delete: "Delete",
  add: "Add",
  cancel: "Cancel",
  buildings: "Buildings",
  unit: "Unit",
  units: "Units",
  project: "Client",
  projects: "Clients",
  applicants: "Applicants",
  "applicants-v2": "Applicants V2",
  applicant: "Applicant",
  deposit: "Deposit",
  deposits: "Deposits",
  finances: "Finances",
  tenant: "Tenant",
  tenants: "Tenants",
  role: "Role",
  calendar: "Calendar",
  user: "User",
  users: "Users",
  urgent: "Urgent",
  activate: "Activate",
  deactivate: "Deactivate",
  zoomIn: "Zoom in",
  zoomOut: "Zoom out",
  "see-details-for": "See details for {{for}}",
  "management-type": "Management type",
  "list-pagination": "Page {{currentPage}} of {{totalPage}}",
  "sort-by": "Sort by",
  "your-company": "Your company",
  "primary-phone": "Primary phone",
  "secondary-phone": "Secondary phone",
  "first-name": "First name",
  "last-name": "Last name",
  language: "Language",
  submit: "Submit",
  save: "Save",
  modify: "Modify",
  password: "Password",
  "password-confirm": "Retype Password",
  heating: "Heating",
  "room-number": "Number of rooms",
  floor: "Floor",
  floors: "Floors",
  next: "Next",
  previous: "Previous",
  "square-foot": "square feet",
  size: "Size",
  "unit-size": "Unit size",
  "water-tank-installation": "Water Tank installation date",
  "toast-warning": "There seems to be some wrong fields in your form, please check this one and try again.",
  download: "Download",
  "prepare-download": "Prepare to download",
  generate: "Generate",
  name: "Name",
  names: "Noms",
  lease: "Lease",
  leases: "Leases",
  renewal: "Renewal",
  renewals: "Renewals",
  credit: "Credit",
  day: "Days",
  status: "Status",
  fullname: "Full Name",
  view: "View",
  "date-birth": "Date of birth",
  yearly: "Yearly",
  monthly: "Monthly",
  biweekly: "BiWeekly",
  weekly: "Weekly",
  salary: "Salary",
  "unsaved-changed": "Unsaved changes",
  hide: "Hide",
  today: "Today",
  "place-holder-address": "123 Syrup Road, Gatineau QC (A1B 2C3)",
  to: "to ",
  reason: "Reason",
  interior: "Interior",
  exterior: "Exterior",
  garage: "Garage",
  true: "True",
  false: "False",
  select: "Select",
  properties: "Properties",
  property: "Property",
  "lease-ended": "Ended",
  "lease-end-today": "End Today",
  rooms: "Rooms",
  room: "Room",
  or: "or",
  and: "and",
  month: "Month",
  months: "Months",
  published: "Published",
  unpublished: "Unpublished",
  fr: "French",
  en: "English",
  "not-saved": "Not Saved",
  "to-maximized-ads": "To maximize the quality of the listing, we recommend the following modifications:",
  "add-pin-to-building": "Add a marker on the map for the building",
  none: "None",
  "field-required": "This field is required",
  "file-too-large": "The file is too large",
  "up-to-10-mb": "Up to 10MB",
  added: "added",
  modified: "modified",
  deleted: "deleted",
  "added-feminine": "added",
  "modified-feminine": "modified",
  "deleted-feminine": "deleted",
  "list-pagination-showing": "Showing",
  "list-pagination-to": "to",
  "list-pagination-of": "of",
  "days-left": "Days left",
  "lease-number": "Lease number",
  "lease-total-amount": "Lease total amount",
  "search-result-select-placeholder": "Search result",
  management: "Management",
  rental: "Rental",
  "generic-fetch-error-message": "An error occured while fetching the desired data, please try again or contact support if the error persists.",
  current: "Current",
  ended: "Ended",
  canceled: "Canceled ",
  restricted: "Restricted",
  agent: "Agent",
  french: "French",
  english: "English",
  delivery: "Delivery",
  deliveries: "Deliveries",
  post: "Post",
  description: "Description",
  task: "Task",
  downpayment: "Received Down Payment",
  "total-downpayment": "Total Down Payment"
};
const emptyStates = {
  "empty-state-buildings-heading": "No buildings found",
  "empty-state-buildings-description": "Try a different search result or add a new building to the system",
  "empty-state-past-tenant-description": "No past tenants found."
};
export const en = { ...generic,
  ...emptyStates,
  audit: "Audit",
  audits: "Audits",
  "tasks-title": "Tasks",
  "legal-disclosure-1": "By connecting to the platform, you agree to our",
  "legal-disclosure-2": "and our",
  "term-of-service": "terms of services",
  "privacy-policy": "privacy policy",
  "term-of-service-url": "https://simpleloyer.ca/en/terms_of_services",
  "privacy-policy-url": "https://simpleloyer.ca/en/privacy_policy",
  "dashboard-unit-vacant-since": "Vacant since {{day}} days",
  "login-email": "Email",
  "login-password": "Password",
  "login-connection": "Sign in",
  "login-next": "Next",
  "login-password-reset": "Reset your password",
  "login-back-to-main": "Return to the login page",
  "login-federated-login": "Log in with one of your accounts on one of the following platforms:",
  "login-title-connect": "Log in with your SimpleLoyer account",
  "login-title-reset": "Reset password",
  "login-error-email": "Please enter a valid email.",
  "login-error-password": "Please provide a password.",
  "login-success-password-reset": "The reset email has been sent!",
  "login-error-password-reset": "Invalid username, try again or contact support.",
  "login-error": "Invalid username or password, try again or contact support.",
  "building-info-no-building-found": "This building is nowhere to be found. Please go back and try again.",
  "building-info-add-unit": "Add unit",
  "building-info-add-lease": "Add new lease",
  "building-info-renew-lease": "Renew lease",
  "building-info-occupied-unit": "Occupied units",
  "building-info-unit-to-renew": "Units to renew",
  "building-info-unit": "Unit",
  "building-info-empty-unit": "This unit is empty",
  "building-info-empty-unit-future-lease": "This unit is currently empty but will be occupied from",
  "building-info-lease-not-renew": "The lease for this unit will not be renewed and will terminate the",
  "building-info-add-unit-empty-state": "Add a unit to your building",
  "building-info-not-unit-found": "Once you have units, you can manage your leases and tenants here.",
  "building-info-card-building-info": "Building information",
  "building-info-modify-building": "Modify Building",
  "building-info-construction-year": "Year of construction",
  "building-info-acquisition-date": "Acquisition date",
  "delete-building": "Delete this building",
  "delete-building-warning": "This building will be deleted from the system, along with any resources attached to it.",
  "delete-building-last-warning": "Delete this building",
  "delete-building-success": "The building has been successfully removed.",
  "delete-building-error": "An error occurred while deleting this building. Please try again.",
  "delete-project": "Delete this client",
  "delete-project-warning": "This client will be deleted from the system, along with any resource attached to it.",
  "delete-project-last-warning": "Delete this client",
  "delete-project-success": "The client has been successfully deleted.",
  "delete-project-error": "An error occurred while deleting this client. Please try again.",
  "delete-lease": "Delete this lease",
  "delete-lease-warning": "This lease will be deleted from the system, along with any resource attached to it.",
  "delete-lease-last-warning": "Delete this lease",
  "delete-lease-success": "The lease was successfully deleted.",
  "delete-lease-error": "An error occurred while deleting this lease. Please try again.",
  "delete-contract": "Delete this lease",
  "delete-contract-warning": "This lease will be deleted from the system, along with any resource attached to it.",
  "delete-contract-last-warning": "Delete this lease",
  "delete-contract-success": "The lease was successfully deleted.",
  "delete-contract-error": "An error occurred while deleting this lease. Please try again.",
  "delete-note": "Delete note",
  "delete-note-warning": "This note will be deleted from the system, along with any associated resources.",
  "delete-note-last-warning": "Delete this note",
  "delete-note-success": "The note has been deleted successfully.",
  "delete-note-error": "An error occurred while deleting this note. Please try again.",
  "delete-tenant": "Remove tenant",
  "delete-tenant-warning": "This tenant will be deleted from the system, along with any resource attached to it.",
  "delete-tenant-last-warning": "Remove this tenant",
  "delete-tenant-success": "The tenant has been successfully deleted.",
  "delete-tenant-error": "An error occurred while deleting this tenant. Please try again.",
  "delete-unit": "Remove unit",
  "delete-unit-warning": "This unit will be deleted from the system, along with any resources attached to it.",
  "delete-unit-last-warning": "Delete this unit",
  "delete-unit-success": "The unit has been successfully removed.",
  "delete-unit-error": "An error occurred while deleting this unit. Please try again.",
  "delete-deposit": "Delete deposits",
  "delete-deposit-warning": "This deposit will be deleted from the system, along with any resource attached to it.",
  "delete-deposit-last-warning": "Delete these deposit(s)",
  "delete-deposit-success": "The deposit has been successfully deleted.",
  "delete-deposit-error": "An error occurred while deleting this deposit. Please try again.",
  "delete-user": "Delete User",
  "delete-user-warning": "This user will be deleted from the system, along with any resource attached to it.",
  "delete-user-last-warning": "Delete this user(s)",
  "delete-user-success": "The user has been successfully deleted.",
  "delete-user-error": "An error occurred while deleting this user. Please try again.",
  "delete-banner-perm-action": "This action is permanent",
  "buildings-empty-unit": "Empty units",
  "buildings-renew-unit": "Units to renew",
  "buildings-units": "Units",
  "buildings-add-building": "Add a new building",
  "project-add-project": "Add a new Client",
  "project-info-no-project-found": "This client cannot be found. Please go back and try again.",
  "project-info-see-deposit": "See the deposit of {{amount}}$ by {{user}}",
  "project-info-modify-project": "Edit client",
  "project-info-list-buildings": "List of buildings",
  "project-info-list-deposit": "List of deposits",
  "project-info-add-deposit": "Add a new deposit",
  "project-info": "Client information",
  "project-info-no-contact-save": "No contact saved",
  "project-info-contact-person": "Contact person",
  "project-info-no-user-associated": "There are no users associated with this project.",
  "project-info-responsible-selector": "Search for a user",
  "project-info-responsible-selector-empty-state-title": "You didn't add a user yet",
  "project-info-responsible-selector-empty-state-description": "You can search and add users for the customer using the search field above.",
  "project-info-responsible-selector-empty-state-send-invite": "Send an invitation by email:",
  "managment-location": "Location",
  "management-management": "Managed",
  "dashboard-title": "Dashboard",
  search: "Search",
  "report-title": "Reports",
  "placeholder-filter-by-client": "Filter by client...",
  "widget-published-on": "Published on",
  "widget-display-units": "Display units",
  "widget-no-listings-found": "No listings found.",
  "widget-no-upcoming-event-found": "No unit events found.",
  "widget-status-other": "This unit contains more than 3 leases registered in the next 150 days.",
  "widget-other": "Other",
  "widget-unit-upcoming-events-title": "Upcoming events in your units (150 days)",
  "widget-tab-all": "All",
  "widget-tab-empty-unit": "Empty unit",
  "widget-tab-lease-to-renew": "Lease to renew",
  "widget-tab-lease-not-renew": "Lease not renewed",
  "widget-tab-empty-unit-plurial": "Empty unit",
  "widget-tab-lease-to-renew-plurial": "Leases to renew",
  "widget-tab-lease-not-renew-plurial": "Leases not renewed",
  "widget-table-row-lease-to-renew": "Lease to renew - Ends in {{days}} days",
  "widget-table-row-lease-end-on": "Lease will end on {{date}}",
  "widget-table-new-unit": "New unit",
  "widget-title": "Units requiring your attention",
  "widget-empty-table": "No unit found.",
  "active-lease-title": "Active Leases Report",
  "active-lease-description": "Generate a report for leases that are considered active at a given date.",
  "leases-to-be-renewed-title": "Leases to be Renewed Report",
  "leases-to-be-renewed-description": "Generate a report for leases that are going to be renewed at a given date.",
  profile: "Profile",
  setting: "Setting",
  logout: "Logout",
  "deposit-viewer-title": "Information on the deposit",
  "deposit-viewer-card-title": "Amount deposited on the {{date}}",
  "deposit-accessibility-label": "See the deposit of {{amount}}$ {{accessibilityInfo}}",
  "deposit-by": "by {{name}}",
  "deposit-add": "Add a new deposit",
  "deposit-enter-by": "Entered by",
  "deposit-enter-deposit-by": "Enter and Deposit by",
  "deposit-deposit-by": "Deposit by",
  "widget-new-entry": "New entry",
  "widget-tenant-leave": "Tenant leaves",
  "widget-renewing-lease": "Renewed Lease",
  "widget-process-of-renewal": "In process of renewal",
  "widget-occupied": "Occupied",
  "profile-toast-success": "Your profile has been saved.",
  "profile-toast-error": "An error occurred while saving the profile. Please try again.",
  "profile-info-shared-company": "Your user information for this company is shared with other members of {{companyName}}.",
  "profile-modify-profil": "Modify your profile",
  "profile-profil-image": "Profile picture",
  "profile-about-you": "About you",
  "profile-shared-to-all-user": "Your information will be shared with all of your different user accounts that you have below.",
  "profile-notification": "I do not wish to receive emails from the system",
  "profile-system-notification": "System Notification",
  "profile-language-changed": "System language changed to ",
  "registration-welcome-title": "Welcome to SimpleLoyer 🎉",
  "registration-complete-registration-title": "Complete your registration",
  "registration-use-external-platform": "Use one of your accounts on an external platform",
  "registration-complete-registration": "Complete your registration simply by logging into SimpleLoyer with one of your existing accounts on an external platform",
  "registration-create-standard-account": "Create a standard account",
  "open-registration": "Register now",
  "registration-register-stardard-account": "Complete your registration by registering with a standard email and password",
  "registration-with-standard-account": "Register with a standard account",
  "registration-invited-by-org": "You have been invited by <0>{{name}}</0> to participate in the management of buildings <1>{{managementName}}</1>",
  "registration-invited-by-project": "You have been invited by <0>{{name}}</0> to oversee the project <1>{{managementName}}</1>",
  "consent-title": "Consent to our usage policies",
  "consent-description": "You acknowledge having read and taken into account our ",
  "consent-connector": "and our",
  "registration-complete-registration-with-option": "To finalize your account creation, you must first accept our terms of usage and then choose one of our two option to create your account",
  "registration-error-request": "There seems to be a problem with the query",
  "registration-make-sure-option-completed": "Please make sure all of the following options have been completed: ",
  "registration-make-sure-option-completed-external-platform": "Have completed all the connection steps with the external platform (If you have chosen an external platform)",
  "registration-valid-email": "Provide a valid and existing email address",
  "registration-invitation-invalid": "This invitation is invalid",
  "registration-invitation-invalid-description": "It appears that this invitation is incorrect. If you have already completed your registration, you can log into the system.",
  "registration-back-to-login-page": "Back to the login page",
  "registration-welcome": `Welcome <0>{{name}}</0>,`,
  "registration-change-language": "Change the language",
  "registration-resend-email-success": "E-mail sent",
  "registration-resend-email-error-send-to-quick": "You must wait 30 minutes before each email is sent",
  "registration-resend-email-error": "There was an error, when attempting to re-send the e-mail, please try again or contact support via chat tool.",
  "rental-inclusion-title": "Inclusions",
  "rental-inclusion-features": "Features",
  "rental-inclusion-appliances": "Household appliances",
  "rental-inclusion-counter-type": "Type of counters",
  "rental-inclusion-yard-access": "Access to the land",
  "rental-inclusion-balcony": "Private balcony included",
  "rental-inclusion-blinds": "Stores included",
  "rental-inclusion-furnished": "Furniture included",
  "rental-inclusion-washer-dryer": "Contains outlet for washer / dryer",
  "rental-inclusion-oven": "Oven",
  "rental-inclusion-dishwasher": "Dishwasher",
  "rental-inclusion-fridge": "Refrigerator",
  "rental-inclusion-drying-machine": "Dryer",
  "rental-inclusion-washing-machine": "Washer",
  "rental-inclusion-microwave": "Microwave",
  "rental-parking-title": "Parking",
  "rental-parking-amount-ext": "Qty. of exterior parking",
  "rental-parking-amount-int": "Qty. of indoor parking",
  "rental-parking-amount-gar": "Amount of garage parking",
  "rental-parking-amount-invalid": "The number of parking is invalid",
  "rental-services-title": "Services",
  "rental-services-electricity": "Electricity",
  "rental-services-internet": "Internet",
  "rental-services-water": "Water",
  "rental-services-heating": "Heating",
  "rental-services-cable": "Cable",
  "rental-services-ac": "Air conditioning",
  "rental-unit-detail-title": "The unit",
  "rental-unit-detail-required": "Details required",
  "rental-unit-detail-disposition": "Disposition",
  "rental-unit-detail-bedrooms": "Qty. of rooms",
  "rental-unit-detail-sqrft": "Square feet",
  "rental-unit-detail-qty-bathrooms": "Qty. Bathroom",
  "rental-unit-detail-qty-half-bathrooms": "Qty. Water parts",
  "rental-unit-detail-disabled-friendly": "The unit is suitable for people with special needs",
  "rental-unit-detail-smoking": "Smoking allowed",
  "rental-unit-detail-animals": "Pets allowed",
  "rental-unit-detail-error-bedrooms": "The number of rooms is invalid",
  "rental-unit-detail-error-disposition": "The disposition is invalid",
  "rental-unit-detail-error-sqrft": "The number of square feet is invalid",
  "rental-unit-detail-error-bathrooms": "The number of bathrooms is invalid",
  "rental-unit-detail-error-half-bathrooms": "The number of bathroom is invalid",
  "rental-detail-title": "Listing details",
  "rental-detail-description-title-french": "Description of the advertisement in french",
  "rental-detail-description-title-english": "Description of the advertisement in english",
  "rental-detail-monthly-price": "Price displayed",
  "rental-detail-lease-status": "Availability of the lease",
  "rental-detail-lease-now": "From now ({{date}})",
  "rental-detail-lease-end": "As soon as the current lease ends ({{date}})",
  "rental-detail-lease-manual": "Choose a manual date",
  "rental-detail-error-price": "The price per month is invalid",
  "rental-detail-error-lease-end": "The date is invalid",
  "rental-detail-error-lease-manual": "The date is invalid",
  "rental-detail-error-description": "A description must be provided",
  "rental-detail-error-description-invalid": "The description is invalid",
  "tenant-accessibility-label": "Tenant information for {{name}}",
  "tenant-add": "Add a tenant",
  "tenant-sort-asc": "Ascending name",
  "tenant-sort-desc": "Descending name",
  "tenant-tab-all": "All",
  "tenant-tab-active": "Active",
  "tenant-tab-future": "Upcoming",
  "tenant-tab-past": "History",
  "tenant-tab-candidate": "Candidates",
  "tenant-tab-invitees": "Pending Invites",
  "tenant-info-empty-state": "This tenant cannot be found. Please go back and try again.",
  "tenant-info-modify": "Modify tenant",
  "tenant-info-no-lease": "There is no lease yet",
  "tenant-info-no-lease-description": `This tenant does not yet have a lease to display. Please bind the tenant with a lease to view more `,
  "tenant-info-lease-history": "Housing history",
  "tenant-info-heading-housing": "Housing",
  "tenant-info-heading-entry-date": "Entry date",
  "tenant-info-heading-end-date": "End date",
  "tenant-info-title": "Tenant information",
  "tenant-info-active-lease": "Active lease",
  "tenant-info": "Tenant information",
  "unit-fill-technical-sheet": "Complete the listing",
  "unit-create-technical-sheet": "Create a listing",
  "unit-modify-technical-sheet": "Modify listing",
  "unit-empty-state-unit-not-found": "This unit cannot be found. Please go back and try again.",
  "unit-add-lease": "Add a new lease",
  "unit-modify-unit": "Edit unit",
  "unit-modify-active-lease": "Modify the active lease",
  "unit-empty-state-no-lease": "There is no lease yet",
  "unit-empty-state-no-lease-description": `This unit does not have a lease yet.`,
  "unit-info": "Unit Information",
  "unit-see-lease": "View lease",
  "unit-modify-lease": "Modify the lease",
  "unit-delete-lease": "Delete the lease",
  "unit-tenant-history": "History of tenants",
  "unit-heading-name": "Name(s)",
  "unit-heading-entry-date": "Entry date",
  "unit-heading-end-date": "End date",
  "unit-heading-action": "Action",
  "user-added-the": "Added on : {{date}}",
  "user-invited-at": "Invited on : {{date}}",
  "user-added-by": "Added by : {{name}}",
  "user-add": "Add user",
  "user-tab-employee": "Employee",
  "user-tab-registrant": "Registrant",
  "file-viewer-modify": "Modify",
  "file-viewer-no-file": "You currently have no files attached to this resource.",
  "lease-viewer-title": "Lease of ",
  "lease-viewer-payment-method": "Payment method",
  "lease-viewer-start-lease": "Start of the lease",
  "lease-viewer-end-lease": "End of the lease",
  "lease-viewer-day-before-renew": "Days left before renewal",
  "lease-viewer-day-remaining": "{{date}} days left",
  "lease-viewer-lease-info": "Lease Information",
  "lease-viewer-monthy-rent": "Monthly rent",
  "lease-viewer-lease-not-renew": "This lease will not be renewed and will terminate the {{date}}.",
  "management-for": "Management for",
  "management-building-title": "Building management",
  "management-building-description": "This building is managed by a company.",
  "management-building-since": "Building management since",
  "management-tenant-title": "Tenant management",
  "management-tenant-description": "This tenant is managed by a company.",
  "management-tenant-since": "Management of the tenant since",
  "map-search-address": "Find an address",
  "map-text-container-no-result": "No result found. Please do a research.",
  "map-automatic-marker": "Allow to position the marker with automatic correction",
  "notes-no-notes-found": "You do not have any notes attached to this resource at this time.",
  "notes-added-success": "Your note has been saved.",
  "notes-added-error": "An error occurred while saving the note. Please try again.",
  "notes-delete-error": "An error occurred while deleting this note. Please try again.",
  "notes-deleted-success": "Your note has been deleted.",
  "notes-title": "Notes",
  "rental-publish-ad": "Publish listing",
  "rental-remove-ad": "Remove listing",
  "rental-ad-no-longer-publised": "The listing is not yet published",
  "rental-publised-since": "The listing is currently active and published",
  "rental-ad-status": "Current listing status",
  "rental-ad-not-created": "No listing found for this unit",
  "building-form-title": "Building Information",
  "building-form-description": "Please provide the basic building  We use these values to allow the automatic capture of certain fields in the creation of a unit or a lease.",
  "building-form-civic-number": "Civic number",
  "building-form-address": "Address",
  "building-form-city": "City",
  "building-form-postal-code": "Postal code",
  "building-form-sector": "Sector",
  "building-form-province": "Province",
  "building-form-country": "Country",
  "building-form-construction-date": "Construction date",
  "building-form-acquisition-date": "Acquisition date",
  "building-form-error-civic": "Civic number is required",
  "building-form-error-address": "Address is required",
  "building-form-error-city": "City is required",
  "building-form-error-postal-code": "Postal code is invalid or required ex: a1b 2c3",
  "building-form-error-province": "The province is invalid",
  "building-form-error-sector": "The sector is invalid",
  "building-form-error-build-date": "Date is invalid or required",
  "building-form-error-purchase-date": "The date is invalid",
  "project-form-error-name": "Your client's name is required",
  "project-form-error-contact-name": "The name of the contact person is required",
  "project-form-error-phone": "Phone number or email is required",
  "project-form-error-email": "Phone number or email is required",
  "project-form-error-management-type": "This management type is invalid",
  "project-form-title": "Customer Information",
  "project-form-description": "Please provide the customer company ",
  "project-form-name": "Client name",
  "project-form-placeholder-my-company": "My company",
  "project-form-legal-name": "Legal name",
  "project-form-management-type": "Management type",
  "project-parent-company": "Owner of this client",
  "project-form-contact-name": "Name of contact person",
  "project-form-primary-phone": "Primary Customer Phone",
  "project-form-email": "Company Email",
  "project-form-fax": "Customer's fax address",
  "provider-form-name": "Name",
  "provider-form-email": "Email",
  "provider-form-primary-phone": "Primary Phone Number",
  "provider-form-secondary-phone": "Secondary Phone Number",
  "provider-parent-company": "Owner of this provider",
  "provider-form-update-provider-success": "Provider is successfully updated.",
  "provider-form-update-provider-fail": "An error occurred while updating the provider. Please try again.",
  "provider-form-add-provider-success": "Provider is successfully created.",
  "provider-form-add-provider-fail": "An error occurred while creating the provider. Please try again.",
  "provider-add-new-provider": "Add new provider",
  "provider-list-no-files": "No files",
  "provider-list-column-name": "Name",
  "provider-list-column-email": "Email",
  "provider-list-column-phone": "Phone",
  "provider-list-column-notes": "Notes",
  "provider-list-column-files": "Files",
  "provider-list-singular": "provider",
  "provider-list-plural": "providers",
  "provider-sidebar-section-title-edit": "Edit provider",
  "provider-sidebar-section-title-create": "Create provider",
  "provider-sidebar-name-label": "Name",
  "provider-sidebar-name-placeholder": "Name...",
  "provider-sidebar-name-error": "Name should be at least 6 symbols long",
  "provider-sidebar-email-label": "Email",
  "provider-sidebar-email-placeholder": "Email...",
  "provider-sidebar-email-error": "Invalid email",
  "provider-sidebar-phone-label": "Phone",
  "company-form-title": "Building management",
  "company-form-description": "Use this field to change which company is in charge of the building management. If you are not in a management company you can just keep the automatic selection.",
  "company-form-error-management-company": "A manager is required",
  "company-form-error-start-date": "The date is invalid",
  "company-form-loading": "Loading",
  "company-form-start-date": "Management start date (optional)",
  "contract-form-error-date": "The date is invalid",
  "contract-form-title": "Lease information",
  "contract-form-description": "Please choose the start and end dates for your agreement with your client.",
  "contract-form-start-date": "Start date",
  "contract-form-end-date": "End date",
  "deposit-form-error-amount": "A positive amount is required",
  "deposit-form-error-deposit-date": "The date is invalid",
  "deposit-form-error-destination": "This client is invalid",
  "deposit-form-title": "Deposit information",
  "deposit-form-description": "Please provide basic deposit ",
  "deposit-form-amount": "Amount",
  "deposit-form-date": "Date",
  "file-section-title": "Files",
  "file-section-description": "You can add files to the lease to keep artifacts such as paper lease for reference.",
  "file-section-error": "A photo is required",
  "file-section-single-image-title": "Picture",
  "file-section-single-image-description": "The image you add to your building will be used to help you visualize your buildings.",
  "file-section-single-image-description-rental": "The thumbnail you want to add to your ad.",
  "file-section-banner-description": "A photo is required to display an ad",
  "file-section-multiple-image-title": "Image Galleries",
  "file-section-multiple-image-description": "The images you want to add to your ad.",
  "rental-form-ad-saved": "Your ad has been saved.",
  "rental-form-ad-error": "An error occurred while saving your ad. Please try again.",
  "rental-form-modify-ad": "Edit listing",
  "rental-form-add-ad": "Add a unit ad",
  "rental-form-image-section-title": "Ad thumbnail",
  "rental-form-contact-for-ad": "Contacts for the ad",
  "note-section-title": "Notes",
  "note-section-placeholder": "Specify the content of your note here ...",
  "note-section-button-remove-note": "Remove note",
  "note-section-description": "Here are the notes in the tenant's file.",
  "note-section-add-note": "Add a note",
  "note-section-empty-state-title": "You haven't added any notes yet",
  "note-section-empty-state-description": "Looks like there aren't any notes yet! Please attach it to the resource for viewing",
  "responsible-selector-title": "Building managers ",
  "responsible-selector-description": "You can add managers who are linked to the building to facilitate your management",
  "responsible-selector-error": "A manager is required",
  "responsible-selector-search-manager": "Search for a manager",
  "responsible-selector-empty-state-title": "You haven't added managers yet",
  "responsible-selector-empty-state-description": "You can search and add building managers using the search box above.",
  "tags-section-title": "Tags",
  "tags-section-description": 'Add tags to facilitate the search and organization of your buildings Ex. "Complexe Sunrise", "Region 1A", "On the lake", ...',
  "tags-section-placeholder": "Add tag",
  "tenant-selector-title": "Selection of tenants",
  "tenant-selector-description": "You can choose among your tenants already included in the system to add them to the lease.",
  "tenant-selector-error": "A tenant is required",
  "tenant-selector-search-tenant": "Finding a tenant",
  "tenant-selector-empty-state-title": "You haven't added any tenants yet",
  "tenant-selector-empty-state-description": "You can search and add tenants using the search field above.",
  "unit-lease-info-monthly-price-invalid": "The price per month is invalid",
  "unit-lease-info-bill-day-invalid": "The billing day is invalid",
  "unit-lease-info-payment-method-invalid": "The payment type is invalid",
  "unit-lease-info-date-invalid": "The date is invalid",
  "unit-lease-info-section-title": "Lease information",
  "unit-lease-info-section-description": "You can choose the duration of your lease as well as the various automatic communication options in relation to snow removal.",
  "unit-lease-info-card-title-info": "Financial informations",
  "unit-lease-info-card-description-duration": "Lease term",
  "unit-lease-info-label-monthly-price": "Monthly price",
  "unit-lease-info-label-bill-day": "Billing day of month",
  "unit-lease-info-label-bill-day-help-text": "ex: 1, meaning that the 1st of the month the tenant will be invoiced.",
  "unit-lease-info-label-payment-method": "Method of payment",
  "unit-lease-info-label-do-not-renew": "Do not renew the lease",
  "unit-lease-info-label-radio-create-1year-lease": "Create a lease for a period of 1 year",
  "unit-lease-info-label-radio-start-date": "Lease start date",
  "unit-lease-info-label-radio-end-date": "Lease end date",
  "unit-lease-info-label-radio-choose-manual-date": "Manually choose the start and end of the Lease",
  "unit-info-section-title": "Unit Information",
  "unit-info-section-description": "Here is the information of the unit you wish to add.",
  "unit-info-section-error-unit-type": "The apartment type is invalid",
  "unit-info-section-error-sub-address": "Unit address is required",
  "unit-info-section-error-bedroom": "The number of rooms is invalid",
  "unit-info-section-error-floor": "The floor is invalid",
  "unit-info-section-error-square-footage": "The number of square feet is invalid",
  "unit-info-section-error-heating-type": "Heating type is invalid",
  "unit-info-section-error-water-tank-date": "The date is invalid",
  "unit-info-section-form-unit-type": "Unit type",
  "unit-info-section-form-sub-address": "Unit number",
  "unit-info-section-form-sub-address-placeholder": "ex: 1, A",
  "unit-info-section-form-floor": "Floor",
  "unit-info-section-form-bedroom-number": "Number of rooms",
  "unit-info-section-form-square-foot": "Living space of the unit",
  "unit-info-section-form-square-foot-suffix": "Square foot",
  "unit-info-section-form-heating-type": "Type of heating",
  "unit-info-section-form-water-tank-date": "Installation date of the water heater",
  "unit-selector-title": "Unit selection",
  "unit-selector-description": "Choose the unit to which you want to add the lease.",
  "unit-selector-error": "A unit is required or the selected unit is invalid",
  "unit-selector-no-unit": "No unit",
  "unit-selector-selected-building": "Your selected building",
  "unit-selector-selected-building-title": "building selection",
  "unit-selector-selected-building-description": "Choose the building and the unit to which you want to add the lease.",
  "user-info-error-first-name": "The user's first name is required",
  "user-info-error-last-name": "The user's last name is required",
  "user-info-error-secondary-phone": "The user's secondary phone number is invalid",
  "user-info-error-phone-or-email-required": "User phone number or email is required",
  "user-info-error-email-required": "Email is required",
  "user-info-error-phone-required": "Phone number is required",
  "user-info-error-phone-invalid": "The phone number is invalid (ex: 222-333-5555)",
  "user-info-error-email-invalid": "The email is invalid (ex: john@simpleloyer.ca)",
  "user-info-form-first-name": "First name",
  "user-info-form-first-name-placeholder": "John",
  "user-info-form-last-name": "Last name",
  "user-info-form-last-name-placeholder": "Doe",
  "user-info-form-primary-phone": "Main Phone",
  "user-info-form-primary-phone-placeholder": "123-456-7894",
  "user-info-form-cellphone": "Cellular",
  "user-info-form-secondary-phone": "Secondary Telephone",
  "user-info-form-secondary-phone-placeholder": "123-456-7894",
  "user-info-form-email-placeholder": "jsmith@gmail.com",
  "user-preference-error-language": "Preferences chosen are invalid",
  "user-preference-error-notification": "The choice is invalid",
  "user-preference-form-language": "Language",
  "user-preference-form-notification": "SYSTEM NOTIFICATIONS",
  "user-preference-form-notification-label": "This user does not wish to receive notifications from the system.",
  "building-form-modify-building": "Modify the building",
  "building-form-add-building": "Add a new building",
  "building-form-add-building-success": "Your building has been saved.",
  "building-form-add-building-fail": "An error occurred while saving the building. Please try again.",
  "project-form-add-success": "Your client has been saved.",
  "project-form-add-fail": "An error occurred while backing up the client. Please try again.",
  "project-form-add": "Add a new Client",
  "project-form-modify": "Edit Client",
  "deposit-form-add-success": "Your deposit has been saved.",
  "deposit-form-add-fail": "An error occurred while saving the repository. Please try again.",
  "deposit-form-delete-success": "Your deposit has been deleted.",
  "deposit-form-delete-fail": "An error occurred while deleting the repository. Please try again.",
  "deposit-form-modify-deposit": "Modify the deposit",
  "deposit-form-add-deposit": "Add a new deposit",
  "deposit-form-deposit-destination": "Destination of deposit",
  "deposit-form-deposit-destination-description": "Please choose the company that receives the deposit.",
  "deposit-form-deposit-chose-company": "Users who made the deposit",
  "deposit-form-deposit-chose-company-description": "You can add the user who makes the deposit",
  "ticket-form-add-ticket-success": "New ticket was successfully created.",
  "ticket-form-add-ticket-fail": "An error occurred while creating the ticket. Please try again.",
  "ticket-form-add-ticket-conversation-success": "New conversation is successfully created.",
  "ticket-form-add-ticket-conversation-fail": "An error occurred while creating the conversation. Please try again.",
  "ticket-form-update-ticket-success": "Ticket was successfully updated.",
  "ticket-form-update-ticket-fail": "An error occurred while updating the ticket. Please try again.",
  "ticket-form-close-task-success": "Task was successfully closed.",
  "ticket-form-close-task-fail": "An error occurred while closing the task. Please try again.",
  "ticket-form-close-ticket-success": "Ticket was successfully closed.",
  "ticket-form-close-ticket-fail": "An error occurred while closing the ticket. Please try again.",
  "ticket-form-delete-ticket-success": "Ticket was successfully deleted.",
  "ticket-form-delete-task-fail": "An error occurred while deleting the ticket. Please try again.",
  "delete-ticket-warning": "This ticket will be deleted from the system, along with any resource attached to it.",
  "delete-ticket": "Delete this ticket",
  "delete-ticket-last-warning": "Delete this ticket",
  "delete-ticket-success": "The ticket has been successfully deleted.",
  "delete-ticket-error": "An error occurred while deleting this ticket. Please try again.",
  "tickets-table-status": "Status",
  "tickets-status-badge-deleted": "Deleted",
  "tickets-status-badge-closed": "Closed",
  "tickets-status-badge-opened": "Opened",
  "ticket-no-tasks-label": "No added tasks",
  "ticket-conversation-header": "Conversation",
  "ticket-filter-close": "Include closed tasks",
  "ticket-related-title": "Related Tickets",
  "task-form-add-task-success": "New task was successfully created.",
  "task-form-add-task-fail": "An error occurred while creating the task. Please try again.",
  "task-form-update-task-success": "Task was successfully updated.",
  "task-form-update-task-fail": "An error occurred while updating the task. Please try again.",
  "task-form-add-invoice-success": "New invoice was successfully created.",
  "task-form-add-invoice-fail": "An error occurred while creating the invoice. Please try again.",
  "task-form-update-invoice-success": "Invoice was successfully updated.",
  "task-form-update-invoice-fail": "An error occurred while updating the invoice. Please try again.",
  "task-form-invoice-payer": "Bill to",
  "task-form-close-task-success": "Task was successfully closed.",
  "task-form-close-task-fail": "An error occurred while closing the task. Please try again.",
  "task-form-delete-task-success": "Task was successfully deleted.",
  "task-form-delete-task-fail": "An error occurred while deleting the task. Please try again.",
  "task-add-section-title": "Add new task",
  "task-edit-section-title": "Edit task",
  "task-edit-close-task": "Close task",
  "task-add-sidebar-title-label": "Task title",
  "task-add-sidebar-title-placeholder": "Title...",
  "task-add-sidebar-title-error": "Title should be at least 1 characters long",
  "task-add-sidebar-due-date": "Due date",
  "task-add-sidebar-user-title": "Responsible",
  "task-add-sidebar-button-delete": "Delete",
  "task-add-sidebar-button-add": "Add",
  "task-add-sidebar-button-edit": "Edit",
  "task-add-sidebar-button-create": "Create",
  "task-add-sidebar-invoice-title-label": "Invoice title",
  "task-add-sidebar-invoice-title-placeholder": "Title...",
  "task-add-sidebar-invoice-amount-label": "Invoice amount",
  "task-add-sidebar-invoice-amount-placeholder": "Amount...",
  "task-add-sidebar-invoice-amount-error": "Please enter a valid invoice amount",
  "task-add-sidebar-invoice-description-label": "Invoice description",
  "task-add-sidebar-invoice-description-placeholder": "Description...",
  "task-add-sidebar-invoice-date-title": "Date",
  "task-invoice-title": "Invoice",
  "task-add-bill": "Add bill",
  "task-assign-to": "Assigned to {{assigned}}",
  "task-billed-for": "Billed for {{bill}}$ on {{bill_date}}",
  "task-due": "Due {{date}}",
  "file-uploader-image-supported-file": "is not supported. File type must be .gif, .jpg, .png or .svg.",
  "file-uploader-image-supported-file-banner-title": "The following images couldn't be uploaded:",
  "file-uploader-file-supported-file": "is not supported. File type must be .pdf.",
  "file-uploader-file-supported-file-banner-title": "The following file couldn't be uploaded:",
  "file-uploader-no-file-attached": "You currently have no files attached to this resource.",
  "file-uploader-add-image": "Add image",
  "file-uploader-drop-single-image": "or drop image to upload",
  "file-uploader-drop-image": "or drop images to upload",
  "file-uploader-add-file": "Add file",
  "file-uploader-drop-file": "or drop files to upload",
  "file-form-saved-success": "Your file has been saved.",
  "file-form-saved-fail": "An error occurred while backing up your files. Please try again.",
  "file-form-title": "Manage files",
  "file-form-description": "You can modify the files of your chosen resource if you drop them in the attached frame.",
  "leasing-form-saved-success": "Your lease has been saved.",
  "leasing-form-saved-fail": "An error occurred while saving the lease. Please try again.",
  "leasing-form-modify-lease": "Modify the lease",
  "leasing-form-add-lease": "Add a new lease",
  "tenant-form-saved-success": "The tenant has been save.",
  "tenant-form-saved-fail": "An error occurred while saving the tenant. Please try again.",
  "tenant-form-modify-tenant": "Modify a tenant",
  "tenant-form-user-info-title": "Personal information",
  "tenant-form-user-info-description": "Here is the contact information in order to communicate with this tenant.",
  "tenant-form-user-preference-title": "Tenant preference",
  "tenant-form-user-preference-description": "Choose the preferences the system will use when communicating with the tenant",
  "unit-form-saved-success": "Your unit has been saved.",
  "unit-form-saved-fail": "An error occurred while saving the unit. Please try again.",
  "unit-form-modify-unit": "Edit this unit",
  "unit-form-add-unit": "Add a unit to a building",
  "user-form-saved-success": "The user has been added.",
  "user-form-saved-fail": "An error occurred while saving the user. Please try again.",
  "user-form-modify-user": "Show user",
  "user-form-add-user": "Add user",
  "user-form-banner-title-register-not-complete": "This user has not yet completed his registration!",
  "user-form-banner-description-register-not-complete": "You can provide the user with the following link to finalize their registration:",
  "user-form-company-selection-title": "Company selection",
  "user-form-company-selection-description": "Choose the company for which you want the user added.",
  "user-form-user-info-title": "Personal information",
  "user-form-user-info-description": "Here is the user's contact ",
  "user-form-user-role-title": "User permissions",
  "user-form-user-role-description": "Here you assign a role to the user in order to give him permissions to perform actions in the system. Roles: Manager (Access to all actions), Employee (Access to standard actions). Observer (Can only view information)",
  "user-form-user-preference-title": "User preference",
  "user-form-user-preference-description": "Choose the preferences the system will use when communicating with this user",
  "user-role-section-error": "The chosen role is invalid or required",
  "leasing-form-billing-cash": "Cash",
  "leasing-form-billing-check": "Check",
  "leasing-form-billing-credit-card": "Credit Card",
  "leasing-form-billing-direct-debit": "Direct Debit",
  "leasing-form-billing-eTransfer": "Electronic Transfer",
  "unit-form-unit-type-apartment": "Apartment",
  "unit-form-unit-type-condo": "Condo",
  "unit-form-unit-type-house": "House",
  "unit-form-unit-type-studio": "Studio",
  "unit-form-unit-type-room": "Single Bedroom",
  "unit-form-unit-type-other": "Other",
  "unit-form-unit-type-commercial": "Commercial",
  "unit-form-heating-type-electricity": "Electricity",
  "unit-form-heating-type-gas": "Gas",
  "unit-form-heating-type-oil": "Oil",
  "unit-form-heating-type-other": "Other",
  "unit-form-floor-basement": "Basement",
  "unit-form-floor-semi-basement": "Semi Basement",
  "unit-form-floor-ground": "Ground",
  "unit-form-floor-first": "First Floor",
  "unit-form-floor-second": "Second Floor",
  "unit-form-floor-third": "Third Floor",
  "unit-form-floor-fourth": "Fourth Floor",
  "unit-form-floor-fifth": "Fifth Floor",
  "unit-form-floor-sixth": "Sixth Floor",
  "unit-form-floor-seventh": "Seventh Floor",
  "unit-form-floor-eighth": "Eighth Floor",
  "unit-form-floor-ninth": "Ninth Floor",
  "unit-form-floor-tenth": "Tenth Floor",
  "rental-inclusion-counter-melamine": "Melamine",
  "rental-inclusion-counter-granite": "Granite",
  "rental-inclusion-counter-quartz": "quartz",
  "rental-inclusion-counter-other": "Other",
  "rental-inclusion-counter-unspecified": "Unspecified",
  "user-role-section-system": "System",
  "user-role-section-owner": "Owner",
  "user-role-section-admin": "Admin",
  "user-role-section-manager": "Manager",
  "user-role-section-user": "User",
  "user-role-section-client": "Client",
  "applicant-request-title": "Rental request",
  "applicant-request-title-new": "New Application",
  "applicant-request-tab-active-approved": "Active and approved",
  "applicant-request-tab-completed": "Completed",
  "applicant-request-tab-canceled": "Canceled",
  "applicant-request-add-applicant": "Add a new applicant",
  "applicant-request-add-applicant-description": "Manually create an applicant or select an existing user for the active listing on this unit",
  "applicant-request-heading-application-state": "Application states",
  "applicant-request-heading-application-date": "Application date",
  "applicant-request-for": "Application for",
  "project-remove-modal-action": "Remove access",
  "project-remove-modal-warning": "This user will lose his access to the client as well as all the resources attached to it.",
  "project-remove-modal-last-warning": "Remove access",
  "project-remove-modal-last-success": "The accesses have been successfully removed.",
  "project-remove-modal-last-error": "An error occurred while removing access for this user. Please try again.",
  "applicant-request-form-applicant-detail": "Applicant details",
  "applicant-request-form-no-visit-agent": "No agent at the moment",
  "applicant-request-form-visit-date-empty": "No visit date sheculed",
  "applicant-request-form-signature-date-empty": "No signature date sheculed",
  "applicant-request-form-down-payment-amount-empty": "No down payment given",
  "applicant-request-form-visit-date-completed-empty": "Visit not completed",
  "applicant-request-form-add-applicant": "Add a new applicant",
  "applicant-request-form-modify-applicant": "Modify an applicant",
  "applicant-request-form-add-applicant-success": "Your application has been saved.",
  "applicant-request-form-add-applicant-status-updated": "The status has been saved",
  "applicant-request-add-applicant-fail": "An error occurred while saving the application. Please try again.",
  "applicant-request-add-applicant-validation-fail": "The application is missing information to be saved. Please fill it out.",
  "applicant-request-form-section-information-title": "Applicant information",
  "applicant-request-form-section-information-subtitle": "Personal details and application.",
  "applicant-request-form-section-information-description": "Description",
  "applicant-request-form-section-information-nas": "SIN (Optional)",
  "applicant-request-form-section-information-nas-invalid": "The SIN is invalid ex: (123 123 123)",
  "applicant-request-form-section-information-name-required": "Name is required",
  "applicant-request-form-section-information-date-invalid": "Date of birth is invalid",
  "applicant-request-form-section-information-phone-invalid": "The phone number is invalid ex: (123-123-1234)",
  "applicant-request-form-section-information-email-invalid": "The email is invalid ex: abc@gmail.com",
  "applicant-request-form-section-information-details-spouse": "Spouse",
  "applicant-request-form-section-information-details-roommate": "Roommate",
  "applicant-request-form-section-information-details-children": "Children",
  "applicant-request-form-section-information-details-dog": "Dog",
  "applicant-request-form-section-information-details-cat": "Cat",
  "applicant-request-form-section-information-details-employer-name": "Name of the Manager",
  "applicant-request-form-section-information-details-employer-phone": "Manager's phone",
  "applicant-request-form-section-information-details-number-resident": "Number of residents in the unit",
  "applicant-request-form-section-information-details-number-parking": "Number of parking spaces requested",
  "applicant-request-form-section-information-details-number-resident-invalid": "The number of residents is invalid",
  "applicant-request-form-section-information-details-number-parking-invalid": "The number of parking is invalid",
  "applicant-request-form-section-information-address-title": "Current address",
  "applicant-request-form-section-information-address-rent": "Rent",
  "applicant-request-form-section-information-address-occupation-period": "Occupancy period",
  "applicant-request-form-section-information-address-occupation-date": "Occupancy date requested",
  "applicant-request-form-section-information-address-landlord": "(Optional) Owner",
  "applicant-request-form-section-information-address-light": "Lights",
  "applicant-request-form-section-information-job-employe": "Employe",
  "applicant-request-form-section-information-job-full-time": "Full time",
  "applicant-request-form-section-information-job-part-time": "Part time",
  "applicant-request-form-section-information-job-permanent": "Permanent",
  "applicant-request-form-section-information-job-contractual": "Contractual",
  "applicant-request-form-section-information-job-start-date-employment": "Employed since",
  "applicant-request-form-section-information-job-number-of-hours": "Number of hours per week",
  "applicant-request-form-section-information-job-number-of-hours-required": "Number of hours per week is required",
  "applicant-request-form-section-information-details-salary-required": "Salary is required",
  "applicant-request-form-section-information-details-salary-interval": "Payment of wages",
  "applicant-request-form-section-button-approve-candidate": "Approve the candidate",
  "applicant-request-form-section-button-approve-candidate-by-owner": "Send to owner",
  "applicant-request-form-section-button-resend-to-owner": "Resend to owner",
  "applicant-request-form-section-button-refuse-candidate": "Refuse the request",
  "applicant-request-form-section-button-finalise-request": "Finishing the requests",
  "applicant-form-is-new-user": "New user form",
  "confirm-modal-title": "Confirm",
  "confirm-modal-body": "Are you sure you want to create a tenant without email or phone?",
  "confirm-modal-confirm-button": "Confirm",
  "applicant-request-form-section-choice-applicant-address": "Applicant Address",
  "applicant-request-form-section-choice-applicant-address-help-text": "Collect the user current physical address details.",
  "applicant-request-form-section-choice-applicant-status": "Applicant Status",
  "applicant-request-form-section-choice-applicant-status-help-text": "Collect the applicant living status.",
  "applicant-request-form-section-choice-applicant-job": "Applicant Work",
  "applicant-request-form-section-choice-applicant-job-help-text": "Collect the applicant job details such as wage, supervisor etc.",
  "applicant-request-form-section-request-status": "Status of the request",
  "applicant-request-form-section-appicant-issues": "Applicant issues",
  "applicant-request-form-section-appicant-issues-not-fix": "You cannot finalize the request unless the following issues have been fixed:",
  "applicant-request-form-section-appicant-issues-tenant-info-not-completed": "Tenant information not completed",
  "applicant-request-form-section-appicant-issues-no-building-selected": "You have not selected a building",
  "applicant-request-form-section-finalize-request": "Finalize request",
  "applicant-request-form-section-select-building": "Choose a building",
  "applicant-request-form-section-create-lease": "Create the lease",
  "applicant-request-form-section-wating-for-unit-selected": "Waiting for a unit to be selected.",
  "applicant-request-form-section-wating-for-building-selected": "Waiting for a building to be selected.",
  "applicant-request-list-intern-approved": "Internally approved",
  "applicant-request-list-down-payment-amount-received": "Amount received",
  "applicant-request-list-owner-approved": "Approved by owner",
  "applicant-request-list-owner-pending": "Awaiting owner",
  "applicant-request-force-owner-approval": "Force owner approval",
  "applicant-request-list-visit-scheduled": "Visit scheduled",
  "applicant-request-list-signature-scheduled": "Signature scheduled",
  "applicant-request-list-down-payment-amount-received-as-down-payment": "Amount received as down payment",
  "applicant-request-list-visit-completed": "Visit completed",
  "applicant-request-visit-approved": "Agent Approved?",
  "applicant-request-situation-detail-title": "Details of the applicant's situation",
  "applicant-request-current-employer": "Current employer",
  "applicant-request-form-banner-fulfilled": "This resource has been completed. You can no longer modify it.",
  "applicant-request-form-banner-cancelled": "This resource has been cancelled. You can no longer modify it.",
  "applicant-request-empty-state": "There are no applicants at the moment",
  "registration-delete-invite-success": "The invitation has been deleted",
  "registration-delete-invite-error": "An error has occurred. When attempting to remove the invitation, please try again or contact support through the chat tool",
  "registration-resend-email-content": "Resend email invite",
  "registration-delete-invite-content": "Delete invitation",
  "registration-delete-modal-warning": "This invitation will be deleted and the invitation will no longer be valid to register.",
  "registration-delete-modal-last-warning": "Delete invitation",
  "rental-video-link-title": "Video for the listing",
  "rental-video-link-description": "The video you want to add to your listing.",
  "rental-video-link-video-field": "Video link",
  "rental-video-link-error-video-link": "Video link is invalid",
  "rental-video-link-place-holder": "https://www.youtube.com/watch?v=oHg5SJYRHA0",
  "unit-application-title": "List of applications",
  "unit-application-created-date": "Creation date",
  "unit-application-visit-date": "Visit date",
  "tenant-application-title": "Housing application",
  "tenant-application-description": "This tenant was created from an application made on {{date}}",
  "tenant-application-link": "View application details",
  "tenant-application-empty-state": "There are no application at the moment",
  "lease-viewer-status-active": "Active",
  "lease-viewer-status-de-not-renew": "Canceled",
  "unit-status-tenant-changing-status": "There will be a change of tenant at the end of the active lease.",
  "applicant-request-lease-lock": "Lock",
  "applicant-request-lease-un-lock": "Unlock",
  "applicant-request-lease-confirm-modal": "Are you sure you want to change the lease?",
  "applicant-request-cosigner": "This application requires a co-signer",
  "applicant-request-option-bad-credit-score": "Bad credit score",
  "applicant-request-option-listing-no-longer-available": "Listing no longer available",
  "applicant-request-option-refusal-of-visit": "Refused by visit agent",
  "applicant-request-option-refusal-of-owner": "Refused by the owner",
  "applicant-request-option-refusal-of-pass-restricted": "Pass has been restricted",
  "applicant-request-option-other": "Other",
  "applicant-request-refused-on": "This application has been refused on",
  "applicant-request-completed-on": "This application has been completed on",
  "application-consent-maximum_number_of_pets": "Animals",
  "application-consent-maximum_number_of_tenants": "Number of people in the accommodation",
  "application-consent-minimum_salary": "Annual revenue",
  "application-consent-parking_spots_included_in_rent": "Parking Available",
  "application-consent-credit_check": "Credit Check",
  "application-consent-terms_of_services": "Terms of Services",
  "application-consent-is_non_smoking": "Non-smoking",
  "applicant-entry-date-requested": "Entry date requested",
  "applicant-sent-to-landlord-date": "Date sent to owner",
  "applicant-landlord-approved-date": "Date owner approved",
  "applicant-landlord-rejected-date": "Date owner rejected",
  "applicant-agent-assigned-date": "Date visit agent assigned",
  "applicant-invite-code": "Invite Code",
  "applicant-invite-code-tooltip": "Send this code to invite new applicants. Click to copy",
  "applicant-status-pass-expired": "Pass Expired",
  "applicant-status-completed": "Completed",
  "applicant-status-pending-credit-check": "Pending Credit Check Result",
  "applicant-status-payment-pending": "Payment Pending",
  "applicant-status-payment-succeeded-credit-check-unsent": "Payment Succeeded Credit Check Unsent",
  "applicant-status-error": "Error",
  "applicant-status-manually-added": "Manually added",
  "applicantion-step-pm-accepted": "Request accepted",
  "applicantion-step-visit-accepted": "Visit conclusive",
  "applicantion-step-owner-accepted": "Approved by the owner",
  "applicantion-step-documents-uploaded": "Documents",
  "applicant-ranking": "Applicant Ranking",
  "applicant-ranking-tooltip": "Applicants will be submitted to owner based on ranking in this queue",
  "applicant-ranking-submitted-to-owner": "Submitted To Owner",
  "applicant-ranking-submission-date": "Submission Date",
  "applicant-ranking-waiting-list": "Applicant Queue",
  "applicant-ranking-remaining": "Remaining applicants",
  "applicant-ranking-score": "Score",
  "applicant-ranking-listing-header": "All applications on this listing",
  refuse: "Refuse",
  reopen: "Reopen",
  score: "Score",
  received: "Received",
  consents: "Consents",
  "applicant-request-cancel-reason": "The reason for refusal",
  "applicant-request-enter-name": "Enter a name",
  "applicant-request-add-applicant-tab": "Add a new applicant",
  "applicant-request-external": "Added from external",
  "applicant-request-internal": "Added from internal",
  "tickets-title": "Tickets",
  "tickets-add": "Add a new Ticket",
  "tickets-edit": "Edit Ticket",
  "tickets-status-filter": "By Status",
  "tickets-form-title": "Title",
  "tickets-form-origin": "Origin",
  "tickets-form-category": "Category",
  "tickets-form-priority": "Priority",
  "tickets-form-tenant-email": "Tenant Email",
  "tickets-form-due-date": "Due Date",
  "tickets-form-completed-date": "Completion Date",
  "tickets-form-assigned-to": "Assigned to",
  "tickets-form-unit": "Unit",
  "tickets-form-tenant": "Tenant contact",
  "tickets-form-note": "Note",
  "tickets-form-tags": "Tags",
  "tickets-form-property": "Property",
  "tickets-tab-all": "All",
  "tickets-tab-active": "Active",
  "tickets-tab-resolved": "Resolved",
  "tickets-assigned": "is assigned",
  "ticket-detail-title": "Ticket Detail",
  "ticket-detail-description": "Description",
  "ticket-detail-opened-by": "opened by",
  "ticket-detail-comments": "comments",
  "ticket-detail-created": "Created on",
  "ticket-detail-assignees": "Assignees",
  "ticket-detail-labels": "Labels",
  "ticket-detail-edit": "Edit",
  "ticket-confirm-delete": "Are you sure you wish to delete this ticket?",
  "ticket-todo": "To do",
  "ticket-doing": "Doing",
  "ticket-done": "Done",
  "ticket-delete": "Delete Ticket",
  "ticket-add-summary": "Ticket summary",
  "ticket-add-origin": "Ticket origin",
  "ticket-add-category": "Ticket category",
  "ticket-add-priority": "Ticket priority",
  "ticket-add-tenant-email": "Tenant email",
  "ticket-priority-high": "High",
  "ticket-priority-medium": "Medium",
  "ticket-priority-low": "Low",
  "tickets-table-title": "Title",
  "tickets-table-resource": "Resource",
  "tickets-table-category": "Category",
  "tickets-table-priority": "Priority",
  "tickets-table-modified": "Modified",
  "tickets-table-created": "Date created",
  "tickets-table-tasks": "Tasks",
  "tickets-table-conversations": "Conversations",
  "ticket-singular": "Ticket",
  "ticket-plural": "Tickets",
  "ticket-info-summary-placeholder": "Ticket summary...",
  "tickets-info-general-parent-ticket": "Ticket: ",
  "tickets-conversation-plural": "messages",
  "tickets-conversation-singular": "message",
  "tickets-conversation-none": "No messages",
  "tickets-info-header-add-task-button": "Add task",
  "tickets-info-general-create-ticket-button": "Create ticket",
  "tickets-info-general-close-ticket-button": "Close ticket",
  "tickets-info-general-completed-tasks": " completed tasks",
  "tickets-info-general-created-by": "Created by ",
  "tickets-info-general-origin": "Origin ",
  "tickets-info-general-opened-on": "Opened on ",
  "tickets-info-general-modified-on": "Last modified on ",
  "tickets-info-general-closed-on": "Closed on ",
  "tickets-info-general-due-to": "Due to ",
  "tickets-info-general-deleted-on": "Deleted on ",
  "tickets-resource-selector-header": "Type of resource",
  "ticket-sidebar-button-create": "Create",
  "ticket-sidebar-category-error": "Select category!",
  "ticket-sidebar-resource-type-error": "Select resource!",
  "ticket-sidebar-priority-error": "Select priority!",
  "ticket-sidebar-title-error": "Title should be at least 6 symbols long!",
  "events-title": "Events",
  "events-description": "Manage recurring events",
  "event-form-add-event-success": "New event was successfully created.",
  "event-form-add-event-fail": "An error occurred while creating the event. Please try again.",
  "event-form-update-event-success": "Event was successfully updated.",
  "event-form-update-event-fail": "An error occurred while updating the event. Please try again.",
  "event-interval-badge-hourly": "hourly",
  "event-interval-badge-daily": "daily",
  "event-interval-badge-weekly": "weekly",
  "event-interval-badge-monthly": "monthly",
  "event-interval-badge-yearly": "yearly",
  "events-page-title": "Events",
  "events-page-create-button": "Create event",
  "events-page-edit-button": "Edit event",
  "events-list-end-period-first-part": "After ",
  "events-list-end-period-second-part": " times",
  "events-list-end-period-never": "Never",
  "events-list-no-limit": "No limit",
  "events-list-column-title": "Title",
  "events-list-column-interval": "Interval",
  "events-list-column-frequency": "Frequency",
  "events-list-column-start": "Start at",
  "events-list-column-end": "Ends at",
  "events-list-plural": "events",
  "events-list-singular": "event",
  "event-sidebar-title-label": "Title",
  "event-sidebar-title-placeholder": "Title...",
  "event-sidebar-title-error": "Title should be at least 6 symbols long",
  "event-sidebar-category-label": "Category",
  "event-sidebar-category-error": "Select category",
  "event-sidebar-priority-label": "Priority",
  "event-sidebar-priority-error": "Select priority",
  "event-sidebar-resource-type-label": "Type of resource",
  "event-sidebar-resource-type-error": "Select resource",
  "event-sidebar-resource-space": "Buildings",
  "event-sidebar-resource-user": "User",
  "event-sidebar-resource-users-tenants": "Users/Tenants",
  "event-sidebar-resource-project": "Project",
  "event-sidebar-resource-lease": "Lease",
  "providers-title": "Providers",
  "providers-description": "Manage providers",
  "providers-preferred-contact-method": "Preferred contact method",
  "providers-sidebar-preferred-contact-error": "Select preferred contact method",
  "renewal-form-title": "Select your renewal price",
  "renewal-form-description": "Current price: ",
  "renewal-simple-form-title": "Renew lease",
  "renewal-simple-section-title": "Lease information",
  "renewal-simple-form-description": `You can change the price of the renewal. For further changes on the lease select "Activate advanced mode".`,
  "renewal-form-price-pourcentage-title": "Use % increase",
  "renewal-form-price-pourcentage-description": "New price will be calculated from the old price and the pourcentage",
  "renewal-form-price-fix-title": "Manually enter amount",
  "renewal-form-price-fix-description": "Enter the full new price",
  "renewal-form-price-field-pourcentage": "Increase in pourcentage",
  "renewal-form-price-field-manual": "New price",
  "renewal-form-price-renewal": "Renewal price: ",
  "renewal-form-price-increase": "Price increase:",
  "unit-renew-lease": "Renew the lease",
  "renewal-form-date-title": "Select your renewal dates",
  "renewal-form-date-description": "Currently from ",
  "renewal-form-date-extend": "Extend for another year",
  "renewal-form-date-manual": "Manually choose dates",
  "renewal-form-date-renew-form": "Renews from ",
  "renewal-form-modal-title": "Renew lease",
  "renewal-form-toggle-title": "Select renewal type",
  "renewal-form-toggle-description": "Advanced mode allows adding notes and files",
  "renewal-form-toggle-simple": "Simplified",
  "renewal-form-toggle-advance": "Advanced",
  "renewal-form-toggle-activate-simple": "Activate simplified mode",
  "renewal-form-toggle-activate-advance": "Activate advanced mode",
  "renewal-form-toggle-current-mode": "The lease is currently in mode:",
  "renewal-submit-proposal": "Send proposal to tenant",
  "renewal-force-renewal": "Force renewal without confirmation",
  "renewal-cancel-proposal": "Cancel renewal and expire the lease",
  "renewal-form-saved-success": "The renewal proposal has been sent to the tenant/s",
  "renewal-form-renewed-success": "The lease has been renewed",
  "renewal-form-saved-fail": "An error occurred while saving the renewal. Please try again.",
  "renewal-cancel-saved-success": "Lease has been expired",
  "renewal-cancel-saved-fail": "Failed to expire lease",
  "renewal-confirm-force-renewal": "Are you sure you wish to renew the lease without tenant confirmation?",
  "renewal-confirm-cancel": "Are you sure you wish to expire this lease?",
  "tab-to-renew": "To Renew",
  "tab-active-renewals": "Active Renewals",
  "renewal-status-created": "Renewal created",
  "renewal-status-email-sent": "Renewal proposal sent",
  "renewal-status-email-opened": "Renewal proposal Opened",
  "upcoming-renewal": "Upcoming renewal",
  "upcoming-lease": "Upcoming lease",
  "renewal-pending-tenant-approval": "Renewal pending, status: ",
  "upcoming-modification": "Upcoming modification",
  "renewal-status-email-failed": "Email Failed",
  "renewal-form-renewal-details": "Renewal Details",
  "tab-renewal-sent": "Renewal Sent",
  "tab-cancelled": "Cancelled",
  "tab-all-leases": "All Leases",
  "days-remaining": "{{days}} Days remaining for the lease",
  "lease-status": "Lease status: {{leaseStatus}}",
  "weekly-actions-title": "Actions from previous 7 days",
  "widget-chart-no-data-found": "No data found for the period.",
  "PropertyUnion!": "Buildings",
  RentalRequest: "Applicants",
  Project: "Clients",
  Deposit: "Deposits",
  maintenance: "Maintenance",
  prevent: "Prevent",
  management: "Management",
  rental: "Rental",
  coproperty: "Co Property",
  administration: "Administration",
  "payment-issue": "Payment issue",
  payment_issue: "Problème de paiement",
  other: "Other",
  Tenant: "Tenants",
  Unit: "Units",
  Organization: "Organization",
  User: "Users",
  Lease: "Leases",
  Registration: "Invites",
  "audit-updated": "Updated",
  "audit-title": "Latest events",
  "audit-created": "created this resource",
  "change-from": " change from ",
  "audit-to": " to ",
  "hide-detail": "Hide details",
  "view-detail": "View details",
  "primary-phone-mobile": "Primary phone is mobile",
  "secondary-phone-mobile": "Secondary phone is mobile",
  "user-preference-notification-label": "Notifications from the system.",
  "change-from-tag": "Tags have been changed to:",
  "all-tags-been-deleted": "All tags have been removed",
  "profile-menu-item": "Your profile",
  "profile-description": "Manage your contact information and notification settings",
  "organization-menu-item": "Your organization",
  "organization-add": "Add organization",
  "organization-description": "Manage your business settings, users, suppliers, integrations and more",
  "organization-tab-team-members": "Employees",
  "organization-tab-pending-invites": "Pending invites",
  "organization-tab-settings": "Settings",
  "settings-label-name": "Name",
  "settings-label-address": "Address",
  "settings-label-email": "Email",
  "settings-label-phone": "Phone",
  "settings-amount-day-renewal-label": "Amount of days before a lease is due for renewal",
  "settings-automatically-unpublish-label": "Automatically unpublish a listing when a lease is fufilled",
  "settings-new-applicant-label": "Receive new applicant requests notifications to email",
  "settings-listing-url-label": "Website URL for listings published on the system",
  "settings-feature-client-management-label": "Client management",
  "settings-feature-deposit-tracking-label": "Deposits tracking",
  "settings-information-header": "Organization Information",
  "settings-information-description": "Configure how your information is displayed to your users and tenants.",
  "settings-header": "Settings",
  "settings-description": "Configure how the system interacts with your data.",
  "settings-features-header": "Features",
  "settings-features-description": "Activate certain features that will change how Simpleloyer function across your organization",
  "settings-updated": "Your changes have been saved",
  "building-added-responsible": "A building manager has been added: ",
  "applicant-confirm-finalize-modal": "Are you sure you want to finalize the request?",
  "applicant-confirm-delete-modal": "Are you sure you want to delete this applicant?",
  "applicant-confirm-approve-modal": "You can add an additional note for the owner and select the files to attach",
  "applicant-confirm-approve-modal-files-header": "Files to attach to email",
  "applicant-confirm-invite-with-email": "Invite by email",
  "applicant-confirm-bypass-queue": "Bypass Queue",
  "building-management-date": "Management date",
  "listings-title": "Ads",
  "amenities-add-placeholder": "Add amenities...",
  "amenities-title": "Amenities",
  "amenities-description": "Choose the amenities available for this resource. Your choices will be used automatically when you create leases and announcements related to this resource.",
  "amenities-add-unit": "Add amenities to the unit",
  "amenities-add-description": "Search the box below for items to add",
  "amenities-add-building": "Add amenities to the building",
  "amenities-empty-state": "There are no amenities for this resource",
  "listings-singular": "Listing",
  "listings-plurial": "Listings",
  "listings-heading-list": "Published listings",
  "listings-add": "Add Listing",
  "marketing-title": "Listings & Applicants",
  "listings-empty-state": "No listings is posted for the moment",
  "listings-available-on": "Available on",
  "help-page-title": "Support",
  "help-page-description": "Do you have questions or comments ? Let our team take care of it.",
  "help-page-card-1-title": "Instant messaging and email",
  "help-page-card-1-description": "Write to our team for online support. We respond between 10 a.m. and 4 p.m. Eastern Standard Time.",
  "help-page-card-1-action": "Contact us",
  "help-page-card-2-title": "Base of knowledge",
  "help-page-card-2-description": "Find your answers using our articles designed to help you use the Simple Loyer platform.",
  "help-page-card-2-action": "Read our articles",
  "help-page-faq-title": "Frequently Asked Questions",
  "help-page-faq-item-1-q": "What is Simple Loyer?",
  "help-page-faq-item-1-awnser": "Simple Loyer is a property management software. You will be able to manage your buildings, tenants, applicants and much more.",
  "help-page-faq-item-2-q": "How can I register?",
  "help-page-faq-item-2-awnser": "Simple Loyer is still in closed beta and the access is limited to our client partners. Please contact us if you would like to become one of our client partners. If you are an owner and your manager uses Simple Loyer, ask him to send you an invite to the platform.",
  "help-page-faq-item-3-q": "Can I suggest a new feature?",
  "help-page-faq-item-3-awnser": "Of Course! Send us your idea at support@simpleloyer.ca",
  "help-page-faq-item-4-q": "I sent an invite to the wrong client!",
  "help-page-faq-item-4-awnser": "Simply navigate to the “Users” section of the client in question and click “Remove access”.",
  "help-page-faq-item-5-q": "I would like to restore a canceled applicant",
  "help-page-faq-item-5-awnser": "We plan to add this feature in the near future. For now though, you will have to add that client again manually.",
  "help-page-faq-item-6-q": "Why is my building manager inviting me to Simple Loyer?",
  "help-page-faq-item-6-awnser": "This is exciting news! It means that your building manager is using Simple Loyer as one of his management tools. With your free Simple Loyer account, you will have access to all the information related to your buildings and will know what your manager does with them.",
  "help-page-faq-item-7-q": "I hired a building manager, can I use Simple Loyer to follow my buildings?",
  "help-page-faq-item-7-awnser": "Simple Loyer is primarily a software design for building managers. The functionality will far exceed what you would need the platform to do. Why not recommend Simple Loyer to your building manager so you can have a free account to follow your buildings?",
  "help-page-faq-item-8-q": "My building manager uses Simple Loyer, do I need to pay to be able to see my buildings?",
  "help-page-faq-item-8-awnser": "No. Simply ask your building manager to send you an invite to create your free account.",
  "login-remember-me": "Remember me",
  "login-forgot-password": "Forgot your password?",
  "login-continue-with": "Or continue with",
  "login-welcome": "Welcome to",
  "login-sign-up-account": "sign up for a free account with us !",
  "rental-publish-success": "The ad was published successfully",
  "rental-publish-error": "An error occurred while posting your ad. Please try again.",
  "rental-unpublish-success": "The ad has been successfully removed",
  "rental-unpublish-error": "An error occurred while removing your ad. Please try again.",
  "lease-page-title": "Create a lease",
  "lease-page-action": "Create Lease",
  "lease-page-tab-detail": "Details",
  "lease-page-tab-tenant": "Tenants ({{number}})",
  "lease-page-tab-amenities": "Amenities ({{number}})",
  "lease-page-form-property-title": "Property",
  "lease-page-form-property-description": "Select which unit and building your tenants will belong to",
  "lease-page-form-delivery-title": "Delivery",
  "lease-page-form-delivery-description": "Choose how you want this lease to be treated in the system",
  "lease-page-form-payment-title": "Payment",
  "lease-page-form-payment-description": "Determine how the tenant will pay you for the duration of this lease",
  "lease-page-form-date-title": "Date",
  "lease-page-form-date-description": "Set the start date for the lease and the length that it will last",
  "lease-page-form-date-one-year-start-date-title": "1 year from start date",
  "lease-page-form-date-one-year-start-date-description": "Your lease will finish  exactly one year after the initial start date",
  "lease-page-form-date-custom-start-date-title": "Custom start and end dates",
  "lease-page-form-date-custom-start-date-description": "Selecting this option will allow you to select a range for your start date and end date",
  "lease-page-form-date-start-on": "Your lease will begin on ",
  "lease-page-form-date-and-finish-on": " and finish on ",
  "lease-page-form-date-days": "({{days}}) days",
  "lease-page-amenities-select-title": "Select your amenities",
  "lease-page-amenities-select-description": "Choose amenities that you wish to include with this lease. You can optionally assign a price to the amenities you assign by clicking on the sections below",
  "lease-page-form-tenant-remove": "Remove",
  "lease-page-form-tenant-select-placeholder": "Find a tenant in the system",
  "lease-page-form-tenant-add-tenant": "Create a new tenant",
  "lease-page-form-property-select-title": "Select a building",
  "lease-page-form-property-select-placeholder": "Choose a building",
  "lease-page-form-property-select-select-unit": "Select a unit",
  "lease-page-details-pane-selected": "{{number}} Selected",
  "lease-viewer-new-item": "New",
  "lease-page-details-addons": "Addons",
  "lease-page-details-lease-detail": "Lease details",
  "lease-page-details-pricing": "Pricing",
  "lease-payee-checkbox": "Payee",
  "lease-form-cancel-lease": "Cancel the lease",
  "lease-form-cancel-lease-description": "This action will cancel the lease at the lease end date",
  "lease-form-cancel-lease-checkbox-label": "The cancellation will happen earlier",
  "rental-publish-ad-description": "By posting the listing it will be visible to everyone on your website.",
  "rental-remove-ad-description": "This listing will no longer be accessible to the public.",
  "rental-modify-success": "The listing has been successfully modified",
  "listing-add-feature-image": "Add a featured image",
  "listing-information": "Informations",
  "listing-description": "Descriptions",
  "admin-dashboard-title": "Admin Dashboard",
  organization: "organization",
  organizations: "organizations",
  "organization-form-saved-success": "The organization has been saved.",
  "organization-form-saved-fail": "An error occurred while saving the organization. Try Again.",
  "organization-form-modify": "Modify organization",
  "organization-form-create": "Add organization",
  "organization-form-main-header": "Organization Profile",
  "organization-form-main-description": "Configure how the profile is displayed to users and tenants",
  "organization-form-address-header": "Organization Address",
  "organization-form-address-description": "Change address associated with this organization",
  "organization-form-phone-header": "Organization Contact",
  "organization-form-phone-description": "Change contact information associated with this organization",
  "organization-form-settings-header": "Organization Settings",
  "organization-form-settings-description": "Change settings associated with this organization",
  "login-error-org-missing": "Failed to fetch organizations",
  "notification-panel-title": "Notify your tenants",
  "notification-panel-subtitle": "Choose the filter that will notify tenants by email.",
  "notification-panel-cat-clients": "Clients",
  "notification-panel-cat-tags": "Tags",
  "notification-panel-cat-buildings": "Buildings",
  "notification-panel-cat-tenants": "Tenants",
  "notification-panel-select-unit": "All the units",
  "notification-panel-placeholder-clients": "Select a client...",
  "notification-panel-placeholder-tags": "Type something and press enter...",
  "notification-panel-rich-text-title": "Email Body",
  "notification-panel-action": "Continue",
  "notification-panel-select-clients": "Filter tenants by client",
  "notification-panel-select-buildings": "Filter tenants by Building",
  "notification-panel-select-tags": "Filter tenants by building tag",
  "notification-panel-select-tenants": "Select a list of tenants",
  "notification-menu-action-title": "Send message",
  "notification-success": "The message has been sent to {{tenant}} people",
  "notification-failure": "An error occurred while sending the message",
  "notification-empty-state": "No communications has been sent for the moment",
  "notification-choose-language": "Choose the language of the message",
  communication: "Communication",
  communications: "Communications",
  CommunicationResult: "Communications",
  "communication-list-title": "List of communications",
  "communication-heading-communication-type": "Type of message",
  "communication-heading-subject": "Subject",
  "communication-heading-message": "Message",
  "communication-heading-status": "Status",
  "communication-heading-sended-date": "Date sent",
  "communication-heading-type-selection": "Selected Resource",
  "communication-heading-user-who-send-message": "Sent by",
  "communication-type-custom": "Personalized message",
  "communication-type-template": "Preset message",
  "communication-status-opened": "Opened",
  "communication-status-delivered": "Delivered",
  "communication-status-processed": "Processed",
  "communication-status-unsent": "Unsent",
  "communication-affected-people": "Affected people :",
  "communication-subject-required": "A subject is required",
  "user-form-modify": "Modify user",
  "user-form-create": "Add user",
  "user-form-main-header": "User Profile",
  "user-form-main-description": "Modify user profile details",
  "user-form-permissions-header": "User Permissions",
  "user-form-permissions-description": "Modify access levels for this user",
  "audit-note": "A note was ",
  "audit-files": "A file was ",
  payments: "Payments",
  payment: "Payment",
  amount: "Amount",
  "status-tentative": "Tentative",
  "status-erroneous": "Erroneous",
  "status-processing": "Processing",
  "status-completed": "Completed",
  "list-heading-payments": "List of payments",
  "panel-title": "Payment information",
  "panel-payment-activity": "Payment activity",
  "panel-payment-created": "Payment created",
  "panel-payment-accepted": "Payment accepted",
  "panel-payment-type": "Type of payment",
  "panel-payment-type-automatic": "Automatic payment",
  account: "Account",
  "account-person-name": "Name of account holder",
  "account-add-account": "Add a account",
  "account-finish-by": "Account {{bankName}} finishing by {{accountNumber}}",
  "account-form-title": "Add an account",
  "account-form-section-title": "Please enter account information",
  "account-form-transit-number": "Transit number",
  "account-form-institution-number": "Institution number",
  "account-form-bank-account-number": "Account number",
  "account-field-invalid": "The field is invalid",
  "no-account-linked": "No account has been linked",
  "lease-page-tab-history": "History",
  "lease-status-active": "Active",
  "lease-status-not-renewed": "Not Renewed",
  "lease-status-request-in-flight": "Request In-Flight",
  "lease-status-renewal-request-awaiting-client-approval": "Renewal Request Awaiting Client Approval",
  "lease-status-display-active": "The lease is active",
  "lease-status-display-not-renewed": "The lease will not be renewed",
  "lease-status-display-request-in-flight": "This lease is in RequestInFlight",
  "lease-status-display-renewal-request-awaiting-client-approval": "The lease is in request for renewal pending client approval",
  "building-info-only-location": "You only managed the rental of this client.",
  "occupation-title-widget": "Occupancy rate per unit",
  "applicant-title-widget": "Candidate status",
  "applicant-cancel": "Candidate refused",
  "applicant-approved": "Approved candidate",
  "applicant-approved-by-owner": "Owner approved candidate",
  "applicant-visit-scheduled": "Scheduled visit",
  "occupation-180-days": "180 Days",
  "occupation-90-days": "90 Days",
  "occupation-60-days": "60 Days",
  "occupation-30-days": "30 Days",
  "occupation-vacant": "Vacant Units",
  "lease-renewal-title-widget": "Lease renewal sent",
  "lease-renewal-seen-not-answered": "Renewal seen in last 30 days but not answered",
  "lease-renewal-unseen": "Renewal still unseen",
  "lease-renewal-not-renrewing": "Tenant will not renew",
  "lease-renewal-will-renew": "Tenant will renew",
  "ticket-title-widget": "Ticket",
  "ticket-pending": "Pending",
  "ticket-unanswered": "New unanswered",
  "ticket-unsolved": "Unsolved",
  "source-application-title-widget": "Source of application",
  "source-application-intern": "Intern",
  "source-application-external-website": "External website",
  "source-application-external-facebook": "Facebook",
  "source-application-external-kijiji": "Kijiji",
  "listings-title-widget": "Number of applicant and Listings",
  "listings-listing-created": "Number of listings",
  "listings-number-applicant": "Number of applicant",
  "listings-number": "Number of",
  "listings-date": "Date",
  "selector-label": "Email Templates",
  "selector-label-snow-removal": "Snow removal",
  "selector-label-air-conditioning": "Air conditioning",
  "selector-label-de-ice": "Ice removal",
  "selector-label-exterminator": "Exterminator",
  "selector-label-office-closing": "Fermeture des bureaux temps des fêtes",
  "selector-label-rental-agent": "Rental agent",
  "selector-label-smoke-detector": "Smoke detector",
  "applicant-request-status-active": "Active",
  "applicant-request-status-approved": "Approved",
  "applicant-request-status-fulfilled": "Fulfilled",
  "applicant-request-status-cancelled": "Cancelled",
  "applicant-request-status-incomplete": "Incomplete",
  "applicant-request-status-denied": "Denied",
  "lease-state-needs-renewal": "Needs renewal",
  "no-hit": "No Hit",
  "lease-state-initial": "Initial",
  "lease-state-archived": "Archived",
  "choose-option": "You must select an option",
  "applicant-request-form-section-finalize-description": "Enter the details of the lease",
  "applicant-request-form-section-finalize-success": "The finalization completed successfully",
  "applicant-request-form-section-finalize-failiure": "An error occurred while finalising this application. Please try again.",
  "applicant-request-form-section-finalize-conflict": "There is another lease on this unit with an overlapping term",
  "applicant-request-form-section-unique-constraint": "There is already an applicant with this email added",
  "applicant-request-form-section-delete-last": "Cannot delete only applicant on application.",
  [FeatureType.FurnitureCommodes]: "Furniture commodes",
  [FeatureType.SmallDogsAllowed]: "Small dogs allowed",
  [FeatureType.LargeDogsAllowed]: "Large dogs allowed",
  [FeatureType.CatsAllowed]: "Cats allowed",
  [FeatureType.SmokingAllowed]: "Smoking allowed",
  [FeatureType.ExtensionsGasHeating]: "Gas heating",
  [FeatureType.UndergroundGarage]: "Underground garage",
  [FeatureType.StreetParking]: "Exterior parking",
  [FeatureType.CoveredGarage]: "Covered garage",
  [FeatureType.GarageParkingSingle]: "Garage parking single",
  [FeatureType.GarageParkingDouble]: "Garage parking double",
  [FeatureType.GarageParkingTriple]: "Garage parking triple",
  [FeatureType.ExtensionsCentralAirconditioning]: "Central airconditioning",
  [FeatureType.ExtensionsCableInternet]: "Cable internet",
  [FeatureType.ExtensionsElectricHeating]: "Electric heating",
  [FeatureType.ExtensionsFiberInternet]: "Fiber internet",
  [FeatureType.DisabledAccess]: "Disabled access",
  [FeatureType.YardAccess]: "Yard access",
  [FeatureType.BuildingElevator]: "Building elevator",
  [FeatureType.BuildingGym]: "Gym access",
  [FeatureType.BuildingPool]: "Pool access",
  [FeatureType.BuildingTerrace]: "Terrace access",
  [FeatureType.AppliancesJacuzzi]: "Jacuzzi access",
  [FeatureType.ExtensionsBalcony]: "Balcony access",
  [FeatureType.ExtensionsCable]: "Cable included",
  [FeatureType.ExtensionsElectricity]: "Electricity included",
  [FeatureType.ExtensionsPortableAirconditioning]: "Portable airconditioning included",
  [FeatureType.ExtensionsWater]: "Water included",
  [FeatureType.ExtensionsWaterTank]: "Water heater tank installation date",
  [FeatureType.ExtensionsCounterType]: "Counter type",
  [FeatureType.ExtensionsBlinds]: "Blinds included",
  [FeatureType.LaundryRoomBuilding]: "Laundry room access",
  [FeatureType.WasherDryerOutlet]: "Washer dryer outlet",
  [FeatureType.AppliancesLockerStorageSpace]: "Locker storage space included",
  [FeatureType.AppliancesFurnitureLeaseAndIncludedInPrice]: "Household appliances rental is included in the price",
  [FeatureType.AppliancesStove]: "Stove included",
  [FeatureType.AppliancesOven]: "Oven included",
  [FeatureType.AppliancesMicrowave]: "Microwave included",
  [FeatureType.AppliancesDishwasher]: "Dishwasher included",
  [FeatureType.AppliancesRefrigerator]: "Refrigerator included",
  [FeatureType.AppliancesWasher]: "Washer included",
  [FeatureType.AppliancesDryer]: "Dryer included",
  [FeatureType.FurnitureNumberOfTables]: "Number of tables",
  [FeatureType.FurnitureNumberOfChairs]: "Number of chairs",
  [FeatureType.FurnitureNumberOfChestsOfDrawers]: "Number of chest of drawers",
  [FeatureType.FurnitureNumberOfCouch]: "Number of couch",
  [FeatureType.FurnitureNumberOfArmchair]: "Number of armchair",
  [FeatureType.FurnitureNumberOfBed]: "Number of beds",
  "consent-value-pet": "Animals allowed",
  "consent-value-tenant": "Limited number of tenants",
  "consent-value-salary": "Minimum annual income",
  "consent-value-parking": "Limited parking",
  "consent-value-credit": "Credit inquiry check",
  "consent-value-terms-service": "Accept terms of use",
  "consent-value-smoking": "Smoking not allowed",
  "consent-value-pet-input": "Number of animals allowed",
  "consent-value-tenant-input": "Maximum number of tenants",
  "consent-value-salary-input": "Minimum annual income required",
  "consent-value-parking-input": "Number of authorized parking spaces",
  "consent-value-credit-input": "Credit check required",
  "consent-value-terms-service-input": "Accept terms of use required",
  "consent-value-smoking-input": "Smoking not allowed",
  "applicants-v2-file-tab-other": "Other",
  "applicants-v2-file-tab-lease": "Lease",
  "applicants-v2-file-tab-insurance": "Insurance",
  "applicants-v2-file-tab-utility": "Utility",
  "applicants-v2-file-tab-building_regulation": "Building regulation",
  "applicants-v2-file-tab-signature_required": "To sign",
  "applicants-v2-file-tab-check": "Check",
  "applicant-request-progress-visit-accepted": "Visit accepted",
  "applicant-request-progress-approved-internally": "Internally approved",
  "applicant-request-progress-owner-approved": "Approved by customer",
  "applicant-request-progress-documents-uploaded": "Documents uploaded",
  "applicant-request-no-progress": "No Progress",
  "applicant-request-progress-placeholder": "Progress",
  "delivery-edit-title": "Edit delivery",
  "delivery-key-status": "Keys status",
  "delivery-key-delivered": "Keys delivered",
  "delivery-key-not-delivered": "Keys not delivered",
  "delivery-document-delivered": "Document delivered",
  "delivery-document-not-delivered": "Document not delivered",
  "delivery-document-status": "Document status",
  "delivery-downpayment-not-delivered": "Downpayment not received",
  "delivery-downpayment-partial": "Downpayment partially received",
  "delivery-downpayment-received": "Downpayment received",
  "delivery-marked-delivered": "Mark as delivered",
  "delivery-form-success": "Your delivery was succesfully saved.",
  "delivery-form-error": "An error occurred while saving this delivery. Please try again.",
  "lease-agent": "Lease agent",
  "applicant-request-form-visit-agent-title": "Visit agent",
  "previous-tenant": "Previous tenant",
  "applicant-request-list-lease-doc-sent": "Lease documents sent to tenant",
  "ticket-empty-state-title": "There are no tasks yet",
  "ticket-empty-state-description": "Add a task to track assignees, billing and due dates",
  "ticket-empty-state-cta": "Add a task",
  "ticket-empty-state-cta-id": "Create ticket first",
  "task-status": "Task status",
  "calendar-filters": "Calendar filters",
  "ticket-manual": "Manuel",
  "ticket-email": "Email",
  "ticket-recurring-event": "Recurring event",
  "ticket-user-submission": "User submission",
  "type-entity": "Type of entity",
  "type-ticket": "Type of ticket",
  "event-title": "Event title",
  "create-recurring-ticket": "Create recurring ticket",
  "recurring-on": "Recurring on {{date}}",
  "ticket-update-title": "Update ticket",
  "tickets-create-singular-title": "Create a task",
  "tickets-create-singular-details": "You want to create a task and immediately assign it to a responsible",
  "tickets-create-multiple-title": "Create a ticket with multiple tasks",
  "tickets-create-multiple-details": "You have a case to settle with possibly several tasks",
  "rent-roll-title": "Rentroll report",
  "rent-roll-description": "The rent roll shows the rental rate for each of the landlord's properties.",
  "applicant-request-button-choose-applicant": "Choose this applicant",
  "applicant-request-button-has-been-choosen": "This applicant has been chosen for this unit",
  "applicant-request-status": "Chosen"
};
export default en;