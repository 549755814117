/* eslint-disable no-underscore-dangle */
import { TYPES } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { mapFiles } from "../../utils/Files.js";
import { mapNotes } from "../../utils/Notes.js";
import { all, getContext, put, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { AddDepositDocument, DeleteDepositDocument, GetDepositDocument, UpdateDepositDocument } from "../../../graphql/documents.js";
import { ApplicationActionsTypes } from "../Application/types.js";
import { addFilesHandler, uploadDocuments } from "../Files/sagas.js";
import { getDeposit } from "./index.js";
import { DepositActionsTypes, initialState } from "./types.js";

// =========== REDUCERS =========== //
const setInitialState = (data, state) => {
  if (data.payload.kind === "WithDepositId") {
    return { ...state,
      id: data.payload.depositId
    };
  }

  if (data.payload.kind === "WithContext") {
    if (!data.payload.deposit.amount) {
      return { ...initialState,
        ...data.payload.deposit
      };
    }

    return { ...state,
      ...data.payload.deposit,
      files: mapFiles(data.payload.deposit.files)
    };
  }

  if (data.payload.kind === "NoContext") {
    return { ...state,
      ...initialState
    };
  }

  return state;
};

const setField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};

const addDepositNotes = (data, state) => ({ ...state,
  notes: data.payload
});

const removeDepositNotes = (data, state) => {
  return { ...state,
    notes: state.notes.filter(note => note.id !== data.payload)
  };
};

const setServerDepositState = (data, state) => {
  if (data.payload.kind === "Update") {
    return state;
  }

  if (data.payload.kind === "Create") {
    return state;
  }

  if (data.payload.payload.kind === "Success") {
    if (data.payload.payload.payload.deposit.__typename === "DepositGQL") {
      const {
        files,
        notes
      } = data.payload.payload.payload.deposit;
      return { ...state,
        ...data.payload.payload.payload.deposit,
        files: mapFiles(files.items),
        notes: mapNotes(notes.items),
        serverDeposit: data.payload.payload
      };
    }
  }

  return { ...state,
    serverDeposit: data.payload.payload
  };
};

const toDepositInputMapper = deposit => {
  const {
    amount,
    depositDate,
    projectId,
    responsableId
  } = deposit;
  return {
    amount,
    projectId,
    depositDate
  };
}; // =========== SAGAS =========== //


function* updateExistingDeposit(id, deposit, files) {
  const client = yield getContext("client");
  const toast = yield getContext("toast");
  const t = yield getContext("t");
  yield put({
    type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
    payload: {
      kind: "Update",
      payload: {
        kind: "Loading"
      }
    }
  });

  try {
    const query = client.query({
      query: UpdateDepositDocument,
      fetchPolicy: "no-cache",
      variables: {
        deposit: {
          id,
          deposit: toDepositInputMapper(deposit)
        }
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put({
        type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
        payload: {
          kind: "Update",
          payload: {
            kind: "Failure",
            error: [...errors]
          }
        }
      });
      yield put({
        type: DepositActionsTypes.DEPOSIT_INITIALIZE,
        payload: {
          kind: "WithContext",
          deposit
        }
      });
      toast.error(t("deposit-form-add-fail"));
    } else {
      yield addFilesHandler(files, data.updateDeposit.id, RESOURCES.Deposit);
      yield put({
        type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
        payload: {
          kind: "Update",
          payload: {
            kind: "Success",
            payload: data
          }
        }
      });
      toast.success(t("deposit-form-add-success"));
      yield put({
        type: DepositActionsTypes.DEPOSIT_INITIALIZE,
        payload: {
          kind: "WithDepositId",
          depositId: data.updateDeposit.id
        }
      });
    }
  } catch (e) {
    yield put({
      type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
      payload: {
        kind: "Update",
        payload: {
          kind: "Failure",
          error: e
        }
      }
    });
    yield put({
      type: DepositActionsTypes.DEPOSIT_INITIALIZE,
      payload: {
        kind: "WithContext",
        deposit
      }
    });
    toast.error(t("deposit-form-add-fail"));
  }
}

function* createDeposit(deposit, files) {
  const client = yield getContext("client");
  const toast = yield getContext("toast");
  const t = yield getContext("t");
  yield put({
    type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
    payload: {
      kind: "Create",
      payload: {
        kind: "Loading"
      }
    }
  });

  try {
    const query = client.query({
      query: AddDepositDocument,
      fetchPolicy: "no-cache",
      variables: {
        deposit: toDepositInputMapper(deposit)
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put({
        type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
        payload: {
          kind: "Create",
          payload: {
            kind: "Failure",
            error: [...errors]
          }
        }
      });
      toast.error(t("deposit-form-add-fail"));
    } else {
      yield addFilesHandler(files, data.addDeposit.id, RESOURCES.Deposit);
      yield put({
        type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
        payload: {
          kind: "Create",
          payload: {
            kind: "Success",
            payload: data
          }
        }
      });
      toast.success(t("deposit-form-add-success"));
    }
  } catch (e) {
    yield put({
      type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
      payload: {
        kind: "Create",
        payload: {
          kind: "Failure",
          error: e
        }
      }
    });
    toast.error(t("deposit-form-add-fail"));
  }
}

function* onSubmitForm(data) {
  const tenant = yield select(getDeposit);
  const history = yield getContext("history");
  const files = yield uploadDocuments(data.payload.files || [], RESOURCES.Deposit, TYPES.Files); // yield put<InitializeDepositState>({ type: DepositActionsTypes.DEPOSIT_INITIALIZE, payload: { kind: "WithContext", deposit: data.payload } });

  if (tenant.id) {
    yield updateExistingDeposit(tenant.id, data.payload, files);
  } else {
    yield createDeposit(data.payload, files);
  }

  yield put({
    type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
    payload: null
  });
  history.push(`/deposits`);
}

function* onDeleteDeposit(data) {
  const deposit = yield select(getDeposit);
  const client = yield getContext("client");
  const toast = yield getContext("toast");
  const t = yield getContext("t");

  try {
    const query = client.query({
      query: DeleteDepositDocument,
      fetchPolicy: "no-cache",
      variables: {
        id: deposit.id
      }
    });
    const {
      errors
    } = yield query;

    if (errors?.length > 0) {
      toast.error(t("deposit-form-add-fail"));
    } else {
      toast.success(t("deposit-form-add-success"));
      yield put({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: null
      });
    }
  } catch (e) {
    toast.error(t("deposit-form-add-fail"));
  }
}

function* onInitializeDeposit($data) {
  const client = yield getContext("client");
  const {
    payload
  } = $data;

  switch (payload.kind) {
    // case "WithContext":
    // State is already changed from reducer.
    case "WithDepositId":
      {
        yield put({
          type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
          payload: {
            kind: "Query",
            payload: {
              kind: "Loading"
            }
          }
        });
        const query = client.query({
          query: GetDepositDocument,
          fetchPolicy: "no-cache",
          variables: {
            id: payload.depositId
          }
        });
        const {
          data,
          error
        } = yield query;

        if (error) {
          yield put({
            type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
            payload: {
              kind: "Query",
              payload: {
                kind: "Failure",
                error
              }
            }
          });
        }

        yield put({
          type: DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS,
          payload: {
            kind: "Query",
            payload: {
              kind: "Success",
              payload: data
            }
          }
        });
        break;
      }

    case "NoContext":
    default:
      // State is already changed from reducer.
      break;
  }
}

export default {
  reducers: {
    [DepositActionsTypes.DEPOSIT_INITIALIZE]: setInitialState,
    [DepositActionsTypes.DEPOSIT_SET_FIELD]: setField,
    [DepositActionsTypes.DEPOSIT_SET_SERVER_DEPOSIT_STATUS]: setServerDepositState,
    [DepositActionsTypes.DEPOSIT_ADD_NOTES]: addDepositNotes,
    [DepositActionsTypes.DEPOSIT_REMOVE_NOTES]: removeDepositNotes
  },

  *executers() {
    yield all([yield takeLatest(DepositActionsTypes.DEPOSIT_INITIALIZE, onInitializeDeposit), yield takeLatest(DepositActionsTypes.DEPOSIT_SUBMIT, onSubmitForm), yield takeLatest(DepositActionsTypes.DEPOSIT_DELETE, onDeleteDeposit)]);
  }

};