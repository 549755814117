import React from "../../../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../../../_snowpack/pkg/react-router.js";
import { Tooltip } from "../../../../../_snowpack/pkg/react-tippy.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { FaTag, FaTags, FaChevronUp, FaUserCircle, FaPenNib, FaLayerGroup, FaUser, FaMailBulk, FaCalendar, FaEdit, FaClock, FaCheck, FaTrash, FaFileInvoice, FaTicketAlt } from "../../../../../_snowpack/pkg/react-icons/fa.js";
import { MdDone } from "../../../../../_snowpack/pkg/react-icons/md.js";
import { HiClipboardList, HiOfficeBuilding, HiUsers } from "../../../../../_snowpack/pkg/react-icons/hi.js";
import { Badge } from "../../../components/Tailwind/Badge.js";
import { prioritiesBadges } from "../../../components/TicketInfo/TicketInfoSidebar/TicketInfoSidebarGeneral.js";
import useI18n from "../../../hooks/useI18n.js";
import { useQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { GetTaskDocument, GetTicketCalendarDocument } from "../../../graphql/documents.js";
import { client } from "../../../apollo/index.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
const resources = {
  leasegql: {
    title: "Lease",
    icon: __cssprop(HiClipboardList, {
      css: {
        "height": "2.4rem",
        "width": "2.4rem"
      },
      color: "#6366f1"
    })
  },
  projectgql: {
    title: "Project",
    icon: __cssprop(HiOfficeBuilding, {
      css: {
        "height": "2.4rem",
        "width": "2.4rem"
      },
      color: "#6366f1"
    })
  },
  unitgql: {
    title: "Space",
    icon: __cssprop(HiOfficeBuilding, {
      css: {
        "height": "2.4rem",
        "width": "2.4rem"
      },
      color: "#6366f1"
    })
  },
  usergql: {
    title: "Tenant",
    icon: __cssprop(HiUsers, {
      css: {
        "height": "2.4rem",
        "width": "2.4rem"
      },
      color: "#6366f1"
    })
  }
};

const TaskItemContent = ({
  id,
  backgroundColor,
  t,
  history
}) => {
  // here giving the client to useQuery because somehow they are not render under context providers
  const {
    data,
    loading
  } = useQuery(GetTaskDocument, {
    variables: {
      taskId: id
    },
    client
  });

  if (!data || loading) {
    return null;
  }

  const {
    ticket,
    closedAt,
    dueDate,
    invoice,
    title,
    createdAt,
    modifiedAt,
    modifiedBy,
    createdBy,
    assignee
  } = data.task;

  const handleTicketTitleClick = ticketId => {
    history.push(`/ticket/${ticketId}`);
  };

  return __cssprop("div", {
    css: {
      "paddingBottom": "0.8rem",
      "width": "32rem"
    }
  }, __cssprop("div", {
    css: {
      "marginBottom": "0.8rem",
      "width": "100%",
      "padding": "0.8rem"
    },
    style: {
      backgroundColor
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "2rem",
      "lineHeight": "2.8rem",
      "fontWeight": "700",
      "whiteSpace": "normal",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      "textAlign": "left"
    }
  }, t("task"))), __cssprop("div", {
    css: {
      "padding": "0.8rem"
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "2rem",
      "lineHeight": "2.8rem",
      "fontWeight": "600",
      "marginBottom": "0.8rem",
      "whiteSpace": "normal",
      "textAlign": "left"
    }
  }, title), __cssprop("hr", {
    css: {
      "marginBottom": "1.6rem"
    }
  }), __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.8rem"
    }
  }, assignee && __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaUser, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, assignee?.name)), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaMailBulk, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, assignee?.email))), ticket && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaTicketAlt, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, t("tickets-info-general-parent-ticket"), __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(59, 130, 246, var(--tw-text-opacity))",
      "textDecoration": "underline",
      "cursor": "pointer"
    },
    tabIndex: 0,
    role: "link",
    onKeyPress: () => handleTicketTitleClick(ticket?.id),
    onClick: () => handleTicketTitleClick(ticket?.id)
  }, ticket?.title))), createdBy && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaUserCircle, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, `${t("tickets-info-general-created-by")}${createdBy?.name}`)), createdAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaCalendar, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, `${t("tickets-info-general-opened-on")}${DateTime.fromISO(`${createdAt}`).setLocale("en").toFormat("LLL dd")}`)), closedAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(MdDone, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, `${t("tickets-info-general-closed-on")}${DateTime.fromISO(`${closedAt}`).setLocale("en").toFormat("LLL dd")}`)), modifiedAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaEdit, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, `${t("tickets-info-general-modified-on")}${DateTime.fromISO(`${modifiedAt}`).setLocale("en").toFormat("LLL dd")}`)), dueDate && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaClock, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, `${t("tickets-info-general-due-to")}${DateTime.fromISO(`${dueDate}`).setLocale("en").toFormat("LLL dd")}`)), invoice && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaFileInvoice, {
    size: 20,
    color: "#6366f1"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem"
    }
  }, "Invoice amount ", invoice?.amount, "$")))));
};

const TaskRenderer = ({
  id,
  title,
  type,
  backgroundColor,
  closedAt,
  deletedAt
}) => {
  const {
    t
  } = useI18n();
  const history = useHistory();
  return __cssprop("div", {
    css: {
      "cursor": "pointer"
    }
  }, __cssprop(Tooltip, {
    position: "right",
    trigger: "click",
    animation: "none",
    interactive: true,
    theme: "light",
    delay: 0,
    duration: 0 // unmountHTMLWhenHide
    ,
    hideDelay: 0,
    animateFill: false,
    html: __cssprop(TaskItemContent, {
      backgroundColor: backgroundColor,
      id: id,
      t: t,
      history: history
    })
  }, __cssprop("p", {
    "data-tip": true,
    "data-for": id,
    css: {
      "display": "flex",
      "alignItems": "center"
    }
  }, "[", type, "] ", title, " ", closedAt && __cssprop(FaCheck, {
    size: 18,
    color: "#07bc0c",
    css: {
      "marginLeft": "auto",
      "marginRight": "0.4rem"
    }
  }), " ", deletedAt && __cssprop(FaTrash, {
    size: 16,
    color: "#f9fafb",
    css: {
      "marginLeft": "auto",
      "marginRight": "0.4rem"
    }
  }))));
};

const TicketRenderer = ({
  title,
  type,
  backgroundColor,
  description,
  id
}) => {
  const {
    t
  } = useI18n(); // here giving the client to useQuery because somehow they are not render under context providers

  const {
    data,
    loading
  } = useQuery(GetTicketCalendarDocument, {
    variables: {
      ticketId: id
    },
    client
  });

  if (!data || loading) {
    return null;
  }

  const {
    title: entityTitle,
    category,
    priority,
    resource,
    tags
  } = data.ticket;
  const {
    project,
    space,
    tenant
  } = resource || {};
  const resourceTitles = tenant?.name || project?.name || space?.sector || space?.buildingAddress && `${space?.buildingAddress}${space?.subAddress}`;
  const selectedResource = project || space || tenant;
  return __cssprop("div", {
    css: {
      "cursor": "pointer"
    }
  }, __cssprop(Tooltip, {
    position: "right",
    trigger: "click",
    interactive: true,
    animation: "none",
    theme: "light",
    delay: 0,
    duration: 0,
    animateFill: false,
    html: __cssprop("div", {
      css: {
        "paddingBottom": "0.8rem",
        "width": "32rem"
      }
    }, __cssprop("div", {
      css: {
        "marginBottom": "0.8rem",
        "width": "100%",
        "padding": "0.8rem"
      },
      style: {
        backgroundColor
      }
    }, __cssprop("p", {
      css: {
        "fontSize": "2rem",
        "lineHeight": "2.8rem",
        "fontWeight": "700",
        "whiteSpace": "normal",
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
        "textAlign": "left"
      }
    }, " ", type)), __cssprop("div", {
      css: {
        "padding": "0.8rem"
      }
    }, __cssprop("p", {
      css: {
        "fontSize": "2rem",
        "lineHeight": "2.8rem",
        "fontWeight": "600",
        "marginBottom": "0.8rem",
        "whiteSpace": "normal",
        "textAlign": "left"
      }
    }, title), __cssprop("p", {
      css: {
        "fontSize": "1.6rem",
        "lineHeight": "2.4rem",
        "marginBottom": "0.4rem",
        "whiteSpace": "normal",
        "textAlign": "left"
      }
    }, description), __cssprop("hr", {
      css: {
        "marginBottom": "1.6rem"
      }
    }), entityTitle && __cssprop("div", {
      css: {
        "display": "flex",
        "flexDirection": "column",
        "gap": "0.8rem"
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "gap": "0.8rem"
      }
    }, __cssprop("div", {
      css: {
        "gridColumn": "span 3 / span 3"
      }
    }, __cssprop(FaPenNib, {
      size: 20,
      color: "#6366f1"
    })), __cssprop("div", {
      css: {
        "gridColumn": "span 9 / span 9",
        "fontSize": "1.6rem",
        "lineHeight": "2.4rem"
      }
    }, entityTitle)), priority && __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "gap": "0.8rem"
      }
    }, __cssprop("div", {
      css: {
        "gridColumn": "span 3 / span 3"
      }
    }, __cssprop(FaChevronUp, {
      size: 20,
      color: "#6366f1"
    })), __cssprop("div", {
      css: {
        "gridColumn": "span 9 / span 9",
        "width": "100%",
        "display": "flex",
        "justifyContent": "flex-start"
      }
    }, prioritiesBadges(t)[priority])), category && __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "gap": "0.8rem"
      }
    }, __cssprop("div", {
      css: {
        "gridColumn": "span 3 / span 3"
      }
    }, __cssprop(FaTag, {
      size: 20,
      color: "#6366f1"
    })), __cssprop("div", {
      css: {
        "gridColumn": "span 9 / span 9",
        "width": "100%",
        "display": "flex",
        "justifyContent": "flex-start"
      }
    }, __cssprop(Badge, {
      primary: true
    }, category))), selectedResource && __cssprop(React.Fragment, null, __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "gap": "0.8rem"
      }
    }, __cssprop("div", {
      css: {
        "gridColumn": "span 3 / span 3"
      }
    }, __cssprop(FaLayerGroup, {
      size: 20,
      color: "#6366f1"
    })), __cssprop("div", {
      css: {
        "gridColumn": "span 9 / span 9",
        "fontWeight": "700",
        "fontSize": "1.6rem",
        "lineHeight": "2.4rem"
      }
    }, `${resources[selectedResource?.__typename.toLowerCase()]?.title}: ${resourceTitles}`))), tags?.length > 0 && __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "gap": "0.8rem"
      }
    }, __cssprop("div", {
      css: {
        "gridColumn": "span 3 / span 3"
      }
    }, __cssprop(FaTags, {
      size: 20,
      color: "#6366f1"
    })), tags?.map(tag => __cssprop(Badge, {
      primary: true
    }, tag))))))
  }, __cssprop("p", {
    "data-tip": true,
    "data-for": id
  }, "[", type, "] ", title)));
};

export const renderCalendarEventContent = eventInfo => {
  switch (eventInfo?.event?.extendedProps?.type) {
    case "Recurring Ticket":
      return __cssprop(TicketRenderer, {
        id: eventInfo.event?.id,
        title: eventInfo?.event?.title,
        type: eventInfo.event?.extendedProps?.type,
        backgroundColor: eventInfo?.event?.backgroundColor,
        description: eventInfo.event.extendedProps?.description
      });

    case "Task":
      return __cssprop(TaskRenderer, {
        id: eventInfo.event?.id,
        type: eventInfo.event?.extendedProps?.type,
        title: eventInfo?.event?.title,
        backgroundColor: eventInfo?.event?.backgroundColor,
        deletedAt: eventInfo.event?.extendedProps?.deletedAt,
        closedAt: eventInfo.event?.extendedProps?.closeAt
      });

    default:
      return null;
  }
};