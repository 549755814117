import { EditorState, ContentState, ContentBlock } from "../../../_snowpack/pkg/draft-js.js";
const frenchTemplateString = {
  "template-write-date-here": "ÉCRIRE UNE DATE AVEC HEURE ICI",
  "template-write-phone-here": "ÉCRIRE LE NUMÉRO DE TÉLÉPHONE ICI",
  "template-hello-comma": "Bonjour,",
  "template-thanks-you": "Merci de votre compréhension.",
  "template-happy-holidays": "Joyeuse fêtes!",
  "template-snow-body": "Nous aimerions vous aviser que les stationnements seront déneigés le",
  "template-snow-footer": "S’il vous plaît, déplacez votre voiture en conséquence pour assurer un déneigement adéquat.",
  "template-office-closing-body": "En cette période des fêtes, nous aimerions vous aviser que nos bureaux seront fermés le",
  "template-office-closing-footer-1": "En cas d’urgence, veuillez composer le",
  "template-office-closing-footer-2": "pour joindre un membre de l’équipe.",
  "template-icing-body": "Nous aimerions vous aviser que nous procéderons au déglaçage des trottoirs et entrées des semi sous-sol en date du",
  "template-icing-footer": "S’il vous plaît, retirez les objets encombrants pour nous permettre un déglaçage adéquat.",
  "template-air-conditioning-header": "Nous aimerions vous rappeler que la saison estivale approche et que vous devez vous assurer de bien entretenir vos airs climatisés.",
  "template-air-conditioning-body-1": "Assurez-vous que le filtre a bien été nettoyé et séché avant d’être remis en place.",
  "template-air-conditioning-body-2": "Assurez-vous que le disjoncteur est en position «ON»",
  "template-air-conditioning-body-3": "Assurez-vous que les piles de la télécommande ont été changées.",
  "template-air-conditioning-footer": "Veuillez noter aussi qu’il est important de ne pas démarrer votre air climatisé en bas de 18 degrés Celsius.",
  "template-rental-agent-body": "Nous aimerions vous aviser qu’un de nos agents locatifs fera visiter les lieux à de potentiels locataires vers le",
  "template-exterminator-body": "Nous aimerions vous aviser qu’un exterminateur se présentera à votre domicile le",
  "template-exterminator-footer": "Veuillez, s’il vous plaît, retirer les objets dans les armoires et tiroirs du bas de votre logis.",
  "template-smoke-body": "Veuillez prendre note que les services d’incendie de la Ville de Gatineau procéderont à une vérification des détecteurs de fumées le",
  "subject-notice-of": "Avis de",
  "selector-label": "Modèles d'e-mails",
  "selector-label-snow-removal": "Déneigement",
  "selector-label-air-conditioning": "Air climatisé",
  "selector-label-de-ice": "Déglaçage",
  "selector-label-exterminator": "Extermination",
  "selector-label-office-closing": "Fermeture des bureaux temps des fêtes",
  "selector-label-rental-agent": "Agent d’immeuble",
  "selector-label-smoke-detector": "Détecteur de fumée"
};
const englishTemplateString = {
  "template-write-date-here": "WRITE A DATE WITH TIME HERE",
  "template-write-phone-here": "WRITE THE PHONE NUMBER HERE",
  "template-hello-comma": "Hello,",
  "template-thanks-you": "Thank you for your understanding.",
  "template-happy-holidays": "Happy Holidays!",
  "template-snow-body": "We would like to advise you that the parking lots will be cleared of snow on",
  "template-snow-footer": "Please move your car accordingly to ensure adequate snow removal.",
  "template-office-closing-body": "During this holiday season, we would like to advise you that our offices will be closed on",
  "template-office-closing-footer-1": "In case of an emergency, please call",
  "template-office-closing-footer-2": "to reach a member of the team.",
  "template-icing-body": "We would like to advise you that we will de-ice the sidewalks and entrances of semi-basements rental units on",
  "template-icing-footer": "Please remove bulky items to allow proper icebreaking.",
  "template-air-conditioning-header": "We would like to remind you that the summer season is approaching and that you must make sure to properly maintain your air conditioning.",
  "template-air-conditioning-body-1": "Make sure the filter has been cleaned and dried before putting it back in place.",
  "template-air-conditioning-body-2": "Make sure the circuit breaker is in the «ON» position",
  "template-air-conditioning-body-3": "Make sure the batteries in the remote control have been changed.",
  "template-air-conditioning-footer": "Please also note that it is important not to start your air conditioning below 18 degrees Celsius.",
  "template-rental-agent-body": "We would like to advise you that one of our rental agent will be showing your unit to potential tenants around",
  "template-exterminator-body": "We would like to advise you that an exterminator will be arriving at your home on",
  "template-exterminator-footer": "Please remove any items from your bottom cabinets and drawers.",
  "template-smoke-body": "Please note that the City of Gatineau fire departments will check the smoke detectors on",
  "subject-notice-of": "Notice of",
  "selector-label": "Email Templates",
  "selector-label-snow-removal": "Snow removal",
  "selector-label-air-conditioning": "Air conditioning",
  "selector-label-de-ice": "Ice removal",
  "selector-label-exterminator": "Exterminator",
  "selector-label-office-closing": "Fermeture des bureaux temps des fêtes",
  "selector-label-rental-agent": "Rental agent",
  "selector-label-smoke-detector": "Smoke detector"
};
export let TemplateType;

(function (TemplateType) {
  TemplateType["SnowRemoval"] = "SnowRemoval";
  TemplateType["OfficeClosing"] = "OfficeClosing";
  TemplateType["DeIce"] = "DeIce";
  TemplateType["AirConditioning"] = "AirConditioning";
  TemplateType["RentalAgent"] = "RentalAgent";
  TemplateType["Exterminator"] = "Exterminator";
  TemplateType["SmokeDetectors"] = "SmokeDetectors";
})(TemplateType || (TemplateType = {}));

export const getEmailSubject = template => {
  switch (template) {
    case TemplateType.SnowRemoval:
      return `${frenchTemplateString["subject-notice-of"]} ${frenchTemplateString["selector-label-snow-removal"]} / ${englishTemplateString["subject-notice-of"]} ${englishTemplateString["selector-label-snow-removal"]}`;

    case TemplateType.AirConditioning:
      return `${frenchTemplateString["subject-notice-of"]} ${frenchTemplateString["selector-label-air-conditioning"]} / ${englishTemplateString["subject-notice-of"]} ${englishTemplateString["selector-label-air-conditioning"]}`;

    case TemplateType.DeIce:
      return `${frenchTemplateString["subject-notice-of"]} ${frenchTemplateString["selector-label-de-ice"]} / ${englishTemplateString["subject-notice-of"]} ${englishTemplateString["selector-label-de-ice"]}`;

    case TemplateType.Exterminator:
      return `${frenchTemplateString["subject-notice-of"]} ${frenchTemplateString["selector-label-exterminator"]} / ${englishTemplateString["subject-notice-of"]} ${englishTemplateString["selector-label-exterminator"]}`;

    case TemplateType.OfficeClosing:
      return `${frenchTemplateString["subject-notice-of"]} ${frenchTemplateString["selector-label-office-closing"]} / ${englishTemplateString["subject-notice-of"]} ${englishTemplateString["selector-label-office-closing"]}`;

    case TemplateType.RentalAgent:
      return `${frenchTemplateString["subject-notice-of"]} ${frenchTemplateString["selector-label-rental-agent"]} / ${englishTemplateString["subject-notice-of"]} ${englishTemplateString["selector-label-rental-agent"]}`;

    case TemplateType.SmokeDetectors:
      return `${frenchTemplateString["subject-notice-of"]} ${frenchTemplateString["selector-label-smoke-detector"]} / ${englishTemplateString["subject-notice-of"]} ${englishTemplateString["selector-label-smoke-detector"]}`;

    default:
      return "";
  }
};
export const getEmailTemplate = template => {
  let contentBlockArray = null;

  switch (template) {
    case TemplateType.SnowRemoval:
      contentBlockArray = [new ContentBlock({
        key: "0001",
        text: frenchTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "0002",
        text: `${frenchTemplateString["template-snow-body"]} ${frenchTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "0003",
        text: frenchTemplateString["template-thanks-you"]
      }), new ContentBlock({
        key: "f4sf",
        text: "*************************************************************"
      }), new ContentBlock({
        key: "f4sv",
        text: englishTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "f3s3",
        text: `${englishTemplateString["template-snow-body"]} ${englishTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "34sf",
        text: englishTemplateString["template-thanks-you"]
      })];
      return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlockArray));

    case TemplateType.OfficeClosing:
      contentBlockArray = [new ContentBlock({
        key: "dfsg",
        text: frenchTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "4esg",
        text: `${frenchTemplateString["template-office-closing-body"]} ${frenchTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "432f",
        text: `${frenchTemplateString["template-office-closing-footer-1"]} ${frenchTemplateString["template-write-phone-here"]} ${frenchTemplateString["template-office-closing-footer-2"]}`
      }), new ContentBlock({
        key: "5bsy",
        text: frenchTemplateString["template-thanks-you"]
      }), new ContentBlock({
        key: "5dh6",
        text: "***********************************************************"
      }), new ContentBlock({
        key: "dfsg",
        text: englishTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "g5sj",
        text: `${englishTemplateString["template-office-closing-body"]} ${englishTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "lkb2",
        text: `${englishTemplateString["template-office-closing-footer-1"]} ${englishTemplateString["template-write-phone-here"]} ${englishTemplateString["template-office-closing-footer-2"]}`
      }), new ContentBlock({
        key: "g5g5",
        text: englishTemplateString["template-thanks-you"]
      })];
      return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlockArray));

    case TemplateType.DeIce:
      contentBlockArray = [new ContentBlock({
        key: "f4sf",
        text: frenchTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "5g4s",
        text: `${frenchTemplateString["template-icing-body"]} ${frenchTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "5sgh",
        text: frenchTemplateString["template-icing-footer"]
      }), new ContentBlock({
        key: "fgh6",
        text: frenchTemplateString["template-thanks-you"]
      }), new ContentBlock({
        key: "123g",
        text: "***********************************************************"
      }), new ContentBlock({
        key: "f4sa",
        text: englishTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "j6cb",
        text: `${englishTemplateString["template-icing-body"]} ${englishTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "b3ab",
        text: englishTemplateString["template-icing-footer"]
      }), new ContentBlock({
        key: "kc92",
        text: englishTemplateString["template-thanks-you"]
      })];
      return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlockArray));

    case TemplateType.AirConditioning:
      contentBlockArray = [new ContentBlock({
        key: "a3fv",
        text: frenchTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "zxfg",
        text: frenchTemplateString["template-air-conditioning-header"]
      }), new ContentBlock({
        key: "52fn",
        text: frenchTemplateString["template-air-conditioning-body-1"]
      }), new ContentBlock({
        key: "k87h",
        text: frenchTemplateString["template-air-conditioning-body-2"]
      }), new ContentBlock({
        key: "gf45",
        text: frenchTemplateString["template-air-conditioning-body-3"]
      }), new ContentBlock({
        key: "9v82",
        text: frenchTemplateString["template-air-conditioning-footer"]
      }), new ContentBlock({
        key: "6fn7",
        text: frenchTemplateString["template-thanks-you"]
      }), new ContentBlock({
        key: "123g",
        text: "***********************************************************"
      }), new ContentBlock({
        key: "04ks",
        text: englishTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "gm29",
        text: englishTemplateString["template-air-conditioning-header"]
      }), new ContentBlock({
        key: "gm32",
        text: englishTemplateString["template-air-conditioning-body-1"]
      }), new ContentBlock({
        key: "av51",
        text: englishTemplateString["template-air-conditioning-body-2"]
      }), new ContentBlock({
        key: "svcb",
        text: englishTemplateString["template-air-conditioning-body-3"]
      }), new ContentBlock({
        key: "mv92",
        text: englishTemplateString["template-air-conditioning-footer"]
      }), new ContentBlock({
        key: "1111",
        text: englishTemplateString["template-thanks-you"]
      })];
      return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlockArray));

    case TemplateType.RentalAgent:
      contentBlockArray = [new ContentBlock({
        key: "h4sv",
        text: frenchTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "a2vn",
        text: `${frenchTemplateString["template-rental-agent-body"]} ${frenchTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "f2v7",
        text: frenchTemplateString["template-thanks-you"]
      }), new ContentBlock({
        key: "5g32",
        text: "*************************************************************"
      }), new ContentBlock({
        key: "d32d",
        text: englishTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "2222",
        text: `${englishTemplateString["template-rental-agent-body"]} ${englishTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "3333",
        text: englishTemplateString["template-thanks-you"]
      })];
      return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlockArray));

    case TemplateType.Exterminator:
      contentBlockArray = [new ContentBlock({
        key: "3901",
        text: frenchTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "09fg",
        text: `${frenchTemplateString["template-exterminator-body"]} ${frenchTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "mb782",
        text: frenchTemplateString["template-exterminator-footer"]
      }), new ContentBlock({
        key: "g92n",
        text: frenchTemplateString["template-thanks-you"]
      }), new ContentBlock({
        key: "5g32",
        text: "***********************************************************"
      }), new ContentBlock({
        key: "f432",
        text: englishTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "1123",
        text: `${englishTemplateString["template-exterminator-body"]} ${englishTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "1122",
        text: englishTemplateString["template-exterminator-footer"]
      }), new ContentBlock({
        key: "2211",
        text: englishTemplateString["template-thanks-you"]
      })];
      return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlockArray));

    case TemplateType.SmokeDetectors:
      contentBlockArray = [new ContentBlock({
        key: "1235",
        text: frenchTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "9384",
        text: `${frenchTemplateString["template-smoke-body"]} ${frenchTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "1346",
        text: frenchTemplateString["template-thanks-you"]
      }), new ContentBlock({
        key: "5g32",
        text: "*************************************************************"
      }), new ContentBlock({
        key: "3344",
        text: englishTemplateString["template-hello-comma"]
      }), new ContentBlock({
        key: "4433",
        text: `${englishTemplateString["template-smoke-body"]} ${englishTemplateString["template-write-date-here"]}`
      }), new ContentBlock({
        key: "2245",
        text: englishTemplateString["template-thanks-you"]
      })];
      return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlockArray));

    default:
      return EditorState.createEmpty();
  }
};