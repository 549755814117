/* eslint-disable import/no-cycle */

/* eslint-disable no-underscore-dangle */
import { all, getContext, put, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { GetCalendarEntriesDocument, TaskStatus } from "../../../graphql/documents.js";
import StatusActions from "../Status/actions.js";
import { CalendarActionTypes } from "./types.js";

function* onCalendarInitialize($data) {
  const {
    payload
  } = $data;
  const {
    resource,
    withClosed = false,
    startRangeDate,
    endRangeDate,
    selectedAssignee
  } = payload;
  const client = yield getContext("client");

  try {
    yield put(StatusActions.setResourceToLoading("calendar"));
    const recurringEvent = {
      ticket: {
        resource
      },
      task: {
        status: withClosed ? [TaskStatus.Closed] : [TaskStatus.Active],
        resource,
        taskAssigneeIds: selectedAssignee?.value ? [selectedAssignee.value] : []
      }
    };

    if (startRangeDate && endRangeDate) {
      const query = client.query({
        query: GetCalendarEntriesDocument,
        fetchPolicy: "no-cache",
        variables: {
          recurringEvent,
          dateRange: {
            start: startRangeDate,
            end: endRangeDate
          }
        }
      });
      const {
        data,
        error
      } = yield query;

      if (error) {
        yield put(StatusActions.setResourceToError("calendar", "generic-fetch-error-message"));
        return;
      }

      yield put(StatusActions.setResourceToIdle("calendar"));
      yield put({
        type: CalendarActionTypes.CALENDAR_SET_FIELD,
        payload: {
          items: data.calendarEntries.items.map(event => event.entry)
        }
      });
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("calendar", "generic-fetch-error-message"));
  }
}

export default {
  *executers() {
    yield all([yield takeLatest(CalendarActionTypes.CALENDAR_INITIALIZE, onCalendarInitialize)]);
  }

};