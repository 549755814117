import { all, getContext, put, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { StatusActionsTypes } from "./types.js";
import StatusActions from "./actions.js";

function* onStatusError(data) {
  const {
    payload: {
      type,
      message
    }
  } = data;
  const toast = yield getContext("toast");
  const t = yield getContext("t");
  toast.error(t(message));
  yield put(StatusActions.setResourceToIdle(type));
}

function* onStatusSuccess(data) {
  const {
    payload: {
      type,
      message
    }
  } = data;
  const toast = yield getContext("toast");
  const t = yield getContext("t");
  toast.success(t(message));
  yield put(StatusActions.setResourceToIdle(type));
}

export default {
  *executers() {
    yield all([yield takeLatest(StatusActionsTypes.STATUS_SET_ERROR, onStatusError), yield takeLatest(StatusActionsTypes.STATUS_SET_SUCCESS, onStatusSuccess)]);
  }

};