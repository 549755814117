import { createReducer } from "../../helpers/actions.js";
import { ApplicationActionsTypes, initialFeaturesState, initialState } from "./types.js";

const setDrawerState = (data, state) => ({ ...state,
  showDrawer: data.payload
});

const setModalState = (data, state) => ({ ...state,
  showModal: data.payload
});

const addCrumbs = (data, state) => {
  if (data.payload.level === 0) {
    return { ...state,
      crumbs: [data.payload]
    };
  }

  const filtered = state.crumbs.filter(c => c.level <= data.payload.level);
  return { ...state,
    crumbs: [...filtered, data.payload]
  };
};

const removeCrumbs = (data, state) => {
  const result = [...state.crumbs];
  const {
    level
  } = result[data.payload];
  result.splice(data.payload, result.length - data.payload);
  const filtered = result.filter(c => c.level <= level);
  return { ...state,
    crumbs: filtered
  };
};

const setRememberMeState = (data, state) => ({ ...state,
  rememberMe: data.payload
});

const saveSearch = (action, state) => ({ ...state,
  [action.payload.name]: { ...state[action.payload.name],
    ...action.payload.search
  } // TODO: Fix save search to prevent looping infinitely

});

const resetFeatureToggles = (data, state) => ({ ...state,
  features: initialFeaturesState
});

export default createReducer(initialState, {
  [ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE]: setDrawerState,
  [ApplicationActionsTypes.RESET_FEATURE_TOGGLES]: resetFeatureToggles,
  [ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE]: setModalState,
  [ApplicationActionsTypes.SET_REMEMBER_ME_EMAIL]: setRememberMeState,
  [ApplicationActionsTypes.SEARCH_SAVE]: saveSearch,
  [ApplicationActionsTypes.ADD_CRUMBS]: addCrumbs,
  [ApplicationActionsTypes.REMOVE_CRUMBS]: removeCrumbs
});