import React, { useState } from "../../../../_snowpack/pkg/react.js";
import CreatableSelect from "../../../../_snowpack/pkg/react-select/creatable.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
const components = {
  DropdownIndicator: null
};

const createOption = label => ({
  label,
  value: label
});

export const SimpleTagEntry = ({
  onChange,
  placeholder
}) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  const handleChange = v => {
    setValue(v);
  };

  const handleInputChange = v => {
    setInputValue(v);
  };

  const handleKeyDown = event => {
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");
        setValue(current => {
          const values = [...current, createOption(inputValue)];
          if (onChange) onChange(values);
          return values;
        });
        event.preventDefault();
        break;

      default:
        break;
    }
  };

  return __cssprop(CreatableSelect, {
    components: components,
    inputValue: inputValue,
    isClearable: true,
    isMulti: true,
    menuIsOpen: false,
    onChange: handleChange,
    onInputChange: handleInputChange,
    onKeyDown: handleKeyDown,
    placeholder: placeholder,
    value: value
  });
};