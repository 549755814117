import i18n from "../_snowpack/pkg/i18next.js";
import { initReactI18next } from "../_snowpack/pkg/react-i18next.js";
import resources from "./locales/index.js";
i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang") || "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});
export default i18n;