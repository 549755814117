import { UpdateProviderDocument } from "../../../graphql/documents.js";
import { all, getContext, put, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import StatusActions from "../Status/actions.js";
import { ProviderActionTypes } from "./types.js";

function* createProvider($data) {
  const client = yield getContext("client");
  const {
    firstName,
    email,
    primaryPhone,
    lastName,
    secondaryPhone
  } = $data.payload;

  try {
    // const query = yield client.query({
    //   query: CreateProviderDocument,
    //   fetchPolicy: "no-cache",
    //   variables: {
    //     provider: {
    //       // email,
    //       firstName,
    //       lastName,
    //       primaryPhone,
    //       secondaryPhone,
    //     },
    //   },
    // });
    // const { errors }: UnPromisify<typeof query> = yield query;
    const errors = ["1"];

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("provider", "provider-form-add-provider-fail"));
    } else {
      yield put(StatusActions.setResourceToSuccess("provider", "provider-form-add-provider-success"));
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("provider", "provider-form-add-provider-fail"));
  }
}

function* updateProvider($data) {
  const client = yield getContext("client");
  const {
    id,
    provider
  } = $data.payload;
  const {
    firstName,
    email,
    primaryPhone,
    lastName,
    secondaryPhone
  } = provider;

  try {
    const query = client.query({
      query: UpdateProviderDocument,
      fetchPolicy: "no-cache",
      variables: {
        provider: {
          id,
          provider: {
            email,
            firstName,
            lastName,
            primaryPhone,
            secondaryPhone
          }
        }
      }
    });
    const {
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("provider", "provider-form-update-provider-fail"));
    } else {
      yield put(StatusActions.setResourceToSuccess("provider", "provider-form-update-provider-success"));
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("provider", "provider-form-update-provider-fail"));
  }
}

export default {
  *executers() {
    yield all([yield takeLatest(ProviderActionTypes.PROVIDER_ADD, createProvider), yield takeLatest(ProviderActionTypes.PROVIDER_EDIT, updateProvider)]);
  }

};