import { createSelector } from "../../../../../_snowpack/pkg/reselect.js";
export const getSession = state => state?.session;
export const getLastRequestTime = state => getSession(state)?.lastRequestTimestamp;
export const getToken = state => getSession(state)?.token;
export const getUser = state => getSession(state)?.user;
export const getUserFirstname = state => getUser(state)?.firstName;
export const getUserLastname = state => getUser(state)?.lastName;
export const getUserRole = state => getUser(state)?.role;
export const getUserLanguage = state => getUser(state)?.language.toLocaleLowerCase();
export const getUserLocale = state => `${getUserLanguage(state)}-ca`;
export const getUserEmail = state => getUser(state)?.email;
export const getUserPhone = state => getUser(state)?.primaryPhone;
export const getUserProfileImage = state => getUser(state)?.profileImage;
export const getUserCreationDate = state => getUser(state)?.creationDate;
export const getUserNotificationPreferences = state => getUser(state)?.notificationEnabled;
export const getUserId = state => getUser(state)?.id;
export const getUserFullname = createSelector(getUserFirstname, getUserLastname, (firstName, lastName) => [firstName, lastName].join(" ").trim());
export const getUserInitials = createSelector(state => getUserFirstname(state) || "", state => getUserLastname(state) || "", (firstName, lastName) => `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`);
export const getIsLoggedIn = createSelector(getToken, getUser, (token, user) => !!token && !!user);
export default {
  getSession,
  getLastRequestTime,
  getToken,
  getUser,
  getUserFirstname,
  getUserLastname,
  getUserRole,
  getUserLanguage,
  getUserLocale,
  getUserEmail,
  getUserPhone,
  getUserProfileImage,
  getUserCreationDate,
  getUserNotificationPreferences,
  getUserId,
  getUserFullname,
  getUserInitials,
  getIsLoggedIn
};