import { DescriptionList } from "../../../components/Tailwind/DescriptionList.js";
import { NumberTextField } from "../../../components/Tailwind/TextField.js";
import useI18n from "../../../hooks/useI18n.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { Controller, useFormContext } from "../../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const PriceField = () => {
  const {
    t
  } = useI18n();
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  return __cssprop(DescriptionList.Item, {
    title: t("unit-lease-info-label-monthly-price"),
    inline: true
  }, __cssprop(Controller, {
    name: "price",
    control: control,
    rules: {
      required: true
    },
    render: ({
      field
    }) => __cssprop(NumberTextField, {
      id: "price",
      thousandSeparator: true,
      suffix: "$",
      onChange: field.onChange,
      error: errors.price && {
        errorMessage: t("field-required")
      },
      value: field.value
    })
  }));
};