import React from "../../../../_snowpack/pkg/react.js";
import ProviderSideBar from "../../forms/SideBar/ProviderSideBar/index.js";
import useI18n from "../../hooks/useI18n.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const ProviderPanel = ({
  refetch,
  handleOnClose,
  isOpen,
  providerId
}) => {
  const {
    t
  } = useI18n();
  return __cssprop(SlideOver, {
    title: t("provider-sidebar-section-title-edit"),
    isOpen: isOpen,
    onClose: handleOnClose
  }, __cssprop(ProviderSideBar, {
    refetch: refetch,
    providerId: providerId
  }));
};