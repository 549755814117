/* eslint-disable no-underscore-dangle */

/* eslint-disable import/no-cycle */
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { createSelector } from "../../../../../_snowpack/pkg/reselect.js";
export const getTenant = state => state?.tenant;

const getId = state => getTenant(state).id;

const getName = state => getTenant(state).name;

const getEmail = state => getTenant(state).email;

const getCurrentLeases = state => getTenant(state).currentLeases;

const getPrimaryPhone = state => getTenant(state).primaryPhone;

const getSecondaryPhone = state => getTenant(state).secondaryPhone;

const getFirstName = state => getTenant(state).firstName;

const getLastName = state => getTenant(state).lastName;

const getLeases = state => getTenant(state).leases;

const getFiles = state => getTenant(state).files;

const getNotes = state => getTenant(state).notes;

const getTickets = state => getTenant(state).tickets.items;

const getAllLeases = createSelector(getLeases, leases => leases?.items?.map(({
  lease,
  paymentMethod,
  isPayee
}) => ({ ...lease,
  paymentMethod,
  isPayee
})));
const getActiveLeases = createSelector(getCurrentLeases, leases => {
  return leases?.items?.filter(({
    lease
  }) => DateTime.fromISO(lease.startDate) <= DateTime.now() && DateTime.fromISO(lease.endDate) >= DateTime.now());
});
export default {
  getId,
  getName,
  getEmail,
  getActiveLeases,
  getPrimaryPhone,
  getSecondaryPhone,
  getFirstName,
  getLastName,
  getAllLeases,
  getFiles,
  getNotes,
  getTickets
};