import { EventActionTypes, initialState } from "./types.js";
export const setEventField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};
export const setEventInitialState = state => {
  return { ...state,
    ...initialState
  };
};
export const clearState = () => {
  return { ...initialState
  };
};
export default {
  [EventActionTypes.EVENT_SET_INITIAL_STATE]: setEventInitialState,
  [EventActionTypes.EVENT_CLEAR_STATE]: clearState,
  [EventActionTypes.EVENT_SET_FIELD]: setEventField
};