import useI18n from "../../hooks/useI18n.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useCallback } from "../../../../_snowpack/pkg/react.js";
import { FaFilter } from "../../../../_snowpack/pkg/react-icons/fa.js";
import { HiOutlineSearch } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { Selector } from "../Selectors/Selector.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Filters = ({
  isActive,
  onTextSearchChange,
  onFilterChange,
  onFilterTextChange,
  filters,
  resourceName
}) => {
  const {
    t
  } = useI18n();
  const handleChange = useCallback((kind, value) => {
    onFilterChange(kind, value);
  }, []);
  const onInputChange = useCallback(kind => value => {
    if (!onFilterTextChange) {
      return;
    }

    onFilterTextChange(kind, value);
  }, []);

  if (!isActive) {
    return null;
  }

  return __cssprop("section", {
    "aria-labelledby": "filter-heading",
    css: {
      "position": "relative",
      "zIndex": "10",
      "display": "grid",
      "alignItems": "center"
    }
  }, __cssprop("div", {
    css: {
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "position": "relative",
      "width": "100%",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ":focus-within": {
        "--tw-text-opacity": "1",
        "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
      },
      "marginLeft": "2.4rem",
      "marginRight": "2.4rem"
    }
  }, __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "left": "0px",
      "display": "flex",
      "alignItems": "center",
      "pointerEvents": "none"
    }
  }, __cssprop(HiOutlineSearch, {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  })), __cssprop("input", {
    onChange: e => {
      onTextSearchChange(e.target.value);
    },
    id: "search-field",
    css: {
      "display": "block",
      "width": "100%",
      "height": "100%",
      "paddingLeft": "3.2rem",
      "paddingRight": "1.2rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderColor": "transparent",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "::placeholder": {
        "--tw-placeholder-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-placeholder-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "::placeholder": {
          "--tw-placeholder-opacity": "1",
          "color": "rgba(156, 163, 175, var(--tw-placeholder-opacity))"
        },
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "borderColor": "transparent"
      },
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    },
    placeholder: `${t("search")} ${resourceName.plural}`,
    type: "search",
    name: "search"
  })), filters?.length >= 1 && __cssprop("div", {
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1.6rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "marginRight": "2.4rem"
    }
  }, filters.map((filter, index) => {
    return __cssprop(Selector, {
      key: filter.name,
      defaultValue: filter.options.filter(x => x.defaults),
      css: {
        "width": "28.8rem"
      },
      isMulti: true,
      placeholder: filter.placeholder,
      options: filter.options,
      onInputChange: onInputChange(filter.name),
      onChange: v => {
        handleChange(filter.name, v);
      }
    });
  })))));
};
export const FilterButton = ({
  onClick,
  onClear,
  numberFilters
}) => {
  const {
    t
  } = useI18n();
  return __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "position": "relative",
      "gridColumnStart": "1",
      "gridRowStart": "1",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem"
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "128rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(2.4rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))",
        "--tw-divide-x-reverse": 0,
        "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
        "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      },
      "fontSize": "1.4rem",
      "lineHeight": "2rem"
    }
  }, __cssprop("div", null, __cssprop("button", {
    onClick: onClick,
    type: "button",
    className: "group",
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "fontWeight": "500",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop(FaFilter, {
    css: {
      "flex": "none",
      "width": "2rem",
      "height": "2rem",
      "marginRight": "0.8rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }), `${numberFilters > 0 && `(${numberFilters})` || ""} ${"Filters"}`)), __cssprop("div", {
    css: {
      "paddingLeft": "2.4rem"
    }
  }, __cssprop("button", {
    onClick: onClear,
    type: "button",
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("clear-all"))))));
};