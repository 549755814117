/** All built-in and custom scalars, mapped to their actual values */

/** Adding an Amenity */

/** Creating a new Applicant */

/** Sorting options for applicants. */
export let ApplicantSortingOptions;

(function (ApplicantSortingOptions) {
  ApplicantSortingOptions["Name"] = "name";
})(ApplicantSortingOptions || (ApplicantSortingOptions = {}));

/** ApplicantStatus */
export let ApplicantStatus;

(function (ApplicantStatus) {
  ApplicantStatus["CreditCheckComplete"] = "CreditCheckComplete";
  ApplicantStatus["CreditCheckPendingResult"] = "CreditCheckPendingResult";
  ApplicantStatus["Error"] = "Error";
  ApplicantStatus["PaymentPending"] = "PaymentPending";
  ApplicantStatus["PaymentSucceededAndCreditCheckUnsent"] = "PaymentSucceededAndCreditCheckUnsent";
  ApplicantStatus["IsManuallyAdded"] = "isManuallyAdded";
})(ApplicantStatus || (ApplicantStatus = {}));

/** Archival filtering options. */
export let ArchivalFilteringOptions;

(function (ArchivalFilteringOptions) {
  ArchivalFilteringOptions["ActiveResource"] = "ActiveResource";
  ArchivalFilteringOptions["All"] = "All";
  ArchivalFilteringOptions["ArchivedResource"] = "ArchivedResource";
})(ArchivalFilteringOptions || (ArchivalFilteringOptions = {}));

/** Branding assets types for images */
export let BrandingAssetTypes;

(function (BrandingAssetTypes) {
  BrandingAssetTypes["DarkLogo"] = "dark_logo";
  BrandingAssetTypes["DarkLogoIcon"] = "dark_logo_icon";
  BrandingAssetTypes["DarkLogoSquare"] = "dark_logo_square";
  BrandingAssetTypes["LightLogo"] = "light_logo";
  BrandingAssetTypes["LightLogoIcon"] = "light_logo_icon";
  BrandingAssetTypes["LightLogoSquare"] = "light_logo_square";
})(BrandingAssetTypes || (BrandingAssetTypes = {}));

/** Sorting options of building. */
export let BuildingSortingOptions;

(function (BuildingSortingOptions) {
  BuildingSortingOptions["Address"] = "address";
  BuildingSortingOptions["City"] = "city";
  BuildingSortingOptions["Units"] = "units";
})(BuildingSortingOptions || (BuildingSortingOptions = {}));

/** Available methods of sending out communications: phone, email, sms. */
export let CommunicationMedium;

(function (CommunicationMedium) {
  CommunicationMedium["Email"] = "email";
  CommunicationMedium["Phone"] = "phone";
  CommunicationMedium["Sms"] = "sms";
})(CommunicationMedium || (CommunicationMedium = {}));

/** Available types of content transmission: free text, templates, system-generated. */
export let CommunicationType;
/** Current feature of the company */

(function (CommunicationType) {
  CommunicationType["FreeText"] = "FreeText";
  CommunicationType["SystemGenerated"] = "SystemGenerated";
  CommunicationType["Template"] = "Template";
})(CommunicationType || (CommunicationType = {}));

export let CompanyFeature;
/** Approved, Rejected, or unset back to null */

(function (CompanyFeature) {
  CompanyFeature["Trial"] = "trial";
})(CompanyFeature || (CompanyFeature = {}));

export let ConfirmationCase;

(function (ConfirmationCase) {
  ConfirmationCase["Approved"] = "Approved";
  ConfirmationCase["Rejected"] = "Rejected";
  ConfirmationCase["Unset"] = "Unset";
})(ConfirmationCase || (ConfirmationCase = {}));

/** Types of confirmation accepted from external website */
export let ConfirmationType;

(function (ConfirmationType) {
  ConfirmationType["RentalAppOwnerConfirm"] = "RentalAppOwnerConfirm";
})(ConfirmationType || (ConfirmationType = {}));

/** Consent Types */
export let ConsentTypes;

(function (ConsentTypes) {
  ConsentTypes["CreditCheck"] = "credit_check";
  ConsentTypes["InsuranceRequired"] = "insurance_required";
  ConsentTypes["IsNonSmoking"] = "is_non_smoking";
  ConsentTypes["MaximumNumberOfPets"] = "maximum_number_of_pets";
  ConsentTypes["MaximumNumberOfTenants"] = "maximum_number_of_tenants";
  ConsentTypes["MinimumSalary"] = "minimum_salary";
  ConsentTypes["ParkingSpotsIncludedInRent"] = "parking_spots_included_in_rent";
  ConsentTypes["TermsOfServices"] = "terms_of_services";
})(ConsentTypes || (ConsentTypes = {}));

/** Sorting options of deposits. */
export let DepositSortingOptions;

(function (DepositSortingOptions) {
  DepositSortingOptions["Amount"] = "amount";
  DepositSortingOptions["Date"] = "date";
  DepositSortingOptions["Project"] = "project";
})(DepositSortingOptions || (DepositSortingOptions = {}));

/** Feature Types */
export let FeatureType;
/** Categories of files attached to resources */

(function (FeatureType) {
  FeatureType["AppliancesDishwasher"] = "appliances_dishwasher";
  FeatureType["AppliancesDryer"] = "appliances_dryer";
  FeatureType["AppliancesFurnitureLeaseAndIncludedInPrice"] = "appliances_furniture_lease_and_included_in_price";
  FeatureType["AppliancesJacuzzi"] = "appliances_jacuzzi";
  FeatureType["AppliancesLockerStorageSpace"] = "appliances_locker_storage_space";
  FeatureType["AppliancesMicrowave"] = "appliances_microwave";
  FeatureType["AppliancesOven"] = "appliances_oven";
  FeatureType["AppliancesRefrigerator"] = "appliances_refrigerator";
  FeatureType["AppliancesStove"] = "appliances_stove";
  FeatureType["AppliancesWasher"] = "appliances_washer";
  FeatureType["BuildingElevator"] = "building_elevator";
  FeatureType["BuildingGym"] = "building_gym";
  FeatureType["BuildingPool"] = "building_pool";
  FeatureType["BuildingTerrace"] = "building_terrace";
  FeatureType["CatsAllowed"] = "cats_allowed";
  FeatureType["CoveredGarage"] = "covered_garage";
  FeatureType["DisabledAccess"] = "disabled_access";
  FeatureType["ExtensionsBalcony"] = "extensions_balcony";
  FeatureType["ExtensionsBlinds"] = "extensions_blinds";
  FeatureType["ExtensionsCable"] = "extensions_cable";
  FeatureType["ExtensionsCableInternet"] = "extensions_cable_internet";
  FeatureType["ExtensionsCentralAirconditioning"] = "extensions_central_airconditioning";
  FeatureType["ExtensionsCounterType"] = "extensions_counter_type";
  FeatureType["ExtensionsElectricHeating"] = "extensions_electric_heating";
  FeatureType["ExtensionsElectricity"] = "extensions_electricity";
  FeatureType["ExtensionsFiberInternet"] = "extensions_fiber_internet";
  FeatureType["ExtensionsGasHeating"] = "extensions_gas_heating";
  FeatureType["ExtensionsPortableAirconditioning"] = "extensions_portable_airconditioning";
  FeatureType["ExtensionsWater"] = "extensions_water";
  FeatureType["ExtensionsWaterTank"] = "extensions_water_tank";
  FeatureType["FurnitureCommodes"] = "furniture_commodes";
  FeatureType["FurnitureNumberOfArmchair"] = "furniture_number_of_armchair";
  FeatureType["FurnitureNumberOfBed"] = "furniture_number_of_bed";
  FeatureType["FurnitureNumberOfChairs"] = "furniture_number_of_chairs";
  FeatureType["FurnitureNumberOfChestsOfDrawers"] = "furniture_number_of_chests_of_drawers";
  FeatureType["FurnitureNumberOfCouch"] = "furniture_number_of_couch";
  FeatureType["FurnitureNumberOfTables"] = "furniture_number_of_tables";
  FeatureType["GarageParkingDouble"] = "garage_parking_double";
  FeatureType["GarageParkingSingle"] = "garage_parking_single";
  FeatureType["GarageParkingTriple"] = "garage_parking_triple";
  FeatureType["LargeDogsAllowed"] = "large_dogs_allowed";
  FeatureType["LaundryRoomBuilding"] = "laundry_room_building";
  FeatureType["SmallDogsAllowed"] = "small_dogs_allowed";
  FeatureType["SmokingAllowed"] = "smoking_allowed";
  FeatureType["StreetParking"] = "street_parking";
  FeatureType["UndergroundGarage"] = "underground_garage";
  FeatureType["WasherDryerOutlet"] = "washer_dryer_outlet";
  FeatureType["YardAccess"] = "yard_access";
})(FeatureType || (FeatureType = {}));

export let FileCategory;

(function (FileCategory) {
  FileCategory["BuildingRegulation"] = "building_regulation";
  FileCategory["Check"] = "check";
  FileCategory["DarkLogo"] = "dark_logo";
  FileCategory["DarkLogoIcon"] = "dark_logo_icon";
  FileCategory["DarkLogoSquare"] = "dark_logo_square";
  FileCategory["Identity"] = "identity";
  FileCategory["Insurance"] = "insurance";
  FileCategory["Lease"] = "lease";
  FileCategory["LightLogo"] = "light_logo";
  FileCategory["LightLogoIcon"] = "light_logo_icon";
  FileCategory["LightLogoSquare"] = "light_logo_square";
  FileCategory["Other"] = "other";
  FileCategory["Payment"] = "payment";
  FileCategory["SignatureRequired"] = "signature_required";
  FileCategory["Utility"] = "utility";
})(FileCategory || (FileCategory = {}));

/** Filter inclusively or exclusively */
export let FilterKind;
/** RRule Frequency */

(function (FilterKind) {
  FilterKind["Exclusive"] = "exclusive";
  FilterKind["Inclusive"] = "inclusive";
})(FilterKind || (FilterKind = {}));

export let Frequency;

(function (Frequency) {
  Frequency["Daily"] = "DAILY";
  Frequency["Hourly"] = "HOURLY";
  Frequency["Minutely"] = "MINUTELY";
  Frequency["Monthly"] = "MONTHLY";
  Frequency["Secondly"] = "SECONDLY";
  Frequency["Weekly"] = "WEEKLY";
  Frequency["Yearly"] = "YEARLY";
})(Frequency || (Frequency = {}));

/** Categories of keys an integration */
export let IntegrationKeysType;
/** Filters for the different states of a lease. */

(function (IntegrationKeysType) {
  IntegrationKeysType["ClerkApiKey"] = "clerk_api_key";
  IntegrationKeysType["ClerkAuthTokenSecret"] = "clerk_auth_token_secret";
  IntegrationKeysType["ClerkPublicInstanceKey"] = "clerk_public_instance_key";
  IntegrationKeysType["GoogleAnalyticsGtagId"] = "google_analytics_gtag_id";
  IntegrationKeysType["GooglePlacesApiKey"] = "google_places_api_key";
  IntegrationKeysType["InternalBackofficeAccessKey"] = "internal_backoffice_access_key";
  IntegrationKeysType["MapboxApiKey"] = "mapbox_api_key";
})(IntegrationKeysType || (IntegrationKeysType = {}));

export let LeaseFilter;

(function (LeaseFilter) {
  LeaseFilter["Active"] = "active";
  LeaseFilter["All"] = "all";
  LeaseFilter["Deliveries"] = "deliveries";
  LeaseFilter["DoNotRenew"] = "doNotRenew";
  LeaseFilter["ToRenew"] = "toRenew";
})(LeaseFilter || (LeaseFilter = {}));

/** Sorting options of leases. */
export let LeaseSortingOptions;

(function (LeaseSortingOptions) {
  LeaseSortingOptions["Address"] = "address";
  LeaseSortingOptions["CreationDate"] = "creationDate";
  LeaseSortingOptions["DownPayment"] = "downPayment";
  LeaseSortingOptions["EndDate"] = "endDate";
  LeaseSortingOptions["MonthlyPrice"] = "monthlyPrice";
  LeaseSortingOptions["RentalAgent"] = "rentalAgent";
  LeaseSortingOptions["StartDate"] = "startDate";
})(LeaseSortingOptions || (LeaseSortingOptions = {}));

/** Sorting options of listings. */
export let ListingSortingOptions;

(function (ListingSortingOptions) {
  ListingSortingOptions["Availability"] = "availability";
  ListingSortingOptions["AvailableOn"] = "availableOn";
  ListingSortingOptions["Bedrooms"] = "bedrooms";
  ListingSortingOptions["Floor"] = "floor";
  ListingSortingOptions["Price"] = "price";
  ListingSortingOptions["PublishedOn"] = "publishedOn";
})(ListingSortingOptions || (ListingSortingOptions = {}));

/** Status of the communication according to the mailer service. E.g. delivered means that the mailer service has successfully delivered the email/sms/phone call. */
export let MailerStatus;

(function (MailerStatus) {
  MailerStatus["Delivered"] = "delivered";
  MailerStatus["Opened"] = "opened";
  MailerStatus["Processed"] = "processed";
  MailerStatus["Unsent"] = "unsent";
})(MailerStatus || (MailerStatus = {}));

/** Payment Methods */
export let PaymentMethod;
/** Different levels of permissions for users. */

(function (PaymentMethod) {
  PaymentMethod["Cash"] = "Cash";
  PaymentMethod["Check"] = "Check";
  PaymentMethod["CreditCard"] = "CreditCard";
  PaymentMethod["DirectDebit"] = "DirectDebit";
  PaymentMethod["ETransfer"] = "ETransfer";
})(PaymentMethod || (PaymentMethod = {}));

export let PermissionType;

(function (PermissionType) {
  PermissionType["Admin"] = "Admin";
  PermissionType["Client"] = "Client";
  PermissionType["Manager"] = "Manager";
  PermissionType["Owner"] = "Owner";
  PermissionType["System"] = "System";
  PermissionType["User"] = "User";
  PermissionType["VisitAgent"] = "VisitAgent";
})(PermissionType || (PermissionType = {}));

/** User preferred method of contact */
export let PreferredMethodOfContact;
/** How often a payment occurs */

(function (PreferredMethodOfContact) {
  PreferredMethodOfContact["Email"] = "email";
  PreferredMethodOfContact["Phone"] = "phone";
})(PreferredMethodOfContact || (PreferredMethodOfContact = {}));

export let PricingType;

(function (PricingType) {
  PricingType["BiWeekly"] = "BiWeekly";
  PricingType["Monthly"] = "Monthly";
  PricingType["Weekly"] = "Weekly";
  PricingType["Yearly"] = "Yearly";
})(PricingType || (PricingType = {}));

/** Sorting options of project. */
export let ProjectsSortingOptions;

(function (ProjectsSortingOptions) {
  ProjectsSortingOptions["Name"] = "name";
})(ProjectsSortingOptions || (ProjectsSortingOptions = {}));

/** Contract types for landlords */
export let PropertyManagementContractType;

(function (PropertyManagementContractType) {
  PropertyManagementContractType["FullyManaged"] = "FullyManaged";
  PropertyManagementContractType["RentalOnly"] = "RentalOnly";
})(PropertyManagementContractType || (PropertyManagementContractType = {}));

/** Types of resources that can be emailed in bulk. E.g. Building will send the notification to all tenants in the building. */
export let RecipientCategory;

(function (RecipientCategory) {
  RecipientCategory["Building"] = "Building";
  RecipientCategory["Project"] = "Project";
  RecipientCategory["Tenant"] = "Tenant";
  RecipientCategory["Unit"] = "Unit";
})(RecipientCategory || (RecipientCategory = {}));

/** Action of the recurring event */
export let RecurringEventEntityAction;

(function (RecurringEventEntityAction) {
  RecurringEventEntityAction["Create"] = "create";
  RecurringEventEntityAction["Reoccur"] = "reoccur";
})(RecurringEventEntityAction || (RecurringEventEntityAction = {}));

/** Statuses for renewals. */
export let RenewalStatus;

(function (RenewalStatus) {
  RenewalStatus["ApprovedByLandlord"] = "ApprovedByLandlord";
  RenewalStatus["ApprovedByTenant"] = "ApprovedByTenant";
  RenewalStatus["CancelledByLandlord"] = "CancelledByLandlord";
  RenewalStatus["CancelledByTenant"] = "CancelledByTenant";
  RenewalStatus["Created"] = "Created";
  RenewalStatus["EmailOpenedByTenant"] = "EmailOpenedByTenant";
  RenewalStatus["EmailSent"] = "EmailSent";
  RenewalStatus["FailedToSend"] = "FailedToSend";
  RenewalStatus["ImpliedRenewal"] = "ImpliedRenewal";
  RenewalStatus["NoTenantEmail"] = "NoTenantEmail";
})(RenewalStatus || (RenewalStatus = {}));

/** Rejection Reasons for a Rental Request Application */
export let RentalApplicationRejectionReason;
/** RentalApplicationSteps */

(function (RentalApplicationRejectionReason) {
  RentalApplicationRejectionReason["BadCredit"] = "bad_credit";
  RentalApplicationRejectionReason["ListingNoLongerAvailable"] = "listing_no_longer_available";
  RentalApplicationRejectionReason["Other"] = "other";
  RentalApplicationRejectionReason["OwnerRefused"] = "owner_refused";
  RentalApplicationRejectionReason["PassRestricted"] = "pass_restricted";
  RentalApplicationRejectionReason["VisitRefused"] = "visit_refused";
})(RentalApplicationRejectionReason || (RentalApplicationRejectionReason = {}));

export let RentalApplicationSteps;
/** status of the candidate rental request */

(function (RentalApplicationSteps) {
  RentalApplicationSteps["InternalApproval"] = "InternalApproval";
  RentalApplicationSteps["LeaseDocumentUploader"] = "LeaseDocumentUploader";
  RentalApplicationSteps["RentalAgentApproval"] = "RentalAgentApproval";
  RentalApplicationSteps["RentalApplicantChosen"] = "RentalApplicantChosen";
})(RentalApplicationSteps || (RentalApplicationSteps = {}));

export let RentalParameters;

(function (RentalParameters) {
  RentalParameters["Cat"] = "Cat";
  RentalParameters["Children"] = "Children";
  RentalParameters["Dog"] = "Dog";
  RentalParameters["Roommate"] = "Roommate";
  RentalParameters["Spouse"] = "Spouse";
})(RentalParameters || (RentalParameters = {}));

/** status of the candidate rental request */
export let RentalRequestStatus;
/** PM can manually create an applicant on a listing */

(function (RentalRequestStatus) {
  RentalRequestStatus["Active"] = "Active";
  RentalRequestStatus["Approved"] = "Approved";
  RentalRequestStatus["Cancelled"] = "Cancelled";
  RentalRequestStatus["Denied"] = "Denied";
  RentalRequestStatus["Fulfilled"] = "Fulfilled";
})(RentalRequestStatus || (RentalRequestStatus = {}));

/** Sorting options of rentalRequests. */
export let RentalRequestV2SortingOptions;

(function (RentalRequestV2SortingOptions) {
  RentalRequestV2SortingOptions["Address"] = "address";
  RentalRequestV2SortingOptions["Agent"] = "agent";
  RentalRequestV2SortingOptions["CreationDate"] = "creationDate";
})(RentalRequestV2SortingOptions || (RentalRequestV2SortingOptions = {}));

/** Filters for the different states of a rental request. */
export let RentalRequestsV2Filter;
/** Filters for the different progression states of a rental request. */

(function (RentalRequestsV2Filter) {
  RentalRequestsV2Filter["Active"] = "active";
  RentalRequestsV2Filter["Completed"] = "completed";
  RentalRequestsV2Filter["Incomplete"] = "incomplete";
  RentalRequestsV2Filter["Rejected"] = "rejected";
  RentalRequestsV2Filter["Restricted"] = "restricted";
})(RentalRequestsV2Filter || (RentalRequestsV2Filter = {}));

export let RentalRequestsV2ProgressFilter;

(function (RentalRequestsV2ProgressFilter) {
  RentalRequestsV2ProgressFilter["ApprovedInternally"] = "approvedInternally";
  RentalRequestsV2ProgressFilter["DocumentsUploaded"] = "documentsUploaded";
  RentalRequestsV2ProgressFilter["NoProgress"] = "noProgress";
  RentalRequestsV2ProgressFilter["OwnerApproved"] = "ownerApproved";
  RentalRequestsV2ProgressFilter["VisitAccepted"] = "visitAccepted";
})(RentalRequestsV2ProgressFilter || (RentalRequestsV2ProgressFilter = {}));

/** direction to sort the fields. */
export let SortDirection;

(function (SortDirection) {
  SortDirection["Asc"] = "Asc";
  SortDirection["Desc"] = "Desc";
})(SortDirection || (SortDirection = {}));

/** SpaceType */
export let SpaceType;

(function (SpaceType) {
  SpaceType["Building"] = "building";
  SpaceType["Unit"] = "unit";
})(SpaceType || (SpaceType = {}));

/** Type of entities you can interact with for notes, files and archival */
export let SystemEntities;

(function (SystemEntities) {
  SystemEntities["Building"] = "building";
  SystemEntities["Deposit"] = "deposit";
  SystemEntities["Organization"] = "organization";
  SystemEntities["Project"] = "project";
  SystemEntities["Provider"] = "provider";
  SystemEntities["Task"] = "task";
  SystemEntities["Tenant"] = "tenant";
  SystemEntities["Ticket"] = "ticket";
  SystemEntities["Unit"] = "unit";
  SystemEntities["User"] = "user";
})(SystemEntities || (SystemEntities = {}));

/** Different kind of statuses tasks can have */
export let TaskStatus;

(function (TaskStatus) {
  TaskStatus["Active"] = "active";
  TaskStatus["Closed"] = "closed";
})(TaskStatus || (TaskStatus = {}));

/** Status filter for Tenants. */
export let TenantStatus;
/** Category of the ticket */

(function (TenantStatus) {
  TenantStatus["Active"] = "active";
  TenantStatus["Previous"] = "previous";
  TenantStatus["Upcomming"] = "upcomming";
})(TenantStatus || (TenantStatus = {}));

export let TicketCategory;

(function (TicketCategory) {
  TicketCategory["Administration"] = "administration";
  TicketCategory["Coproperty"] = "coproperty";
  TicketCategory["Maintenance"] = "maintenance";
  TicketCategory["Management"] = "management";
  TicketCategory["Other"] = "other";
  TicketCategory["PaymentIssue"] = "payment_issue";
  TicketCategory["Prevent"] = "prevent";
  TicketCategory["Rental"] = "rental";
})(TicketCategory || (TicketCategory = {}));

/** Origin of the ticket */
export let TicketOrigin;
/** Priority of the ticket */

(function (TicketOrigin) {
  TicketOrigin["Email"] = "email";
  TicketOrigin["Manual"] = "manual";
  TicketOrigin["RecurringEvent"] = "recurringEvent";
  TicketOrigin["UserSubmission"] = "userSubmission";
})(TicketOrigin || (TicketOrigin = {}));

export let TicketPriority;

(function (TicketPriority) {
  TicketPriority["High"] = "high";
  TicketPriority["Low"] = "low";
  TicketPriority["Medium"] = "medium";
})(TicketPriority || (TicketPriority = {}));

/** Sorting options of tickets. */
export let TicketSortingOptions;

(function (TicketSortingOptions) {
  TicketSortingOptions["Category"] = "category";
  TicketSortingOptions["Conversations"] = "conversations";
  TicketSortingOptions["CreatedAt"] = "createdAt";
  TicketSortingOptions["LastModified"] = "lastModified";
  TicketSortingOptions["Priority"] = "priority";
  TicketSortingOptions["Status"] = "status";
  TicketSortingOptions["Tasks"] = "tasks";
  TicketSortingOptions["Title"] = "title";
})(TicketSortingOptions || (TicketSortingOptions = {}));

/** Different kind of statuses tickets can have */
export let TicketStatus;
/** Transfer a new building */

(function (TicketStatus) {
  TicketStatus["Active"] = "active";
  TicketStatus["Closed"] = "closed";
  TicketStatus["Deleted"] = "deleted";
})(TicketStatus || (TicketStatus = {}));

/** Positions of users */
export let UserActingPositions;

(function (UserActingPositions) {
  UserActingPositions["Landlord"] = "Landlord";
  UserActingPositions["PropertyManager"] = "PropertyManager";
  UserActingPositions["VisitAgent"] = "VisitAgent";
})(UserActingPositions || (UserActingPositions = {}));

/** Sorting options of Users. */
export let UserSortingOptions;

(function (UserSortingOptions) {
  UserSortingOptions["Email"] = "email";
  UserSortingOptions["Name"] = "name";
})(UserSortingOptions || (UserSortingOptions = {}));

/** State of users */
export let UserState;

(function (UserState) {
  UserState["Active"] = "Active";
  UserState["Blocked"] = "Blocked";
  UserState["Created"] = "Created";
  UserState["Deleted"] = "Deleted";
  UserState["Registered"] = "Registered";
})(UserState || (UserState = {}));

/** status of the candidate work situation */
export let WorkStatus;
/** Fetching an amenity from a resource */

(function (WorkStatus) {
  WorkStatus["Contractual"] = "Contractual";
  WorkStatus["FullTime"] = "FullTime";
  WorkStatus["PartTime"] = "PartTime";
  WorkStatus["Permanent"] = "Permanent";
})(WorkStatus || (WorkStatus = {}));

export let Space_Type;

(function (Space_Type) {
  Space_Type["Acreage"] = "acreage";
  Space_Type["Apartment"] = "apartment";
  Space_Type["Basement"] = "basement";
  Space_Type["Commercial"] = "commercial";
  Space_Type["Complex"] = "complex";
  Space_Type["Condo"] = "condo";
  Space_Type["Duplex"] = "duplex";
  Space_Type["House"] = "house";
  Space_Type["Loft"] = "loft";
  Space_Type["MainFloor"] = "main_floor";
  Space_Type["Mobile"] = "mobile";
  Space_Type["OfficeSpace"] = "office_space";
  Space_Type["ParkingSpot"] = "parking_spot";
  Space_Type["Shared"] = "shared";
  Space_Type["Storage"] = "storage";
  Space_Type["Townhouse"] = "townhouse";
  Space_Type["Vacation"] = "vacation";
  Space_Type["Villa"] = "villa";
})(Space_Type || (Space_Type = {}));

export const TicketCalendarFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "TicketCalendar"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TicketGQL"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "category"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tags"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "resource"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tenant"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "project"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "BuildingGQL"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "sector"
                    }
                  }]
                }
              }, {
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "UnitGQL"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildingAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "subAddress"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const TicketFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Ticket"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "TicketGQL"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "title"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "category"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "priority"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "resource"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tenant"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "project"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "space"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "BuildingGQL"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }, {
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "UnitGQL"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UnitFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Unit"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "UnitGQL"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "buildingAddress"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "subAddress"
        }
      }]
    }
  }]
};
export const BuildingFragmentDoc = {
  "kind": "Document",
  "definitions": [{
    "kind": "FragmentDefinition",
    "name": {
      "kind": "Name",
      "value": "Building"
    },
    "typeCondition": {
      "kind": "NamedType",
      "name": {
        "kind": "Name",
        "value": "BuildingGQL"
      }
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "civicNumber"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "streetName"
        }
      }, {
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sector"
        }
      }]
    }
  }]
};
export const GetRentalApplicationsByUsersV2Document = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetRentalApplicationsByUsersV2"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "RentalRequestsV2Filter"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tenants"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "withRentalApplicationsStatus"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "status"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "firstName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "rentalApplications"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "searchString"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "query"
                    }
                  }
                }, {
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "sortBy"
                  },
                  "value": {
                    "kind": "ObjectValue",
                    "fields": [{
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "option"
                      },
                      "value": {
                        "kind": "EnumValue",
                        "value": "creationDate"
                      }
                    }, {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "direction"
                      },
                      "value": {
                        "kind": "EnumValue",
                        "value": "Desc"
                      }
                    }]
                  }
                }, {
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "status"
                  },
                  "value": {
                    "kind": "Variable",
                    "name": {
                      "kind": "Name",
                      "value": "status"
                    }
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "currentPage"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalItems"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalPages"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "createdAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "propertyManagerApprovedAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "landlordApprovedAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "completedAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "unit"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "subAddress"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "buildingAddress"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "applicants"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "dateOfBirth"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "applicationPass"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "files"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "url"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "fileName"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "fileType"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "filePath"
                                    }
                                  }]
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "slScore"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "endDate"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "startDate"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "restrictions"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "restrictedAt"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "restrictedBy"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "name"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "firstName"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "lastName"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "email"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "primaryPhone"
                                        }
                                      }]
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "restrictedFor"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "name"
                                        }
                                      }]
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetActiveLeaseOnRentalApplicationV2Document = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetActiveLeaseOnRentalApplicationV2"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "rentalApplicationV2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unit"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currentLease"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "startDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "endDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "tenants"
                    },
                    "arguments": [{
                      "kind": "Argument",
                      "name": {
                        "kind": "Name",
                        "value": "take"
                      },
                      "value": {
                        "kind": "IntValue",
                        "value": "10"
                      }
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "items"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "tenant"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "email"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetSingularRentalApplicationV2Document = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetSingularRentalApplicationV2"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "rentalApplicationV2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "requestedEntryDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "propertyManagerApprovedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "propertyManagerRejectedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "landlordApprovedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "landlordRejectedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isInOwnerApprovalQueue"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "completedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "visitDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "chosenAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "visitAgentApprovedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "visitAgentRejectedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "visitAgentAssignedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sentToLandlordAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "signatureDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "downPaymentAmount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalDownPaymentAmount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "rejected"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "at"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "rejection"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "reason"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unit"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "subAddress"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildingAddress"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "building"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "project"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "inviteCode"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "applicants"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "dateOfBirth"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "status"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "firstName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "language"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "addressHistory"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "addressLineOne"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "addressLineTwo"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "city"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "postalZipCode"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "provinceState"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "landlordName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "landlordPhone"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "applicationPass"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "files"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileType"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "filePath"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "slScore"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "endDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "startDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "restrictions"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "restrictedAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "restrictedBy"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "firstName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "lastName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "primaryPhone"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "restrictedFor"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileCategory"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "visitAgent"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "listing"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "consents"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "consentType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "consentValue"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnail"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amenities"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "key"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "rentalRequests"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "slug"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "sentToLandlordAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "applicationRank"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "completedAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "createdAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "isInOwnerApprovalQueue"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "visitDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "rejected"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "at"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "rejection"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "reason"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "applicants"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "dateOfBirth"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "status"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "firstName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "lastName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "primaryPhone"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "applicationPass"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "files"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "url"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "fileName"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "fileType"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "filePath"
                                    }
                                  }]
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "slScore"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "endDate"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "startDate"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "restrictions"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "restrictedAt"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "restrictedBy"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "name"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "firstName"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "lastName"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "email"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "primaryPhone"
                                        }
                                      }]
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "restrictedFor"
                                    },
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [{
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "id"
                                        }
                                      }, {
                                        "kind": "Field",
                                        "name": {
                                          "kind": "Name",
                                          "value": "name"
                                        }
                                      }]
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CancelRentalRequestV2Document = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CancelRentalRequestV2"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2CancellationInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "cancelRentalRequestV2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const ProjectManagerApprovedDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "ProjectManagerApproved"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2PMApprovalInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "projectManagerApproved"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SendToOwnerForApprovalDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SendToOwnerForApproval"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2SendToOwnerApprovalInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "sendRentalApplicationToOwnerForApproval"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const AssignVisitAgentToApplicationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AssignVisitAgentToApplication"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2AssignVisitAgentInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "assignVisitAgentToApplication"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SetVisitDateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SetVisitDate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2AssignVisitDateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "setVisitDateOnRentalApplication"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SetRentalApplicationAgentApprovalStatusDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SetRentalApplicationAgentApprovalStatus"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2SetStatusInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "setRentalApplicationAgentApprovalStatus"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const FinalizeRentalRequestV2AndPublishLeaseDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "FinalizeRentalRequestV2AndPublishLease"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2FinalizationInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "finalizeRentalRequestV2AndPublishLease"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unit"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const InviteNewUserToApplicationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "InviteNewUserToApplication"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2InviteUserInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "inviteNewUserToApplication"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const RemoveApplicantFromApplicationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "RemoveApplicantFromApplication"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2RemoveUserInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "removeApplicantFromApplication"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const AddApplicantToApplicationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddApplicantToApplication"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2AddApplicantToRequestInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "addApplicantToApplication"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const CreateCandidateV2Document = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateCandidateV2"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2AddApplicantToListingInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createCandidateV2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SetRentalApplicationDocumentsSignatureDateDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SetRentalApplicationDocumentsSignatureDate"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2SetDocumentSignDateInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "setRentalApplicationDocumentsSignatureDate"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const ReviveRefusedApplicationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "ReviveRefusedApplication"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalAppV2UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "reviveRefusedApplication"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const RestrictApplicantFromApplyingDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "RestrictApplicantFromApplying"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2RestrictApplicantInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "restrictApplicantFromApplying"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SetRentalApplicationDownPaymentAmountDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SetRentalApplicationDownPaymentAmount"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalRequestV2SetDownPaymentAmountInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "setRentalApplicationDownPaymentAmount"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const IncrementApplicationRankDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "IncrementApplicationRank"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "input"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RentalAppV2UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "incrementApplicationRank"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "input"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "input"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const ApplicantChosenDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "applicantChosen"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "rentalApplicationId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "applicantChosen"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "rentalApplicationId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "rentalApplicationId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const CandidateApplicationReportDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "candidateApplicationReport"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "rentalApplicationId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "candidateId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "candidateApplicationReport"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "rentalApplicationId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "rentalApplicationId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "candidateId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "candidateId"
            }
          }
        }]
      }]
    }
  }]
};
export const AddBuildingDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddBuilding"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "building"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "BuildingInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "projectId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "addBuilding"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "building"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "building"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "projectId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "projectId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateBuildingDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateBuilding"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "building"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateBuildingInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateBuilding"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "building"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "building"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetBuildingDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetBuilding"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "building"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "__typename"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "civicNumber"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "streetName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "city"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "sector"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "province"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "postalCode"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "country"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "buildDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "purchaseDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "latitude"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "longitude"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tags"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "managementStartDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tickets"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "IntValue",
                "value": "5"
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "deletedAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "closedAt"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "amenities"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "key"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pricingType"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "image"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "project"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "managementType"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "units"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "subAddress"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "bedrooms"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "floor"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "squareFootage"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "waterTankInstallationDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "listing"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "price"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "availability"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currentLease"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "startDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "endDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "monthlyPrice"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "billDay"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "amenities"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "key"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "value"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "price"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pricingType"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "tenants"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "items"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "isPayee"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "paymentMethod"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "tenant"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "firstName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "lastName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "email"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "primaryPhone"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "statistics"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalUnits"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetBuildingsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetBuildings"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "buildings"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "civicNumber"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "streetName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "city"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "sector"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "province"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "postalCode"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "country"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "purchaseDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "latitude"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "longitude"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tags"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "managementStartDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "statistics"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalUnits"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetUnitsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getUnits"
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "buildings"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "units"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildingAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "subAddress"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetSpacesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetSpaces"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "spaces"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "UnitGQL"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "subAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildingAddress"
                    }
                  }]
                }
              }, {
                "kind": "InlineFragment",
                "typeCondition": {
                  "kind": "NamedType",
                  "name": {
                    "kind": "Name",
                    "value": "BuildingGQL"
                  }
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "civicNumber"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "slug"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "streetName"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetCalendarEntriesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetCalendarEntries"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "recurringEvent"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CalendarFilterRecurringEventArgs"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "dateRange"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "DateRange"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "calendarEntries"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "recurringEvent"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "recurringEvent"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "dateRange"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "dateRange"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "entry"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "TaskGQL"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "typename"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "__typename"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "title"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "dueDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "closedAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "createdAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "deletedAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "modifiedAt"
                        }
                      }]
                    }
                  }, {
                    "kind": "InlineFragment",
                    "typeCondition": {
                      "kind": "NamedType",
                      "name": {
                        "kind": "Name",
                        "value": "RecurringEventGQL"
                      }
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "typename"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "__typename"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "title"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "description"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "RRule"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "disabledAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "runLimit"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "deletedAt"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetTicketCalendarDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getTicketCalendar"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ticketId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "ticket"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ticketId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ticketId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "FragmentSpread",
            "name": {
              "kind": "Name",
              "value": "TicketCalendar"
            }
          }]
        }
      }]
    }
  }, ...TicketCalendarFragmentDoc.definitions]
};
export const GetDepositsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetDeposits"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deposits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creationDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "depositDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "depositBy"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "primaryPhone"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "depositFor"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "notes"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "content"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "createdAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "createdBy"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "files"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileType"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "filePath"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetDepositDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetDeposit"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deposit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "creationDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "depositDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "amount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "depositBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "depositFor"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetClientCompanyDepositsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getClientCompanyDeposits"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "deposits"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "take"
                }
              }
            }, {
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "skip"
                }
              }
            }, {
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "searchString"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "query"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currentPage"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalItems"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalPages"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "amount"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "depositDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "creationDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "depositFor"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "files"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "items"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "url"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "fileName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "fileType"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "filePath"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteDepositDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteDeposit"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deleteDeposit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const AddDepositDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddDeposit"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "deposit"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "DepositInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "addDeposit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "deposit"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "deposit"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateDepositDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateDeposit"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "deposit"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateDepositInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateDeposit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "deposit"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "deposit"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetEventsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getEvents"
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "getEvents"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "__typename"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "description"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "RRule"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "disabledAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "runLimit"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "deletedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "parsedRRule"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "freq"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "dtstart"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "until"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "interval"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "count"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "entity"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "__typename"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "Ticket"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, ...TicketFragmentDoc.definitions]
};
export const CreateTicketEventDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "createTicketEvent"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "event"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RecurringEventCreateTicketInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createTicketEvent"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "event"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "event"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "description"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "RRule"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "disabledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "runLimit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "deletedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "__typename"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "entity"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "__typename"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Ticket"
                }
              }]
            }
          }]
        }
      }]
    }
  }, ...TicketFragmentDoc.definitions]
};
export const UpdateTicketEventDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "updateTicketEvent"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "event"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RecurringEventCreateTicketInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateTicketEvent"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "recurringEventId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "event"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "event"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "description"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "disabledAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "RRule"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "runLimit"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "deletedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "__typename"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "entity"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "__typename"
                }
              }, {
                "kind": "FragmentSpread",
                "name": {
                  "kind": "Name",
                  "value": "Ticket"
                }
              }]
            }
          }]
        }
      }]
    }
  }, ...TicketFragmentDoc.definitions]
};
export const CreateTicketReoccurEventDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateTicketReoccurEvent"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "event"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RecurringEventRecurringTicketInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createTicketReoccurEvent"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "event"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "event"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteTicketReoccurEventDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteTicketReoccurEvent"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "recurringEventId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deleteTicketReoccurEvent"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "recurringEventId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "recurringEventId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const SetFilesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "setFiles"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "files"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "FilesInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "setFiles"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "files"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "files"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "order"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "url"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "fileName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "fileType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "fileCategory"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "filePath"
            }
          }]
        }
      }]
    }
  }]
};
export const GetSignedUrLforFileDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetSignedURLforFile"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "path"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "getSignedURLforFile"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "path"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "path"
            }
          }
        }]
      }]
    }
  }]
};
export const GetRentalApplicationsV2Document = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetRentalApplicationsV2"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "sortBy"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "RentalRequestV2SortingParameter"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "RentalRequestsV2Filter"
            }
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "progress"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "RentalRequestsV2ProgressFilter"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "rentalApplicationsV2"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "sortBy"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "sortBy"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "status"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "status"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "progress"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "progress"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "propertyManagerApprovedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "propertyManagerRejectedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "landlordApprovedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "landlordRejectedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "completedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "chosenAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "visitAgentApprovedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "visitDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "rejected"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "at"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "reason"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "rejection"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "visitAgent"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "unit"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "subAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildingAddress"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "applicants"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "dateOfBirth"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "status"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "applicationPass"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "files"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "url"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "fileName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "fileType"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "filePath"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "slScore"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "endDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "startDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "restrictions"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "restrictedAt"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "restrictedBy"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "firstName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "lastName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "email"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "primaryPhone"
                                }
                              }]
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "restrictedFor"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "files"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileType"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "filePath"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileCategory"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateLeaseDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateLease"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "lease"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateLeaseInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateLease"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "lease"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "lease"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unit"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "__typename"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CreateLeaseDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateLease"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "lease"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "LeaseInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createLease"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "lease"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "lease"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const CancelLeaseDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CancelLease"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "terminateEarlier"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "DateTime"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "cancelLease"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "terminateEarlier"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "terminateEarlier"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateDeliveryDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateDelivery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "delivery"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateDeliveriesInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateDelivery"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "delivery"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "delivery"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetLeaseDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetLease"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "lease"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "startDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "endDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "billDay"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "monthlyPrice"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "cancelled"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "downPaymentAmount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "amenities"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "key"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pricingType"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "order"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileCategory"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unit"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "__typename"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "subAddress"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "unitType"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "floor"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "bedrooms"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildingAddress"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "squareFootage"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildingId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "building"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "civicNumber"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "streetName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "city"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "postalCode"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "sector"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "province"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "purchaseDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "country"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "latitude"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "longitude"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amenities"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "key"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "price"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pricingType"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "leases"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tenants"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isPayee"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "paymentMethod"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "tenant"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "firstName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "lastName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "email"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "primaryPhone"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "project"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "managementType"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetLeasesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetLeases"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "sortBy"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "LeaseSortingParameter"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "LeaseFilter"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "leases"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "sortBy"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "sortBy"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "status"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "status"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "unit"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "__typename"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "subAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildingAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildingId"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastModifiedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "startDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "endDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "billDay"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "monthlyPrice"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tenants"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "isPayee"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "paymentMethod"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "tenant"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "firstName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "lastName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "primaryPhone"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "project"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetDeliveriesDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetDeliveries"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "sortBy"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "LeaseSortingParameter"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "LeaseFilter"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "leases"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "sortBy"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "sortBy"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "status"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "status"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "unit"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "__typename"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "subAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildingAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildingId"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastModifiedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "startDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "endDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "billDay"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "monthlyPrice"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "documentsMarkedCompletedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "keysDeliveredAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "deliveryFinalizedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "leaseAgent"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "downPaymentAmount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalDownPaymentAmount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "project"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetDeliveryLeaseDetailsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetDeliveryLeaseDetails"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "lease"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "startDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "endDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unit"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "__typename"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "leases"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "startDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "endDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "tenants"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "items"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "__typename"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "isPayee"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "paymentMethod"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "tenant"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "firstName"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "lastName"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "email"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "primaryPhone"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tenants"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isPayee"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "paymentMethod"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "tenant"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "__typename"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "firstName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "lastName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "email"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "primaryPhone"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "downPaymentAmount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalDownPaymentAmount"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileCategory"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetListingsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetListings"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "sortBy"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "ListingSortingParameter"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "floor"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Int"
            }
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "bedrooms"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Int"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "listings"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "sortBy"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "sortBy"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "floor"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "floor"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "bedrooms"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "bedrooms"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "published"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publishedOn"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "availability"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isUrgent"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "slug"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "unit"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "subAddress"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "unitType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "floor"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "bedrooms"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "building"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "latitude"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "longitude"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "civicNumber"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "streetName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "sector"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "image"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "currentLease"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "endDate"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "consents"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "consentType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "consentValue"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }]
        }
      }]
    }
  }]
};
export const GetUnitRentalApplicantsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetUnitRentalApplicants"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "unit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "rentalRequestsV2"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "applicants"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "propertyManagerApprovedAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "visitAgentApprovedAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "landlordApprovedAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "rejected"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "at"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "applicationRank"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "files"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "items"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "fileCategory"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "fileName"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CreateListingDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateListing"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "listing"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CreateListingInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "addListing"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "listing"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "listing"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "published"
            }
          }]
        }
      }]
    }
  }]
};
export const ListingDetailsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "ListingDetails"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "listingId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "listing"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "listingId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "description"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "locale"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "category"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "price"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "availability"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isUrgent"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isCreditCheckRequired"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "videoLink"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "gallery"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "order"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "url"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "fileName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "fileType"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "filePath"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "thumbnail"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "order"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "url"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "fileName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "fileType"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "filePath"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "consents"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "consentType"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "consentValue"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unit"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "__typename"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "subAddress"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildingAddress"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "published"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publishedOn"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "projectId"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const SetListingStatusDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "SetListingStatus"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "publishStatus"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Boolean"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "setPublishStatus"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "publishStatus"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "publishStatus"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "published"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "publishedOn"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateListingDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateListing"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "listing"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateListingInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateListing"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "listing"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "listing"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "published"
            }
          }]
        }
      }]
    }
  }]
};
export const AddNoteDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddNote"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "resourceId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "resourceType"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "note"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "NotesInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "addNote"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "resourceId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "resourceId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "resourceType"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "resourceType"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "notes"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "note"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "content"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "firstName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creationDate"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const RemoveNoteDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "RemoveNote"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "noteId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "removeNote"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "noteId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "noteId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "content"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetOrganizationDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getOrganization"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "organization"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "address"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "civicNumber"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "postalCode"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "streetName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "city"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "province"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "country"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "sector"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "phoneInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "secondaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "phoneIsMobile"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "secondaryPhoneIsMobile"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "settings"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amountOfDaysBeforeLeaseRenewal"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "automaticallyUnpublishAdsAfterNewLease"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "image"
            }
          }]
        }
      }]
    }
  }]
};
export const GetOrganizationsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetOrganizations"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "organizations"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "image"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetProjectsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetProjects"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "projects"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "phoneInfo"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "primaryPhone"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "managementType"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "statistics"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalUnits"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalBuildings"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetSingleProjectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetSingleProject"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "legalName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "contactName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "phoneInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tickets"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "deletedAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "closedAt"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "managementType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "users"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "IntValue",
                "value": "1000"
              }
            }, {
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "value": {
                "kind": "IntValue",
                "value": "0"
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const ProjectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "Project"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "legalName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "contactName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "phoneInfo"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "users"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "take"
                }
              }
            }, {
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "skip"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currentPage"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalItems"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalPages"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "primaryPhone"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "secondaryPhone"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "email"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "firstName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "lastName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "creationDate"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "managementType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetProjectBuildingsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getProjectBuildings"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "project"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "buildings"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "take"
                }
              }
            }, {
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "skip"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "skip"
                }
              }
            }, {
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "searchString"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "query"
                }
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currentPage"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalItems"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "totalPages"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "__typename"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "civicNumber"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "streetName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "buildDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "city"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "province"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "sector"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "statistics"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalUnits"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const AddProjectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddProject"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "project"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ProjectInput"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "parentOrgId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "addProject"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "project"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "project"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "parentOrgId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "parentOrgId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateProjectDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateProject"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "project"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateProjectInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateProject"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "project"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "project"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetProjectUsersDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getProjectUsers"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "rolesFilter"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "RolesFilter"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "users"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "roles"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "kind"
              },
              "value": {
                "kind": "EnumValue",
                "value": "inclusive"
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "roles"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "EnumValue",
                  "value": "Landlord"
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "secondaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "firstName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creationDate"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetProviderDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getProvider"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "provider"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "primaryPhone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "secondaryPhone"
            }
          }]
        }
      }]
    }
  }]
};
export const GetProvidersDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getProviders"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "providers"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateProviderDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateProvider"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "provider"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateProviderInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateProvider"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "provider"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "provider"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const CreateProviderDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateProvider"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "provider"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CreateProviderInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createProvider"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "provider"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "provider"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetActiveLeaseDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetActiveLease"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "date"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "DateTime"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "statistics"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "timestamp"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "activeLeases"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "date"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "date"
                }
              }
            }]
          }]
        }
      }]
    }
  }]
};
export const GetLeasesToBeRenewedDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetLeasesToBeRenewed"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "date"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "DateTime"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "statistics"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "timestamp"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "leasesToBeRenewed"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "date"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "date"
                }
              }
            }]
          }]
        }
      }]
    }
  }]
};
export const GetRentRollForClientDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetRentRollForClient"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "date"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "DateTime"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "projectId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "statistics"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "timestamp"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "rentRollForClient"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "date"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "date"
                }
              }
            }, {
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "projectId"
              },
              "value": {
                "kind": "Variable",
                "name": {
                  "kind": "Name",
                  "value": "projectId"
                }
              }
            }]
          }]
        }
      }]
    }
  }]
};
export const GetTaskDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetTask"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "taskId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "task"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "taskId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "taskId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "dueDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "closedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modifiedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "ticket"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "category"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "priority"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdBy"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tasks"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "totalItems"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "title"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "closedAt"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "assignee"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modifiedBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "invoice"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "description"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "date"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "payer"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "files"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "order"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileType"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "filePath"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileCategory"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "modifiedAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CreateTaskDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateTask"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ticketId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "task"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CreateTaskInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createTask"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ticketId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ticketId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "task"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "task"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateTaskDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateTask"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "taskId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "task"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateTaskInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateTask"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "taskId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "taskId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "task"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "task"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "dueDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "closedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modifiedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "assignee"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modifiedBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "invoice"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "description"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "date"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "files"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "order"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileType"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "modifiedAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteTaskDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "DeleteTask"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "taskId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deleteTask"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "taskId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "taskId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const CreateTaskInvoiceDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "CreateTaskInvoice"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "taskId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "invoice"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CreateTaskInvoiceInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createTaskInvoice"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "taskId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "taskId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "invoice"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "invoice"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateTaskInvoiceDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateTaskInvoice"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "invoiceId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "invoice"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateTaskInvoiceInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateTaskInvoice"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "invoiceId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "invoiceId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "invoice"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "invoice"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "amount"
            }
          }]
        }
      }]
    }
  }]
};
export const GetTenantDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetTenant"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tenant"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "firstName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tickets"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "IntValue",
                "value": "5"
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "primaryPhone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "secondaryPhone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "leases"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "paymentMethod"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isPayee"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "lease"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "startDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "endDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "monthlyPrice"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "project"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "amenities"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "key"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "price"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "pricingType"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "unit"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "subAddress"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "buildingId"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "buildingAddress"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentLeases"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "paymentMethod"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "isPayee"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "lease"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "startDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "endDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "monthlyPrice"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "project"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "amenities"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "key"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "value"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "price"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "pricingType"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "unit"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "subAddress"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "buildingId"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "buildingAddress"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetTenantsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetTenants"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "TenantStatus"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "sortBy"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "UserSortingParameter"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tenants"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "status"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "status"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "sortBy"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "sortBy"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "firstName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "secondaryPhone"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateTenantDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateTenant"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "tenant"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateTenantInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateTenant"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "tenant"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "tenant"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetTenantLeaseInfoDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetTenantLeaseInfo"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tenant"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "firstName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentLeases"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "lease"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "startDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "endDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "monthlyPrice"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "unit"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "subAddress"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "buildingId"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "buildingAddress"
                            }
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "tenants"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "items"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "tenant"
                                },
                                "selectionSet": {
                                  "kind": "SelectionSet",
                                  "selections": [{
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "email"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "name"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "firstName"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "lastName"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "primaryPhone"
                                    }
                                  }, {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "secondaryPhone"
                                    }
                                  }]
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetTicketsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getTickets"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "sortBy"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "TicketSortingParameter"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "priorities"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "TicketPriority"
            }
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "taskAssigneeIds"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "UUID"
            }
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "categories"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "TicketCategory"
            }
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "status"
        }
      },
      "type": {
        "kind": "ListType",
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "TicketStatus"
            }
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tickets"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "taskAssigneeIds"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "taskAssigneeIds"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "sortBy"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "sortBy"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "priorities"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "priorities"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "categories"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "categories"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "status"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "status"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "category"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "modifiedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "closedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "priority"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tags"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "origin"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "deletedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "isRecurring"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "resource"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "space"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "__typename"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Unit"
                        }
                      }, {
                        "kind": "FragmentSpread",
                        "name": {
                          "kind": "Name",
                          "value": "Building"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "tenant"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "project"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tasks"
                },
                "arguments": [{
                  "kind": "Argument",
                  "name": {
                    "kind": "Name",
                    "value": "status"
                  },
                  "value": {
                    "kind": "ListValue",
                    "values": []
                  }
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "title"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "createdAt"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "dueDate"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "closedAt"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, ...UnitFragmentDoc.definitions, ...BuildingFragmentDoc.definitions]
};
export const GetRelatedTicketsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getRelatedTickets"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "resource"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "RecurringEventResourceFilter"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Int"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "sortBy"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "TicketSortingParameter"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "tickets"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "resource"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "resource"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "sortBy"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "sortBy"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "closedAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "deletedAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetTicketDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getTicket"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ticketId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "ticket"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ticketId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ticketId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "closedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modifiedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "origin"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tags"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "deletedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "isRecurring"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "order"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "priority"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "category"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "resource"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "space"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "__typename"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "Unit"
                    }
                  }, {
                    "kind": "FragmentSpread",
                    "name": {
                      "kind": "Name",
                      "value": "Building"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tenant"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "project"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tasks"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "status"
              },
              "value": {
                "kind": "ListValue",
                "values": []
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "dueDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "closedAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "assignee"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "profileImage"
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "invoice"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "title"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "amount"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "date"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "files"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "items"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "order"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "url"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "fileName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "fileType"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, ...UnitFragmentDoc.definitions, ...BuildingFragmentDoc.definitions]
};
export const CreateTicketDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "createTicket"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ticket"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "CreateTicketInputType"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "createTicket"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ticket"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ticket"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateTicketDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "updateTicket"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ticketId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ticket"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateTicketInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateTicket"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ticketId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ticketId"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ticket"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ticket"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "priority"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "category"
            }
          }]
        }
      }]
    }
  }]
};
export const CloseTaskDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "closeTask"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "taskId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "closeTask"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "taskId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "taskId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "title"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "dueDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "closedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modifiedAt"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "assignee"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "modifiedBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "createdBy"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "invoice"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amount"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "description"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "date"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "files"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "order"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "url"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileName"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "fileType"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "createdAt"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "modifiedAt"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const CloseTicketDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "closeTicket"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ticketId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "closeTicket"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ticketId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ticketId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const DeleteTicketDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "deleteTicket"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "ticketId"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "deleteTicket"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "ticketId"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "ticketId"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const AddUnitDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "AddUnit"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "unit"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UnitInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "addUnit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "unit"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "unit"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetUnitDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetUnit"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "unit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "__typename"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tickets"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "IntValue",
                "value": "5"
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "deletedAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "closedAt"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "building"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "civicNumber"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "streetName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "city"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "postalCode"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "sector"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "province"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "purchaseDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "country"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "latitude"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "longitude"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amenities"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "key"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "price"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pricingType"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "project"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "managementType"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentLease"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "startDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "endDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "monthlyPrice"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "cancelled"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amenities"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "key"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "price"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pricingType"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tenants"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "isPayee"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "paymentMethod"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "tenant"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "firstName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "lastName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "primaryPhone"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "buildingAddress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "subAddress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unitType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "bedrooms"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "floor"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "squareFootage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "waterTankInstallationDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "amenities"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "key"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pricingType"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "listing"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "description"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "locale"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "category"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "availability"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "videoLink"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "gallery"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "order"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnail"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "published"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publishedOn"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "projectId"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "leases"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "IntValue",
                "value": "50"
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "startDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "endDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "billDay"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "monthlyPrice"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "tenants"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "items"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "isPayee"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "paymentMethod"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "tenant"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "firstName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "lastName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "email"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "primaryPhone"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "amenities"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "key"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "value"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "price"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pricingType"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateUnitDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "UpdateUnit"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "unit"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateUnitInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateUnit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "unit"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "unit"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "__typename"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "tickets"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "IntValue",
                "value": "5"
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "title"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "deletedAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "closedAt"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "building"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "civicNumber"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "streetName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "city"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "postalCode"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "sector"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "province"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "purchaseDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "country"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "latitude"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "longitude"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amenities"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "key"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "price"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pricingType"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "project"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "managementType"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentLease"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "startDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "endDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "monthlyPrice"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "amenities"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "key"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "price"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "pricingType"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tenants"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "isPayee"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "paymentMethod"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "tenant"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "firstName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "lastName"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "primaryPhone"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "buildingAddress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "subAddress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "unitType"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "bedrooms"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "floor"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "squareFootage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "waterTankInstallationDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "amenities"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "key"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "value"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "pricingType"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "listing"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "description"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "locale"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "category"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "value"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "availability"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "videoLink"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "gallery"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "order"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "thumbnail"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "published"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "publishedOn"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "projectId"
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "leases"
            },
            "arguments": [{
              "kind": "Argument",
              "name": {
                "kind": "Name",
                "value": "take"
              },
              "value": {
                "kind": "IntValue",
                "value": "10"
              }
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "startDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "endDate"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "billDay"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "monthlyPrice"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "tenants"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "items"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "isPayee"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "paymentMethod"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "tenant"
                            },
                            "selectionSet": {
                              "kind": "SelectionSet",
                              "selections": [{
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "id"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "name"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "firstName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "lastName"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "email"
                                }
                              }, {
                                "kind": "Field",
                                "name": {
                                  "kind": "Name",
                                  "value": "primaryPhone"
                                }
                              }]
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "amenities"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "key"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "value"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "price"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pricingType"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "notes"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "content"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdAt"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "createdBy"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "files"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "items"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "url"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileName"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "fileType"
                    }
                  }, {
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "filePath"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetEmptyUnitsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetEmptyUnits"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "searchString"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "emptyUnits"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "searchString"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildingId"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "buildingAddress"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "subAddress"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "building"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "project"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        }
                      }]
                    }
                  }]
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "listing"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "id"
                    }
                  }]
                }
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }]
        }
      }]
    }
  }]
};
export const GetUnitLeaseInfoDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "getUnitLeaseInfo"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "unit"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "subAddress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "buildingAddress"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentLease"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "startDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "endDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "monthlyPrice"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "tenants"
                },
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "items"
                    },
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "tenant"
                        },
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "id"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "email"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "primaryPhone"
                            }
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "secondaryPhone"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetUsersDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetUsers"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "roles"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "RolesFilter"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "users"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "roles"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "roles"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "secondaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "firstName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creationDate"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "language"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const GetApplicantsDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetApplicants"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "applicants"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "secondaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "firstName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastName"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const UpdateUserProfileDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "mutation",
    "name": {
      "kind": "Name",
      "value": "updateUserProfile"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "provider"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UpdateProviderInput"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "updateProvider"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "provider"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "provider"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }]
        }
      }]
    }
  }]
};
export const GetUserDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetUser"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "id"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "UUID"
          }
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "user"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "firstName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "primaryPhone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "secondaryPhone"
            }
          }]
        }
      }]
    }
  }]
};
export const GetCompanyUsersDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "GetCompanyUsers"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "take"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "skip"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "Int"
          }
        }
      }
    }, {
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "query"
        }
      },
      "type": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "String"
        }
      }
    }],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "users"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "take"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "take"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "skip"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "skip"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "searchString"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "query"
            }
          }
        }, {
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "roles"
          },
          "value": {
            "kind": "ObjectValue",
            "fields": [{
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "kind"
              },
              "value": {
                "kind": "EnumValue",
                "value": "inclusive"
              }
            }, {
              "kind": "ObjectField",
              "name": {
                "kind": "Name",
                "value": "roles"
              },
              "value": {
                "kind": "ListValue",
                "values": [{
                  "kind": "EnumValue",
                  "value": "PropertyManager"
                }, {
                  "kind": "EnumValue",
                  "value": "VisitAgent"
                }]
              }
            }]
          }
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "currentPage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalItems"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "totalPages"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "items"
            },
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "id"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "primaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "secondaryPhone"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "email"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "name"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "firstName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "lastName"
                }
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "creationDate"
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
export const MeDocument = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "Me"
    },
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "me"
        },
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "primaryPhone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "secondaryPhone"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "profileImage"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "email"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "name"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "firstName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "lastName"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "language"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "creationDate"
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "role"
            }
          }]
        }
      }]
    }
  }]
};