function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { DatePicker } from "../../components/DatePicker.js";
import { ProjectSelector } from "../../components/Selectors/ProjectSelector.js";
import { FileDropZone } from "../../components/Tailwind/FileDropZone.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { NumberTextField } from "../../components/Tailwind/TextField.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { DepositActionsTypes } from "../../store/artifacts/Deposit/types.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);
export const DepositSideBar = props => {
  const {
    sectionTitle,
    description
  } = props;
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const isLoading = useSelector(Selectors.deposit.isLoading);
  const amount = useSelector(Selectors.deposit.getAmount);
  const project = useSelector(Selectors.deposit.getDepositFor);
  const depositDate = useSelector(Selectors.deposit.getDepositDate);
  const id = useSelector(Selectors.deposit.getId);
  const userId = useSelector(Selectors.session.getUserId); // sad i used a useState

  const [files, setFiles] = useState(useSelector(Selectors.deposit.getFiles));
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();

  const onFileDrop = pFiles => {
    setFiles(prev => {
      return [...prev, ...pFiles];
    });
  };

  const onFileDelete = fileToDelete => {
    setFiles(prev => {
      return prev.filter(file => file.fileName !== fileToDelete.fileName);
    });
  };

  const onSubmit = data => {
    props.onDoneSubmit({
      id: data.id,
      amount: data.amount,
      depositDate: data.depositDate,
      projectId: data.project.value,
      responsableId: data.responsable?.value || userId,
      files
    });
  };

  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, sectionTitle), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "maxWidth": "67.2rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, description)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "amount",
    control: control,
    defaultValue: amount,
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(NumberTextField, _extends({
      thousandSeparator: true,
      suffix: "$"
    }, field, {
      label: t("deposit-form-amount"),
      error: showError(errors, "amount", t("deposit-form-error-amount"))
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "depositDate",
    control: control,
    defaultValue: depositDate,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(DatePicker, _extends({}, field, {
      defaultValue: DateTime.fromJSDate(depositDate),
      error: showError(errors, "depositDate", t("deposit-form-error-deposit-date")),
      id: "depositDate",
      title: t("deposit-form-date")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "project",
    control: control,
    defaultValue: {
      label: project?.name,
      value: project?.id
    },
    rules: {
      validate: {
        isValid: v => {
          if (!v?.value) return false;
          return true;
        }
      }
    },
    render: ({
      field
    }) => __cssprop(ProjectSelector, _extends({}, field, {
      error: showError(errors, "project", t("deposit-form-error-destination")),
      inform: true,
      label: t("deposit-form-deposit-destination"),
      selectedProject: project,
      defaultValue: {
        label: project?.name,
        value: project?.id
      }
    }))
  })), __cssprop(StyledItems, null, __cssprop(FileDropZone, {
    files: files,
    inForm: true,
    onFilesDrop: onFileDrop,
    onFilesDelete: onFileDelete
  }))), __cssprop("div", {
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "justifyContent": "flex-end"
    }
  }, __cssprop(ProtectedButton, {
    permission: {
      kind: "Deposit",
      permission: "mutate:update"
    },
    submit: true,
    loading: isLoading,
    disabled: Object.keys(errors).length !== 0,
    primary: true
  }, t("save")), id && __cssprop(ProtectedButton, {
    permission: {
      kind: "Deposit",
      permission: "mutate:delete"
    },
    onAction: () => {
      dispatch({
        type: DepositActionsTypes.DEPOSIT_DELETE,
        payload: id
      });
    },
    loading: isLoading,
    disabled: Object.keys(errors).length !== 0,
    destructive: true
  }, t("delete")))));
};
export default DepositSideBar;