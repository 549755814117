/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useLazyQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { PaperClipIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import useFileModalViewer from "../../forms/useFileModalViewer.js";
import { GetSignedUrLforFileDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import FileSaver from "../../../../_snowpack/pkg/file-saver.js";
import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { Button } from "./Button.js";
import { DropDownActions } from "./DropDownActions.js";
import { ProtectedActions } from "./ProtectedComponents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
// codesnippet that i took from stackoverflow
// its for a cheap image link verification
export function isImgLink(url) {
  if (typeof url !== "string") return false; // eslint-disable-next-line no-useless-escape

  return url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) != null;
}

const RenderDownloadButton = ({
  file
}) => {
  const {
    t
  } = useI18n();
  const [signedUrl, SetSignedUrl] = useState(undefined);
  const [getSignedUrl, {
    loading,
    called
  }] = useLazyQuery(GetSignedUrLforFileDocument);

  const handlePrepareDownload = async path => {
    const response = await getSignedUrl({
      variables: {
        path
      }
    });
    SetSignedUrl(response.data.getSignedURLforFile);
  };

  useEffect(() => {
    if (called && !loading && signedUrl) {
      FileSaver.saveAs(signedUrl, file.fileName);
    }
  }, [loading, called, signedUrl]);
  return __cssprop(Button, {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      },
      "cursor": "pointer"
    },
    onAction: () => handlePrepareDownload(file.filePath),
    loading: loading
  }, t("download"));
};

export const Attachments = ({
  files,
  handleDeleteFile,
  visualOnly
}) => {
  const {
    t
  } = useI18n();
  const [ViewerModal, openViewerModal] = useFileModalViewer();
  const role = useSelector(Selectors.session.getUserRole);

  if (!files || files?.length === 0) {
    return null;
  } // i dont know why some file a put as null sometime in some rerender...


  const notNullFiles = files.filter(x => x !== null);
  return __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "gridColumn": "span 2 / span 2"
      }
    }
  }, __cssprop("dt", {
    css: {
      "display": "flex",
      "justifyContent": "space-between"
    }
  }, __cssprop("span", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("attachments"))), __cssprop("dd", {
    css: {
      "marginTop": "0.4rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, __cssprop("ul", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, notNullFiles.map((file, idx) => {
    return __cssprop("li", {
      key: `${file.fileName}-${idx}`,
      css: {
        "paddingLeft": "1.2rem",
        "paddingRight": "1.6rem",
        "paddingTop": "1.2rem",
        "paddingBottom": "1.2rem",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between",
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }, __cssprop("div", {
      css: {
        "width": "0px",
        "flex": "1 1 0%",
        "display": "flex",
        "alignItems": "center"
      }
    }, isImgLink(file.url) || ["image/jpeg", "image/jpeg", "image/gif"].includes(file.fileType) ? __cssprop("img", {
      src: file.url,
      alt: file.fileName,
      css: {
        "height": "5.6rem",
        "width": "5.6rem",
        "borderRadius": "0.6rem"
      }
    }) : __cssprop(PaperClipIcon, {
      css: {
        "flexShrink": "0",
        "height": "2rem",
        "width": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      },
      "aria-hidden": "true"
    }), __cssprop("span", {
      css: {
        "marginLeft": "0.8rem",
        "flex": "1 1 0%",
        "width": "0px",
        "overflow": "hidden",
        "textOverflow": "ellipsis",
        "whiteSpace": "nowrap"
      }
    }, file.fileName)), __cssprop("div", {
      css: {
        "marginLeft": "1.6rem",
        "flexShrink": "0",
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(1.6rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }, __cssprop(RenderDownloadButton, {
      file: file
    }), file.fileType === "application/pdf" && __cssprop("button", {
      type: "button",
      onClick: () => openViewerModal(file),
      css: {
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-offset-width": "2px",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
        }
      }
    }, t("view")), !visualOnly && __cssprop("div", null, __cssprop(DropDownActions, {
      content: "open",
      dots: true,
      actions: [ProtectedActions(role, [{
        content: t("delete"),
        destructive: true,
        onAction: () => {
          handleDeleteFile(file);
        },
        permission: {
          kind: "Lease",
          permission: "mutate:delete"
        }
      }])]
    }))));
  }))), __cssprop(ViewerModal, null));
};