import React from "../../../../_snowpack/pkg/react.js";
import useI18n from "../../hooks/useI18n.js";
import { LeaseToBeRenewWidget, ActiveLeaseWidget } from "./widgets/index.js";
import { Page } from "../../components/Tailwind/Page.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Reports = _props => {
  const {
    t
  } = useI18n();
  return __cssprop(Page, {
    title: t("report-title")
  }, __cssprop(ActiveLeaseWidget, null), __cssprop(LeaseToBeRenewWidget, null));
};