import _styled from "../../../_snowpack/pkg/@emotion/styled.js";
import { Disclosure } from "../../../_snowpack/pkg/@headlessui/react.js";
import { MinusSmIcon, PaperClipIcon, PlusSmIcon } from "../../../_snowpack/pkg/@heroicons/react/solid.js";
import useFileModalViewer from "../forms/useFileModalViewer.js";
import useI18n from "../hooks/useI18n.js";
import { Selectors, useSelector } from "../hooks/useRedux.js";
import { LeaseActionsTypes } from "../store/artifacts/Lease/types.js";
import { findPaymentMethodForLease, getFormattedPriceForLease } from "../store/artifacts/Lease/util.js";
import { FeatureTypeInput, ReversedFeatureType } from "../utils/types.js";
import { DateTime } from "../../../_snowpack/pkg/luxon.js";
import React, { useMemo } from "../../../_snowpack/pkg/react.js";
import { useDispatch } from "../../../_snowpack/pkg/react-redux.js";
import { useHistory } from "../../../_snowpack/pkg/react-router.js";
import { PaymentMethodBadge } from "./PaymentMethodBadge.js";
import { isImgLink } from "./Tailwind/Attachments.js";
import { Badge } from "./Tailwind/Badge.js";
import { DescriptionList } from "./Tailwind/DescriptionList.js";
import { ProtectedCard, ProtectedLink } from "./Tailwind/ProtectedComponents.js";
import { SmallTenant } from "./Tailwind/SmallTenant.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const StyledBar = _styled.div(({
  cancelled
}) => [{
  "height": "0.8rem",
  "borderRadius": "9999px"
}, !cancelled && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
}, cancelled && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(248, 113, 113, var(--tw-bg-opacity))"
}]);

export const LeaseViewerAttachments = ({
  files
}) => {
  const {
    t
  } = useI18n();
  const [ViewerModal, openViewerModal] = useFileModalViewer();

  if (!files || files?.length === 0) {
    return null;
  } // i dont know why some file a put as null sometime in some rerender...


  const notNullFiles = files.filter(x => x !== null);
  return __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "gridColumn": "span 2 / span 2"
      }
    }
  }, __cssprop("dd", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, __cssprop("ul", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, notNullFiles.map((file, idx) => {
    return __cssprop("li", {
      key: `${file.fileName}-${idx}`,
      css: {
        "paddingLeft": "1.2rem",
        "paddingRight": "1.6rem",
        "paddingTop": "0.4rem",
        "paddingBottom": "0.4rem",
        "display": "flex",
        "alignItems": "center",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(1.2rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "flex": "1 1 0%"
      }
    }, isImgLink(file.url) || ["image/jpeg", "image/jpeg", "image/gif"].includes(file.fileType) ? __cssprop("img", {
      src: file.url,
      alt: file.fileName,
      css: {
        "height": "4rem",
        "width": "4rem",
        "borderRadius": "0.6rem"
      }
    }) : __cssprop(PaperClipIcon, {
      css: {
        "height": "2rem",
        "width": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      },
      "aria-hidden": "true"
    })), __cssprop("div", {
      css: {
        "width": "12.8rem",
        "overflow": "hidden",
        "textOverflow": "ellipsis",
        "whiteSpace": "nowrap",
        "@media (min-width: 1024px)": {
          "width": "20.8rem"
        },
        "@media (min-width: 1280px)": {
          "width": "28.8rem"
        }
      }
    }, __cssprop("span", {
      css: {
        "overflow": "hidden",
        "textOverflow": "ellipsis",
        "whiteSpace": "nowrap"
      }
    }, file.fileName)), __cssprop("div", {
      css: {
        "marginLeft": "1.6rem",
        "display": "grid"
      }
    }, __cssprop("a", {
      css: {
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-offset-width": "2px",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
        },
        "display": "flex",
        "justifyContent": "flex-end"
      },
      href: file.url,
      target: "_blank",
      download: true,
      rel: "noreferrer"
    }, t("download")), file.fileType === "application/pdf" && __cssprop("button", {
      type: "button",
      onClick: () => openViewerModal(file),
      css: {
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-offset-width": "2px",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
        },
        "justifyContent": "flex-end"
      }
    }, t("view"))));
  }))), __cssprop(ViewerModal, null));
};

const GetAmenities = ({
  kind,
  lease
}) => {
  const {
    t
  } = useI18n();

  const StyledSvg = _styled.svg(() => [{
    "marginLeft": "-0.2rem",
    "marginRight": "0.6rem",
    "height": "0.8rem",
    "width": "0.8rem",
    "--tw-text-opacity": "1",
    "color": "rgba(129, 140, 248, var(--tw-text-opacity))"
  }]);

  const lang = useSelector(Selectors.session.getUserLanguage);

  if (kind !== "tenant" && lease.amenities) {
    return __cssprop(Disclosure, null, ({
      open
    }) => __cssprop(DescriptionList.Item, {
      withPadding: true,
      inline: true,
      title: t("amenities-title")
    }, __cssprop(Disclosure.Button, {
      css: {
        "position": "relative",
        "width": "100%",
        "display": "flex",
        "justifyContent": "flex-end",
        "alignItems": "center",
        "textAlign": "right"
      },
      className: "group"
    }, __cssprop("span", {
      css: {
        "marginLeft": "2.4rem",
        "display": "flex",
        "alignItems": "center"
      }
    }, open ? __cssprop(MinusSmIcon, {
      css: {
        "display": "block",
        "height": "2.4rem",
        "width": "2.4rem",
        "--tw-text-opacity": "1",
        "color": "rgba(129, 140, 248, var(--tw-text-opacity))",
        ".group:hover &": {
          "--tw-text-opacity": "1",
          "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
        }
      },
      "aria-hidden": "true"
    }) : __cssprop(PlusSmIcon, {
      css: {
        "display": "block",
        "height": "2.4rem",
        "width": "2.4rem",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
        ".group:hover &": {
          "--tw-text-opacity": "1",
          "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
        }
      },
      "aria-hidden": "true"
    }))), __cssprop(Disclosure.Panel, {
      as: "div",
      css: {}
    }, __cssprop("div", {
      css: {
        "display": "grid",
        "gridTemplateColumns": "repeat(3, minmax(0, 1fr))",
        "rowGap": "0px",
        "columnGap": "0.4rem"
      }
    }, lease.amenities.map(item => {
      return __cssprop("div", {
        key: item.id,
        css: {
          "display": "flex",
          "alignItems": "center",
          "borderWidth": "1px",
          "--tw-border-opacity": "1",
          "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
          "paddingLeft": "1.2rem",
          "paddingRight": "1.2rem",
          "paddingTop": "0.2rem",
          "paddingBottom": "0.2rem",
          "borderRadius": "9999px",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "fontWeight": "500",
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
          "--tw-text-opacity": "1",
          "color": "rgba(0, 0, 0, var(--tw-text-opacity))",
          "marginRight": "0.8rem",
          "marginTop": "0.8rem"
        }
      }, __cssprop(StyledSvg, {
        fill: "currentColor",
        viewBox: "0 0 8 8"
      }, __cssprop("circle", {
        cx: 4,
        cy: 4,
        r: 3
      })), t(item.key), item.value && FeatureTypeInput[ReversedFeatureType[item.key]] !== "boolean" ? `: ${item.value}` : null);
    })))));
  }

  return null;
};

const GetFilesView = ({
  files
}) => {
  const {
    t
  } = useI18n();

  if (!files || files.length === 0) {
    return null;
  }

  return __cssprop(Disclosure, null, ({
    open
  }) => __cssprop(DescriptionList.Item, {
    inline: true,
    withPadding: true,
    title: t("file-section-title")
  }, __cssprop(Disclosure.Button, {
    css: {
      "position": "relative",
      "width": "100%",
      "display": "flex",
      "justifyContent": "flex-end",
      "alignItems": "center",
      "textAlign": "right"
    },
    className: "group"
  }, __cssprop("span", {
    css: {
      "marginLeft": "2.4rem",
      "display": "flex",
      "alignItems": "center"
    }
  }, open ? __cssprop(MinusSmIcon, {
    css: {
      "display": "block",
      "height": "2.4rem",
      "width": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(129, 140, 248, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }) : __cssprop(PlusSmIcon, {
    css: {
      "display": "block",
      "height": "2.4rem",
      "width": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }))), __cssprop(Disclosure.Panel, {
    as: "div",
    css: {}
  }, __cssprop(LeaseViewerAttachments, {
    files: files
  }))));
};

export const LeaseViewer = ({
  kind,
  lease
}) => {
  const {
    t
  } = useI18n();
  const dispatch = useDispatch();
  const history = useHistory();
  const role = useSelector(Selectors.session.getUserRole);
  const paymentMethod = findPaymentMethodForLease(lease);
  const files = [];

  const getTenant = () => {
    if (!lease?.tenants?.items) {
      return null;
    }

    return __cssprop(DescriptionList.Item, {
      withPadding: true,
      inline: true,
      title: t("tenants")
    }, lease.tenants.items.map(tenant => {
      return __cssprop(SmallTenant, {
        tenant: tenant.tenant
      });
    }));
  };

  const percentage = useMemo(() => {
    const total = DateTime.fromISO(lease.endDate).diff(DateTime.fromISO(lease.startDate), "days").days;
    const remaining = DateTime.fromISO(lease.endDate).diff(DateTime.now(), "days").days;
    return (total - remaining) * 100 / total;
  }, [lease]);
  return __cssprop(ProtectedCard, {
    permission: {
      kind: "Lease",
      permission: "view:lease"
    }
  }, __cssprop("div", {
    css: {
      "padding": "1.6rem",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "@media (min-width: 640px)": {
        "padding": "2.4rem"
      }
    }
  }, __cssprop("dl", {
    css: {
      "display": "flex",
      "flex": "1 1 0%",
      "flexDirection": "row",
      "justifyContent": "space-between",
      "padding": "0.4rem",
      "@media (min-width: 640px)": {
        "gridColumn": "span 12 / span 12",
        "rowGap": "2.4rem"
      }
    }
  }, ["Manager", "Admin", "Owner"].indexOf(role) > -1 && __cssprop("div", null, __cssprop("dt", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("lease-number")), __cssprop("dd", {
    css: {
      "marginTop": "0.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, __cssprop(ProtectedLink, {
    text: lease.id,
    action: {
      kind: "ClickAction",
      event: () => {
        dispatch({
          type: LeaseActionsTypes.LEASE_INITIALIZE_WITH_LEASE,
          payload: lease.id
        });
        history.push(`/lease/${lease.id}`);
      }
    },
    permission: {
      kind: "Lease",
      permission: "mutate:update"
    }
  }))), __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(3, minmax(0, 1fr))"
    }
  }, __cssprop("div", null, __cssprop("dt", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("status")), __cssprop("dd", {
    css: {
      "marginTop": "0.4rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
      "justifyContent": "flex-start",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "marginRight": "1.6rem"
    }
  }, __cssprop(Badge, {
    success: true
  }, t("current")), lease.cancelled && __cssprop(Badge, {
    critical: true
  }, t("tab-cancelled"))))), __cssprop("div", null, __cssprop("dt", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("days-left")), __cssprop("dd", {
    css: {
      "marginTop": "0.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("lease-viewer-day-remaining", {
    date: Math.floor(DateTime.fromISO(lease.endDate).diff(DateTime.now(), "days").days)
  }))), __cssprop("div", null, __cssprop("dt", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("lease-total-amount")), __cssprop("dd", {
    css: {
      "marginTop": "0.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, getFormattedPriceForLease(lease)))))), __cssprop("div", {
    css: {
      "paddingBottom": "1.6rem",
      "@media (min-width: 640px)": {
        "alignItems": "flex-start"
      }
    }
  }, __cssprop(DescriptionList, null, kind === "unit" ? null : getTenant(), __cssprop(DescriptionList.Item, {
    withPadding: true,
    inline: true,
    title: t("lease-viewer-payment-method")
  }, __cssprop(PaymentMethodBadge, {
    value: paymentMethod
  })), __cssprop(GetFilesView, {
    files: files
  }), __cssprop(GetAmenities, {
    kind: kind,
    lease: lease
  }), __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "padding": "0.8rem",
      "paddingTop": "2.4rem"
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))",
      "borderRadius": "9999px",
      "overflow": "hidden"
    }
  }, __cssprop(StyledBar, {
    cancelled: lease.cancelled,
    style: {
      width: `${percentage}%`
    }
  }))), __cssprop("div", null, __cssprop("div", {
    css: {
      "display": "none",
      "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "display": "grid"
      }
    }
  }, __cssprop("div", null, __cssprop("div", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(79, 70, 229, var(--tw-text-opacity))"
    }
  }, t("contract-form-start-date")), __cssprop("div", null, DateTime.fromISO(lease.startDate).toUTC().toISODate())), __cssprop("div", {
    css: {
      "textAlign": "right"
    }
  }, __cssprop("div", null, t("contract-form-end-date")), __cssprop("div", null, DateTime.fromISO(lease.endDate).toUTC().toISODate()))))))));
};
export default LeaseViewer;