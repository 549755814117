import { StatusActionsTypes } from "./types.js";

const setResourceToSubmit = key => ({
  type: StatusActionsTypes.STATUS_SET_SUBMIT,
  payload: {
    type: key
  }
});

const setResourceToLoading = key => ({
  type: StatusActionsTypes.STATUS_SET_LOADING,
  payload: {
    type: key
  }
});

const setResourceToSuccess = (key, message) => ({
  type: StatusActionsTypes.STATUS_SET_SUCCESS,
  payload: {
    type: key,
    message
  }
});

const setResourceToError = (key, message) => ({
  type: StatusActionsTypes.STATUS_SET_ERROR,
  payload: {
    type: key,
    message
  }
});

const setResourceToIdle = key => ({
  type: StatusActionsTypes.STATUS_SET_IDLE,
  payload: {
    type: key
  }
});

const resetAll = () => ({
  type: StatusActionsTypes.RESET_ALL_STATUS,
  payload: null
});

export default {
  setResourceToSubmit,
  setResourceToLoading,
  setResourceToSuccess,
  setResourceToError,
  setResourceToIdle,
  resetAll
};