import React from "../../../../../../_snowpack/pkg/react.js";
import { FaCalendar, FaMoneyBillWave, FaSignature, FaUser } from "../../../../../../_snowpack/pkg/react-icons/fa.js";
import { ProtectedDiv } from "../../../../components/Tailwind/ProtectedComponents.js";
import { VisitAgentSelector } from "./visitAgentSelector.js";
import { VisitDate } from "./visitDateSelector.js";
import { VisitApprovedToggle } from "./visitAgentApprovedToggle.js";
import { SignatureDate } from "./signatureDate.js";
import { DownPayment } from "./DownPayment.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";
export const AgentVisitSection = props => {
  const {
    visitAgent,
    visitDate,
    agentApproved,
    rentalApplicationId,
    disabled,
    refetch,
    signatureDate,
    downPaymentAmount,
    totalDownPaymentAmount
  } = props;
  return __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaUser, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop(VisitAgentSelector, {
    disabled: disabled,
    refetch: refetch,
    rentalApplicationId: rentalApplicationId,
    visitAgent: visitAgent
  })), __cssprop(ProtectedDiv, {
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:visitDate"
    },
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaCalendar, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop(VisitDate, {
    disabled: disabled,
    refetch: refetch,
    rentalApplicationId: rentalApplicationId,
    visitDate: visitDate
  })), __cssprop(ProtectedDiv, {
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:sigDate"
    },
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaCalendar, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop(SignatureDate, {
    disabled: disabled,
    refetch: refetch,
    rentalApplicationId: rentalApplicationId,
    pSigDate: signatureDate
  })), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaMoneyBillWave, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop(DownPayment, {
    rentalApplicationId: rentalApplicationId,
    refetch: refetch,
    pDPM: downPaymentAmount,
    totalDownPaymentAmount: totalDownPaymentAmount,
    disabled: disabled
  })), __cssprop(ProtectedDiv, {
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:agentApproval"
    },
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaSignature, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop(VisitApprovedToggle, {
    agentApproved: agentApproved,
    disabled: disabled,
    refetch: refetch,
    rentalApplicationId: rentalApplicationId
  })));
};