import { FeatureType } from "../../../graphql/documents.js";
import { mapFiles } from "../../utils/Files.js";
import { mapNotes } from "../../utils/Notes.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { checkIfLeaseIsLocked } from "./util.js";
export const fromGetLease = payload => {
  const {
    lease
  } = payload;
  return { ...lease,
    startDate: DateTime.fromISO(lease.startDate).toISO(),
    endDate: DateTime.fromISO(lease.endDate).toISO(),
    isLocked: checkIfLeaseIsLocked(lease),
    unit: lease.unit,
    tenants: lease.tenants.items.map(({
      tenant,
      paymentMethod,
      isPayee
    }) => ({
      kind: "existing",
      id: tenant.id,
      email: tenant.email,
      firstName: tenant.firstName,
      lastName: tenant.lastName,
      phone: tenant.primaryPhone,
      isPayee,
      paymentMethod
    })),
    building: lease?.unit?.building,
    project: lease.project,
    notes: mapNotes(lease.notes.items),
    files: mapFiles(lease.files.items)
  };
};
export const fromCreateLease = payload => {
  const {
    id
  } = payload.createLease;
  return {
    id
  };
};
export const fromUpdateLease = payload => {
  const {
    id
  } = payload.updateLease;
  return {
    id
  };
};
export const toLeaseInputMapper = (lease, isRenewalleaseId) => {
  const shouldMarkDelivered = !!isRenewalleaseId || lease.deliveryFinalizedAt;
  return {
    amenities: lease.amenities.map(a => ({
      key: FeatureType[a.key] || a.key,
      value: a.value || true,
      price: a.price,
      pricingType: a.pricingType
    })),
    parentLeaseId: isRenewalleaseId || undefined,
    deliveryFinalizedAt: shouldMarkDelivered ? DateTime.utc() : null,
    documentsMarkedCompletedAt: shouldMarkDelivered ? DateTime.utc() : null,
    keysDeliveredAt: shouldMarkDelivered ? DateTime.utc() : null,
    basePrice: lease.monthlyPrice,
    billDay: lease.billDay,
    unitId: lease?.unit?.id || lease.building.id,
    startDate: DateTime.fromISO(lease.startDate),
    endDate: DateTime.fromISO(lease.endDate).plus({
      hour: 1
    }),
    tenants: lease.tenants.reduce((acc, t) => {
      if (t.kind === "existing") {
        acc.push({
          linkExistingTenant: t.id,
          isPayee: t.isPayee,
          paymentMethod: t.paymentMethod,
          paymentShare: t.isPayee ? 1 : 0
        });
        return acc;
      }

      acc.push({
        createNewTenant: {
          email: t.email,
          firstName: t.firstName,
          lastName: t.lastName,
          primaryPhone: t.phone
        },
        isPayee: t.isPayee,
        paymentMethod: t.paymentMethod,
        paymentShare: t.isPayee ? 1 : 0
      });
      return acc;
    }, []),
    downPaymentAmount: lease.downPaymentAmount
  };
};
export const toUpdateLeaseInputMapper = lease => {
  return {
    id: lease.id,
    lease: toLeaseInputMapper(lease)
  };
};
export const fromLeaseGqlToRenewal = lease => {
  return { ...lease,
    startDate: DateTime.fromISO(lease.endDate).plus({
      day: 1
    }),
    endDate: DateTime.fromISO(lease.endDate).plus({
      year: 1
    }),
    isLocked: checkIfLeaseIsLocked(lease),
    unit: lease.unit,
    tenants: lease.tenants.items.map(({
      tenant,
      paymentMethod,
      isPayee
    }) => ({
      kind: "existing",
      id: tenant.id,
      email: tenant.email,
      firstName: tenant.firstName,
      lastName: tenant.lastName,
      phone: tenant.primaryPhone,
      isPayee,
      paymentMethod
    })),
    building: lease?.unit?.building,
    project: lease.project,
    notes: mapNotes(lease.notes.items),
    files: mapFiles(lease.files.items)
  };
};