/* eslint-disable jsx-a11y/control-has-associated-label */
import { useLazyQuery, useMutation, useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { SearchIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { ApplicantScore } from "../../components/ApplicantScore.js";
import { RenderFileDropdown } from "../../components/FileDropdown.js";
import { Loader } from "../../components/Loader.js";
import { ApplicantPanel } from "../../components/Panels/ApplicantPanel.js";
import { Alert } from "../../components/Tailwind/Alert.js";
import { AmenitiesTagCloud } from "../../components/Tailwind/Amenities/AmenitiesTagCloud.js";
import { Badge } from "../../components/Tailwind/Badge.js";
import { Card } from "../../components/Tailwind/Card.js";
import { DropDownActions } from "../../components/Tailwind/DropDownActions.js";
import { EmptyState } from "../../components/Tailwind/EmptyState.js";
import { Link } from "../../components/Tailwind/Link.js";
import { Notes } from "../../components/Tailwind/Notes.js";
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedActions, ProtectedButton, ProtectedCard } from "../../components/Tailwind/ProtectedComponents.js";
import { SmallTenant } from "../../components/Tailwind/SmallTenant.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useConfirmModal from "../../forms/useConfirmModal.js";
import useFileModalViewer from "../../forms/useFileModalViewer.js";
import useFinalizeModal from "../../forms/useFinalizeModal.js";
import useSendToOwnerModal from "../../forms/useSendToOwnerModal.js";
import { AddApplicantToApplicationDocument, AddNoteDocument, ApplicantChosenDocument, ApplicantStatus, CancelRentalRequestV2Document, CandidateApplicationReportDocument, GetSingularRentalApplicationV2Document, InviteNewUserToApplicationDocument, PermissionType, ProjectManagerApprovedDocument, RemoveApplicantFromApplicationDocument, RemoveNoteDocument, RestrictApplicantFromApplyingDocument, ReviveRefusedApplicationDocument, SendToOwnerForApprovalDocument } from "../../graphql/documents.js";
import { getScoreText } from "../../helpers/functions.js";
import { RESOURCES } from "../../hooks/useFiles.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useEffect } from "../../../../_snowpack/pkg/react.js";
import { BiBuildingHouse } from "../../../../_snowpack/pkg/react-icons/bi.js";
import { FaCalendar, FaClock } from "../../../../_snowpack/pkg/react-icons/fa.js";
import { MdAttachMoney } from "../../../../_snowpack/pkg/react-icons/md.js";
import { toast } from "../../../../_snowpack/pkg/react-toastify.js";
import ReactTooltip from "../../../../_snowpack/pkg/react-tooltip.js";
import { AgentVisitSection } from "./components/agentVisitSection/index.js";
import FileDropper from "./components/fileDropper.js";
import RankingList from "./components/rankingList.js";
import { RentalApplicationCurrentLease } from "./currentLease.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const mapNotes = notes => {
  return notes.map(note => {
    return { ...note,
      kind: "remote",
      content: note.content,
      createdAt: note.createdAt,
      id: note.id,
      createdBy: note.createdBy
    };
  });
};

const renderAddress = (history, item) => {
  return __cssprop(Link, {
    action: {
      kind: "ClickAction",
      event: () => {
        history.push(`/unit/${item.id}`);
      }
    },
    text: `${item.subAddress ? `${item.subAddress}-` : ""}${item?.buildingAddress}`
  });
};

const transformRefusalKey = key => {
  switch (key) {
    default:
    case "other":
      return "applicant-request-option-other";

    case "bad_credit":
      return "applicant-request-option-bad-credit-score";

    case "visit_refused":
      return "applicant-request-option-refusal-of-visit";

    case "owner_refused":
      return "applicant-request-option-refusal-of-owner";

    case "listing_no_longer_available":
      return "applicant-request-option-listing-no-longer-available";

    case "pass_restricted":
      return "applicant-request-option-refusal-of-pass-restricted";
  }
};

const transformConsentKey = key => {
  switch (key) {
    default:
    case "maximum_number_of_pets":
      return "application-consent-maximum_number_of_pets";

    case "maximum_number_of_tenants":
      return "application-consent-maximum_number_of_tenants";

    case "minimum_salary":
      return "application-consent-minimum_salary";

    case "parking_spots_included_in_rent":
      return "application-consent-parking_spots_included_in_rent";

    case "terms_of_services":
      return "application-consent-terms_of_services";

    case "credit_check":
      return "application-consent-credit_check";

    case "is_non_smoking":
      return "application-consent-is_non_smoking";
  }
};

const approvedOwnerButtonText = (t, landlordApprovedAt, sentToLandlordAt) => {
  if (landlordApprovedAt) return t("applicant-request-list-owner-approved");
  if (sentToLandlordAt) return t("applicant-request-form-section-button-resend-to-owner");
  return t("applicant-request-form-section-button-approve-candidate-by-owner");
};

const approvedPMButtonText = (t, propertyManagerApprovedAt) => {
  if (propertyManagerApprovedAt) {
    return t("applicant-request-list-intern-approved");
  }

  return t("applicant-request-form-section-button-approve-candidate");
};

const chooseApplicantButtonText = (t, chosenAt) => {
  if (chosenAt) return t("applicant-request-button-has-been-choosen");
  return t("applicant-request-button-choose-applicant");
};

const Indicator = ({
  children,
  content
}) => {
  return __cssprop("div", {
    css: {
      "position": "relative",
      "display": "inline-block"
    }
  }, children, __cssprop("span", {
    css: {
      "--tw-translate-x": "50%",
      "--tw-translate-y": "-50%",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "paddingTop": "0.4rem",
      "paddingBottom": "0.4rem",
      "fontSize": "1.2rem",
      "lineHeight": "1",
      "fontWeight": "700",
      "--tw-text-opacity": "1",
      "color": "rgba(254, 226, 226, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(220, 38, 38, var(--tw-bg-opacity))",
      "borderRadius": "9999px"
    }
  }, content));
};

const getBadgeFromStatus = (t, status) => {
  let applicantStatusBadge;

  switch (status) {
    case ApplicantStatus.IsManuallyAdded:
      applicantStatusBadge = __cssprop("td", {
        css: {
          "whiteSpace": "nowrap",
          "paddingTop": "1.6rem",
          "paddingBottom": "1.6rem",
          "paddingLeft": "1.2rem",
          "paddingRight": "1.2rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
        }
      }, __cssprop(Badge, null, t("applicant-status-manually-added")));
      break;

    case ApplicantStatus.CreditCheckComplete:
      applicantStatusBadge = __cssprop("td", {
        css: {
          "whiteSpace": "nowrap",
          "paddingTop": "1.6rem",
          "paddingBottom": "1.6rem",
          "paddingLeft": "1.2rem",
          "paddingRight": "1.2rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
        }
      }, __cssprop(Badge, {
        success: true
      }, t("applicant-status-completed")));
      break;

    case ApplicantStatus.CreditCheckPendingResult:
      applicantStatusBadge = __cssprop("td", {
        css: {
          "whiteSpace": "nowrap",
          "paddingTop": "1.6rem",
          "paddingBottom": "1.6rem",
          "paddingLeft": "1.2rem",
          "paddingRight": "1.2rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
        }
      }, __cssprop(Badge, {
        purple: true
      }, t("applicant-status-pending-credit-check")));
      break;

    case ApplicantStatus.PaymentPending:
      applicantStatusBadge = __cssprop("td", {
        css: {
          "whiteSpace": "nowrap",
          "paddingTop": "1.6rem",
          "paddingBottom": "1.6rem",
          "paddingLeft": "1.2rem",
          "paddingRight": "1.2rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
        }
      }, __cssprop(Badge, {
        warning: true
      }, t("applicant-status-payment-pending")));
      break;

    case ApplicantStatus.PaymentSucceededAndCreditCheckUnsent:
      applicantStatusBadge = __cssprop("td", {
        css: {
          "whiteSpace": "nowrap",
          "paddingTop": "1.6rem",
          "paddingBottom": "1.6rem",
          "paddingLeft": "1.2rem",
          "paddingRight": "1.2rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
        }
      }, __cssprop(Badge, {
        warning: true
      }, t("applicant-status-payment-succeeded-credit-check-unsent")));
      break;

    default:
      applicantStatusBadge = __cssprop("td", {
        css: {
          "whiteSpace": "nowrap",
          "paddingTop": "1.6rem",
          "paddingBottom": "1.6rem",
          "paddingLeft": "1.2rem",
          "paddingRight": "1.2rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
        }
      }, __cssprop(Badge, {
        critical: true
      }, t("applicant-status-error")));
  }

  return applicantStatusBadge;
};

export const ApplicantRequestFormV2 = withBreadcrumb(({
  history,
  setCrumb,
  match,
  crumbs
}) => {
  const role = useSelector(Selectors.session.getUserRole);
  const {
    id
  } = match.params;
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const applicantShowSmallIconEnabled = true;
  const [getApplicantReportQuery] = useLazyQuery(CandidateApplicationReportDocument);
  const {
    data,
    loading,
    refetch
  } = useQuery(GetSingularRentalApplicationV2Document, {
    variables: {
      id
    },
    fetchPolicy: "no-cache"
  });
  const [cancelRequest, {
    loading: cancelLoading
  }] = useMutation(CancelRentalRequestV2Document, {
    fetchPolicy: "no-cache"
  });
  const [pmApprove, {
    loading: pmApproveLoading
  }] = useMutation(ProjectManagerApprovedDocument, {
    fetchPolicy: "no-cache"
  });
  const [chooseApplicant, {
    loading: chooseApplicantLoading
  }] = useMutation(ApplicantChosenDocument, {
    fetchPolicy: "no-cache"
  });
  const [inviteNewUser, {
    loading: inviteNewUserLoading
  }] = useMutation(InviteNewUserToApplicationDocument, {
    fetchPolicy: "no-cache"
  });
  const [sendToOwnerForApproval, {
    loading: sendToOwnerForApprovalLoading
  }] = useMutation(SendToOwnerForApprovalDocument, {
    fetchPolicy: "no-cache"
  });
  const [removeApplicantFromApplication, {
    loading: removeApplicantFromApplicationLoading
  }] = useMutation(RemoveApplicantFromApplicationDocument, {
    fetchPolicy: "no-cache"
  });
  const [addApplicantFromApplication, {
    loading: addApplicantFromApplicationLoading
  }] = useMutation(AddApplicantToApplicationDocument, {
    fetchPolicy: "no-cache"
  });
  const [addNote, {
    loading: addNoteLoading
  }] = useMutation(AddNoteDocument, {
    fetchPolicy: "no-cache"
  });
  const [removeNote, {
    loading: removeNoteLoading
  }] = useMutation(RemoveNoteDocument, {
    fetchPolicy: "no-cache"
  });
  const [reopenApplication, {
    loading: reopenApplicationLoading
  }] = useMutation(ReviveRefusedApplicationDocument, {
    fetchPolicy: "no-cache"
  });
  const [restrictApplicant] = useMutation(RestrictApplicantFromApplyingDocument, {
    fetchPolicy: "no-cache"
  });

  const handleOnRevive = async () => {
    try {
      const reviveResponse = await reopenApplication({
        variables: {
          input: {
            rentalApplicationId: id
          }
        }
      });

      if (reviveResponse.errors) {
        toast.warn(`${t("generic-fetch-error-message")}`);
      }

      if (reviveResponse && reviveResponse.data) {
        refetch();
      }
    } catch (e) {
      console.error("ReviveSection", e);
      toast.warn(`${t("generic-fetch-error-message")}`);
    }
  };

  const [ConfirmModal, openConfirmModal] = useConfirmModal(t("applicant-request-cancel-reason"), "refuseApplicant", async (result, _, choice) => {
    if (result) {
      const response = await cancelRequest({
        variables: {
          input: {
            rentalApplicationId: id,
            reason: choice.selected,
            reason_text: choice.textFieldValue,
            blockeeList: []
          }
        }
      });

      if (response) {
        refetch();
      }
    }
  }, true); // send files to confirm modal

  const [ConfirmOwnerApprovalModal, openConfirmOwnerApprovalModal] = useSendToOwnerModal(data?.rentalApplicationV2.applicants.filter(a => a.applicationPass && a.applicationPass.files).map(a => a.applicationPass?.files).flat().concat(data?.rentalApplicationV2.files.items || []) || [], data?.rentalApplicationV2.isInOwnerApprovalQueue || false, async (result, choice) => {
    if (result) {
      const response = await sendToOwnerForApproval({
        variables: {
          input: {
            rentalApplicationId: id,
            customMessage: choice.textFieldValue || null,
            forceOwnerApproval: choice.forceOwner,
            fileIds: choice.filesSelected,
            bypassQueue: choice.bypassQueue
          }
        }
      });

      if (response && response.data) {
        refetch();
      }
    }
  });

  const handlePmApprove = async () => {
    const response = await pmApprove({
      variables: {
        input: {
          rentalApplicationId: id
        }
      }
    });

    if (response && response.data) {
      refetch();
    }
  };

  const handleChooseApplicant = async () => {
    const response = await chooseApplicant({
      variables: {
        rentalApplicationId: id
      }
    });

    if (response && response.data) {
      refetch();
    }
  };

  const [DeleteUserFromApplication, openDeleteUserFromApplication] = useConfirmModal(t("applicant-confirm-delete-modal"), "deleteApplicant", async (result, _values, choice) => {
    if (result) {
      try {
        const response = await removeApplicantFromApplication({
          variables: {
            input: {
              rentalApplicationId: id,
              applicantId: _values
            }
          }
        });

        if (response.errors) {
          if (JSON.stringify(response.errors).includes("last")) toast.warn(t("applicant-request-form-section-delete-last"));
          toast.error(t("user-form-saved-fail"));
        }

        if (response && response.data) {
          refetch();
        }
      } catch (e) {
        if (JSON.stringify(e).includes("last")) toast.warn(t("applicant-request-form-section-delete-last"));
        toast.warn(t("user-form-saved-fail"));
      }
    }
  });
  const [InviteNewApplicant, openInviteNewApplicant] = useConfirmModal(t("applicant-confirm-invite-with-email"), "inviteApplicant", async (result, _values, choice) => {
    if (result) {
      const response = await inviteNewUser({
        variables: {
          input: {
            rentalApplicationId: id,
            email: choice.textFieldValue
          }
        }
      });

      if (response && response.data) {
        refetch();
      }
    }
  });
  const [FinalizeModal, openFinalizeModal] = useFinalizeModal(t("applicant-request-form-section-finalize-description"), id);
  const [ViewerModal, openViewerModal] = useFileModalViewer();

  const handleRestrictApplicant = async applicantId => {
    try {
      await restrictApplicant({
        variables: {
          input: {
            applicantId,
            rentalApplicationId: id
          }
        }
      });
      refetch();
    } catch {
      toast.error(t("user-form-saved-fail"));
    }
  };

  const getProtectedAction = tenant => {
    if (tenant.applicationPass) {
      return [ProtectedActions(role, [{
        content: t("delete"),
        onAction: () => {
          openDeleteUserFromApplication(tenant.id);
        },
        destructive: true,
        permission: {
          kind: "ApplicantRequest",
          permission: "mutate:delete"
        }
      }, {
        content: "Restrict user from applying again",
        onAction: () => {
          handleRestrictApplicant(tenant.id);
        },
        destructive: true,
        permission: {
          kind: "ApplicantRequest",
          permission: "mutate:delete"
        }
      }])];
    }

    return [ProtectedActions(role, [{
      content: t("delete"),
      onAction: () => {
        openDeleteUserFromApplication(tenant.id);
      },
      destructive: true,
      permission: {
        kind: "ApplicantRequest",
        permission: "mutate:delete"
      }
    }])];
  };

  useEffect(() => {
    setCrumb({
      title: t("applicant-request-title"),
      path: `/applicant/v2`,
      level: 3
    });
  }, []);

  if (loading) {
    return __cssprop(Page, {
      title: t("applicant-request-title"),
      breadcrumbs: crumbs
    }, __cssprop(Loader, {
      fullscreen: true
    }));
  }

  if (!data?.rentalApplicationV2) {
    return __cssprop(Page, {
      title: t("applicant-request-title"),
      breadcrumbs: crumbs
    }, __cssprop(EmptyState, {
      image: "/img/notFound.svg",
      heading: "La page ou la ressource que vous cherchez est introuvable"
    }));
  }

  const headerActions = [// TODO: Removing until we rework the invite system. Currently invites require a host id that belongs to an applicant. PMs and Landlords are not going to be applicants...
  // ProtectedActions(role, [
  //   {
  //     content: "Add with email invite",
  //     onAction: () => {
  //       openInviteNewApplicant();
  //     },
  //     permission: { kind: "ApplicantRequest", permission: "mutate:create" },
  //   },
  // ]),
  ProtectedActions(role, [{
    content: "Add manually",
    destructive: true,
    onAction: () => {
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: "applicant"
      });
    },
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:create"
    }
  }])];

  const onDoneSubmit = async slideOverData => {
    // https://github.com/apollographql/apollo-client/issues/5708 known issue in apollo client not being able to handle mutation errors
    try {
      let linkExistingUser;
      let newApplicant;

      if ("id" in slideOverData) {
        linkExistingUser = slideOverData.id;
      } else if ("firstName" in slideOverData) {
        newApplicant = slideOverData;
      }

      const response = await addApplicantFromApplication({
        variables: {
          input: {
            rentalApplicationId: id,
            linkExistingUser,
            createNewApplicant: newApplicant ? {
              firstName: newApplicant.firstName,
              lastName: newApplicant.lastName,
              email: newApplicant.email,
              phoneInfo: {
                primaryPhone: newApplicant.primaryPhone,
                secondaryPhone: newApplicant.secondaryPhone,
                phoneIsMobile: !!newApplicant.primaryChecked,
                secondaryPhoneIsMobile: !!newApplicant.secondaryChecked
              },
              language: newApplicant.language,
              notificationEnabled: !!newApplicant.notificationEnabled,
              candidateDOB: newApplicant.candidateDOB
            } : undefined
          }
        }
      }); // refresh applicant page if success

      if (response?.errors?.length !== 0) {
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: null
        });
        refetch();
        toast.success(t("applicant-request-form-add-applicant-success"));
      }

      if (response.errors) {
        if (JSON.stringify(response.errors).includes("unique")) toast.warn(t("applicant-request-form-section-unique-constraint"));
        toast.error(t("user-form-saved-fail"));
      }
    } catch (e) {
      if (JSON.stringify(e).includes("unique")) toast.warn(t("applicant-request-form-section-unique-constraint"));
      toast.warn(t("user-form-saved-fail"));
    }
  }; // will return TRUE if the requirement are met


  const checkFinalizedRequirement = ({
    files,
    landlordApprovedAt,
    landlordRejectedAt,
    propertyManagerApprovedAt,
    propertyManagerRejectedAt
  }) => {
    const hasLeaseFile = files.items.find(f => f.fileCategory === "lease");
    if (landlordRejectedAt) return false;
    if (propertyManagerRejectedAt) return false;
    if (!hasLeaseFile) return false;
    if (landlordApprovedAt && propertyManagerApprovedAt && hasLeaseFile) return true;
    return false;
  };

  const getApplicantReport = async candidateId => {
    const {
      data: {
        candidateApplicationReport
      }
    } = await getApplicantReportQuery({
      variables: {
        candidateId,
        rentalApplicationId: id
      }
    });
    const sanitize = candidateApplicationReport.replaceAll('"', '"').replaceAll("\n", "");
    const wnd = window.open("about:blank", "", "_blank");
    wnd.document.write(sanitize);
  };

  const {
    unit,
    applicants,
    inviteCode,
    createdAt,
    requestedEntryDate,
    landlordApprovedAt,
    landlordRejectedAt,
    propertyManagerApprovedAt,
    completedAt,
    rejected,
    visitAgent,
    visitDate,
    visitAgentApprovedAt,
    sentToLandlordAt,
    notes,
    listing,
    signatureDate,
    visitAgentAssignedAt,
    downPaymentAmount,
    totalDownPaymentAmount,
    chosenAt
  } = data?.rentalApplicationV2;
  return __cssprop(React.Fragment, null, __cssprop(ApplicantPanel, {
    formTitle: t("applicant-request-add-applicant"),
    description: "",
    sectionTitle: "",
    onDoneSubmit: onDoneSubmit
  }), __cssprop(ViewerModal, null), __cssprop(Page, {
    thumbnail: listing.thumbnail.url,
    title: `${unit.subAddress ? `${unit.subAddress}-` : ""}${unit?.buildingAddress}`,
    titleMetadata: __cssprop(Link, {
      text: id,
      action: {
        kind: "InternalLink",
        to: `/applicant/v2/${id}`
      }
    }),
    primaryAction: !!rejected || !!completedAt ? __cssprop("div", null, __cssprop(ProtectedButton, {
      permission: {
        kind: "ApplicantRequest",
        permission: "mutate:update"
      },
      secondary: true,
      disabled: !!completedAt,
      loading: !!cancelLoading,
      onAction: () => handleOnRevive()
    }, t("reopen"))) : __cssprop("div", null, __cssprop(ProtectedButton, {
      permission: {
        kind: "ApplicantRequest",
        permission: "mutate:update"
      },
      grouped: true,
      disabled: propertyManagerApprovedAt,
      loading: !!pmApproveLoading,
      onAction: () => handlePmApprove()
    }, approvedPMButtonText(t, propertyManagerApprovedAt)), __cssprop(ProtectedButton, {
      permission: {
        kind: "ApplicantRequest",
        permission: "mutate:update"
      },
      grouped: true,
      disabled: landlordApprovedAt || !propertyManagerApprovedAt,
      loading: !!sendToOwnerForApprovalLoading,
      onAction: () => openConfirmOwnerApprovalModal()
    }, approvedOwnerButtonText(t, landlordApprovedAt, sentToLandlordAt)), __cssprop(ProtectedButton, {
      permission: {
        kind: "ApplicantRequest",
        permission: "mutate:update"
      },
      grouped: true,
      disabled: chosenAt,
      loading: !!chooseApplicantLoading,
      onAction: () => handleChooseApplicant()
    }, chooseApplicantButtonText(t, chosenAt)), __cssprop(ProtectedButton, {
      permission: {
        kind: "ApplicantRequest",
        permission: "mutate:update"
      },
      primary: true,
      onAction: () => openFinalizeModal(applicants),
      disabled: !checkFinalizedRequirement({ ...data.rentalApplicationV2
      })
    }, t("applicant-request-form-section-finalize-request")), __cssprop(ProtectedButton, {
      permission: {
        kind: "ApplicantRequest",
        permission: "mutate:update"
      },
      grouped: true,
      destructive: true,
      loading: !!cancelLoading,
      onAction: () => openConfirmModal()
    }, t("refuse"))),
    breadcrumbs: crumbs
  }, __cssprop(ConfirmModal, null), __cssprop(ConfirmOwnerApprovalModal, null), __cssprop(FinalizeModal, null), __cssprop(InviteNewApplicant, null), __cssprop(DeleteUserFromApplication, null), __cssprop("main", {
    css: {
      "flex": "1 1 0%",
      "paddingTop": "2.4rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, rejected && __cssprop(Alert, {
    type: "critical",
    title: `${t("applicant-request-refused-on")} ${DateTime.fromISO(rejected.at).toISODate()}`,
    description: rejected.rejection === "other" ? [`${t(transformRefusalKey(rejected.rejection))}: ${rejected.reason}`] : [t(transformRefusalKey(rejected.rejection))]
  }), completedAt && __cssprop(Alert, {
    type: "info",
    title: `${t("applicant-request-completed-on")} ${DateTime.fromISO(completedAt).toISODate()}`
  }), __cssprop("div", null, __cssprop("div", {
    css: {
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 1024px)": {
        "paddingRight": "3.2rem"
      },
      "@media (min-width: 1280px)": {
        "display": "grid",
        "gridTemplateColumns": "repeat(3, minmax(0, 1fr))"
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 1280px)": {
        "gridColumn": "span 2 / span 2",
        "paddingRight": "3.2rem",
        "borderRightWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
      }
    }
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop("div", {
    css: {
      "@media (min-width: 1280px)": {
        "paddingBottom": "0px"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column"
    }
  }, __cssprop("div", {
    css: {
      "marginTop": "-0.8rem",
      "marginBottom": "-0.8rem",
      "marginLeft": "-1.6rem",
      "marginRight": "-1.6rem",
      "overflowX": "auto",
      "@media (min-width: 640px)": {
        "marginLeft": "-2.4rem",
        "marginRight": "-2.4rem"
      },
      "@media (min-width: 1024px)": {
        "marginLeft": "-3.2rem",
        "marginRight": "-3.2rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "inline-block",
      "minWidth": "100%",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "verticalAlign": "middle",
      "@media (min-width: 768px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 1024px)": {
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem"
      }
    }
  }, role !== PermissionType.VisitAgent ? __cssprop("table", {
    css: {
      "minWidth": "100%",
      "tableLayout": "auto",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("thead", null, __cssprop("tr", null, __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("tenant-tab-candidate")), applicantShowSmallIconEnabled ? __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }) : __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("file-section-title")), __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("score")), __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("status")), !completedAt && !rejected && __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "textAlign": "right",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, __cssprop("div", {
    css: {}
  }, __cssprop(DropDownActions, {
    content: "Add",
    actions: headerActions
  }))))), __cssprop("tbody", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, applicants.map(tenant => {
    const {
      files = [],
      slScore = 0
    } = tenant?.applicationPass || {};
    const applicantStatusBadge = getBadgeFromStatus(t, tenant.status);
    const scoreText = getScoreText(slScore, t);
    return __cssprop("tr", {
      key: tenant.id
    }, __cssprop("td", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "1.6rem",
        "paddingBottom": "1.6rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, __cssprop(SmallTenant, {
      tenant: tenant
    })), __cssprop("td", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "1.6rem",
        "paddingBottom": "1.6rem",
        "paddingLeft": "1.2rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(2rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(2rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }, __cssprop("dd", {
      css: {
        "marginTop": "0.4rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "gridColumn": "span 2 / span 2",
          "marginTop": "0px"
        }
      }
    }, files.length !== 0 && __cssprop(RenderFileDropdown, {
      files: files
    })), applicantShowSmallIconEnabled && __cssprop("dd", {
      css: {
        "marginTop": "0.4rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "gridColumn": "span 2 / span 2",
          "marginTop": "0px"
        }
      }
    }, tenant.addressHistory?.length > 0 && __cssprop(DropDownActions, {
      content: "files",
      actions: tenant.addressHistory.map(address => {
        return ProtectedActions(role, [{
          content: __cssprop("div", {
            css: {
              "display": "flex",
              "flexDirection": "column",
              "> :not([hidden]) ~ :not([hidden])": {
                "--tw-space-y-reverse": 0,
                "marginTop": "calc(0.8rem * calc(1 - var(--tw-space-y-reverse)))",
                "marginBottom": "calc(0.8rem * var(--tw-space-y-reverse))"
              }
            }
          }, __cssprop(Badge, null, t(address.landlordName ? "tenant" : "user-role-section-owner")), __cssprop("div", {
            css: {
              "fontWeight": "700"
            }
          }, `${address.addressLineOne}${address.addressLineTwo ? ` (${address.addressLineTwo})` : ""}`), address.landlordName && address.landlordPhone && __cssprop("div", null, address.landlordName, " (", address.landlordPhone, ")")),
          onAction: () => {// emtpy
          },
          permission: {
            kind: "ApplicantRequest",
            permission: "mutate:create"
          }
        }]);
      })
    }, __cssprop("span", {
      css: {
        "position": "relative",
        "display": "inline-block"
      }
    }, __cssprop(BiBuildingHouse, {
      css: {
        "width": "2.4rem",
        "height": "2.4rem",
        "--tw-text-opacity": "1",
        "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
        "fill": "currentColor"
      }
    }), __cssprop("span", {
      css: {
        "--tw-translate-x": "50%",
        "--tw-translate-y": "-50%",
        "--tw-rotate": "0",
        "--tw-skew-x": "0",
        "--tw-skew-y": "0",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
        "position": "absolute",
        "top": "0px",
        "right": "0px",
        "display": "inline-flex",
        "alignItems": "center",
        "justifyContent": "center",
        "paddingLeft": "0.8rem",
        "paddingRight": "0.8rem",
        "paddingTop": "0.4rem",
        "paddingBottom": "0.4rem",
        "fontSize": "1.2rem",
        "lineHeight": "1",
        "fontWeight": "700",
        "--tw-text-opacity": "1",
        "color": "rgba(254, 226, 226, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(220, 38, 38, var(--tw-bg-opacity))",
        "borderRadius": "9999px"
      }
    }, tenant.addressHistory.length))))), __cssprop("td", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "1.6rem",
        "paddingBottom": "1.6rem",
        "paddingLeft": "1.2rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "flexDirection": "row",
        "gap": "1.6rem",
        "alignItems": "center"
      }
    }, __cssprop(ApplicantScore, {
      css: {
        "marginTop": "0.4rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "700",
        "@media (min-width: 640px)": {
          "gridColumn": "span 2 / span 2",
          "marginTop": "0px"
        }
      },
      score: Number.isNaN(Number(slScore)) ? 0 : Number(slScore)
    }, scoreText), scoreText !== "NA" && __cssprop(SearchIcon, {
      css: {
        "width": "2.4rem",
        "height": "2.4rem",
        "--tw-text-opacity": "1",
        "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
        "cursor": "pointer"
      },
      onClick: () => getApplicantReport(tenant.id)
    }))), applicantStatusBadge, !completedAt && !rejected && __cssprop("td", {
      css: {
        "whiteSpace": "nowrap",
        "textAlign": "right",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "@media (min-width: 640px)": {
          "paddingRight": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingRight": "0px"
        }
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "justifyContent": "flex-end"
      }
    }, __cssprop(DropDownActions, {
      content: "Options",
      dots: true,
      actions: getProtectedAction(tenant)
    }))));
  }))) : __cssprop("table", {
    css: {
      "minWidth": "100%",
      "tableLayout": "auto",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("thead", null, __cssprop("tr", null, __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("tenant-tab-candidate")), __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("email")), __cssprop("th", {
    scope: "col",
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("phone")))), __cssprop("tbody", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, applicants.map(tenant => {
    return __cssprop("tr", {
      key: tenant.id
    }, __cssprop("td", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "1.6rem",
        "paddingBottom": "1.6rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, __cssprop(SmallTenant, {
      tenant: tenant
    })), __cssprop("td", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "1.6rem",
        "paddingBottom": "1.6rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, tenant.email), __cssprop("td", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "1.6rem",
        "paddingBottom": "1.6rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, tenant.primaryPhone));
  }))))))))), __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(FileDropper, {
    refetch: refetch,
    resourceId: data.rentalApplicationV2.id,
    resourceType: RESOURCES.RentalRequestV2,
    files: data.rentalApplicationV2.files.items,
    readOnly: !!rejected || !!completedAt
  }))), __cssprop(ProtectedCard, {
    permission: {
      kind: "ApplicantRequest",
      permission: "view:allOtherApplication"
    }
  }, __cssprop(Card.Section, null, __cssprop(RankingList, {
    refetch: refetch,
    ownApplicationId: data.rentalApplicationV2.id,
    applications: listing.rentalRequests.items,
    readOnly: !!rejected || !!completedAt
  })))), __cssprop("aside", {
    css: {
      "@media (min-width: 1280px)": {
        "paddingLeft": "3.2rem"
      }
    }
  }, __cssprop("h2", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Details"), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(ListingStepper, data.rentalApplicationV2)), __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaClock, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, t("received"), ": ", __cssprop("time", {
    dateTime: "2020-12-02"
  }, DateTime.fromISO(createdAt).toISODate()))), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaCalendar, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, t("applicant-entry-date-requested"), " :", " ", __cssprop("time", {
    dateTime: "2020-12-02"
  }, DateTime.fromISO(requestedEntryDate).toUTC().toISODate()))), sentToLandlordAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaCalendar, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, t("applicant-sent-to-landlord-date"), " :", " ", __cssprop("time", {
    dateTime: "2020-12-02"
  }, DateTime.fromISO(sentToLandlordAt).toUTC().toISODate()))), landlordApprovedAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaCalendar, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(52, 211, 153, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(6, 78, 59, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "700"
    }
  }, t("applicant-landlord-approved-date"), " :", " ", __cssprop("time", {
    dateTime: "2020-12-02"
  }, DateTime.fromISO(landlordApprovedAt).toISODate()))), landlordRejectedAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaCalendar, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(248, 113, 113, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(127, 29, 29, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "700"
    }
  }, t("applicant-landlord-rejected-date"), " :", " ", __cssprop("time", {
    dateTime: "2020-12-02"
  }, DateTime.fromISO(landlordRejectedAt).toISODate()))), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(MdAttachMoney, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, listing.price, "$ /m")), visitAgentAssignedAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(FaCalendar, {
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, t("applicant-agent-assigned-date"), " : ", __cssprop("time", {
    dateTime: "2020-12-02"
  }, DateTime.fromISO(visitAgentAssignedAt).toISODate())))), (propertyManagerApprovedAt || !!rejected || !!completedAt) && __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(AgentVisitSection, {
    downPaymentAmount: downPaymentAmount,
    totalDownPaymentAmount: totalDownPaymentAmount,
    visitAgent: visitAgent,
    visitDate: visitDate,
    agentApproved: !!visitAgentApprovedAt,
    rentalApplicationId: id,
    disabled: !!completedAt || !!rejected,
    refetch: refetch,
    signatureDate: signatureDate
  })), __cssprop("div", {
    css: {
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(3.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(3.2rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(RentalApplicationCurrentLease, {
    id: id
  })), __cssprop("div", {
    css: {
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(3.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(3.2rem * var(--tw-space-y-reverse))"
      }
    }
  }, inviteCode && __cssprop("div", null, __cssprop("div", null, __cssprop("h2", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("applicant-invite-code")), __cssprop("ul", {
    css: {
      "marginTop": "1.2rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.2rem * var(--tw-space-y-reverse))"
      },
      "fontWeight": "700",
      "fontSize": "1.8rem",
      "lineHeight": "2.8rem"
    },
    "data-tip": t("applicant-invite-code-tooltip")
  }, __cssprop(Link, {
    css: {
      "display": "flex",
      "justifyContent": "flex-start"
    },
    action: {
      kind: "ClickAction",
      event: () => {
        const inviteLink = `https://simpleloyer.ca/apply/${listing.id}?inviteCode=${inviteCode}`;
        navigator.clipboard.writeText(inviteLink);
      }
    },
    text: inviteCode
  }))), __cssprop(ReactTooltip, {
    backgroundColor: "#6366f1",
    type: "info"
  })), __cssprop("div", null, __cssprop("h2", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("building-form-address")), __cssprop("ul", {
    css: {
      "marginTop": "1.2rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.2rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("li", {
    css: {
      "display": "flex",
      "justifyContent": "flex-start"
    }
  }, renderAddress(history, unit)))), __cssprop("div", null, __cssprop("h2", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("user-role-section-owner")), __cssprop("ul", {
    css: {
      "marginTop": "1.2rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.2rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("li", {
    css: {
      "display": "flex",
      "justifyContent": "flex-start"
    }
  }, __cssprop(Link, {
    action: {
      kind: "ClickAction",
      event: () => {
        history.push(`/clients/${unit.building.project.id}`);
      }
    },
    text: unit.building.project.name
  })))), listing.consents.length !== 0 && __cssprop("div", null, __cssprop("h2", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("consents")), __cssprop("ul", {
    css: {
      "marginTop": "0.8rem",
      "lineHeight": "3.2rem"
    }
  }, listing.consents.map(c => {
    return __cssprop("li", {
      css: {
        "display": "inline"
      }
    }, __cssprop("div", {
      css: {
        "position": "relative",
        "display": "inline-flex",
        "alignItems": "center",
        "borderRadius": "9999px",
        "borderWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
        "paddingLeft": "1.2rem",
        "paddingRight": "1.2rem",
        "paddingTop": "0.2rem",
        "paddingBottom": "0.2rem"
      }
    }, __cssprop("div", {
      css: {
        "position": "absolute",
        "flexShrink": "0",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center"
      }
    }, __cssprop("span", {
      css: {
        "height": "0.6rem",
        "width": "0.6rem",
        "borderRadius": "9999px",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(239, 68, 68, var(--tw-bg-opacity))"
      },
      "aria-hidden": "true"
    })), c.consentValue === "required" ? __cssprop("div", {
      css: {
        "marginLeft": "1.4rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      }
    }, t(transformConsentKey(c.consentType))) : __cssprop("div", {
      css: {
        "marginLeft": "1.4rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      }
    }, t(transformConsentKey(c.consentType)), ": ", c.consentValue)), " ");
  }))), listing.amenities.length !== 0 && __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(AmenitiesTagCloud, {
    title: t("amenities-title"),
    list: listing.amenities
  })), __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(Notes, {
    notes: mapNotes(notes.items),
    loading: addNoteLoading,
    permission: {
      kind: "ApplicantRequest",
      permission: "view:notes"
    },
    addNoteEvent: pNotes => {
      addNote({
        variables: {
          note: {
            content: pNotes.note
          },
          resourceType: "rentalRequestV2",
          resourceId: data.rentalApplicationV2.id
        }
      });
      refetch();
    },
    hideNewNote: role === "Client" || role === "User" || !!rejected || !!completedAt,
    isViewOnly: !!rejected || !!completedAt,
    removeNoteEvent: pNotes => {
      removeNote({
        variables: {
          noteId: pNotes.id
        }
      });
      refetch();
    }
  })))))))));
});

const ListingStepper = ({
  propertyManagerApprovedAt,
  propertyManagerRejectedAt,
  visitAgentApprovedAt,
  landlordApprovedAt,
  landlordRejectedAt,
  files,
  chosenAt
}) => {
  const {
    t
  } = useI18n();

  const getApprovalStatus = (approvedAt, rejectedAt) => {
    if (!approvedAt && !rejectedAt) return "neutral";
    if (rejectedAt) return "warning";
    if (approvedAt) return "good";
    return "neutral";
  };

  const steps = [{
    name: t("applicantion-step-pm-accepted"),
    status: getApprovalStatus(propertyManagerApprovedAt, propertyManagerRejectedAt)
  }, {
    name: t("applicantion-step-visit-accepted"),
    status: visitAgentApprovedAt ? "good" : "neutral"
  }, {
    name: t("applicantion-step-owner-accepted"),
    status: getApprovalStatus(landlordApprovedAt, landlordRejectedAt)
  }, {
    name: t("applicant-request-status"),
    status: chosenAt ? "good" : "neutral"
  }, {
    name: t("applicantion-step-documents-uploaded"),
    status: files.items.find(f => f.fileCategory === "lease") ? "good" : "neutral"
  }];
  return __cssprop("div", null, __cssprop("nav", {
    "aria-label": "Progress"
  }, __cssprop("ol", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.8rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.8rem * var(--tw-space-y-reverse))"
      }
    }
  }, steps.map((step, key) => {
    if (step.status === "good") {
      return __cssprop("li", {
        key: key
      }, __cssprop("div", {
        className: "group"
      }, __cssprop("span", {
        css: {
          "display": "flex",
          "alignItems": "flex-start"
        }
      }, __cssprop("span", {
        css: {
          "flexShrink": "0",
          "height": "2rem",
          "width": "2rem",
          "position": "relative",
          "display": "flex",
          "alignItems": "center",
          "justifyContent": "center"
        },
        "aria-hidden": "true"
      }, __cssprop("span", {
        css: {
          "position": "absolute",
          "height": "1.6rem",
          "width": "1.6rem",
          "borderRadius": "9999px",
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(167, 243, 208, var(--tw-bg-opacity))"
        }
      }), __cssprop("span", {
        css: {
          "position": "relative",
          "display": "block",
          "width": "0.8rem",
          "height": "0.8rem",
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(5, 150, 105, var(--tw-bg-opacity))",
          "borderRadius": "9999px"
        }
      })), __cssprop("span", {
        css: {
          "marginLeft": "1.2rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "fontWeight": "500",
          "--tw-text-opacity": "1",
          "color": "rgba(16, 185, 129, var(--tw-text-opacity))"
        }
      }, step.name))));
    }

    if (step.status === "warning") {
      return __cssprop("li", {
        key: key
      }, __cssprop("div", {
        css: {
          "display": "flex",
          "alignItems": "flex-start"
        },
        "aria-current": "step"
      }, __cssprop("span", {
        css: {
          "flexShrink": "0",
          "height": "2rem",
          "width": "2rem",
          "position": "relative",
          "display": "flex",
          "alignItems": "center",
          "justifyContent": "center"
        },
        "aria-hidden": "true"
      }, __cssprop("span", {
        css: {
          "position": "absolute",
          "height": "1.6rem",
          "width": "1.6rem",
          "borderRadius": "9999px",
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(254, 202, 202, var(--tw-bg-opacity))"
        }
      }), __cssprop("span", {
        css: {
          "position": "relative",
          "display": "block",
          "width": "0.8rem",
          "height": "0.8rem",
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(220, 38, 38, var(--tw-bg-opacity))",
          "borderRadius": "9999px"
        }
      })), __cssprop("span", {
        css: {
          "marginLeft": "1.2rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "fontWeight": "500",
          "--tw-text-opacity": "1",
          "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
        }
      }, step.name)));
    }

    return __cssprop("li", {
      key: key
    }, __cssprop("div", {
      className: "group"
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "flex-start"
      }
    }, __cssprop("div", {
      css: {
        "flexShrink": "0",
        "height": "2rem",
        "width": "2rem",
        "position": "relative",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center"
      },
      "aria-hidden": "true"
    }, __cssprop("div", {
      css: {
        "height": "0.8rem",
        "width": "0.8rem",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))",
        "borderRadius": "9999px"
      }
    })), __cssprop("p", {
      css: {
        "marginLeft": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
      }
    }, step.name))));
  }))));
};

export default ApplicantRequestFormV2;