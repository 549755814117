import { DateTime } from "../../../_snowpack/pkg/luxon.js";
import i18n from "../../../_snowpack/pkg/i18next.js";
import React, { useState } from "../../../_snowpack/pkg/react.js";
import { fr, enUS } from "../../../_snowpack/pkg/react-date-range/dist/locale.js";
import { Calendar } from "../../../_snowpack/pkg/react-date-range.js";
import "../../../_snowpack/pkg/react-date-range/dist/styles.css"; // main css file

import "../../../_snowpack/pkg/react-date-range/dist/theme/default.css"; // theme css file

import { HiCalendar } from "../../../_snowpack/pkg/react-icons/hi.js";
import { Tooltip } from "../../../_snowpack/pkg/react-tippy.js";
import "../../../_snowpack/pkg/react-tippy/dist/tippy.css";
import { TextField } from "./Tailwind/TextField.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const getLang = lang => {
  if (lang === "en") {
    return enUS;
  }

  return fr;
};

export const DatePicker = ({
  defaultValue,
  onChange,
  title,
  error,
  disabled,
  id
}) => {
  const [date, setDate] = useState(defaultValue?.isValid ? defaultValue : DateTime.utc());
  const [isOpen, setIsOpen] = useState(false);
  const changeDate = React.useCallback(d => {
    const newDate = DateTime.fromJSDate(d).toUTC();
    setDate(newDate);
    if (onChange) onChange(newDate);
    setTimeout(() => {
      setIsOpen(false);
    }, 50);
  }, []);
  return __cssprop(Tooltip, {
    position: "bottom-start",
    distance: -40,
    interactive: true,
    onRequestClose: () => setIsOpen(false),
    open: isOpen,
    theme: "light",
    size: "big",
    animateFill: false,
    trigger: "click",
    disabled: disabled,
    html: __cssprop("div", null, __cssprop(Calendar, {
      locale: getLang(i18n.language),
      showPreview: true,
      showDateDisplay: true,
      showSelectionPreview: true,
      months: 1,
      showMonthAndYearPickers: true,
      date: defaultValue?.isValid ? defaultValue.toJSDate() : DateTime.utc().toJSDate(),
      onChange: changeDate
    }))
  }, __cssprop("div", {
    css: {
      "marginTop": "0.4rem",
      "paddingTop": "0.4rem"
    },
    "aria-hidden": true,
    onClick: () => setIsOpen(true)
  }, __cssprop(TextField, {
    label: title,
    disabled: disabled,
    value: defaultValue?.isValid ? defaultValue.toUTC().toISODate() : date.toUTC().toISODate(),
    id: id,
    error: error,
    clickOnly: true,
    icon: HiCalendar
  })));
};