import React from "../../../../../../_snowpack/pkg/react.js";
import { useSelector } from "../../../../../../_snowpack/pkg/react-redux.js";
import { Selectors } from "../../../../store/index.js";
import EventSideEntityButtons from "./EventSideEntityButtons.js";
import EventSideEntitySummary from "./EventSideEntitySummary.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const EventSide = () => {
  const entityMetadata = useSelector(Selectors.event.getEntityMetadata);
  return __cssprop("div", {
    css: {
      "padding": "2.8rem",
      "display": "flex",
      "flexDirection": "column",
      "gap": "2.4rem"
    }
  }, entityMetadata ? __cssprop(EventSideEntitySummary, null) : __cssprop(EventSideEntityButtons, null));
};

export default EventSide;