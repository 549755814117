function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { BreadcrumbsConsumer } from "./Consumer.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const BreadCrumbWrapper = props => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const {
      addCrumb,
      findCrumbIndex,
      removeCrumbs,
      crumb,
      crumbs
    } = props;

    if (!crumb || loaded) {
      return;
    }

    setLoaded(true);
    const index = findCrumbIndex(crumb, crumbs);

    if (index >= 0) {
      removeCrumbs(index);
      addCrumb(crumb);
    } else {
      addCrumb(crumb);
    }
  }, [props, setLoaded, loaded]);
  return props.children;
};

export const withBreadcrumb = WrappedComponent => props => {
  const [crumb, setCrumb] = useState();
  return __cssprop(BreadcrumbsConsumer, null, consumer => {
    return __cssprop(BreadCrumbWrapper, _extends({}, consumer, {
      crumb: crumb
    }), __cssprop(WrappedComponent, _extends({}, props, {
      setCrumb: setCrumb,
      crumbs: consumer.crumbs
    })));
  });
};