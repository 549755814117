import { TaskInvoiceSideBar } from "../../forms/SideBar/TaskInvoiceSideBar.js";
import useI18n from "../../hooks/useI18n.js";
import React from "../../../../_snowpack/pkg/react.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const TaskInvoicePanel = ({
  refetch,
  taskId,
  handleOnClose,
  isOpen
}) => {
  const {
    t
  } = useI18n();
  const sectionTitle = t("task-invoice-title");
  return __cssprop(SlideOver, {
    title: sectionTitle,
    isOpen: isOpen,
    onClose: handleOnClose
  }, __cssprop(TaskInvoiceSideBar, {
    refetch: refetch,
    taskId: taskId
  }));
};