import { initialState, TaskActionTypes } from "./types.js";
export const setTaskField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};
export const setTaskInitialState = state => {
  return { ...state,
    ...initialState
  };
};
export default {
  [TaskActionTypes.TASK_SET_INITIAL_STATE]: setTaskInitialState,
  [TaskActionTypes.TASK_SET_FIELD]: setTaskField
};