import { Selector } from "../../../components/Selectors/Selector.js";
import { TextField } from "../../../components/Tailwind/TextField.js";
import useI18n from "../../../hooks/useI18n.js";
import { consentKeyValue, consentKeyValueObjInput, consentKeyValueObjSelector } from "../../../utils/consent.js";
import React, { useCallback } from "../../../../../_snowpack/pkg/react.js";
import { Controller, useFormContext } from "../../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const getConsentInKeyValueFormat = (t, consents) => {
  return consents.map(c => {
    return {
      label: consentKeyValueObjSelector(t)[c.consentType],
      value: c.consentType
    };
  });
};

export const ConsentPicker = () => {
  const {
    t
  } = useI18n();
  const {
    control,
    setValue,
    getValues
  } = useFormContext(); // on change of key update list of consents. keep value if consents already exists, else default to empty string. Exception is is_non_smoking which should always default to true

  const handleOnChangeKey = useCallback(labelItems => {
    const getConsents = getValues("consents");
    const newConsents = labelItems.map(l => {
      let consentValue;
      const consentIndexBeingUpdated = getConsents.findIndex(c => c.consentType === l.value);
      if (consentIndexBeingUpdated < 0) consentValue = "";else if (l.value === "is_non_smoking") consentValue = "true";else consentValue = getConsents[consentIndexBeingUpdated].consentValue;
      return {
        consentType: l.value,
        consentValue
      };
    });
    setValue("consents", newConsents);
  }, []);
  const handleOnChangeValue = useCallback((consentType, value) => {
    const getConsents = getValues("consents"); // find consent in list

    const consentIndexToUpdate = getConsents.findIndex(c => c.consentType === consentType);
    if (consentIndexToUpdate < 0) return; // update value of consent and clone to new list

    getConsents[consentIndexToUpdate] = { ...getConsents[consentIndexToUpdate],
      consentValue: value
    };
    setValue("consents", getConsents);
  }, []);
  return __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("consents")), __cssprop("div", {
    css: {
      "marginTop": "0.8rem",
      "borderTopWidth": "1px",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(Controller, {
    name: "consents",
    control: control,
    render: ({
      field,
      fieldState,
      formState
    }) => {
      return __cssprop("div", null, __cssprop(Selector, {
        onChange: v => handleOnChangeKey(v),
        options: consentKeyValue(t),
        isMulti: true,
        selectedItem: getConsentInKeyValueFormat(t, field.value)
      }), field.value.map((consent, index) => consent.consentType !== "is_non_smoking" && __cssprop("div", {
        css: {
          "paddingTop": "0.8rem"
        },
        key: index
      }, __cssprop(TextField, {
        id: consent.consentType,
        key: consent.consentType,
        defaultValue: consent.consentValue,
        label: consentKeyValueObjInput(t)[consent.consentType],
        onChange: v => handleOnChangeValue(consent.consentType, v)
      }))));
    }
  }))));
};