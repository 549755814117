import { DepositBody } from "../../../components/DepositBody.js";
import { DepositPanel } from "../../../components/Panels/DepositPanel.js";
import { ProtectedCard, ProtectedFunction } from "../../../components/Tailwind/ProtectedComponents.js";
import RemoteList from "../../../components/Tailwind/RemoteList.js";
import { ResourceListItem } from "../../../components/Tailwind/ResourceList.js";
import { GetClientCompanyDepositsDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../../store/artifacts/Application/types.js";
import { DepositActionsTypes } from "../../../store/artifacts/Deposit/types.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const ProjectDepositList = () => {
  const {
    t
  } = useI18n();
  const role = useSelector(Selectors.session.getUserRole);
  const id = useSelector(Selectors.project.getId);
  const name = useSelector(Selectors.project.getName);
  const {
    dispatch
  } = useAction();

  const renderDeposits = item => {
    const {
      depositUser
    } = item;
    const user = depositUser ? depositUser.name : "";
    return __cssprop("div", null, __cssprop(ResourceListItem, {
      id: item.id,
      accessibilityLabel: t("project-info-see-deposit", {
        amount: item.amount,
        user
      }),
      onClick: ProtectedFunction(role, () => {
        dispatch({
          type: DepositActionsTypes.DEPOSIT_INITIALIZE,
          payload: {
            kind: "WithContext",
            deposit: { ...item,
              files: item.files.items
            }
          }
        });
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: "deposit"
        });
      }, {
        kind: "Deposit",
        permission: "mutate:update"
      }, ProtectedFunction(role, () => {
        dispatch({
          type: DepositActionsTypes.DEPOSIT_INITIALIZE,
          payload: {
            kind: "WithContext",
            deposit: { ...item,
              files: item.files.items
            }
          }
        });
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: "deposit"
        });
      }, {
        kind: "Deposit",
        permission: "view:info"
      })),
      persistActions: true
    }, __cssprop(DepositBody, {
      item: item
    })));
  };

  const onDoneSubmit = data => {
    dispatch({
      type: DepositActionsTypes.DEPOSIT_SUBMIT,
      payload: { ...data
      }
    });
  };

  return __cssprop(ProtectedCard, {
    permission: {
      kind: "Project",
      permission: "view:projectDeposit"
    },
    title: t("project-info-list-deposit")
  }, __cssprop(RemoteList, {
    noShadow: true,
    query: GetClientCompanyDepositsDocument,
    name: "project.deposits",
    renderItem: renderDeposits,
    extraVariables: {
      id
    },
    resourceName: {
      plural: t("deposits"),
      singular: t("deposit")
    }
  }), __cssprop(DepositPanel, {
    onDoneSubmit: onDoneSubmit,
    formTitle: t("deposit-form-add-deposit"),
    description: "",
    sectionTitle: ""
  }));
};