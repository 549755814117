/* eslint-disable jsx-a11y/label-has-associated-control */
import { BadgeCheckIcon } from "../../../../_snowpack/pkg/@heroicons/react/outline.js";
import { Loader } from "../../components/Loader.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { Tabs } from "../../components/Tailwind/Tabs.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useCancelLeaseModal from "../../forms/useCancelLease.js";
import { useFeatureToggle } from "../../hooks/useFeatureToggle.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { LeaseActionsTypes } from "../../store/artifacts/Lease/types.js";
import React, { useEffect } from "../../../../_snowpack/pkg/react.js";
import { RiErrorWarningFill } from "../../../../_snowpack/pkg/react-icons/ri.js";
import { Layout } from "../../components/Tailwind/Layout.js";
import { Page } from "../../components/Tailwind/Page.js";
import { PaymentDataList } from "../Payments/index.js";
import { AmenitiesView } from "./components/AmenitiesView.js";
import { DetailsPane } from "./components/DetailsPane.js";
import { DetailsView } from "./components/DetailsView.js";
import { TenantsView } from "./components/TenantsPane.js"; // const StatusSelector = () => {
//   const { t } = useI18n();
//   const { dispatch } = useAction();
//   const status = useSelector(Selectors.lease.getNewStatus);
//   const onClick = (event) => {
//     dispatch({ type: "LEASE_SET_STATUS", payload: event.option.value });
//   };
//   return (
//     <div tw="inline-flex px-2">
//       <ListBox
//         loading={false}
//         options={leaseStatusOptions(t)}
//         selected={leaseStatusOptions(t).find((item) => item.value === status)}
//         onClick={onClick}
//       />
//     </div>
//   );
// };

import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const TabsRenderer = ({
  selectedTab,
  setSelectedTab,
  t
}) => {
  const isLocked = useSelector(Selectors.lease.isLocked);
  const tenants = useSelector(Selectors.lease.getTenants);
  const amenities = useSelector(Selectors.lease.getAmenities);
  const isDetailComplete = useSelector(Selectors.lease.hasCompletedDetails);
  const paymentEnable = useFeatureToggle("payment");
  const tab = [{
    content: __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center"
      }
    }, isDetailComplete && __cssprop(BadgeCheckIcon, {
      css: {
        "height": "1.6rem",
        "width": "1.6rem",
        "marginRight": "0.4rem"
      }
    }), " ", t("lease-page-tab-detail")),
    id: "Details"
  }, {
    content: __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center"
      }
    }, tenants?.length === 0 ? __cssprop(RiErrorWarningFill, {
      css: {
        "height": "1.6rem",
        "width": "1.6rem",
        "marginRight": "0.4rem"
      }
    }) : __cssprop(BadgeCheckIcon, {
      css: {
        "height": "1.6rem",
        "width": "1.6rem",
        "marginRight": "0.4rem"
      }
    }), t("lease-page-tab-tenant", {
      number: tenants?.length
    })),
    id: "Tenants"
  }, {
    content: t("lease-page-tab-amenities", {
      number: amenities?.length
    }),
    id: "Amenities"
  }];

  if (paymentEnable) {
    tab.push({
      content: t("payments"),
      id: "payments",
      permission: {
        kind: "Payement",
        permission: "view:list"
      }
    });
  }

  if (isLocked) {
    // remove details view
    tab.splice(0, 1);
  }

  return __cssprop(Tabs, {
    selected: selectedTab,
    tabs: tab,
    onSelect: setSelectedTab
  });
};

export const Lease = withBreadcrumb(({
  crumbs,
  setCrumb,
  match,
  location
}) => {
  const {
    t
  } = useI18n();
  const {
    id,
    renewal
  } = match.params;
  const isLocked = useSelector(Selectors.lease.isLocked);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const {
    dispatch
  } = useAction();
  const isReady = useSelector(Selectors.lease.hasCompletedDetails);
  const isSubmiting = useSelector(Selectors.status.isSubmiting("lease"));
  const isLoading = useSelector(Selectors.status.isLoading("lease"));
  const isExistingLease = useSelector(Selectors.lease.isExistingLease);
  const leaseId = useSelector(Selectors.lease.getId);
  const unitId = useSelector(Selectors.lease.getBuildingId);
  const buildingId = useSelector(Selectors.lease.getUnitId);
  const isViewOnly = location.pathname.includes("view");
  useEffect(() => {
    if (leaseId) {
      setCrumb({
        title: t("lease"),
        path: `/lease/${leaseId}`,
        level: 4
      });
    }
  }, [leaseId]);
  useEffect(() => {
    if (id) {
      if (isViewOnly) {
        dispatch({
          type: LeaseActionsTypes.LEASE_INITIALIZE_WITH_LEASE_VIEW_ONLY,
          payload: id
        });
        return;
      } // If this is a renewal then initialize renewal esle initialize modify lease


      if (renewal) {
        dispatch({
          type: LeaseActionsTypes.LEASE_INITIALIZE_WITH_RENEWAL,
          payload: id
        });
      } else {
        dispatch({
          type: LeaseActionsTypes.LEASE_INITIALIZE_WITH_LEASE,
          payload: id
        });
      }
    } else {
      if (unitId || buildingId) {
        return;
      }

      dispatch({
        type: LeaseActionsTypes.LEASE_INITIALIZE_WITH_LEASE,
        payload: null
      });
    }
  }, []);

  const MainTabRenderer = () => {
    if (isLocked) {
      switch (selectedTab) {
        case 0:
          return __cssprop(TenantsView, null);

        case 1:
          return __cssprop(AmenitiesView, null);

        case 2:
          return __cssprop(PaymentDataList, null);

        default:
          return __cssprop(AmenitiesView, null);
      }
    }

    switch (selectedTab) {
      case 0:
        return __cssprop(DetailsView, null);

      case 1:
        return __cssprop(TenantsView, null);

      case 2:
        return __cssprop(AmenitiesView, null);

      case 3:
        return __cssprop(PaymentDataList, null);

      default:
        return __cssprop(AmenitiesView, null);
    }
  };

  const getLeaseTitle = () => {
    if (isLocked) return t("unit-see-lease");
    if (renewal) return t("unit-renew-lease");
    if (isExistingLease) return t("unit-modify-lease");
    return t("lease-page-title");
  };

  const handleOnCancelLease = (_result, _values, pvalues) => {
    dispatch({
      type: LeaseActionsTypes.LEASE_CANCEL,
      payload: pvalues
    });
  };

  const [CancelModal, openCancelModal] = useCancelLeaseModal(t("lease-form-cancel-lease-description"), handleOnCancelLease);

  const getSubmitButton = () => {
    if (isLocked) {
      return __cssprop(ProtectedButton, {
        destructive: true,
        permission: {
          kind: "Lease",
          permission: "mutate:update"
        },
        loading: isSubmiting,
        disabled: !isReady,
        onAction: openCancelModal
      }, t("lease-form-cancel-lease"));
    }

    if (renewal) {
      return __cssprop(ProtectedButton, {
        primary: true,
        permission: {
          kind: "Lease",
          permission: "mutate:create"
        },
        loading: isSubmiting,
        disabled: !isReady,
        onAction: () => dispatch({
          type: LeaseActionsTypes.LEASE_SUBMIT,
          payload: true
        })
      }, t("unit-renew-lease"));
    }

    return __cssprop(ProtectedButton, {
      primary: true,
      permission: {
        kind: "Lease",
        permission: "mutate:update"
      },
      loading: isSubmiting,
      disabled: !isReady,
      onAction: () => dispatch({
        type: LeaseActionsTypes.LEASE_SUBMIT,
        payload: null
      })
    }, isExistingLease ? t("unit-modify-lease") : t("lease-page-action"));
  };

  if (isLoading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  return __cssprop(React.Fragment, null, __cssprop(Page, {
    breadcrumbs: crumbs,
    title: getLeaseTitle(),
    primaryAction: getSubmitButton()
  }, __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop(TabsRenderer, {
    selectedTab: selectedTab,
    setSelectedTab: setSelectedTab,
    t: t
  }), __cssprop(MainTabRenderer, null)), __cssprop(Layout, {
    oneThird: true
  }, __cssprop(DetailsPane, null)))), __cssprop(CancelModal, null));
});
export default Lease;