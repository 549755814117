function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../../_snowpack/pkg/react.js";
import { useMutation } from "../../../../_snowpack/pkg/@apollo/client.js";
import { DatePicker } from "../../components/DatePicker.js";
import { Loader } from "../../components/Loader.js";
import { UserSelector } from "../../components/Selectors/UserSelector.js";
import { StyledButton } from "../../components/Tailwind/Button.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import { CreateTaskDocument, UpdateTaskDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { toast } from "../../../../_snowpack/pkg/react-toastify.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

const getTaskTitle = (ticket, taskTitle) => {
  if (taskTitle) return taskTitle;

  if (ticket.tasks.items && ticket.tasks.items.length === 0) {
    return ticket.title;
  }

  return "";
};

export const TaskSideBarFormView = ({
  title,
  dueDate,
  taskId,
  ticket,
  refetch,
  sectionTitle,
  closedAt,
  assignee
}) => {
  const {
    t
  } = useI18n();
  const {
    handleSubmit,
    formState: {
      errors
    },
    control
  } = useForm({
    defaultValues: {
      assigneeId: assignee ? {
        label: assignee.name,
        value: assignee.id
      } : undefined,
      dueDate,
      title: getTaskTitle(ticket, title)
    }
  });
  const [createTask, {
    loading: createTaskLoading
  }] = useMutation(CreateTaskDocument, {
    fetchPolicy: "no-cache"
  });
  const [updateTask, {
    loading: updateTaskLoading
  }] = useMutation(UpdateTaskDocument, {
    fetchPolicy: "no-cache"
  });
  const onSubmit = handleSubmit(async task => {
    try {
      let taskSaveResponse;

      if (taskId) {
        taskSaveResponse = await updateTask({
          variables: {
            taskId,
            task: { ...task,
              assigneeId: task.assigneeId?.value ? task.assigneeId?.value : task.assigneeId
            }
          }
        });
      } else {
        if (!ticket.id) {
          throw new Error("Ticket ID missing");
        }

        taskSaveResponse = await createTask({
          variables: {
            ticketId: ticket.id,
            task: { ...task,
              assigneeId: task.assigneeId?.value ? task.assigneeId?.value : task.assigneeId
            }
          }
        });
      }

      if (taskSaveResponse.errors) {
        toast.warn(t("generic-fetch-error-message"));
      }

      if (taskSaveResponse && taskSaveResponse.data) {
        toast.success(t("task-form-add-task-success"));
        refetch();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("TaskSection", e);
      toast.warn(t("generic-fetch-error-message"));
    }
  });
  const isLoading = createTaskLoading || updateTaskLoading;

  if (isLoading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  return __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "1.6rem"
    }
  }, __cssprop("form", {
    onSubmit: onSubmit,
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, sectionTitle)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "title",
    control: control,
    rules: {
      required: true,
      minLength: 6
    },
    render: ({
      field
    }) => {
      return __cssprop(TextField, _extends({}, field, {
        id: "title",
        label: t("task-add-sidebar-title-label"),
        placeholder: t("task-add-sidebar-title-placeholder"),
        error: showError(errors, "title", t("task-add-sidebar-title-error")),
        disabled: false
      }));
    }
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "dueDate",
    control: control,
    rules: {
      required: true
    },
    render: ({
      field
    }) => __cssprop(DatePicker, _extends({}, field, {
      id: "dueDate",
      defaultValue: field.value,
      onChange: field.onChange,
      title: t("task-add-sidebar-due-date"),
      error: showError(errors, "dueDate", t("field-required")),
      disabled: false
    }))
  })), __cssprop(StyledItems, null, __cssprop("h4", null, t("task-add-sidebar-user-title")), __cssprop(Controller, {
    name: "assigneeId",
    control: control,
    rules: {
      required: true
    },
    render: ({
      field,
      fieldState
    }) => __cssprop(UserSelector, {
      selected: field.value,
      onChange: v => {
        field.onChange(v.value);
      },
      defaultValue: field.value,
      placeholder: t("project-info-responsible-selector"),
      error: !!fieldState.error,
      roles: []
    })
  }))), !closedAt && __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "row",
      "width": "100%",
      "justifyContent": "space-evenly"
    }
  }, __cssprop(StyledButton, {
    submit: true,
    primary: true
  }, t("save")))));
};