import { useDispatch, useSelector as reduxSelector } from "../../../_snowpack/pkg/react-redux.js";
import { Selectors as ReduxSelectors } from "../store/index.js";
export const useSelector = reduxSelector;
export const Selectors = ReduxSelectors;
export function useAction() {
  const dispatch = useDispatch();
  return {
    dispatch: data => {
      return dispatch({
        type: data.type,
        payload: data?.payload
      });
    }
  };
}