import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { Disclosure } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { ChevronDownIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { Card } from "./Card.js";
import { ProtectedCard } from "./ProtectedComponents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledDiv = _styled.div(({
  open
}) => [open && {
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "180deg",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
}]);

const StyledTr = _styled.tr(({
  open
}) => [open && {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(196, 181, 253, var(--tw-border-opacity)) !important",
  "borderBottomWidth": "0px",
  "borderLeftWidth": "8px",
  "borderRightWidth": "8px",
  "borderTopWidth": "4px !important"
}]);

const StyledDisclosurePanel = _styled(Disclosure.Panel)(() => [{
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(196, 181, 253, var(--tw-border-opacity)) !important",
  "borderBottomWidth": "4px !important",
  "borderLeftWidth": "8px !important",
  "borderRightWidth": "8px !important",
  "borderTopWidth": "0px !important"
}]);

const StyledSpan = _styled.span(({
  currentTab
}) => [{
  "position": "absolute",
  "left": "0px",
  "right": "0px",
  "bottom": "0px",
  "height": "0.2rem"
}, currentTab && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))"
}, !currentTab && {
  "backgroundColor": "transparent"
}]);

const StyledButton = _styled.button(({
  selected,
  tabIdx,
  tabsLength
}) => [{
  "position": "relative",
  "minWidth": "0px",
  "flex": "1 1 0%",
  "overflow": "hidden",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "paddingTop": "1.6rem",
  "paddingBottom": "1.6rem",
  "paddingLeft": "1.6rem",
  "paddingRight": "1.6rem",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "textAlign": "center",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
  },
  ":focus": {
    "zIndex": "10"
  }
}, selected && {
  "--tw-text-opacity": "1",
  "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
}, !selected && {
  "--tw-text-opacity": "1",
  "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
  ":hover": {
    "--tw-text-opacity": "1",
    "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
  }
}, tabIdx === 0 && {
  "borderTopLeftRadius": "0.8rem",
  "borderBottomLeftRadius": "0.8rem"
}, tabIdx === tabsLength - 1 && {
  "borderTopRightRadius": "0.8rem",
  "borderBottomRightRadius": "0.8rem"
}]);

const tableRow = rows => {
  if (!rows || rows.length === 0) {
    return null;
  }

  const mappedRows = rows.map((row, index) => {
    if (row.disclosure) {
      return __cssprop(Disclosure, null, ({
        open
      }) => __cssprop(React.Fragment, null, __cssprop(StyledTr, {
        key: `row-${index}`,
        open: open
      }, row.items.map((item, idx) => {
        return __cssprop("td", {
          key: `row-item-${idx}`,
          css: {
            "paddingLeft": "2.4rem",
            "paddingRight": "2.4rem",
            "paddingTop": "1.6rem",
            "paddingBottom": "1.6rem",
            "whiteSpace": "nowrap"
          }
        }, __cssprop("div", {
          css: {
            "display": "flex",
            "alignItems": "center"
          }
        }, item));
      }), __cssprop("td", null, __cssprop(Disclosure.Button, {
        css: {
          "display": "flex",
          "justifyContent": "flex-end",
          "paddingLeft": "1.6rem",
          "paddingRight": "1.6rem",
          "paddingTop": "0.8rem",
          "paddingBottom": "0.8rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "fontWeight": "500",
          "textAlign": "left",
          "--tw-text-opacity": "1",
          "color": "rgba(76, 29, 149, var(--tw-text-opacity))",
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(237, 233, 254, var(--tw-bg-opacity))",
          "borderRadius": "0.8rem",
          ":hover": {
            "--tw-bg-opacity": "1",
            "backgroundColor": "rgba(221, 214, 254, var(--tw-bg-opacity))"
          },
          ":focus": {
            "outline": "2px solid transparent",
            "outlineOffset": "2px"
          },
          ":focus-visible": {
            "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
            "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
            "--tw-ring-opacity": "0.75",
            "--tw-ring-color": "rgba(139, 92, 246, var(--tw-ring-opacity))"
          }
        }
      }, row.disclosure.button && __cssprop("span", null, row.disclosure.button), __cssprop(StyledDiv, {
        open: open
      }, __cssprop(ChevronDownIcon, {
        css: {
          "width": "2rem",
          "height": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(139, 92, 246, var(--tw-text-opacity))"
        }
      }))))), __cssprop(StyledDisclosurePanel, {
        as: "tr"
      }, row.disclosure.panel)));
    }

    return __cssprop("tr", {
      key: `row-${index}`
    }, row.items.map((item, idx) => {
      return __cssprop("td", {
        key: `row-item-${idx}`,
        css: {
          "paddingLeft": "2.4rem",
          "paddingRight": "2.4rem",
          "paddingTop": "1.6rem",
          "paddingBottom": "1.6rem",
          "whiteSpace": "nowrap"
        }
      }, __cssprop("div", {
        css: {
          "display": "flex",
          "alignItems": "center"
        }
      }, item));
    }), __cssprop("td", null));
  });
  return mappedRows;
};

export const DataList = ({
  headings,
  rows,
  title,
  permission,
  tabs,
  collapsible,
  onTabChange,
  currentTab
}) => {
  const getList = () => {
    return __cssprop("div", {
      css: {
        "marginTop": "-0.8rem",
        "marginBottom": "-0.8rem",
        "overflowX": "auto",
        "@media (min-width: 640px)": {
          "marginLeft": "-2.4rem",
          "marginRight": "-2.4rem"
        },
        "@media (min-width: 1024px)": {
          "marginLeft": "-3.2rem",
          "marginRight": "-3.2rem"
        }
      }
    }, __cssprop("div", {
      css: {
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "verticalAlign": "middle",
        "minWidth": "100%",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem",
          "paddingRight": "2.4rem"
        },
        "@media (min-width: 1024px)": {
          "paddingLeft": "3.2rem",
          "paddingRight": "3.2rem"
        }
      }
    }, __cssprop("div", {
      css: {
        "borderTopWidth": "1px",
        "overflowX": "auto",
        "overflowY": "auto",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
      }
    }, __cssprop("table", {
      css: {
        "minWidth": "100%",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-divide-y-reverse": 0,
          "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
          "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
          "--tw-divide-opacity": "1",
          "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
        }
      }
    }, __cssprop("thead", {
      css: {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }, __cssprop("tr", null, headings.map((name, index) => __cssprop("th", {
      scope: "col",
      key: `heading-item-${index}`,
      css: {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem",
        "paddingTop": "1.2rem",
        "paddingBottom": "1.2rem",
        "textAlign": "left",
        "fontSize": "1.2rem",
        "lineHeight": "1.6rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "textTransform": "uppercase",
        "letterSpacing": "0.05em"
      }
    }, name)), __cssprop("th", {
      scope: "col",
      css: {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem",
        "paddingTop": "1.2rem",
        "paddingBottom": "1.2rem",
        "textAlign": "left",
        "fontSize": "1.2rem",
        "lineHeight": "1.6rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "textTransform": "uppercase",
        "letterSpacing": "0.05em"
      }
    }, " "))), __cssprop("tbody", {
      css: {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-divide-y-reverse": 0,
          "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
          "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
          "--tw-divide-opacity": "1",
          "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
        }
      }
    }, tableRow(rows))))));
  };

  const getListNoTab = () => {
    return __cssprop("div", {
      css: {
        "display": "flex",
        "flexDirection": "column"
      }
    }, getList());
  };

  const getListWithTab = () => {
    return __cssprop(React.Fragment, null, __cssprop("div", {
      css: {
        "borderRadius": "1.2rem"
      }
    }, __cssprop("div", {
      css: {
        "@media (min-width: 640px)": {
          "display": "none"
        }
      }
    }, __cssprop("select", {
      id: "tabs",
      name: "tabs",
      css: {
        "display": "block",
        "width": "100%",
        ":focus": {
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
          "--tw-border-opacity": "1",
          "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
        },
        "--tw-border-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
        "borderRadius": "0.6rem"
      },
      defaultValue: tabs[0].label
    }, tabs.map(tab => __cssprop("option", {
      key: tab.label
    }, tab.label)))), __cssprop("div", {
      css: {
        "display": "none",
        "@media (min-width: 640px)": {
          "display": "block"
        }
      }
    }, __cssprop("nav", {
      css: {
        "position": "relative",
        "zIndex": "0",
        "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
        "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-divide-x-reverse": 0,
          "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
          "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
          "--tw-divide-opacity": "1",
          "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
        }
      },
      "aria-label": "Tabs"
    }, tabs.map((tab, tabIdx) => __cssprop(StyledButton, {
      type: "button",
      key: tab.label,
      className: "group",
      onClick: () => onTabChange(tab.value),
      selected: currentTab === tab.value,
      tabIdx: tabIdx,
      tabsLength: tabs.length
    }, __cssprop("span", null, tab.label), __cssprop(StyledSpan, {
      "aria-hidden": "true",
      currentTab: currentTab === tab.value
    })))))), getList());
  };

  if (tabs && permission) {
    return __cssprop(ProtectedCard, {
      permission: permission
    }, getListWithTab());
  }

  if (tabs) {
    return __cssprop(Card, null, getListWithTab());
  }

  if (permission) {
    return __cssprop(ProtectedCard, {
      title: title,
      permission: permission,
      collapsible: collapsible,
      sectionned: true
    }, getListNoTab());
  }

  return __cssprop(Card, {
    title: title,
    collapsible: collapsible
  }, getListNoTab());
};