import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { Switch } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { PhoneInput } from "../../components/PhoneInput/index.js";
import { Selector } from "../../components/Selectors/Selector.js";
import { Attachments } from "../../components/Tailwind/Attachments.js";
import { Card } from "../../components/Tailwind/Card.js";
import { FileDropZone } from "../../components/Tailwind/FileDropZone.js";
import { Layout } from "../../components/Tailwind/Layout.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { UserActionsTypes } from "../../store/artifacts/User/types.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useEffect } from "../../../../_snowpack/pkg/react.js";
import { Page } from "../../components/Tailwind/Page.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledSpan = _styled.span(({
  active
}) => [{
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "0",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "pointerEvents": "none",
  "display": "inline-block",
  "height": "2rem",
  "width": "2rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)"
}, active ? {
  "--tw-translate-x": "2rem"
} : {
  "--tw-translate-x": "0px"
}]);

const StyledSwitch = _styled(Switch)(({
  checked
}) => [{
  "position": "relative",
  "display": "inline-flex",
  "flexShrink": "0",
  "height": "2.4rem",
  "width": "4.4rem",
  "borderWidth": "2px",
  "borderColor": "transparent",
  "borderRadius": "9999px",
  "cursor": "pointer",
  "transitionProperty": "background-color, border-color, color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-width": "2px",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
  }
}, checked ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
} : {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))"
}]);

const getSelectedOptions = (options, selectedOne) => {
  return options.find(o => o.value === selectedOne);
};

const AVATAR_STYLES = {
  width: "200px",
  height: "200px",
  borderRadius: "100px"
};
const LANGUAGE_OPTIONS = [{
  label: "Français",
  value: "FR"
}, {
  label: "English",
  value: "EN"
}];

const FilesCard = ({
  isViewOnly
}) => {
  const files = useSelector(Selectors.lease.getFiles);
  return __cssprop(React.Fragment, null, !isViewOnly && __cssprop(FileDropZone, {
    onDeleteDispatch: UserActionsTypes.USER_REMOVE_FILES,
    onDropDispatch: UserActionsTypes.USER_ADD_FILES,
    files: files
  }), isViewOnly && __cssprop(Attachments, {
    visualOnly: true,
    files: files?.map(attachement => {
      if (attachement.kind === "local") {
        return {
          fileName: attachement.fileName,
          url: attachement.preview,
          fileType: attachement.fileType
        };
      }

      return {
        fileName: attachement.fileName,
        url: attachement.url,
        fileType: attachement.fileType,
        filePath: attachement.filePath
      };
    })
  }));
};

export const Profile = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const userFromApp = useSelector(Selectors.session.getUser);
  const initials = useSelector(Selectors.session.getUserInitials);
  const profileImage = useSelector(Selectors.session.getUserProfileImage);
  const notificationEnabled = useSelector(Selectors.session.getUserNotificationPreferences);
  const isSubmiting = useSelector(Selectors.status.isSubmiting("user"));
  const {
    id,
    firstName,
    lastName,
    language,
    primaryPhone,
    email,
    secondaryPhone
  } = userFromApp;
  useEffect(() => {
    if (userFromApp) {
      const user = {
        id,
        firstName,
        lastName,
        language,
        email,
        profileImage
      };
      setTimeout(() => {
        dispatch({
          type: UserActionsTypes.USER_INITIALIZE,
          payload: {
            kind: "WithContext",
            user
          }
        });
      }, 0);
    }
  }, []);
  return __cssprop(Page, {
    title: t("profile-modify-profil"),
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "User",
        permission: "mutate:profile"
      },
      primary: true,
      onAction: () => null,
      loading: isSubmiting
    }, t("submit"))
  }, __cssprop(Layout, null, __cssprop(Layout, {
    oneThird: true
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "justifyContent": "center"
    }
  }, profileImage ? __cssprop("img", {
    id: "base64image",
    alt: "",
    style: AVATAR_STYLES,
    src: profileImage
  }) : __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(59, 130, 246, var(--tw-bg-opacity))",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    },
    style: AVATAR_STYLES
  }, __cssprop("svg", {
    css: {
      "width": "100%",
      "height": "100%"
    }
  }, __cssprop("text", {
    x: "50%",
    y: "50%",
    dy: "0.35em",
    fill: "currentColor",
    fontSize: "64",
    textAnchor: "middle"
  }, initials))))), __cssprop("div", {
    css: {
      "display": "flex",
      "justifyItems": "center",
      "alignContent": "center",
      "alignItems": "center"
    }
  }, __cssprop(FilesCard, {
    isViewOnly: false
  })))))), __cssprop(Layout, {
    twoThird: true
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(TextField, {
    css: {
      "marginBottom": "1.6rem"
    },
    id: "firstName",
    label: t("first-name"),
    type: "text",
    value: firstName,
    onChange: value => {
      dispatch({
        type: UserActionsTypes.USER_SET_FIELD,
        payload: {
          firstName: value
        }
      });
    }
  }), __cssprop(TextField, {
    css: {
      "marginBottom": "1.6rem"
    },
    id: "lastName",
    label: t("last-name"),
    type: "text",
    value: lastName,
    onChange: value => {
      dispatch({
        type: UserActionsTypes.USER_SET_FIELD,
        payload: {
          lastName: value
        }
      });
    }
  }), __cssprop(Selector, {
    css: {
      "marginBottom": "1.6rem"
    },
    label: t("language"),
    options: LANGUAGE_OPTIONS,
    selectedItem: getSelectedOptions(LANGUAGE_OPTIONS, language),
    onChange: value => {
      dispatch({
        type: UserActionsTypes.USER_SET_FIELD,
        payload: {
          language: value.value
        }
      });
    }
  }), __cssprop("div", {
    css: {
      "marginBottom": "1.6rem"
    }
  }, __cssprop(PhoneInput, {
    css: {
      "marginBottom": "1.6rem"
    },
    label: t("primary-phone"),
    value: primaryPhone,
    onChange: value => {
      dispatch({
        type: UserActionsTypes.USER_SET_FIELD,
        payload: {
          primaryPhone: value
        }
      });
    },
    zIndex: 25
  })), __cssprop("div", {
    css: {
      "marginBottom": "1.6rem"
    }
  }, __cssprop(PhoneInput, {
    label: t("secondary-phone"),
    value: secondaryPhone,
    onChange: value => {
      dispatch({
        type: UserActionsTypes.USER_SET_FIELD,
        payload: {
          secondaryPhone: value
        }
      });
    },
    zIndex: 24
  })), __cssprop(TextField, {
    id: "email",
    label: t("email"),
    type: "text",
    value: email,
    onChange: value => {
      dispatch({
        type: UserActionsTypes.USER_SET_FIELD,
        payload: {
          email: value
        }
      });
    }
  })), __cssprop(Card.Section, {
    greyed: true
  }, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("profile-notification")), __cssprop(StyledSwitch, {
    checked: notificationEnabled,
    onChange: v => {
      dispatch({
        type: UserActionsTypes.USER_SET_FIELD,
        payload: {
          notificationEnabled: !v
        }
      });
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Use setting"), __cssprop(StyledSpan, {
    "aria-hidden": "true",
    active: notificationEnabled
  }))))))));
};
export default Profile; // title={t("profile-about-you")} description={t("profile-shared-to-all-user")}
// title={t("profile-system-notification")}