import { FullAmenitiesPicker } from "../../../../components/Tailwind/Amenities/FullAmenitiesPicker.js";
import { Card } from "../../../../components/Tailwind/Card.js";
import { DescriptionLayoutItem } from "../../../../components/Tailwind/Layout.js";
import useI18n from "../../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../../store/artifacts/Building/types.js";
import React, { useState } from "../../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";
export const AmenitiesSection = () => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const [errors, setError] = useState({});
  const amenities = useSelector(Selectors.building.getAmenities);

  const clearErrors = () => {
    setError({});
  };

  const handleErrors = (item, errorMessage) => {
    setError(pValue => {
      return { ...pValue,
        [item]: errorMessage
      };
    });
  };

  return __cssprop(DescriptionLayoutItem, {
    title: t("amenities-title"),
    description: t("amenities-description")
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(FullAmenitiesPicker, {
    amenities: amenities || [],
    resourceType: "Building",
    setHookError: handleErrors,
    error: errors,
    clearErrors: clearErrors,
    dispatchType: BuildingActionsTypes.BUILDING_SET_FIELD
  }))));
};