import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { useParams } from "../../../../_snowpack/pkg/react-router.js";
import { Loading } from "../../components/Icons.js";
import { CreateTicketPanel } from "../../components/Panels/CreateTicketPanel.js";
import { ProviderPanel } from "../../components/Panels/ProviderPanel.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { EventActionTypes } from "../../store/artifacts/Events/types.js";
import { ProviderActionTypes } from "../../store/artifacts/Provider/types.js";
import EventMain from "./components/EventMain/EventMain.js";
import EventSide from "./components/EventSide/EventSide.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const Event = () => {
  const [isProviderPanelOpen, setIsProviderPanelOpen] = useState(false);
  const {
    id
  } = useParams();
  const {
    dispatch
  } = useAction();
  const eventStatus = useSelector(Selectors.status.getEventStatus);
  useEffect(() => {
    if (id) {
      dispatch({
        type: EventActionTypes.EVENT_INITIALIZE,
        payload: {
          id
        }
      });
    }

    return () => {
      dispatch({
        type: [[EventActionTypes.EVENT_CLEAR_STATE]]
      });
    };
  }, [id]);

  const handleSubmitTicket = data => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        entityMetadata: { ...data
        }
      }
    });
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });

    if (id) {
      dispatch({
        type: EventActionTypes.EVENT_UPDATE,
        payload: {
          entityMetadata: { ...data
          }
        }
      });
    }
  };

  const handleSubmitProvider = data => {
    dispatch({
      type: ProviderActionTypes.PROVIDER_EDIT,
      payload: {
        id,
        provider: { ...data
        }
      }
    });
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
    dispatch({
      type: ProviderActionTypes.PROVIDER_CLEAR_STATE
    });
  };

  if (eventStatus === "Loading") {
    return __cssprop("div", null, __cssprop(Loading, null));
  }

  return __cssprop(React.Fragment, null, __cssprop(CreateTicketPanel, {
    onDoneSubmit: handleSubmitTicket
  }), __cssprop(ProviderPanel, {
    refetch: () => {
      setIsProviderPanelOpen(false);
    },
    handleOnClose: () => setIsProviderPanelOpen(false),
    isOpen: isProviderPanelOpen
  }), __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(12, minmax(0, 1fr))",
      "width": "100%",
      "overflowY": "scroll"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 8 / span 8"
    }
  }, __cssprop(EventMain, null)), __cssprop("div", {
    css: {
      "gridColumn": "span 4 / span 4"
    }
  }, __cssprop(EventSide, null))));
};

export default Event;