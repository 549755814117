import AddEventSideBar from "../../forms/SideBar/AddEventSideBar.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { EventActionTypes } from "../../store/artifacts/Events/types.js";
import React from "../../../../_snowpack/pkg/react.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const AddEventPanel = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const eventId = useSelector(Selectors.event.getId);
  const activeDrawer = useSelector(Selectors.app.getDrawerState);

  const handleClose = () => {
    dispatch({
      type: EventActionTypes.EVENT_CLEAR_STATE
    });
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
  };

  const isDrawerOpen = activeDrawer === "event-create";
  return __cssprop(SlideOver, {
    title: eventId ? t("events-page-edit-button") : t("events-page-create-button"),
    isOpen: isDrawerOpen,
    onClose: handleClose
  }, __cssprop(AddEventSideBar, null));
};