import { createReducer } from "../../helpers/actions.js";
import { initialState, SessionActionsTypes } from "./types.js";

const onSetField = (data, state) => ({ ...state,
  ...data.payload
});

const onSetUserField = (data, state) => ({ ...state,
  user: { ...state.user,
    ...data.payload
  }
});

const onUserLogout = (_data, _state) => initialState;

export default createReducer(initialState, {
  [SessionActionsTypes.SESSION_SET_FIELD]: onSetField,
  [SessionActionsTypes.SESSION_SET_USER_FIELD]: onSetUserField,
  [SessionActionsTypes.ON_USER_LOGOUT]: onUserLogout
});