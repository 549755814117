import React, { useCallback } from "../../../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../../../_snowpack/pkg/react-router.js";
import { Link } from "../../Tailwind/Link.js";
import { useAction } from "../../../hooks/useRedux.js";
import { TicketActionTypes } from "../../../store/artifacts/Tickets/types.js";
import useI18n from "../../../hooks/useI18n.js";
import { getTicketStatusBadge } from "../../../views/Tickets/components/TicketStatusBadge.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const TicketInfoRelatedTickets = ({
  data
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const history = useHistory(); // createdAt: DateTime.fromISO(createdAt).toRelative(),

  const handleRelatedTicket = id => () => {
    dispatch({
      type: TicketActionTypes.TICKET_INITIALIZE,
      payload: {
        kind: "WithTicketId",
        ticketId: id
      }
    });
    history.push(`/ticket/${id}`);
  };

  const dateDisplay = useCallback((createdAt, closedAt, deletedAt) => {
    if (deletedAt) return `${t("tickets-info-general-deleted-on")} ${DateTime.fromISO(deletedAt).toUTC().toISODate()}`;
    if (closedAt) return `${t("tickets-info-general-closed-on")} ${DateTime.fromISO(closedAt).toUTC().toISODate()}`;
    return `${t("tickets-info-general-opened-on")} ${DateTime.fromISO(createdAt).toUTC().toISODate()}`;
  }, []);
  return __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column"
    }
  }, data?.map(ticket => __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(3, minmax(0, 1fr))",
      "marginTop": "0.4rem",
      "marginBottom": "0.4rem"
    }
  }, __cssprop(Link, {
    key: ticket.id,
    text: ticket?.title,
    action: {
      kind: "ClickAction",
      event: handleRelatedTicket(ticket.id)
    }
  }), __cssprop("div", null, getTicketStatusBadge({
    deletedAt: ticket.deletedAt,
    closedAt: ticket.closedAt
  }, t)), __cssprop("div", null, dateDisplay(ticket.createdAt, ticket.closedAt, ticket.deletedAt)))));
};

export default TicketInfoRelatedTickets;