import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const initialState = {
  audits: {
    items: []
  },
  bedrooms: 0,
  squareFootage: 0,
  building: null,
  buildingAddress: "",
  buildingId: "",
  id: "",
  subAddress: "",
  unitType: "apartment",
  amenities: [],
  files: [],
  floor: 0,
  listing: null,
  notes: [],
  rentalRequest: null,
  responsible: null,
  waterTankInstallationDate: DateTime.utc(),
  property: null,
  propertyIssue: {
    unit: [],
    building: []
  },
  currentLease: {},
  leases: [],
  tickets: {
    items: []
  }
};
// =========== ACTIONS CREATORS =========== //
export let UnitActionsTypes;

(function (UnitActionsTypes) {
  UnitActionsTypes["UNIT_INITIALIZE"] = "UNIT_INITIALIZE";
  UnitActionsTypes["UNIT_SUBMIT"] = "UNIT_SUBMIT";
  UnitActionsTypes["UNIT_SET_FIELD"] = "UNIT_SET_FIELD";
  UnitActionsTypes["UNIT_SET_SERVER_UNIT_STATUS"] = "UNIT_SET_SERVER_UNIT_STATUS";
  UnitActionsTypes["UNIT_ON_SUBMIT_FORM"] = "UNIT_ON_SUBMIT_FORM";
  UnitActionsTypes["UNIT_SET_SUBMISSION_STATUS"] = "UNIT_SET_SUBMISSION_STATUS";
  UnitActionsTypes["UNIT_ADD_FILES"] = "UNIT_ADD_FILES";
  UnitActionsTypes["UNIT_CREATE"] = "UNIT_CREATE";
  UnitActionsTypes["UNIT_ADD_NOTES"] = "UNIT_ADD_NOTES";
  UnitActionsTypes["UNIT_REMOVE_NOTES"] = "UNIT_REMOVE_NOTES";
  UnitActionsTypes["UNIT_ADD_NOTES_FORMLESS"] = "UNIT_ADD_NOTES_FORMLESS";
  UnitActionsTypes["UNIT_REMOVE_NOTES_FORMLESS"] = "UNIT_REMOVE_NOTES_FORMLESS";
})(UnitActionsTypes || (UnitActionsTypes = {}));