import { DescriptionList } from "../../../components/Tailwind/DescriptionList.js";
import { TextField } from "../../../components/Tailwind/TextField.js";
import useI18n from "../../../hooks/useI18n.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { Controller, useFormContext } from "../../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const VideoField = () => {
  const {
    t
  } = useI18n();
  const {
    control
  } = useFormContext();
  return __cssprop(DescriptionList.Item, {
    title: t("rental-video-link-title"),
    inline: true
  }, __cssprop(Controller, {
    name: "videoLink",
    control: control,
    render: ({
      field
    }) => __cssprop(TextField, {
      id: "videoUrl",
      onChange: field.onChange,
      defaultValue: field.value,
      placeholder: t("rental-video-link-place-holder")
    })
  }));
};