import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { useLazyQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { Loader } from "../../components/Loader.js";
import { ProtectedLink } from "../../components/Tailwind/ProtectedComponents.js";
import { GetDeliveryLeaseDetailsDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { TenantActionsTypes } from "../../store/artifacts/Tenant/types.js";
import { formatPhoneNumber } from "../../utils/tenant.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../../_snowpack/pkg/react-router.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);
export const TenantSideBar = props => {
  const {
    leaseId
  } = props;
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const history = useHistory();
  const [isReady, setIsReady] = useState(false);
  const [currentTenants, setCurrentTenants] = useState([]);
  const [pastTenants, setPastTenants] = useState([]);
  const [loadDeliveryLeaseDetails, {
    loading: queryLoading,
    data: leaseData
  }] = useLazyQuery(GetDeliveryLeaseDetailsDocument, {
    variables: {
      id: leaseId
    },
    onCompleted: data => {
      const sorted = [...data.lease.unit.leases.items] // Need to create a new array, since filter mutates the original one and apollo cache doesn't like that
      .sort((a, b) => {
        return DateTime.fromISO(b.endDate).toMillis() - DateTime.fromISO(a.endDate).toMillis();
      }).filter(a => DateTime.fromISO(a.endDate).toMillis() < DateTime.fromISO(data.lease.endDate).toMillis() || data.lease.id !== leaseId) || [];
      setPastTenants(sorted[0]?.tenants.items.map(({
        tenant
      }) => ({ ...tenant,
        startDate: sorted[0].startDate,
        endDate: sorted[0].endDate
      })) || []);
      setCurrentTenants(data.lease?.tenants.items.map(({
        tenant
      }) => tenant));
      setIsReady(true);
    }
  });
  useEffect(() => {
    if (!leaseId) {
      return;
    }

    if (isReady) {
      return;
    }

    loadDeliveryLeaseDetails();
  }, [leaseId, isReady]);

  if (queryLoading || !isReady || !leaseId) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  return __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginBottom": "0.8rem"
    }
  }, t("tenant")), __cssprop("div", {
    css: {
      "position": "relative",
      "display": "grid",
      "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
      "gap": "0.8rem",
      "@media (min-width: 640px)": {
        "gap": "1.2rem",
        "paddingLeft": "1.2rem",
        "paddingRight": "1.2rem"
      }
    }
  }, __cssprop("div", null, t("contract-form-start-date"), ": ", __cssprop("b", null, DateTime.fromISO(leaseData.lease.startDate).toUTC().toFormat("MMMM dd, yyyy"))), __cssprop("div", null, t("contract-form-end-date"), ": ", __cssprop("b", null, DateTime.fromISO(leaseData.lease.endDate).toUTC().toFormat("MMMM dd, yyyy")))), currentTenants.map(ct => __cssprop("div", {
    css: {
      "position": "relative",
      "display": "grid",
      "gap": "0.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "@media (min-width: 640px)": {
        "gap": "1.2rem",
        "padding": "1.2rem"
      }
    }
  }, ct?.name && __cssprop("div", null, t("name"), ": ", __cssprop("b", null, ct.name)), ct?.phoneInfo?.primaryPhone && __cssprop("div", null, t("phone"), ": ", __cssprop("b", null, formatPhoneNumber(ct.phoneInfo.primaryPhone))), ct?.email && __cssprop("div", null, t("email"), ": ", __cssprop("b", null, ct.email)), __cssprop("div", null, __cssprop("div", null, __cssprop(ProtectedLink, {
    permission: {
      kind: "Tenant",
      permission: "view:info"
    },
    text: t("see-details-for", {
      for: ct.name
    }),
    action: {
      kind: "ClickAction",
      event: () => {
        if (ct?.id) {
          dispatch({
            type: TenantActionsTypes.TENANT_INITIALIZE,
            payload: {
              kind: "WithTenantId",
              tenantId: ct.id
            }
          });
          history.push(`/tenants/${ct.id}`);
        }
      }
    }
  }))))), __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginBottom": "0.8rem",
      "marginTop": "1.6rem"
    }
  }, t("previous-tenant")), pastTenants.length === 0 && __cssprop("h2", null, t("empty-state-past-tenant-description")), pastTenants.map(ct => __cssprop("div", {
    css: {
      "position": "relative",
      "display": "grid",
      "gap": "0.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "@media (min-width: 640px)": {
        "gap": "1.2rem",
        "padding": "1.2rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "position": "relative",
      "display": "grid",
      "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
      "gap": "0.8rem",
      "@media (min-width: 640px)": {
        "gap": "1.2rem"
      }
    }
  }, __cssprop("div", null, t("contract-form-start-date"), ": ", __cssprop("b", null, DateTime.fromISO(ct.startDate).toUTC().toFormat("MMMM dd, yyyy"))), __cssprop("div", null, t("contract-form-end-date"), ": ", __cssprop("b", null, DateTime.fromISO(ct.endDate).toUTC().toFormat("MMMM dd, yyyy")))), ct?.name && __cssprop("div", null, __cssprop("div", null, t("name"), ": ", __cssprop("b", null, ct.name))), ct?.phoneInfo?.primaryPhone && __cssprop("div", null, __cssprop("div", null, t("phone"), ": ", __cssprop("b", null, formatPhoneNumber(ct.phoneInfo.primaryPhone)))), ct?.email && __cssprop("div", null, __cssprop("div", null, t("email"), ": ", __cssprop("b", null, ct.email))), __cssprop("div", null, __cssprop("div", null, __cssprop(ProtectedLink, {
    permission: {
      kind: "Tenant",
      permission: "view:info"
    },
    text: t("see-details-for", {
      for: ct.name
    }),
    action: {
      kind: "ClickAction",
      event: () => {
        if (ct?.id) {
          dispatch({
            type: TenantActionsTypes.TENANT_INITIALIZE,
            payload: {
              kind: "WithTenantId",
              tenantId: ct.id
            }
          });
          history.push(`/tenants/${ct.id}`);
        }
      }
    }
  }))))));
};
export default TenantSideBar;