import { ApplicantStatus, FeatureType } from "../graphql/documents.js";
import { isValidPhoneNumber } from "../../../_snowpack/pkg/libphonenumber-js.js";
import { DateTime } from "../../../_snowpack/pkg/luxon.js";
export function Sluggify(value) {
  return value.split(" ").join("-");
}
export const validatePhoneNumber = (phoneNumber, helpers) => {
  if (phoneNumber === null) {
    return null;
  }

  if (!isValidPhoneNumber(phoneNumber)) {
    return helpers.error("any.invalid");
  }

  return phoneNumber;
};
export const cleanupPhoneNumber = phoneNumber => {
  if (phoneNumber === "+1") {
    return null;
  }

  return phoneNumber;
}; // give object with key message for showing errors

export const showError = (errors, id, t) => {
  if (errors[id]) {
    return {
      errorMessage: t(errors[id])
    };
  }

  return undefined;
};
export const filterAmenity = (selectedItems, t) => {
  return Object.entries(FeatureType).map(([key, value]) => ({
    key,
    value: t(key),
    category: "Amenities"
  })).filter(x => !selectedItems.find(input => {
    return input.key === x.key;
  })).reduce((acc, item) => {
    const foundItem = acc.find(i => i.groupLabel === item.category);

    if (!foundItem) {
      return [...acc, {
        icon: null,
        groupLabel: item.category,
        options: [item]
      }];
    }

    foundItem.options = [...foundItem.options, item];
    return acc;
  }, []);
};
export const getCurrentPassFromPasses = (passes, referentDate = DateTime.utc()) => {
  return passes.find(p => {
    return DateTime.fromISO(p.endDate).toUTC() >= referentDate;
  });
};
export const getScoreText = (slScore, t) => {
  let scoreText = "NA";
  if (slScore && !Number.isNaN(Number(slScore))) scoreText = `${slScore} / 100`;else if (Number.isNaN(Number(slScore))) scoreText = t("no-hit");
  return scoreText;
};
export const getRentalRequestStatus = (item, t) => {
  if (item.completedAt) {
    return t("status-completed");
  }

  if (item.rejected) {
    return t("applicant-request-status-denied");
  }

  if (item.applicants.every(a => {
    return a.status === ApplicantStatus.CreditCheckComplete || a.status === ApplicantStatus.IsManuallyAdded;
  })) {
    return t("tenant-tab-active");
  }

  return t("applicant-request-status-incomplete");
};
export const getAddressFromUnit = unit => {
  let address = "";

  if (!unit) {
    return address;
  }

  if (!unit.subAddress && unit.buildingAddress) {
    address = `${unit.buildingAddress}`;
  }

  if (unit.subAddress && unit.buildingAddress) {
    address = `${unit.subAddress} - ${unit.buildingAddress}`;
  }

  return address;
};
export default Sluggify;