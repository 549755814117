import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { mapFiles } from "../../utils/Files.js";
import { initialState, TicketActionTypes } from "./types.js";
export const setTicketField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};
export const setTicketInitialState = state => {
  return { ...state,
    ...initialState
  };
};
export const addTicketNotes = (data, state) => {
  return { ...state,
    notes: [...state.notes, ...data.payload.map(t => ({ ...t,
      kind: "remote"
    }))]
  };
};
export const removeTicketNotes = (data, state) => {
  return { ...state,
    notes: state.notes.filter(note => note.id !== data.payload)
  };
};
export const addTicketFiles = (data, state) => {
  return { ...state,
    files: [...state.files, ...data.payload]
  };
};
export const removeTicketFiles = (data, state) => {
  return { ...state,
    files: mapFiles(state.files.filter(file => file.fileName !== data.payload.fileName))
  };
};
export const closeTask = (data, state) => {
  const {
    taskId
  } = data.payload;
  const newTasks = [...state.tasks.items];
  const task = newTasks.find(t => t.id === taskId);
  task.closedAt = DateTime.utc().toISO();
  newTasks.splice(newTasks.indexOf(newTasks.find(t => t.id === taskId)), 1, task);
  return { ...state,
    tasks: {
      items: newTasks
    }
  };
};
export const removeConversationFiles = (data, state) => {
  const {
    files,
    conversationId
  } = data.payload;
  return { ...state,
    conversations: {
      items: state.conversations.items.map(item => item.id !== conversationId ? item : { ...item,
        files
      })
    }
  };
};
export default {
  [TicketActionTypes.TICKET_SET_INITIAL_STATE]: setTicketInitialState,
  [TicketActionTypes.TICKET_SET_FIELD]: setTicketField,
  [TicketActionTypes.TICKET_ADD_NOTES]: addTicketNotes,
  [TicketActionTypes.TICKET_REMOVE_NOTES]: removeTicketNotes,
  [TicketActionTypes.TICKET_ADD_FILES]: addTicketFiles,
  [TicketActionTypes.TICKET_REMOVE_FILES]: removeTicketFiles,
  [TicketActionTypes.TICKET_CLOSE_TASK_REDUCER]: closeTask,
  [TicketActionTypes.TICKET_CONVERSATION_REMOVE_FILES]: removeConversationFiles
};