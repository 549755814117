import Joi from "../../../_snowpack/pkg/@hapi/joi.js";
export const UNIT_TYPES = t => ({
  APARTMENT: t("unit-form-unit-type-apartment"),
  CONDO: t("unit-form-unit-type-condo"),
  HOUSE: t("unit-form-unit-type-house"),
  STUDIO: t("unit-form-unit-type-studio"),
  ROOM: t("unit-form-unit-type-room"),
  OTHER: t("unit-form-unit-type-other"),
  COMMERCIAL: t("unit-form-unit-type-commercial")
});
export const UNIT_HEATING_TYPES = t => ({
  ELECTRICITY: t("unit-form-heating-type-electricity"),
  GAS: t("unit-form-heating-type-gas"),
  OIL: t("unit-form-heating-type-oil"),
  OTHER: t("unit-form-heating-type-other")
});
export const UNIT_FLOORS = t => ({
  Basement: t("unit-form-floor-basement"),
  SemiBasement: t("unit-form-floor-semi-basement"),
  Ground: t("unit-form-floor-ground"),
  First: t("unit-form-floor-first"),
  Second: t("unit-form-floor-second"),
  Third: t("unit-form-floor-third"),
  Fourth: t("unit-form-floor-fourth"),
  Fifth: t("unit-form-floor-fifth"),
  Sixth: t("unit-form-floor-sixth"),
  Seventh: t("unit-form-floor-seventh"),
  Eighth: t("unit-form-floor-eighth"),
  Ninth: t("unit-form-floor-ninth"),
  Tenth: t("unit-form-floor-tenth"),
  null: "Unknown",
  undefined: "Unknown"
});
export let UNIT_FLOORS_ENUM;

(function (UNIT_FLOORS_ENUM) {
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Basement"] = -2] = "Basement";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["SemiBasement"] = -1] = "SemiBasement";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Ground"] = 0] = "Ground";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["First"] = 1] = "First";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Second"] = 2] = "Second";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Third"] = 3] = "Third";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Fourth"] = 4] = "Fourth";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Fifth"] = 5] = "Fifth";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Sixth"] = 6] = "Sixth";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Seventh"] = 7] = "Seventh";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Eighth"] = 8] = "Eighth";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Ninth"] = 9] = "Ninth";
  UNIT_FLOORS_ENUM[UNIT_FLOORS_ENUM["Tenth"] = 10] = "Tenth";
})(UNIT_FLOORS_ENUM || (UNIT_FLOORS_ENUM = {}));

export const UNIT_FLOOR_SELECT_OPTIONS = t => [{
  label: t("unit-form-floor-basement"),
  value: -2
}, {
  label: t("unit-form-floor-semi-basement"),
  value: -1
}, {
  label: t("unit-form-floor-ground"),
  value: 0
}, {
  label: t("unit-form-floor-first"),
  value: 1
}, {
  label: t("unit-form-floor-second"),
  value: 2
}, {
  label: t("unit-form-floor-third"),
  value: 3
}, {
  label: t("unit-form-floor-fourth"),
  value: 4
}, {
  label: t("unit-form-floor-fifth"),
  value: 5
}, {
  label: t("unit-form-floor-sixth"),
  value: 6
}, {
  label: t("unit-form-floor-seventh"),
  value: 7
}, {
  label: t("unit-form-floor-eighth"),
  value: 8
}, {
  label: t("unit-form-floor-ninth"),
  value: 9
}, {
  label: t("unit-form-floor-tenth"),
  value: 10
}];
export let UNIT_BEDROOMS;

(function (UNIT_BEDROOMS) {
  UNIT_BEDROOMS[UNIT_BEDROOMS["1 1/2"] = 1] = "1 1/2";
  UNIT_BEDROOMS[UNIT_BEDROOMS["2 1/2"] = 2] = "2 1/2";
  UNIT_BEDROOMS[UNIT_BEDROOMS["3 1/2"] = 3] = "3 1/2";
  UNIT_BEDROOMS[UNIT_BEDROOMS["4 1/2"] = 4] = "4 1/2";
  UNIT_BEDROOMS[UNIT_BEDROOMS["5 1/2"] = 5] = "5 1/2";
  UNIT_BEDROOMS[UNIT_BEDROOMS["6 1/2"] = 6] = "6 1/2";
})(UNIT_BEDROOMS || (UNIT_BEDROOMS = {}));

export const LEASE_BILLING = t => ({
  Cash: t("leasing-form-billing-cash"),
  Check: t("leasing-form-billing-check"),
  CreditCard: t("leasing-form-billing-credit-card"),
  DirectDebit: t("leasing-form-billing-direct-debit"),
  ETransfer: t("leasing-form-billing-eTransfer")
});
export const LEASE_BILLING_SELECT_OPTIONS = t => [{
  label: t("leasing-form-billing-cash"),
  value: "Cash"
}, {
  label: t("leasing-form-billing-check"),
  value: "Check"
}, {
  label: t("leasing-form-billing-credit-card"),
  value: "CreditCard"
}, {
  label: t("leasing-form-billing-direct-debit"),
  value: "DirectDebit"
}, {
  label: t("leasing-form-billing-eTransfer"),
  value: "ETransfer"
}];
export const UNIT_TYPE_SELECT_OPTIONS = t => [{
  label: t("unit-form-unit-type-apartment"),
  value: "apartment"
}, {
  label: t("unit-form-unit-type-condo"),
  value: "condo"
}, {
  label: t("unit-form-unit-type-house"),
  value: "house"
}, {
  label: t("unit-form-unit-type-studio"),
  value: "studio"
}, {
  label: t("unit-form-unit-type-room"),
  value: "room"
}, {
  label: t("unit-form-unit-type-other"),
  value: "other"
}, {
  label: t("unit-form-unit-type-commercial"),
  value: "commercial"
}];
export const UNIT_HEATING_TYPES_SELECT_OPTIONS = t => [{
  label: t("unit-form-heating-type-electricity"),
  value: "Electricity"
}, {
  label: t("unit-form-heating-type-gas"),
  value: "Gas"
}, {
  label: t("unit-form-heating-type-oil"),
  value: "Oil"
}, {
  label: t("unit-form-heating-type-other"),
  value: "Other"
}];
export const UNIT_BEDROOMS_SELECT_OPTIONS = [{
  label: "1 ½",
  value: 1
}, {
  label: "2 ½",
  value: 2
}, {
  label: "3 ½",
  value: 3
}, {
  label: "4 ½",
  value: 4
}, {
  label: "5 ½",
  value: 5
}, {
  label: "6 ½",
  value: 6
}];
export const UNIT_STATUS_SELECT_OPTIONS = [{
  label: "Tenant Leaving",
  value: "TenantLeaving"
}, {
  label: "Tenant To Renew",
  value: "TenantToRenew"
}, {
  label: "Tenant Changing",
  value: "TenantChanging"
}, {
  label: "Occupied",
  value: "Occupied"
}, {
  label: "Vacant",
  value: "Vacant"
}, {
  label: "Reserved",
  value: "Reserved"
}];
export const UNIT_TICKET_PRIORITY_SELECT_OPTIONS = [{
  label: "Low",
  value: "low"
}, {
  label: "Medium",
  value: "medium"
}, {
  label: "High",
  value: "high"
}];
export const UNIT_TICKET_STATUS_SELECT_OPTIONS = [{
  label: "Active",
  value: "active",
  defaults: true
}, {
  label: "Closed",
  value: "closed"
}, {
  label: "Deleted",
  value: "deleted"
}];
export const UNIT_BEDROOMS_SELECT_OPTIONS_RENTAL = [{
  label: "1 ½",
  value: "studio"
}, {
  label: "2 ½",
  value: "2 1/2"
}, {
  label: "3 ½",
  value: "3 1/2"
}, {
  label: "4 ½",
  value: "4 1/2"
}, {
  label: "5 ½",
  value: "5 1/2"
}, {
  label: "6 ½",
  value: "6 1/2"
}];
export const UNIT_PARKING_TYPE = [{
  label: "Intérieur",
  value: "interior"
}, {
  label: "Extérieur",
  value: "exterior"
}, {
  label: "Garage",
  value: "garage"
}];
export const UNIT_COUNTER_TYPE = t => [{
  label: t("rental-inclusion-counter-melamine"),
  value: "melamine"
}, {
  label: t("rental-inclusion-counter-granite"),
  value: "granite"
}, {
  label: t("rental-inclusion-counter-quartz"),
  value: "quartz"
}, {
  label: t("rental-inclusion-counter-other"),
  value: "other"
}, {
  label: t("rental-inclusion-counter-unspecified"),
  value: "unspecified"
}];
export const PROVINCE = {
  Québec: "QC",
  Quebec: "QC",
  QC: "QC",
  Ontario: "ON",
  ON: "ON"
};
export const isRentableUnitGQL = unit => {
  if (!unit) {
    return false;
  } // eslint-disable-next-line no-underscore-dangle


  return unit?.__typename === "RentableUnitGQL";
};
export const joiErrorReducer = array => {
  if (!array) return {};
  return array.reduce((acc, {
    context,
    message,
    path
  }) => {
    if (context.label === context.key) {
      acc[path[0]] = message;
      return acc;
    }

    if (context.peersWithLabels) {
      // eslint-disable-next-line array-callback-return
      context.peersWithLabels.map((val, index) => {
        acc[context.peers[index]] = val;
      });
      return acc;
    }

    acc[path[0]] = context.label;
    return acc;
  }, {});
};
export const validateUnitAndBuilding = (unit, building) => {
  const mappedUnit = {
    unitType: unit.unitType,
    subAddress: unit.subAddress,
    bedrooms: unit.bedrooms,
    floor: unit.floor,
    squareFootage: unit.squareFootage,
    waterTankInstallationDate: unit.waterTankInstallationDate
  };
  const mappedBuilding = {
    civicNumber: building.civicNumber.toString(),
    streetName: building.streetName,
    city: building.city,
    postalCode: building.postalCode,
    sector: building.sector,
    province: building.province,
    buildDate: building.buildDate,
    purchaseDate: building.purchaseDate,
    country: building.country,
    latitude: building.latitude,
    longitude: building.longitude
  };
  const unitSchema = Joi.object({
    unitType: Joi.string().allow("apartment", "condo", "house", "studio", "room", "other", "commercial").required().label("unit-info-section-error-unit-type"),
    subAddress: Joi.string().required().alphanum().label("unit-info-section-error-sub-address"),
    bedrooms: Joi.number().integer().positive().required().label("unit-info-section-error-bedroom"),
    floor: Joi.number().integer().required().label("unit-info-section-error-floor"),
    squareFootage: Joi.number().integer().positive().required().label("unit-info-section-error-square-footage"),
    waterTankInstallationDate: Joi.date().allow(null).label("unit-info-section-error-water-tank-date")
  }).options({
    abortEarly: false
  });
  const buildingSchema = Joi.object({
    civicNumber: Joi.string().required().label("building-form-error-civic"),
    streetName: Joi.string().required().label("building-form-error-address"),
    city: Joi.string().required().label("building-form-error-city"),
    postalCode: Joi.string().pattern(/^([a-z]\d[a-z][ ]?\d[a-z]\d)$/i).required().label("building-form-error-postal-code"),
    sector: Joi.string().allow("", null).label("building-form-error-sector"),
    province: Joi.string().valid("QC", "ON").required().label("building-form-error-province"),
    buildDate: Joi.date().required().label("building-form-error-build-date"),
    purchaseDate: Joi.date().label("building-form-error-purchase-date"),
    country: Joi.string(),
    latitude: Joi.number(),
    longitude: Joi.number()
  }).options({
    abortEarly: false
  });
  const validatedUnit = unitSchema.validate(mappedUnit);
  const validatedBuilding = buildingSchema.validate(mappedBuilding);
  const buildingResults = joiErrorReducer(validatedBuilding?.error?.details);
  const unitResults = joiErrorReducer(validatedUnit?.error?.details);
  const bArray = Object.keys(buildingResults);

  if (bArray.includes("latitude") || bArray.includes("longitude")) {
    delete buildingResults.latitude;
    delete buildingResults.longitude;
    buildingResults.pin = "add-pin-to-building";
  }

  return {
    building: Object.values(buildingResults),
    unit: Object.values(unitResults)
  };
};
export const getBedroomsSize = bedrooms => {
  console.log(bedrooms);

  if (bedrooms - 2 < 1) {
    return 1;
  }

  return bedrooms - 2;
};