import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { Button } from "./Button.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledContainer = _styled.span(({
  image
}) => [{
  "borderRadius": "0.8rem",
  "overflow": "hidden"
}, image && {
  "@media (min-width: 1024px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
    "gap": "1.6rem"
  }
}]);

export const EmptyState = props => {
  const {
    heading,
    description,
    action,
    image
  } = props;
  return __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))"
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "128rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingTop": "3.2rem",
      "paddingBottom": "3.2rem",
      "paddingLeft": "4.8rem",
      "paddingRight": "4.8rem"
    }
  }, __cssprop(StyledContainer, {
    image: image
  }, __cssprop("div", {
    css: {
      "paddingTop": "4rem",
      "paddingBottom": "6.4rem",
      "paddingRight": "3.2rem"
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 1024px)": {
        "alignSelf": "center"
      }
    }
  }, __cssprop("h2", {
    css: {
      "fontSize": "3rem",
      "lineHeight": "3.6rem",
      "fontWeight": "800",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "fontSize": "3.6rem",
        "lineHeight": "4rem"
      }
    }
  }, __cssprop("span", {
    css: {
      "display": "block"
    }
  }, heading)), description && __cssprop("p", {
    css: {
      "marginTop": "1.6rem",
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
    }
  }, description), action && __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop(Button, {
    onAction: action.onAction,
    secondary: true,
    spaced: true
  }, action.content)))), image && __cssprop("div", {
    css: {
      "marginTop": "-2.4rem",
      "--tw-aspect-w": "5",
      "position": "relative",
      "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
      "> *": {
        "position": "absolute",
        "height": "100%",
        "width": "100%",
        "top": "0",
        "right": "0",
        "bottom": "0",
        "left": "0"
      },
      "--tw-aspect-h": "3",
      "@media (min-width: 768px)": {
        "--tw-aspect-w": "2",
        "position": "relative",
        "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
        "> *": {
          "position": "absolute",
          "height": "100%",
          "width": "100%",
          "top": "0",
          "right": "0",
          "bottom": "0",
          "left": "0"
        },
        "--tw-aspect-h": "1"
      }
    }
  }, __cssprop("img", {
    css: {
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "borderRadius": "0.6rem",
      "objectFit": "contain"
    },
    src: image,
    alt: ""
  })))));
};