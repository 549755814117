import ReactTooltip from "../../../../_snowpack/pkg/react-tooltip.js";
import React from "../../../../_snowpack/pkg/react.js";
import { StyledStepContainer, StyledStepCircle, StyledStepCircleContainer, StyledStepCircleName, StyledStepCircleIcon, StyledCircleLine, StyledStepCircleFraction } from "./styles.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Stepper = ({
  steps = [],
  simpleMode = false
}) => {
  const circleRender = ({
    state,
    Icon,
    name,
    fraction
  }) => {
    if (!simpleMode) return __cssprop(StyledStepCircleContainer, null, __cssprop(StyledStepCircle, {
      state: state
    }, Icon && __cssprop(StyledStepCircleIcon, null, __cssprop(Icon, null))), __cssprop(StyledStepCircleName, null, name), fraction && __cssprop(StyledStepCircleFraction, null, fraction[0], "/", fraction[1]));
    return __cssprop("div", {
      "data-tip": name
    }, __cssprop(StyledStepCircleContainer, null, __cssprop(StyledStepCircle, {
      state: state
    }, Icon && __cssprop(StyledStepCircleIcon, null, __cssprop(Icon, null)))));
  };

  return __cssprop(StyledStepContainer, null, steps.map(({
    state,
    Icon,
    name,
    fraction
  }, index) => {
    return __cssprop(React.Fragment, {
      key: `${name}-${index}`
    }, index >= 1 && __cssprop(StyledCircleLine, null), circleRender({
      state,
      name,
      Icon,
      fraction
    }));
  }), __cssprop(ReactTooltip, {
    backgroundColor: "#3f4eae",
    type: "info",
    place: "left"
  }));
};