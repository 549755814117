import * as Sentry from "../../../../../_snowpack/pkg/@sentry/react.js";
import { Selectors } from "../../index.js";
import LogRocket from "../../../../../_snowpack/pkg/logrocket.js";
import { END, eventChannel } from "../../../../../_snowpack/pkg/redux-saga.js";
import { all, select, takeEvery, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { ApplicationActionsTypes } from "./types.js";

function CustomGraphQLError(message = "", name = "") {
  this.name = name;
  this.message = message;
}

CustomGraphQLError.prototype = Error.prototype;

function* handleServerErrors(action) {
  const errors = action.payload;

  if (!errors || errors.length === 0) {
    return;
  }

  errors.forEach(gqlError => {
    Sentry.captureMessage(new CustomGraphQLError(gqlError.message, gqlError.message), {
      contexts: {
        QueryInformation: {
          graphQLErrors: JSON.stringify(gqlError)
        }
      },
      level: Sentry.Severity.Error
    });
  });
}

function countdown(secs) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      if (secs === 0) {
        emitter(secs);
      }
    }, 1000); // The subscriber must return an unsubscribe function

    return () => {
      clearInterval(iv);
      emitter(END);
    };
  });
}

function* updateIntercomUserDetails() {
  const id = yield select(Selectors.session.getUserId);
  const fullName = yield select(Selectors.session.getUserFullname);
  LogRocket.identify(id, {
    name: fullName
  });
} // =========== DEFAULTS =========== //


export default {
  *executers() {
    yield all([yield takeEvery(ApplicationActionsTypes.SERVER_ERRORS, handleServerErrors), yield takeLatest(ApplicationActionsTypes.UPDATE_INTERCOM_USER, updateIntercomUserDetails)]);
  }

};