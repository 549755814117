import { useCallback } from "../../../_snowpack/pkg/react.js";
import { Selectors, useSelector } from "./useRedux.js";
export let PermissionTypeLevel;

(function (PermissionTypeLevel) {
  PermissionTypeLevel[PermissionTypeLevel["System"] = 0] = "System";
  PermissionTypeLevel[PermissionTypeLevel["Owner"] = 1] = "Owner";
  PermissionTypeLevel[PermissionTypeLevel["Admin"] = 2] = "Admin";
  PermissionTypeLevel[PermissionTypeLevel["Manager"] = 3] = "Manager";
  PermissionTypeLevel[PermissionTypeLevel["User"] = 4] = "User";
  PermissionTypeLevel[PermissionTypeLevel["Client"] = 5] = "Client";
  PermissionTypeLevel[PermissionTypeLevel["VisitAgent"] = 6] = "VisitAgent";
  PermissionTypeLevel[PermissionTypeLevel["Provider"] = 7] = "Provider";
})(PermissionTypeLevel || (PermissionTypeLevel = {}));

const PermissionTree = {
  System: {
    Audit: ["view:list"],
    Tenant: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:activeLease", "view:management"],
    Building: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:responsible", "view:management"],
    Lease: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:lease"],
    ApplicantRequest: ["view:listingWebsite", "mutate:create", "mutate:update", "mutate:delete", "view:list", "view:allOtherApplication", "view:notes", "view:files", "view:info", "mutate:visitAgent", "mutate:visitDate", "mutate:sigDate", "mutate:agentApproval"],
    Unit: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Deposit: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Rental: ["mutate:create", "mutate:update", "mutate:delete", "view:published", "view:notes", "view:files", "view:info"],
    Project: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:projectBuilding", "view:projectDeposit"],
    User: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "mutate:profile"],
    File: ["mutate:delete"],
    Reporting: ["mutate:create", "mutate:delete", "mutate:update"],
    Invitation: ["mutate:sendInvite"],
    Ticket: ["view:list", "mutate:create", "mutate:delete", "mutate:update"],
    AuditFeed: ["view:list", "mutate:create"],
    Listing: ["view:list", "mutate:create"],
    Organisation: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:invite", "view:setting"],
    Support: ["view:page", "mutate:create", "mutate:update", "mutate:delete"],
    Notifications: ["view:page", "mutate:create", "mutate:update", "mutate:delete"],
    Payement: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Event: ["view:list", "mutate:create", "mutate:update", "mutate:delete"]
  },
  Owner: {
    Audit: ["view:list"],
    Tenant: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:activeLease", "view:management"],
    Building: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:responsible", "view:management"],
    Lease: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:lease"],
    ApplicantRequest: ["view:listingWebsite", "mutate:create", "mutate:update", "mutate:delete", "view:list", "view:allOtherApplication", "view:notes", "view:files", "view:info", "mutate:visitAgent", "mutate:visitDate", "mutate:sigDate", "mutate:agentApproval"],
    Unit: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Deposit: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Rental: ["mutate:create", "mutate:update", "mutate:delete", "view:published", "view:notes", "view:files", "view:info"],
    Project: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:projectBuilding", "view:projectDeposit"],
    User: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "mutate:profile"],
    File: ["mutate:delete"],
    Reporting: ["mutate:create", "mutate:delete", "mutate:update"],
    Invitation: ["mutate:sendInvite"],
    Ticket: ["view:list", "mutate:create", "mutate:delete", "mutate:update"],
    AuditFeed: ["view:list", "mutate:create"],
    Listing: ["view:list", "mutate:create"],
    Organisation: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:invite", "view:setting"],
    Support: ["view:page", "mutate:create", "mutate:update", "mutate:delete"],
    Notifications: ["view:page", "mutate:create", "mutate:update", "mutate:delete"],
    Payement: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Event: ["view:list", "mutate:create", "mutate:update", "mutate:delete"]
  },
  Admin: {
    Audit: ["view:list"],
    Tenant: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:activeLease", "view:management"],
    Building: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:responsible", "view:management"],
    Lease: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:lease"],
    ApplicantRequest: ["view:listingWebsite", "mutate:create", "mutate:update", "mutate:delete", "view:list", "view:allOtherApplication", "view:notes", "view:files", "view:info", "mutate:visitAgent", "mutate:visitDate", "mutate:sigDate", "mutate:agentApproval"],
    Unit: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Deposit: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Rental: ["mutate:create", "mutate:update", "mutate:delete", "view:published", "view:notes", "view:files", "view:info"],
    Project: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:projectBuilding", "view:projectDeposit"],
    User: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "mutate:profile"],
    File: ["mutate:delete"],
    Reporting: ["mutate:create", "mutate:delete", "mutate:update"],
    Invitation: ["mutate:sendInvite"],
    Ticket: ["view:list", "mutate:create", "mutate:delete", "mutate:update"],
    AuditFeed: ["view:list", "mutate:create"],
    Listing: ["view:list", "mutate:create"],
    Organisation: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:invite", "view:setting"],
    Support: ["view:page", "mutate:create", "mutate:update", "mutate:delete"],
    Notifications: ["view:page", "mutate:create", "mutate:update", "mutate:delete"],
    Payement: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Event: ["view:list", "mutate:create", "mutate:update", "mutate:delete"]
  },
  Manager: {
    Audit: ["view:list"],
    Tenant: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:activeLease", "view:management"],
    Building: ["mutate:create", "mutate:update", "view:list", "view:notes", "view:files", "view:info", "view:responsible", "view:management"],
    Lease: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:lease"],
    ApplicantRequest: ["view:listingWebsite", "mutate:create", "mutate:update", "mutate:delete", "view:list", "view:allOtherApplication", "view:notes", "view:files", "view:info", "mutate:visitAgent", "mutate:visitDate", "mutate:sigDate", "mutate:agentApproval"],
    Unit: ["mutate:create", "mutate:update", "view:list", "view:notes", "view:files", "view:info"],
    Deposit: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Rental: ["mutate:create", "mutate:update", "mutate:delete", "view:published", "view:notes", "view:files", "view:info"],
    Project: ["mutate:create", "mutate:update", "view:list", "view:notes", "view:files", "view:info", "view:projectBuilding", "view:projectDeposit"],
    User: ["mutate:create", "mutate:update", "view:list", "view:notes", "view:files", "view:info", "mutate:profile"],
    File: ["mutate:delete"],
    Reporting: ["mutate:create", "mutate:delete", "mutate:update"],
    Invitation: ["mutate:sendInvite"],
    Ticket: ["view:list", "mutate:create", "mutate:delete", "mutate:update"],
    AuditFeed: ["view:list", "mutate:create"],
    Listing: ["view:list", "mutate:create"],
    Support: ["view:page", "mutate:create", "mutate:update", "mutate:delete"],
    Notifications: ["view:page", "mutate:create", "mutate:update", "mutate:delete"],
    Payement: ["mutate:create", "mutate:update", "mutate:delete", "view:list", "view:notes", "view:files", "view:info"],
    Event: ["view:list", "mutate:create", "mutate:update", "mutate:delete"]
  },
  User: {
    Tenant: ["view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:activeLease"],
    Building: ["view:list", "view:notes", "view:files", "view:info", "view:responsible", "view:management"],
    Lease: ["view:list", "view:notes", "view:files", "view:info", "view:lease", "view:leaseHistory"],
    ApplicantRequest: ["view:listingWebsite"],
    Unit: ["view:list", "view:notes", "view:files", "view:info"],
    Deposit: ["view:list", "view:notes", "view:files", "view:info"],
    Rental: ["view:published", "view:notes", "view:files", "view:info"],
    Project: ["view:list", "view:notes", "view:files", "view:info", "view:projectBuilding", "view:projectDeposit"],
    User: ["view:list", "view:notes", "view:files", "view:info", "mutate:profile"],
    Reporting: [],
    AuditFeed: ["view:list", "mutate:create"],
    Support: ["view:page"],
    Listing: [],
    Organisation: [],
    Notifications: [],
    Payement: ["view:list", "view:notes", "view:files", "view:info"]
  },
  Client: {
    Audit: [],
    ApplicantRequest: ["view:listingWebsite"],
    Tenant: ["view:list", "view:notes", "view:files", "view:info", "view:leaseHistory", "view:activeLease", "view:management"],
    Building: ["view:list", "view:notes", "view:files", "view:info", "view:responsible", "view:management"],
    Lease: ["view:list", "view:notes", "view:files", "view:info", "view:lease", "view:leaseHistory"],
    Unit: ["view:list", "view:notes", "view:files", "view:info"],
    Deposit: ["view:list", "view:notes", "view:files", "view:info"],
    Rental: ["view:published", "view:notes", "view:files", "view:info"],
    Project: ["view:list", "view:notes", "view:files", "view:info", "view:projectBuilding", "view:projectDeposit"],
    User: ["view:list", "view:notes", "view:files", "view:info", "mutate:profile"],
    Reporting: [],
    Listing: [],
    Support: ["view:page"],
    Organisation: [],
    Notifications: [],
    Payement: []
  },
  VisitAgent: {
    Audit: [],
    ApplicantRequest: ["view:listingWebsite", "view:list", "view:info", "mutate:visitDate", "mutate:agentApproval", "view:notes"],
    Tenant: ["view:info"],
    Building: [],
    Lease: [],
    Unit: [],
    Deposit: [],
    Rental: [],
    Project: [],
    User: ["view:info", "mutate:profile"],
    Reporting: [],
    Listing: ["view:list"],
    Support: ["view:page"],
    Organisation: [],
    Notifications: [],
    Payement: []
  }
};
export const canPerform = (userRole, taskPermission) => {
  return PermissionTree[userRole][taskPermission.kind]?.includes(taskPermission?.permission);
};
export function usePermission(permission, output, deniedOutput) {
  const role = useSelector(Selectors.session.getUserRole);
  const permissionCheckedOutput = useCallback(() => {
    if (canPerform(role, permission)) {
      return output;
    }

    return deniedOutput !== undefined ? deniedOutput : null;
  }, [role, output, deniedOutput, permission]);
  return permissionCheckedOutput;
}
export const hasPermissionLevel = (currentRole, minimumRoleNumber) => {
  return PermissionTypeLevel[currentRole] <= minimumRoleNumber;
};