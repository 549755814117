import { gql, useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import useDebounce from "../../hooks/useDebounce.js";
import useI18n from "../../hooks/useI18n.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useCallback, useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import Select from "../../../../_snowpack/pkg/react-select.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
const FETCH_TENANTS = gql`
  query GetTenants($take: Int!, $query: String!, $skip: Int!) {
    tenants(take: $take, searchString: $query, skip: $skip) {
      currentPage
      totalItems
      totalPages
      items {
        id
        email
        name
        phoneInfo {
          primaryPhone
          secondaryPhone
        }
      }
    }
  }
`;

const getSelectedOptionsFromListItem = tenant => {
  const {
    id,
    email,
    name
  } = tenant;
  return {
    value: id,
    label: `${email} (${name})`
  };
};

export const SimpleTenantSelector = props => {
  const {
    t
  } = useI18n();
  const {
    isDisabled = false,
    onChange,
    errors
  } = props;
  const [searchField, setSearchField] = useState("");
  const [options, setOptions] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const debouncedSearch = useDebounce(searchField, 500);
  useQuery(FETCH_TENANTS, {
    variables: {
      take: 100,
      query: debouncedSearch,
      skip: 0
    },
    onCompleted: data => {
      if (!data.tenants) {
        return;
      }

      const tOptions = data.tenants.items.map(tenant => getSelectedOptionsFromListItem(tenant));
      setTenants(tOptions);
    }
  });
  const onValueChange = useCallback(values => {
    if (onChange) {
      onChange(values);
    }

    setSelectedOption(values);
  }, [props, onChange]);
  useEffect(() => {
    const ids = [];
    tenants.forEach(b => {
      ids[b.value] = b.label;
    });
    const finalIds = Object.keys(ids).map(key => {
      return tenants.filter(b => b.value === key)[0];
    });
    setOptions(finalIds);
  }, [tenants, props]);
  return __cssprop(Select, {
    style: {
      width: "100%"
    },
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: errors ? "rgb(222, 53, 11)" : "hsl(0,0%,80%)"
      }),
      valueContainer: styles => ({ ...styles,
        maxHeight: "8em",
        overflow: "auto"
      })
    },
    isClearable: true,
    isMulti: true,
    placeholder: t("tenants"),
    onChange: onValueChange,
    options: options,
    menuContainerStyle: {
      zIndex: 25
    },
    onInputChange: setSearchField,
    value: selectedOption,
    isDisabled: isDisabled
  });
};