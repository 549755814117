import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../../_snowpack/pkg/react.js";
import { Listbox, Transition } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { CheckIcon, ChevronDownIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { css } from "../../../../_snowpack/pkg/@emotion/css.js";
import { AnimateLoadingSvg } from "./Button.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const Option = _styled.div(({
  selected,
  active
}) => [{
  "cursor": "pointer",
  "userSelect": "none",
  "position": "relative",
  "padding": "1.6rem",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "--tw-text-opacity": "1",
  "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
}, selected && {
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))"
}, active && !selected && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
}]);

export const OptionText = _styled.p(({
  selected
}) => [{
  "fontWeight": "400"
}, selected && {
  "fontWeight": "600"
}]);
export const OptionIcon = _styled.span(({
  selected
}) => [{
  "--tw-text-opacity": "1",
  "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
}, selected && {
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
}]);
export const OptionDescription = _styled.p(({
  selected
}) => [{
  "--tw-text-opacity": "1",
  "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
  "marginTop": "0.8rem"
}, selected && {
  "--tw-text-opacity": "1",
  "color": "rgba(199, 210, 254, var(--tw-text-opacity))"
}]);

const DividerContainer = _styled.div(({
  kind
}) => [{
  "position": "relative",
  "zIndex": "20",
  "display": "inline-flex",
  "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "borderRadius": "0.6rem",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-divide-x-reverse": 0,
    "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
    "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))"
  }
}, kind === "Neutral" && {
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-divide-opacity": "1",
    "borderColor": "rgba(75, 85, 99, var(--tw-divide-opacity))"
  }
}, kind === "Positive" && {
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-divide-opacity": "1",
    "borderColor": "rgba(79, 70, 229, var(--tw-divide-opacity))"
  }
}]);

const Container = _styled.div(({
  kind
}) => [{
  "position": "relative",
  "display": "inline-flex",
  "alignItems": "center",
  "paddingTop": "0.8rem",
  "paddingBottom": "0.8rem",
  "paddingLeft": "1.2rem",
  "paddingRight": "1.6rem",
  "borderWidth": "1px",
  "borderColor": "transparent",
  "borderTopLeftRadius": "0.6rem",
  "borderBottomLeftRadius": "0.6rem",
  "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
}, kind === "Neutral" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(107, 114, 128, var(--tw-bg-opacity))"
}, kind === "Positive" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))"
}]);

const Button = _styled.button(({
  kind
}) => [{
  "position": "relative",
  "display": "inline-flex",
  "alignItems": "center",
  "cursor": "pointer",
  "padding": "0.8rem",
  "borderTopLeftRadius": "0px",
  "borderBottomLeftRadius": "0px",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "zIndex": "10",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-width": "2px",
    "--tw-ring-offset-color": "#f9fafb"
  }
}, kind === "Neutral" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(107, 114, 128, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))"
  },
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(107, 114, 128, var(--tw-ring-opacity))"
  }
}, kind === "Positive" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
  },
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
  }
}]);

const ListBoxButton = _styled(Listbox.Button)(({
  kind
}) => [{
  "position": "relative",
  "display": "inline-flex",
  "alignItems": "center",
  "padding": "0.8rem",
  "borderTopLeftRadius": "0px",
  "borderBottomLeftRadius": "0px",
  "borderTopRightRadius": "0.6rem",
  "borderBottomRightRadius": "0.6rem",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "zIndex": "10",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-width": "2px",
    "--tw-ring-offset-color": "#f9fafb"
  }
}, kind === "Neutral" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(107, 114, 128, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))"
  },
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(107, 114, 128, var(--tw-ring-opacity))"
  }
}, kind === "Positive" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
  },
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
  }
}]);

export const ListBox = props => {
  const {
    label,
    selected: selectedOpt,
    options,
    secondaryAction,
    onClick,
    loading
  } = props;
  const setSelectedOption = React.useCallback(opt => {
    props.onClick({
      kind: "MainAction",
      option: opt
    });
  }, [onClick]);
  return __cssprop(Listbox, {
    value: selectedOpt,
    onChange: setSelectedOption
  }, ({
    open
  }) => __cssprop(React.Fragment, null, label && __cssprop(Listbox.Label, {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, label), __cssprop("div", {
    css: {
      "position": "relative"
    }
  }, loading && __cssprop("div", {
    css: {
      "position": "absolute",
      "width": "100%",
      "zIndex": "10",
      "height": "100%",
      "borderRadius": "0.6rem"
    }
  }), __cssprop("div", {
    css: {
      "display": "inline-flex",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "borderRadius": "0.6rem"
    }
  }, __cssprop(DividerContainer, {
    kind: selectedOpt.kind
  }, __cssprop(Container, {
    kind: selectedOpt.kind
  }, loading && __cssprop("div", {
    css: {
      "marginLeft": "1rem"
    }
  }, __cssprop(AnimateLoadingSvg, null)), !loading && selectedOpt.kind === "Positive" && __cssprop(CheckIcon, {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  }), !loading && __cssprop("p", {
    css: {
      "marginLeft": "1rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500"
    }
  }, selectedOpt.display)), secondaryAction && __cssprop(Button, {
    kind: selectedOpt.kind,
    type: "button",
    onClick: () => props.onClick({
      kind: "SecondaryAction"
    })
  }, __cssprop(secondaryAction.icon, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })), __cssprop(ListBoxButton, {
    kind: selectedOpt.kind
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, selectedOpt.display), __cssprop(ChevronDownIcon, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })))), __cssprop(Transition, {
    show: open,
    as: React.Fragment,
    leave: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "100ms"
    }),
    leaveFrom: css({
      "opacity": "1"
    }),
    leaveTo: css({
      "opacity": "0"
    })
  }, __cssprop(Listbox.Options, {
    static: true,
    css: {
      "transformOrigin": "top right",
      "position": "absolute",
      "zIndex": "20",
      "left": "0px",
      "marginTop": "0.8rem",
      "marginRight": "-0.4rem",
      "width": "28.8rem",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "overflow": "hidden",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      },
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "--tw-ring-opacity": "0.05",
      "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      },
      "@media (min-width: 640px)": {
        "left": "auto",
        "right": "0px"
      }
    }
  }, options.map(option => __cssprop(Listbox.Option, {
    key: option.name,
    value: option
  }, ({
    active
  }) => {
    const selected = selectedOpt.name === option.name;
    return __cssprop(Option, {
      selected: selected,
      active: active
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "flexDirection": "column"
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "justifyContent": "space-between"
      }
    }, __cssprop(OptionText, {
      selected: !!selected
    }, option.name), selected && option.kind === "Positive" ? __cssprop(OptionIcon, {
      selected: selected
    }, __cssprop(CheckIcon, {
      css: {
        "height": "2rem",
        "width": "2rem"
      },
      "aria-hidden": "true"
    })) : null), option?.description && __cssprop(OptionDescription, {
      selected: selected
    }, option.description)));
  })))))));
};