/* eslint-disable no-underscore-dangle */
import { createSelector } from "../../../../../_snowpack/pkg/reselect.js";
import Joi from "../../../../../_snowpack/pkg/@hapi/joi.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";

const getBuilding = state => state?.building;

const getStatistics = state => getBuilding(state).statistics;

const getCivicNumber = state => getBuilding(state).civicNumber;

const getStreetName = state => getBuilding(state).streetName;

const getTags = state => getBuilding(state).tags;

const getImage = state => getBuilding(state).image;

const getImageResource = state => getBuilding(state).imageResource;

const getCity = state => getBuilding(state).city;

const getSector = state => getBuilding(state).sector;

const getProvince = state => getBuilding(state).province;

const getPostalCode = state => getBuilding(state).postalCode;

const getCountry = state => getBuilding(state).country;

const getBuildDate = state => DateTime.fromISO(getBuilding(state).buildDate);

const getPurchaseDate = state => DateTime.fromISO(getBuilding(state).purchaseDate);

const getManagementStartDate = state => DateTime.fromISO(getBuilding(state).managementStartDate);

const getProject = state => getBuilding(state).project;

const getProjectId = state => getProject(state)?.id;

const getUnits = state => getBuilding(state)?.units;

const getLatitude = state => getBuilding(state).latitude;

const getLongitude = state => getBuilding(state).longitude;

const getId = state => getBuilding(state)?.id || null;

const getAmenities = state => getBuilding(state).amenities;

const getFiles = state => getBuilding(state).files;

const getNotes = state => getBuilding(state).notes;

const getFilteredNotes = state => getBuilding(state).notes.filter(note => note.kind !== "removed");

const getTickets = state => getBuilding(state).tickets.items;

const getCoords = createSelector(getBuilding, building => ({
  longitude: building.longitude,
  latitude: building.latitude
}));
const getActiveLease = createSelector(getUnits, units => {
  if (units.length > 1) {
    return null;
  }

  return units[0].currentLease;
});
const getErrors = createSelector(getBuilding, building => {
  const schema = Joi.object({
    civicNumber: Joi.number().allow(null).required().label("building-form-error-civic"),
    streetName: Joi.string().allow(null).required().label("building-form-error-address"),
    city: Joi.string().allow(null).required().label("building-form-error-city"),
    postalCode: Joi.string().allow(null).pattern(/^([a-z]\d[a-z][ ]?\d[a-z]\d)$/i).required().label("building-form-error-postal-code"),
    sector: Joi.string().allow("", null).label("building-form-error-sector"),
    province: Joi.string().valid("QC", "ON").required().label("building-form-error-province"),
    buildDate: Joi.any().required().label("building-form-error-build-date"),
    purchaseDate: Joi.any().label("building-form-error-purchase-date"),
    country: Joi.string(),
    latitude: Joi.number(),
    longitude: Joi.number(),
    // project
    project: Joi.object({
      id: Joi.string().required().label("project-form-description"),
      name: Joi.string().label("project-form-description")
    }).unknown(true).label("project-form-description"),
    // projectId: Joi.string().required().label("company-form-error-management-company"),
    managementStartDate: Joi.any().label("company-form-error-start-date")
  }).options({
    abortEarly: false
  }).unknown(true);
  const {
    error
  } = schema.validate(building, {
    abortEarly: false
  });

  if (!error) {
    return {};
  }

  const results = error.details.reduce((acc, {
    context,
    message,
    path
  }) => {
    if (context.label === context.key) {
      acc[path[0]] = message;
      return acc;
    }

    if (context.peersWithLabels) {
      // eslint-disable-next-line array-callback-return
      context.peersWithLabels.map((val, index) => {
        acc[context.peers[index]] = val;
      });
      return acc;
    }

    acc[path[0]] = context.label;
    return acc;
  }, {});
  return results;
});
export default {
  getBuilding,
  getActiveLease,
  getStatistics,
  getCivicNumber,
  getStreetName,
  getTags,
  getImage,
  getImageResource,
  getCity,
  getSector,
  getProvince,
  getPostalCode,
  getCountry,
  getBuildDate,
  getPurchaseDate,
  getManagementStartDate,
  getProject,
  getProjectId,
  getUnits,
  getLatitude,
  getLongitude,
  getId,
  getAmenities,
  getFiles,
  getNotes,
  getCoords,
  getErrors,
  getFilteredNotes,
  getTickets
};