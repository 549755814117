import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';

import axios from "../../../_snowpack/pkg/axios.js"; // NEED AUTHORIZATION TOKEN AND USER ID

export const createListing = async (listing, userId) => {
  return axios.post(`${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_URL}listings?userId=${userId}`, listing, {
    validateStatus: status => {
      return true;
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_TOKEN}`
    }
  });
};
export const updateListing = async (listing, listingId, userId) => {
  return axios.put(`${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_URL}listings/${listingId}?userId=${userId}`, listing, {
    validateStatus: status => {
      return true;
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_TOKEN}`
    }
  });
};
export const deleteListing = async (listingId, userId) => {
  return axios.delete(`${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_URL}listings/${listingId}?userId=${userId}`, {
    validateStatus: status => {
      return true;
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_TOKEN}`
    }
  });
}; // publishState being a boolean, true for publish, false for unpublish

export const publishOrUnpublish = async (listingId, publishState, userId) => {
  return axios.post(`${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_URL}listings/${listingId}/${publishState ? "publish" : "unpublish"}?userId=${userId}`, {}, {
    validateStatus: status => {
      return true;
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_TOKEN}`
    }
  });
}; // DONT NEED AUTHORIZATION, READ ONLY

export const getListing = async listingId => {
  return axios.get(`${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_LISTING_API_URL}listings/${listingId}`, {
    validateStatus(status) {
      return true;
    },

    headers: {
      "Content-Type": "application/json"
    }
  });
}; // eventually
// eslint-disable-next-line @typescript-eslint/no-empty-function

export const validateListing = listing => {};