export const statusColor = {
  warning: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(255, 251, 235, var(--tw-bg-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(254, 243, 199, var(--tw-border-opacity))"
  },
  critical: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(254, 242, 242, var(--tw-bg-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(254, 226, 226, var(--tw-border-opacity))"
  },
  info: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(239, 246, 255, var(--tw-bg-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(219, 234, 254, var(--tw-border-opacity))"
  },
  disabled: {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "cursor": "not-allowed"
  }
};