import { TaskInvoicePanel } from "../../Panels/TaskInvoicePanel.js";
import { TaskPanel } from "../../Panels/TaskPanel.js";
import { EmptyState } from "../../Tailwind/EmptyState.js";
import useI18n from "../../../hooks/useI18n.js";
import { useAction, useSelector } from "../../../hooks/useRedux.js";
import { Selectors } from "../../../store/index.js";
import { TicketActionTypes } from "../../../store/artifacts/Tickets/types.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useState } from "../../../../../_snowpack/pkg/react.js";
import { useParams } from "../../../../../_snowpack/pkg/react-router.js";
import TicketInfoMainTask from "./TicketInfoMainTask.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const TicketInfoMainTasks = () => {
  const {
    id
  } = useParams();
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const tasks = useSelector(Selectors.ticket.getTasks);
  const ticket = useSelector(Selectors.ticket.getTicket);
  const isClosed = Boolean(useSelector(Selectors.ticket.getClosedAt));
  const [isTaskPanelOpen, setIsTaskPanelOpen] = useState(false);
  const [isTaskInvoicePanelOpen, setIsTaskInvoicePanelOpen] = useState(false);

  const handleCreateTask = () => setIsTaskPanelOpen(true);

  const refetch = () => {
    setIsTaskPanelOpen(false);
    dispatch({
      type: TicketActionTypes.TICKET_INITIALIZE,
      payload: {
        kind: "WithTicketId",
        ticketId: id
      }
    });
  };

  if (tasks?.items?.length <= 0 && id && isClosed) {
    return null;
  }

  return __cssprop(React.Fragment, null, __cssprop(TaskPanel, {
    refetch: refetch,
    isEdit: false,
    ticket: ticket,
    isOpen: isTaskPanelOpen,
    handleOnClose: () => setIsTaskPanelOpen(false)
  }), __cssprop(TaskInvoicePanel, {
    refetch: refetch,
    taskId: id,
    isOpen: isTaskInvoicePanelOpen,
    handleOnClose: () => setIsTaskInvoicePanelOpen(false)
  }), __cssprop("h1", {
    css: {
      "fontSize": "2.4rem",
      "lineHeight": "3.2rem"
    }
  }, t("tickets-table-tasks")), __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "1.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem"
    }
  }, tasks?.items?.length > 0 && tasks?.items.sort((ta, tb) => DateTime.fromISO(ta.dueDate).toUTC().toMillis() - DateTime.fromISO(tb.dueDate).toUTC().toMillis()).map(task => __cssprop(TicketInfoMainTask, {
    task: task,
    ticketId: id
  })), tasks?.items?.length <= 0 && id && __cssprop(EmptyState, {
    heading: t("ticket-empty-state-title"),
    description: t("ticket-empty-state-description"),
    action: {
      content: t("ticket-empty-state-cta"),
      onAction: handleCreateTask,
      permission: {
        kind: "Ticket",
        permission: "mutate:update"
      }
    }
  }), !id && __cssprop("h2", {
    css: {
      "fontSize": "3rem",
      "lineHeight": "3.6rem",
      "textAlign": "center",
      "paddingTop": "2rem",
      "paddingBottom": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("ticket-empty-state-cta-id"))));
};

export default TicketInfoMainTasks;