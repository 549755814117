function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { Switch } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { PhoneInput } from "../../components/PhoneInput/index.js";
import { Selector } from "../../components/Selectors/Selector.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import { PermissionType } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import { getEmailValidationForForm, getPhoneValidationForForm } from "../../utils/index.js";
import { getRoleLabel, ROLE_SELECT_OPTIONS } from "../../utils/users.js";
import React, { useMemo } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

const getSelectedOptions = (options, selectedOne) => {
  return options.find(o => o.value === selectedOne);
};

export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);

const StyledSpan = _styled.span(({
  active
}) => [{
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "0",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "pointerEvents": "none",
  "display": "inline-block",
  "height": "2rem",
  "width": "2rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)"
}, active ? {
  "--tw-translate-x": "2rem"
} : {
  "--tw-translate-x": "0px"
}]);

const StyledSwitch = _styled(Switch)(({
  checked
}) => [{
  "position": "relative",
  "display": "inline-flex",
  "flexShrink": "0",
  "height": "2.4rem",
  "width": "4.4rem",
  "borderWidth": "2px",
  "borderColor": "transparent",
  "borderRadius": "9999px",
  "cursor": "pointer",
  "transitionProperty": "background-color, border-color, color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-width": "2px",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
  }
}, checked ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
} : {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))"
}]);

const LANGUAGE_SELECT_OPTION = t => [{
  label: t("fr"),
  value: "FR"
}, {
  label: t("en"),
  value: "EN"
}];

export const UserSideBar = props => {
  const {
    description
  } = props;
  const {
    t
  } = useI18n();
  const isLoading = useSelector(Selectors.status.isLoading("user"));
  const firstName = useSelector(Selectors.user.getFirstName);
  const lastName = useSelector(Selectors.user.getLastName);
  const email = useSelector(Selectors.user.getEmail);
  const id = useSelector(Selectors.user.getId);
  const primaryPhone = useSelector(Selectors.user.getPrimaryPhone);
  const secondaryPhone = useSelector(Selectors.user.getSecondaryPhone);
  const language = useSelector(Selectors.user.getLanguage);
  const role = PermissionType.Client; // sad i used a useState

  const userRole = useSelector(Selectors.session.getUserRole);
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();

  const onSubmit = data => {
    props.onDoneSubmit({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      primaryPhone: data.primaryPhone,
      primaryChecked: data.primaryChecked,
      secondaryPhone: data.secondaryPhone,
      secondaryChecked: data.secondaryChecked,
      language: data.language?.value || getSelectedOptions(LANGUAGE_SELECT_OPTION(t), language).value,
      role: PermissionType[data.role?.value || role],
      notificationEnabled: !data.notificationEnabled
    });
  };

  function findWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }

    return -1;
  }

  const options = useMemo(() => {
    const indexOfRole = findWithAttr(ROLE_SELECT_OPTIONS(t), "value", userRole);

    if (userRole !== "Owner" && userRole !== "System") {
      return ROLE_SELECT_OPTIONS(t).filter((x, i) => indexOfRole < i);
    }

    return ROLE_SELECT_OPTIONS(t).filter((x, i) => indexOfRole <= i);
  }, [t, userRole]);
  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, id ? t("user-form-modify") : t("user-add")), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "maxWidth": "67.2rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, description)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "firstName",
    control: control,
    defaultValue: firstName,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "firstName", t("user-info-error-first-name")),
      id: "firstName"
    }, field, {
      label: t("user-info-form-first-name"),
      placeholder: t("user-info-form-first-name-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "lastName",
    control: control,
    defaultValue: lastName,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "lastName", t("user-info-error-last-name")),
      id: "lastName"
    }, field, {
      label: t("user-info-form-last-name"),
      placeholder: t("user-info-form-last-name-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop(React.Fragment, null, __cssprop(Controller, {
    name: "primaryPhone",
    control: control,
    defaultValue: primaryPhone,
    rules: getPhoneValidationForForm(),
    render: ({
      field
    }) => __cssprop(PhoneInput, _extends({}, field, {
      value: primaryPhone || field.value,
      label: t("user-info-form-primary-phone"),
      errorLabel: showError(errors, "primaryPhone", t("user-info-error-phone-required"))?.errorMessage,
      zIndex: 30
    }))
  }))), __cssprop(StyledItems, null, __cssprop(React.Fragment, null, __cssprop(Controller, {
    name: "secondaryPhone",
    control: control,
    defaultValue: secondaryPhone,
    rules: getPhoneValidationForForm(),
    render: ({
      field
    }) => __cssprop(PhoneInput, _extends({}, field, {
      label: t("user-info-form-secondary-phone"),
      errorLabel: showError(errors, "primaryPhone", t("user-info-error-secondary-phone"))?.errorMessage,
      zIndex: 30
    }))
  }))), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "email",
    control: control,
    defaultValue: email,
    rules: getEmailValidationForForm(),
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "email", t("user-info-error-email-invalid")),
      id: "lastName"
    }, field, {
      label: t("email"),
      placeholder: t("user-info-form-email-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("user-form-user-role-title")), __cssprop(Controller, {
    name: "role",
    control: control,
    rules: {
      validate: {
        isValid: v => {
          if (ROLE_SELECT_OPTIONS(t).find(o => o.value === v?.value) || role) return true;
          return false;
        }
      }
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      defaultValue: role && {
        label: getRoleLabel(role, t),
        value: role
      },
      id: "role",
      options: options,
      error: showError(errors, "role", t("user-preference-error-language")),
      isClearable: false
    }))
  }))), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("user-preference-form-language")), __cssprop(Controller, {
    name: "language",
    control: control,
    rules: {
      validate: {
        isValid: v => {
          if (v?.value === "FR" || v?.value === "EN") return true;
          return false;
        }
      }
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      defaultValue: getSelectedOptions(LANGUAGE_SELECT_OPTION(t), language),
      id: "language",
      options: LANGUAGE_SELECT_OPTION(t),
      error: showError(errors, "language", t("user-preference-error-language")),
      isClearable: false
    }))
  })))), __cssprop(ProtectedButton, {
    permission: id ? {
      kind: "User",
      permission: "mutate:update"
    } : {
      kind: "User",
      permission: "mutate:create"
    },
    submit: true,
    loading: isLoading,
    disabled: Object.keys(errors).length !== 0,
    primary: true
  }, id ? t("save") : t("user-form-create"))));
};
export default UserSideBar;