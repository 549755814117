/* eslint-disable import/no-cycle */
export const getProvider = state => state?.provider;
export default {
  getProvider,
  getId: state => getProvider(state).id,
  getFirstName: state => getProvider(state).name,
  getEmail: state => getProvider(state).email,
  getPhone: state => getProvider(state).phoneInfo?.primaryPhone,
  getFiles: state => getProvider(state).files,
  getNotes: state => getProvider(state).notes,
  getPreferredMethodOfContact: state => getProvider(state).preferredMethodOfContact
};