import { getFormattedAmount } from "../store/artifacts/Lease/util.js";
import { DateTime } from "../../../_snowpack/pkg/luxon.js";
import * as React from "../../../_snowpack/pkg/react.js";
import { EmptyState } from "./Tailwind/EmptyState.js";
import { ProtectedCard } from "./Tailwind/ProtectedComponents.js";
import { DualColumnItem, ResourceList } from "./Tailwind/ResourceList.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
export const UnitsView = ({
  history,
  units,
  t
}) => {
  // not using unpacking here cause the startDate and endDate are from different leases within the contracts
  const renderLeaseInformation = (monthlyPrice, startDate, endDate) => __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "textAlign": "right"
    }
  }, __cssprop("div", null, getFormattedAmount(monthlyPrice), " /m"), DateTime.fromISO(startDate).toUTC().toFormat("MMMM dd, yyyy"), " - ", DateTime.fromISO(endDate).toUTC().toFormat("MMMM dd, yyyy"));

  const renderTenants = (tenants = []) => __cssprop(React.Fragment, null, tenants.map(({
    tenant
  }, index) => __cssprop("div", {
    key: index,
    css: {},
    style: {
      paddingRight: "12px",
      display: "inline"
    }
  }, tenant.name)));

  const onUnitClicked = unitId => {
    history.push(`/unit/${unitId}`);
  };

  const renderUnit = unit => {
    const {
      subAddress,
      currentLease,
      id
    } = unit;
    const {
      tenants
    } = currentLease || {};
    return __cssprop(ResourceList.Item, {
      id: id,
      onClick: () => onUnitClicked(id)
    }, __cssprop(DualColumnItem, {
      primary: {
        heading: `${t("unit")} ${subAddress}`,
        content: __cssprop("div", null, tenants && renderTenants(tenants.items))
      },
      secondary: {
        heading: __cssprop("div", null, currentLease && renderLeaseInformation(currentLease.monthlyPrice, currentLease.startDate, currentLease.endDate))
      }
    }));
  };

  return __cssprop(ProtectedCard, {
    permission: {
      kind: "Unit",
      permission: "view:list"
    }
  }, units?.length > 0 && __cssprop(ResourceList, {
    items: units,
    renderItem: renderUnit
  }), units?.length === 0 && __cssprop(EmptyState, {
    image: "/img/noBuildingUnitsEmptyState.svg",
    heading: t("building-info-add-unit-empty-state")
  }, __cssprop("p", null, t("building-info-not-unit-found"))));
};
export default UnitsView;