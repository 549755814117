import React from "../../../../_snowpack/pkg/react.js";
import { HiUserCircle } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const ProfilePicture = ({
  id
}) => {
  return __cssprop(HiUserCircle, {
    css: {
      "height": "4rem",
      "width": "4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  });
};