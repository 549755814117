import { useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { GetBuildingsDocument } from "../../graphql/documents.js";
import useDebounce from "../../hooks/useDebounce.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction, useSelector } from "../../hooks/useRedux.js";
import { Selectors } from "../../store/index.js";
import { LeaseActionsTypes } from "../../store/artifacts/Lease/types.js";
import React, { useCallback, useMemo, useEffect } from "../../../../_snowpack/pkg/react.js";
import Select from "../../../../_snowpack/pkg/react-select.js";
import { Layout } from "./Layout.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const LeasePropertyPicker = () => {
  const {
    t
  } = useI18n();
  const [searchField, setSearchField] = React.useState("");
  const building = useSelector(Selectors.lease.getBuilding);
  const unit = useSelector(Selectors.lease.getUnit);
  const {
    dispatch
  } = useAction();
  const isLocked = useSelector(Selectors.lease.isLocked);
  const debouncedSearch = useDebounce(searchField, 500);
  const {
    data,
    loading
  } = useQuery(GetBuildingsDocument, {
    variables: {
      take: 25,
      query: debouncedSearch,
      skip: 0
    }
  }); // eslint-disable-next-line dot-notation

  const items = useMemo(() => data?.buildings?.items.map(i => {
    return {
      value: i.id,
      label: `${i.civicNumber} ${i.streetName}`
    };
  }), [data]);
  const onBuildingChange = useCallback(async b => {
    if (!b) {
      return;
    }

    dispatch({
      type: LeaseActionsTypes.LEASE_CHANGE_BUILDING,
      payload: b.value
    });
  }, []);
  const onUnitChange = useCallback(u => {
    if (!u) {
      return;
    }

    dispatch({
      type: LeaseActionsTypes.LEASE_CHANGE_UNIT,
      payload: u.value
    });
  }, []);
  useEffect(() => {
    if (building || !items) {
      return;
    }

    onBuildingChange(items[0]);
  }, [building, items]);
  const defaultBuildingOption = useMemo(() => {
    if (!building || !building?.civicNumber || !building?.streetName) {
      return {
        label: "",
        value: ""
      };
    }

    return {
      label: `${building?.civicNumber} ${building?.streetName}`,
      value: building?.id
    };
  }, [building]);
  return __cssprop("section", null, __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop("span", {
    tw: "block text-sm font-medium text-gray-700"
  }, t("lease-page-form-property-select-title")), __cssprop(Select, {
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: "hsl(0,0%,80%)"
      })
    },
    isClearable: true,
    placeholder: t("lease-page-form-property-select-placeholder"),
    menuContainerStyle: {
      zIndex: 25
    },
    onInputChange: setSearchField,
    isLoading: loading,
    onChange: onBuildingChange,
    options: items,
    defaultValue: defaultBuildingOption,
    isDisabled: isLocked
  })), __cssprop(Layout, {
    oneThird: true
  }, __cssprop(React.Fragment, null, __cssprop("span", {
    tw: "block text-sm font-medium text-gray-700"
  }, t("lease-page-form-property-select-select-unit")), __cssprop(Select, {
    isLoading: loading,
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: "hsl(0,0%,80%)"
      })
    },
    isDisabled: isLocked,
    isClearable: true,
    placeholder: t("unit"),
    menuContainerStyle: {
      zIndex: 25
    },
    onChange: onUnitChange,
    defaultValue: {
      label: unit?.subAddress,
      value: unit?.id
    },
    options: building?.units?.map(u => {
      return {
        label: u.subAddress,
        value: u.id
      };
    })
  })))));
};