/* eslint-disable import/no-cycle */

/* eslint-disable no-underscore-dangle */
import { TYPES } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { mapFiles } from "../../utils/Files.js";
import { mapNotes } from "../../utils/Notes.js";
import { all, getContext, put, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { GetTenantDocument, UpdateTenantDocument } from "../../../graphql/documents.js";
import { ApplicationActionsTypes } from "../Application/types.js";
import { addFilesHandler, uploadDocuments } from "../Files/sagas.js";
import StatusActions from "../Status/actions.js";
import { toTenantInputMapper } from "./mapper.js";
import { getTenant } from "./selectors.js";
import { TenantActionsTypes } from "./types.js";

function* updateExistingTenant(id, tenant, files) {
  const client = yield getContext("client");
  const history = yield getContext("history");
  yield put(StatusActions.setResourceToSubmit("tenant"));

  try {
    const query = client.query({
      query: UpdateTenantDocument,
      fetchPolicy: "no-cache",
      variables: {
        tenant: {
          id,
          tenant: toTenantInputMapper(tenant)
        }
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("tenant", "building-form-add-building-fail"));
      yield put({
        type: TenantActionsTypes.TENANT_INITIALIZE,
        payload: {
          kind: "WithContext",
          tenant
        }
      });
    } else {
      yield addFilesHandler(files, data.updateTenant.id, RESOURCES.Tenants);
      yield put({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: null
      });
      yield put(StatusActions.setResourceToSuccess("tenant", "building-form-add-building-success"));
      yield put({
        type: TenantActionsTypes.TENANT_INITIALIZE,
        payload: {
          kind: "WithTenantId",
          tenantId: data.updateTenant.id
        }
      });
      history.replace(`/tenants/${data.updateTenant.id}`);
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("tenant", "building-form-add-building-fail"));
    yield put({
      type: TenantActionsTypes.TENANT_INITIALIZE,
      payload: {
        kind: "WithContext",
        tenant
      }
    });
  }
}

function* onSubmitForm(data) {
  const tenant = yield select(getTenant);
  const files = yield uploadDocuments(data.payload.files || [], RESOURCES.Tenants, TYPES.Files);
  yield put({
    type: TenantActionsTypes.TENANT_INITIALIZE,
    payload: {
      kind: "WithContext",
      tenant: data.payload
    }
  });

  if (!tenant.id) {
    return;
  }

  yield updateExistingTenant(tenant.id, data.payload, files);
}

function* onInitializeTenant($data) {
  const client = yield getContext("client");
  const {
    payload
  } = $data;

  switch (payload.kind) {
    // case "WithContext":
    // State is already changed from reducer.
    case "WithTenantId":
      {
        try {
          yield put(StatusActions.setResourceToLoading("tenant"));
          const query = client.query({
            query: GetTenantDocument,
            fetchPolicy: "no-cache",
            variables: {
              id: payload.tenantId
            }
          });
          const {
            data,
            error
          } = yield query;

          if (error) {
            yield put(StatusActions.setResourceToError("tenant", "generic-fetch-error-message"));
            break;
          }

          yield put({
            type: TenantActionsTypes.TENANT_SET_FIELD,
            payload: { ...data.tenant,
              notes: mapNotes(data.tenant.notes.items),
              files: mapFiles(data.tenant.files.items)
            }
          });
          yield put(StatusActions.setResourceToIdle("tenant"));
        } catch (e) {
          yield put(StatusActions.setResourceToError("tenant", "generic-fetch-error-message"));
        }

        break;
      }

    case "NoContext":
    default:
      // State is already changed from reducer.
      break;
  }
}

export default {
  *executers() {
    yield all([yield takeLatest(TenantActionsTypes.TENANT_INITIALIZE, onInitializeTenant), yield takeLatest(TenantActionsTypes.TENANT_SUBMIT, onSubmitForm)]);
  }

};