/* eslint-disable import/no-cycle */
import { all, getContext, put, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { CloseTicketDocument, CreateTaskDocument, CreateTicketDocument, DeleteTicketDocument, GetTicketDocument, UpdateTicketDocument } from "../../../graphql/documents.js";
import { Selectors } from "../../index.js";
import { mapNotes } from "../../utils/Notes.js";
import { TYPES } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { mapFiles } from "../../utils/Files.js";
import { addFilesHandler, uploadDocuments } from "../Files/sagas.js";
import StatusActions from "../Status/actions.js";
import { TicketActionTypes } from "./types.js";

function* onInitializeTicket($data) {
  const client = yield getContext("client");
  const {
    payload
  } = $data;

  switch (payload.kind) {
    case "WithTicketId":
      {
        try {
          yield put(StatusActions.setResourceToLoading("ticket"));
          const query = client.query({
            query: GetTicketDocument,
            fetchPolicy: "no-cache",
            variables: {
              ticketId: payload.ticketId
            }
          });
          const {
            data
          } = yield query;
          const mappedTasks = data.ticket.tasks.items.map(t => {
            return { ...t,
              invoice: { ...t.invoice,
                files: t.invoice?.files.items
              },
              assignee: t.assignee ? {
                name: t.assignee.name,
                avatar: t.assignee.profileImage
              } : null // dueDate: t.dueDate ? DateTime.fromJSDate(t.dueDate).toUTC() : null,

            };
          }); // . TODO: Map conversations and tasks

          yield put({
            type: TicketActionTypes.TICKET_SET_FIELD,
            payload: { ...data.ticket,
              notes: mapNotes(data.ticket.notes.items),
              files: mapFiles(data.ticket.files.items),
              tasks: {
                items: mappedTasks
              }
            }
          });
          yield put(StatusActions.setResourceToIdle("ticket"));
        } catch (e) {
          yield put(StatusActions.setResourceToError("ticket", "generic-fetch-error-message"));
        }

        break;
      }
    // TODO: add default case

    default:
      {
        break;
      }
  }
}

function* createNewTicket($data) {
  const client = yield getContext("client");
  const history = yield getContext("history");
  yield put(StatusActions.setResourceToSubmit("ticket"));
  const tags = yield select(Selectors.ticket.getTags); // eslint-disable-next-line react-hooks/rules-of-hooks

  const resourceObject = {
    userId: $data.payload.resource.userId || $data.payload.resource.tenantId || $data.payload.resource.tenant?.userId || $data.payload.resource.tenant?.id || $data.payload.resource.user?.userId || $data.payload.resource.user?.id || null,
    spaceId: $data.payload.resource.spaceId || $data.payload.resource.space?.id || null,
    projectId: $data.payload.resource.projectId || $data.payload.resource.project?.projectId || $data.payload.resource.project?.id || null,
    leaseId: $data.payload.resource.lease?.leaseId || $data.payload.resource.lease?.id || null
  };
  const resource = Object.fromEntries(Object.entries(resourceObject).filter(([key, value]) => value != null));

  try {
    const query = client.query({
      query: CreateTicketDocument,
      fetchPolicy: "no-cache",
      variables: {
        ticket: {
          title: $data.payload.title,
          priority: $data.payload.priority,
          category: $data.payload.category,
          tags,
          resource
        }
      }
    });
    const {
      data
    } = yield query;

    if ($data.payload.withTask && $data.payload.withTask.task && $data.payload.withTask.task.assigneeId) {
      const taskQuery = client.query({
        query: CreateTaskDocument,
        fetchPolicy: "no-cache",
        variables: {
          ticketId: data.createTicket.id,
          task: {
            title: $data.payload.title,
            dueDate: $data.payload.withTask.task.dueDate,
            assigneeId: $data.payload.withTask.task.assigneeId
          }
        }
      });
      yield taskQuery;
    }

    yield put(StatusActions.setResourceToSuccess("ticket", "ticket-form-add-ticket-success"));
    yield put({
      type: TicketActionTypes.TICKET_INITIALIZE,
      payload: {
        kind: "WithTicketId",
        ticketId: data.createTicket.id
      }
    });
    history.push(`/ticket/${data.createTicket.id}`);
  } catch (e) {
    yield put(StatusActions.setResourceToError("ticket", "ticket-form-add-ticket-fail"));
  }
}

function* updateTicket($data) {
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("ticket"));
  const state = yield select(Selectors.ticket.getTicket);
  const resourceObject = {
    userId: state.resource.tenant?.userId || state.resource.tenant?.id || state.resource.user?.userId || state.resource.user?.id || null,
    spaceId: state.resource?.space?.spaceId || state.resource?.space?.id || $data.payload.resource?.building?.value || null,
    projectId: state.resource.project?.projectId || state.resource.project?.id || null,
    leaseId: state.resource.lease?.leaseId || state.resource.lease?.id || null
  };
  const resource = Object.fromEntries(Object.entries(resourceObject).filter(([key, value]) => value !== null));
  const ticket = { ...$data.payload,
    resource
  };

  try {
    const query = client.query({
      query: UpdateTicketDocument,
      fetchPolicy: "no-cache",
      variables: {
        ticketId: state.id,
        ticket
      }
    });
    const {
      data
    } = yield query;
    yield put({
      type: TicketActionTypes.TICKET_SET_FIELD,
      payload: { ...$data.payload
      }
    });
    yield put(StatusActions.setResourceToSuccess("ticket", "ticket-form-update-ticket-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("ticket", "ticket-form-update-ticket-fail"));
  }
}

function* AddConversation($data) {// const client: SimpleLoyerApolloClient = yield getContext("client");
  // yield put(StatusActions.setResourceToSubmit("ticket"));
  // const state = yield select(Selectors.ticket.getTicket);
  // const { content, files } = $data.payload;
  // try {
  //   const query = client.query({
  //     query: CreateConversationDocument,
  //     fetchPolicy: "no-cache",
  //     variables: {
  //       ticketId: state.id,
  //       conversation: {
  //         content,
  //       },
  //     },
  //   });
  //   const { data, errors }: UnPromisify<typeof query> = yield query;
  //   if (errors?.length > 0) {
  //     yield put(StatusActions.setResourceToError("ticket", "ticket-form-add-ticket-conversation-fail"));
  //   } else {
  //     const newConversation = {
  //       ...data.createTicketConversation,
  //       ...$data.payload,
  //     };
  //     yield put({
  //       type: TicketActionTypes.TICKET_SET_FIELD,
  //       payload: {
  //         conversations: { items: [newConversation, ...state.conversations.items] },
  //       },
  //     });
  //     yield put({
  //       type: TicketActionTypes.TICKET_CONVERSATION_ADD_FILES_FORMLESS,
  //       payload: {
  //         conversationId: data.createTicketConversation.id,
  //         files,
  //       },
  //     });
  //     yield put(StatusActions.setResourceToSuccess("ticket", "ticket-form-add-ticket-conversation-success"));
  //   }
  // } catch (e) {
  //   yield put(StatusActions.setResourceToError("ticket", "ticket-form-add-ticket-conversation-fail"));
  // }
}

function* closeTicket($data) {
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("ticket"));

  try {
    const query = client.query({
      query: CloseTicketDocument,
      fetchPolicy: "no-cache",
      variables: {
        ticketId: $data.payload.ticketId
      }
    });
    const {
      errors
    } = yield query;

    if (errors) {
      yield put(StatusActions.setResourceToError("ticket", "ticket-form-delete-task-fail"));
    } else {
      yield put(StatusActions.setResourceToSuccess("ticket", "ticket-form-close-ticket-success"));
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("ticket", "ticket-form-close-ticket-fail"));
  }
}

function* deleteTicket($data) {
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("ticket"));

  try {
    const query = client.query({
      query: DeleteTicketDocument,
      fetchPolicy: "no-cache",
      variables: {
        ticketId: $data.payload.ticketId
      }
    });
    const {
      errors
    } = yield query;

    if (errors) {
      yield put(StatusActions.setResourceToError("ticket", "ticket-form-delete-task-fail"));
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("ticket", "ticket-form-delete-task-fail"));
  }
}

function* onTicketAddFiles($data) {
  const files = $data.payload;
  const ticket = yield select(Selectors.ticket.getTicket);
  const filesUploaded = yield uploadDocuments([...ticket.files, ...files], RESOURCES.Ticket, TYPES.Files);
  yield addFilesHandler(filesUploaded, ticket.id, RESOURCES.Ticket);
  yield put({
    type: TicketActionTypes.TICKET_ADD_FILES,
    payload: files
  });
}

function* onTicketRemoveFiles($data) {
  const files = $data.payload;
  const ticket = yield select(Selectors.ticket.getTicket);
  const newFiles = ticket.files.filter(file => file.fileName !== files.fileName);
  const filesUploaded = yield uploadDocuments(newFiles, RESOURCES.Ticket, TYPES.Files);
  yield addFilesHandler(filesUploaded, ticket.id, RESOURCES.Ticket);
  yield put({
    type: TicketActionTypes.TICKET_REMOVE_FILES,
    payload: files
  });
}

function* onTicketConversationAddFiles($data) {
  const {
    conversationId,
    files
  } = $data.payload;
  const filesUploaded = yield uploadDocuments([...files], RESOURCES.TicketConversation, TYPES.Files);
  yield addFilesHandler(filesUploaded, conversationId, RESOURCES.TicketConversation);
}

function* onTicketConversationRemoveFiles($data) {
  const {
    conversationId,
    file
  } = $data.payload;
  const ticket = yield select(Selectors.ticket.getTicket);
  const newFiles = ticket.conversations.items.find(conversation => conversation.id === conversationId).files.filter(fileItem => fileItem.fileName !== file.fileName).map(item => {
    return { ...item,
      kind: "remote"
    };
  });
  const filesUploaded = yield uploadDocuments(newFiles, RESOURCES.TicketConversation, TYPES.Files);
  yield addFilesHandler(filesUploaded, conversationId, RESOURCES.TicketConversation);
  yield put({
    type: TicketActionTypes.TICKET_CONVERSATION_REMOVE_FILES,
    payload: {
      files: newFiles,
      conversationId
    }
  });
}

export default {
  *executers() {
    yield all([yield takeLatest(TicketActionTypes.TICKET_INITIALIZE, onInitializeTicket), yield takeLatest(TicketActionTypes.TICKET_CREATE, createNewTicket), yield takeLatest(TicketActionTypes.TICKET_UPDATE, updateTicket), yield takeLatest(TicketActionTypes.TICKET_ADD_CONVERSATION, AddConversation), yield takeLatest(TicketActionTypes.TICKET_CLOSE, closeTicket), yield takeLatest(TicketActionTypes.TICKET_DELETE, deleteTicket), yield takeLatest(TicketActionTypes.TICKET_ADD_FILES_FORMLESS, onTicketAddFiles), yield takeLatest(TicketActionTypes.TICKET_REMOVE_FILES_FORMLESS, onTicketRemoveFiles), yield takeLatest(TicketActionTypes.TICKET_CONVERSATION_ADD_FILES_FORMLESS, onTicketConversationAddFiles), yield takeLatest(TicketActionTypes.TICKET_CONVERSATION_REMOVE_FILES_FORMLESS, onTicketConversationRemoveFiles)]);
  }

};