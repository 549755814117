/* eslint-disable import/no-cycle */

/* eslint-disable no-underscore-dangle */
import { createReducer } from "../../helpers/actions.js";
import { mapFiles } from "../../utils/Files.js";
import { initialState, LeaseActionsTypes } from "./types.js";

const setField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};

export const addLeaseFiles = (data, state) => {
  return { ...state,
    files: [...state.files, ...data.payload]
  };
};
export const removeLeaseFiles = (data, state) => {
  return { ...state,
    files: mapFiles(state.files.filter(file => file.fileName !== data.payload.fileName))
  };
};

const addLeaseNotes = (data, state) => ({ ...state,
  notes: [...state.notes, ...data.payload]
});

const removeLeaseNotes = (data, state) => {
  return { ...state,
    notes: state.notes.map(note => {
      if (note.id === data.payload) {
        return { ...note,
          kind: "removed"
        };
      }

      return note;
    })
  };
};

export default createReducer(initialState, {
  [LeaseActionsTypes.LEASE_ADD_FILES]: addLeaseFiles,
  [LeaseActionsTypes.LEASE_REMOVE_FILES]: removeLeaseFiles,
  [LeaseActionsTypes.LEASE_ADD_NOTES]: addLeaseNotes,
  [LeaseActionsTypes.LEASE_REMOVE_NOTES]: removeLeaseNotes,
  [LeaseActionsTypes.LEASE_SET_FIELD]: setField
});