import { useLazyQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { DatePicker } from "../../../components/DatePicker.js";
import { Button } from "../../../components/Tailwind/Button.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { ProtectedCard } from "../../../components/Tailwind/ProtectedComponents.js";
import { GetLeasesToBeRenewedDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useCallback, useEffect, useState } from "../../../../../_snowpack/pkg/react.js";
import { downloadFile } from "../../../helpers/files.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
const headers = ["project_name", "build_date", "monthly_price", "payment_method", "bedrooms", "unit_address", "street_name", "postal_code", "city", "start_date", "end_date"];
const tenantHeaders = ["tenant_name", "tenant_email", "language", "primary_phone", "secondary_phone"];

const reportDate = () => {
  return DateTime.now().plus({
    month: 1
  });
};

export const LeaseToBeRenewWidget = () => {
  const {
    t
  } = useI18n();
  const [date, setDate] = useState(reportDate());
  const [leases, setLeases] = useState(null);
  const [getLease, {
    data,
    loading
  }] = useLazyQuery(GetLeasesToBeRenewedDocument);
  const download = useCallback(async leasesData => {
    if (!leasesData) {
      return;
    }

    const authedDownloadURL = await downloadFile(leasesData);
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // Manage IE11+ & Edge
      window.navigator.msLaunchUri(authedDownloadURL);
    } else {
      fakeLink.setAttribute("href", authedDownloadURL);
      fakeLink.click();
    }
  }, [date]);
  const onClick = useCallback(() => {
    if (!leases) {
      getLease({
        variables: {
          date: date.toUTC().toISODate()
        }
      });
      return;
    }

    download(leases);
  }, [date, download, getLease, leases]);
  const onDateChange = useCallback(value => {
    setDate(value);
    setLeases(null);
  }, []);
  useEffect(() => {
    if (loading || !data || !data?.statistics?.leasesToBeRenewed) {
      return;
    }

    setLeases(data.statistics.leasesToBeRenewed);
  }, [data]);
  return __cssprop(ProtectedCard, {
    title: t("leases-to-be-renewed-title"),
    permission: {
      kind: "Reporting",
      permission: "mutate:create"
    }
  }, __cssprop(Card.Section, null, t("leases-to-be-renewed-description")), __cssprop(Card.Section, {
    greyed: true
  }, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "paddingRight": "1.2rem"
    }
  }, __cssprop(DatePicker, {
    id: "activeLeaseDate",
    key: "LeaseToBeRenewedDate",
    defaultValue: date,
    onChange: onDateChange
  })), __cssprop("div", {
    css: {
      "alignSelf": "center",
      "alignItems": "center"
    }
  }, __cssprop(Button, {
    onAction: onClick,
    loading: loading,
    primary: !!leases
  }, leases ? t("download") : t("generate"))))));
};
export default LeaseToBeRenewWidget;