import _styled from "../../../../../_snowpack/pkg/@emotion/styled.js";

/* eslint-disable no-param-reassign */
import { FeatureType } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { FeatureTypeInput, ReversedFeatureType } from "../../../utils/types.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useMemo } from "../../../../../_snowpack/pkg/react.js";
import { v4 as uuid } from "../../../../../_snowpack/pkg/uuid.js";
import { Card } from "../Card.js";
import { DescriptionList } from "../DescriptionList.js";
import { AmenitiesGroups } from "./types.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const COLORS_BY_CATEGORY = {
  Appliances: {
    "--tw-text-opacity": "1",
    "color": "rgba(248, 113, 113, var(--tw-text-opacity))"
  },
  Animals: {
    "--tw-text-opacity": "1",
    "color": "rgba(251, 191, 36, var(--tw-text-opacity))"
  },
  Accessibility: {
    "--tw-text-opacity": "1",
    "color": "rgba(52, 211, 153, var(--tw-text-opacity))"
  },
  Facilities: {
    "--tw-text-opacity": "1",
    "color": "rgba(96, 165, 250, var(--tw-text-opacity))"
  },
  Extensions: {
    "--tw-text-opacity": "1",
    "color": "rgba(129, 140, 248, var(--tw-text-opacity))"
  },
  Furniture: {
    "--tw-text-opacity": "1",
    "color": "rgba(167, 139, 250, var(--tw-text-opacity))"
  }
};

const StyledSvg = _styled.svg(({
  color
}) => [{
  "marginLeft": "-0.2rem",
  "marginRight": "0.6rem",
  "height": "0.8rem",
  "width": "0.8rem",
  "--tw-text-opacity": "1",
  "color": "rgba(129, 140, 248, var(--tw-text-opacity))"
}, color]);

export const AmenitiesTagCloud = ({
  title,
  list
}) => {
  const {
    t
  } = useI18n();
  const mappedList = list.map(i => {
    return { ...i,
      key: FeatureType[i.key] || i.key
    }; // map back to snake case
  });
  const groupByCategory = useMemo(() => {
    return mappedList.reduce((group, item) => {
      const [category] = Object.entries(AmenitiesGroups).find(([key, value]) => value.includes(item.key));
      group[category] = group[category] ?? [];
      group[category].push(item);
      return group;
    }, {});
  }, [mappedList]);
  return __cssprop(Card, {
    title: title
  }, __cssprop(Card.Section, null, __cssprop(DescriptionList.TwoColumn, null, Object.entries(groupByCategory).map(([category, items]) => __cssprop(DescriptionList.Item, {
    key: uuid(),
    title: category,
    spacing: "tight"
  }, items.map(item => {
    let itemKey = item.key;
    let itemValue = item.value;

    if (item.value && FeatureTypeInput[ReversedFeatureType[item.key]] === "boolean") {
      itemKey = item.value === "true" ? item.key : null; // don't show value for boolean

      itemValue = null;
    } else if (item.value && FeatureTypeInput[ReversedFeatureType[item.key]] === "date") {
      itemValue = DateTime.fromISO(item.value).toUTC().toISODate();
    }

    return __cssprop("div", {
      key: category,
      css: {
        "display": "inline-flex",
        "alignItems": "center",
        "borderWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
        "paddingLeft": "1.2rem",
        "paddingRight": "1.2rem",
        "paddingTop": "0.2rem",
        "paddingBottom": "0.2rem",
        "borderRadius": "9999px",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(0, 0, 0, var(--tw-text-opacity))",
        "marginRight": "0.8rem",
        "marginTop": "0.8rem",
        "marginBottom": "0.8rem"
      }
    }, __cssprop(StyledSvg, {
      color: COLORS_BY_CATEGORY[category],
      fill: "currentColor",
      viewBox: "0 0 8 8"
    }, __cssprop("circle", {
      cx: 4,
      cy: 4,
      r: 3
    })), itemKey ? t(item.key) : null, itemValue ? ` ${itemValue}` : null);
  }))))));
};