import { PermissionType, UserState as UserGQLState } from "../../../graphql/documents.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const initialState = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  name: null,
  creationDate: DateTime.utc().toJSDate(),
  profileImage: null,
  serverUser: {
    kind: "NotAsked"
  },
  language: null,
  archivalStatus: false,
  notificationEnabled: false,
  role: PermissionType.User,
  userState: UserGQLState.Active
};
// =========== ACTIONS CREATORS =========== //
export let UserActionsTypes;

(function (UserActionsTypes) {
  UserActionsTypes["USER_SUBMIT"] = "USER_SUBMIT";
  UserActionsTypes["USER_SUBMIT_FAILURE"] = "USER_SUBMIT_FAILURE";
  UserActionsTypes["USER_INITIALIZE"] = "USER_INITIALIZE";
  UserActionsTypes["USER_SET_SERVER_USER_STATUS"] = "USER_SET_SERVER_USER_STATUS";
  UserActionsTypes["USER_SET_FIELD"] = "USER_SET_FIELD";
  UserActionsTypes["USER_ADD_FILES"] = "USER_ADD_FILES";
  UserActionsTypes["USER_REMOVE_FILES"] = "USER_REMOVE_FILES";
})(UserActionsTypes || (UserActionsTypes = {}));