import React from "../../../../../_snowpack/pkg/react.js";
import { HiPlus } from "../../../../../_snowpack/pkg/react-icons/hi.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { useDropzone } from "../../../../../_snowpack/pkg/react-dropzone.js";
import useI18n from "../../../hooks/useI18n.js";
import { ListingActionsTypes } from "../../../store/artifacts/Listing/types.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const MediaView = () => {
  const {
    dispatch
  } = useAction();
  const images = useSelector(Selectors.listing.getGalleryPictures);
  const {
    t
  } = useI18n();
  const onDrop = React.useCallback(acceptedFiles => {
    dispatch({
      type: ListingActionsTypes.LISTING_ADD_GALLERY_ITEMS,
      payload: acceptedFiles.map((f, idx) => {
        return {
          kind: "local",
          id: `${f.name}-${f.size}`,
          order: idx,
          path: f.path,
          blob: new Blob([f]),
          preview: URL.createObjectURL(f),
          fileName: f.name,
          fileType: f.type,
          size: f.size
        };
      })
    });
  }, []);
  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png"
  });
  return __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("file-section-multiple-image-title")), __cssprop("ul", {
    css: {
      "marginTop": "0.8rem",
      "borderTopWidth": "1px",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, images.map(i => {
    return __cssprop("li", {
      key: i.id,
      css: {
        "paddingTop": "1.2rem",
        "paddingBottom": "1.2rem",
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center"
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center"
      }
    }, __cssprop("img", {
      src: i.kind === "local" ? i.preview : i.url,
      alt: "",
      css: {
        "width": "3.2rem",
        "height": "3.2rem",
        "borderRadius": "9999px"
      }
    }), __cssprop("p", {
      css: {
        "marginLeft": "1.6rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      }
    }, i.fileName)), __cssprop("button", {
      type: "button",
      onClick: () => dispatch({
        type: ListingActionsTypes.LISTING_REMOVE_GALLERY_ITEMS,
        payload: i.id
      }),
      "aria-hidden": true,
      css: {
        "marginLeft": "2.4rem",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
        "borderRadius": "0.6rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-offset-width": "2px",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
        }
      }
    }, t("lease-page-form-tenant-remove")));
  }), __cssprop("li", {
    css: {
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "center"
    }
  }, __cssprop("div", getRootProps(), __cssprop("input", getInputProps()), __cssprop("button", {
    type: "button",
    className: "group",
    css: {
      "marginLeft": "-0.4rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "padding": "0.4rem",
      "borderRadius": "0.6rem",
      "display": "flex",
      "alignItems": "center",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop("span", {
    css: {
      "width": "3.2rem",
      "height": "3.2rem",
      "borderRadius": "9999px",
      "borderWidth": "2px",
      "borderStyle": "dashed",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    }
  }, __cssprop(HiPlus, {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  })), __cssprop("span", {
    css: {
      "marginLeft": "1.6rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
      }
    }
  }, t("add")))))));
};