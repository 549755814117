import { useQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { ApplicantScore } from "../../../components/ApplicantScore.js";
import { BuildingAddressRender } from "../../../components/Building/BuildingAddress.js";
import { Stepper } from "../../../components/Stepper/index.js";
import { Badge } from "../../../components/Tailwind/Badge.js";
import { Link } from "../../../components/Tailwind/Link.js";
import { PaginatedDataTable } from "../../../components/Tailwind/PaginatedDataTable.js";
import { withBreadcrumb } from "../../../context/Breadcrumbs/withBreadcrumbs.js";
import { FileCategory, GetRentalApplicationsV2Document, GetUsersDocument, UserActingPositions } from "../../../graphql/documents.js";
import { getRentalRequestStatus } from "../../../helpers/functions.js";
import useI18n from "../../../hooks/useI18n.js";
import { hasPermissionLevel, PermissionTypeLevel } from "../../../hooks/usePermission.js";
import { useSelector } from "../../../hooks/useRedux.js";
import { getUserRole } from "../../../store/artifacts/Session/selectors.js";
import { applicantProgressOptionsV2, applicantStatusOptionsV2 } from "../../../utils/applicant.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useState } from "../../../../../_snowpack/pkg/react.js";
import { IoCheckmarkDoneSharp, IoCheckmarkSharp, IoFileTraySharp, IoTodaySharp, IoCheckboxOutline } from "../../../../../_snowpack/pkg/react-icons/io5.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const getRestriction = applicant => {
  if (!applicant.applicationPass) {
    return false;
  }

  if (!applicant.applicationPass.restrictions) {
    return false;
  }

  return applicant.applicationPass.restrictions.length !== 0;
};

export const ApplicantRequestV2 = withBreadcrumb(({
  history
}) => {
  const {
    t
  } = useI18n();
  const userRole = useSelector(getUserRole);
  const [filters, setFilters] = useState({
    status: ["active"],
    progress: [],
    agentsId: []
  });
  const {
    loading,
    data
  } = useQuery(GetUsersDocument, {
    variables: {
      take: 100,
      query: "",
      skip: 0,
      roles: {
        roles: [UserActingPositions.PropertyManager, UserActingPositions.VisitAgent]
      }
    }
  });

  const renderApplicants = application => {
    return __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center"
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "flexDirection": "column"
      }
    }, application.applicants.map(applicant => {
      return __cssprop("div", {
        css: {
          "fontSize": "1.8rem",
          "lineHeight": "2.8rem",
          "--tw-text-opacity": "1",
          "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
        },
        key: `${applicant.email}-${applicant.name}`
      }, __cssprop(Link, {
        red: getRestriction(applicant),
        text: applicant.name,
        action: {
          kind: "InternalLink",
          to: `/applicant/v2/${application.id}`
        }
      }), getRestriction(applicant) && __cssprop(Badge, {
        css: {
          "marginLeft": "0.8rem"
        },
        critical: true
      }, t("restricted")));
    })));
  };

  const getVisitStatus = item => {
    if (item.visitAgentApprovedAt) {
      return "success";
    }

    if (item.visitDate) {
      return "warning";
    }

    return "neutral";
  };

  const getOwnerApprovalStatus = item => {
    if (item.landlordRejectedAt) {
      return "danger";
    }

    if (item.landlordApprovedAt) {
      return "success";
    }

    return "neutral";
  };

  const getPMApprovalStatus = item => {
    if (item.propertyManagerApprovedAt) {
      return "success";
    }

    if (item.propertyManagerRejectedAt) {
      return "danger";
    } // if issue return neutral


    return "neutral";
  };

  const renderBody = item => {
    let slScore = 0;

    if (item?.applicants?.length !== 0 && item?.applicants[0]?.applicationPass && item?.applicants[0]?.applicationPass.slScore) {
      slScore = item.applicants[0].applicationPass.slScore;
    }

    let scoreText = "NA";
    if (slScore && !Number.isNaN(Number(slScore))) scoreText = `${slScore} / 100`;else if (Number.isNaN(Number(slScore))) scoreText = t("no-hit");
    const stepperView = [{
      name: t("applicant-request-list-intern-approved"),
      state: getPMApprovalStatus(item),
      Icon: IoCheckmarkSharp
    }, {
      name: t("applicant-request-status"),
      state: item.chosenAt ? "success" : "neutral",
      Icon: IoCheckboxOutline
    }, {
      name: t("applicant-request-list-visit-scheduled"),
      state: getVisitStatus(item),
      Icon: IoTodaySharp
    }, {
      name: t("applicant-request-list-owner-approved"),
      state: getOwnerApprovalStatus(item),
      Icon: IoCheckmarkDoneSharp
    }, {
      name: t("applicant-request-list-lease-doc-sent"),
      state: item?.files?.items.find(file => file.fileCategory === FileCategory.Lease) ? "success" : "neutral",
      Icon: IoFileTraySharp,
      fraction: [item?.files?.items.length ? item.files.items.length : 0, 6]
    }];
    return {
      applicants: renderApplicants(item),
      address: item.unit && __cssprop(BuildingAddressRender, {
        unit: item.unit,
        buildingId: item.unit.buildingId
      }),
      created_at: `${DateTime.fromISO(item?.createdAt).toUTC().toISODate()} (${DateTime.fromISO(item?.createdAt).toUTC().toRelative()})`,
      score: __cssprop(ApplicantScore, {
        css: {
          "marginTop": "0.4rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "fontWeight": "700",
          "@media (min-width: 640px)": {
            "gridColumn": "span 2 / span 2",
            "marginTop": "0px"
          }
        },
        score: Number.isNaN(Number(slScore)) ? 0 : Number(slScore)
      }, scoreText),
      status: getRentalRequestStatus(item, t),
      agent: item.visitAgent?.name,
      stepper: __cssprop(Stepper, {
        simpleMode: true,
        steps: stepperView
      })
    };
  };

  const columns = hasPermissionLevel(userRole, PermissionTypeLevel.Manager) ? [{
    Header: t("applicants"),
    accessor: "applicants",
    disableSortBy: true
  }, {
    Header: t("building-form-address"),
    accessor: "address",
    fieldOverwrite: "address"
  }, {
    Header: t("unit-application-created-date"),
    accessor: "created_at",
    fieldOverwrite: "creationDate"
  }, {
    Header: "Score",
    disableSortBy: true,
    accessor: "score"
  }, {
    Header: t("selector-label-rental-agent"),
    accessor: "agent",
    fieldOverwrite: "agent"
  }, {
    Header: t("applicant-request-form-section-choice-applicant-status"),
    disableSortBy: true,
    accessor: "status"
  }, {
    Header: t("status"),
    accessor: "stepper",
    disableSortBy: true
  }] : [{
    Header: t("applicants"),
    accessor: "applicants",
    disableSortBy: true
  }, {
    Header: t("building-form-address"),
    accessor: "address",
    fieldOverwrite: "address"
  }, {
    Header: t("unit-application-created-date"),
    accessor: "created_at",
    fieldOverwrite: "creationDate"
  }, {
    Header: t("applicant-request-form-section-choice-applicant-status"),
    disableSortBy: true,
    accessor: "status"
  }, {
    Header: t("status"),
    accessor: "stepper",
    disableSortBy: true
  }];
  const filterItems = [{
    placeholder: t("applicant-request-progress-placeholder"),
    options: applicantProgressOptionsV2(t),
    name: "progress"
  }, {
    placeholder: t("status"),
    options: applicantStatusOptionsV2(t),
    name: "status"
  }];

  if (data?.users?.items) {
    const users = data.users.items.map(u => ({
      label: u.name,
      value: u.id
    }));
    const item = {
      placeholder: "Agent",
      options: users,
      name: "agentsId"
    };
    filterItems.push(item);
  }

  return __cssprop(PaginatedDataTable, {
    title: t("applicants"),
    query: GetRentalApplicationsV2Document,
    resourceName: {
      plural: t("applicants"),
      singular: t("applicant")
    },
    headers: columns,
    renderItem: renderBody,
    filters: filterItems,
    extraVariables: { ...filters
    },
    onFilterChange: (kind, change) => {
      setFilters(f => ({ ...f,
        [kind]: change.map(x => x.value) || []
      }));
    }
  });
});
export default ApplicantRequestV2;