export function handleRemoteData(state, query, mapper) {
  switch (query.kind) {
    case "Success":
      return { ...state,
        ...mapper(query.payload)
      };

    case "Loading":
    case "Failure":
    case "NotAsked":
      return state;

    default:
      return state;
  }
}