import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { textSm } from "../tailwind.temp.fix.js";
export const StyledSpanSelected = _styled.span(({
  isSelected
}) => [isSelected && {
  "fontWeight": "600",
  "display": "block",
  "overflow": "hidden",
  "textOverflow": "ellipsis",
  "whiteSpace": "nowrap"
}, !isSelected && {
  "fontWeight": "400",
  "display": "block",
  "overflow": "hidden",
  "textOverflow": "ellipsis",
  "whiteSpace": "nowrap"
}]);
export const StyledItemSpanSelected = _styled.div(() => [{
  "width": "4rem",
  "height": "4rem",
  "position": "absolute",
  "top": "0px",
  "bottom": "0px",
  "right": "0px",
  "display": "flex",
  "alignItems": "center",
  "paddingLeft": "1.6rem",
  "paddingRight": "0.8rem"
}, textSm]);
export const StyledLabel = _styled.label(() => [{
  "display": "block",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
}, textSm]);
export const StyledSpan = _styled.span(() => [{
  "display": "block",
  "overflow": "hidden",
  "textOverflow": "ellipsis",
  "whiteSpace": "nowrap"
}, textSm]);
export const StyledUl = _styled.ul(() => [{
  "maxHeight": "24rem",
  "borderRadius": "0.6rem",
  "paddingTop": "0.4rem",
  "paddingBottom": "0.4rem",
  "fontSize": "1.6rem",
  "lineHeight": "2.4rem",
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-opacity": "0.05",
  "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
  "overflow": "auto",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  }
}, textSm]);
export const StyledItemSelected = _styled.li(({
  highlighted
}) => [highlighted && {
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
}, !highlighted && {
  "--tw-text-opacity": "1",
  "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
}, {
  "cursor": "default",
  "userSelect": "none",
  "position": "relative",
  "paddingTop": "0.8rem",
  "paddingBottom": "0.8rem",
  "paddingLeft": "1.2rem",
  "paddingRight": "3.6rem"
}]);