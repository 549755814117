function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { Switch } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { DatePicker } from "../../components/DatePicker.js";
import { PhoneInput } from "../../components/PhoneInput/index.js";
import { ApplicantSelector } from "../../components/Selectors/ApplicantSelector.js";
import { Selector } from "../../components/Selectors/Selector.js";
import { Button } from "../../components/Tailwind/Button.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import { getEmailValidationForForm, getPhoneValidationForForm } from "../../utils/index.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

export const rehydrateDateTimeObject = date => {
  return DateTime.fromMillis(date.ts).toUTC();
};
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);

const LANGUAGE_SELECT_OPTION = t => [{
  label: t("fr"),
  value: "fr"
}, {
  label: t("en"),
  value: "en"
}];

const StyledSpan = _styled.span(({
  active
}) => [{
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "0",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "pointerEvents": "none",
  "display": "inline-block",
  "height": "2rem",
  "width": "2rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)"
}, active ? {
  "--tw-translate-x": "2rem"
} : {
  "--tw-translate-x": "0px"
}]);

const StyledSwitch = _styled(Switch)(({
  checked
}) => [{
  "position": "relative",
  "display": "inline-flex",
  "flexShrink": "0",
  "height": "2.4rem",
  "width": "4.4rem",
  "borderWidth": "2px",
  "borderColor": "transparent",
  "borderRadius": "9999px",
  "cursor": "pointer",
  "transitionProperty": "background-color, border-color, color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-width": "2px",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
  }
}, checked ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
} : {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))"
}]);

export const ApplicantSideBar = props => {
  const {
    sectionTitle,
    description
  } = props;
  const {
    t
  } = useI18n();
  const isLoading = useSelector(Selectors.status.isLoading("applicant"));
  const listingIsPublished = useSelector(Selectors.listing.isPublished);
  const listingAvailabilityDate = useSelector(Selectors.listing.getAvailabiltyDate);
  const [userSelected, setUserSelected] = useState();
  const [isNewUserForm, setIsNewUserForm] = useState(false);
  const {
    control: linkedControl,
    handleSubmit: linkedHandleSubmit,
    formState: {
      errors: linkedErrors
    }
  } = useForm({
    defaultValues: {
      occupancyDateRequested: DateTime.fromJSDate(listingAvailabilityDate).toUTC()
    }
  });
  const {
    control: newControl,
    handleSubmit: newHandleSubmit,
    formState: {
      errors: newErrors
    }
  } = useForm({
    defaultValues: {
      occupancyDateRequested: DateTime.fromJSDate(listingAvailabilityDate).toUTC()
    }
  }); // According to react hook form docs, it is best to create separate forms than have conditional fields

  const onSubmitLinked = data => {
    props.onDoneSubmit({
      id: data.id,
      occupancyDateRequested: rehydrateDateTimeObject(data.occupancyDateRequested).toUTC().toISODate()
    });
  };

  const onSubmitNew = data => {
    props.onDoneSubmit({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      primaryPhone: data.primaryPhone === "+1" ? null : data.primaryPhone,
      primaryChecked: data.primaryChecked,
      secondaryPhone: data.secondaryPhone,
      secondaryChecked: data.secondaryChecked,
      language: data.language.value,
      notificationEnabled: data.notificationEnabled,
      candidateDOB: rehydrateDateTimeObject(data.candidateDOB).toUTC().toISODate(),
      occupancyDateRequested: rehydrateDateTimeObject(data.occupancyDateRequested).toISODate()
    });
  }; // tw="border-4 border-green-700 border-dashed"


  const userSelectionForm = () => __cssprop("form", {
    onSubmit: linkedHandleSubmit(onSubmitLinked),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "id",
    control: linkedControl,
    render: ({
      field
    }) => __cssprop(ApplicantSelector, {
      selected: userSelected,
      onChange: v => {
        field.onChange(v.value);
      },
      placeholder: t("project-info-responsible-selector"),
      onlyClient: false
    })
  })), listingIsPublished && __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "occupancyDateRequested",
    control: linkedControl,
    render: ({
      field
    }) => __cssprop(DatePicker, _extends({}, field, {
      id: "occupancyDateRequested",
      defaultValue: field.value,
      onChange: v => field.onChange(v.toUTC()),
      title: t("applicant-request-form-section-information-address-occupation-date"),
      error: showError(newErrors, "occupancyDateRequested", t("applicant-request-form-section-information-address-occupation-date"))
    }))
  })), __cssprop(Button, {
    submit: true,
    loading: isLoading,
    disabled: Object.keys(linkedErrors).length !== 0,
    primary: true
  }, t("save")));

  const newUserForm = () => __cssprop("form", {
    onSubmit: newHandleSubmit(onSubmitNew),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "firstName",
    control: newControl,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(newErrors, "firstName", t("user-info-error-first-name")),
      id: "firstName"
    }, field, {
      label: t("user-info-form-first-name"),
      placeholder: t("user-info-form-first-name-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "lastName",
    control: newControl,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(newErrors, "lastName", t("user-info-error-last-name")),
      id: "lastName"
    }, field, {
      label: t("user-info-form-last-name"),
      placeholder: t("user-info-form-last-name-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop(React.Fragment, null, __cssprop(Controller, {
    name: "primaryPhone",
    control: newControl,
    rules: getPhoneValidationForForm(),
    render: ({
      field
    }) => __cssprop(PhoneInput, _extends({}, field, {
      label: t("user-info-form-primary-phone"),
      errorLabel: showError(newErrors, "primaryPhone", t("user-info-error-phone-required"))?.errorMessage,
      zIndex: 30
    }))
  }), __cssprop(Controller, {
    name: "primaryChecked",
    control: newControl,
    defaultValue: true,
    render: ({
      field
    }) => __cssprop("div", {
      css: {
        "display": "flex"
      }
    }, __cssprop("div", {
      css: {
        "flex": "1 1 0%",
        "lineHeight": "2.4rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      }
    }, t("user-info-form-cellphone")), __cssprop(StyledSwitch, _extends({}, field, {
      checked: field.value,
      onChange: field.onChange
    }), __cssprop("span", {
      css: {
        "position": "absolute",
        "width": "1px",
        "height": "1px",
        "padding": "0",
        "margin": "-1px",
        "overflow": "hidden",
        "clip": "rect(0, 0, 0, 0)",
        "whiteSpace": "nowrap",
        "borderWidth": "0"
      }
    }, "Use setting"), __cssprop(StyledSpan, {
      "aria-hidden": "true",
      active: field.value
    })))
  }))), __cssprop(StyledItems, null, __cssprop(React.Fragment, null, __cssprop(Controller, {
    name: "secondaryPhone",
    control: newControl,
    rules: getPhoneValidationForForm(),
    render: ({
      field
    }) => __cssprop(PhoneInput, _extends({}, field, {
      label: t("user-info-form-secondary-phone"),
      errorLabel: showError(newErrors, "secondaryPhone", t("user-info-error-secondary-phone"))?.errorMessage,
      zIndex: 30
    }))
  }), __cssprop(Controller, {
    name: "secondaryChecked",
    control: newControl,
    render: ({
      field
    }) => __cssprop("div", {
      css: {
        "display": "flex"
      }
    }, __cssprop("div", {
      css: {
        "flex": "1 1 0%",
        "lineHeight": "2.4rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      }
    }, t("user-info-form-cellphone")), __cssprop(StyledSwitch, _extends({}, field, {
      checked: field.value,
      onChange: field.onChange
    }), __cssprop("span", {
      css: {
        "position": "absolute",
        "width": "1px",
        "height": "1px",
        "padding": "0",
        "margin": "-1px",
        "overflow": "hidden",
        "clip": "rect(0, 0, 0, 0)",
        "whiteSpace": "nowrap",
        "borderWidth": "0"
      }
    }, "Use setting"), __cssprop(StyledSpan, {
      "aria-hidden": "true",
      active: field.value
    })))
  }))), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "email",
    control: newControl,
    rules: getEmailValidationForForm(),
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(newErrors, "email", t("user-info-error-email-invalid")),
      id: "email"
    }, field, {
      label: t("email"),
      placeholder: t("user-info-form-email-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "candidateDOB",
    control: newControl,
    render: ({
      field
    }) => __cssprop(DatePicker, _extends({}, field, {
      defaultValue: field.value,
      error: showError(newErrors, "candidateDOB", t("deposit-form-error-deposit-date")),
      id: "candidateDOB",
      onChange: v => field.onChange(v.toUTC()),
      title: t("date-birth")
    }))
  })), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("user-preference-form-language")), __cssprop(Controller, {
    name: "language",
    control: newControl,
    defaultValue: {
      label: t("fr"),
      value: "fr"
    },
    rules: {
      validate: {
        isValid: v => {
          if (v?.value === "fr" || v?.value === "en") return true;
          return false;
        }
      }
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      id: "language",
      defaultValue: {
        label: t("fr"),
        value: "fr"
      },
      options: LANGUAGE_SELECT_OPTION(t),
      error: showError(newErrors, "language", t("user-preference-error-language")),
      isClearable: false
    }))
  }))), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("user-preference-form-notification")), __cssprop(Controller, {
    name: "notificationEnabled",
    defaultValue: false,
    control: newControl,
    render: ({
      field
    }) => __cssprop("div", {
      css: {
        "display": "flex"
      }
    }, __cssprop("div", {
      css: {
        "flex": "1 1 0%",
        "lineHeight": "2.4rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      }
    }, t("user-preference-form-notification-label")), __cssprop(StyledSwitch, _extends({}, field, {
      checked: field.value,
      onChange: field.onChange
    }), __cssprop("span", {
      css: {
        "position": "absolute",
        "width": "1px",
        "height": "1px",
        "padding": "0",
        "margin": "-1px",
        "overflow": "hidden",
        "clip": "rect(0, 0, 0, 0)",
        "whiteSpace": "nowrap",
        "borderWidth": "0"
      }
    }, "Use setting"), __cssprop(StyledSpan, {
      "aria-hidden": "true",
      active: field.value
    })))
  }))), listingIsPublished && __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "occupancyDateRequested",
    control: newControl,
    render: ({
      field
    }) => __cssprop(DatePicker, _extends({}, field, {
      defaultValue: field.value,
      error: showError(newErrors, "occupancyDateRequested", t("applicant-request-form-section-information-address-occupation-date")),
      id: "occupancyDateRequested",
      onChange: v => field.onChange(v.toUTC()),
      title: t("applicant-request-form-section-information-address-occupation-date")
    }))
  })), __cssprop(Button, {
    submit: true,
    loading: isLoading,
    disabled: Object.keys(newErrors).length !== 0,
    primary: true
  }, t("save")));

  return __cssprop("div", null, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, sectionTitle), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "maxWidth": "67.2rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, description)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("applicant-form-is-new-user")), __cssprop(StyledSwitch, {
    checked: isNewUserForm,
    onChange: event => setIsNewUserForm(event)
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Use setting"), __cssprop(StyledSpan, {
    "aria-hidden": "true",
    active: isNewUserForm
  }))), !isNewUserForm && userSelectionForm(), !!isNewUserForm && newUserForm()));
};
export default ApplicantSideBar;