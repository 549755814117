import { CalendarActionTypes, initialState } from "./types.js";
export const setCalendarField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};
export const addCalendarItem = (data, state) => {
  return { ...state,
    items: [...state.items, data.payload]
  };
};
export const updateCalendarItem = (data, state) => {
  return { ...state,
    items: state.items.map(item => item.id === data.payload.id ? { ...item,
      ...data.payload
    } : item)
  };
};
export const setCalendarInitialState = state => {
  return { ...state,
    ...initialState
  };
};
export default {
  [CalendarActionTypes.CALENDAR_SET_FIELD]: setCalendarField,
  [CalendarActionTypes.CALENDAR_SET_INITIAL_STATE]: setCalendarInitialState,
  [CalendarActionTypes.CALENDAR_ADD_ITEM]: addCalendarItem,
  [CalendarActionTypes.CALENDAR_UPDATE_ITEM]: updateCalendarItem
};