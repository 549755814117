import DeliverySideBar from "../../forms/SideBar/DeliverySideBar.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import React from "../../../../_snowpack/pkg/react.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const DeliveryPanel = props => {
  const {
    t
  } = useI18n();
  const activeDrawer = useSelector(Selectors.app.getDrawerState);
  const {
    dispatch
  } = useAction();
  const {
    onDoneSubmit,
    lease,
    loading
  } = props;
  return __cssprop(SlideOver, {
    size: "3xl",
    title: t("delivery-edit-title"),
    isOpen: activeDrawer === "delivery",
    onClose: () => dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    })
  }, __cssprop(DeliverySideBar, {
    loading: loading,
    lease: lease,
    onDoneSubmit: onDoneSubmit
  }));
};