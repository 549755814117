function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";

/* eslint-disable no-nested-ternary */
import { useTable, useSortBy } from "../../../../_snowpack/pkg/react-table.js";
import { useMemo, useEffect } from "../../../../_snowpack/pkg/react.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { IoIosArrowUp, IoIosArrowDown } from "../../../../_snowpack/pkg/react-icons/io.js";
import { Pagination } from "./PaginationV2.js";
import useI18n from "../../hooks/useI18n.js";
import { FaFilter } from "../../../../_snowpack/pkg/react-icons/fa.js";
import { HiSearch } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { Loader } from "../Loader.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledTr = _styled.tr(({
  open
}) => [open && {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(196, 181, 253, var(--tw-border-opacity)) !important",
  "borderBottomWidth": "0px",
  "borderLeftWidth": "8px",
  "borderRightWidth": "8px",
  "borderTopWidth": "4px !important"
}]);

const NoItemsFound = ({
  onClick
}) => {
  const {
    t
  } = useI18n();
  return __cssprop("div", {
    css: {
      "textAlign": "center"
    }
  }, __cssprop(HiSearch, {
    css: {
      "marginLeft": "auto",
      "marginRight": "auto",
      "height": "4.8rem",
      "width": "4.8rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    }
  }), __cssprop("h3", {
    css: {
      "marginTop": "0.8rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("data-not-found")), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("change-filters-settings")), __cssprop("div", {
    css: {
      "marginTop": "2.4rem"
    }
  }, __cssprop("button", {
    onClick: onClick,
    type: "button",
    css: {
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "borderRadius": "0.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(67, 56, 202, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop(FaFilter, {
    css: {
      "marginLeft": "-0.4rem",
      "marginRight": "0.8rem",
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  }), t("change-filters"))));
};

export const DataTable = ({
  renderItem,
  columns,
  onShowFilters,
  totalItems,
  onSort,
  loading,
  items,
  itemsPerPage,
  onPageChange,
  onTabChange,
  resourceName,
  currentPage
}) => {
  const data = useMemo(() => items.map(renderItem), [items, renderItem]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: {
      sortBy
    }
  } = useTable({
    columns,
    data,
    manualSortBy: true,
    disableSortRemove: true
  }, useSortBy);
  useEffect(() => {
    if (sortBy.length !== 0) {
      onSort({
        [sortBy[0].id]: sortBy[0].desc ? "Desc" : "Asc"
      });
    }
  }, [sortBy]);
  return __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "verticalAlign": "middle",
      "display": "inline-block",
      "minWidth": "100%"
    }
  }, __cssprop("div", {
    css: {
      "overflow": "hidden",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(243, 244, 246, var(--tw-border-opacity))"
    }
  }, __cssprop("table", _extends({
    css: {
      "minWidth": "100%",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, getTableProps()), __cssprop("thead", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
    }
  }, headerGroups.map((headerGroup, index) => __cssprop("tr", _extends({}, headerGroup.getHeaderGroupProps(), {
    key: `header-item-${index}`
  }), headerGroup.headers.map(column => __cssprop("th", _extends({
    key: `col-item-${index}`,
    scope: "col",
    css: {
      "paddingLeft": "2.4rem",
      "paddingRight": "2.4rem",
      "paddingTop": "1.2rem",
      "paddingBottom": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "textTransform": "uppercase",
      "letterSpacing": "0.05em"
    }
  }, column.getHeaderProps(column.getSortByToggleProps())), __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, column.render("Header"), __cssprop("span", {
    css: {
      "paddingLeft": "0.4rem",
      "display": "flex"
    }
  }, column.isSorted ? column.isSortedDesc ? __cssprop(IoIosArrowDown, {
    css: {
      "width": "1.6rem",
      "height": "1.6rem"
    }
  }) : __cssprop(IoIosArrowUp, {
    css: {
      "width": "1.6rem",
      "height": "1.6rem"
    }
  }) : ""))))))), __cssprop("tbody", _extends({
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, getTableBodyProps()), loading && __cssprop("tr", {
    css: {
      "width": "100%"
    }
  }, __cssprop("td", {
    align: "center",
    colSpan: columns.length
  }, __cssprop("div", {
    css: {
      "padding": "6.4rem"
    }
  }, __cssprop(Loader, null)))), !loading && rows.length === 0 && __cssprop("tr", {
    css: {
      "width": "100%"
    }
  }, __cssprop("td", {
    align: "center",
    colSpan: columns.length
  }, __cssprop("div", {
    css: {
      "padding": "6.4rem"
    }
  }, __cssprop(NoItemsFound, {
    onClick: onShowFilters
  })))), !loading && rows.map(row => {
    prepareRow(row);
    return __cssprop(StyledTr, row.getRowProps(), row.cells.map((cell, index) => {
      return __cssprop("td", _extends({
        css: {
          "paddingLeft": "2.4rem",
          "paddingRight": "2.4rem",
          "paddingTop": "1.6rem",
          "paddingBottom": "1.6rem",
          "whiteSpace": "nowrap"
        },
        key: `row-item-${index}`
      }, cell.getCellProps()), __cssprop("div", {
        css: {
          "display": "flex",
          "flex": "1 1 0%",
          "alignItems": "center"
        }
      }, cell.render("Cell")));
    }));
  }))))), __cssprop(Pagination, {
    currentPage: currentPage,
    totalItems: totalItems,
    pageSize: itemsPerPage,
    resourceLabel: resourceName,
    onPageChange: onPageChange,
    onTabChange: onTabChange
  }));
};