import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../../_snowpack/pkg/react.js";
import { Page } from "../../components/Tailwind/Page.js";
import { SupportIcon, ChevronDownIcon, ChatIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { Disclosure } from "../../../../_snowpack/pkg/@headlessui/react.js";
import useI18n from "../../hooks/useI18n.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const ArrowIcon = _styled(ChevronDownIcon)(({
  open
}) => [{
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "0",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "height": "2.4rem",
  "width": "2.4rem"
}, open ? {
  "--tw-rotate": "-180deg"
} : `rotate-0`]);
export const HelpPage = () => {
  const {
    t
  } = useI18n();
  const supportLinks = [{
    name: t("help-page-card-1-title"),
    action: t("help-page-card-1-action"),
    onClick: () => {},
    description: t("help-page-card-1-description"),
    icon: ChatIcon
  }, {
    name: t("help-page-card-2-title"),
    action: t("help-page-card-2-action"),
    href: "https://support.simpleloyer.ca",
    description: t("help-page-card-2-description"),
    icon: SupportIcon
  }];
  return __cssprop(Page, {
    fullWidth: true,
    title: "",
    fullPageHeader: {
      title: t("help-page-title"),
      description: t("help-page-description"),
      backgroundImage: "/img/john-schnobrich-2FPjlAyMQTA-unsplash.jpg"
    }
  }, __cssprop("div", null, __cssprop("section", {
    css: {
      "marginTop": "-12.8rem",
      "maxWidth": "128rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "position": "relative",
      "zIndex": "10",
      "paddingBottom": "12.8rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 1024px)": {
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem"
      }
    },
    "aria-labelledby": "contact-heading"
  }, __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
      "rowGap": "8rem",
      "@media (min-width: 1024px)": {
        "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
        "rowGap": "0px",
        "columnGap": "3.2rem"
      }
    }
  }, supportLinks.map(link => __cssprop("div", {
    key: link.name,
    css: {
      "display": "flex",
      "flexDirection": "column",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderRadius": "1.6rem",
      "--tw-shadow": "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "position": "relative",
      "paddingTop": "6.4rem",
      "paddingLeft": "2.4rem",
      "paddingRight": "2.4rem",
      "paddingBottom": "3.2rem",
      "@media (min-width: 768px)": {
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "--tw-translate-x": "0",
      "--tw-translate-y": "-50%",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "position": "absolute",
      "top": "0px",
      "padding": "2rem",
      "display": "inline-block",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))",
      "borderRadius": "1.2rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  }, __cssprop(link.icon, {
    css: {
      "height": "2.4rem",
      "width": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })), __cssprop("h3", {
    css: {
      "fontSize": "2rem",
      "lineHeight": "2.8rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, link.name), __cssprop("p", {
    css: {
      "marginTop": "1.6rem",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, link.description)), __cssprop("div", {
    css: {
      "padding": "2.4rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "borderBottomLeftRadius": "1.6rem",
      "borderBottomRightRadius": "1.6rem",
      "@media (min-width: 768px)": {
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem"
      }
    }
  }, link.href && __cssprop("a", {
    href: link.href,
    css: {
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(67, 56, 202, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(79, 70, 229, var(--tw-text-opacity))"
      },
      "cursor": "pointer"
    }
  }, link.action, __cssprop("span", {
    "aria-hidden": "true"
  }, " \u2192")), link.onClick && __cssprop("a", {
    onClick: link.onClick,
    "aria-hidden": true,
    css: {
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(67, 56, 202, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(79, 70, 229, var(--tw-text-opacity))"
      },
      "cursor": "pointer"
    }
  }, link.action, __cssprop("span", {
    "aria-hidden": "true"
  }, " \u2192"))))))), __cssprop("div", {
    css: {
      "marginTop": "-6.4rem"
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "128rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingTop": "0px",
      "paddingBottom": "0px",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingTop": "6.4rem",
        "paddingBottom": "6.4rem",
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 1024px)": {
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "76.8rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(2px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(2px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("h2", {
    css: {
      "textAlign": "center",
      "fontSize": "3rem",
      "lineHeight": "3.6rem",
      "fontWeight": "800",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "fontSize": "3.6rem",
        "lineHeight": "4rem"
      }
    }
  }, t("help-page-faq-title")), __cssprop("dl", {
    css: {
      "marginTop": "2.4rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))",
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, Array.from(Array(8)).map((_, i) => __cssprop(Disclosure, {
    as: "div",
    key: `faq-${i + 1}`,
    css: {
      "paddingTop": "2.4rem"
    }
  }, ({
    open
  }) => __cssprop(React.Fragment, null, __cssprop("dt", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.8rem"
    }
  }, __cssprop(Disclosure.Button, {
    css: {
      "textAlign": "left",
      "width": "100%",
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "flex-start",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    }
  }, __cssprop("span", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t(`help-page-faq-item-${i + 1}-q`)), __cssprop("span", {
    css: {
      "marginLeft": "2.4rem",
      "height": "2.8rem",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop(ArrowIcon, {
    open: open,
    "aria-hidden": "true"
  })))), __cssprop(Disclosure.Panel, {
    as: "dd",
    css: {
      "marginTop": "0.8rem",
      "paddingRight": "4.8rem"
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t(`help-page-faq-item-${i + 1}-awnser`))))))))))));
};