import { MeDocument } from "../../../graphql/documents.js";
import { loginWithFacebook, loginWithGoogle, loginWithMicrosoft, loginWithUnamePwd } from "../../../services/authentication.js";
import { sendEmailReset } from "../../../services/firebase.js";
import { Settings } from "../../../../../_snowpack/pkg/luxon.js";
import { all, call, getContext, put, putResolve, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import i18n from "../../../../../_snowpack/pkg/i18next.js";
import { ApplicationActionsTypes } from "../Application/types.js";
import StatusActions from "../Status/actions.js";
import { SessionActionsTypes } from "./types.js";
export function* onForgotPasswordAttempt(action) {
  const username = action.payload;

  try {
    yield put(StatusActions.setResourceToSubmit("user"));
    yield call(sendEmailReset, username);
    yield put(StatusActions.setResourceToSuccess("user", "login-success-password-reset"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("user", "login-error-password-reset"));
  }
}
export function* onUserLoginAttempt(action) {
  const {
    login,
    username,
    password,
    rememberMe
  } = action.payload;
  yield put(StatusActions.setResourceToSubmit("user"));
  yield put({
    type: ApplicationActionsTypes.SET_REMEMBER_ME_EMAIL,
    payload: rememberMe ? username : null
  });

  try {
    let user = null;

    switch (login) {
      case "password":
        user = yield call(loginWithUnamePwd, username, password);
        break;

      case "facebook":
        user = yield call(loginWithFacebook);
        break;

      case "google":
        user = yield call(loginWithGoogle);
        break;

      case "microsoft":
        user = yield call(loginWithMicrosoft);
        break;

      default:
        break;
    }

    if (!user) {
      yield put(StatusActions.setResourceToError("user", "login-error"));
      return;
    }

    const client = yield getContext("client");
    const query = yield client.query({
      query: MeDocument,
      fetchPolicy: "no-cache",
      context: {
        headers: {
          Authorization: `Bearer ${user.token}`
        }
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("user", "login-error"));
      return;
    }

    yield putResolve({
      type: SessionActionsTypes.SESSION_SET_FIELD,
      payload: {
        lastRequestTimestamp: 0,
        token: user.token
      }
    }); // reset feature toggle

    yield put({
      type: ApplicationActionsTypes.RESET_FEATURE_TOGGLES
    });

    if (i18n.language !== data.me.language.toLowerCase()) {
      const lang = data.me.language.toLowerCase();
      Settings.defaultLocale = data.me.language;
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
    }

    yield putResolve({
      type: SessionActionsTypes.SESSION_SET_USER_FIELD,
      payload: { ...data.me,
        name: `${data.me.firstName} ${data.me.lastName}`,
        role: data.me.role || "Client",
        isNotificationsEnabled: data.me.notificationEnabled,
        picture: data.me.profileImage
      }
    });
    yield put({
      type: ApplicationActionsTypes.UPDATE_INTERCOM_USER
    });
    yield put(StatusActions.setResourceToIdle("user"));
    const history = yield getContext("history");

    if (data.me.role === "VisitAgent") {
      history.push("/marketing");
    } else {
      history.push("/properties");
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("user", "login-error"));
  }
}
export function* onUserLogout() {
  yield put(StatusActions.resetAll());
  const history = yield getContext("history");
  history.push("/");
}
export default {
  *executers() {
    yield all([yield takeLatest(SessionActionsTypes.ON_USER_LOGIN, onUserLoginAttempt), yield takeLatest(SessionActionsTypes.ON_USER_LOGOUT, onUserLogout), yield takeLatest(SessionActionsTypes.ON_FORGOT_PASSWORD, onForgotPasswordAttempt)]);
  }

};