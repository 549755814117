import * as React from "../../../_snowpack/pkg/react.js";
import { useCallback, useState } from "../../../_snowpack/pkg/react.js";
import useI18n from "../hooks/useI18n.js";
import { cancelChoice } from "../utils/applicant.js";
import { Modal } from "../components/Tailwind/Modal.js";
import { useAction } from "../hooks/useRedux.js";
import { RadioGroup } from "../components/Tailwind/RadioGroup.js";
import { TextField } from "../components/Tailwind/TextField.js";
import { ApplicationActionsTypes } from "../store/artifacts/Application/types.js";
import ToggleSection from "../components/ToggleSection.js";
import { RentalApplicationRejectionReason } from "../graphql/documents.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const ConfirmModal = props => {
  const {
    onSuccess,
    label,
    options,
    modalName,
    defaultValue
  } = props;
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const [selected, setSelected] = useState(defaultValue || "none");
  const [textFieldValue, setTextFieldValue] = useState();
  const handleTextFieldChange = useCallback(value => setTextFieldValue(value), []);
  const [error, setError] = useState();
  const handleChoiceListChange = useCallback(value => setSelected(value), []);
  const renderChildren = useCallback(isSelected => isSelected && __cssprop(TextField, {
    id: "choice",
    onChange: handleTextFieldChange,
    value: textFieldValue
  }), [handleTextFieldChange, textFieldValue]);
  const getValues = useCallback(() => ({
    selected,
    textFieldValue
  }), [selected, textFieldValue]);
  const validateValues = useCallback(() => {
    if (getValues().selected === "none") {
      setError(t("user-preference-error-notification"));
      return false;
    }

    setError(undefined);
    return true;
  }, [getValues, selected]);

  const getSelected = s => {
    return cancelChoice(t, renderChildren).find(item => item.id === s);
  };

  const renderChoice = name => {
    if (name === "refuseApplicant") {
      return __cssprop(RadioGroup, {
        error: error,
        onChange: handleChoiceListChange,
        selected: getSelected(selected),
        settings: cancelChoice(t, renderChildren(getSelected(selected)))
      });
    }

    if (name === "approveApplication") {
      return __cssprop("div", {
        css: {
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-y-reverse": 0,
            "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
            "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))"
          }
        }
      }, __cssprop(TextField, {
        id: "approveApplication",
        value: textFieldValue,
        onChange: handleTextFieldChange
      }), __cssprop(ToggleSection, {
        title: t("applicant-request-force-owner-approval"),
        handleToggle: event => {
          setSelected(event ? "none" : "force");
        },
        active: selected === "force"
      }));
    }

    if (modalName === "inviteApplicant") {
      return __cssprop("div", {
        css: {
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-y-reverse": 0,
            "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
            "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))"
          }
        }
      }, __cssprop(TextField, {
        id: "inviteApplicant",
        value: textFieldValue,
        onChange: handleTextFieldChange
      }));
    }

    return null;
  };

  return __cssprop(Modal, {
    modalName: modalName,
    title: t("confirm-modal-title"),
    description: label,
    buttonContent: t("confirm-modal-confirm-button"),
    type: ["refuseApplicant", "deleteApplicant"].includes(modalName) ? "destructive" : "successful",
    onAction: () => {
      if (!options) {
        onSuccess(true, getValues());
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
          payload: null
        });
      } else if (validateValues()) {
        onSuccess(true, getValues());
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
          payload: null
        });
      }
    },
    other: renderChoice(modalName)
  });
};

const useConfirmModal = (label, modalName, onSuccess, options) => {
  const [values, setValues] = useState();
  const {
    dispatch
  } = useAction();
  const defaultValue = modalName === "refuseApplicant" ? RentalApplicationRejectionReason.BadCredit : null; // return modal, open toggle,

  return [() => __cssprop(ConfirmModal, {
    onSuccess: (result, pValues) => {
      onSuccess(result, values, pValues);
    },
    label: label,
    options: options,
    defaultValue: defaultValue,
    modalName: modalName
  }), _values => {
    setValues(_values);
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
      payload: modalName
    });
  }];
};

export default useConfirmModal;