import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import { GetTaskDocument } from "../../graphql/documents.js";
import { Loader } from "../../components/Loader.js";
import { useLazyQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { TaskSideBarFormView } from "../sections/TaskSideBarFormView.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const TaskSideBar = ({
  sectionTitle,
  refetch,
  ticket,
  taskId
}) => {
  const [isReady, setIsReady] = useState(false);
  const [taskState, setTaskState] = useState({
    dueDate: DateTime.utc(),
    title: "",
    assigneeId: undefined,
    assignee: undefined,
    taskId,
    ticket,
    closedAt: null,
    refetch,
    sectionTitle
  });
  const [loadTask, {
    loading: queryLoading
  }] = useLazyQuery(GetTaskDocument, {
    variables: {
      taskId
    },
    onCompleted: taskData => {
      const {
        task
      } = taskData;

      if (!task) {
        return;
      } // pass values to form component


      setTaskState({ ...taskState,
        closedAt: task.closedAt,
        dueDate: task.dueDate,
        title: task.title,
        assigneeId: task.assignee?.id,
        assignee: task.assignee
      });
      setIsReady(true);
    }
  });
  useEffect(() => {
    if (taskId) loadTask(); // if taskId is passed then get task details
    else setIsReady(true); // component is ready to render form
  }, []);

  if (queryLoading || !isReady) {
    return __cssprop("div", {
      tw: "absolute bg-gray-50 z-10 h-full w-full opacity-60"
    }, __cssprop(Loader, null));
  }

  return __cssprop(TaskSideBarFormView, {
    dueDate: taskState.dueDate,
    title: taskState.title,
    assigneeId: taskState.assigneeId,
    assignee: taskState.assignee,
    ticket: taskState.ticket,
    taskId: taskState.taskId,
    refetch: refetch,
    sectionTitle: sectionTitle
  });
};