function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React, { useCallback } from "../../../../../_snowpack/pkg/react.js";
import { useQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import useDebounce from "../../../hooks/useDebounce.js";
import { useForm, Controller } from "../../../../../_snowpack/pkg/react-hook-form.js";
import ReactSelect from "../../../../../_snowpack/pkg/react-select.js";
import { Select } from "../../../components/Select/index.js";
import { Badge } from "../../../components/Tailwind/Badge.js";
import { SlideOver } from "../../../components/Tailwind/SlideOver.js";
import { TextField } from "../../../components/Tailwind/TextField.js";
import { DataList } from "../../../components/Tailwind/DataList.js";
import { Button } from "../../../components/Tailwind/Button.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import useI18n from "../../../hooks/useI18n.js";
import { LeaseActionsTypes } from "../../../store/artifacts/Lease/types.js";
import { PhoneInput } from "../../../components/PhoneInput/index.js";
import { formatPhoneNumber } from "../../../utils/tenant.js";
import { GetTenantsDocument, PaymentMethod } from "../../../graphql/documents.js";
import { Checkbox } from "../../../components/Checkbox/index.js";
import { LEASE_BILLING_SELECT_OPTIONS } from "../../../utils/unit.js";
import FileDropper from "../../ApplicantRequestV2/components/fileDropper.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

export const TenantsView = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const isLocked = useSelector(Selectors.lease.isLocked);
  const tenants = useSelector(Selectors.lease.getTenants);
  const leaseId = useSelector(Selectors.lease.getId);
  const files = useSelector(Selectors.lease.getFiles);
  const [searchString, setSearchString] = React.useState("");
  const debouncedSearch = useDebounce(searchString, 500);
  const [slideShown, setSlideShown] = React.useState(false);
  const {
    loading,
    data
  } = useQuery(GetTenantsDocument, {
    variables: {
      take: 100,
      query: debouncedSearch,
      skip: 0
    }
  });
  const {
    control,
    handleSubmit,
    formState: {
      errors
    },
    reset
  } = useForm();

  const onSubmit = d => {
    dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        tenants: [...tenants, {
          kind: "new",
          email: d.email,
          firstName: d.firstName,
          lastName: d.lastName,
          phone: d.phone,
          isPayee: false,
          paymentMethod: PaymentMethod.DirectDebit
        }]
      }
    });
    setSlideShown(false);
    reset({
      name: null,
      email: null,
      phone: null
    });
  };

  const onPayeeChange = useCallback(id => () => {
    const $tenants = tenants.reduce((acc, tenant) => {
      acc.push({ ...tenant,
        isPayee: tenant.id === id
      });
      return acc;
    }, []);
    dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        tenants: $tenants
      }
    });
  }, [tenants]);
  const onPaymentMethodChange = useCallback(id => pm => {
    const $tenants = tenants.reduce((acc, tenant) => {
      if (tenant.id === id) {
        acc.push({ ...tenant,
          paymentMethod: pm?.value
        });
      } else {
        acc.push(tenant);
      }

      return acc;
    }, []);
    dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        tenants: $tenants
      }
    });
  }, [tenants]);
  const rows = tenants?.map(item => {
    const items = [[item.kind === "new" && __cssprop(Badge, null, t("lease-viewer-new-item")), __cssprop("div", {
      css: {
        "marginRight": "0.8rem"
      }
    }, item.firstName && item.firstName !== "" ? `${item.firstName} ${item.lastName}` : `${item.name}`)], [item.email], [formatPhoneNumber(item?.phone)], [__cssprop(Checkbox, {
      id: `lease-payee-checkbox-${item.id}`,
      name: "",
      label: "",
      onChange: onPayeeChange(item.id),
      checked: item?.isPayee,
      disabled: isLocked
    })], [__cssprop(Select, {
      label: "",
      items: LEASE_BILLING_SELECT_OPTIONS(t),
      selected: LEASE_BILLING_SELECT_OPTIONS(t).find(pm => pm.value === item?.paymentMethod),
      onSelect: onPaymentMethodChange(item.id),
      disabled: isLocked
    })], [__cssprop("button", {
      type: "button",
      onClick: () => dispatch({
        type: LeaseActionsTypes.LEASE_SET_FIELD,
        payload: {
          tenants: tenants.filter(i => item.id !== i.id)
        }
      }),
      css: {
        "marginLeft": "2.4rem",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
        "borderRadius": "0.6rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-offset-width": "2px",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
        }
      }
    }, t("lease-page-form-tenant-remove"))]];

    if (isLocked) {
      items.pop();
    }

    const row = {
      items
    };
    return row;
  });
  const items = data?.tenants?.items.map(i => {
    return {
      value: i.id,
      label: `${i.name}`
    };
  }) // filter tenant so you cant add 2 time the same tenant
  .filter(item => !tenants?.find(te => te.kind === "existing" && te.id === item.value));
  const onTenantSelect = React.useCallback(te => {
    if (!te?.value || data?.tenants?.items?.length === 0) {
      return;
    }

    const tenant = data?.tenants?.items?.find(i => i.id === te.value);
    dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        tenants: [...tenants, {
          kind: "existing",
          id: tenant.id,
          email: tenant.email,
          firstName: tenant.firstName,
          lastName: tenant.lastName,
          phone: tenant.primaryPhone,
          isPayee: tenants.length === 0,
          paymentMethod: PaymentMethod.DirectDebit
        }]
      }
    });
  }, [tenants, data]);
  return __cssprop(React.Fragment, null, __cssprop(SlideOver, {
    title: t("tenant-add"),
    isOpen: slideShown,
    onClose: () => setSlideShown(false)
  }, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(Controller, {
    name: "firstName",
    control: control,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "firstName", t("user-info-error-first-name")),
      id: "firstName"
    }, field, {
      label: t("user-info-form-first-name"),
      placeholder: t("user-info-form-first-name-placeholder")
    }))
  }), __cssprop(Controller, {
    name: "lastName",
    control: control,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "lastName", t("user-info-error-last-name")),
      id: "lastName"
    }, field, {
      label: t("user-info-form-last-name"),
      placeholder: t("user-info-form-last-name-placeholder")
    }))
  }), __cssprop(Controller, {
    name: "email",
    control: control,
    rules: {
      required: false
    },
    defaultValue: null,
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      defaultValue: null,
      error: showError(errors, "email", t("user-info-error-email-invalid")),
      id: "email"
    }, field, {
      label: t("email"),
      placeholder: t("user-info-form-email-placeholder")
    }))
  }), __cssprop(Controller, {
    name: "phone",
    control: control,
    defaultValue: null,
    render: ({
      field
    }) => __cssprop(PhoneInput, _extends({}, field, {
      label: t("user-info-form-primary-phone"),
      errorLabel: showError(errors, "phone", t("user-info-error-phone-required"))?.errorMessage,
      zIndex: 30
    }))
  }), __cssprop(Button, {
    submit: true,
    primary: true
  }, t("submit")))), __cssprop("div", {
    css: {
      "marginTop": "1.6rem"
    }
  }, !isLocked && __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "marginRight": "0.8rem"
    }
  }, __cssprop(ReactSelect, {
    css: {
      "--tw-shadow": "0 0 #0000",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    },
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: "hsl(0,0%,80%)"
      })
    },
    isClearable: true,
    placeholder: t("lease-page-form-tenant-select-placeholder"),
    menuContainerStyle: {
      zIndex: 25
    },
    onInputChange: setSearchString,
    isLoading: loading,
    onChange: onTenantSelect,
    options: items,
    hideSelectedOptions: true
  })), __cssprop(Button, {
    secondary: true,
    onAction: () => setSlideShown(true)
  }, t("lease-page-form-tenant-add-tenant"))))), __cssprop("div", {
    css: {
      "marginTop": "1.6rem"
    }
  }, __cssprop(DataList, {
    headings: [t("names"), t("emails"), t("phones"), t("lease-payee-checkbox"), t("unit-lease-info-label-payment-method"), ""],
    title: t("tenants"),
    rows: rows
  }))), leaseId && __cssprop(Card, null, __cssprop(FileDropper, {
    resourceId: leaseId,
    resourceType: RESOURCES.Lease,
    files: files.filter(f => f.kind === "remote") || [],
    readOnly: false,
    withSubmitButton: true
  })));
};