/* eslint-disable no-underscore-dangle */
import { createReducer } from "../../helpers/actions.js";
import sagas from "./sagas.js";
import { initialState } from "./types.js";
export const getDeposit = state => state?.deposit;

const getAmount = state => getDeposit(state).amount;

export default {
  sagas: sagas.executers,
  reducer: createReducer(initialState, sagas.reducers),
  selectors: {
    getId: state => getDeposit(state).id,
    getAmount,
    getProject: state => getDeposit(state).project,
    getDepositBy: state => getDeposit(state).depositBy,
    getDepositFor: state => getDeposit(state).depositFor,
    getDepositDate: state => getDeposit(state).depositDate,
    getCreationDate: state => getDeposit(state).creationDate,
    getCreatedBy: state => getDeposit(state).createdBy,
    getNotes: state => getDeposit(state).notes,
    getFiles: state => getDeposit(state).files,
    getServerDeposit: state => getDeposit(state).serverDeposit,
    isLoading: state => {
      const b = getDeposit(state);
      return b.serverDeposit.kind === "Loading";
    }
  }
};