import LeaseViewer from "../../components/LeaseViewer.js";
import { Loader } from "../../components/Loader.js";
import { TenantPanel } from "../../components/Panels/TenantPanel.js";
import { Attachments } from "../../components/Tailwind/Attachments.js";
import { DescriptionList } from "../../components/Tailwind/DescriptionList.js";
import { EmptyState } from "../../components/Tailwind/EmptyState.js";
import { Notes } from "../../components/Tailwind/Notes.js";
import TicketInfoRelatedTickets from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoRelatedTickets.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useDeleteModal, { RESOURCE } from "../../forms/useDeleteModal.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { TenantActionsTypes } from "../../store/artifacts/Tenant/types.js";
import { formatPhoneNumber } from "../../utils/tenant.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useEffect } from "../../../../_snowpack/pkg/react.js";
import { Card } from "../../components/Tailwind/Card.js";
import { DataList } from "../../components/Tailwind/DataList.js";
import { Layout } from "../../components/Tailwind/Layout.js";
import { Link } from "../../components/Tailwind/Link.js";
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedButton, ProtectedCard, ProtectedCardSection } from "../../components/Tailwind/ProtectedComponents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const TenantsInfo = withBreadcrumb(({
  history,
  match,
  setCrumb,
  crumbs
}) => {
  const {
    t
  } = useI18n();
  const {
    id
  } = match.params;
  const role = useSelector(Selectors.session.getUserRole);
  const [DeleteModal, _openDeleteModal, deleteModalAction] = useDeleteModal(id, RESOURCE.Tenant); // redux stuff

  const tenantId = useSelector(Selectors.tenant.getId);
  const name = useSelector(Selectors.tenant.getName);
  const loading = useSelector(Selectors.status.isLoading("tenant"));
  const {
    dispatch
  } = useAction();
  useEffect(() => {
    if (!tenantId && !loading) {
      setTimeout(() => {
        dispatch({
          type: TenantActionsTypes.TENANT_INITIALIZE,
          payload: {
            kind: "WithTenantId",
            tenantId: id
          }
        });
      }, 0);
    }
  }, []);
  useEffect(() => {
    if (!loading && name) {
      setCrumb({
        title: name,
        path: `/tenants/${tenantId}`,
        level: 2
      });
    }
  }, [loading]);

  if (loading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  const onDoneSubmit = data => {
    dispatch({
      type: TenantActionsTypes.TENANT_SUBMIT,
      payload: { ...data
      }
    });
  };

  return __cssprop(React.Fragment, null, __cssprop(TenantPanel, {
    formTitle: t("tenant-form-modify-tenant"),
    description: "",
    sectionTitle: "",
    onDoneSubmit: onDoneSubmit
  }), __cssprop(Page, {
    title: name,
    breadcrumbs: crumbs,
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Tenant",
        permission: "mutate:update"
      },
      primary: true,
      onAction: () => dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: "tenant"
      })
    }, t("tenant-info-modify"))
  }, __cssprop(TenantView, {
    history: history,
    id: id
  })));
});

const TenantView = ({
  history,
  id
}) => {
  const {
    t
  } = useI18n();
  const role = useSelector(Selectors.session.getUserRole);
  const activeLeases = useSelector(Selectors.tenant.getActiveLeases);
  const noteLoading = useSelector(Selectors.status.isSubmiting("note"));
  const notes = useSelector(Selectors.tenant.getNotes);
  const tickets = useSelector(Selectors.tenant.getTickets);
  const {
    dispatch
  } = useAction();
  return __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop("div", null, activeLeases && activeLeases.length > 0 ? __cssprop(React.Fragment, null, activeLeases.map(activeLease => __cssprop(LeaseViewer, {
    kind: "tenant",
    lease: activeLease.lease
  }))) : __cssprop(EmptyState, {
    image: "/img/noLeaseHistoryForSelectedTenant.svg",
    heading: t("tenant-info-no-lease")
  }, __cssprop("p", null, t("tenant-info-no-lease-description"))), __cssprop(LeaseHistory, null))), __cssprop(Layout, {
    oneThird: true
  }, __cssprop(TentantInfoCard, null), tickets.length > 0 && __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(TicketInfoRelatedTickets, {
    data: tickets
  }))), __cssprop(Notes, {
    notes: notes,
    loading: noteLoading,
    permission: {
      kind: "Tenant",
      permission: "view:notes"
    },
    addNoteEvent: pNotes => {
      dispatch({
        payload: {
          resourceId: id,
          resourceType: "tenant",
          actionType: TenantActionsTypes.TENANT_ADD_NOTES,
          content: pNotes.note,
          fromPage: true
        },
        type: TenantActionsTypes.TENANT_ADD_NOTES_FORMLESS
      });
    },
    hideNewNote: role === "Client" || role === "User",
    removeNoteEvent: pNotes => {
      dispatch({
        payload: {
          noteId: pNotes.id,
          actionType: TenantActionsTypes.TENANT_REMOVE_NOTES,
          fromPage: true
        },
        type: TenantActionsTypes.TENANT_REMOVE_NOTES_FORMLESS
      });
    }
  })));
};

const LeaseHistory = () => {
  const leases = useSelector(Selectors.tenant.getAllLeases);
  const {
    t
  } = useI18n();

  if (!leases || leases.length === 0) {
    return null;
  }

  const rows = leases.map(lease => {
    const row = {
      items: [__cssprop(Link, {
        text: `${lease?.unit?.buildingAddress} - Unité #${lease?.unit?.subAddress}`,
        action: {
          kind: "InternalLink",
          to: `/unit/${lease?.unit?.id}`
        }
      }), DateTime.fromISO(lease?.startDate).toUTC().toFormat("MMMM dd, yyyy"), DateTime.fromISO(lease?.endDate).toUTC().toFormat("MMMM dd, yyyy"), lease?.paymentMethod || "", __cssprop(Link, {
        text: lease.project.name,
        action: {
          kind: "InternalLink",
          to: `/clients/${lease.project.id}`
        }
      })]
    };
    return row;
  });
  return __cssprop("div", {
    css: {
      "marginTop": "1.6rem"
    }
  }, __cssprop(DataList, {
    title: t("tenant-info-lease-history"),
    headings: [t("tenant-info-heading-housing"), t("tenant-info-heading-entry-date"), t("tenant-info-heading-end-date"), t("payment"), t("project")],
    rows: rows,
    permission: {
      kind: "Tenant",
      permission: "view:leaseHistory"
    }
  }));
};

const TentantInfoCard = () => {
  const {
    t
  } = useI18n();
  const email = useSelector(Selectors.tenant.getEmail);
  const primaryPhone = useSelector(Selectors.tenant.getPrimaryPhone);
  const secondaryPhone = useSelector(Selectors.tenant.getSecondaryPhone);
  const files = useSelector(Selectors.tenant.getFiles);
  const languages = {
    FR: "Français",
    EN: "Anglais"
  };
  return __cssprop(ProtectedCard, {
    title: t("tenant-info-title"),
    permission: {
      kind: "Tenant",
      permission: "view:info"
    }
  }, __cssprop(Card.Section, null, __cssprop(DescriptionList.TwoColumn, null, primaryPhone && __cssprop(DescriptionList.Item, {
    half: true,
    title: t("primary-phone")
  }, formatPhoneNumber(primaryPhone)), secondaryPhone && __cssprop(DescriptionList.Item, {
    half: true,
    title: t("secondary-phone")
  }, formatPhoneNumber(secondaryPhone)), email && __cssprop(DescriptionList.Item, {
    half: true,
    title: t("email")
  }, email))), files && files?.length > 0 && __cssprop(ProtectedCardSection, {
    greyed: true,
    permission: {
      kind: "Tenant",
      permission: "view:files"
    }
  }, __cssprop(Attachments, {
    visualOnly: true,
    files: files?.map(attachement => {
      if (attachement.kind === "local") {
        return {
          fileName: attachement.fileName,
          url: attachement.preview,
          fileType: attachement.fileType
        };
      }

      return {
        fileName: attachement.fileName,
        url: attachement.url,
        fileType: attachement.fileType,
        filePath: attachement.filePath
      };
    })
  })));
};