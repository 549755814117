import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "../../../../_snowpack/pkg/react.js";
import { Page } from "../../components/Tailwind/Page.js";
import CalendarComponent from "./components/CalendarComponent.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start"
  }
}]);

export const Calendar = () => {
  return __cssprop(Page, {
    title: ""
  }, __cssprop(StyledItems, null, __cssprop(CalendarComponent, null)));
};
export default Calendar;