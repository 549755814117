import { PropertyManagementContractType } from "../../../graphql/documents.js";
export const initialState = {
  name: null,
  email: null,
  managementType: PropertyManagementContractType.FullyManaged,
  users: {
    items: [],
    currentPage: 0,
    totalItems: 0,
    totalPages: 0
  },
  id: null,
  phoneInfo: null,
  contactName: null,
  legalName: null,
  statistics: null,
  buildings: null,
  deposits: null,
  applications: null,
  metadata: null,
  notes: [],
  files: [],
  tickets: {
    items: []
  }
};
// =========== ACTIONS CREATORS =========== //
export let ProjectActionsTypes;

(function (ProjectActionsTypes) {
  ProjectActionsTypes["PROJECT_ADD_NEW"] = "PROJECT_ADD_NEW";
  ProjectActionsTypes["PROJECT_INITIALIZE"] = "PROJECT_INITIALIZE";
  ProjectActionsTypes["PROJECT_SUBMIT"] = "PROJECT_SUBMIT";
  ProjectActionsTypes["PROJECT_SET_FIELD"] = "PROJECT_SET_FIELD";
  ProjectActionsTypes["PROJECT_ADD_EXISTING_USER"] = "PROJECT_ADD_EXISTING_USER";
  ProjectActionsTypes["PROJECT_INVITE_NEW_USER"] = "PROJECT_INVITE_NEW_USER";
  ProjectActionsTypes["PROJECT_REMOVE_USER"] = "PROJECT_REMOVE_USER";
  ProjectActionsTypes["PROJECT_ADD_NOTES_FORMLESS"] = "PROJECT_ADD_NOTES_FORMLESS";
  ProjectActionsTypes["PROJECT_REMOVE_NOTES_FORMLESS"] = "PROJECT_REMOVE_NOTES_FORMLESS";
  ProjectActionsTypes["PROJECT_ADD_NOTES"] = "PROJECT_ADD_NOTES";
  ProjectActionsTypes["PROJECT_REMOVE_NOTES"] = "PROJECT_REMOVE_NOTES";
})(ProjectActionsTypes || (ProjectActionsTypes = {}));