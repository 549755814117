import { FeatureType } from "../graphql/documents.js";
export let PropertyManagementContractType;

(function (PropertyManagementContractType) {
  PropertyManagementContractType["FullyManaged"] = "FullyManaged";
  PropertyManagementContractType["RentalOnly"] = "RentalOnly";
})(PropertyManagementContractType || (PropertyManagementContractType = {}));

export let ServiceType;

(function (ServiceType) {
  ServiceType[ServiceType["Garbage"] = 0] = "Garbage";
})(ServiceType || (ServiceType = {}));

export let UnitStatus;

(function (UnitStatus) {
  UnitStatus["TenantLeaving"] = "TenantLeaving";
  UnitStatus["TenantToRenew"] = "TenantToRenew";
  UnitStatus["TenantChanging"] = "TenantChanging";
  UnitStatus["Occupied"] = "Occupied";
  UnitStatus["Vacant"] = "Vacant";
  UnitStatus["Reserved"] = "Reserved";
})(UnitStatus || (UnitStatus = {}));

export let LeaseStatus;

(function (LeaseStatus) {
  LeaseStatus["Active"] = "Active";
  LeaseStatus["DoNotRenew"] = "DoNotRenew";
  LeaseStatus["Cancelling"] = "Cancelling";
  LeaseStatus["SubLeasing"] = "SubLeasing";
  LeaseStatus["Transfering"] = "Transfering";
})(LeaseStatus || (LeaseStatus = {}));

export const BuildingKeyMapper = t => {
  return {
    sector: t("building-form-sector"),
    buildDate: t("building-form-construction-date"),
    civicNumber: t("building-form-civic-number"),
    postalCode: t("building-form-postal-code"),
    purchaseDate: t("building-form-acquisition-date"),
    streetName: t("building-form-address"),
    city: t("building-form-city"),
    province: t("building-form-province"),
    tags: t("tags-section-title"),
    responsibles: t("responsible-selector-title"),
    managementStartDate: t("building-management-date")
  };
};
export const ProjectKeyMapper = t => {
  return {
    name: t("project-form-name"),
    legalName: t("project-form-legal-name"),
    managementType: t("project-form-management-type"),
    contactName: t("project-form-contact-name"),
    email: t("project-form-email"),
    streetName: t("building-form-address"),
    city: t("building-form-city"),
    province: t("building-form-province"),
    tags: t("tags-section-title"),
    responsibles: t("responsible-selector-title")
  };
};
export const TenantKeyMapper = t => {
  return {
    firstName: t("user-info-form-first-name"),
    lastName: t("user-info-form-last-name"),
    primaryPhone: t("user-info-form-primary-phone"),
    phoneIsMobile: t("primary-phone-mobile"),
    secondaryPhone: t("user-info-form-secondary-phone"),
    secondaryPhoneIsMobile: t("secondary-phone-mobile"),
    email: t("email"),
    language: t("user-preference-form-language"),
    notificationEnabled: t("user-preference-notification-label")
  };
};
export const UnitKeyMapper = t => {
  return {
    unitType: t("unit-info-section-form-unit-type"),
    subAddress: t("unit-info-section-form-sub-address"),
    floor: t("unit-info-section-form-floor"),
    bedrooms: t("unit-info-section-form-bedroom-number"),
    squareFootage: t("unit-info-section-form-square-foot"),
    waterTankInstallationDate: t("unit-info-section-form-water-tank-date")
  };
};
export const LeaseKeyMapper = t => {
  return {
    startDate: t("contract-form-start-date"),
    endDate: t("contract-form-end-date"),
    billDay: t("unit-lease-info-label-bill-day"),
    monthlyPrice: t("unit-lease-info-label-monthly-price"),
    status: t("communication-heading-status"),
    paymentMethod: t("lease-viewer-payment-method"),
    tenants: t("tenants")
  };
};
export let FeatureTypeInput;

(function (FeatureTypeInput) {
  FeatureTypeInput["SmallDogsAllowed"] = "boolean";
  FeatureTypeInput["LargeDogsAllowed"] = "boolean";
  FeatureTypeInput["CatsAllowed"] = "boolean";
  FeatureTypeInput["SmokingAllowed"] = "boolean";
  FeatureTypeInput["ExtensionsGasHeating"] = "boolean";
  FeatureTypeInput["UndergroundGarage"] = "number";
  FeatureTypeInput["StreetParking"] = "number";
  FeatureTypeInput["CoveredGarage"] = "number";
  FeatureTypeInput["GarageParkingSingle"] = "number";
  FeatureTypeInput["ExtensionsCentralAirconditioning"] = "boolean";
  FeatureTypeInput["ExtensionsCableInternet"] = "boolean";
  FeatureTypeInput["FurnitureCommodes"] = "number";
  FeatureTypeInput["ExtensionsElectricHeating"] = "boolean";
  FeatureTypeInput["GarageParkingDouble"] = "boolean";
  FeatureTypeInput["GarageParkingTriple"] = "boolean";
  FeatureTypeInput["ExtensionsFiberInternet"] = "boolean";
  FeatureTypeInput["DisabledAccess"] = "boolean";
  FeatureTypeInput["YardAccess"] = "boolean";
  FeatureTypeInput["BuildingElevator"] = "boolean";
  FeatureTypeInput["BuildingGym"] = "boolean";
  FeatureTypeInput["BuildingPool"] = "boolean";
  FeatureTypeInput["BuildingTerrace"] = "boolean";
  FeatureTypeInput["AppliancesJacuzzi"] = "boolean";
  FeatureTypeInput["ExtensionsBalcony"] = "boolean";
  FeatureTypeInput["ExtensionsCable"] = "boolean";
  FeatureTypeInput["ExtensionsElectricity"] = "boolean";
  FeatureTypeInput["ExtensionsPortableAirconditioning"] = "boolean";
  FeatureTypeInput["ExtensionsWater"] = "boolean";
  FeatureTypeInput["ExtensionsWaterTank"] = "date";
  FeatureTypeInput["ExtensionsCounterType"] = "string";
  FeatureTypeInput["ExtensionsBlinds"] = "boolean";
  FeatureTypeInput["LaundryRoomBuilding"] = "boolean";
  FeatureTypeInput["WasherDryerOutlet"] = "boolean";
  FeatureTypeInput["AppliancesLockerStorageSpace"] = "boolean";
  FeatureTypeInput["AppliancesFurnitureLeaseAndIncludedInPrice"] = "boolean";
  FeatureTypeInput["AppliancesStove"] = "boolean";
  FeatureTypeInput["AppliancesOven"] = "boolean";
  FeatureTypeInput["AppliancesMicrowave"] = "boolean";
  FeatureTypeInput["AppliancesDishwasher"] = "boolean";
  FeatureTypeInput["AppliancesRefrigerator"] = "boolean";
  FeatureTypeInput["AppliancesWasher"] = "boolean";
  FeatureTypeInput["AppliancesDryer"] = "boolean";
  FeatureTypeInput["FurnitureNumberOfTables"] = "number";
  FeatureTypeInput["FurnitureNumberOfChairs"] = "number";
  FeatureTypeInput["FurnitureNumberOfChestsOfDrawers"] = "number";
  FeatureTypeInput["FurnitureNumberOfCouch"] = "number";
  FeatureTypeInput["FurnitureNumberOfArmchair"] = "number";
  FeatureTypeInput["FurnitureNumberOfBed"] = "number";
})(FeatureTypeInput || (FeatureTypeInput = {}));

export const ReversedFeatureType = Object.entries(FeatureType).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {});