/* eslint-disable import/no-cycle */
export const toTenantInputMapper = tenant => {
  const {
    firstName,
    lastName,
    email,
    primaryPhone
  } = tenant;
  return {
    firstName,
    lastName,
    email,
    primaryPhone
  };
};