import { useFeatureToggle } from "../../hooks/useFeatureToggle.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { AccountPanel } from "../Panels/AccountPanel.js";
import { Card } from "./Card.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const AccountView = ({
  accountInfo
}) => {
  const {
    t
  } = useI18n();

  if (accountInfo) {
    const {
      tenantName,
      bankName,
      accountNumber
    } = accountInfo;
    return __cssprop(React.Fragment, null, __cssprop("div", {
      css: {
        "fontWeight": "500",
        "paddingBottom": "0.4rem"
      }
    }, tenantName), __cssprop("div", null, t("account-finish-by", {
      bankName,
      accountNumber
    })));
  }

  return __cssprop("div", null, t("no-account-linked"));
};

export const AccountCard = ({
  accountInfo
}) => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const paymentEnable = useFeatureToggle("payment");

  if (!paymentEnable) {
    return null;
  }

  return __cssprop(React.Fragment, null, __cssprop(AccountPanel, {
    sectionTitle: t("account-form-section-title"),
    formTitle: t("account-add-account")
  }), __cssprop(Card, {
    title: t("account"),
    primaryHeaderAction: {
      permission: {
        kind: "Payement",
        permission: "mutate:create"
      },
      content: t("account-form-title"),
      onAction: () => {
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: "account"
        });
      }
    }
  }, __cssprop(Card.Section, null, __cssprop(AccountView, {
    accountInfo: accountInfo
  }))));
};