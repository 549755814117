function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { DatePicker } from "../../components/DatePicker.js";
import { StyledButton } from "../../components/Tailwind/Button.js";
import { NumberTextField, TextField } from "../../components/Tailwind/TextField.js";
import { TaskActionTypes } from "../../store/artifacts/Tasks/types.js";
import { FileDropZone } from "../../components/Tailwind/FileDropZone.js";
import { Loader } from "../../components/Loader.js";
import useI18n from "../../hooks/useI18n.js";
import { CreateTaskInvoiceDocument, UpdateTaskInvoiceDocument } from "../../graphql/documents.js";
import { useMutation } from "../../../../_snowpack/pkg/@apollo/client.js";
import { toast } from "../../../../_snowpack/pkg/react-toastify.js";
import { useAction } from "../../hooks/useRedux.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import { ClientSelector } from "../../components/Selectors/ClientSelector.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

export const TaskInvoiceSideBarFormView = ({
  taskId,
  refetch,
  payer,
  amount,
  title,
  description,
  date = DateTime.utc().toISO(),
  invoiceId,
  filesDefault
}) => {
  const {
    t
  } = useI18n();
  const [files, setFiles] = useState(filesDefault);
  const {
    dispatch
  } = useAction();
  const {
    handleSubmit: handleInvoiceSubmit,
    setValue: setInvoiceValue,
    formState: {
      errors
    },
    control: invoiceControl
  } = useForm({
    defaultValues: {
      payer: payer || undefined,
      amount,
      date,
      description,
      title
    }
  });
  const [createTaskInvoice, {
    loading: createTaskInvoiceLoading
  }] = useMutation(CreateTaskInvoiceDocument, {
    fetchPolicy: "no-cache"
  });
  const [updateTaskInvoice, {
    loading: updateTaskInvoiceLoading
  }] = useMutation(UpdateTaskInvoiceDocument, {
    fetchPolicy: "no-cache"
  });

  const onFileDrop = pFiles => {
    setFiles([...files, ...pFiles]);
  };

  const onFileDelete = fileToDelete => {
    setFiles(prev => {
      return prev.filter(file => file.fileName !== fileToDelete.fileName);
    });
  };

  const isLoading = createTaskInvoiceLoading || updateTaskInvoiceLoading;
  const onSubmit = handleInvoiceSubmit(async invoice => {
    try {
      if (invoiceId) {
        const invoiceSubmit = { ...invoice,
          payer: undefined,
          payerId: invoice.payer.value
        };
        const invoiceSaveResponse = await updateTaskInvoice({
          variables: {
            invoiceId,
            invoice: invoiceSubmit
          }
        });

        if (invoiceSaveResponse.errors) {
          toast.warn(`${t("generic-fetch-error-message")}`);
          return;
        } // upload files


        dispatch({
          type: TaskActionTypes.INVOICE_SET_FILES,
          payload: {
            files,
            invoiceId
          }
        });
        refetch();
      } else {
        const invoiceSubmit = { ...invoice,
          payer: undefined,
          payerId: invoice.payer.value
        };
        const invoiceSaveResponse = await createTaskInvoice({
          variables: {
            taskId,
            invoice: invoiceSubmit
          }
        });

        if (invoiceSaveResponse.errors) {
          toast.warn(`${t("generic-fetch-error-message")}`);
          return;
        } // upload files


        dispatch({
          type: TaskActionTypes.INVOICE_SET_FILES,
          payload: {
            files,
            invoiceId: invoiceSaveResponse.data.createTaskInvoice.id
          }
        });
        refetch();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("TaskInvoiceSection", e);
      toast.warn(`${t("generic-fetch-error-message")}`);
    }
  });

  if (isLoading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  return __cssprop("form", {
    onSubmit: onSubmit,
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(1.2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(1.2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "title",
    control: invoiceControl,
    rules: {
      required: true,
      minLength: 6
    },
    defaultValue: "",
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      id: "title"
    }, field, {
      onChange: field.onChange,
      defaultValue: "",
      label: t("task-add-sidebar-invoice-title-label"),
      placeholder: t("task-add-sidebar-invoice-title-placeholder"),
      error: showError(errors, "title", t("task-add-sidebar-title-error"))
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "amount",
    control: invoiceControl,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(NumberTextField, _extends({
      id: "amount"
    }, field, {
      onChange: field.onChange,
      label: t("task-add-sidebar-invoice-amount-label"),
      placeholder: t("task-add-sidebar-invoice-amount-placeholder"),
      error: showError(errors, "amount", t("task-add-sidebar-invoice-amount-error"))
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "description",
    control: invoiceControl,
    defaultValue: "",
    render: ({
      field
    }) => __cssprop(TextField, _extends({}, field, {
      id: "description",
      onChange: field.onChange,
      label: t("task-add-sidebar-invoice-description-label"),
      placeholder: t("task-add-sidebar-invoice-description-placeholder"),
      error: showError(errors, "description", t("field-required")),
      defaultValue: ""
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "date",
    control: invoiceControl,
    rules: {
      required: true
    },
    render: ({
      field
    }) => __cssprop(DatePicker, _extends({}, field, {
      id: "date",
      onChange: field.onChange,
      error: showError(errors, "date", t("field-required")),
      title: t("task-add-sidebar-invoice-date-title")
    }))
  })), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("task-form-invoice-payer")), __cssprop(Controller, {
    name: "payer",
    rules: {
      required: true
    },
    control: invoiceControl,
    render: ({
      field
    }) => {
      const selectedProject = field.value ? {
        name: field.value.label,
        id: field.value.value
      } : null;
      return __cssprop(ClientSelector, _extends({}, field, {
        selectedProject: selectedProject,
        defaultValue: field.value,
        inform: true
      }));
    }
  }))), __cssprop(StyledItems, null, __cssprop(FileDropZone, {
    files: files,
    inForm: true,
    onFilesDrop: onFileDrop,
    onFilesDelete: onFileDelete
  })), __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "row",
      "width": "100%",
      "justifyContent": "space-evenly"
    }
  }, __cssprop(StyledButton, {
    submit: true,
    primary: true
  }, invoiceId ? t("task-add-sidebar-button-edit") : t("task-add-sidebar-button-create")))));
};