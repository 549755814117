function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { PhoneInput } from "../../components/PhoneInput/index.js";
import { Button } from "../../components/Tailwind/Button.js";
import { FileDropZone } from "../../components/Tailwind/FileDropZone.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import { getEmailValidationForForm, getPhoneValidationForForm } from "../../utils/index.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);
export const TenantSideBar = props => {
  const {
    sectionTitle,
    description
  } = props;
  const {
    t
  } = useI18n();
  const isLoading = useSelector(Selectors.status.isLoading("tenant"));
  const firstName = useSelector(Selectors.tenant.getFirstName);
  const lastName = useSelector(Selectors.tenant.getLastName);
  const email = useSelector(Selectors.tenant.getEmail);
  const primaryPhone = useSelector(Selectors.tenant.getPrimaryPhone);
  const secondaryPhone = useSelector(Selectors.tenant.getSecondaryPhone); // sad i used a useState

  const [files, setFiles] = useState(useSelector(Selectors.tenant.getFiles));
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();

  const onFileDrop = pFiles => {
    setFiles([...files, ...pFiles]);
  };

  const onFileDelete = fileToDelete => {
    setFiles(prev => {
      return prev.filter(file => file.fileName !== fileToDelete.fileName);
    });
  };

  const onSubmit = data => {
    props.onDoneSubmit({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      primaryPhone: data.primaryPhone === "+1" ? null : data.primaryPhone,
      secondaryPhone: data.secondaryPhone,
      files,
      notes: data.notes
    });
  };

  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, sectionTitle), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "maxWidth": "67.2rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, description)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "firstName",
    control: control,
    defaultValue: firstName,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "firstName", t("user-info-error-first-name")),
      id: "firstName"
    }, field, {
      label: t("user-info-form-first-name"),
      placeholder: t("user-info-form-first-name-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "lastName",
    control: control,
    defaultValue: lastName,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "lastName", t("user-info-error-last-name")),
      id: "lastName"
    }, field, {
      label: t("user-info-form-last-name"),
      placeholder: t("user-info-form-last-name-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop(React.Fragment, null, __cssprop(Controller, {
    name: "primaryPhone",
    control: control,
    rules: getPhoneValidationForForm(),
    defaultValue: primaryPhone,
    render: ({
      field
    }) => __cssprop(PhoneInput, _extends({}, field, {
      label: t("user-info-form-primary-phone"),
      errorLabel: showError(errors, "primaryPhone", t("user-info-error-phone-required"))?.errorMessage,
      zIndex: 30
    }))
  }))), __cssprop(StyledItems, null, __cssprop(React.Fragment, null, __cssprop(Controller, {
    name: "secondaryPhone",
    control: control,
    defaultValue: secondaryPhone,
    rules: getPhoneValidationForForm(),
    render: ({
      field
    }) => __cssprop(PhoneInput, _extends({}, field, {
      label: t("user-info-form-secondary-phone"),
      errorLabel: showError(errors, "secondaryPhone", t("user-info-error-secondary-phone"))?.errorMessage,
      zIndex: 30
    }))
  }))), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "email",
    control: control,
    defaultValue: email,
    rules: getEmailValidationForForm(),
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "email", t("user-info-error-email-invalid")),
      id: "email"
    }, field, {
      label: t("email"),
      placeholder: t("user-info-form-email-placeholder")
    }))
  })), __cssprop(StyledItems, null, __cssprop(FileDropZone, {
    files: files,
    inForm: true,
    onFilesDrop: onFileDrop,
    onFilesDelete: onFileDelete
  }))), __cssprop(Button, {
    submit: true,
    loading: isLoading,
    disabled: Object.keys(errors).length !== 0,
    primary: true
  }, t("save"))));
};
export default TenantSideBar;