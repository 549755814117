import { css as _css } from "../../../_snowpack/pkg/@emotion/react.js";
export const textSm = _css`
  font-size: 14px;
  line-height: 20px;
`;
export const textBase = _css`
  font-size: 16px;
  line-height: 24px;
`;
export const textLarge = _css`
  font-size: 18px;
  line-height: 28px;
`;