export let ProviderActionTypes;

(function (ProviderActionTypes) {
  ProviderActionTypes["PROVIDER_SET_INITIAL_STATE"] = "PROVIDER_SET_INITIAL_STATE";
  ProviderActionTypes["PROVIDER_SET_FIELD"] = "PROVIDER_SET_FIELD";
  ProviderActionTypes["PROVIDER_ADD"] = "PROVIDER_ADD";
  ProviderActionTypes["PROVIDER_CLEAR_STATE"] = "PROVIDER_CLEAR_STATE";
  ProviderActionTypes["PROVIDER_EDIT"] = "PROVIDER_EDIT";
  ProviderActionTypes["PROVIDER_DELETE"] = "PROVIDER_DELETE";
})(ProviderActionTypes || (ProviderActionTypes = {}));

export const initialState = {
  id: "",
  name: "",
  email: "",
  phoneInfo: {
    primaryPhone: ""
  },
  files: [],
  notes: [],
  preferredMethodOfContact: undefined
};