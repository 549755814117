import { AnimateLoadingSvg } from "../../../../components/Tailwind/Button.js";
import React from "../../../../../../_snowpack/pkg/react.js";
import { BiCheck, BiPencil, BiXCircle } from "../../../../../../_snowpack/pkg/react-icons/bi.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";
export const ModifyButton = props => {
  const {
    loading,
    inEdit,
    setEdit,
    handleOnSave,
    disabled
  } = props;

  if (disabled) {
    return null;
  }

  if (inEdit) {
    return __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "marginTop": "0.4rem",
        "paddingTop": "0.4rem"
      }
    }, __cssprop(BiCheck, {
      onClick: () => handleOnSave(),
      css: {
        "cursor": "pointer",
        "height": "2.4rem",
        "width": "2.4rem"
      }
    }), __cssprop(BiXCircle, {
      onClick: () => setEdit(false),
      css: {
        "cursor": "pointer",
        "height": "2.4rem",
        "width": "2.4rem"
      }
    }));
  }

  if (loading) return __cssprop(AnimateLoadingSvg, null);
  return __cssprop(React.Fragment, null, __cssprop(BiPencil, {
    onClick: () => setEdit(true),
    css: {
      "cursor": "pointer",
      "height": "2rem",
      "width": "2rem"
    }
  }));
};