import { createSelector } from "../../../../../_snowpack/pkg/reselect.js";
export const getListing = state => state?.listing;

const getListingId = state => getListing(state).id;

const getPrice = state => getListing(state).price;

const getDescriptions = state => getListing(state).description;

const getNote = state => getListing(state).note;

const getFeaturedPicture = state => getListing(state)?.thumbnail;

const getGalleryPictures = state => getListing(state)?.gallery;

const getVideoUrl = state => getListing(state)?.videoLink;

const hasExistingListing = state => !!getListing(state).id;

const isPublished = state => getListing(state).published;

const getPublishedOn = state => getListing(state).publishedOn;

const getAvailabiltyDate = state => getListing(state).availability;

const getUnit = state => getListing(state).unit;

const getConsent = state => getListing(state).consents;

const isReadyToSubmit = state => {
  const listing = getListing(state);
  return listing.thumbnail !== null && listing.gallery.length > 0 && listing.unit !== null && (listing.description.fr !== "" || listing.description.en !== "");
};

const getFullAddress = createSelector(getUnit, unit => {
  if (!unit) {
    return "";
  }

  if (!unit.subAddress && unit.buildingAddress) {
    return `${unit.buildingAddress}`;
  }

  if (unit.subAddress && unit.buildingAddress) {
    return `${unit.subAddress} - ${unit.buildingAddress}`;
  }

  return undefined;
});
export default {
  getPrice,
  getDescriptions,
  getNote,
  getAvailabiltyDate,
  getFeaturedPicture,
  getGalleryPictures,
  getVideoUrl,
  getPublishedOn,
  hasExistingListing,
  isPublished,
  getUnit,
  isReadyToSubmit,
  getListingId,
  getFullAddress,
  getConsent
};