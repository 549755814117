export const mapNote = note => ({ ...note,
  kind: "remote"
});
export const mapNotes = notes => {
  if (notes !== undefined && notes !== null) {
    return notes.map(mapNote);
  }

  return [];
};
export const mapNotesInput = notes => {
  if (notes !== undefined && notes !== null) {
    return notes?.map(note => {
      if (note.kind === "remote") {
        return {
          id: note.id,
          content: note.content
        };
      }

      return {
        content: note.content
      };
    });
  }

  return [];
};