import { Attachments } from "../../../components/Tailwind/Attachments.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { DescriptionList } from "../../../components/Tailwind/DescriptionList.js";
import { ProtectedCard, ProtectedCardSection } from "../../../components/Tailwind/ProtectedComponents.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../../hooks/useRedux.js";
import MANAGEMENT_TYPES from "../../../utils/project.js";
import { formatPhoneNumber } from "../../../utils/tenant.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const ProjectsInformation = () => {
  const {
    t
  } = useI18n();
  const name = useSelector(Selectors.project.getName);
  const contactName = useSelector(Selectors.project.getContactName);
  const phoneInfo = useSelector(Selectors.project.getPhoneInfo);
  const email = useSelector(Selectors.project.getEmail);
  const legalName = useSelector(Selectors.project.getLegalName);
  const managementType = useSelector(Selectors.project.getManagementType);
  const files = useSelector(Selectors.project.getFiles); // const { name, contactName, phoneInfo, email, legalName, managementType } = project;

  return __cssprop(ProtectedCard, {
    title: t("project-info"),
    permission: {
      kind: "Project",
      permission: "view:info"
    }
  }, __cssprop(Card.Section, null, __cssprop(DescriptionList.TwoColumn, null, __cssprop(DescriptionList.Item, {
    half: true,
    title: t("project-form-name")
  }, name), legalName && __cssprop(DescriptionList.Item, {
    half: true,
    title: t("project-form-legal-name")
  }, legalName), phoneInfo?.primaryPhone && __cssprop(DescriptionList.Item, {
    half: true,
    title: t("project-form-primary-phone")
  }, formatPhoneNumber(phoneInfo.primaryPhone)), email && __cssprop(DescriptionList.Item, {
    half: true,
    title: t("project-form-email")
  }, email), __cssprop(DescriptionList.Item, {
    half: true,
    title: t("management-type")
  }, __cssprop("div", {
    css: {
      "fontWeight": "600"
    }
  }, MANAGEMENT_TYPES[managementType])), __cssprop(DescriptionList.Item, {
    half: true,
    title: t("project-info-contact-person")
  }, __cssprop("div", {
    css: {
      "fontWeight": "600"
    }
  }, contactName || t("project-info-no-contact-save"))))), files && files?.length > 0 && __cssprop(ProtectedCardSection, {
    greyed: true,
    permission: {
      kind: "Project",
      permission: "view:files"
    }
  }, __cssprop(Attachments, {
    files: files?.map(attachement => {
      if (attachement.kind === "local") {
        return {
          fileName: attachement.fileName,
          url: attachement.preview,
          fileType: attachement.fileType
        };
      }

      return {
        fileName: attachement.fileName,
        url: attachement.url,
        fileType: attachement.fileType,
        filePath: attachement.filePath
      };
    }),
    visualOnly: true
  })));
};