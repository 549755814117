import { FeatureType } from "../../../graphql/documents.js";
import { mapFiles } from "../../utils/Files.js";
import { mapNotes } from "../../utils/Notes.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const mapUnitGqlToState = unit => {
  const {
    id,
    waterTankInstallationDate,
    notes,
    floor,
    files,
    amenities,
    subAddress,
    squareFootage,
    bedrooms,
    listing,
    unitType,
    buildingAddress,
    leases,
    building,
    buildingId,
    currentLease
  } = unit;
  return {
    currentLease,
    buildingId,
    propertyIssue: null,
    adId: listing?.id,
    bedrooms,
    buildingAddress,
    floor,
    id,
    waterTankInstallationDate,
    subAddress,
    squareFootage,
    unitType,
    building,
    amenities: amenities?.map(amenity => {
      let itemValue;

      if (amenity && amenity.value) {
        itemValue = amenity.value;
        if (amenity.key === FeatureType.ExtensionsWaterTank) itemValue = DateTime.fromISO(amenity.value).toUTC().toISODate();
      }

      return { ...amenity,
        value: itemValue || true
      };
    }) || [],
    files: mapFiles(files.items),
    notes: mapNotes(notes.items),
    listing,
    leases: leases?.items || [],
    tickets: unit.tickets
  };
};