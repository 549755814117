import React, { useEffect, useState } from "../../../../../_snowpack/pkg/react.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { Loader } from "../../../components/Loader.js";
import { useLazyQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { ListingDetailsDocument } from "../../../graphql/documents.js";
import { mapFile, mapFiles } from "../../../store/utils/Files.js";
import { getAddressFromUnit } from "../../../helpers/functions.js";
import { mapConsentsGqlToConsents } from "../../../store/utils/Consents.js";
import { ListingSideBarFormView } from "./ListingSideBarFormView.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const ListingSideBar = ({
  refetch,
  listingId,
  unitId
}) => {
  const [isReady, setIsReady] = useState(false);
  const [listingState, setListingState] = useState({
    refetch,
    listingId,
    listingProp: null,
    address: "",
    unitId,
    published: false
  });
  const [loadListing, {
    loading: queryLoading
  }] = useLazyQuery(ListingDetailsDocument, {
    variables: {
      listingId
    },
    onCompleted: listingData => {
      const {
        listing
      } = listingData;

      if (!listing) {
        return;
      } // pass values to form component


      const mappedListingState = { ...listingState,
        listingId: listing.id,
        published: listing.published,
        address: getAddressFromUnit(listing.unit),
        listingProp: { ...listing,
          availability: DateTime.fromISO(listing.availability).toUTC(),
          consents: mapConsentsGqlToConsents(listing.consents),
          description: listing.description.reduce((acc, desc) => {
            acc[desc.locale.toUpperCase()] = desc.value;
            return acc;
          }, {
            FR: "",
            EN: ""
          }),
          gallery: mapFiles(listing.gallery),
          thumbnail: mapFile(listing.thumbnail, 1, "remote"),
          note: listing.notes.items[0]?.content ?? ""
        }
      };
      setListingState(mappedListingState);
      setIsReady(true);
    }
  });
  useEffect(() => {
    if (listingId) loadListing(); // if listingId is passed then get Listing details
    else setIsReady(true); // component is ready to render form
  }, []);

  if (queryLoading || !isReady) {
    return __cssprop("div", {
      tw: "absolute bg-gray-50 z-10 h-full w-full opacity-60"
    }, __cssprop(Loader, null));
  }

  return __cssprop(ListingSideBarFormView, listingState);
};