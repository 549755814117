/* eslint-disable jsx-a11y/label-has-associated-control */
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../store/artifacts/Building/types.js";
import React, { useEffect } from "../../../../_snowpack/pkg/react.js";
import { Buildings } from "./tabs/Buildings.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Properties = withBreadcrumb(({
  history,
  setCrumb
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  useEffect(() => {
    setCrumb({
      title: t("properties"),
      path: "/properties",
      level: 0
    });
  }, []);
  return __cssprop(React.Fragment, null, __cssprop(Page, {
    title: t("properties"),
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Building",
        permission: "mutate:create"
      },
      primary: true,
      onAction: () => {
        dispatch({
          type: BuildingActionsTypes.BUILDING_INITIALIZE,
          payload: {
            kind: "NoContext"
          }
        });
        history.push("/building");
      }
    }, t("buildings-add-building"))
  }, __cssprop(Buildings, {
    history: history,
    setCrumb: setCrumb
  })));
});
export default Properties;