import "../_snowpack/pkg/mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useState } from "../_snowpack/pkg/react.js";
import { prepareAppVendors } from "./app/utils/index.js";
import reactDom from "../_snowpack/pkg/react-dom.js";
import { Provider } from "../_snowpack/pkg/react-redux.js";
import { Router } from "../_snowpack/pkg/react-router.js";
import { ToastContainer, toast } from "../_snowpack/pkg/react-toastify.js";
import { PersistGate } from "../_snowpack/pkg/redux-persist/integration/react.js";
import "../_snowpack/pkg/react-toastify/dist/ReactToastify.min.css";
import { useApolloClient } from "../_snowpack/pkg/@apollo/client.js";
import useI18n from "./app/hooks/useI18n.js";
import App from "./app/index.js";
import WithContextApolloProvider from "./app/apollo/index.js";
import { Loader } from "./app/components/Loader.js";
import { BreadcrumbsProvider } from "./app/context/Breadcrumbs/Provider.js";
import history from "./app/history.js";
import { startSaga, store, persistor } from "./app/store/index.js";
import "./i18n.js";
import { jsx as __cssprop } from "../_snowpack/pkg/@emotion/react.js";

const AppLoading = props => {
  const [loaded, setLoaded] = useState(false);
  const client = useApolloClient();
  const {
    t
  } = useI18n();
  useEffect(() => {
    startSaga(client, history, t, toast);
    setLoaded(prepareAppVendors());
  }, [history, client]);

  if (loaded) {
    return props.children;
  }

  return __cssprop(Loader, null);
};

reactDom.render(__cssprop(React.StrictMode, null, __cssprop(Provider, {
  store: store
}, __cssprop(PersistGate, {
  loading: null,
  persistor: persistor
}, __cssprop(WithContextApolloProvider, null, __cssprop(AppLoading, null, __cssprop(Router, {
  history: history
}, __cssprop(BreadcrumbsProvider, null, __cssprop(App, null)), __cssprop(ToastContainer, {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true
}))))))), document.getElementById("root"));