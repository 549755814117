import { client } from "../../apollo/index.js";
import { GetProjectsDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import React, { useCallback, useState } from "../../../../_snowpack/pkg/react.js";
import AsyncSelect from "../../../../_snowpack/pkg/react-select/async.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const ProjectSelector = props => {
  const {
    isMulti,
    error,
    isForm = false,
    disabled,
    selectedProject,
    label,
    defaultValue
  } = props;
  const {
    t
  } = useI18n();
  const [selectedOptions, setSelectedOptions] = useState(selectedProject ? [{
    label: selectedProject.label,
    value: selectedProject.id
  }] : []);
  const promiseOptions = useCallback(async inputValue => {
    try {
      const {
        data
      } = await client.query({
        query: GetProjectsDocument,
        variables: {
          take: 15,
          query: inputValue,
          skip: 0
        },
        fetchPolicy: "no-cache"
      });

      if (!data) {
        return [];
      }

      const filtered = data.projects.items;
      const ids = {};
      filtered.forEach(u => {
        ids[u.id] = u.name;
      });
      const finalIds = Object.keys(ids).map(key => {
        return {
          value: key,
          label: ids[key]
        };
      });

      if (isForm && !selectedProject?.value) {
        if (props.onChange) {
          props.onChange(finalIds[0]);
        }

        setSelectedOptions([finalIds[0]]);
      }

      if (props.onLoad) {
        props.onLoad(finalIds);
      }

      return finalIds;
    } catch (e) {
      throw new Error(e);
    }
  }, [client]);
  const onChange = useCallback(values => {
    // Deposit page values are not an array so we need to check and cope.
    setSelectedOptions(Array.isArray(values) ? [...values] : [values]);

    if (props.onChange) {
      props.onChange(values || {
        label: null,
        value: null
      });
    }
  }, [props, selectedOptions, isMulti]);
  return __cssprop("div", null, label && __cssprop("span", {
    css: {
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "paddingBottom": "0.4rem"
    }
  }, label), __cssprop(AsyncSelect, {
    isDisabled: disabled,
    defaultOptions: true,
    defaultValue: defaultValue,
    css: {
      "--tw-shadow": "0 0 #0000",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    },
    placeholder: t("placeholder-filter-by-client"),
    menuContainerStyle: {
      zIndex: 25
    },
    onChange: onChange,
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: error ? "rgb(222, 53, 11)" : "hsl(0,0%,80%)"
      })
    },
    loadOptions: promiseOptions
  }), error && __cssprop("span", {
    css: {
      "paddingTop": "0.8rem",
      "--tw-text-opacity": "1",
      "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
    }
  }, error?.errorMessage));
};