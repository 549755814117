import { css as _css } from "../../../../_snowpack/pkg/@emotion/react.js";
import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { ChevronEnd, ChevronRight, ChevronLeft, ChevronStart } from "../Icons.js";
export const PaginationContainer = _styled.div`
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
`;
export const PaginationButton = _styled.div(({
  disabled
}) => [!disabled && {
  "cursor": "pointer"
}, _css`
    display: flex;
    align-items: center;
    padding: 0 0.2em;
  `]);

const Icons = ({
  disabled,
  className
}) => _css`
  color: ${disabled ? "#d4d9de" : "#637381"};
  width: 1.25em;
  height: 1.25em;
`;

export const Next = _styled(ChevronRight)(({
  disabled,
  className
}) => [Icons({
  disabled,
  className
})]);
export const Previous = _styled(ChevronLeft)(({
  disabled,
  className
}) => [Icons({
  disabled,
  className
})]);
export const Start = _styled(ChevronStart)(({
  disabled,
  className
}) => [Icons({
  disabled,
  className
})]);
export const End = _styled(ChevronEnd)(({
  disabled,
  className
}) => [Icons({
  disabled,
  className
})]);