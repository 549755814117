// Don't ask, it works -Vincent
export const RESET_STORE = "RESET_STORE";
export const createReducer = (initialState, handlers, finalizer = x => x) => (state = initialState, action) => {
  if (action.type) {
    const handler = handlers[action.type];

    if (handler) {
      const result = handler(action, state);

      if (result === RESET_STORE) {
        return initialState;
      }

      if (result === null) {
        return state;
      }

      return finalizer({ ...state,
        ...result
      });
    }
  }

  return state;
};