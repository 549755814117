/* eslint-disable jsx-a11y/label-has-associated-control */
import { Page } from "../../components/Tailwind/Page.js";
import { UserPanel } from "../../components/Panels/UserPanel.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { UserActionsTypes } from "../../store/artifacts/User/types.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useEffect, useCallback } from "../../../../_snowpack/pkg/react.js";
import { ProviderPanel } from "../../components/Panels/ProviderPanel.js";
import { ProtectedButton, ProtectedTab } from "../../components/Tailwind/ProtectedComponents.js";
import { ProviderView } from "../Providers/index.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Organization = withBreadcrumb(({
  history,
  setCrumb
}) => {
  const {
    t
  } = useI18n();
  useEffect(() => {
    setCrumb({
      title: t("audits"),
      path: "/audits",
      level: 0
    });
  }, []);
  const {
    dispatch
  } = useAction();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isProviderPanelOpen, setIsProviderPanelOpen] = React.useState(false);
  const role = useSelector(Selectors.session.getUserRole);
  const providerId = useSelector(Selectors.provider.getId);
  const tabs = ProtectedTab(role, [{
    content: t("providers-title"),
    id: "providers",
    permission: {
      kind: "Provider",
      permission: "view:list"
    }
  }]);
  const getActions = useCallback(() => {
    switch (selectedTab) {
      case 0:
        return __cssprop(ProtectedButton, {
          permission: {
            kind: "Provider",
            permission: "mutate:create"
          },
          primary: true,
          onAction: () => {
            setIsProviderPanelOpen(true);
          }
        }, t("provider-add-new-provider"));

      default:
        return null;
    }
  }, [selectedTab]);

  const onDoneSubmit = data => {
    dispatch({
      type: UserActionsTypes.USER_SUBMIT,
      payload: { ...data
      }
    });
  };

  return __cssprop(React.Fragment, null, __cssprop(UserPanel, {
    formTitle: t("user"),
    description: "",
    sectionTitle: "",
    onDoneSubmit: onDoneSubmit
  }), __cssprop(Page, {
    title: t("organization-menu-item"),
    tabs: {
      tabs,
      onSelect: setSelectedTab,
      selected: selectedTab
    },
    primaryAction: getActions()
  }, __cssprop(ProviderPanel, {
    refetch: () => {
      setIsProviderPanelOpen(false);
    },
    handleOnClose: () => setIsProviderPanelOpen(false),
    isOpen: isProviderPanelOpen,
    providerId: providerId
  }), __cssprop(ProviderView, null)));
});
export default Organization;