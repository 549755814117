/* eslint-disable jsx-a11y/label-has-associated-control */
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedTab } from "../../components/Tailwind/ProtectedComponents.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useEffect } from "../../../../_snowpack/pkg/react.js";
import Deliveries from "../Deliveries/index.js";
import ApplicantRequestV2 from "./tabs/ApplicantRequestsV2.js";
import { ListingsList } from "./tabs/Listings.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const RenderPageContent = ({
  selectedTab,
  history
}) => {
  switch (selectedTab) {
    case 0:
      return __cssprop(ListingsList, {
        history: history
      });

    case 1:
      return __cssprop(ApplicantRequestV2, {
        history: history
      });

    case 2:
      return __cssprop(Deliveries, null);

    default:
      return __cssprop(ListingsList, {
        history: history
      });
  }
};

export const Marketing = withBreadcrumb(({
  history,
  location,
  setCrumb
}) => {
  const {
    t
  } = useI18n();
  useEffect(() => {
    setCrumb({
      title: t("marketing-title"),
      path: "/marketing",
      level: 0
    });
  }, []);
  const role = useSelector(Selectors.session.getUserRole);
  const tabs = ProtectedTab(role, [{
    content: t("listings-plurial"),
    id: "listing",
    permission: {
      kind: "Listing",
      permission: "view:list"
    }
  }, {
    content: t("applicants"),
    id: "rental-request-v2",
    permission: {
      kind: "ApplicantRequest",
      permission: "view:list"
    }
  }, {
    content: t("deliveries"),
    id: "deliveries",
    permission: {
      kind: "Lease",
      permission: "view:list"
    }
  }]);
  const [selectedTab, setSelectedTab] = React.useState(location?.state?.tab || 0);
  return __cssprop(Page, {
    title: t("marketing-title"),
    tabs: {
      tabs,
      onSelect: setSelectedTab,
      selected: selectedTab
    }
  }, __cssprop(RenderPageContent, {
    selectedTab: selectedTab,
    history: history
  }));
});
export default Marketing;