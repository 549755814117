import { Modal } from "../components/Tailwind/Modal.js";
import { TextField } from "../components/Tailwind/TextField.js";
import useI18n from "../hooks/useI18n.js";
import { useAction } from "../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../store/artifacts/Application/types.js";
import { DateTime } from "../../../_snowpack/pkg/luxon.js";
import * as React from "../../../_snowpack/pkg/react.js";
import { useCallback, useState } from "../../../_snowpack/pkg/react.js";
import { Checkbox } from "../components/Checkbox/Checkbox.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const CancelLeaseModal = props => {
  const {
    onSuccess,
    label,
    modalName
  } = props;
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const [date, setDate] = useState(DateTime.utc().toISODate());
  const [willTerminateEarlier, setWillTerminateEarlier] = useState(false);
  const [error, setError] = useState();
  const getValues = useCallback(() => ({
    date,
    willTerminateEarlier
  }), [date, willTerminateEarlier]);
  const validateValues = useCallback(() => {
    try {
      if (!getValues().willTerminateEarlier) return true;
      const dateValue = DateTime.fromISO(getValues().date);

      if (!dateValue.isValid) {
        setError(t("deposit-form-error-deposit-date"));
        return false;
      }

      setError(undefined);
      return true;
    } catch {
      setError(t("deposit-form-error-deposit-date"));
      return false;
    }
  }, [getValues, date]);

  const renderComponent = () => {
    return __cssprop("div", null, __cssprop("div", null, __cssprop(Checkbox, {
      id: "check1",
      disabled: false,
      label: t("lease-form-cancel-lease-checkbox-label"),
      name: "Checkbox1",
      checked: willTerminateEarlier,
      onChange: setWillTerminateEarlier
    })), __cssprop("div", {
      css: {
        "paddingTop": "0.8rem"
      }
    }, willTerminateEarlier && __cssprop(TextField, {
      id: "cancelDate1",
      type: "date",
      onChange: setDate,
      value: date,
      defaultValue: date,
      max: "2099-12-31",
      error: error ? {
        errorMessage: error
      } : null
    })));
  };

  return __cssprop(Modal, {
    modalName: modalName,
    title: t("lease-form-cancel-lease"),
    description: label,
    buttonContent: t("lease-form-cancel-lease"),
    type: "destructive",
    onAction: () => {
      if (validateValues()) {
        onSuccess(true, getValues());
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
          payload: null
        });
      }
    },
    other: renderComponent()
  });
};

const useCancelLeaseModal = (label, onSuccess) => {
  const [values, setValues] = useState();
  const {
    dispatch
  } = useAction();
  return [() => __cssprop(CancelLeaseModal, {
    onSuccess: (result, pValues) => {
      onSuccess(result, values, pValues);
    },
    label: label,
    modalName: "cancelLease"
  }), _values => {
    setValues(_values);
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
      payload: "cancelLease"
    });
  }];
};

export default useCancelLeaseModal;