import { createReducer } from "../../helpers/actions.js";
import sagas from "./sagas.js";
import { initialState } from "./types.js";
export const getUser = state => state?.user;

const hasCompletedDetails = user => {
  return !!user?.firstName;
};

export default {
  sagas: sagas.executers,
  reducer: createReducer(initialState, sagas.reducers),
  selectors: {
    getUserDetails: state => getUser(state),
    isExtantUser: state => {
      return getUser(state).id !== null && getUser(state).id !== undefined;
    },
    isReadyToSubmit: state => {
      return hasCompletedDetails(getUser(state));
    },
    hasCompletedDetails,
    getId: state => getUser(state).id,
    getName: state => getUser(state).name,
    getEmail: state => getUser(state).email,
    getPrimaryPhone: state => getUser(state).primaryPhone,
    getSecondaryPhone: state => getUser(state).secondaryPhone,
    getLanguage: state => getUser(state).language,
    getFirstName: state => getUser(state).firstName,
    getLastName: state => getUser(state).lastName,
    getServerUser: state => getUser(state).serverUser,
    getFiles: state => getUser(state).files,
    getNotes: state => getUser(state).notes
  }
};