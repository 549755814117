export const initialState = {
  building: "Idle",
  listing: "Idle",
  lease: "Idle",
  unit: "Idle",
  tenant: "Idle",
  project: "Idle",
  deposit: "Idle",
  user: "Idle",
  rental: "Idle",
  note: "Idle",
  file: "Idle",
  applicant: "Idle",
  ticket: "Idle",
  task: "Idle",
  taskInvoice: "Idle",
  provider: "Idle",
  calendar: "Idle",
  event: "Idle"
};
export let StatusActionsTypes;

(function (StatusActionsTypes) {
  StatusActionsTypes["STATUS_SET_LOADING"] = "STATUS_SET_LOADING";
  StatusActionsTypes["STATUS_SET_ERROR"] = "STATUS_SET_ERROR";
  StatusActionsTypes["STATUS_SET_SUCCESS"] = "STATUS_SET_SUCCESS";
  StatusActionsTypes["STATUS_SET_IDLE"] = "STATUS_SET_IDLE";
  StatusActionsTypes["STATUS_SET_SUBMIT"] = "STATUS_SET_SUBMIT";
  StatusActionsTypes["RESET_ALL_STATUS"] = "RESET_ALL_STATUS";
})(StatusActionsTypes || (StatusActionsTypes = {}));