import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const TabSelector = _styled.a(({
  selected
}) => [{
  "whiteSpace": "nowrap",
  "paddingTop": "1.6rem",
  "paddingBottom": "1.6rem",
  "paddingLeft": "0.4rem",
  "paddingRight": "0.4rem",
  "borderBottomWidth": "2px",
  "fontWeight": "500",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "cursor": "pointer",
  "textTransform": "capitalize"
}, selected ? {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(139, 92, 246, var(--tw-border-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(124, 58, 237, var(--tw-text-opacity))"
} : {
  "borderColor": "transparent",
  "--tw-text-opacity": "1",
  "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
  ":hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
  }
}]);
export const Tabs = ({
  tabs,
  selected,
  onSelect
}) => {
  return __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "@media (min-width: 1024px)": {
        "display": "none"
      }
    }
  }, __cssprop("label", {
    htmlFor: "selected-tab",
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Select a tab"), __cssprop("select", {
    id: "selected-tab",
    name: "selected-tab",
    css: {
      "marginTop": "0.4rem",
      "display": "block",
      "width": "100%",
      "paddingLeft": "1.2rem",
      "paddingRight": "4rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(139, 92, 246, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(139, 92, 246, var(--tw-border-opacity))"
      },
      "borderRadius": "0.6rem",
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }
  }, tabs.map(tab => __cssprop("option", {
    key: tab.id
  }, tab.content)))), __cssprop("div", {
    css: {
      "display": "none",
      "@media (min-width: 1024px)": {
        "display": "block"
      }
    }
  }, __cssprop("div", {
    css: {
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
    }
  }, __cssprop("nav", {
    css: {
      "marginBottom": "-1px",
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(3.2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(3.2rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, tabs.map((tab, idx) => __cssprop(TabSelector, {
    key: tab.id,
    selected: idx === selected,
    onClick: () => onSelect && onSelect(idx),
    "aria-hidden": true
  }, tab.content))))));
};