import React from "../../../../../../_snowpack/pkg/react.js";
import { useParams } from "../../../../../../_snowpack/pkg/react-router.js";
import { useAction, useSelector } from "../../../../hooks/useRedux.js";
import { Selectors } from "../../../../store/index.js";
import { EventActionTypes } from "../../../../store/artifacts/Events/types.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const EventDescription = () => {
  const {
    id
  } = useParams();
  const {
    dispatch
  } = useAction();
  const description = useSelector(Selectors.event.getDescription);

  const handleChange = e => {
    const {
      value
    } = e.target;
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        description: value
      }
    });

    if (id) {
      dispatch({
        type: EventActionTypes.EVENT_UPDATE,
        payload: {
          description: value
        }
      });
    }
  };

  return __cssprop("input", {
    type: "text",
    css: {
      "width": "100%",
      "borderWidth": "0px",
      "fontSize": "2rem",
      "lineHeight": "2.8rem",
      "borderRadius": "0.6rem",
      "backgroundColor": "transparent",
      ":focus": {
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)"
      },
      "paddingLeft": "0px"
    },
    value: description || "",
    onChange: handleChange,
    placeholder: "Description"
  });
};

export default EventDescription;