import firebase from "../../../_snowpack/pkg/firebase.js";
import "../../../_snowpack/pkg/firebase/auth.js";
import axios from "../../../_snowpack/pkg/axios.js";
export const regenerateToken = async () => firebase.auth().currentUser.getIdToken();
export async function loginWithProvider(provider, isRegistration, rid) {
  const result = await firebase.auth().signInWithPopup(provider); // force refresh to allow custom claims to be propogated to client https://firebase.google.com/docs/auth/admin/custom-claims?hl=en#propagate_custom_claims_to_the_client

  const refreshedToken = await result.user.getIdToken(true); // eslint-disable-next-line consistent-return

  return {
    token: refreshedToken
  };
}
export async function loginWithGoogle(isRegistration = false, rid) {
  return loginWithProvider(new firebase.auth.GoogleAuthProvider(), isRegistration, rid);
}
export async function loginWithMicrosoft(isRegistration = false, rid) {
  return loginWithProvider(new firebase.auth.OAuthProvider("microsoft.com"), isRegistration, rid);
}
export async function loginWithFacebook(isRegistration = false, rid) {
  return loginWithProvider(new firebase.auth.FacebookAuthProvider(), isRegistration, rid);
}
export async function loginWithUnamePwd(email, password) {
  const result = await firebase.auth().signInWithEmailAndPassword(email, password); // force refresh to allow custom claims to be propogated to client https://firebase.google.com/docs/auth/admin/custom-claims?hl=en#propagate_custom_claims_to_the_client

  const refreshedToken = await result.user.getIdToken(true); // eslint-disable-next-line consistent-return

  return {
    token: refreshedToken
  };
}
export async function loginUsingCustomToken(token) {
  return firebase.auth().signInWithCustomToken(token);
}
export async function logout() {
  return firebase.auth().signOut();
}