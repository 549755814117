import React from "../../../../../_snowpack/pkg/react.js";
import { useSelector } from "../../../../../_snowpack/pkg/react-redux.js";
import Select from "../../../../../_snowpack/pkg/react-select.js";
import { useParams } from "../../../../../_snowpack/pkg/react-router.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { FaCalendar, FaCircleNotch, FaTag, FaChevronUp, FaUserCircle, FaEdit, FaWindowClose, FaPenNib, FaTrashAlt } from "../../../../../_snowpack/pkg/react-icons/fa.js";
import { Selectors } from "../../../store/index.js";
import { useAction } from "../../../hooks/useRedux.js";
import { TicketActionTypes } from "../../../store/artifacts/Tickets/types.js";
import { TicketPriority } from "../../../graphql/documents.js";
import { Badge } from "../../Tailwind/Badge.js";
import useI18n from "../../../hooks/useI18n.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const priorities = t => [{
  label: t("ticket-priority-low"),
  value: "low",
  name: "priority"
}, {
  label: t("ticket-priority-medium"),
  value: "medium",
  name: "priority"
}, {
  label: t("ticket-priority-high"),
  value: "high",
  name: "priority"
}];

export const prioritiesBadges = t => ({
  [TicketPriority.Low]: __cssprop(Badge, {
    success: true
  }, t("ticket-priority-low")),
  [TicketPriority.Medium]: __cssprop(Badge, {
    warning: true
  }, t("ticket-priority-medium")),
  [TicketPriority.High]: __cssprop(Badge, {
    critical: true
  }, t("ticket-priority-high"))
});

const getOrigin = t => ({
  manual: t("ticket-manual"),
  email: t("ticket-email"),
  recurringEvent: t("ticket-recurring-event"),
  userSubmission: t("ticket-user-submission")
});

export const getTicketCategories = t => [{
  label: t("maintenance"),
  value: "maintenance",
  name: "category"
}, {
  label: t("prevent"),
  value: "prevent",
  name: "category"
}, {
  label: t("management"),
  value: "management",
  name: "category"
}, {
  label: t("rental"),
  value: "rental",
  name: "category"
}, {
  label: t("coproperty"),
  value: "coproperty",
  name: "category"
}, {
  label: t("administration"),
  value: "administration",
  name: "category"
}, {
  label: t("payment-issue"),
  value: "payment_issue",
  name: "category"
}, {
  label: t("other"),
  value: "other",
  name: "category"
}];

const TicketInfoSidebarGeneral = ({
  data
}) => {
  const {
    t
  } = useI18n();
  const {
    id
  } = useParams();
  const {
    dispatch
  } = useAction();
  const tasks = useSelector(Selectors.ticket.getTasks);
  const priority = useSelector(Selectors.ticket.getPriority);
  const category = useSelector(Selectors.ticket.getCategory);
  const modifiedAt = useSelector(Selectors.ticket.getModifiedAt);
  const closedAt = useSelector(Selectors.ticket.getClosedAt);
  const createdAt = useSelector(Selectors.ticket.getCreatedAt);
  const deletedAt = useSelector(Selectors.ticket.getDeletedAt);
  const origin = useSelector(Selectors.ticket.getOrigin);
  const completedTasks = tasks?.items?.length > 0 ? tasks?.items?.filter(task => task.closedAt).length : 0;

  const handleSetField = event => {
    dispatch({
      type: id ? TicketActionTypes.TICKET_UPDATE : TicketActionTypes.TICKET_SET_FIELD,
      payload: {
        [event.name]: event.value
      }
    });
  };

  const handleClearField = event => {
    const {
      id: fieldId
    } = event.currentTarget;

    if (!closedAt) {
      dispatch({
        type: TicketActionTypes.TICKET_SET_FIELD,
        payload: {
          [fieldId]: ""
        }
      });
    }
  };

  return __cssprop("div", null, __cssprop("div", {
    css: [id && {
      "display": "flex",
      "flexDirection": "column",
      "gap": "1.6rem"
    }]
  }, id && __cssprop(React.Fragment, null, tasks?.items?.length > 0 && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", null, __cssprop(FaCircleNotch, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", null, `${completedTasks}/${tasks?.items?.length}${t("tickets-info-general-completed-tasks")}`)), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaUserCircle, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9"
    }
  }, `${t("tickets-info-general-created-by")}${data?.createdBy?.name}`)), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaPenNib, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9"
    }
  }, `${t("tickets-info-general-origin")}${getOrigin(t)[origin]}`)), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaCalendar, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9"
    }
  }, `${t("tickets-info-general-opened-on")}${DateTime.fromISO(`${createdAt}`).toFormat("LLL dd")}`)), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaEdit, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9"
    }
  }, `${t("tickets-info-general-modified-on")}${DateTime.fromISO(`${modifiedAt}`).toFormat("LLL dd")}`)), closedAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaWindowClose, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9"
    }
  }, `${t("tickets-info-general-closed-on")}${DateTime.fromISO(`${closedAt}`).toFormat("LLL dd")}`)), deletedAt && __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaTrashAlt, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 9 / span 9"
    }
  }, `${t("tickets-info-general-deleted-on")}${DateTime.fromISO(`${deletedAt}`).toFormat("LLL dd")}`))), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaChevronUp, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", {
    css: id ? {
      "gridColumn": "span 9 / span 9",
      "width": "100%"
    } : {
      "marginTop": "1.6rem",
      "width": "100%"
    }
  }, id && priority ? __cssprop("div", {
    role: "presentation",
    css: !closedAt && {
      ":hover": {
        "cursor": "pointer"
      }
    },
    onKeyDown: handleClearField,
    onClick: handleClearField,
    id: "priority"
  }, prioritiesBadges(t)[priority]) : __cssprop(Select, {
    style: {
      width: "100%"
    },
    isMulti: false,
    placeholder: t("tickets-table-priority"),
    onChange: handleSetField,
    options: priorities(t),
    isDisabled: false,
    closeMenuOnSelect: true
  }))), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "gap": "0.8rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop(FaTag, {
    css: {
      "width": "1.6rem"
    },
    size: 24,
    color: "grey"
  })), __cssprop("div", {
    css: id ? {
      "gridColumn": "span 9 / span 9",
      "width": "100%"
    } : {
      "marginTop": "1.6rem",
      "width": "100%"
    }
  }, id && category ? __cssprop("div", {
    role: "presentation",
    css: !closedAt && {
      ":hover": {
        "cursor": "pointer"
      }
    },
    onKeyDown: handleClearField,
    onClick: handleClearField,
    id: "category"
  }, __cssprop(Badge, {
    primary: true
  }, category)) : __cssprop(Select, {
    style: {
      width: "100%"
    },
    isMulti: false,
    placeholder: t("tickets-table-category"),
    onChange: handleSetField,
    options: getTicketCategories(t),
    isDisabled: false,
    closeMenuOnSelect: true
  })))));
};

export default TicketInfoSidebarGeneral;