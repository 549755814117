import React, { useEffect, useRef } from "../../../../../../_snowpack/pkg/react.js";
import { useParams } from "../../../../../../_snowpack/pkg/react-router.js";
import RRuleGenerator from "../../../../../../_snowpack/pkg/react-rrule-generator.js";
import "../../../../../../_snowpack/pkg/react-rrule-generator/build/styles.css";
import { useAction, useSelector } from "../../../../hooks/useRedux.js";
import { Selectors } from "../../../../store/index.js";
import { Card } from "../../../../components/Tailwind/Card.js";
import { EventActionTypes } from "../../../../store/artifacts/Events/types.js";
import { StyledButton } from "../../../../components/Tailwind/Button.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const EventInterval = () => {
  const {
    id
  } = useParams();
  const {
    dispatch
  } = useAction();
  const ref = useRef(null);
  const RRule = useSelector(Selectors.event.getRRule);
  useEffect(() => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        RRule: ref.current.state.rrule
      }
    });
  }, []);

  const handleChange = e => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        RRule: e
      }
    });
  };

  const handleEdit = () => {
    dispatch({
      type: EventActionTypes.EVENT_UPDATE,
      payload: {
        RRule
      }
    });
  };

  return __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(RRuleGenerator, {
    ref: ref,
    onChange: handleChange,
    config: {
      end: ["Never", "On date", "After"],
      hideStart: false
    },
    value: RRule
  }), id && __cssprop(StyledButton, {
    onClick: handleEdit,
    primary: true
  }, "Edit")));
};

export default EventInterval;