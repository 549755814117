function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { Disclosure } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { ProtectedButton } from "./ProtectedComponents.js";
import { statusColor } from "./sharedStyles.js";
import { ChevronDownIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const ChevronContainer = _styled.div(({
  open
}) => [open && {
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "180deg",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
}]);

const CardBody = _styled.div(({
  status
}) => [{
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "position": "relative",
  "@media (min-width: 640px)": {
    "borderRadius": "0.8rem"
  }
}, status === "Warning" && statusColor.warning, status === "Critical" && statusColor.critical, status === "Disabled" && statusColor.disabled]);

const Sectionned = _styled.div(({
  sectionned
}) => [sectionned && {
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-divide-y-reverse": 0,
    "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
    "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
    "--tw-divide-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
  }
}]);

const Header = _styled.div(({
  sectionned
}) => [{
  "paddingLeft": "1.6rem",
  "paddingRight": "1.6rem",
  "paddingTop": "2rem",
  "paddingBottom": "2rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "@media (min-width: 640px)": {
    "paddingLeft": "2.4rem",
    "paddingRight": "2.4rem"
  }
}]);

const ActionContainer = _styled.div(({
  disabled
}) => [disabled && {
  "pointerEvents": "none",
  "opacity": "0.2"
}]);

const MainCard = ({
  children,
  title,
  sectionned,
  primaryHeaderAction,
  status,
  CustomAction,
  collapsible
}) => {
  return __cssprop(CardBody, {
    status: status
  }, __cssprop(Sectionned, {
    sectionned: sectionned
  }, title && __cssprop(Header, {
    sectionned: sectionned
  }, __cssprop("h2", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, title || ""), primaryHeaderAction && __cssprop(ActionContainer, {
    disabled: primaryHeaderAction?.disabled
  }, __cssprop(ProtectedButton, {
    permission: primaryHeaderAction.permission,
    loading: primaryHeaderAction?.loading,
    destructive: primaryHeaderAction?.destructive,
    onAction: primaryHeaderAction?.onAction,
    plain: true
  }, primaryHeaderAction.content)), CustomAction && CustomAction), children));
};

const Section = ({
  children,
  greyed
}) => {
  if (greyed) {
    return __cssprop("div", {
      css: {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem",
        "paddingTop": "2.4rem",
        "paddingBottom": "2.4rem",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem",
          "paddingRight": "2.4rem"
        }
      }
    }, children);
  }

  return __cssprop("div", {
    css: {
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, children);
};

export const Card = function Card(props) {
  const {
    collapsible,
    children
  } = props;

  if (collapsible) {
    return __cssprop(Disclosure, null, ({
      open
    }) => __cssprop(MainCard, _extends({}, props, {
      CustomAction: __cssprop(Disclosure.Button, {
        css: {
          "display": "flex",
          "justifyContent": "flex-end",
          "paddingLeft": "1.6rem",
          "paddingRight": "1.6rem",
          "paddingTop": "0.8rem",
          "paddingBottom": "0.8rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "fontWeight": "500",
          "textAlign": "left",
          "--tw-text-opacity": "1",
          "color": "rgba(76, 29, 149, var(--tw-text-opacity))",
          ":focus": {
            "outline": "2px solid transparent",
            "outlineOffset": "2px"
          },
          ":focus-visible": {
            "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
            "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
            "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
            "--tw-ring-opacity": "0.75",
            "--tw-ring-color": "rgba(139, 92, 246, var(--tw-ring-opacity))"
          }
        }
      }, __cssprop(ChevronContainer, {
        open: open
      }, __cssprop(ChevronDownIcon, {
        css: {
          "width": "2rem",
          "height": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(139, 92, 246, var(--tw-text-opacity))"
        }
      })))
    }), __cssprop(Disclosure.Panel, null, children)));
  }

  return __cssprop(MainCard, props);
};
Card.Section = Section;