import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React from "../../../../_snowpack/pkg/react.js";
import { Badge } from "../Tailwind/Badge.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);

const PaymentSideBar = props => {
  const {
    sectionTitle,
    description
  } = props;
  const {
    t
  } = useI18n();
  return __cssprop("div", null, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, sectionTitle)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("panel-payment-activity")), __cssprop("div", null, __cssprop("ul", null, __cssprop("li", null, "Paiement cr\xE9\xE9 ", DateTime.utc().minus({
    days: 5
  }).toISODate()), __cssprop("li", null, "Paiement accept\xE9 ", DateTime.utc().toISODate()))))), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("panel-payment-type")), __cssprop(Badge, {
    success: true
  }, t("panel-payment-type-automatic")))), __cssprop(StyledItems, null)));
};

export const PaymentPanel = props => {
  const activeDrawer = useSelector(Selectors.app.getDrawerState);
  const {
    dispatch
  } = useAction();
  const {
    formTitle,
    description,
    sectionTitle
  } = props;
  return __cssprop(SlideOver, {
    title: formTitle,
    isOpen: activeDrawer === "payment",
    onClose: () => dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    })
  }, __cssprop(PaymentSideBar, {
    sectionTitle: sectionTitle,
    description: description
  }));
};