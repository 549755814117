import { createSelector } from "../../../../../_snowpack/pkg/reselect.js";

const getStatus = state => state?.status;

const getBuildingStatus = state => getStatus(state)?.building;

const getListingStatus = state => getStatus(state)?.listing;

const getLeaseStatus = state => getStatus(state)?.lease;

const getTenantStatus = state => getStatus(state)?.tenant;

const getRentalStatus = state => getStatus(state)?.rental;

const getDepositStatus = state => getStatus(state)?.deposit;

const getProjectStatus = state => getStatus(state)?.project;

const getUserStatus = state => getStatus(state)?.user;

const getNotesStatus = state => getStatus(state)?.note;

const getEventStatus = state => getStatus(state)?.event;

const getTicketStatus = state => getStatus(state)?.ticket;

const getCalendarStatus = state => getStatus(state)?.calendar;

const isLoading = key => createSelector(getStatus, status => status[key] === "Loading");

const isSubmiting = key => createSelector(getStatus, status => status[key] === "Submit");

export default {
  getBuildingStatus,
  getListingStatus,
  getLeaseStatus,
  getTenantStatus,
  getRentalStatus,
  getDepositStatus,
  getProjectStatus,
  getUserStatus,
  getEventStatus,
  isLoading,
  isSubmiting,
  getNotesStatus,
  getTicketStatus,
  getCalendarStatus
};