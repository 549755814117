/* eslint-disable import/no-cycle */
import { UNIT_TICKET_PRIORITY_SELECT_OPTIONS } from "../../../utils/unit.js";
export const getEvent = state => state?.event;
export default {
  getEvent,
  getId: state => getEvent(state).id,
  getTitle: state => getEvent(state).title,
  getEntityMetadata: state => getEvent(state).entity,
  getFormattedEntityMetadata: state => {
    const {
      category,
      priority,
      resource,
      tags
    } = getEvent(state)?.entity || {};
    const categoryValue = category;
    const priorityValue = UNIT_TICKET_PRIORITY_SELECT_OPTIONS.find(item => item.value === priority) || "";
    return { ...getEvent(state).entity,
      category: categoryValue,
      priority: priorityValue,
      tags,
      resource
    };
  },
  getCreatedAt: state => getEvent(state).createdAt,
  getDescription: state => getEvent(state).description,
  getRRule: state => getEvent(state).RRule
};