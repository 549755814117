import * as React from "../../../../_snowpack/pkg/react.js";
import { Link } from "./Link.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const BreadCrumbs = ({
  pages
}) => {
  if (pages?.length === 1) {
    return null;
  }

  return __cssprop("nav", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "display": "flex"
    },
    "aria-label": "Breadcrumb"
  }, __cssprop("ol", {
    css: {
      "maxWidth": "1280px",
      "width": "100%",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1.6rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "height": "4.8rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 1024px)": {
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem"
      }
    }
  }, pages.map((page, idx) => {
    if (idx === pages.length - 1) {
      return null;
    }

    return __cssprop("li", {
      key: page.title,
      css: {
        "display": "flex"
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center"
      }
    }, idx > 0 && __cssprop("svg", {
      css: {
        "flexShrink": "0",
        "width": "2.4rem",
        "height": "100%",
        "--tw-text-opacity": "1",
        "color": "rgba(229, 231, 235, var(--tw-text-opacity))"
      },
      viewBox: "0 0 24 44",
      preserveAspectRatio: "none",
      fill: "currentColor",
      xmlns: "http://www.w3.org/2000/svg",
      "aria-hidden": "true"
    }, __cssprop("path", {
      d: "M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"
    })), __cssprop("div", {
      css: {
        "marginLeft": "1.6rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
        }
      }
    }, __cssprop(Link, {
      text: page.title,
      action: {
        kind: "InternalLink",
        to: page.path
      },
      disabled: idx === pages.length - 1
    }))));
  })));
};