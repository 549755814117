export const initialState = {
  user: null,
  token: null,
  lastRequestTimestamp: -1
};
// =========== ACTIONS CREATORS =========== //
export let SessionActionsTypes;

(function (SessionActionsTypes) {
  SessionActionsTypes["SESSION_SET_FIELD"] = "SESSION_SET_FIELD";
  SessionActionsTypes["SESSION_SET_USER_FIELD"] = "SESSION_SET_USER_FIELD";
  SessionActionsTypes["ON_FORGOT_PASSWORD"] = "ON_FORGOT_PASSWORD";
  SessionActionsTypes["ON_USER_LOGIN"] = "ON_USER_LOGIN";
  SessionActionsTypes["ON_USER_LOGOUT"] = "ON_USER_LOGOUT";
})(SessionActionsTypes || (SessionActionsTypes = {}));