import React, { useState } from "../../../../../../_snowpack/pkg/react.js";
import { StyledButton } from "../../../../components/Tailwind/Button.js";
import { Card } from "../../../../components/Tailwind/Card.js";
import { useAction } from "../../../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../../../store/artifacts/Application/types.js";
import Select from "../../../../../../_snowpack/pkg/react-select.js";
import { typeOfEntities } from "../../../../utils/event.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const EventSideEntityButtons = () => {
  const [type, setType] = useState();
  const {
    dispatch
  } = useAction();

  const handleCreateTicket = () => {
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: "ticket-create"
    });
  };

  const handleSelectTypeOfResource = e => {
    setType(e.value);
  };

  return __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "1.6rem"
    }
  }, __cssprop("h2", {
    css: {
      "fontSize": "2rem",
      "lineHeight": "2.8rem"
    }
  }, "Entity"), __cssprop(Select, {
    isMulti: false,
    placeholder: "Type of entity",
    onChange: handleSelectTypeOfResource,
    options: typeOfEntities,
    menuContainerStyle: {
      zIndex: 25
    }
  }), __cssprop(StyledButton, {
    onClick: handleCreateTicket,
    primary: true,
    disabled: !type
  }, "Create new Entity"))));
};

export default EventSideEntityButtons;