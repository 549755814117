import React from "../../../../../_snowpack/pkg/react.js";
import { Badge } from "../../../components/Tailwind/Badge.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const getTicketStatusBadge = (status, t) => {
  if (status.deletedAt) {
    return __cssprop(Badge, {
      critical: true
    }, t("tickets-status-badge-deleted"));
  }

  if (status.closedAt) {
    return __cssprop(Badge, {
      success: true
    }, t("tickets-status-badge-closed"));
  }

  return __cssprop(Badge, {
    primary: true
  }, t("tickets-status-badge-opened"));
};