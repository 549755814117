import { useMutation } from "../../../../_snowpack/pkg/@apollo/client.js";
import { BuildingAddressRender } from "../../components/Building/BuildingAddress.js";
import { DeliveryPanel } from "../../components/Panels/DeliveryPanel.js";
import { DeliveryLeaseDetailsPanel } from "../../components/Panels/DeliveryLeaseDetailsPanel.js";
import { Stepper } from "../../components/Stepper/index.js";
import { DropDownActions } from "../../components/Tailwind/DropDownActions.js";
import { PaginatedDataTable } from "../../components/Tailwind/PaginatedDataTable.js";
import { ProtectedActions } from "../../components/Tailwind/ProtectedComponents.js";
import { SmallTenant } from "../../components/Tailwind/SmallTenant.js";
import { GetDeliveriesDocument, UpdateDeliveryDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { Selectors } from "../../store/index.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { ImKey } from "../../../../_snowpack/pkg/react-icons/im.js";
import { IoWallet, IoFileTraySharp } from "../../../../_snowpack/pkg/react-icons/io5.js";
import { useSelector } from "../../../../_snowpack/pkg/react-redux.js";
import { toast } from "../../../../_snowpack/pkg/react-toastify.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Deliveries = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const [lease, setLease] = useState(undefined);
  const role = useSelector(Selectors.session.getUserRole); // const [notes, setNotes] = useState<NotesGql[]>(undefined);

  const [updateDelivery, {
    loading: loadingUpdateLease
  }] = useMutation(UpdateDeliveryDocument, {
    fetchPolicy: "no-cache"
  });
  const columns = [{
    Header: t("building-form-address"),
    accessor: "address",
    disableSortBy: true
  }, {
    Header: t("lease-agent"),
    accessor: "rentalAgent",
    disableSortBy: false
  }, {
    Header: t("contract-form-start-date"),
    accessor: "startDate",
    disableSortBy: false
  }, {
    Header: t("status"),
    accessor: "stepper",
    disableSortBy: true
  }, {
    Header: t("unit-heading-action"),
    accessor: "action",
    disableSortBy: true
  }];

  const renderBody = item => {
    const {
      unit: {
        subAddress,
        buildingAddress,
        id,
        buildingId
      },
      startDate,
      files,
      downPaymentAmount,
      totalDownPaymentAmount
    } = item;
    const stepperView = [{
      name: item.keysDeliveredAt ? t("delivery-key-delivered") : t("delivery-key-not-delivered"),
      state: item.keysDeliveredAt ? "success" : "neutral",
      Icon: ImKey
    }, {
      name: item?.documentsMarkedCompletedAt ? t("delivery-document-not-delivered") : t("delivery-document-not-delivered"),
      state: item?.documentsMarkedCompletedAt ? "success" : "neutral",
      Icon: IoFileTraySharp
    }];

    if (downPaymentAmount === 0) {
      stepperView.push({
        name: t("delivery-downpayment-not-delivered"),
        state: "neutral",
        Icon: IoWallet
      });
    } else if (downPaymentAmount === totalDownPaymentAmount) {
      stepperView.push({
        name: t("delivery-downpayment-received"),
        state: "success",
        Icon: IoWallet
      });
    } else {
      stepperView.push({
        name: t("delivery-downpayment-partial"),
        state: "warning",
        Icon: IoWallet
      });
    }

    return {
      address: item.unit && __cssprop(BuildingAddressRender, {
        unit: {
          subAddress,
          buildingAddress,
          id
        },
        buildingId: buildingId
      }),
      rentalAgent: item.leaseAgent ? __cssprop(SmallTenant, {
        tenant: item.leaseAgent
      }) : "",
      startDate: __cssprop("div", null, DateTime.fromISO(startDate).toUTC().toFormat("MMMM dd, yyyy")),
      stepper: __cssprop(Stepper, {
        simpleMode: true,
        steps: stepperView
      }),
      action: __cssprop(DropDownActions, {
        content: "open",
        dots: true,
        actions: [ProtectedActions(role, [{
          content: t("delivery-edit-title"),
          onAction: () => {
            dispatch({
              type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
              payload: "delivery"
            });
            setLease(item);
          },
          permission: {
            kind: "Lease",
            permission: "mutate:update"
          }
        }]), ProtectedActions(role, [{
          content: t("view-detail"),
          onAction: () => {
            dispatch({
              type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
              payload: "lease-tenant-information"
            });
            setLease(item);
          },
          permission: {
            kind: "Lease",
            permission: "mutate:update"
          }
        }])]
      })
    };
  };

  return __cssprop(React.Fragment, null, __cssprop(DeliveryPanel, {
    onDoneSubmit: async data => {
      const res = await updateDelivery({
        variables: {
          delivery: {
            delivery: data,
            id: lease.id
          }
        }
      });

      if (res.errors && res.errors.length > 0) {
        toast.error(t("delivery-form-error"));
        return;
      }

      if (res.data) {
        toast.success(t("delivery-form-success"));
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: null
        });
      }
    },
    lease: lease,
    loading: loadingUpdateLease
  }), __cssprop(DeliveryLeaseDetailsPanel, {
    leaseId: lease?.id
  }), __cssprop(PaginatedDataTable, {
    title: t("deliveries"),
    query: GetDeliveriesDocument,
    headers: columns,
    renderItem: renderBody,
    resourceName: {
      plural: t("deliveries"),
      singular: t("delivery")
    } // since i cant refetch i put a quicker pulling
    ,
    pollInterval: 5000,
    extraVariables: {
      status: "deliveries"
    }
  }));
};
export default Deliveries;