function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { useLazyQuery, useMutation } from "../../../../_snowpack/pkg/@apollo/client.js";
import { Loader } from "../../components/Loader.js";
import { Notes } from "../../components/Tailwind/Notes.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { NumberTextField } from "../../components/Tailwind/TextField.js";
import ToggleSection from "../../components/ToggleSection.js";
import { AddNoteDocument, GetDeliveryLeaseDetailsDocument, RemoveNoteDocument } from "../../graphql/documents.js";
import { RESOURCES } from "../../hooks/useFiles.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { Selectors } from "../../store/index.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { mapNote } from "../../store/utils/Notes.js";
import FileDropper from "../../views/ApplicantRequestV2/components/fileDropper.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useCallback, useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { useSelector } from "../../../../_snowpack/pkg/react-redux.js";
import { LeaseAgentSelector } from "../sections/LeaseAgentSelector.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);
export const DeliverySideBar = props => {
  const {
    dispatch
  } = useAction();
  const {
    lease = {},
    loading,
    onDoneSubmit
  } = props; // const role = useSelector(Selectors.session.getUserRole);
  // added this check incase that the from manage to open without a lease in props
  // without that line and no lease in props will be stuck in blank page forever

  if (!lease) {
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
  }

  const {
    documentsMarkedCompletedAt = null,
    keysDeliveredAt = null,
    deliveryFinalizedAt = null,
    leaseAgent = null,
    downPaymentAmount = 0,
    totalDownPaymentAmount = 0
  } = lease;
  const {
    t
  } = useI18n();
  const {
    control,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();
  const onSubmit = useCallback(data => {
    onDoneSubmit({
      id: data.id,
      documentsMarkedCompletedAt: data.documentDelivered ? DateTime.now().toISO() : null,
      keysDeliveredAt: data.keyDelivered ? DateTime.now().toISO() : null,
      deliveryFinalizedAt: data.markAsDelivered ? DateTime.now().toISO() : null,
      downPaymentAmount: data.downPaymentAmount,
      totalDownPaymentAmount: data.totalDownPaymentAmount,
      leaseAgentId: data?.leaseAgent?.id ? data.leaseAgent.id : null
    });
  }, [onDoneSubmit]);
  const role = useSelector(Selectors.session.getUserRole);
  const [isReady, setIsReady] = useState(false);
  const [notes, setNotes] = useState([]);
  const [files, setFiles] = useState([]);
  const [addNote, {
    loading: addNoteLoading
  }] = useMutation(AddNoteDocument, {
    fetchPolicy: "no-cache"
  });
  const [removeNote, {
    loading: removeNoteLoading
  }] = useMutation(RemoveNoteDocument, {
    fetchPolicy: "no-cache"
  });
  const [loadDeliveryLeaseDetails, {
    loading: queryLoading
  }] = useLazyQuery(GetDeliveryLeaseDetailsDocument, {
    variables: {
      id: lease?.id
    },
    onCompleted: data => {
      setNotes(data.lease.notes.items);
      setFiles(data.lease.files.items);
      setIsReady(true);
    }
  });
  useEffect(() => {
    if (!lease?.id) {
      return;
    }

    if (isReady) {
      return;
    }

    loadDeliveryLeaseDetails();
  }, [lease, isReady]);

  if (queryLoading || !isReady || !lease?.id) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(Controller, {
    name: "leaseAgent",
    control: control,
    defaultValue: leaseAgent,
    render: ({
      field
    }) => {
      return __cssprop(LeaseAgentSelector, {
        title: t("lease-agent"),
        leaseAgent: leaseAgent,
        onSelectCallback: v => {
          return field.onChange(v);
        }
      });
    }
  }), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "keyDelivered",
    control: control,
    defaultValue: !!keysDeliveredAt,
    render: ({
      field
    }) => __cssprop(ToggleSection, {
      title: t("delivery-key-delivered"),
      active: field.value,
      handleToggle: v => field.onChange(!v)
    })
  }), __cssprop(Controller, {
    name: "documentDelivered",
    control: control,
    defaultValue: !!documentsMarkedCompletedAt,
    render: ({
      field
    }) => __cssprop(ToggleSection, {
      title: t("delivery-document-delivered"),
      active: field.value,
      handleToggle: v => field.onChange(!v)
    })
  }), __cssprop(Controller, {
    name: "markAsDelivered",
    control: control,
    defaultValue: !!deliveryFinalizedAt,
    render: ({
      field
    }) => __cssprop(ToggleSection, {
      title: t("delivery-marked-delivered"),
      active: field.value,
      handleToggle: v => field.onChange(!v)
    })
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "downPaymentAmount",
    control: control,
    defaultValue: downPaymentAmount || 0,
    render: ({
      field
    }) => __cssprop(NumberTextField, _extends({
      thousandSeparator: true,
      suffix: "$"
    }, field, {
      label: t("downpayment"),
      error: showError(errors, "amount", t("applicant-request-list-down-payment-amount-received"))
    }))
  }), __cssprop(Controller, {
    name: "totalDownPaymentAmount",
    control: control,
    defaultValue: totalDownPaymentAmount || 0,
    render: ({
      field
    }) => __cssprop(NumberTextField, _extends({
      thousandSeparator: true,
      suffix: "$"
    }, field, {
      label: t("total-downpayment"),
      error: showError(errors, "amount", t("applicant-request-list-down-payment-amount-received"))
    }))
  })), __cssprop(StyledItems, null, __cssprop(Notes, {
    notes: notes?.map(mapNote),
    loading: addNoteLoading || removeNoteLoading,
    permission: {
      permission: "view:notes",
      kind: "Lease"
    },
    addNoteEvent: async pNotes => {
      const rep = await addNote({
        variables: {
          note: {
            content: pNotes.note
          },
          resourceId: lease?.id,
          resourceType: RESOURCES.Lease
        }
      });
      setNotes(prev => {
        return [...prev, rep.data.addNote];
      });
    },
    hideNewNote: role === "Client" || role === "User",
    removeNoteEvent: async pNotes => {
      await removeNote({
        variables: {
          noteId: pNotes.id
        }
      });
      setNotes(prev => prev.filter(no => no.id !== pNotes.id));
    }
  })), __cssprop(StyledItems, null, __cssprop(FileDropper, {
    resourceId: lease.id,
    resourceType: RESOURCES.Lease,
    files: files || [],
    readOnly: false
  }))), __cssprop("div", {
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "justifyContent": "flex-end"
    }
  }, __cssprop(ProtectedButton, {
    permission: {
      kind: "Deposit",
      permission: "mutate:update"
    },
    submit: true,
    loading: loading,
    disabled: Object.keys(errors).length !== 0,
    primary: true
  }, t("save")))));
};
export default DeliverySideBar;