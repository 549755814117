import Map from "../../../components/Map.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { FileDropZone } from "../../../components/Tailwind/FileDropZone.js";
import { Notes } from "../../../components/Tailwind/Notes.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../store/artifacts/Building/types.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const DetailsPane = () => {
  const {
    t
  } = useI18n();
  const notes = useSelector(Selectors.building.getFilteredNotes);
  const role = useSelector(Selectors.session.getUserRole);
  const latitude = useSelector(Selectors.building.getLatitude);
  const longitude = useSelector(Selectors.building.getLongitude);
  const imageResource = useSelector(Selectors.building.getImageResource);
  const noteLoading = useSelector(Selectors.status.isSubmiting("note"));
  const buildingLoading = useSelector(Selectors.status.isSubmiting("building"));
  const {
    dispatch
  } = useAction();
  return __cssprop(React.Fragment, null, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(Map, {
    latitude: latitude,
    longitude: longitude
  }))), __cssprop(Card, {
    title: t("file-section-title")
  }, __cssprop(Card.Section, null, __cssprop(FileDropZone, {
    onDeleteDispatch: BuildingActionsTypes.BUILDING_REMOVE_FILES,
    onDropDispatch: BuildingActionsTypes.BUILDING_ADD_FILES,
    files: useSelector(Selectors.building.getFiles)
  }))), __cssprop(Card, {
    title: t("file-uploader-add-image")
  }, __cssprop(Card.Section, null, __cssprop(FileDropZone, {
    onDeleteDispatch: BuildingActionsTypes.BUILDING_REMOVE_IMAGE,
    onDropDispatch: BuildingActionsTypes.BUILDING_ADD_IMAGE,
    files: imageResource ? [imageResource] : [],
    highlightText: t("file-uploader-add-image"),
    description: t("file-uploader-drop-single-image")
  }))), __cssprop(Notes, {
    notes: notes,
    permission: {
      kind: "Building",
      permission: "view:notes"
    },
    loading: noteLoading || buildingLoading,
    addNoteEvent: pNotes => {
      const newNote = {
        kind: "local",
        content: pNotes.note,
        id: `${notes.length + 1}`
      };
      dispatch({
        payload: [newNote],
        type: BuildingActionsTypes.BUILDING_ADD_NOTES
      });
    },
    hideNewNote: role === "Client" || role === "User",
    removeNoteEvent: pNotes => {
      dispatch({
        payload: pNotes.id,
        type: BuildingActionsTypes.BUILDING_REMOVE_NOTES
      });
    }
  }));
};