import '../../../common/_commonjsHelpers-f5d70792.js';
import { r as react } from '../../../common/index-8f144fe1.js';
import { r as reactDom } from '../../../common/index-821eef78.js';

(typeof globalThis !== 'undefined' ? globalThis : window).FullCalendarVDom = {
    Component: react.Component,
    createElement: react.createElement,
    render: reactDom.render,
    createRef: react.createRef,
    Fragment: react.Fragment,
    createContext: react.createContext,
    createPortal: reactDom.createPortal,
    flushSync: flushSync,
    unmountComponentAtNode: reactDom.unmountComponentAtNode // never called by FullCalendar's React component
};
function flushSync(callback) {
    // always sync from top-level
    callback();
}

export { flushSync };
