import { useMutation } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { ApplicantScore } from "../../../components/ApplicantScore.js";
import { Link } from "../../../components/Tailwind/Link.js";
import { IncrementApplicationRankDocument } from "../../../graphql/documents.js";
import { getRentalRequestStatus, getScoreText } from "../../../helpers/functions.js";
import useI18n from "../../../hooks/useI18n.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { FaArrowUp } from "../../../../../_snowpack/pkg/react-icons/fa.js";
import { toast } from "../../../../../_snowpack/pkg/react-toastify.js";
import ReactTooltip from "../../../../../_snowpack/pkg/react-tooltip.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const RankingList = props => {
  const {
    refetch,
    ownApplicationId,
    applications: pApplications,
    readOnly = false
  } = props;
  const {
    t
  } = useI18n();
  const [incrementApplicationRank, {
    loading: incrementApplicationRankLoading
  }] = useMutation(IncrementApplicationRankDocument, {
    fetchPolicy: "no-cache"
  });
  const submittedToOwner = pApplications.filter(a => !!a.sentToLandlordAt && !a.rejected);
  const waitingList = pApplications.filter(a => !a.sentToLandlordAt && a.isInOwnerApprovalQueue);
  const remainingApplicants = pApplications.filter(a => !!a.rejected || !a.sentToLandlordAt && !a.isInOwnerApprovalQueue && a.id !== ownApplicationId);

  const handleOnChangeRanking = async id => {
    if (readOnly) return null;

    try {
      const rankingResponse = await incrementApplicationRank({
        variables: {
          input: {
            rentalApplicationId: id
          }
        }
      });

      if (rankingResponse.errors) {
        toast.warn(`${t("generic-fetch-error-message")}`);
      }

      if (rankingResponse && rankingResponse.data) {
        refetch();
      }

      return null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("RankingSection", e);
      toast.warn(`${t("generic-fetch-error-message")}`);
      return null;
    }
  };

  const submittedSection = __cssprop("div", {
    css: {
      "minWidth": "100%",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "justifyContent": "space-between"
    }
  }, __cssprop("div", {
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("applicant-ranking-submitted-to-owner")), __cssprop("div", {
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("applicant-ranking-submission-date"))), __cssprop("div", null, submittedToOwner.map((s, index) => {
    return __cssprop("div", {
      key: index,
      css: {
        "display": "flex",
        "justifyContent": "space-between"
      }
    }, __cssprop("div", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "300",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, __cssprop(Link, {
      text: s.applicants.map(a => a.name).join(", "),
      action: {
        kind: "InternalLink",
        to: `/applicant/v2/${s.id}`
      }
    })), __cssprop("div", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "300",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, DateTime.fromISO(s.sentToLandlordAt).toUTC().toISODate()));
  })));

  const remainingListSection = __cssprop("div", {
    css: {
      "minWidth": "100%",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(6, minmax(0, 1fr))",
      "gap": "1.6rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 4 / span 4",
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("applicant-ranking-remaining")), __cssprop("div", {
    css: {
      "gridColumn": "span 1 / span 1",
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("applicant-ranking-score")), __cssprop("div", {
    css: {
      "gridColumn": "span 1 / span 1",
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("status"))), __cssprop("div", null, remainingApplicants.map((a, index) => {
    return __cssprop("div", {
      key: index,
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "300",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, a.applicants.map((applicant, key) => {
      const currentPass = applicant.applicationPass; // getCurrentPassFromPasses(applicant.passes, DateTime.fromISO(a.createdAt).toUTC());

      const scoreText = getScoreText(currentPass?.slScore, t);
      return __cssprop("div", {
        key: key,
        css: {
          "display": "grid",
          "gridTemplateColumns": "repeat(6, minmax(0, 1fr))",
          "gap": "1.6rem"
        }
      }, __cssprop("div", {
        css: {
          "gridColumn": "span 4 / span 4"
        }
      }, __cssprop("div", null, __cssprop(Link, {
        text: applicant.name,
        action: {
          kind: "InternalLink",
          to: `/applicant/v2/${a.id}`
        }
      }))), __cssprop("div", {
        css: {
          "gridColumn": "span 1 / span 1"
        }
      }, __cssprop(ApplicantScore, {
        css: {
          "marginTop": "0.4rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "fontWeight": "700",
          "@media (min-width: 640px)": {
            "gridColumn": "span 2 / span 2",
            "marginTop": "0px"
          }
        },
        score: Number.isNaN(Number(currentPass?.slScore)) ? 0 : Number(currentPass?.slScore)
      }, scoreText)), __cssprop("div", {
        css: {
          "gridColumn": "span 1 / span 1"
        }
      }, getRentalRequestStatus(a, t)));
    }));
  })));

  const waitingListSection = __cssprop("div", {
    css: {
      "minWidth": "100%",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "justifyContent": "space-between"
    }
  }, __cssprop("div", {
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("applicant-ranking-waiting-list")), __cssprop("div", {
    css: {
      "paddingTop": "1.4rem",
      "paddingBottom": "1.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.2rem",
      "textAlign": "left",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem"
      },
      "@media (min-width: 768px)": {
        "paddingLeft": "0px"
      }
    }
  }, t("applicant-ranking"))), __cssprop("div", null, waitingList.sort((wa, wb) => wa.applicationRank - wb.applicationRank).map(w => {
    return __cssprop("div", {
      key: w.applicationRank,
      css: {
        "display": "flex",
        "justifyContent": "space-between"
      }
    }, __cssprop("div", {
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "300",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, __cssprop(Link, {
      text: w.applicants.map(a => a.name).join(", "),
      action: {
        kind: "InternalLink",
        to: `/applicant/v2/${w.id}`
      }
    })), __cssprop("div", {
      "data-tip": t("applicant-ranking-tooltip"),
      css: {
        "whiteSpace": "nowrap",
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.2rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "300",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem"
        },
        "@media (min-width: 768px)": {
          "paddingLeft": "0px"
        }
      }
    }, readOnly ? __cssprop(FaArrowUp, {
      css: {
        "height": "1.6rem",
        "width": "1.6rem",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      },
      "aria-hidden": "true"
    }) : __cssprop(FaArrowUp, {
      css: {
        "height": "1.6rem",
        "width": "1.6rem",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
        "cursor": "pointer"
      },
      "aria-hidden": "true",
      onClick: () => handleOnChangeRanking(w.id)
    }), __cssprop(ReactTooltip, {
      backgroundColor: "#6366f1",
      type: "info"
    })));
  })));

  return __cssprop("div", {
    css: {
      "overflowY": "auto"
    }
  }, __cssprop("div", {
    css: {
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "paddingBottom": "0.4rem"
    }
  }, t("applicant-ranking-listing-header")), submittedToOwner.length > 0 && submittedSection, waitingList.length > 0 && waitingListSection, remainingApplicants.length > 0 && remainingListSection);
};
export default RankingList;