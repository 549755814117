function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { Menu, Transition } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { ChevronDownIcon, DotsVerticalIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { css } from "../../../../_snowpack/pkg/@emotion/css.js";
import { ProtectedBase } from "./ProtectedComponents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledMenuItem = _styled.a(({
  disabled,
  active,
  destructive
}) => [{
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "display": "flex",
  "alignItems": "center",
  "paddingLeft": "1.6rem",
  "paddingRight": "1.6rem",
  "paddingTop": "0.8rem",
  "paddingBottom": "0.8rem",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
  },
  "cursor": "pointer",
  "whiteSpace": "nowrap",
  "zIndex": "30"
}, active && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
}, destructive && {
  "--tw-text-opacity": "1",
  "color": "rgba(220, 38, 38, var(--tw-text-opacity))"
}, disabled && {
  "--tw-text-opacity": "1",
  "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
}]);

const StyledMenu = _styled(Menu)(({
  absolute
}) => [{
  "textAlign": "left"
}, absolute && {
  "position": "absolute"
}]);

const StyledMenuItems = _styled(Menu.Items)(({
  right,
  dots
}) => [{
  "zIndex": "30",
  "transformOrigin": "top right",
  "position": "absolute",
  "marginTop": "0.8rem",
  "borderRadius": "0.6rem",
  "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
  "--tw-ring-opacity": "0.05",
  "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-divide-y-reverse": 0,
    "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
    "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
    "--tw-divide-opacity": "1",
    "borderColor": "rgba(243, 244, 246, var(--tw-divide-opacity))"
  },
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  }
}, right && {
  "right": "0px"
}]);

const renderMenuButton = (content, dots, icon) => {
  if (icon) {
    return __cssprop("div", null, __cssprop(Menu.Button, {
      css: {
        "borderRadius": "9999px",
        "display": "flex",
        "alignItems": "center",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-offset-width": "2px",
          "--tw-ring-offset-color": "#f3f4f6",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
        }
      }
    }, __cssprop("span", {
      css: {
        "position": "absolute",
        "width": "1px",
        "height": "1px",
        "padding": "0",
        "margin": "-1px",
        "overflow": "hidden",
        "clip": "rect(0, 0, 0, 0)",
        "whiteSpace": "nowrap",
        "borderWidth": "0"
      }
    }, content), icon));
  }

  if (dots) {
    return __cssprop("div", null, __cssprop(Menu.Button, {
      css: {
        "borderRadius": "9999px",
        "display": "flex",
        "alignItems": "center",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-offset-width": "2px",
          "--tw-ring-offset-color": "#f3f4f6",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
        }
      }
    }, __cssprop("span", {
      css: {
        "position": "absolute",
        "width": "1px",
        "height": "1px",
        "padding": "0",
        "margin": "-1px",
        "overflow": "hidden",
        "clip": "rect(0, 0, 0, 0)",
        "whiteSpace": "nowrap",
        "borderWidth": "0"
      }
    }, content), __cssprop(DotsVerticalIcon, {
      css: {
        "height": "2rem",
        "width": "2rem"
      },
      "aria-hidden": "true"
    })));
  }

  return __cssprop("div", null, __cssprop(Menu.Button, {
    css: {
      "display": "inline-flex",
      "justifyContent": "center",
      "width": "100%",
      "borderRadius": "0.6rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-offset-color": "#f3f4f6",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, content, __cssprop(ChevronDownIcon, {
    css: {
      "marginRight": "-0.4rem",
      "marginLeft": "0.8rem",
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  })));
};

export const DropDownActions = ({
  actions,
  content,
  dots = false,
  right = false,
  absolute = false,
  icon = null,
  children
}) => {
  return __cssprop(StyledMenu, {
    as: "div",
    absolute: absolute
  }, ({
    open
  }) => __cssprop(React.Fragment, null, !children && renderMenuButton(content, dots, icon), children && __cssprop(Menu.Button, {
    css: {
      "borderRadius": "9999px",
      "display": "flex",
      "alignItems": "center",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-offset-color": "#f3f4f6",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, children), __cssprop("div", {
    css: {
      "display": "block"
    }
  }, __cssprop(Transition, {
    show: open,
    as: React.Fragment,
    enter: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
      "transitionDuration": "100ms"
    }),
    enterFrom: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": ".95",
      "--tw-scale-y": ".95",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0"
    }),
    enterTo: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1"
    }),
    leave: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "75ms"
    }),
    leaveFrom: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1"
    }),
    leaveTo: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": ".95",
      "--tw-scale-y": ".95",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0"
    })
  }, __cssprop(StyledMenuItems, {
    right: right,
    dots: dots
  }, actions.map((a, index) => {
    return __cssprop("div", {
      css: {
        "paddingTop": "0.4rem",
        "paddingBottom": "0.4rem",
        "overflow": "hidden"
      },
      key: index
    }, a.map((i, key) => {
      return __cssprop(ProtectedBase, {
        permission: i.permission,
        Container: Menu.Item,
        key: key
      }, ({
        active
      }) => __cssprop(StyledMenuItem, _extends({
        active: active
      }, i, {
        onClick: i?.onAction
      }), i.icon && __cssprop(i.icon, {
        css: {
          "marginRight": "1.2rem",
          "height": "2rem",
          "width": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
          ".group:hover &": {
            "--tw-text-opacity": "1",
            "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
          }
        },
        "aria-hidden": "true"
      }), __cssprop("div", null, i.content)));
    }));
  }))))));
};