function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const StyledButton = _styled.button(({
  primary,
  disabled,
  destructive,
  loading,
  plain,
  secondary,
  spaced,
  grouped
}) => [{
  "borderWidth": "1px",
  "borderColor": "transparent",
  "borderRadius": "0.6rem",
  "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "paddingTop": "0.8rem",
  "paddingBottom": "0.8rem",
  "paddingLeft": "1.6rem",
  "paddingRight": "1.6rem",
  "display": "inline-flex",
  "justifyContent": "center",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500"
}, {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
  "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
  }
}, plain && {
  "borderStyle": "none",
  "--tw-shadow": "0 0 #0000",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
  }
}, primary && {
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
  },
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
  }
}, secondary && {
  "--tw-text-opacity": "1",
  "color": "rgba(67, 56, 202, var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(224, 231, 255, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(199, 210, 254, var(--tw-bg-opacity))"
  }
}, destructive && !plain && {
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(239, 68, 68, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(220, 38, 38, var(--tw-bg-opacity))"
  },
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(239, 68, 68, var(--tw-ring-opacity))"
  }
}, destructive && plain && {
  "--tw-text-opacity": "1",
  "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
}, disabled && {
  "--tw-text-opacity": "1",
  "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(156, 163, 175, var(--tw-bg-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(156, 163, 175, var(--tw-bg-opacity))"
  },
  "cursor": "not-allowed",
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(107, 114, 128, var(--tw-ring-opacity))"
  }
}, loading && {
  "cursor": "not-allowed"
}, spaced && {
  "paddingLeft": "2rem",
  "paddingRight": "2rem",
  "paddingTop": "1.2rem",
  "paddingBottom": "1.2rem"
}, grouped && {
  "marginLeft": "0.8rem",
  "marginRight": "0.8rem"
}]);
export const AnimateLoadingSvg = () => {
  return __cssprop("svg", {
    css: {
      "animation": "spin 1s linear infinite",
      "marginLeft": "-0.4rem",
      "marginRight": "1.2rem",
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    },
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24"
  }, __cssprop("circle", {
    css: {
      "opacity": "0.25"
    },
    cx: "12",
    cy: "12",
    r: "10",
    stroke: "currentColor",
    strokeWidth: "4"
  }), __cssprop("path", {
    css: {
      "opacity": "0.75"
    },
    fill: "currentColor",
    d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
  }));
};
export const Button = props => {
  const {
    children,
    onAction,
    loading,
    disabled,
    url,
    submit
  } = props;

  if (url) {
    return __cssprop("a", {
      href: url
    }, __cssprop(StyledButton, _extends({}, props, {
      type: "button",
      disabled: disabled || loading
    }), loading && __cssprop(AnimateLoadingSvg, null), children));
  }

  if (submit) {
    return __cssprop(StyledButton, _extends({}, props, {
      type: "submit",
      disabled: disabled || loading
    }), loading && __cssprop(AnimateLoadingSvg, null), children);
  }

  return __cssprop(StyledButton, _extends({}, props, {
    onClick: () => {
      if (disabled || loading) {
        return;
      }

      onAction();
    },
    type: "button",
    disabled: disabled || loading
  }), loading && __cssprop(AnimateLoadingSvg, null), children);
};