import React, { useState, useCallback } from "../../../../../_snowpack/pkg/react.js";
import { useApolloClient } from "../../../../../_snowpack/pkg/@apollo/client.js";
import AsyncSelect from "../../../../../_snowpack/pkg/react-select/async.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const mapValues = (values, label) => {
  if (!values && values.length === 0) {
    return null;
  }

  if (values[0] && values[0].label && values[0].value) {
    return values[0];
  }

  return values.map(b => {
    let labelString; // eslint-disable-next-line no-underscore-dangle

    if (b.__typename === "UnitGQL") labelString = `${b.subAddress}-${b.buildingAddress}`; // eslint-disable-next-line no-underscore-dangle
    else if (b.__typename === "BuildingGQL") labelString = `${b.civicNumber} ${b.streetName}`;else labelString = b[label];
    return {
      value: b.id,
      label: labelString
    };
  });
};

export const SimpleSelector = ({
  labelPattern,
  name,
  errors,
  error,
  disabled = false,
  defaultValue = null,
  onChange,
  query,
  label = "Select",
  isClearable = true,
  extraValues = [],
  isMulti = false,
  additionalFilterCallback = val => {
    return val;
  },
  extraQueryVariable = {}
}) => {
  const client = useApolloClient();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const promiseOptions = useCallback(async inputValue => {
    try {
      const q = await client.query({
        query,
        variables: {
          take: 25,
          query: inputValue,
          skip: 0,
          ...extraQueryVariable
        },
        fetchPolicy: "no-cache"
      });
      return name.reduce((acc, n) => {
        const data = q.data[`${n}s`];

        if (!data) {
          return acc;
        }

        const {
          items
        } = data;
        const mappedValues = mapValues(items.filter(additionalFilterCallback), labelPattern);
        return [...acc, ...mappedValues];
      }, []);
    } catch (e) {
      console.error("oops", e);
      return [];
    }
  }, [client]);
  const onValueChange = useCallback(values => {
    // Deposit page values are not an array so we need to check and cope.
    setSelectedOptions(Array.isArray(values) ? [...values] : [values]);

    if (onChange) {
      onChange(values || {
        label: null,
        value: null
      });
    }
  }, [onChange, selectedOptions, isMulti]);
  return __cssprop(AsyncSelect, {
    style: {
      width: "100%"
    },
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: errors ? "rgb(222, 53, 11)" : "hsl(0,0%,80%)"
      })
    },
    isClearable: isClearable,
    isMulti: isMulti,
    placeholder: label,
    defaultValue: defaultValue ? mapValues([defaultValue], labelPattern) : null,
    onChange: onValueChange,
    loadOptions: promiseOptions,
    menuContainerStyle: {
      zIndex: 25
    },
    isDisabled: disabled,
    error: error
  });
};