/* eslint-disable import/extensions */

/* eslint-disable no-underscore-dangle */
import reducers from "./reducers.js";
import sagas from "./sagas.js";
import selectors from "./selectors.js";
export default {
  sagas: sagas.executers,
  reducer: reducers,
  selectors
};