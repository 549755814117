import { TextField } from "../../../components/Tailwind/TextField.js";
import useI18n from "../../../hooks/useI18n.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { Controller, useFormContext } from "../../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const StringField = ({
  title,
  name
}) => {
  const {
    t
  } = useI18n();
  const {
    control
  } = useFormContext();
  return __cssprop(Controller, {
    name: name,
    control: control,
    render: ({
      field
    }) => __cssprop(TextField, {
      id: name,
      onChange: field.onChange,
      defaultValue: field.value,
      placeholder: title
    })
  });
};