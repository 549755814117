import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { ChevronRightIcon, SearchIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import React, { Children } from "../../../../_snowpack/pkg/react.js";
import { Loader } from "../Loader.js";
import { Layout } from "./Layout.js";
import { TextField } from "./TextField.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

function defaultIdForItem(item, index) {
  return Object.prototype.hasOwnProperty.call(item, "id") ? item.id : index.toString();
}

const ResourceListRendererContainer = _styled.div(({
  noShadow
}) => [{
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "overflow": "hidden",
  "@media (min-width: 640px)": {
    "borderRadius": "0.6rem"
  }
}, !noShadow && {
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
}]);

const StyledA = _styled.a(({
  notClickable
}) => [{
  "display": "block"
}, !notClickable && {
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
  },
  "cursor": "pointer"
}]);

const ResourceListRenderer = ({
  items,
  loading,
  filterControl,
  footer,
  emptyState,
  renderItem,
  idForItem = defaultIdForItem,
  noShadow
}) => {
  const itemsExist = items.length > 0;
  const showEmptyState = emptyState && !itemsExist && !loading;

  const renderItemWithId = (item, index) => {
    const id = idForItem(item, index);
    const itemContent = renderItem(item, id, index);
    return itemContent;
  };

  const loadingOverlay = loading ? __cssprop("div", {
    css: {
      "position": "absolute",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "zIndex": "10",
      "height": "100%",
      "width": "100%",
      "opacity": "0.6"
    }
  }, __cssprop(Loader, null)) : null;
  const loadingWithoutItemsMarkup = !itemsExist && loading ? __cssprop(Loader, null) : null;
  const listMarkup = itemsExist ? __cssprop("ul", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      },
      "position": "relative"
    },
    id: "resourceList",
    "aria-live": "polite",
    "aria-busy": loading
  }, loadingOverlay, Children.toArray(items.map(renderItemWithId))) : null;
  return __cssprop(ResourceListRendererContainer, {
    noShadow: noShadow
  }, filterControl, listMarkup, showEmptyState && emptyState, loadingWithoutItemsMarkup, footer);
};

const FilterControl = ({
  onClearAll,
  onQueryChange,
  onQueryClear,
  queryValue,
  children,
  placeholder
}) => {
  if (!children) {
    return __cssprop("div", {
      css: {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem",
        "paddingTop": "2rem",
        "paddingBottom": "2rem",
        "borderBottomWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
        "@media (min-width: 640px)": {
          "paddingLeft": "2.4rem",
          "paddingRight": "2.4rem"
        }
      }
    }, __cssprop(TextField, {
      onChange: onQueryChange,
      value: queryValue,
      id: "search",
      icon: SearchIcon,
      placeholder: placeholder
    }));
  }

  return __cssprop("form", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "2rem",
      "paddingBottom": "2rem",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop(TextField, {
    onChange: onQueryChange,
    value: queryValue,
    id: "search",
    placeholder: placeholder,
    icon: SearchIcon
  })), __cssprop(Layout, {
    oneThird: true
  }, children)));
};

export const ResourceListItem = ({
  children,
  backgroundImage,
  media,
  id,
  accessibilityLabel,
  onClick,
  notClickable
}) => {
  return __cssprop("li", {
    css: {
      "position": "relative",
      "overflow": "hidden"
    },
    key: id
  }, __cssprop(StyledA, {
    onClick: onClick,
    "aria-hidden": true,
    className: "group",
    notClickable: notClickable
  }, backgroundImage && __cssprop(React.Fragment, null, __cssprop("img", {
    css: {
      "width": "50%",
      "height": "100%",
      "opacity": "0.2",
      "right": "0px",
      "position": "absolute",
      "borderTopLeftRadius": "0.6rem",
      "borderBottomLeftRadius": "0.6rem",
      "objectFit": "cover",
      ".group:hover &": {
        "opacity": "0.4"
      }
    },
    loading: "lazy",
    alt: "",
    src: backgroundImage
  }), __cssprop("div", {
    css: {
      "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
      "--tw-gradient-from": "#fff",
      "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0))",
      "width": "50%",
      "height": "100%",
      "right": "0px",
      "position": "absolute",
      ".group:hover &": {
        "--tw-gradient-from": "#f9fafb",
        "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0))"
      }
    }
  })), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem",
      "position": "relative",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    },
    id: id
  }, __cssprop("div", {
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%",
      "display": "flex",
      "alignItems": "center"
    }
  }, media && __cssprop("div", {
    css: {
      "flexShrink": "0"
    }
  }, media), __cssprop("div", {
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 768px)": {
        "display": "grid",
        "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
        "gap": "1.6rem"
      }
    }
  }, children)), __cssprop("div", null, __cssprop(ChevronRightIcon, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })))));
};

const StyledSecondaryContainer = _styled.div(({
  spaced
}) => [{
  "display": "none",
  "flexDirection": "column",
  "justifyContent": "center",
  "@media (min-width: 768px)": {
    "display": "flex"
  }
}, spaced && {
  "alignItems": "flex-end"
}]);

export const DualColumnItem = ({
  primary,
  secondary
}) => {
  return __cssprop(React.Fragment, null, __cssprop("div", null, __cssprop("div", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, primary.heading), primary.content && __cssprop("div", {
    css: {
      "marginTop": "0.8rem",
      "display": "flex",
      "alignItems": "center",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, __cssprop("span", {
    css: {
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, primary.content))), __cssprop(StyledSecondaryContainer, {
    spaced: true
  }, __cssprop("div", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, secondary.heading), secondary.content && __cssprop("div", {
    css: {
      "marginTop": "0.8rem",
      "display": "flex",
      "alignItems": "center",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, secondary.content)));
};
export const ResourceList = function Card(props) {
  return __cssprop(ResourceListRenderer, props);
};
ResourceList.Item = ResourceListItem;
ResourceList.FilterControl = FilterControl;