import _styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import { BadgeWithRemove } from "../../../components/Tailwind/Badge.js";
import { TextField } from "../../../components/Tailwind/TextField.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { TicketActionTypes } from "../../../store/artifacts/Tickets/types.js";
import React, { useState } from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "paddingTop": "2rem"
  }
}]);

export const TagsSelection = ({
  items,
  handleAdd,
  handleRemove,
  inForm
}) => {
  const {
    t
  } = useI18n();
  const [inputTag, setInputTag] = useState("");
  let tags = useSelector(Selectors.ticket.getTags);
  const id = useSelector(Selectors.ticket.getId);
  const {
    dispatch
  } = useAction();

  if (items?.length > 0) {
    tags = items;
  }

  const handleAddTag = () => {
    setInputTag("");

    if (handleAdd) {
      handleAdd(inputTag);
    } else {
      dispatch({
        type: TicketActionTypes.TICKET_SET_FIELD,
        payload: {
          tags: [...tags, inputTag]
        }
      });

      if (!inForm && id) {
        dispatch({
          type: TicketActionTypes.TICKET_UPDATE,
          payload: {
            tags: [...tags, inputTag]
          }
        });
      }
    }

    return null;
  };

  const handleDeleteTag = tagId => {
    const newTags = [...tags];
    newTags.splice(newTags.indexOf(newTags.find(tagItem => tagItem === tagId)), 1);

    if (handleRemove) {
      return handleRemove(newTags);
    }

    dispatch({
      type: TicketActionTypes.TICKET_SET_FIELD,
      payload: {
        tags: newTags
      }
    });

    if (inForm) {
      return () => {
        /* such empty */
      };
    }

    return dispatch({
      type: TicketActionTypes.TICKET_UPDATE,
      payload: {
        tags: newTags
      }
    });
  };

  return __cssprop("div", {
    css: {
      "@media (min-width: 768px)": {
        "display": "grid",
        "gridTemplateColumns": "repeat(3, minmax(0, 1fr))",
        "gap": "0.8rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 768px)": {
        "gridColumn": "span 3 / span 3"
      }
    }
  }, __cssprop("div", {
    css: {
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "0px",
        "paddingRight": "0px"
      }
    }
  }, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("tags-section-title")))), __cssprop("div", {
    css: {
      "marginTop": "2rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 768px)": {
        "gridColumn": "span 3 / span 3",
        "marginTop": "0px"
      }
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "display": "grid",
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1.6rem",
        "alignItems": "flex-start"
      }
    }
  }, __cssprop(TextField, {
    id: "tags",
    label: "",
    onChange: setInputTag,
    value: inputTag,
    placeholder: t("tags-section-placeholder"),
    error: undefined,
    withButton: __cssprop("button", {
      onClick: handleAddTag,
      type: "button",
      css: {
        "marginLeft": "-1px",
        "position": "relative",
        "display": "inline-flex",
        "alignItems": "center",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "marginTop": "0.4rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem",
        "borderWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "borderTopRightRadius": "0.6rem",
        "borderBottomRightRadius": "0.6rem",
        "--tw-text-opacity": "1",
        "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
          "--tw-border-opacity": "1",
          "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
        }
      }
    }, __cssprop("span", null, t("add")))
  })), tags && tags.length > 0 && __cssprop(StyledItems, null, __cssprop("div", null, tags.map(tag => {
    return __cssprop(BadgeWithRemove, {
      key: tag,
      onDelete: handleDeleteTag,
      id: tag,
      css: {
        "margin": "0.4rem"
      },
      primary: true
    }, tag);
  })))));
};