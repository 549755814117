import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const List = ({
  title,
  children
}) => {
  return __cssprop("div", {
    css: {
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, children);
};

const StyledColumnItem = _styled.div(({
  span
}) => [span === "half" && {
  "@media (min-width: 640px)": {
    "gridColumn": "span 1 / span 1"
  }
}, span === "full" && {
  "@media (min-width: 640px)": {
    "gridColumn": "span 2 / span 2"
  }
}]);

const StyledItem = _styled.div(({
  spacing
}) => [spacing === "tight" && {
  "paddingTop": "0.4rem",
  "paddingBottom": "0.4rem"
}, spacing === "loose" && {
  "paddingTop": "1.2rem",
  "paddingBottom": "1.2rem"
}]);

const StyledInlineItem = _styled.div(({
  withPadding
}) => [withPadding && {
  "paddingLeft": "2.4rem",
  "paddingRight": "2.4rem",
  "paddingTop": "1.2rem",
  "paddingBottom": "1.2rem",
  "display": "flex",
  "justifyContent": "space-between",
  "alignItems": "center"
}, {
  "paddingTop": "1.2rem",
  "paddingBottom": "1.2rem",
  "display": "flex",
  "justifyContent": "space-between",
  "alignItems": "center"
}]);

const ColumnItem = ({
  half,
  title,
  children,
  spacing = "loose",
  inline = false,
  withPadding = false
}) => __cssprop(StyledColumnItem, {
  span: half ? "half" : "full"
}, inline ? __cssprop(StyledInlineItem, {
  withPadding: withPadding
}, title && __cssprop("dt", {
  css: {
    "fontSize": "1.4rem",
    "lineHeight": "2rem",
    "--tw-text-opacity": "1",
    "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
  }
}, title), __cssprop("dd", {
  css: {
    "fontSize": "1.4rem",
    "lineHeight": "2rem",
    "--tw-text-opacity": "1",
    "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
    "width": "max-content"
  }
}, children)) : __cssprop(StyledItem, {
  spacing: spacing
}, __cssprop("dt", {
  css: {
    "fontSize": "1.4rem",
    "lineHeight": "2rem",
    "fontWeight": "500",
    "--tw-text-opacity": "1",
    "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
  }
}, title), __cssprop("dd", {
  css: {
    "marginTop": "0.4rem",
    "fontSize": "1.4rem",
    "lineHeight": "2rem",
    "--tw-text-opacity": "1",
    "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
  }
}, children)));

const TwoColumnList = ({
  children
}) => {
  return __cssprop("dl", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
      "columnGap": "1.6rem",
      "rowGap": "1.6rem",
      "@media (min-width: 640px)": {
        "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
      }
    }
  }, children);
};

export const DescriptionList = function DescriptionList(props) {
  return __cssprop(List, props);
};
DescriptionList.TwoColumn = TwoColumnList;
DescriptionList.Item = ColumnItem;