import React, { useEffect, useState } from "../../../../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../../../../_snowpack/pkg/react-router.js";
import { StyledButton } from "../../../../components/Tailwind/Button.js";
import { Card } from "../../../../components/Tailwind/Card.js";
import { useAction, useSelector } from "../../../../hooks/useRedux.js";
import { Selectors } from "../../../../store/index.js";
import { EventActionTypes } from "../../../../store/artifacts/Events/types.js";
import { prioritiesBadges } from "../../../../components/TicketInfo/TicketInfoSidebar/TicketInfoSidebarGeneral.js";
import { Badge } from "../../../../components/Tailwind/Badge.js";
import { client } from "../../../../apollo/index.js";
import { Link } from "../../../../components/Tailwind/Link.js";
import { getResource, queriesObject } from "../../../../utils/event.js";
import { ProviderActionTypes } from "../../../../store/artifacts/Provider/types.js";
import { ApplicationActionsTypes } from "../../../../store/artifacts/Application/types.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const EventSideEntitySummary = () => {
  const entityMetadata = useSelector(Selectors.event.getEntityMetadata);
  const [resource, setResource] = useState();
  const {
    dispatch
  } = useAction();
  const history = useHistory();

  const handleRemoveTicket = () => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        entityMetadata: null
      }
    });
  };

  const handleResourceClick = () => {
    if (resource.type === "provider") {
      dispatch({
        type: ProviderActionTypes.PROVIDER_SET_FIELD,
        payload: { ...resource.data
        }
      });
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: "provider"
      });
    } else {
      history.push(`/${queriesObject[resource.type].link}/${resource.data.id}`);
    }
  };

  useEffect(() => {
    const [uniqueResourceKey, uniqueResourceValue] = Object.entries(Object.fromEntries(Object.entries(entityMetadata.resource).filter(([key, value]) => value !== null)))[0];
    const resourceQuery = queriesObject[uniqueResourceKey]?.query;
    const query = resourceQuery ? client.query({
      query: resourceQuery,
      fetchPolicy: "no-cache",
      variables: {
        id: uniqueResourceValue
      }
    }) : null;
    query?.then(data => {
      if (uniqueResourceKey === "userId" && !data.data) {
        const providerQuery = client.query({
          query: queriesObject.provider.query,
          fetchPolicy: "no-cache",
          variables: {
            skip: 0,
            take: 1000,
            query: ""
          }
        });
        providerQuery?.then(providers => {
          const provider = providers.data.providers.items.find(prov => prov.id === uniqueResourceValue);
          return setResource({
            data: provider,
            type: "provider"
          });
        });
      }

      return setResource({
        data: data.data[queriesObject[uniqueResourceKey].name],
        type: uniqueResourceKey
      });
    });
  }, [entityMetadata]);
  return __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop("h2", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.8rem",
      "marginBottom": "2rem"
    }
  }, "Entity information"), __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "2rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column"
    }
  }, __cssprop("h3", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "marginBottom": "0.4rem"
    }
  }, "Title"), __cssprop("p", null, entityMetadata?.title)), __cssprop("div", null, __cssprop("h3", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "marginBottom": "0.4rem"
    }
  }, "Category"), __cssprop(Badge, {
    primary: true
  }, entityMetadata?.category)), __cssprop("div", null, __cssprop("h3", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "marginBottom": "0.4rem"
    }
  }, "Priority"), prioritiesBadges[entityMetadata?.priority]), resource?.data && __cssprop("div", null, __cssprop("h3", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "marginBottom": "0.4rem"
    }
  }, "Resource"), __cssprop(Link, {
    action: {
      kind: "ClickAction",
      event: handleResourceClick
    },
    text: getResource(resource?.data, resource?.type)
  })), __cssprop(StyledButton, {
    onClick: handleRemoveTicket,
    primary: true
  }, "Remove Entity"))));
};

export default EventSideEntitySummary;