import _styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import { BadgeWithRemove } from "../../../../components/Tailwind/Badge.js";
import { Card } from "../../../../components/Tailwind/Card.js";
import { DescriptionLayoutItem } from "../../../../components/Tailwind/Layout.js";
import { TextField } from "../../../../components/Tailwind/TextField.js";
import useI18n from "../../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../../store/artifacts/Building/types.js";
import React, { useState } from "../../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "paddingTop": "2rem"
  }
}]);

export const TagsSection = () => {
  const {
    t
  } = useI18n();
  const [inputTag, setInputTag] = useState("");
  const tags = useSelector(Selectors.building.getTags);
  const {
    dispatch
  } = useAction();
  return __cssprop(DescriptionLayoutItem, {
    title: t("tags-section-title"),
    description: t("tags-section-description")
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "display": "grid",
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1.6rem",
        "alignItems": "flex-start"
      }
    }
  }, __cssprop(TextField, {
    id: "tags",
    label: "",
    onChange: setInputTag,
    value: inputTag,
    placeholder: t("tags-section-placeholder"),
    error: undefined,
    withButton: __cssprop("button", {
      onClick: () => {
        dispatch({
          type: BuildingActionsTypes.BUILDING_ADD_TAGS,
          payload: inputTag
        });
        setInputTag("");
      },
      type: "button",
      css: {
        "marginLeft": "-1px",
        "position": "relative",
        "display": "inline-flex",
        "alignItems": "center",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem",
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "borderWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "borderTopRightRadius": "0.6rem",
        "borderBottomRightRadius": "0.6rem",
        "--tw-text-opacity": "1",
        "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
        },
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px",
          "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
          "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
          "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
          "--tw-border-opacity": "1",
          "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
        }
      }
    }, __cssprop("span", null, t("add")))
  })), __cssprop(StyledItems, null, __cssprop("div", null, tags.map(tag => {
    return __cssprop(BadgeWithRemove, {
      key: tag,
      onDelete: tagId => {
        dispatch({
          type: BuildingActionsTypes.BUILDING_REMOVE_TAGS,
          payload: tagId
        });
      },
      id: tag,
      css: {
        "margin": "0.4rem"
      },
      primary: true
    }, tag);
  }))))));
};