import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import React, { useCallback } from "../../../../_snowpack/pkg/react.js";
import { Provider } from "./context.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const BreadcrumbsProvider = props => {
  const {
    children
  } = props;
  const crumbs = useSelector(Selectors.app.getCrumbs);
  const {
    dispatch
  } = useAction();
  const addCrumb = useCallback(crumb => {
    // use function version of set state
    // to get accurate tracking between state
    dispatch({
      type: ApplicationActionsTypes.ADD_CRUMBS,
      payload: crumb
    });
  }, []);
  const findCrumbIndex = useCallback((crumb, arr) => {
    return arr?.findIndex(c => c.title === crumb.title || c.path === crumb.path);
  }, []);
  const removeCrumbs = useCallback(index => {
    dispatch({
      type: ApplicationActionsTypes.REMOVE_CRUMBS,
      payload: index
    });
  }, []);
  const context = {
    crumbs,
    addCrumb,
    findCrumbIndex,
    removeCrumbs
  };
  return __cssprop(Provider, {
    value: context
  }, children);
};

export { BreadcrumbsProvider };