import { useQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { Loader } from "../../../components/Loader.js";
import { ListingPanel } from "../../../components/Panels/ListingPanel.js";
import { Badge } from "../../../components/Tailwind/Badge.js";
import { DropDownActions } from "../../../components/Tailwind/DropDownActions.js";
import { Link } from "../../../components/Tailwind/Link.js";
import { PaginatedDataTable } from "../../../components/Tailwind/PaginatedDataTable.js";
import { ProtectedActions, ProtectedFunction } from "../../../components/Tailwind/ProtectedComponents.js";
import { withBreadcrumb } from "../../../context/Breadcrumbs/withBreadcrumbs.js";
import { FileCategory, GetListingsDocument, GetUnitRentalApplicantsDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../store/artifacts/Building/types.js";
import { ListingActionsTypes } from "../../../store/artifacts/Listing/types.js";
import { getBedroomsSize, isRentableUnitGQL, UNIT_BEDROOMS_SELECT_OPTIONS, UNIT_FLOORS, UNIT_FLOORS_ENUM, UNIT_FLOOR_SELECT_OPTIONS } from "../../../utils/unit.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useRef, useState } from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const renderAddress = (dispatch, history, item, role) => {
  let action;

  if (isRentableUnitGQL(item)) {
    action = {
      kind: "ClickAction",
      event: ProtectedFunction(role, () => {
        dispatch({
          type: BuildingActionsTypes.BUILDING_INITIALIZE,
          payload: {
            buildingId: item.id,
            kind: "WithBuildingId"
          }
        });
        history.push(`/buildings/${item.id}`);
      }, {
        kind: "Unit",
        permission: "view:info"
      })
    };
    return __cssprop(Link, {
      action: action,
      text: `${item?.civicNumber} ${item?.streetName}`
    });
  }

  action = {
    kind: "ClickAction",
    event: ProtectedFunction(role, () => {
      history.push(`/unit/${item.id}`);
    }, {
      kind: "Unit",
      permission: "view:info"
    })
  };
  return __cssprop(Link, {
    action: action,
    text: `${item?.subAddress ? `${item.subAddress}-` : ""}${item.building?.civicNumber} ${item.building?.streetName}`
  });
};

const getActions = (dispatch, item, role, refetch, t, listingEditAction) => {
  const actions = [ProtectedActions(role, [{
    content: "View on halfred.ca",
    destructive: false,
    onAction: () => {
      window.open(`https://www.halfred.ca/${item.slug}`);
    },
    permission: {
      kind: "ApplicantRequest",
      permission: "view:listingWebsite"
    }
  }, {
    content: t("rental-remove-ad"),
    destructive: false,
    onAction: () => {
      dispatch({
        type: ListingActionsTypes.LISTING_PUBLISH,
        payload: {
          id: item.id,
          status: false
        }
      });

      if (refetch) {
        refetch();
      }
    },
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:update"
    }
  }, {
    content: t("rental-form-modify-ad"),
    destructive: false,
    onAction: listingEditAction,
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:update"
    }
  }])];
  return __cssprop(DropDownActions, {
    content: "open",
    dots: true,
    actions: actions,
    absolute: true
  });
};

const displayApplicationStep = rentalApp => {
  const lease = rentalApp?.files?.items.find(file => file.fileCategory === FileCategory.Lease);
  const items = [rentalApp.propertyManagerApprovedAt, rentalApp.visitAgentApprovedAt, rentalApp.landlordApprovedAt, lease];
  return `(${items.filter(Boolean).length}/${items.length})`;
};

const displayTenant = tenants => {
  const tenantString = tenants.map(tenant => {
    return tenant.name;
  });
  return tenantString.toString().replace(/,(?=[^\s])/g, ", ");
};

const ApplicantsDropDown = ({
  unit,
  history
}) => {
  const {
    loading,
    data
  } = useQuery(GetUnitRentalApplicantsDocument, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      id: unit
    }
  });

  if (loading) {
    return __cssprop(Loader, {
      size: 50
    });
  }

  const {
    rentalRequestsV2
  } = data.unit;

  if (!rentalRequestsV2 || rentalRequestsV2?.items?.length === 0) {
    return __cssprop("div", null, "0");
  }

  const requests = rentalRequestsV2?.items;
  const filtered = requests.filter(a => a.rejected === null);

  if (!filtered || filtered.length === 0) {
    return __cssprop("div", null, "0 / ", requests.length);
  }

  return __cssprop("div", {
    css: {
      "width": "100%",
      "maxWidth": "44.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderRadius": "1.6rem"
    }
  }, __cssprop(DropDownActions, {
    right: true,
    content: `${filtered.length} / ${requests.length}`,
    actions: [filtered.sort((a, b) => {
      if (a.applicationRank < b.applicationRank) return -1;
      if (a.applicationRank > b.applicationRank) return 1;
      return 0;
    }).map(a => {
      return {
        content: __cssprop("div", null, a.applicationRank, " - ", __cssprop("span", {
          css: {
            "--tw-text-opacity": "1",
            "color": "rgba(79, 70, 229, var(--tw-text-opacity))"
          }
        }, displayTenant(a.applicants)), " ", displayApplicationStep(a), " (", DateTime.fromISO(a.createdAt).toUTC().toFormat("MMMM dd, yyyy"), ")"),
        onAction: () => history.push(`/applicant/v2/${a.id}`),
        permission: {
          kind: "ApplicantRequest",
          permission: "view:info"
        }
      };
    })]
  }));
};

export const ListingsList = withBreadcrumb(({
  history
}) => {
  const {
    t
  } = useI18n();
  const dataTableRef = useRef();
  const {
    dispatch
  } = useAction();
  const role = useSelector(Selectors.session.getUserRole);
  const [filters, setFilters] = useState({
    bedrooms: [],
    floor: []
  });
  const [isListingPanelOpen, setIsListingPanelOpen] = useState(false);
  const [listingItemId, setListingItemId] = useState(null);

  const renderBody = item => {
    return {
      buildings: __cssprop("div", {
        css: {
          "width": "100%",
          "display": "flex",
          "justifyContent": "space-between"
        }
      }, renderAddress(dispatch, history, item.unit, role), item.isUrgent && __cssprop(Badge, {
        critical: true
      }, t("urgent"))),
      "building-form-sector": item?.unit?.building?.sector,
      price: `${item.price}$`,
      floor: `${UNIT_FLOORS(t)[UNIT_FLOORS_ENUM[item.unit.floor]]}`,
      availability: __cssprop("div", {
        css: {
          "display": "flex",
          "flexDirection": "column"
        }
      }, __cssprop("div", {
        css: {
          "display": "flex",
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-x-reverse": 0,
            "marginRight": "calc(0.4rem * var(--tw-space-x-reverse))",
            "marginLeft": "calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))"
          }
        }
      }, __cssprop("div", null, DateTime.fromISO(item.availability).toLocaleString(DateTime.DATE_MED)), __cssprop("div", null, "(", DateTime.fromISO(item.availability).toRelative(), ")")), item.unit?.currentLease?.endDate && __cssprop(Badge, null, t("widget-table-row-lease-end-on", {
        date: DateTime.fromISO(item.unit.currentLease.endDate).toLocaleString(DateTime.DATE_MED)
      }))),
      bedrooms: getBedroomsSize(item.unit.bedrooms),
      applicants: __cssprop(ApplicantsDropDown, {
        unit: item.unit.id,
        history: history
      }),
      actions: getActions(dispatch, item, role, dataTableRef?.current?.refetch, t, () => {
        setListingItemId(item.id);
        setIsListingPanelOpen(true);
      })
    };
  };

  const columns = [{
    Header: t("buildings"),
    accessor: "buildings",
    disableSortBy: true
  }, {
    Header: t("building-form-sector"),
    accessor: "building-form-sector",
    disableSortBy: true
  }, {
    Header: t("rental-detail-monthly-price"),
    accessor: "price"
  }, {
    Header: t("floor"),
    accessor: "floor"
  }, {
    Header: t("room"),
    accessor: "bedrooms"
  }, {
    Header: t("listings-available-on"),
    accessor: "availability"
  }, {
    Header: t("applicants"),
    accessor: "applicants",
    disableSortBy: true
  }, {
    Header: "Actions",
    accessor: "actions",
    disableSortBy: true
  }]; // return <DataList headings={headings} title={t("listings-heading-list")} rows={rows} />;

  const filterItems = [{
    placeholder: t("room-number"),
    options: UNIT_BEDROOMS_SELECT_OPTIONS,
    name: "bedrooms"
  }, {
    placeholder: t("floor"),
    options: UNIT_FLOOR_SELECT_OPTIONS(t),
    name: "floor"
  }];
  return __cssprop(React.Fragment, null, __cssprop(ListingPanel, {
    handleOnClose: () => setIsListingPanelOpen(false),
    isOpen: isListingPanelOpen,
    refetch: () => {
      setIsListingPanelOpen(false);
    },
    listingId: listingItemId
  }), __cssprop(PaginatedDataTable, {
    ref: dataTableRef,
    title: t("listings-heading-list"),
    query: GetListingsDocument,
    headers: columns,
    renderItem: renderBody,
    resourceName: {
      plural: t("listings-plurial"),
      singular: t("listings-singular")
    },
    filters: filterItems,
    extraVariables: filters,
    defaultSorting: {
      availability: "Asc"
    },
    numberOfFilters: filters.bedrooms.length + filters.floor.length,
    onFilterChange: (kind, change) => {
      setFilters(f => ({ ...f,
        [kind]: change.map(({
          value
        }) => value)
      }));
    }
  }));
});
export default ListingsList;