function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../../_snowpack/pkg/react.js";
import { textSm } from "../tailwind.temp.fix.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const StyledCheckbox = props => {
  return __cssprop("input", _extends({
    type: "checkbox"
  }, props, {
    css: [{
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      },
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.4rem"
    }]
  }));
};
export const StyledCheckboxContainer = _styled.div(() => [{
  "display": "flex",
  "alignItems": "center"
}]);
export const StyledCheckboxWrapper = _styled.div(() => [{
  "display": "flex",
  "alignItems": "center",
  "height": "2.4rem"
}]);
export const StyledLabelWrapper = _styled.div(() => [{
  "marginLeft": "1.2rem"
}]);
export const StyledLabel = _styled.label(() => [{
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
}, textSm]);