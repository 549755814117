/* eslint-disable no-underscore-dangle */
import { TYPES } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { Selectors } from "../../index.js";
import { all, getContext, put, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { AddBuildingDocument, GetBuildingDocument, UpdateBuildingDocument } from "../../../graphql/documents.js";
import { ApplicationActionsTypes } from "../Application/types.js";
import { addFilesHandler, uploadDocuments } from "../Files/sagas.js";
import { ListingActionsTypes } from "../Listing/types.js";
import { addServerNotes, removeServerNotes } from "../Notes/sagas.js";
import StatusActions from "../Status/actions.js";
import { UnitActionsTypes } from "../Unit/types.js";
import { fromGetBuilding, toBuildingInputMapper } from "./mapper.js";
import { BuildingActionsTypes } from "./types.js";

function* updateExistingBuilding(id, files) {
  const client = yield getContext("client");
  const history = yield getContext("history");
  const building = yield select(Selectors.building.getBuilding);
  yield put(StatusActions.setResourceToSubmit("building"));

  try {
    const query = client.query({
      query: UpdateBuildingDocument,
      fetchPolicy: "no-cache",
      variables: {
        building: {
          id,
          building: toBuildingInputMapper(building)
        }
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("building", "building-form-add-building-fail"));
      return;
    }

    yield addFilesHandler(files, data.updateBuilding.id, RESOURCES.Building);
    yield addServerNotes({
      resourceId: data.updateBuilding.id,
      resourceType: "building",
      notes: building.notes
    });
    yield removeServerNotes({
      notes: building.notes,
      resourceType: "building"
    });
    yield put(StatusActions.setResourceToSuccess("building", "building-form-add-building-success"));
    yield put({
      type: BuildingActionsTypes.BUILDING_INITIALIZE,
      payload: {
        kind: "WithBuildingId",
        buildingId: data.updateBuilding.id
      }
    });
    history.push(`buildings/${building.id}`);
  } catch (e) {
    yield put(StatusActions.setResourceToError("building", "building-form-add-building-fail"));
  }
}

function* createBuilding(files) {
  const client = yield getContext("client");
  const history = yield getContext("history");
  const building = yield select(Selectors.building.getBuilding);
  yield put(StatusActions.setResourceToSubmit("building"));

  try {
    const query = client.query({
      query: AddBuildingDocument,
      fetchPolicy: "no-cache",
      variables: {
        building: toBuildingInputMapper(building),
        projectId: building.project.id
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("building", "building-form-add-building-fail"));
      return;
    }

    yield addFilesHandler(files, data.addBuilding.id, RESOURCES.Building);
    yield addServerNotes({
      resourceId: data.addBuilding.id,
      resourceType: "building",
      notes: building.notes
    });
    yield put(StatusActions.setResourceToSuccess("building", "building-form-add-building-success"));

    if (building.unit) {
      yield put({
        type: UnitActionsTypes.UNIT_CREATE,
        payload: {
          unit: { ...building.unit,
            buildingId: data.addBuilding.id
          },
          files: []
        }
      });
    }

    yield put({
      type: BuildingActionsTypes.BUILDING_INITIALIZE,
      payload: {
        kind: "WithBuildingId",
        buildingId: data.addBuilding.id
      }
    });
    history.push(`buildings/${data.addBuilding.id}`);
  } catch (e) {
    yield put(StatusActions.setResourceToError("building", "building-form-add-building-fail"));
  }
}

function* onSubmitForm() {
  const building = yield select(Selectors.building.getBuilding);
  const files = yield uploadDocuments(building.files || [], RESOURCES.Building, TYPES.Files);

  if (building.id) {
    yield updateExistingBuilding(building.id, files);
  } else {
    yield createBuilding(files);
  }
}

function* onInitializeBuilding($data) {
  const client = yield getContext("client");
  const {
    payload
  } = $data;
  yield put({
    type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
    payload: null
  });

  switch (payload.kind) {
    // case "WithContext":
    // State is already changed from reducer.
    case "WithBuildingId":
      {
        yield put(StatusActions.setResourceToLoading("building"));
        const query = client.query({
          query: GetBuildingDocument,
          fetchPolicy: "no-cache",
          variables: {
            id: payload.buildingId
          }
        });
        const {
          data,
          error,
          errors
        } = yield query;

        if (error || errors || !data) {
          yield put(StatusActions.setResourceToError("building", "generic-fetch-error-message"));
          break;
        }

        if (data.building.units.length === 1) {
          if (data.building.units[0]?.listing?.id) {
            yield put({
              type: "LISTING_INITIALIZE",
              payload: {
                kind: "WithListingId",
                listingId: data.building.units[0].listing.id
              }
            });
          } else {
            yield put({
              type: ListingActionsTypes.LISTING_SET_UNIT_FIELD,
              payload: {
                id: data.building.units[0].id
              }
            });
          }
        }

        yield put({
          type: BuildingActionsTypes.BUILDING_SET_FIELD,
          payload: fromGetBuilding(data)
        });
        yield put(StatusActions.setResourceToIdle("building"));
        break;
      }

    case "NoContext":
    default:
      // State is already changed from reducer.
      break;
  }
}

export default {
  *executers() {
    yield all([yield takeLatest(BuildingActionsTypes.BUILDING_INITIALIZE, onInitializeBuilding), yield takeLatest(BuildingActionsTypes.BUILDING_SUBMIT, onSubmitForm)]);
  }

};