import { DatePicker } from "../../../components/DatePicker.js";
import { DescriptionList } from "../../../components/Tailwind/DescriptionList.js";
import useI18n from "../../../hooks/useI18n.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { Controller, useFormContext } from "../../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const DateField = () => {
  const {
    t
  } = useI18n();
  const {
    control,
    formState: {
      errors
    }
  } = useFormContext();
  return __cssprop(DescriptionList.Item, {
    title: "Date",
    inline: true
  }, __cssprop("div", {
    css: {
      "marginTop": "1.6rem"
    }
  }, __cssprop(Controller, {
    name: "availability",
    control: control,
    rules: {
      required: true
    },
    render: ({
      field
    }) => __cssprop(DatePicker, {
      id: "availability",
      defaultValue: field.value,
      onChange: field.onChange,
      error: errors.availability && {
        errorMessage: t("field-required")
      },
      disabled: false
    })
  })));
};