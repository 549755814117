/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useMemo, useState } from "../../../../../_snowpack/pkg/react.js";
import { useSelector } from "../../../../../_snowpack/pkg/react-redux.js";
import { useParams } from "../../../../../_snowpack/pkg/react-router.js";
import Select from "../../../../../_snowpack/pkg/react-select.js";
import { useAction } from "../../../hooks/useRedux.js";
import { Selectors } from "../../../store/index.js";
import { TicketActionTypes } from "../../../store/artifacts/Tickets/types.js";
import { TICKET_SIDEBAR_SELECTORS } from "../../../utils/ticket.js";
import useI18n from "../../../hooks/useI18n.js";
import { SimpleSelector } from "./SimpleSelector.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const typeSelectorValues = t => {
  return {
    space: {
      value: "space",
      label: t("property")
    },
    user: {
      value: "user",
      label: t("user")
    },
    tenant: {
      value: "tenant",
      label: t("tenant")
    },
    project: {
      value: "project",
      label: t("project")
    }
  };
};

export const mapUnitItems = data => {
  const res = [];
  data?.buildings.items?.forEach(building => {
    building.units?.forEach(unit => {
      const obj = {
        value: unit.id,
        label: `${unit.buildingAddress} ${unit.subAddress}`
      };
      res.push(obj);
    });
  });
  return res;
};

const TicketInfoSidebarSelector = () => {
  const {
    t
  } = useI18n();
  const {
    id
  } = useParams();
  const [typeOfResource, setTypeOfResource] = useState();
  const {
    dispatch
  } = useAction();
  const resources = useSelector(Selectors.ticket.getResource);
  const isClosed = Boolean(useSelector(Selectors.ticket.getClosedAt));
  const {
    space,
    tenant,
    project
  } = resources;
  const selectors = useMemo(() => {
    const selectorKeys = Object.keys(TICKET_SIDEBAR_SELECTORS(t));
    const selectorValues = { ...TICKET_SIDEBAR_SELECTORS(t)
    };
    selectorKeys.forEach(value => {
      selectorValues[value].defaultValue = resources[selectorValues[value].requestFieldName];
    });
    return selectorValues;
  }, [typeOfResource]);
  useEffect(() => {
    const resourceObject = {
      space: space || null,
      project: project || null,
      tenant: tenant || null
    };
    const resource = Object.keys(resourceObject).find(key => resourceObject[key] !== null);
    setTypeOfResource(resource);
  }, [resources]);

  const handleChange = type => e => {
    const resource = {
      [`${type}Id`]: e?.id || e?.value
    };
    dispatch({
      type: [TicketActionTypes.TICKET_SET_FIELD],
      payload: {
        resource: {
          [type]: resource
        } || null
      }
    });

    if (id) {
      dispatch({
        type: TicketActionTypes.TICKET_UPDATE,
        payload: {
          resource: {
            [type]: resource
          } || null
        }
      });
    }
  };

  return __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.4rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
    }
  }, t("tickets-resource-selector-header")), __cssprop("div", {
    css: isClosed && {
      "cursor": "not-allowed"
    }
  }, __cssprop(Select, {
    style: {
      width: "100%"
    },
    isClearable: false,
    isMulti: false,
    placeholder: t("tickets-resource-selector-header"),
    onChange: e => {
      setTypeOfResource(e.value);
    },
    options: Object.values(typeSelectorValues(t)),
    menuContainerStyle: {
      zIndex: 25
    },
    value: typeSelectorValues(t)[typeOfResource],
    isDisabled: isClosed
  }))), typeOfResource && __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.4rem"
    },
    key: selectors[typeOfResource].name
  }, __cssprop("h3", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
    }
  }, selectors[typeOfResource].label), __cssprop("div", {
    css: isClosed && {
      "cursor": "not-allowed"
    }
  }, __cssprop(SimpleSelector, {
    labelPattern: selectors[typeOfResource].labelPattern,
    query: selectors[typeOfResource].query,
    defaultValue: selectors[typeOfResource].defaultValue,
    onChange: handleChange(selectors[typeOfResource].requestFieldName || selectors[typeOfResource].name),
    label: selectors[typeOfResource].label,
    name: selectors[typeOfResource].name,
    disabled: isClosed,
    isClearable: false,
    extraValues: selectors[typeOfResource].extraValues
  }))));
};

export default TicketInfoSidebarSelector;