import { ProjectPanel } from "../../components/Panels/ProjectPanel.js";
import { Badge } from "../../components/Tailwind/Badge.js";
import RemoteList from "../../components/Tailwind/RemoteList.js";
import { DualColumnItem, ResourceList } from "../../components/Tailwind/ResourceList.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import { GetProjectsDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { ProjectActionsTypes } from "../../store/artifacts/Project/types.js";
import { MANAGEMENT_TYPES } from "../../utils/project.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useEffect } from "../../../../_snowpack/pkg/react.js";
import { BiBuildingHouse } from "../../../../_snowpack/pkg/react-icons/bi.js";
import { MdStore } from "../../../../_snowpack/pkg/react-icons/md.js";
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedButton, ProtectedCard } from "../../components/Tailwind/ProtectedComponents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Projects = withBreadcrumb(({
  history,
  setCrumb
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  useEffect(() => {
    setCrumb({
      title: t("projects"),
      path: "/clients",
      level: 0
    });
  }, []);

  const renderProjects = item => {
    const {
      statistics: {
        totalUnits,
        totalBuildings
      }
    } = item;
    const projectContact = [];

    if (item.phoneInfo?.primary_phone) {
      projectContact.push(__cssprop("div", null, item.phoneInfo.primary_phone));
    }

    if (item.email) {
      projectContact.push(__cssprop("div", null, item.email));
    }

    const itemBody = __cssprop(DualColumnItem, {
      primary: {
        heading: __cssprop("div", {
          css: {
            "display": "flex"
          }
        }, __cssprop("h1", {
          css: {
            "fontSize": "2rem",
            "lineHeight": "2.8rem"
          }
        }, item.name), __cssprop(Badge, {
          css: {
            "marginLeft": "0.8rem"
          }
        }, MANAGEMENT_TYPES[item.managementType])),
        content: projectContact
      },
      secondary: {
        heading: __cssprop("div", {
          css: {
            "@media (min-width: 640px)": {
              "display": "flex"
            }
          }
        }, __cssprop("div", {
          css: {
            "display": "flex",
            "flexDirection": "column",
            "padding": "0.8rem",
            "alignItems": "center"
          }
        }, __cssprop(MdStore, {
          css: {
            "flexShrink": "0",
            "marginRight": "0.6rem",
            "height": "2.4rem",
            "width": "2.4rem",
            "--tw-text-opacity": "1",
            "color": "rgba(167, 139, 250, var(--tw-text-opacity))"
          }
        }), totalBuildings, " ", t("buildings").toLowerCase()), __cssprop("div", {
          css: {
            "display": "flex",
            "flexDirection": "column",
            "padding": "0.8rem",
            "alignItems": "center"
          }
        }, __cssprop(BiBuildingHouse, {
          css: {
            "flexShrink": "0",
            "marginRight": "0.6rem",
            "height": "2.4rem",
            "width": "2.4rem",
            "--tw-text-opacity": "1",
            "color": "rgba(167, 139, 250, var(--tw-text-opacity))"
          }
        }), totalUnits, " ", t("units")))
      }
    });

    return __cssprop("div", null, __cssprop(ResourceList.Item, {
      id: item.id,
      accessibilityLabel: t("see-details-for", {
        for: item.name
      }),
      onClick: () => {
        dispatch({
          type: ProjectActionsTypes.PROJECT_INITIALIZE,
          payload: {
            kind: "WithProjectId",
            projectId: item.id
          }
        });
        history.push(`/clients/${item.id}`);
      },
      persistActions: true
    }, itemBody));
  };

  const onDoneSubmit = data => {
    dispatch({
      type: ProjectActionsTypes.PROJECT_SUBMIT,
      payload: { ...data
      }
    });
  };

  return __cssprop(React.Fragment, null, __cssprop(ProjectPanel, {
    formTitle: t("project"),
    showOrgPicker: true,
    description: "",
    sectionTitle: "",
    onDoneSubmit: onDoneSubmit
  }), __cssprop(Page, {
    title: t("projects"),
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Project",
        permission: "mutate:create"
      },
      primary: true,
      onAction: () => {
        dispatch({
          type: ProjectActionsTypes.PROJECT_ADD_NEW,
          payload: null
        });
      }
    }, t("project-add-project"))
  }, __cssprop(ProtectedCard, {
    permission: {
      kind: "Project",
      permission: "view:list"
    }
  }, __cssprop(RemoteList, {
    query: GetProjectsDocument,
    name: "projects",
    renderItem: renderProjects,
    resourceName: {
      plural: t("projects"),
      singular: t("project")
    }
  }))));
});
export default Projects;