import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';

import "../../../_snowpack/pkg/firebase/auth.js";
import firebase from "../../../_snowpack/pkg/firebase.js";
export const RESOURCES = {
  Building: "building",
  Unit: "unit",
  Tenants: "tenant",
  Lease: "lease",
  Deposit: "deposit",
  Company: "company"
};
export const IMAGE_RESOURCES = {
  Building: "building",
  Unit: "unit",
  User: "user",
  Company: "company",
  Project: "project",
  Listing: "listing"
}; // Initialize Firebase

export function initFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(JSON.parse(__SNOWPACK_ENV__.SNOWPACK_PUBLIC_FIREBASE_CONFIGS ?? "{}")); // THIS IS NECESSARY TO ENSURE THAT A USER OBJECT EXISTS WITHOUT HAVING TO DO MULTIPLE CALLS TO FIREBASE

    firebase.auth().onAuthStateChanged(user => {
      if (user) {// User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        // ...
      } else {// User is signed out
          // ...
        }
    });
  } else {
    firebase.app();
  }
}
export async function createUser(email, password) {
  try {
    const firebaseUser = await firebase.auth().createUserWithEmailAndPassword(email, password);
    if (!firebaseUser.user) // Typescript does this? interesting
      throw new Error("User Profile is null");
    const token = await firebaseUser.user.getIdToken();
    localStorage.setItem("token", token);
    return token;
  } catch {
    throw new Error("Invalid login information");
  }
}
export async function sendEmailReset(email) {
  return firebase.auth().sendPasswordResetEmail(email);
}