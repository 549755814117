import React from "../../../../_snowpack/pkg/react.js";
import { useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { LeaseDetails } from "../../forms/SideBar/CreateTicketSidebar.js";
import { GetActiveLeaseOnRentalApplicationV2Document } from "../../graphql/documents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const RentalApplicationCurrentLease = ({
  id
}) => {
  const {
    data,
    loading
  } = useQuery(GetActiveLeaseOnRentalApplicationV2Document, {
    variables: {
      id
    },
    fetchPolicy: "no-cache"
  });

  if (loading) {
    return null;
  }

  if (!data || !data.rentalApplicationV2?.unit || !data.rentalApplicationV2?.unit?.currentLease) {
    return null;
  }

  const {
    endDate,
    startDate,
    tenants
  } = data.rentalApplicationV2?.unit?.currentLease;
  return __cssprop(LeaseDetails, {
    endDate: endDate,
    startDate: startDate,
    tenants: tenants.items
  });
};