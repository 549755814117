import { useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { CreateTicketPanel } from "../../components/Panels/CreateTicketPanel.js";
import { Badge } from "../../components/Tailwind/Badge.js";
import { Link } from "../../components/Tailwind/Link.js";
import { Page } from "../../components/Tailwind/Page.js";
import { PaginatedDataTable } from "../../components/Tailwind/PaginatedDataTable.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { getTicketCategories } from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoSidebarGeneral.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import { FilterKind, GetProvidersDocument, GetTicketsDocument, GetUsersDocument, TicketStatus, UserActingPositions } from "../../graphql/documents.js";
import useDebounce from "../../hooks/useDebounce.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { ProviderActionTypes } from "../../store/artifacts/Provider/types.js";
import { TicketActionTypes } from "../../store/artifacts/Tickets/types.js";
import { UNIT_TICKET_PRIORITY_SELECT_OPTIONS, UNIT_TICKET_STATUS_SELECT_OPTIONS } from "../../utils/unit.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useEffect } from "../../../../_snowpack/pkg/react.js";
import { MdRepeat } from "../../../../_snowpack/pkg/react-icons/md.js";
import { useHistory } from "../../../../_snowpack/pkg/react-router.js";
import { prioritiesBadges } from "../TicketInfoPage/index.js";
import { getTicketStatusBadge } from "./components/TicketStatusBadge.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const getResource = (resourceItem, key) => {
  switch (key) {
    case "tenants":
      return resourceItem.tenants.name;

    case "buildings":
      // eslint-disable-next-line no-underscore-dangle
      if (resourceItem.buildings.__typename === "UnitGQL") {
        return `${resourceItem.buildings?.subAddress}-${resourceItem.buildings?.buildingAddress}`;
      }

      return `${resourceItem.buildings?.civicNumber} ${resourceItem.buildings?.streetName}`;

    case "clients":
      return resourceItem.clients.name;

    case "lease":
      return resourceItem.lease.unit.buildingAddress;

    default:
      break;
  }

  return null;
};
export const Tickets = withBreadcrumb(({
  history,
  match,
  setCrumb,
  crumbs
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();

  const handleCreateButtonClick = () => {
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: "ticket-create"
    });
  };

  const handleCreateTicket = data => {
    dispatch({
      type: TicketActionTypes.TICKET_CREATE,
      payload: { ...data
      }
    });
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
  };

  useEffect(() => {
    setCrumb({
      title: "Tickets",
      path: `/tickets/`,
      level: 0
    });
  }, []);
  return __cssprop(React.Fragment, null, __cssprop(CreateTicketPanel, {
    onDoneSubmit: handleCreateTicket
  }), __cssprop(Page, {
    title: t("tickets-title"),
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Ticket",
        permission: "mutate:create"
      },
      primary: true,
      onAction: handleCreateButtonClick
    }, t("tickets-add"))
  }, __cssprop(TicketsView, null)));
});

const TicketsView = () => {
  const {
    t
  } = useI18n();
  const history = useHistory();
  const {
    dispatch
  } = useAction();
  const [filtersText, setFiltersText] = React.useState({
    taskAssigneeIds: ""
  });
  const debouncedFilterSearch = useDebounce(filtersText?.taskAssigneeIds, 500);
  const [filters, setFilters] = React.useState({
    categories: [],
    taskAssigneeIds: [],
    priorities: [],
    status: [TicketStatus.Active]
  });
  useEffect(() => {
    setFilters(prev => ({ ...prev
    }));
  }, [filters.status]);
  const {
    data
  } = useQuery(GetProvidersDocument, {
    variables: {
      take: 1000,
      skip: 0
    }
  });
  const {
    data: users
  } = useQuery(GetUsersDocument, {
    variables: {
      take: 100,
      query: debouncedFilterSearch,
      skip: 0,
      roles: {
        kind: FilterKind.Inclusive,
        roles: [UserActingPositions.PropertyManager, UserActingPositions.VisitAgent]
      }
    }
  });

  const renderBody = item => {
    const {
      id,
      title,
      priority,
      category,
      modifiedAt,
      createdAt,
      tasks,
      conversations,
      closedAt,
      resource,
      deletedAt,
      isRecurring
    } = item;
    const {
      lease,
      user,
      space,
      project
    } = resource;
    const resourceObject = {
      tenants: user || null,
      buildings: space || null,
      clients: project || null,
      lease: lease || null
    };
    const uniqueResource = Object.fromEntries(Object.entries(resourceObject).filter(([key, value]) => value !== null));
    const completedTasks = tasks?.items.filter(task => task.closedAt !== null).length;

    const handleOnItemSummaryClick = () => {
      history.push(`/ticket/${id}`);
    };

    const percentage = tasks.items.length !== 0 ? (completedTasks / tasks.items.length * 100).toFixed(0) : 0;

    const percentageColors = percentageValue => {
      if (percentageValue < 33) {
        return {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(220, 38, 38, var(--tw-bg-opacity))"
        };
      }

      if (percentageValue < 66) {
        return {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(251, 191, 36, var(--tw-bg-opacity))"
        };
      }

      if (percentageValue < 100) {
        return {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(5, 150, 105, var(--tw-bg-opacity))"
        };
      }

      return {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))"
      };
    };

    const progressBar = __cssprop("div", {
      css: {
        "width": "100%"
      },
      style: {
        zIndex: 1
      }
    }, __cssprop("div", {
      css: {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))",
        "borderRadius": "9999px",
        "overflow": "hidden",
        "height": "2rem",
        "width": "100%",
        "position": "relative",
        "zIndex": "10"
      }
    }, __cssprop("div", {
      css: [{
        "height": "100%",
        "position": "absolute",
        "zIndex": "10"
      }, percentageColors(Number(percentage))],
      style: {
        width: `${percentage}%`,
        zIndex: -1
      }
    }), __cssprop("p", {
      css: {
        "textAlign": "center",
        "zIndex": "10"
      }
    }, percentage !== "100" ? `${percentage}%` : "Done")));

    const handleClickResource = () => {
      const provider = data?.providers.items?.find(res => {
        return res?.id === uniqueResource[Object.keys(uniqueResource)[0]]?.id;
      });

      if (!provider) {
        history.push(`/${uniqueResource?.buildings?.__typename === "UnitGQL" ? "unit" : Object.keys(uniqueResource)[0]}/${uniqueResource[Object.keys(uniqueResource)[0]].id}`);
      } else {
        dispatch({
          type: ProviderActionTypes.PROVIDER_SET_FIELD,
          payload: { ...provider
          }
        });
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: "provider"
        });
      }
    };

    const styleForClosedTicket = closedAt ? [{
      "textDecoration": "line-through"
    }] : [];
    return {
      title: __cssprop("div", {
        css: {
          "display": "flex",
          "flexDirection": "row",
          "gap": "1.2rem"
        }
      }, isRecurring ? __cssprop(MdRepeat, {
        size: 20,
        color: "#6366f1"
      }) : __cssprop("div", {
        style: {
          width: 20
        }
      }), __cssprop(Link, {
        text: title,
        custom: styleForClosedTicket,
        action: {
          kind: "ClickAction",
          event: handleOnItemSummaryClick
        }
      })),
      resource: __cssprop(Link, {
        text: getResource(uniqueResource, Object.keys(uniqueResource)[0]),
        action: {
          kind: "ClickAction",
          event: handleClickResource
        }
      }),
      priority: prioritiesBadges(t)[priority],
      category: __cssprop(Badge, {
        primary: true
      }, t(category)),
      status: getTicketStatusBadge({
        deletedAt,
        closedAt
      }, t),
      createdAt: DateTime.fromISO(createdAt).toRelative(),
      tasks: progressBar,
      lastModified: DateTime.fromISO(modifiedAt).toUTC().toISODate(),
      conversations: conversations?.items.length ? `${conversations?.items.length} ${conversations?.items.length === 1 ? t("tickets-conversation-singular") : t("tickets-conversation-plural")}` : t("tickets-conversation-none")
    };
  };

  const columns = [{
    Header: t("tickets-table-title"),
    accessor: "title"
  }, {
    Header: t("tickets-table-resource"),
    accessor: "resource",
    disableSortBy: true
  }, {
    Header: t("tickets-table-priority"),
    accessor: "priority"
  }, {
    Header: t("tickets-table-category"),
    accessor: "category"
  }, {
    Header: t("tickets-table-status"),
    accessor: "status"
  }, {
    Header: t("tickets-table-created"),
    accessor: "createdAt"
  }, {
    Header: t("tickets-table-tasks"),
    accessor: "tasks"
  }];
  const filterItems = [{
    placeholder: t("tickets-form-category"),
    options: getTicketCategories(t),
    name: "categories"
  }, {
    placeholder: t("tickets-form-priority"),
    options: UNIT_TICKET_PRIORITY_SELECT_OPTIONS,
    name: "priorities"
  }, {
    placeholder: t("status"),
    options: UNIT_TICKET_STATUS_SELECT_OPTIONS,
    name: "status"
  }, {
    placeholder: t("users"),
    options: users?.users.items?.map(u => ({
      label: u.name,
      value: u.id
    })) || [],
    name: "taskAssigneeIds"
  }];
  return __cssprop(PaginatedDataTable, {
    title: t("tickets-title"),
    query: GetTicketsDocument,
    headers: columns,
    filters: filterItems,
    extraVariables: filters,
    numberOfFilters: filters.categories.length + filters.priorities.length,
    onFilterChange: (kind, change) => {
      setFilters(f => ({ ...f,
        [kind]: change.map(({
          value
        }) => value)
      }));
    },
    onFilterTextChange: (kind, value) => {
      setFiltersText(f => ({ ...f,
        [kind]: value
      }));
    },
    renderItem: renderBody,
    resourceName: {
      plural: t("ticket-plural"),
      singular: t("ticket-singular")
    }
  });
};

export default Tickets;