import { css as _css } from "../../../../_snowpack/pkg/@emotion/react.js";
import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { Global as _globalImport } from "../../../../_snowpack/pkg/@emotion/react.js";

const _GlobalStyles = () => __cssprop(_globalImport, {
  styles: _css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  :root {
    -moz-tab-size: 4;
    tab-size: 4;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
  }

  body {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  }

  hr {
    height: 0;
    color: inherit;
  }

  abbr[title] {
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  table {
    text-indent: 0;
    border-color: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  legend {
    padding: 0;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }


  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  button {
    background-color: transparent;
    background-image: none;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.5;
  }

  body {
    font-family: inherit;
    line-height: inherit;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }

  hr {
    border-top-width: 1px;
  }

  img {
    border-style: solid;
  }

  textarea {
    resize: vertical;
  }

  input::placeholder,
  textarea::placeholder {
    color: #9ca3af;
  }

  button,
  [role="button"] {
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }


      @keyframes spin {
          to { 
            transform: rotate(360deg);
          }
        }
      @keyframes ping {
          75%, 100% { 
            transform: scale(2); opacity: 0;
          }
        }
      @keyframes pulse {
          50% { 
            opacity: .5;
          }
        }
      @keyframes bounce {
          0%, 100% { 
            transform: translateY(-25%); animationTimingFunction: cubic-bezier(0.8,0,1,1);
          }
        
          50% { 
            transform: none; animationTimingFunction: cubic-bezier(0,0,0.2,1);
          }
        }
* {
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
  }
* {
  --tw-shadow: 0 0 #0000; }

select {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
background-position: right 0.5rem center;
background-repeat: no-repeat;
background-size: 1.5em 1.5em;
padding-right: 2.5rem;
color-adjust: exact;
        }
[multiple] {
background-image: initial;
background-position: initial;
background-repeat: unset;
background-size: initial;
padding-right: 0.75rem;
color-adjust: unset;
        }
[type='file'] {
background: unset;
border-color: inherit;
border-width: 0;
border-radius: 0;
padding: 0;
font-size: unset;
line-height: inherit;
        }
[type='radio'] {
border-radius: 100%;
        }
[type='checkbox'] {
border-radius: 0px;
        }
[type='file']:focus {
outline: 1px auto -webkit-focus-ring-color;
        }
[type='radio']:checked {
background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
        }
[type='checkbox']:checked {
background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
        }
::-webkit-date-and-time-value {
min-height: 1.5em;
        }
[type='checkbox']:indeterminate {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
border-color: transparent;
background-color: currentColor;
background-size: 100% 100%;
background-position: center;
background-repeat: no-repeat;
        }
::-webkit-datetime-edit-fields-wrapper {
padding: 0;
        }
input::placeholder,textarea::placeholder {
color: #6b7280;
opacity: 1;
        }
[type='checkbox'],[type='radio'] {
appearance: none;
padding: 0;
color-adjust: exact;
display: inline-block;
vertical-align: middle;
background-origin: border-box;
user-select: none;
flex-shrink: 0;
height: 1rem;
width: 1rem;
color: #2563eb;
background-color: #fff;
border-color: #6b7280;
border-width: 1px;
        }
[type='checkbox']:focus,[type='radio']:focus {
outline: 2px solid transparent;
outline-offset: 2px;
--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
--tw-ring-offset-width: 2px;
--tw-ring-offset-color: #fff;
--tw-ring-color: #2563eb;
--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
        }
[type='checkbox']:checked,[type='radio']:checked {
border-color: transparent;
background-color: currentColor;
background-size: 100% 100%;
background-position: center;
background-repeat: no-repeat;
        }
[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus {
border-color: transparent;
background-color: currentColor;
        }
[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus {
border-color: transparent;
background-color: currentColor;
        }
[type='text']:focus,[type='email']:focus,[type='url']:focus,[type='password']:focus,[type='number']:focus,[type='date']:focus,[type='datetime-local']:focus,[type='month']:focus,[type='search']:focus,[type='tel']:focus,[type='time']:focus,[type='week']:focus,[multiple]:focus,textarea:focus,select:focus {
outline: 2px solid transparent;
outline-offset: 2px;
--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
--tw-ring-offset-width: 0px;
--tw-ring-offset-color: #fff;
--tw-ring-color: #2563eb;
--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
border-color: #2563eb;
        }
[type='text'],[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select {
appearance: none;
background-color: #fff;
border-color: #6b7280;
border-width: 1px;
border-radius: 0px;
padding-top: 0.5rem;
padding-right: 0.75rem;
padding-bottom: 0.5rem;
padding-left: 0.75rem;
font-size: 1rem;
line-height: 1.5rem;
        }`
});

import { Switch } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { Loader } from "../../components/Loader.js";
import { Card as TWCard } from "../../components/Tailwind/Card.js";
import { Link } from "../../components/Tailwind/Link.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { loginWithFacebook, loginWithGoogle, loginWithMicrosoft } from "../../services/authentication.js";
import { SessionActionsTypes } from "../../store/artifacts/Session/types.js";
import i18next from "../../../../_snowpack/pkg/i18next.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useCallback, useState } from "../../../../_snowpack/pkg/react.js";
import { Trans } from "../../../../_snowpack/pkg/react-i18next.js";
import { MdError } from "../../../../_snowpack/pkg/react-icons/md.js";
import Select from "../../../../_snowpack/pkg/react-select.js";
import { Alert } from "../../components/Tailwind/Alert.js";
import { Button } from "../../components/Tailwind/Button.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
const FEDERATED_TYPES = {
  GOOGLE: {
    title: "Google",
    handler: loginWithGoogle,
    icon: "/img/google_icon.svg"
  },
  MICROSOFT: {
    title: "Microsoft",
    handler: loginWithMicrosoft,
    icon: "/img/microsoft_icon.svg"
  },
  FACEBOOK: {
    title: "Facebook",
    handler: loginWithFacebook,
    icon: "/img/square-facebook_icon.svg"
  }
};

const StyledSwitch = _styled(Switch)(({
  checked
}) => [{
  "position": "relative",
  "display": "inline-flex",
  "flexShrink": "0",
  "height": "2.4rem",
  "width": "4.4rem",
  "borderWidth": "2px",
  "borderColor": "transparent",
  "borderRadius": "9999px",
  "cursor": "pointer",
  "transitionProperty": "background-color, border-color, color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-width": "2px",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
  }
}, !checked ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
} : {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))"
}]);

export const StyledSpan = _styled.span(({
  active
}) => [{
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "0",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "pointerEvents": "none",
  "display": "inline-block",
  "height": "2rem",
  "width": "2rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)"
}, active ? {
  "--tw-translate-x": "2rem"
} : {
  "--tw-translate-x": "0px"
}]);

const StyledBTN = _styled.button(({
  disabled
}) => [{
  "width": "100%",
  "display": "inline-flex",
  "justifyContent": "center",
  "paddingTop": "0.8rem",
  "paddingBottom": "0.8rem",
  "paddingLeft": "1.6rem",
  "paddingRight": "1.6rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
  "borderRadius": "0.6rem",
  "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
  }
}, disabled && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
  "cursor": "not-allowed"
}]);

const FederatedButton = ({
  type,
  disabled,
  history,
  rid
}) => {
  const {
    dispatch
  } = useAction();
  const onFederatedClick = useCallback(async () => {
    const user = await type.handler(true, rid);

    if (user) {
      dispatch({
        type: SessionActionsTypes.ON_USER_LOGIN,
        payload: user
      });
      history.push("/properties");
    }
  }, [history, dispatch]);

  const icon = __cssprop("img", {
    css: {
      "width": "2.4rem",
      "height": "2.4rem"
    },
    className: "federated-icon",
    src: type.icon,
    alt: type.title
  });

  return __cssprop(StyledBTN, {
    disabled: disabled,
    type: "button",
    onClick: onFederatedClick,
    css: {}
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, type.title), icon);
};

const Frame = ({
  children,
  info
}) => {
  const {
    t
  } = useI18n();
  return __cssprop("div", {
    css: {
      "minHeight": "100vh",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))"
    }
  }, __cssprop("main", null, __cssprop("div", {
    css: {
      "paddingTop": "9.6rem",
      "paddingBottom": "9.6rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "@media (min-width: 640px)": {
        "paddingTop": "12.8rem",
        "paddingBottom": "12.8rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "44.8rem",
      "marginLeft": "auto",
      "marginRight": "auto"
    }
  }, __cssprop("img", {
    src: "/img/icon.svg",
    css: {
      "height": "12.8rem",
      "width": "12.8rem",
      "display": "block",
      "marginLeft": "auto",
      "marginRight": "auto"
    },
    alt: "SimpleLoyer"
  })), __cssprop("div", {
    css: {
      "maxWidth": "44.8rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingLeft": "1.6rem",
      "paddingRight": "3.2rem",
      "@media (min-width: 640px)": {
        "maxWidth": "51.2rem",
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 1024px)": {
        "maxWidth": "128rem",
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem"
      }
    }
  }, __cssprop("h1", {
    css: {
      "fontSize": "3rem",
      "lineHeight": "4rem",
      "fontWeight": "800",
      "letterSpacing": "-0.025em",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "textAlign": "center",
      "@media (min-width: 640px)": {
        "fontSize": "3rem",
        "lineHeight": "1"
      },
      "@media (min-width: 1024px)": {
        "fontSize": "3.6rem",
        "lineHeight": "4rem"
      }
    }
  }, t("registration-welcome-title")), info && __cssprop("p", {
    css: {
      "marginTop": "2.4rem",
      "maxWidth": "76.8rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "fontSize": "2rem",
      "lineHeight": "1.5",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "textAlign": "center"
    }
  }, __cssprop(Trans, {
    i18nKey: info.permissionType === "Project" ? "registration-invited-by-project" : "registration-invited-by-org"
  }, __cssprop("span", {
    css: {
      "fontWeight": "700"
    }
  }, {
    name: info.inviterName
  }), __cssprop("span", {
    css: {
      "fontWeight": "700"
    }
  }, {
    managementName: info.managementName
  }))), __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "maxWidth": "32rem",
      "marginLeft": "auto",
      "marginRight": "auto"
    }
  }, __cssprop(Select, {
    style: {
      width: "100%"
    },
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      })
    },
    isClearable: true,
    isMulti: false,
    placeholder: t("registration-change-language"),
    onChange: i => {
      i18next.changeLanguage(i.value);
    },
    options: [{
      label: "Francais / French ",
      value: "fr"
    }, {
      label: "Anglais / English",
      value: "en"
    }],
    menuContainerStyle: {
      zIndex: 25
    }
  })))), __cssprop("div", {
    css: {
      "position": "relative",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))"
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 1024px)": {
        "position": "absolute",
        "top": "0px",
        "right": "0px",
        "bottom": "0px",
        "left": "0px"
      }
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 1024px)": {
        "position": "absolute",
        "top": "0px",
        "bottom": "0px",
        "right": "0px",
        "width": "50%"
      }
    }
  }, __cssprop("img", {
    css: {
      "height": "22.4rem",
      "width": "100%",
      "objectFit": "cover",
      "@media (min-width: 1024px)": {
        "position": "absolute",
        "height": "100%"
      }
    },
    src: "/img/montreal-ball.jpg",
    alt: ""
  }))), __cssprop("div", {
    css: {
      "position": "relative",
      "paddingTop": "3.2rem",
      "paddingBottom": "3.2rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingTop": "9.6rem",
        "paddingBottom": "9.6rem",
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 1024px)": {
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem",
        "maxWidth": "128rem",
        "marginLeft": "auto",
        "marginRight": "auto",
        "paddingTop": "6.4rem",
        "paddingBottom": "6.4rem",
        "display": "grid",
        "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
      }
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 1024px)": {
        "paddingRight": "3.2rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "44.8rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "@media (min-width: 640px)": {
        "maxWidth": "51.2rem"
      },
      "@media (min-width: 1024px)": {
        "marginLeft": "0px",
        "marginRight": "0px"
      }
    }
  }, children))))), __cssprop("footer", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))"
    },
    "aria-labelledby": "footer-heading"
  }, __cssprop("div", null, __cssprop("div", {
    css: {
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(55, 65, 81, var(--tw-border-opacity))",
      "paddingTop": "4.8rem",
      "paddingBottom": "4.8rem"
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      "@media (min-width: 1280px)": {
        "textAlign": "center"
      }
    }
  }, "\xA9 ", new Date().getFullYear(), " SimpleLoyer.ca, All rights reserved.")))));
};

const FetchedView = ({
  info,
  rid,
  history,
  handleFailure,
  failure,
  t
}) => {
  const {
    dispatch
  } = useAction();
  const [isConnecting, setIsConnecting] = useState(false);
  const [hasAcceptedConsent, sethasAcceptedConsent] = useState(false); // Email

  const [username, setUsername] = useState(info.email);
  const handleUsername = useCallback(v => setUsername(v), []); // password

  const [password, setPassword] = useState();
  const handleChangePassword = useCallback(v => setPassword(v), []);
  const [password2, setPassword2] = useState();
  const handleChangePassword2 = useCallback(v => setPassword2(v), []); // Name information

  const [firstName, setFirstName] = useState(info.firstName);
  const handleChangeFirstName = useCallback(v => setFirstName(v), []);
  const [lastName, setLastName] = useState(info.lastName);
  const handleChangeLastName = useCallback(v => setLastName(v), []);
  const onRegisterClick = useCallback(async () => {//try {
    //  if (username === "") {
    //    handleFailure(true);
    //    return;
    //  }
    //  if (password !== password2) {
    //    handleFailure(true);
    //    return;
    //  }
    //  setIsConnecting(true);
    //  const token = await createUser(username, password);
    //  const response = await registerToSimpleLoyerFromRegistrationToken({ firstName, lastName, rid, userName: username, userId: token });
    //  if (response.status !== 200) {
    //    return;
    //  }
    //  const user = await loginToSimpleLoyerFromFirebaseToken(token);
    //  if (user) {
    //    dispatch({ type: "BEGIN_USER_LOGIN", payload: user });
    //    history.push("/dashboard");
    //  }
    //} catch {
    //  handleFailure(true);
    //  setIsConnecting(false);
    //}
  }, [username, password, password2, history]);
  return __cssprop(Frame, {
    info: info
  }, failure && __cssprop(BannerError, {
    t: t
  }), __cssprop("h2", {
    css: {
      "fontSize": "2.4rem",
      "lineHeight": "3.2rem",
      "fontWeight": "800",
      "letterSpacing": "-0.025em"
    }
  }, t("registration-complete-registration-title")), __cssprop("p", {
    css: {
      "marginTop": "1.6rem",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "paddingBottom": "2.4rem",
      "@media (min-width: 640px)": {
        "marginTop": "1.2rem"
      }
    }
  }, t("registration-complete-registration-with-option")), __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(238, 242, 255, var(--tw-bg-opacity))",
      "marginBottom": "3.2rem",
      "borderRadius": "0.4rem",
      "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  }, __cssprop(Switch.Group, {
    as: "div",
    css: {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between",
      "paddingLeft": "2.4rem",
      "paddingRight": "2.4rem",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem"
    }
  }, __cssprop("span", {
    css: {
      "flexGrow": "1",
      "display": "flex",
      "flexDirection": "column"
    }
  }, __cssprop(Switch.Label, {
    as: "span",
    css: {
      "fontSize": "2rem",
      "lineHeight": "2.8rem",
      "fontWeight": "800",
      "letterSpacing": "-0.025em"
    },
    passive: true
  }, t("consent-title")), __cssprop(Switch.Description, {
    as: "span",
    css: {
      "marginTop": "1.6rem",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "marginTop": "1.2rem"
      }
    }
  }, t("consent-description"), __cssprop(Link, {
    action: {
      kind: "UrlAction",
      url: t("term-of-service-url"),
      target: "_blank"
    },
    text: t("term-of-service")
  }), " ", t("consent-connector"), __cssprop(Link, {
    action: {
      kind: "UrlAction",
      url: t("privacy-policy-url"),
      target: "_blank"
    },
    text: t("privacy-policy")
  }), ".")), __cssprop(StyledSwitch, {
    checked: !hasAcceptedConsent,
    onChange: () => sethasAcceptedConsent(!hasAcceptedConsent)
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Use setting"), __cssprop(StyledSpan, {
    "aria-hidden": "true",
    active: hasAcceptedConsent
  })))), __cssprop("div", {
    css: {
      "display": "block",
      "position": "relative"
    }
  }, !hasAcceptedConsent && __cssprop("div", {
    css: {
      "position": "absolute",
      "width": "100%",
      "height": "100%",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "opacity": "0.5",
      "zIndex": "50",
      "cursor": "not-allowed"
    }
  }), __cssprop(TWCard, null, __cssprop(TWCard.Section, null, __cssprop("h2", {
    css: {
      "fontSize": "2rem",
      "lineHeight": "2.8rem",
      "fontWeight": "800",
      "letterSpacing": "-0.025em"
    }
  }, t("registration-create-standard-account")), __cssprop("p", {
    css: {
      "marginTop": "1.6rem",
      "fontSize": "1.6rem",
      "lineHeight": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "paddingBottom": "2.4rem",
      "@media (min-width: 640px)": {
        "marginTop": "1.2rem"
      }
    }
  }, t("registration-register-stardard-account")), __cssprop(TextField, {
    id: "email",
    label: t("email"),
    type: "email",
    disabled: isConnecting,
    onChange: handleUsername,
    value: username
  }), __cssprop("div", {
    css: {
      "marginTop": "1.6rem",
      "display": "grid",
      "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
      "rowGap": "0.8rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
        "columnGap": "3.2rem"
      }
    }
  }, __cssprop(TextField, {
    id: "first_name",
    label: t("first-name"),
    type: "text",
    disabled: isConnecting,
    onChange: handleChangeFirstName,
    value: firstName
  }), __cssprop(TextField, {
    id: "last_name",
    label: t("last-name"),
    type: "text",
    disabled: isConnecting,
    onChange: handleChangeLastName,
    value: lastName
  }), __cssprop(TextField, {
    label: t("password"),
    type: "password",
    disabled: isConnecting,
    id: "password",
    onChange: handleChangePassword,
    value: password
  }), __cssprop(TextField, {
    label: t("password-confirm"),
    type: "password",
    disabled: isConnecting,
    id: "password2",
    onChange: handleChangePassword2,
    value: password2
  })), __cssprop("div", {
    css: {
      "marginTop": "2.4rem"
    }
  }, __cssprop(Button, {
    primary: true,
    loading: isConnecting,
    onAction: onRegisterClick
  }, t("registration-with-standard-account")))))), __cssprop("div", {
    css: {
      "position": "relative",
      "marginTop": "3.2rem"
    }
  }, __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("div", {
    css: {
      "width": "100%",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))"
    }
  })), __cssprop("div", {
    css: {
      "position": "relative",
      "display": "flex",
      "justifyContent": "center",
      "fontSize": "1.4rem",
      "lineHeight": "2rem"
    }
  }, __cssprop("span", {
    css: {
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("login-continue-with")))), __cssprop("div", {
    css: {
      "marginTop": "3.2rem",
      "display": "grid",
      "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
      "gap": "1.2rem"
    }
  }, __cssprop(FederatedButton, {
    history: history,
    type: FEDERATED_TYPES.GOOGLE,
    rid: rid,
    disabled: !hasAcceptedConsent
  }), __cssprop(FederatedButton, {
    history: history,
    type: FEDERATED_TYPES.MICROSOFT,
    rid: rid,
    disabled: !hasAcceptedConsent
  })));
};

export const Registration = ({
  history,
  match
}) => {
  const {
    t
  } = useI18n();
  const [loading, setLoading] = useState(true);
  const [failure, setFailure] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const [registrationInformation, setRegistrationInformation] = useState({});
  const {
    rid
  } = match.params; // useEffect(() => {
  //   (async () => {
  //     if (rid) {
  //       try {
  //         const response = await getRegistrationInformation(rid);
  //         if (response.status === 400) {
  //           setIsExpire(true);
  //           setLoading(false);
  //         } else {
  //           setRegistrationInformation(response.data);
  //           setLoading(false);
  //         }
  //       } catch (e) {
  //         setIsExpire(true);
  //         setLoading(false);
  //       }
  //     }
  //   })();
  // }, []);

  if (loading) {
    return __cssprop(Loader, null);
  }

  if (isExpire) {
    return __cssprop(MainPage, null, __cssprop(Frame, {
      info: null
    }, __cssprop("div", {
      css: {
        "textAlign": "center",
        "marginTop": "9.6rem",
        "marginBottom": "9.6rem"
      }
    }, __cssprop(MdError, {
      css: {
        "marginLeft": "auto",
        "marginRight": "auto",
        "height": "4.8rem",
        "width": "4.8rem",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    }), __cssprop("h2", {
      css: {
        "fontSize": "2rem",
        "lineHeight": "2.8rem",
        "marginTop": "0.8rem",
        "fontWeight": "800",
        "letterSpacing": "-0.025em"
      }
    }, t("registration-invitation-invalid")), __cssprop("p", {
      css: {
        "marginTop": "1.6rem",
        "fontSize": "1.6rem",
        "lineHeight": "2.4rem",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "paddingBottom": "2.4rem",
        "@media (min-width: 640px)": {
          "marginTop": "1.2rem"
        }
      }
    }, t("registration-invitation-invalid-description")), __cssprop(Button, {
      primary: true,
      url: "/"
    }, t("registration-back-to-login-page")))));
  }

  return __cssprop(MainPage, {
    t: t
  }, __cssprop(FetchedView, {
    info: registrationInformation,
    rid: rid,
    failure: failure,
    history: history,
    handleFailure: setFailure,
    t: t
  }));
};

const MainPage = props => {
  const {
    children
  } = props;
  return __cssprop(React.Fragment, null, __cssprop(_GlobalStyles, null), children && children);
};

const BannerError = ({
  t
}) => {
  return __cssprop("div", {
    css: {
      "paddingBottom": "1.6rem"
    }
  }, __cssprop(Alert, {
    type: "warning",
    title: t("registration-make-sure-option-completed"),
    description: [t("registration-make-sure-option-completed"), t("registration-make-sure-option-completed-external-platform"), t("registration-valid-email")]
  }));
};

export default Registration;