import React from "../../../../_snowpack/pkg/react.js";
import { StyledCheckbox, StyledCheckboxContainer, StyledCheckboxWrapper, StyledLabelWrapper, StyledLabel } from "./styles.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Checkbox = ({
  id,
  name,
  onChange,
  checked,
  label
}) => {
  const onLocalChange = e => {
    onChange(e.target.checked);
  };

  return __cssprop(StyledCheckboxContainer, {
    key: id
  }, __cssprop(StyledCheckboxWrapper, null, __cssprop(StyledCheckbox, {
    id: id,
    name: name,
    onChange: onLocalChange,
    checked: checked
  })), __cssprop(StyledLabelWrapper, null, __cssprop(StyledLabel, null, label)));
};