function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";

/* eslint-disable import/no-cycle */
import { Selector } from "../../components/Selectors/Selector.js";
import { FullAmenitiesPicker } from "../../components/Tailwind/Amenities/FullAmenitiesPicker.js";
import { Button } from "../../components/Tailwind/Button.js";
import { FileDropZone } from "../../components/Tailwind/FileDropZone.js";
import { NumberTextField, TextField } from "../../components/Tailwind/TextField.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import { UnitActionsTypes } from "../../store/artifacts/Unit/types.js";
import { UNIT_BEDROOMS_SELECT_OPTIONS, UNIT_FLOOR_SELECT_OPTIONS, UNIT_TYPE_SELECT_OPTIONS } from "../../utils/unit.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { useDispatch } from "../../../../_snowpack/pkg/react-redux.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

const getSelectedOptions = (options, selectedOne) => {
  return options.find(o => o.value === selectedOne);
};

export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);
export const UnitSideBar = props => {
  const {
    sectionTitle,
    description
  } = props;
  const {
    t
  } = useI18n();
  const dispatch = useDispatch();
  const isLoading = useSelector(Selectors.status.isLoading("unit"));
  const subAddress = useSelector(Selectors.unit.getSubAddress);
  const squareFootage = useSelector(Selectors.unit.getSquareFootage);
  const bedrooms = useSelector(Selectors.unit.getBedrooms);
  const floor = useSelector(Selectors.unit.getFloor);
  const unitType = useSelector(Selectors.unit.getUnitType);
  const waterTankInstallationDate = useSelector(Selectors.unit.getWaterTankInstallationDate) || DateTime.utc();
  const amenities = useSelector(Selectors.unit.getAmenities);
  const buildingId = useSelector(Selectors.unit.getBuildingId); // sad i used a useState

  const [files, setFiles] = useState(useSelector(Selectors.unit.getFiles));
  const {
    control,
    setError,
    handleSubmit,
    clearErrors,
    formState: {
      errors
    }
  } = useForm();

  const onFileDrop = pFiles => {
    setFiles([...files, ...pFiles]);
  };

  const onFileDelete = fileToDelete => {
    setFiles(prev => {
      return prev.filter(file => file.fileName !== fileToDelete.fileName);
    });
  };

  const onSubmit = data => {
    props.onDoneSubmit({
      bedrooms: data.bedrooms.value,
      files,
      floor: data.floor.value,
      squareFootage: data.squareFootage,
      subAddress: data.subAddress,
      unitType: data.unitType.value,
      amenities,
      buildingId
    });
  };

  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, sectionTitle), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "maxWidth": "67.2rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, description)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("unit-info-section-form-unit-type")), __cssprop(Controller, {
    name: "unitType",
    defaultValue: getSelectedOptions(UNIT_TYPE_SELECT_OPTIONS(t), unitType),
    control: control,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      id: "unitType",
      defaultValue: getSelectedOptions(UNIT_TYPE_SELECT_OPTIONS(t), unitType),
      options: UNIT_TYPE_SELECT_OPTIONS(t),
      error: showError(errors, "unitType", t("unit-info-section-error-unit-type"))
    }))
  }))), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "subAddress",
    control: control,
    defaultValue: subAddress,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "subAddress", t("unit-info-section-error-sub-address")),
      id: "subAddress"
    }, field, {
      label: t("unit-info-section-form-sub-address")
    }))
  })), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("unit-info-section-form-floor")), __cssprop(Controller, {
    name: "floor",
    control: control,
    defaultValue: getSelectedOptions(UNIT_FLOOR_SELECT_OPTIONS(t), floor),
    rules: {
      required: true
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      id: "floor",
      defaultValue: getSelectedOptions(UNIT_FLOOR_SELECT_OPTIONS(t), floor),
      options: UNIT_FLOOR_SELECT_OPTIONS(t),
      error: showError(errors, "floor", t("unit-info-section-error-floor"))
    }))
  }))), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("unit-info-section-form-bedroom-number")), __cssprop(Controller, {
    name: "bedrooms",
    control: control,
    defaultValue: getSelectedOptions(UNIT_BEDROOMS_SELECT_OPTIONS, bedrooms),
    rules: {
      required: true
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      id: "bedrooms",
      defaultValue: getSelectedOptions(UNIT_BEDROOMS_SELECT_OPTIONS, bedrooms),
      options: UNIT_BEDROOMS_SELECT_OPTIONS,
      error: showError(errors, "bedrooms", t("unit-info-section-error-bedroom"))
    }))
  }))), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "squareFootage",
    control: control,
    defaultValue: squareFootage,
    rules: {
      required: true
    },
    render: ({
      field
    }) => __cssprop(NumberTextField, _extends({
      error: showError(errors, "squareFootage", t("unit-info-section-error-square-footage")),
      id: "squareFootage"
    }, field, {
      label: t("unit-info-section-form-square-foot")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "amenities",
    control: control,
    defaultValue: amenities || [],
    render: ({
      field
    }) => __cssprop(FullAmenitiesPicker, {
      amenities: amenities || [],
      resourceType: "Unit",
      selectedValues: field.value,
      setHookError: setError,
      error: errors,
      clearErrors: clearErrors,
      dispatchType: UnitActionsTypes.UNIT_SET_FIELD
    })
  })), __cssprop(StyledItems, null, __cssprop(FileDropZone, {
    files: files,
    inForm: true,
    onFilesDrop: onFileDrop,
    onFilesDelete: onFileDelete
  }))), __cssprop(Button, {
    submit: true,
    loading: isLoading,
    disabled: Object.keys(errors).length !== 0,
    primary: true
  }, t("save"))));
};
export default UnitSideBar;