import * as React from "../../../../../_snowpack/pkg/react.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors } from "../../../store/index.js";
import { useSelector } from "../../../hooks/useRedux.js";
import { SmallTenant } from "../../Tailwind/SmallTenant.js";
import TicketInfoMainTasks from "./TicketInfoMainTasks.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const TicketInfoMain = ({
  data
}) => {
  const {
    t
  } = useI18n();
  const modifiedAt = useSelector(Selectors.ticket.getModifiedAt);
  const createdAt = useSelector(Selectors.ticket.getCreatedAt);
  const category = useSelector(Selectors.ticket.getCategory);
  const stats = [{
    name: t("tickets-info-general-opened-on"),
    stat: __cssprop(React.Fragment, null, __cssprop("span", {
      css: {
        "fontSize": "2rem",
        "lineHeight": "2.8rem"
      }
    }, DateTime.fromISO(`${createdAt}`).toFormat("LLL dd")), __cssprop("span", {
      css: {
        "fontSize": "1.2rem",
        "lineHeight": "1.6rem",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "marginLeft": "0.8rem"
      }
    }, "(", t("tickets-info-general-modified-on"), " ", DateTime.fromISO(`${modifiedAt}`).toFormat("LLL dd"), ")"))
  }, {
    name: t("tickets-form-category"),
    stat: __cssprop("span", {
      css: {
        "fontSize": "2rem",
        "lineHeight": "2.8rem"
      }
    }, t(category))
  }, {
    name: t("tickets-info-general-created-by"),
    stat: data?.createdBy && __cssprop(SmallTenant, {
      tenant: data?.createdBy
    })
  }];
  return __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(3.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(3.2rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", null, __cssprop("dl", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
      "gap": "2rem",
      "@media (min-width: 640px)": {
        "gridTemplateColumns": "repeat(3, minmax(0, 1fr))"
      }
    }
  }, stats.map(item => __cssprop("div", {
    key: item.name,
    css: {
      "overflow": "hidden",
      "borderRadius": "0.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "2rem",
      "paddingBottom": "2rem",
      "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "@media (min-width: 640px)": {
        "padding": "2.4rem"
      }
    }
  }, __cssprop("dt", {
    css: {
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, item.name), __cssprop("dd", {
    css: {
      "marginTop": "0.4rem",
      "fontWeight": "600",
      "letterSpacing": "-0.025em",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, item.stat))))), __cssprop("div", null, __cssprop(TicketInfoMainTasks, null)));
};

export default TicketInfoMain;