function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import React from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { Button } from "../Tailwind/Button.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { TextField } from "../Tailwind/TextField.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

const AccountSideBar = props => {
  const {
    sectionTitle
  } = props;
  const {
    t
  } = useI18n(); // const [addAccount, { loading }] = useMutation(undefined);

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: {
      errors
    }
  } = useForm();

  const onSubmit = data => {
    // addAccount({ variables: { a: "test" } });
    console.log("data", data);
  };

  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, sectionTitle)), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop("div", {
    css: {
      "marginLeft": "-6.4rem"
    },
    style: {
      width: "135%"
    }
  }, __cssprop("img", {
    src: "/img/voidCheck.png",
    alt: "voidCheck"
  }))), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "personName",
    control: control,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "personName", t("account-field-invalid")),
      id: "personName"
    }, field, {
      label: t("account-person-name")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "transitNumber",
    control: control,
    rules: {
      required: true,
      minLength: 1,
      maxLength: 5
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "transitNumber", t("account-field-invalid")),
      id: "transitNumber"
    }, field, {
      label: t("account-form-transit-number")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "institutionNumber",
    control: control,
    rules: {
      required: true,
      minLength: 1,
      maxLength: 3
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "institutionNumber", t("account-field-invalid")),
      id: "institutionNumber"
    }, field, {
      label: t("account-form-institution-number")
    }))
  })), __cssprop(StyledItems, null, __cssprop(Controller, {
    name: "bankAccountNumber",
    control: control,
    rules: {
      required: true,
      minLength: 1
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      error: showError(errors, "bankAccountNumber", t("account-field-invalid")),
      id: "bankAccountNumber"
    }, field, {
      label: t("account-form-bank-account-number")
    }))
  }))), __cssprop(Button, {
    submit: true,
    loading: false,
    disabled: Object.keys(errors).length !== 0,
    primary: true
  }, t("save"))));
};

export const AccountPanel = props => {
  const activeDrawer = useSelector(Selectors.app.getDrawerState);
  const {
    dispatch
  } = useAction();
  const {
    formTitle,
    sectionTitle
  } = props;
  return __cssprop(SlideOver, {
    title: formTitle,
    isOpen: activeDrawer === "account",
    onClose: () => dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    })
  }, __cssprop(AccountSideBar, {
    sectionTitle: sectionTitle
  }));
};