/* eslint-disable no-underscore-dangle */

/* eslint-disable import/no-cycle */
import { TYPES } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { omit } from "../../../utils/index.js";
import { all, getContext, put, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { AddUnitDocument, FeatureType, GetUnitDocument, UpdateUnitDocument } from "../../../graphql/documents.js";
import { ApplicationActionsTypes } from "../Application/types.js";
import { BuildingActionsTypes } from "../Building/types.js";
import { addFilesHandler, uploadDocuments } from "../Files/sagas.js";
import StatusActions from "../Status/actions.js";
import { mapUnitGqlToState } from "./mapper.js";
import { initialState, UnitActionsTypes } from "./types.js";

// =========== REDUCERS =========== //
const setField = (data, state) => {
  return { ...state,
    ...data.payload,
    amenities: [...(data.payload?.amenities || [])]
  };
};

const addUnitFiles = (data, state) => ({ ...state,
  files: [...state.files, ...data.payload].map((i, idx) => {
    return { ...i,
      order: idx
    };
  })
});

const toUnitInputMapper = unit => {
  return { ...omit(unit, "id", "files"),
    amenities: unit.amenities.map(amenity => {
      return {
        key: FeatureType[amenity.key] || amenity.key,
        value: amenity.value || true,
        price: amenity.price,
        pricingType: amenity.pricingType
      };
    })
  };
}; // =========== NOTES =========== //


const addUnitNotes = (data, state) => ({ ...state,
  notes: [...state.notes, ...data.payload]
});

const removeUnitNotes = (data, state) => {
  return { ...state,
    notes: state.notes.filter(note => note.id !== data.payload)
  };
}; // =========== SAGAS =========== //


function* updateExistingUnit(id, unit, files) {
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("unit"));

  try {
    const query = client.query({
      query: UpdateUnitDocument,
      fetchPolicy: "no-cache",
      variables: {
        unit: {
          id,
          unit: toUnitInputMapper(unit)
        }
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("unit", "unit-form-saved-fail"));
      return;
    }

    yield addFilesHandler(files, data.updateUnit.id, RESOURCES.Unit);
    yield put(StatusActions.setResourceToSuccess("unit", "unit-form-saved-success"));
    yield put({
      type: UnitActionsTypes.UNIT_SET_FIELD,
      payload: mapUnitGqlToState(data.updateUnit)
    });
  } catch (e) {
    yield put(StatusActions.setResourceToError("unit", "unit-form-saved-fail"));
  }
}

function* createUnit($data) {
  const {
    payload: {
      unit,
      files
    }
  } = $data;
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("unit"));

  try {
    const query = client.query({
      query: AddUnitDocument,
      fetchPolicy: "no-cache",
      variables: {
        unit: toUnitInputMapper(unit)
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("unit", "unit-form-saved-fail"));
      return;
    }

    yield addFilesHandler(files, data.addUnit.id, RESOURCES.Unit);
    yield put(StatusActions.setResourceToSuccess("unit", "unit-form-saved-success"));
    yield put({
      type: BuildingActionsTypes.BUILDING_INITIALIZE,
      payload: {
        kind: "WithBuildingId",
        buildingId: unit.buildingId
      }
    });
  } catch (e) {
    yield put(StatusActions.setResourceToError("unit", "unit-form-saved-fail"));
  }
}

function* onSubmitForm(data) {
  yield put({
    type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
    payload: null
  });
  const files = yield uploadDocuments(data.payload.files, RESOURCES.Unit, TYPES.Files);

  if (data.payload.id) {
    yield updateExistingUnit(data.payload.id, data.payload, files);
  } else {
    yield put({
      type: UnitActionsTypes.UNIT_CREATE,
      payload: {
        unit: data.payload,
        files
      }
    });
  }
}

function* onInitializeUnit($data) {
  try {
    const {
      payload
    } = $data;

    if (!payload) {
      yield put({
        type: UnitActionsTypes.UNIT_SET_FIELD,
        payload: initialState
      });
      return;
    }

    const client = yield getContext("client");
    yield put({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
    yield put(StatusActions.setResourceToLoading("unit"));
    const query = client.query({
      query: GetUnitDocument,
      fetchPolicy: "cache-first",
      variables: {
        id: payload
      }
    });
    const {
      data,
      error
    } = yield query;

    if (error) {
      yield put(StatusActions.setResourceToError("unit", "generic-fetch-error-message"));
      return;
    }

    yield put({
      type: UnitActionsTypes.UNIT_SET_FIELD,
      payload: mapUnitGqlToState(data.unit)
    }); // if listing exists initialize listing panel with id

    if (data.unit.listing) {
      yield put({
        type: "LISTING_INITIALIZE",
        payload: {
          kind: "WithListingId",
          listingId: data.unit.listing.id
        }
      });
    } // if listing does not exist then initialize listing panel with property context
    else if (data.unit.__typename === "UnitGQL") {
        const al = data.unit.currentLease;
        const {
          unit
        } = data;
        yield put({
          type: "LISTING_INITIALIZE",
          payload: {
            kind: "WithContext",
            activeLeaseEndDate: al?.endDate,
            property: unit
          }
        });
      }
  } finally {
    yield put(StatusActions.setResourceToIdle("unit"));
  }
}

export default {
  reducers: {
    [UnitActionsTypes.UNIT_ADD_FILES]: addUnitFiles,
    [UnitActionsTypes.UNIT_SET_FIELD]: setField,
    [UnitActionsTypes.UNIT_ADD_NOTES]: addUnitNotes,
    [UnitActionsTypes.UNIT_REMOVE_NOTES]: removeUnitNotes
  },

  *executers() {
    yield all([yield takeLatest(UnitActionsTypes.UNIT_INITIALIZE, onInitializeUnit), yield takeLatest(UnitActionsTypes.UNIT_SUBMIT, onSubmitForm), yield takeLatest(UnitActionsTypes.UNIT_CREATE, createUnit)]);
  }

};