import { AmenitiesPicker } from "./AmenitiesPicker.js";
import React, { useCallback, useState, useEffect } from "../../../../../_snowpack/pkg/react.js";
import { omit } from "../../../utils/index.js";
import useI18n from "../../../hooks/useI18n.js";
import { useAction } from "../../../hooks/useRedux.js";
import { FeatureTypeInput, ReversedFeatureType } from "../../../utils/types.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

// this function will check if there are some error with amenities that are no more selected
// the goal is to clear the error of not selected amenities
const removeError = (error, selected) => {
  const result = selected.map(i => {
    if (Object.keys(error).includes(i.key) && FeatureTypeInput[i.key] !== "boolean") {
      return false;
    }

    return true;
  }); // if it include a false dont remove errors

  return !result.includes(false);
};

export const FullAmenitiesPicker = props => {
  const {
    amenities,
    setHookError,
    clearErrors,
    dispatchType
  } = props;
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const [error, setError] = useState({});
  const [selectedOptions, setSelectedOptions] = useState(amenities);
  const onAmenityValueChange = useCallback(item => {
    const updatedAmenitiesValues = amenities.reduce((acc, amenity) => {
      if (amenity.key === item.key) {
        acc.push({ ...amenity,
          value: item.value
        });
        return acc;
      }

      acc.push(amenity);
      return acc;
    }, []);
    dispatch({
      type: dispatchType,
      payload: {
        amenities: updatedAmenitiesValues
      }
    });
  }, [dispatch, amenities]);
  const optionsChanges = useCallback(options => {
    if (!options || options === []) {
      setSelectedOptions([]);
      return;
    }

    setSelectedOptions(options);
    const updatedAmenities = options.reduce((acc, option) => {
      const found = amenities.find(am => am.key === option.value);

      if (found) {
        acc.push(found);
        return acc;
      }

      acc.push({
        key: ReversedFeatureType[option.value],
        value: null
      });
      return acc;
    }, []);
    dispatch({
      type: dispatchType,
      payload: {
        amenities: updatedAmenities
      }
    });
  }, [selectedOptions]);
  useEffect(() => {
    if (Object.keys(error).length !== 0) {
      setHookError("amenities", {
        type: "manual",
        message: "error in one of the amenities field"
      });
    } else {
      clearErrors("amenities");
    }

    if (removeError(error, selectedOptions)) {
      clearErrors("amenities");
    }
  }, [error, selectedOptions]);
  return __cssprop("div", null, __cssprop(AmenitiesPicker, {
    selected: selectedOptions,
    onSelect: v => {
      optionsChanges(v);
    }
  }), __cssprop("div", {
    tw: "mt-4"
  }, __cssprop(AmenitiesPicker.SimpleRenderer, {
    onChangeCallback: item => {
      if (!item.value) {
        setError(pv => {
          return { ...pv,
            [item.key]: t("field-required")
          };
        });
      } else {
        setError(pv => {
          return omit(pv, item.key);
        });
      }

      onAmenityValueChange(item);
    },
    items: amenities,
    error: error
  })));
};