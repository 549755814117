function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";

/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from "../../../../_snowpack/pkg/react.js";
import NumberFormat from "../../../../_snowpack/pkg/react-number-format.js";
import { ExclamationCircleIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const NumberInput = _styled(NumberFormat)(({
  error,
  disabled
}) => [{
  "paddingTop": "0.8rem",
  "paddingBottom": "0.8rem"
}, error && {
  "display": "block",
  "width": "100%",
  "paddingRight": "4rem",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(252, 165, 165, var(--tw-border-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(127, 29, 29, var(--tw-text-opacity))",
  "::placeholder": {
    "--tw-placeholder-opacity": "1",
    "color": "rgba(252, 165, 165, var(--tw-placeholder-opacity))"
  },
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(239, 68, 68, var(--tw-ring-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(239, 68, 68, var(--tw-border-opacity))"
  },
  "borderRadius": "0.6rem",
  "@media (min-width: 640px)": {
    "fontSize": "1.4rem",
    "lineHeight": "2rem"
  }
}, !error && {
  "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
  },
  "display": "block",
  "width": "100%",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
  "borderRadius": "0.6rem",
  "@media (min-width: 640px)": {
    "fontSize": "1.4rem",
    "lineHeight": "2rem"
  }
}, disabled && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
}]);

export const StyledInputField = _styled.input(({
  error,
  clickOnly,
  disabled,
  withButton
}) => [{
  "paddingTop": "0.8rem",
  "paddingBottom": "0.8rem",
  "marginTop": "0.4rem"
}, error && {
  "display": "block",
  "width": "100%",
  "paddingRight": "4rem",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(252, 165, 165, var(--tw-border-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(127, 29, 29, var(--tw-text-opacity))",
  "::placeholder": {
    "--tw-placeholder-opacity": "1",
    "color": "rgba(252, 165, 165, var(--tw-placeholder-opacity))"
  },
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(239, 68, 68, var(--tw-ring-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(239, 68, 68, var(--tw-border-opacity))"
  },
  "borderRadius": "0.6rem",
  "@media (min-width: 640px)": {
    "fontSize": "1.4rem",
    "lineHeight": "2rem"
  }
}, !error && {
  "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
  },
  "display": "block",
  "width": "100%",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
  "borderRadius": "0.6rem",
  "@media (min-width: 640px)": {
    "fontSize": "1.4rem",
    "lineHeight": "2rem"
  }
}, disabled && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
}, withButton && {
  ":focus": {
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
  },
  "display": "block",
  "width": "100%",
  "borderRadius": "0px",
  "borderTopLeftRadius": "0.6rem",
  "borderBottomLeftRadius": "0.6rem",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
  "@media (min-width: 640px)": {
    "fontSize": "1.4rem",
    "lineHeight": "2rem"
  }
}]);

const StyledDivForButton = _styled.div(({
  withButton
}) => [withButton && {
  "display": "flex"
}, !withButton && {
  "position": "relative"
}]);

export const TextField = props => {
  const {
    label,
    id,
    placeholder,
    onChange,
    error,
    clickOnly,
    disabled,
    onClick,
    icon,
    ref,
    type,
    withButton = null
  } = props;
  return __cssprop("div", null, label && __cssprop("label", {
    htmlFor: id,
    css: {
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, label), __cssprop(StyledDivForButton, {
    withButton: !!withButton
  }, clickOnly && __cssprop("div", {
    css: {
      "width": "100%",
      "height": "100%",
      "position": "absolute",
      "cursor": "pointer"
    },
    "aria-hidden": true,
    onClick: onClick
  }), __cssprop(StyledInputField, _extends({}, props, {
    withButton: !!withButton,
    onChange: evt => onChange(evt.target.value),
    type: type || "text",
    disabled: disabled,
    placeholder: placeholder,
    defaultValue: props.defaultValue
  })), withButton, error && __cssprop(ExclamationError, null), !error && icon && __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "right": "0px",
      "paddingRight": "1.2rem",
      "display": "flex",
      "alignItems": "center",
      "pointerEvents": "none"
    }
  }, __cssprop(props.icon, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }))), error && __cssprop(ErrorMessage, {
    errorMessage: error.errorMessage,
    errorType: error.errorType
  }));
};
export const PriceTextField = ({
  label,
  value,
  id,
  placeholder,
  onChange,
  error
}) => {
  return __cssprop("div", null, label && __cssprop("label", {
    htmlFor: id,
    css: {
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, label), __cssprop("div", {
    css: {
      "marginTop": "0.4rem",
      "position": "relative",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    }
  }, __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "left": "0px",
      "paddingLeft": "1.2rem",
      "display": "flex",
      "alignItems": "center",
      "pointerEvents": "none"
    }
  }, __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }
  }, "$")), __cssprop("div", {
    css: {
      "marginTop": "0.4rem",
      "position": "relative"
    }
  }, __cssprop(StyledInputField, {
    onChange: evt => onChange(evt.target.value),
    type: "text",
    name: id,
    id: id,
    defaultValue: value,
    placeholder: placeholder,
    error: error
  }), error && __cssprop(ExclamationError, null)), error && __cssprop(ErrorMessage, {
    errorMessage: error.errorMessage,
    errorType: error.errorType
  }), __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "right": "0px",
      "paddingRight": "1.2rem",
      "display": "flex",
      "alignItems": "center",
      "pointerEvents": "none"
    }
  }, __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    },
    id: "price-currency"
  }, "USD"))));
};
export const NumberTextField = ({
  label,
  value,
  id,
  placeholder,
  onChange,
  allowNegative = true,
  decimalScale,
  prefix,
  thousandSeparator = false,
  isAllowed,
  suffix,
  error,
  disabled
}) => {
  return __cssprop("div", null, label && __cssprop("label", {
    htmlFor: id,
    css: {
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, label), __cssprop("div", null, __cssprop("div", {
    css: {
      "position": "relative",
      "marginTop": "0.4rem"
    }
  }, __cssprop(NumberInput, {
    disabled: disabled,
    suffix: suffix,
    isAllowed: isAllowed,
    onValueChange: v => onChange(v.floatValue),
    name: id,
    id: id,
    value: value,
    placeholder: placeholder,
    thousandSeparator: thousandSeparator,
    prefix: prefix,
    decimalScale: decimalScale || 2,
    allowNegative: allowNegative,
    error: error
  }), error && __cssprop(ExclamationError, null)), error && __cssprop(ErrorMessage, {
    errorMessage: error.errorMessage,
    errorType: error.errorType
  })));
};

const ExclamationError = () => {
  return __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "right": "0px",
      "paddingRight": "1.2rem",
      "display": "flex",
      "alignItems": "center",
      "pointerEvents": "none"
    }
  }, __cssprop(ExclamationCircleIcon, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }));
};

const ErrorMessage = ({
  errorMessage,
  errorType
}) => {
  return __cssprop("p", {
    css: {
      "marginTop": "0.8rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(220, 38, 38, var(--tw-text-opacity))"
    },
    id: `${errorType}-error`
  }, errorMessage);
};