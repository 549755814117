import React from "../../../../../../_snowpack/pkg/react.js";
import { useHistory, useParams } from "../../../../../../_snowpack/pkg/react-router.js";
import { PlusSmIcon } from "../../../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { StyledButton } from "../../../../components/Tailwind/Button.js";
import { useAction, useSelector } from "../../../../hooks/useRedux.js";
import { Selectors } from "../../../../store/index.js";
import { EventActionTypes } from "../../../../store/artifacts/Events/types.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const EventHeader = () => {
  const {
    id
  } = useParams();
  const {
    dispatch
  } = useAction();
  const title = useSelector(Selectors.event.getTitle);
  const description = useSelector(Selectors.event.getDescription);
  const RRule = useSelector(Selectors.event.getRRule);
  const entityMetadata = useSelector(Selectors.event.getEntityMetadata);
  const history = useHistory();

  const handleChange = e => {
    const {
      value
    } = e.target;
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        title: value
      }
    });

    if (id) {
      dispatch({
        type: EventActionTypes.EVENT_UPDATE,
        payload: {
          title: value
        }
      });
    }
  };

  const handleCreateEvent = () => {
    dispatch({
      type: EventActionTypes.EVENT_CREATE
    });
    history.push("/events");
  };

  const isCreatePossible = ![title, RRule, entityMetadata].some(item => !item) && title.length >= 3;
  return __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "row",
      "justifyContent": "space-between"
    }
  }, __cssprop("input", {
    type: "text",
    css: {
      "width": "100%",
      "borderWidth": "0px",
      "fontSize": "2rem",
      "lineHeight": "2.8rem",
      "borderRadius": "0.6rem",
      "backgroundColor": "transparent",
      ":focus": {
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)"
      },
      "paddingLeft": "0px"
    },
    value: title || "",
    onChange: handleChange,
    placeholder: "Event title"
  }), !id && __cssprop(StyledButton, {
    css: {
      "display": "flex",
      "alignItems": "center"
    },
    onClick: handleCreateEvent,
    disabled: !isCreatePossible,
    primary: true
  }, __cssprop(PlusSmIcon, {
    css: {
      "display": "block",
      "height": "2.4rem",
      "width": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }), __cssprop("h2", null, "Create")));
};

export default EventHeader;