import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React, { useCallback, useEffect, useMemo, useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import Select from "../../../../_snowpack/pkg/react-select.js";
import debounce from "../../../../_snowpack/pkg/lodash.debounce.js";
import useI18n from "../../hooks/useI18n.js";
import { UNIT_TICKET_PRIORITY_SELECT_OPTIONS } from "../../utils/unit.js";
import { Selector } from "../../components/Selectors/Selector.js";
import { SimpleSelector } from "../../components/TicketInfo/TicketInfoSidebar/SimpleSelector.js";
import { TICKET_SIDEBAR_SELECTORS } from "../../utils/ticket.js";
import { useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { GetUnitsDocument } from "../../graphql/documents.js";
import { mapUnitItems } from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoSidebarSelector.js";
import { EventActionTypes } from "../../store/artifacts/Events/types.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { TagsSelection } from "../../views/Tickets/components/TagsSelection.js";
import { getTicketCategories } from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoSidebarGeneral.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);
export const EventTicketSideBar = ({
  onDoneSubmit
}) => {
  const {
    t
  } = useI18n();
  const [typeOfResource, setTypeOfResource] = useState();
  const {
    dispatch
  } = useAction();
  const id = useSelector(Selectors.event.getId);
  const entityMetadata = useSelector(Selectors.event.getEntityMetadata);
  const formattedEntityMetadata = useSelector(Selectors.event.getFormattedEntityMetadata);
  const {
    resource
  } = formattedEntityMetadata;
  const {
    space,
    tenant,
    project,
    lease
  } = resource || {};
  const typeSelectorValues = {
    space: {
      value: "space",
      label: t("event-sidebar-resource-space")
    },
    tenant: {
      value: "tenant",
      label: t("event-sidebar-resource-user")
    },
    project: {
      value: "project",
      label: t("event-sidebar-resource-project")
    } // lease: { value: "lease", label: t("event-sidebar-resource-lease") },

  };
  const resources = {
    space: space?.id,
    tenant: tenant?.id,
    project: project?.id,
    lease: lease?.id
  };
  const {
    data
  } = useQuery(GetUnitsDocument);
  const selectors = useMemo(() => {
    const selectorKeys = Object.keys(TICKET_SIDEBAR_SELECTORS(t));
    const selectorValues = { ...TICKET_SIDEBAR_SELECTORS(t)
    };
    selectorKeys.forEach(value => {
      selectorValues[value].defaultValue = resources[selectorValues[value].requestFieldName];
    });
    selectorValues.space.extraValues = mapUnitItems(data);
    return selectorValues;
  }, [typeOfResource]);
  const {
    control,
    setValue,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm();

  const handleChangeTypeOfResource = e => {
    setTypeOfResource(e.value);
  };

  const handleAddTag = inputTag => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        entity: { ...entityMetadata,
          tags: [...(formattedEntityMetadata.tags || []), inputTag]
        }
      }
    });

    if (id) {
      dispatch({
        type: EventActionTypes.EVENT_UPDATE
      });
    }
  };

  const handleRemoveTag = newTags => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        entity: { ...entityMetadata,
          tags: newTags
        }
      }
    });

    if (id) {
      dispatch({
        type: EventActionTypes.EVENT_UPDATE
      });
    }
  };

  const handleTicketFieldChange = useCallback(({
    value,
    type
  }) => {
    setValue(type, value);
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        entity: { ...entityMetadata,
          [type]: type === "title" ? value : value?.value
        }
      }
    });
  }, [entityMetadata]);
  const debounced = debounce(handleTicketFieldChange, 500);
  const handleTicketResourceFieldChange = useCallback(({
    value,
    type
  }) => {
    setValue(type, value);
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        entity: { ...entityMetadata,
          resource: {
            [type]: value
          }
        }
      }
    });
  }, [entityMetadata]);
  useEffect(() => {
    TICKET_SIDEBAR_SELECTORS(t).space.extraValues = mapUnitItems(data);
  }, [data]);
  useEffect(() => {
    const resourceObject = {
      space: resources.space || formattedEntityMetadata.resource?.building?.id || null,
      tenant: resources.tenant || formattedEntityMetadata.resource?.tenant?.id || null,
      project: resources.project || formattedEntityMetadata.resource?.project?.id || null,
      lease: resources.lease || formattedEntityMetadata.resource?.lease?.id || null
    };
    const resourceValue = Object.keys(resourceObject).find(key => resourceObject[key] !== null);
    setTypeOfResource(resourceValue);
  }, [resource, entityMetadata]);
  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onDoneSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("event-sidebar-category-label")), __cssprop(Controller, {
    control: control,
    name: "category",
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(Selector, {
      id: "language",
      options: getTicketCategories(t),
      onChange: value => handleTicketFieldChange({
        value,
        type: "category"
      }),
      error: showError(errors, "category", t("event-sidebar-category-error")),
      isClearable: false,
      defaultValue: formattedEntityMetadata.category
    })
  }))), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("event-sidebar-priority-label")), __cssprop(Controller, {
    name: "priority",
    control: control,
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(Selector, {
      id: "priority",
      options: UNIT_TICKET_PRIORITY_SELECT_OPTIONS,
      onChange: value => handleTicketFieldChange({
        value,
        type: "priority"
      }),
      error: showError(errors, "priority", t("event-sidebar-priority-error")),
      isClearable: false,
      defaultValue: formattedEntityMetadata.priority
    })
  }))), __cssprop(StyledItems, null, __cssprop(TagsSelection, {
    items: formattedEntityMetadata.tags,
    handleAdd: tag => handleAddTag(tag),
    handleRemove: newTags => handleRemoveTag(newTags)
  })), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("event-sidebar-resource-type-label")), __cssprop(Controller, {
    name: "resource",
    control: control,
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(Select, {
      options: Object.values(typeSelectorValues),
      isClearable: false,
      onChange: handleChangeTypeOfResource,
      error: showError(errors, "resource", t("event-sidebar-resource-type-error")),
      value: typeSelectorValues[typeOfResource]
    })
  }))), typeOfResource && __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, selectors[typeOfResource].label), __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.4rem"
    },
    key: selectors[typeOfResource].name
  }, __cssprop("div", null, __cssprop(Controller, {
    name: "resource",
    defaultValue: selectors[typeOfResource].defaultValue,
    control: control,
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(SimpleSelector, {
      labelPattern: selectors[typeOfResource].labelPattern,
      query: selectors[typeOfResource].query,
      label: selectors[typeOfResource].label,
      defaultValue: selectors[typeOfResource].defaultValue,
      name: selectors[typeOfResource].name,
      isClearable: false,
      onChange: value => handleTicketResourceFieldChange({
        value,
        type: typeOfResource
      }),
      error: showError(errors, "resource", t("event-sidebar-resource-type-error")),
      extraValues: selectors[typeOfResource].extraValues
    })
  }))))))));
};
export default EventTicketSideBar;