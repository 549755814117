import React from "../../../../_snowpack/pkg/react.js";
import { Page } from "../../components/Tailwind/Page.js";
import { PaginatedDataTable } from "../../components/Tailwind/PaginatedDataTable.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { ProviderActionTypes } from "../../store/artifacts/Provider/types.js";
import { GetProvidersDocument } from "../../graphql/documents.js";
import { ProviderPanel } from "../../components/Panels/ProviderPanel.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const ProviderView = () => {
  const {
    t
  } = useI18n();
  const [isProviderPanelOpen, setIsProviderPanelOpen] = React.useState(false);
  const [providerId, setProviderId] = React.useState(null);
  const {
    dispatch
  } = useAction();

  const renderBody = item => {
    const {
      id,
      name,
      email,
      phoneInfo
    } = item;

    const handleOpenProvider = () => {
      setProviderId(id);
      setIsProviderPanelOpen(true);
    };

    return {
      name: __cssprop("p", {
        role: "presentation",
        css: {
          "fontWeight": "500",
          "--tw-text-opacity": "1",
          "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
          ":hover": {
            "--tw-text-opacity": "1",
            "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
          },
          "cursor": "pointer"
        },
        onClick: handleOpenProvider,
        onKeyUp: handleOpenProvider
      }, name),
      email,
      phoneInfo: __cssprop("p", null, phoneInfo?.primaryPhone || "No added phone")
    };
  };

  const columns = [{
    Header: t("provider-list-column-name"),
    accessor: "name",
    disableSortBy: true
  }, {
    Header: t("provider-list-column-email"),
    accessor: "email",
    disableSortBy: true
  }, {
    Header: t("provider-list-column-phone"),
    accessor: "phoneInfo",
    disableSortBy: true
  }];
  return __cssprop(React.Fragment, null, __cssprop(ProtectedButton, {
    permission: {
      kind: "Tenant",
      permission: "mutate:create"
    },
    primary: true,
    css: {
      "display": "block",
      "marginLeft": "auto"
    },
    onAction: () => setIsProviderPanelOpen(true)
  }, t("provider-add-new-provider")), __cssprop(ProviderPanel, {
    refetch: () => {
      setIsProviderPanelOpen(false);
    },
    handleOnClose: () => setIsProviderPanelOpen(false),
    isOpen: isProviderPanelOpen,
    providerId: providerId
  }), __cssprop(PaginatedDataTable, {
    title: "Providers",
    query: GetProvidersDocument,
    headers: columns,
    renderItem: renderBody,
    resourceName: {
      plural: t("provider-list-plural"),
      singular: t("provider-list-singular")
    }
  }));
};
export const Providers = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const id = useSelector(Selectors.provider.getId);
  return __cssprop(React.Fragment, null, __cssprop(Page, {
    title: "Providers",
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Tenant",
        permission: "mutate:create"
      },
      primary: true,
      onAction: () => {
        dispatch({
          type: ProviderActionTypes.PROVIDER_CLEAR_STATE
        });
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: "provider"
        });
      }
    }, t("provider-add-new-provider"))
  }, __cssprop(ProviderView, null)));
};
export default Providers;