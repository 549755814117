import useI18n from "../hooks/useI18n.js";
import { Selectors, useSelector } from "../hooks/useRedux.js";
import { checkIfLeaseIsLocked, getFormattedPriceForLease } from "../store/artifacts/Lease/util.js";
import { DateTime } from "../../../_snowpack/pkg/luxon.js";
import React from "../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../_snowpack/pkg/react-router.js";
import { DataList } from "./Tailwind/DataList.js";
import { DropDownActions } from "./Tailwind/DropDownActions.js";
import { ProtectedActions } from "./Tailwind/ProtectedComponents.js";
import { SmallTenant } from "./Tailwind/SmallTenant.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
export const LeaseHistoryCard = ({
  leases,
  openDeleteLeaseModal
}) => {
  const {
    t
  } = useI18n();
  const history = useHistory();
  const role = useSelector(Selectors.session.getUserRole);

  const getTenantInfo = tenants => {
    const tenant = tenants?.map(ten => __cssprop(SmallTenant, {
      tenant: ten.tenant
    }));
    return __cssprop("div", {
      tw: "block"
    }, tenant);
  };

  const getActions = lease => {
    const actions = [ProtectedActions(role, [{
      content: t("unit-see-lease"),
      destructive: false,
      onAction: () => {
        history.push(`/lease/${lease.id}/view`);
      },
      permission: {
        kind: "Lease",
        permission: "view:lease"
      }
    }, {
      content: t("unit-modify-lease"),
      onAction: () => {
        history.push(`/lease/${lease.id}`);
      },
      permission: {
        kind: "Lease",
        permission: "mutate:update"
      },
      condition: !checkIfLeaseIsLocked(lease)
    }, {
      content: t("delete-lease"),
      onAction: () => openDeleteLeaseModal(lease.id),
      permission: {
        kind: "Lease",
        permission: "mutate:delete"
      },
      condition: !checkIfLeaseIsLocked(lease)
    }])];
    return __cssprop(DropDownActions, {
      content: "open",
      dots: true,
      actions: actions
    });
  };

  if (!leases || leases.length === 0) {
    return null;
  }

  const rows = leases.filter(lease => !!lease).map(lease => {
    const row = {
      items: [[getTenantInfo(lease?.tenants?.items)], [DateTime.fromISO(lease?.startDate).toUTC().toISODate()], [DateTime.fromISO(lease?.endDate).toUTC().toISODate()], [getFormattedPriceForLease(lease)], [getActions(lease)]]
    };
    return row;
  }); // sort by end date descending

  const sortedRows = rows.sort((a, b) => {
    const endDateA = DateTime.fromISO(a.items[2]);
    const endDateB = DateTime.fromISO(b.items[2]);

    if (endDateA < endDateB) {
      return 1;
    }

    if (endDateA > endDateB) {
      return -1;
    }

    return 0;
  });
  const headings = [t("unit-heading-name"), t("unit-heading-entry-date"), t("unit-heading-end-date"), t("unit-lease-info-label-monthly-price"), t("unit-heading-action")];
  return __cssprop(React.Fragment, null, __cssprop("div", {
    tw: "relative"
  }, __cssprop(DataList, {
    headings: headings,
    title: t("unit-tenant-history"),
    rows: sortedRows
  })));
};