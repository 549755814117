import { mapFile, mapFileInput, mapFiles, mapFilesInput } from "../../utils/Files.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const fromGetListing = payload => {
  const {
    listing
  } = payload;
  return { ...listing,
    description: listing.description.reduce((acc, desc) => {
      acc[desc.locale] = desc.value;
      return acc;
    }, {
      fr: "",
      en: ""
    }),
    availability: DateTime.fromISO(listing.availability).toUTC().toJSDate(),
    unit: listing.unit,
    gallery: mapFiles(listing.gallery),
    thumbnail: mapFile(listing.thumbnail, 1, "remote")
  };
};
export const fromListingStateToInput = payload => {
  const {
    id,
    status,
    unit,
    published,
    publishedOn,
    responsible,
    projectId,
    __typename,
    ...rest
  } = payload;
  return { ...rest,
    description: Object.entries(payload.description).map(([key, value]) => ({
      locale: key,
      value
    })),
    videoLink: rest.videoLink,
    gallery: mapFilesInput(payload.gallery),
    thumbnail: mapFileInput(payload.thumbnail, 1),
    consents: payload.consents.map(c => {
      return {
        consentType: c.consentType,
        consentValue: c.consentValue
      };
    })
  };
};
export const fromListingStateToCreateInput = payload => {
  return {
    unitId: payload.unit.id,
    listing: fromListingStateToInput(payload)
  };
};