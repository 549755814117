import { DescriptionList } from "../../../components/Tailwind/DescriptionList.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { ListingActionsTypes } from "../../../store/artifacts/Listing/types.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const DescriptionFields = () => {
  const {
    dispatch
  } = useAction();
  const descriptions = useSelector(Selectors.listing.getDescriptions);
  const {
    t
  } = useI18n();
  return __cssprop(React.Fragment, null, Object.keys(descriptions).map(i => {
    return __cssprop(DescriptionList.Item, {
      key: i[0],
      title: t(i)
    }, __cssprop("div", {
      css: {
        "marginTop": "0.8rem",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between"
      }
    }, __cssprop("textarea", {
      id: `description-${i}`,
      name: `description-${i}`,
      rows: 3,
      css: {
        "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
        "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
        ":focus": {
          "--tw-ring-opacity": "1",
          "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
          "--tw-border-opacity": "1",
          "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
        },
        "marginTop": "0.4rem",
        "display": "block",
        "width": "100%",
        "borderWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
        "borderRadius": "0.6rem",
        "@media (min-width: 640px)": {
          "fontSize": "1.4rem",
          "lineHeight": "2rem"
        }
      },
      defaultValue: descriptions[i],
      onChange: e => dispatch({
        type: ListingActionsTypes.LISTING_SET_FIELD,
        payload: {
          description: { ...descriptions,
            [i]: e.target.value
          }
        }
      })
    })));
  }));
};

export const DescriptionView = () => {
  const {
    t
  } = useI18n();
  return __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginBottom": "0.4rem"
    }
  }, t("listing-description")), __cssprop(DescriptionList, null, __cssprop(DescriptionFields, null)));
};