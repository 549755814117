function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { useMutation } from "../../../_snowpack/pkg/@apollo/client.js";
import { DatePicker } from "../components/DatePicker.js";
import { Select } from "../components/Select/index.js";
import { Modal } from "../components/Tailwind/Modal.js";
import { NumberTextField, TextField } from "../components/Tailwind/TextField.js";
import { FinalizeRentalRequestV2AndPublishLeaseDocument } from "../graphql/documents.js";
import history from "../history.js";
import useI18n from "../hooks/useI18n.js";
import { useAction } from "../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../store/artifacts/Application/types.js";
import { LEASE_BILLING_SELECT_OPTIONS } from "../utils/unit.js";
import { DateTime } from "../../../_snowpack/pkg/luxon.js";
import * as React from "../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../_snowpack/pkg/react-hook-form.js";
import { toast } from "../../../_snowpack/pkg/react-toastify.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

const FinalizeModal = props => {
  const {
    label,
    rentalApplicationId,
    value
  } = props;
  const {
    t
  } = useI18n();
  const [finalizeRequest, {
    loading: finalizeLoading
  }] = useMutation(FinalizeRentalRequestV2AndPublishLeaseDocument, {
    fetchPolicy: "no-cache"
  });
  const {
    dispatch
  } = useAction();
  const {
    control,
    handleSubmit,
    formState: {
      errors
    },
    setValue,
    watch
  } = useForm({
    mode: "onChange",
    defaultValues: {
      endDate: DateTime.now().plus({
        year: 1
      }).minus({
        day: 1
      }),
      startDate: DateTime.now()
    }
  });

  const onSubmit = async data => {
    const inputData = {
      rentalApplicationId,
      leaseStartDate: data.startDate,
      leaseEndDate: data.endDate,
      billDay: parseInt(data.billDay, 10),
      paymentMethod: data.paymentMethod,
      leasePrice: data.price,
      payeeIds: value?.map(applicant => {
        return applicant.id;
      }) || [],
      amenities: []
    }; // https://github.com/apollographql/apollo-client/issues/5708 known issue in apollo client not being able to handle mutation errors

    try {
      const response = await finalizeRequest({
        variables: {
          input: inputData
        }
      });

      if (response?.errors?.length !== 0) {
        history.push(`/unit/${response.data.finalizeRentalRequestV2AndPublishLease.unit.id}`);
        toast.success(t("applicant-request-form-section-finalize-success"));
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
          payload: null
        });
      }

      if (response.errors) {
        if (JSON.stringify(response.errors).includes("unique")) toast.warn(t("applicant-request-form-section-finalize-conflict"));else toast.warn(t("applicant-request-form-section-finalize-failiure"));
      }
    } catch (e) {
      if (JSON.stringify(e).includes("unique")) toast.warn(t("applicant-request-form-section-finalize-conflict"));else toast.warn(t("applicant-request-form-section-finalize-failiure"));
    }
  };

  const renderChoice = () => {
    return __cssprop("div", {
      css: {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(1.2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(1.2rem * var(--tw-space-y-reverse))"
        }
      }
    }, __cssprop(Controller, {
      name: "billDay",
      control: control,
      rules: {
        required: true,
        minLength: 1
      },
      render: ({
        field
      }) => __cssprop(TextField, _extends({}, field, {
        id: t("unit-lease-info-label-bill-day"),
        label: t("unit-lease-info-label-bill-day"),
        type: "number",
        error: showError(errors, "billDay", t("unit-lease-info-bill-day-invalid"))
      }))
    }), __cssprop(Controller, {
      name: "price",
      control: control,
      rules: {
        required: true,
        minLength: 1
      },
      render: ({
        field
      }) => __cssprop(NumberTextField, _extends({}, field, {
        label: t("unit-lease-info-label-monthly-price"),
        thousandSeparator: true,
        suffix: "$"
      }))
    }), __cssprop(Controller, {
      name: "paymentMethod",
      control: control,
      render: ({
        field
      }) => __cssprop(Select, _extends({}, field, {
        label: t("unit-lease-info-label-payment-method"),
        items: LEASE_BILLING_SELECT_OPTIONS(t),
        selected: LEASE_BILLING_SELECT_OPTIONS(t).find(pm => {
          return pm.value === field.value;
        }),
        onSelect: i => field.onChange(i.value)
      }))
    }), __cssprop(Controller, {
      name: "startDate",
      control: control,
      defaultValue: DateTime.now(),
      render: ({
        field
      }) => __cssprop(DatePicker, _extends({}, field, {
        onChange: v => {
          field.onChange(v);
          setValue("endDate", v.plus({
            year: 1
          }).minus({
            day: 1
          }));
        },
        id: "date-picker",
        error: showError(errors, "startDate", t("company-form-error-start-date")),
        title: t("unit-lease-info-label-radio-start-date")
      }))
    }), __cssprop(Controller, {
      name: "endDate",
      control: control,
      render: ({
        field
      }) => {
        return __cssprop(DatePicker, {
          onChange: field.onChange,
          defaultValue: field.value,
          id: field.name,
          title: t("unit-lease-info-label-radio-end-date"),
          error: showError(errors, "endDate", t("company-form-error-start-date"))
        });
      }
    }));
  };

  return __cssprop(Modal, {
    modalName: "finalizeApplicant",
    title: t("applicant-request-form-section-finalize-request"),
    description: label,
    buttonContent: t("confirm-modal-confirm-button"),
    type: "successful",
    handleSubmit: handleSubmit(onSubmit),
    other: renderChoice()
  });
};

const useFinalizeModal = (label, rentalApplicationId) => {
  const {
    dispatch
  } = useAction();
  const [value, setValue] = React.useState(); // return modal, open toggle,

  return [() => __cssprop(FinalizeModal, {
    value: value,
    rentalApplicationId: rentalApplicationId,
    label: label,
    modalName: "finalizeApplicant"
  }), _value => {
    setValue(_value);
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
      payload: "finalizeApplicant"
    });
  }];
};

export default useFinalizeModal;