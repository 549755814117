import { Button } from "../../components/Tailwind/Button.js";
import { ConsentPicker } from "../../components/Tailwind/ConsentPicker.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ListingActionsTypes } from "../../store/artifacts/Listing/types.js";
import { consentKeyValueObjSelector, consentKeyValueObjInput } from "../../utils/consent.js";
import React, { useCallback } from "../../../../_snowpack/pkg/react.js";
import { useFieldArray, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { DescriptionView } from "./panes/DescriptionView.js";
import { DetailsView } from "./panes/DetailsView.js";
import { FeaturedImage } from "./panes/FeaturedImage.js";
import { MediaView } from "./panes/MediaView.js";
import { PanelNotesView } from "./panes/PanelNotesView.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const getConsentInKeyValue = (t, consent) => {
  return consent.map(c => {
    return {
      label: consentKeyValueObjSelector(t)[c.consentType],
      value: c.consentType
    };
  });
};

export const Listing = () => {
  const {
    dispatch
  } = useAction();
  const canSubmit = useSelector(Selectors.listing.isReadyToSubmit);
  const consent = useSelector(Selectors.listing.getConsent);
  const isSubmiting = useSelector(Selectors.status.isSubmiting("listing"));
  const {
    t
  } = useI18n();
  const {
    control,
    register,
    handleSubmit,
    watch
  } = useForm();
  const {
    fields,
    remove,
    append
  } = useFieldArray({
    // : { fields: FieldItem[], remove }
    control,
    // control props comes from useForm (optional: if you are using FormContext)
    name: "consent" // unique name for your Field Array

  }); // everytime that consent in saga changes, update the useForm array.
  // useEffect(() => {
  //   remove(); // remove all consents from field array
  //   // recreate field array with newly passed in consents
  //   append(
  //     consent.map((c) => {
  //       return { label: consentKeyValueObjInput(t)[c.consentType], cid: c.consentType, value: c.consentValue };
  //     })
  //   );
  // }, [consent]);
  // on change append consent or remove if already exists in field array

  const handleOnChange = useCallback(e => {
    if (e.length !== 0) {
      const label = consentKeyValueObjInput(t)[e[0].value];
      const cid = e[0].value; // if item is found in field array then remove it

      const fieldIndex = fields.findIndex(f => f.cid === cid);

      if (fieldIndex >= 0) {
        remove(fieldIndex);
      } else {
        // else append it
        append({
          label,
          cid
        });
      }
    }
  }, [fields]); // useEffect(() => {
  //   const itemToRemove = fields.filter((item1) => !selectedItems.some((item2) => (item1 as any).cid === item2.value));
  //   let indexItemToRemove;
  //   if (itemToRemove.length !== 0) {
  //     indexItemToRemove = fields.findIndex((x) => x.id === (itemToRemove[0] as any).cid);
  //   }
  //   if (fields.length > selectedItems.length && indexItemToRemove) {
  //     remove(indexItemToRemove);
  //   }
  // }, [selectedItems]);

  const onSubmit = data => {
    dispatch({
      type: ListingActionsTypes.LISTING_SAVE_FORM,
      payload: data
    });
  };

  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit)
  }, __cssprop(FeaturedImage, null), __cssprop(DetailsView, null), __cssprop(DescriptionView, null), __cssprop(MediaView, null), __cssprop(ConsentPicker, {
    handleOnChange: handleOnChange,
    selectedItems: [],
    fields: fields,
    register: register,
    defautValueSelectedItems: getConsentInKeyValue(t, consent)
  }), __cssprop(PanelNotesView, {
    isLocked: false
  }), __cssprop("div", {
    css: {
      "display": "flex",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem",
      "marginTop": "1.6rem"
    }
  }, __cssprop(Button, {
    submit: true,
    loading: isSubmiting,
    disabled: !canSubmit,
    primary: true
  }, t("save")))));
};
export default Listing;