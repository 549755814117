function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import useI18n from "../../../hooks/useI18n.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { useFormContext } from "../../../../../_snowpack/pkg/react-hook-form.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const NoteField = () => {
  const {
    t
  } = useI18n();
  const {
    register
  } = useFormContext();
  return __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "marginTop": "0.8rem",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between"
    }
  }, __cssprop("textarea", _extends({}, register("note"), {
    name: "note",
    rows: 3,
    css: {
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
      },
      "marginTop": "0.4rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.6rem",
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    },
    placeholder: t("notes-title")
  }))));
};