import useI18n from "../../hooks/useI18n.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { getEmailSubject, getEmailTemplate, TemplateType } from "../../utils/emailTemplate.js";
import { Selector } from "./Selector.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const EmailTemplateSelector = props => {
  const {
    t
  } = useI18n();
  const options = [{
    label: t("selector-label-snow-removal"),
    value: TemplateType.SnowRemoval
  }, {
    label: t("selector-label-air-conditioning"),
    value: TemplateType.AirConditioning
  }, {
    label: t("selector-label-de-ice"),
    value: TemplateType.DeIce
  }, {
    label: t("selector-label-exterminator"),
    value: TemplateType.Exterminator
  }, {
    label: t("selector-label-office-closing"),
    value: TemplateType.OfficeClosing
  }, {
    label: t("selector-label-rental-agent"),
    value: TemplateType.RentalAgent
  }, {
    label: t("selector-label-smoke-detector"),
    value: TemplateType.SmokeDetectors
  }];
  const [selectedOption, setSelectedOption] = useState();

  const handleChange = value => {
    setSelectedOption(value);
    props.changeContent(getEmailTemplate(value.value));
    props.changeSubject(getEmailSubject(value.value));
  };

  return __cssprop(Selector, {
    label: t("selector-label"),
    options: options,
    onChange: handleChange,
    selectedItem: selectedOption,
    isMulti: false,
    isClearable: false,
    placeholder: t("selector-label")
  });
};