import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../../_snowpack/pkg/react.js";
import { ExclamationIcon, InformationCircleIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { statusColor } from "./sharedStyles.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const Container = _styled.div(({
  type,
  inList
}) => [{
  "padding": "1.6rem"
}, !inList && {
  "borderWidth": "1px",
  "borderRadius": "0.6rem",
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
}, type === "warning" && statusColor.warning, type === "critical" && statusColor.critical, type === "info" && statusColor.info]);

const TextContainer = _styled.div(({
  type
}) => [{
  "fontSize": "1.4rem",
  "lineHeight": "2rem"
}, type === "warning" && {
  "--tw-text-opacity": "1",
  "color": "rgba(180, 83, 9, var(--tw-text-opacity))"
}, type === "critical" && {
  "--tw-text-opacity": "1",
  "color": "rgba(185, 28, 28, var(--tw-text-opacity))"
}, type === "info" && {
  "--tw-text-opacity": "1",
  "color": "rgba(29, 78, 216, var(--tw-text-opacity))"
}]);

const getIcon = type => {
  if (type === "info") {
    return __cssprop(InformationCircleIcon, {
      css: {
        "height": "2rem",
        "width": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(96, 165, 250, var(--tw-text-opacity))"
      },
      "aria-hidden": "true"
    });
  }

  if (type === "critical") {
    return __cssprop(ExclamationIcon, {
      css: {
        "height": "2rem",
        "width": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(248, 113, 113, var(--tw-text-opacity))"
      },
      "aria-hidden": "true"
    });
  }

  return __cssprop(ExclamationIcon, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(251, 191, 36, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  });
};

export const Alert = ({
  title,
  description,
  type,
  inList = false
}) => {
  return __cssprop(Container, {
    type: type,
    inList: inList
  }, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "flexShrink": "0"
    }
  }, getIcon(type)), __cssprop("div", {
    css: {
      "marginLeft": "1.2rem"
    }
  }, __cssprop(TextContainer, {
    type: type
  }, title, description && __cssprop("ul", {
    css: {
      "listStyleType": "disc",
      "paddingLeft": "2rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "paddingTop": "0.4rem"
    }
  }, description.map(element => {
    return __cssprop("li", {
      key: "element"
    }, element);
  }))))));
};