/* eslint-disable no-underscore-dangle */
import { BuildingCard, UnitsView } from "../../components/index.js";
import { Loader } from "../../components/Loader.js";
import { UnitPanel } from "../../components/Panels/UnitPanel.js";
import { AmenitiesTagCloud } from "../../components/Tailwind/Amenities/AmenitiesTagCloud.js";
import { Badge } from "../../components/Tailwind/Badge.js";
import { Card } from "../../components/Tailwind/Card.js";
import { Layout } from "../../components/Tailwind/Layout.js";
import { Notes } from "../../components/Tailwind/Notes.js";
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedActions, ProtectedTab } from "../../components/Tailwind/ProtectedComponents.js";
import TicketInfoRelatedTickets from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoRelatedTickets.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import { RESOURCE, useDeleteModal } from "../../forms/useDeleteModal.js";
import { useFeatureToggle } from "../../hooks/useFeatureToggle.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction, useSelector } from "../../hooks/useRedux.js";
import { Selectors } from "../../store/index.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { BuildingActionsTypes } from "../../store/artifacts/Building/types.js";
import { LeaseActionsTypes } from "../../store/artifacts/Lease/types.js";
import { UnitActionsTypes } from "../../store/artifacts/Unit/types.js";
import { getFeatureFlagsTabs } from "../../utils/index.js";
import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { batch } from "../../../../_snowpack/pkg/react-redux.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const BuildingInfo = withBreadcrumb(({
  history,
  match,
  setCrumb,
  crumbs
}) => {
  const {
    t
  } = useI18n();
  const {
    id
  } = match.params;
  const role = useSelector(Selectors.session.getUserRole);
  const buildingId = useSelector(Selectors.building.getId);
  const loading = useSelector(Selectors.status.isLoading("building"));
  const civicNumber = useSelector(Selectors.building.getCivicNumber);
  const streetName = useSelector(Selectors.building.getStreetName);
  const tags = useSelector(Selectors.building.getTags);
  const image = useSelector(Selectors.building.getImage);
  const tickets = useSelector(Selectors.building.getTickets);
  const [selectedTab, setSelectedTab] = useState(0);
  const units = useSelector(Selectors.building.getUnits);
  const {
    dispatch
  } = useAction();
  const paymentEnable = useFeatureToggle("payment");
  const [DeleteModal, _openDeleteModal, deleteModalAction] = useDeleteModal(id, RESOURCE.Building);
  const [DeleteLeaseModal, openDeleteLeaseModal] = useDeleteModal(undefined, RESOURCE.Lease);
  useEffect(() => {
    if (buildingId !== id || !buildingId) {
      dispatch({
        type: BuildingActionsTypes.BUILDING_INITIALIZE,
        payload: {
          kind: "WithBuildingId",
          buildingId: id
        }
      });
    }
  }, []);
  useEffect(() => {
    if (!loading && civicNumber && streetName) {
      setCrumb({
        title: `${civicNumber} ${streetName}`,
        path: `/buildings/${buildingId}`,
        level: 1
      });
    }
  }, [loading, civicNumber, streetName, buildingId]);

  if (loading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  const fullBuildingAddress = `${civicNumber} ${streetName}`;

  const getTags = () => tags.map((tag, index) => __cssprop(Badge, {
    key: index
  }, tag));

  const getActions = () => {
    return [ProtectedActions(role, [{
      name: "modify-building",
      content: t("building-info-modify-building"),
      onAction: () => {
        dispatch({
          type: BuildingActionsTypes.BUILDING_INITIALIZE,
          payload: {
            kind: "WithBuildingId",
            buildingId: id
          }
        });
        history.push("/building");
      },
      permission: {
        kind: "Building",
        permission: "mutate:update"
      }
    }, { ...deleteModalAction,
      permission: {
        kind: "Building",
        permission: "mutate:delete"
      },
      onAction: () => {
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
          payload: "Building"
        });
      },
      content: t("delete-building")
    }, {
      content: t("building-info-add-unit"),
      onAction: () => {
        batch(() => {
          dispatch({
            type: UnitActionsTypes.UNIT_INITIALIZE,
            payload: null
          });
          dispatch({
            type: UnitActionsTypes.UNIT_SET_FIELD,
            payload: {
              buildingId
            }
          });
          dispatch({
            type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
            payload: "unit"
          });
        });
      },
      permission: {
        kind: "Unit",
        permission: "mutate:create"
      }
    }, {
      content: t("building-info-add-lease"),
      onAction: () => {
        dispatch({
          type: LeaseActionsTypes.LEASE_INITIALIZE_WITH_BUILDING,
          payload: id
        });
        history.push("/lease");
      },
      disabled: units?.length === 0,
      permission: {
        kind: "Lease",
        permission: "mutate:create"
      }
    }])];
  };

  const onDoneSubmit = data => {
    dispatch({
      type: UnitActionsTypes.UNIT_SUBMIT,
      payload: { ...data,
        buildingId
      }
    });
  };

  const tabs = ProtectedTab(role, getFeatureFlagsTabs([{
    content: t("building"),
    id: "building",
    permission: {
      kind: "Building",
      permission: "view:info"
    }
  }], [{
    content: t("payments"),
    id: "payments",
    permission: {
      kind: "Payement",
      permission: "view:list"
    }
  }], paymentEnable));
  return __cssprop(Page, {
    title: fullBuildingAddress,
    titleMetadata: getTags(),
    thumbnail: image,
    breadcrumbs: crumbs,
    secondaryActions: {
      content: t("widget-other"),
      actions: getActions()
    }
  }, __cssprop(BuildingView, {
    history: history,
    openDeleteLeaseModal: openDeleteLeaseModal
  }), __cssprop(UnitPanel, {
    formTitle: t("unit-modify-unit"),
    description: t("unit-info-section-description"),
    sectionTitle: t("unit-info-section-title"),
    onDoneSubmit: onDoneSubmit
  }), __cssprop(DeleteLeaseModal, null), __cssprop(DeleteModal, null));
});

const BuildingView = ({
  history,
  openDeleteLeaseModal
}) => {
  const {
    t
  } = useI18n();
  const amenities = useSelector(Selectors.building.getAmenities);
  const notes = useSelector(Selectors.building.getFilteredNotes);
  const buildingId = useSelector(Selectors.building.getId);
  const tickets = useSelector(Selectors.building.getTickets);
  const units = useSelector(Selectors.building.getUnits);
  const role = useSelector(Selectors.session.getUserRole);
  const {
    dispatch
  } = useAction();
  const noteLoading = useSelector(Selectors.status.isSubmiting("note"));
  return __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop(UnitsView, {
    history: history,
    units: units,
    t: t
  })), __cssprop(Layout, {
    oneThird: true
  }, __cssprop(BuildingCard, null), amenities.length !== 0 && __cssprop(AmenitiesTagCloud, {
    title: t("amenities-title"),
    list: amenities
  }), tickets.length > 0 && __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(TicketInfoRelatedTickets, {
    data: tickets
  }))), __cssprop(Notes, {
    notes: notes,
    loading: noteLoading,
    permission: {
      kind: "Building",
      permission: "view:notes"
    },
    addNoteEvent: pNotes => {
      dispatch({
        payload: {
          resourceId: buildingId,
          resourceType: "building",
          actionType: BuildingActionsTypes.BUILDING_ADD_NOTES,
          content: pNotes.note,
          fromPage: true
        },
        type: BuildingActionsTypes.BUILDING_ADD_NOTES_FORMLESS
      });
    },
    hideNewNote: role === "Client" || role === "User",
    removeNoteEvent: pNotes => {
      dispatch({
        payload: {
          noteId: pNotes.id,
          actionType: BuildingActionsTypes.BUILDING_REMOVE_NOTES,
          fromPage: true
        },
        type: BuildingActionsTypes.BUILDING_REMOVE_NOTES_FORMLESS
      });
    }
  })));
};