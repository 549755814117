import React, { useEffect, useRef, useState } from "../../../../_snowpack/pkg/react.js";
import { useParams } from "../../../../_snowpack/pkg/react-router.js";
import RRuleGenerator from "../../../../_snowpack/pkg/react-rrule-generator.js";
import Select from "../../../../_snowpack/pkg/react-select.js";
import "../../../../_snowpack/pkg/react-rrule-generator/build/styles.css";
import debounce from "../../../../_snowpack/pkg/lodash.debounce.js";
import { PlusSmIcon, PencilAltIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { StyledButton } from "../../components/Tailwind/Button.js";
import { useAction, useSelector } from "../../hooks/useRedux.js";
import { Selectors } from "../../store/index.js";
import { EventActionTypes } from "../../store/artifacts/Events/types.js";
import { typeOfEntities, typeOfTickets } from "../../utils/event.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { SimpleSelector } from "../../components/TicketInfo/TicketInfoSidebar/SimpleSelector.js";
import { GetTicketsDocument } from "../../graphql/documents.js";
import { TicketActionTypes } from "../../store/artifacts/Tickets/types.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import useI18n from "../../hooks/useI18n.js";
import EventTicketSideBar, { StyledItems, StyledItemsLabel } from "./EventTicketSideBar.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const entitiesForms = (t, type, subType, action, defaultValue) => {
  switch (type) {
    case "TicketGQL":
      if (subType === "new") {
        return __cssprop(EventTicketSideBar, null);
      }

      if (subType === "existing") {
        return __cssprop(StyledItems, null, __cssprop(StyledItemsLabel, null, t("tickets-title")), __cssprop(SimpleSelector, {
          labelPattern: "title",
          query: GetTicketsDocument,
          onChange: action,
          label: t("tickets-title"),
          name: ["ticket"],
          isClearable: true,
          defaultValue: defaultValue,
          disabled: Boolean(defaultValue),
          additionalFilterCallback: val => {
            return !val.isRecurring;
          }
        }));
      }

      return null;

    default:
      return null;
  }
};

const AddEventSideBar = () => {
  const {
    t
  } = useI18n();
  const [typeOfEntity, setTypeOfEntity] = useState();
  const [typeOfTicket, setTypeOfTicket] = useState();
  const {
    id: pageId
  } = useParams();
  const {
    dispatch
  } = useAction();
  const eventId = useSelector(Selectors.event.getId);
  const title = useSelector(Selectors.event.getTitle);
  const description = useSelector(Selectors.event.getDescription);
  const RRule = useSelector(Selectors.event.getRRule);
  const entityMetadata = useSelector(Selectors.event.getEntityMetadata);
  const ticketId = useSelector(Selectors.ticket.getId);
  const rruleRef = useRef(null);
  const debounced = debounce((value, id) => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        [id]: value
      }
    });

    if (id === "title") {
      dispatch({
        type: EventActionTypes.EVENT_SET_FIELD,
        payload: {
          entity: { ...entityMetadata,
            [id]: id === "title" ? value : value?.value
          }
        }
      });
    }

    if (pageId && !ticketId) {
      dispatch({
        type: EventActionTypes.EVENT_UPDATE,
        payload: {
          [id]: value
        }
      });
    }
  }, 300);

  const handleChange = v => {
    const {
      value,
      id
    } = v;
    debounced(value, id);
  };

  const handleSelectTypeOfResource = e => {
    setTypeOfEntity(e.value);
  };

  const handleSelectTypeOfTicket = e => {
    setTypeOfTicket(e.value);
  };

  const handleSelectTicket = e => {
    if (e) {
      dispatch({
        type: EventActionTypes.EVENT_SET_FIELD,
        payload: {
          entity: {
            id: e.id
          }
        }
      });
    }
  };

  const handleRruleChange = rrule => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        RRule: rrule
      }
    });
  };

  const handleSubmitEvent = () => {
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });

    if (ticketId) {
      dispatch({
        type: TicketActionTypes.TICKET_SET_FIELD,
        payload: {
          isRecurring: true
        }
      });
    }

    if (typeOfTicket === "new") {
      dispatch({
        type: eventId ? EventActionTypes.EVENT_UPDATE : EventActionTypes.EVENT_CREATE
      });
    } else {
      dispatch({
        type: eventId ? EventActionTypes.EVENT_UPDATE : EventActionTypes.EVENT_CREATE_TICKET
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: {
        isCreatingTicket: false
      }
    });

    if (ticketId) {
      setTypeOfTicket("existing");
      dispatch({
        type: EventActionTypes.EVENT_SET_FIELD,
        payload: {
          entity: {
            id: ticketId
          }
        }
      });
    }

    setTypeOfEntity(!ticketId ? entityMetadata?.__typename : "TicketGQL");

    if (!RRule || !rruleRef || !rruleRef.current) {
      dispatch({
        type: EventActionTypes.EVENT_SET_FIELD,
        payload: {
          RRule: rruleRef.current?.state?.rrule
        }
      });
    }

    return () => dispatch({
      type: [EventActionTypes.EVENT_SET_INITIAL_STATE]
    });
  }, []); // if (!entityMetadata) {
  //   return null;
  // }

  const isCreatePossible = RRule && (entityMetadata?.category && entityMetadata?.priority && entityMetadata?.resource || entityMetadata?.id || ticketId);
  return __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "justifyContent": "space-between",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.2rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(TextField, {
    id: "title",
    type: "text",
    defaultValue: title,
    onChange: v => handleChange({
      value: v,
      id: "title"
    }),
    placeholder: t("event-title")
  }), __cssprop(TextField, {
    id: "description",
    type: "text",
    defaultValue: description,
    onChange: v => handleChange({
      value: v,
      id: "description"
    }),
    placeholder: t("description")
  })), __cssprop(StyledItems, null, __cssprop(StyledItemsLabel, null, t("type-entity")), __cssprop(Select, {
    label: t("type-entity"),
    isMulti: false,
    placeholder: t("type-entity"),
    onChange: handleSelectTypeOfResource,
    options: typeOfEntities,
    menuContainerStyle: {
      zIndex: 25
    },
    value: typeOfEntities.find(item => item.value === typeOfEntity),
    isDisabled: Boolean(ticketId)
  })), !eventId && typeOfEntity === "TicketGQL" && __cssprop(StyledItems, null, __cssprop(StyledItemsLabel, null, t("type-ticket")), __cssprop(Select, {
    label: t("type-ticket"),
    isMulti: false,
    placeholder: t("type-ticket"),
    onChange: handleSelectTypeOfTicket,
    options: typeOfTickets,
    menuContainerStyle: {
      zIndex: 25
    },
    value: typeOfTickets.find(item => item.value === typeOfTicket),
    isDisabled: Boolean(ticketId)
  })), entitiesForms(t, typeOfEntity, eventId ? "new" : typeOfTicket, handleSelectTicket, ticketId), __cssprop(RRuleGenerator, {
    onChange: handleRruleChange,
    config: {
      hideStart: false,
      frequency: ["Yearly", "Monthly", "Weekly", "Daily"]
    },
    value: RRule,
    ref: rruleRef
  }), (!pageId || ticketId) && __cssprop(StyledButton, {
    css: {
      "display": "flex",
      "alignItems": "center"
    },
    onClick: handleSubmitEvent,
    disabled: !isCreatePossible,
    primary: true
  }, eventId ? __cssprop(PencilAltIcon, {
    css: {
      "display": "block",
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      },
      "marginRight": "0.4rem"
    },
    "aria-hidden": "true"
  }) : __cssprop(PlusSmIcon, {
    css: {
      "display": "block",
      "height": "2.4rem",
      "width": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }), __cssprop("h2", null, eventId ? t("modify") : t("add"))));
};

export default AddEventSideBar;