import _styled from "../../../../../_snowpack/pkg/@emotion/styled.js";

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMutation } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { ChevronRightIcon } from "../../../../../_snowpack/pkg/@heroicons/react/solid.js";
import { Button } from "../../../components/Tailwind/Button.js";
import { FileDropZone } from "../../../components/Tailwind/FileDropZone.js";
import { SetFilesDocument } from "../../../graphql/documents.js";
import { TYPES, uploadDocumentsNoneSaga } from "../../../helpers/files.js";
import useI18n from "../../../hooks/useI18n.js";
import { useAction } from "../../../hooks/useRedux.js";
import { LeaseActionsTypes } from "../../../store/artifacts/Lease/types.js";
import { mapFiles } from "../../../store/utils/Files.js";
import { applicantv2FileCategory } from "../../../utils/applicant.js";
import React, { useCallback, useEffect, useState } from "../../../../../_snowpack/pkg/react.js";
import { toast } from "../../../../../_snowpack/pkg/react-toastify.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
const SECTIONS = ["signature_required", "other", "lease", "insurance", "utility", "building_regulation", "check"];

const StyledDiv = _styled.div(({
  current
}) => [{
  "display": "flex",
  "cursor": "pointer",
  "userSelect": "none",
  "alignItems": "center",
  "borderRadius": "0.6rem",
  "padding": "0.8rem"
}, current ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
  "--tw-text-opacity": "1",
  "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
} : ""]);

export const FileDropper = ({
  refetch,
  resourceId,
  resourceType,
  files: pFile,
  readOnly = false,
  onDropDispatch = null,
  onDeleteDispatch = null,
  withSubmitButton = false
}) => {
  const {
    t
  } = useI18n();
  const [files, setFiles] = useState(pFile);
  const [currentSection, setCurrentSection] = useState("signature_required");
  const [setFilesMutation, {
    loading: filesLoading
  }] = useMutation(SetFilesDocument, {
    fetchPolicy: "no-cache"
  });
  const {
    dispatch
  } = useAction(); // this is a big plaster until we rewrite the platform

  const handleOnSubmit = useCallback(async () => {
    try {
      const response = await setFilesMutation({
        variables: {
          files: {
            files: await uploadDocumentsNoneSaga(files, resourceType, TYPES.Files),
            resourceId,
            resourceType
          }
        }
      });

      if (!response.data) {
        toast.warn(`${t("file-form-saved-fail")}`);
      }

      if (refetch) {
        refetch();
      }

      if (resourceType === "lease") {
        dispatch({
          type: LeaseActionsTypes.LEASE_SET_FIELD,
          payload: {
            files: mapFiles(response.data.setFiles)
          }
        });
      }

      toast.success(`${t("file-form-saved-success")}`);
    } catch {
      toast.warn(`${t("file-form-saved-fail")}`);
    }
  }, [files]);
  const onFileDrop = useCallback(async pFiles => {
    const mappedFiles = pFiles.map(file => {
      return { ...file,
        fileCategory: currentSection,
        kind: "local"
      };
    });
    setFiles(prev => {
      return [...prev, ...mappedFiles];
    });
  }, [currentSection, resourceId, resourceType]);
  const onFileDelete = useCallback(async fileToDelete => {
    let ff = [];
    setFiles(prev => {
      ff = prev.filter(file => file.fileName !== fileToDelete.fileName);
      return ff;
    });
  }, [resourceId, resourceType]);
  useEffect(() => {
    if (withSubmitButton) return;

    const upload = async () => {
      // will do try catch because of a knowed issue from apollo
      try {
        const response = await setFilesMutation({
          variables: {
            files: {
              files: await uploadDocumentsNoneSaga(files, resourceType, TYPES.Files),
              resourceId,
              resourceType
            }
          }
        });

        if (!response.data) {
          toast.warn(`${t("file-form-saved-fail")}`);
        }

        if (refetch) {
          refetch();
        }
      } catch {
        toast.warn(`${t("file-form-saved-fail")}`);
      }
    };

    if (!onDropDispatch && !onDeleteDispatch) {
      upload();
    }
  }, [files]);
  return __cssprop("div", {
    css: {
      "overflowY": "auto"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-x-reverse": 0,
        "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
        "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(243, 244, 246, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("div", {
    css: {
      "maxHeight": "38.4rem",
      "minWidth": "0px",
      "flex": "1 1 auto",
      "overflowY": "auto",
      "paddingLeft": "2.4rem",
      "paddingRight": "2.4rem",
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem"
    }
  }, __cssprop("h2", {
    css: {
      "marginTop": "0.8rem",
      "marginBottom": "1.6rem",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "File type"), __cssprop("div", {
    css: {
      "marginLeft": "-0.8rem",
      "marginRight": "-0.8rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, SECTIONS.map((name, index) => {
    return __cssprop(StyledDiv, {
      key: index,
      css: {
        "display": "flex",
        "cursor": "pointer",
        "userSelect": "none",
        "alignItems": "center",
        "borderRadius": "0.6rem",
        "padding": "0.8rem"
      },
      onClick: () => setCurrentSection(name),
      current: currentSection === name
    }, __cssprop("span", {
      css: {
        "marginLeft": "1.2rem",
        "flex": "1 1 auto",
        "overflow": "hidden",
        "textOverflow": "ellipsis",
        "whiteSpace": "nowrap"
      }
    }, applicantv2FileCategory(t)[name], " (", onDropDispatch && onDeleteDispatch ? pFile.filter(file => file.fileCategory === name).length : files.filter(file => file.fileCategory === name).length, ")"), currentSection === name && __cssprop(ChevronRightIcon, {
      css: {
        "marginLeft": "1.2rem",
        "height": "2rem",
        "width": "2rem",
        "flex": "none",
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      },
      "aria-hidden": "true"
    }));
  }))), __cssprop("div", {
    css: {
      "display": "none",
      "height": "38.4rem",
      "width": "75%",
      "flex": "none",
      "flexDirection": "column",
      "overflowY": "auto",
      "padding": "4rem",
      "@media (min-width: 640px)": {
        "display": "flex"
      }
    }
  }, withSubmitButton && __cssprop("div", {
    css: {
      "display": "flex",
      "justifyContent": "flex-end",
      "paddingBottom": "2rem"
    }
  }, __cssprop(Button, {
    primary: true,
    onAction: handleOnSubmit
  }, t("submit"))), __cssprop(FileDropZone, {
    fileCategory: currentSection,
    files: onDropDispatch && onDeleteDispatch ? pFile.filter(file => file.fileCategory === currentSection) : files.filter(file => file.fileCategory === currentSection),
    inForm: !onDeleteDispatch && !onDropDispatch,
    onFilesDrop: onFileDrop,
    onFilesDelete: onFileDelete,
    onDeleteDispatch: onDeleteDispatch,
    onDropDispatch: onDropDispatch,
    readOnly: readOnly
  }))));
};
export default FileDropper;