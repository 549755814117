import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import useI18n from "../../hooks/useI18n.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useCallback, useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const GeneratePaging = (currentPage, totalPage, setPage) => {
  const buttons = [];
  const lowest = Math.max(currentPage - 2, 1); // console.log(lowest);

  const highest = Math.min(currentPage + 2, totalPage);

  for (let i = lowest; i <= highest; i++) {
    i === currentPage ? buttons.push(__cssprop("button", {
      key: i,
      type: "button",
      "aria-current": "page",
      css: {
        "zIndex": "20",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(238, 242, 255, var(--tw-bg-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
        "position": "relative",
        "display": "inline-flex",
        "alignItems": "center",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem",
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "borderWidth": "1px",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "cursor": "pointer"
      }
    }, i)) : buttons.push(__cssprop("button", {
      key: i,
      type: "button",
      "aria-current": "page",
      onClick: () => setPage(i),
      css: {
        "zIndex": "10",
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        "position": "relative",
        "display": "inline-flex",
        "alignItems": "center",
        "paddingLeft": "1.2rem",
        "paddingRight": "1.2rem",
        "paddingTop": "0.8rem",
        "paddingBottom": "0.8rem",
        "borderWidth": "1px",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "fontWeight": "500",
        "cursor": "pointer"
      }
    }, i));
  }

  return buttons;
};

export const Pagination = ({
  totalItems,
  pageSize,
  onPageChange,
  resourceLabel,
  onTabChange,
  currentPage: pCurrentPage
}) => {
  const {
    t
  } = useI18n();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / pageSize);
  const hasPrevious = totalPages >= currentPage && currentPage !== 1;
  const hasNext = totalPages > currentPage;
  const onNext = useCallback(() => {
    const value = currentPage + 1;
    setCurrentPage(value);
    onPageChange(value);
  }, [currentPage]);
  const onPrevious = useCallback(() => {
    const value = currentPage - 1;
    setCurrentPage(value);
    onPageChange(value);
  }, [currentPage]);
  const onFirst = useCallback(() => {
    setCurrentPage(1);
    onPageChange(1);
  }, []);
  const onLast = useCallback(() => {
    setCurrentPage(totalPages);
    onPageChange(totalPages);
  }, [totalPages]);
  const gotoPage = useCallback(selectedPage => {
    setCurrentPage(selectedPage);
    onPageChange(selectedPage);
  }, []);
  useEffect(() => {
    setCurrentPage(1);
    onPageChange(1);
  }, [onTabChange]);
  useEffect(() => {
    setCurrentPage(pCurrentPage);
  }, [pCurrentPage]);
  return __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "1.2rem",
      "paddingBottom": "1.2rem",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "display": "flex",
      "justifyContent": "space-between",
      "@media (min-width: 640px)": {
        "display": "none"
      }
    }
  }, hasPrevious && __cssprop("button", {
    onClick: onPrevious,
    type: "button",
    css: {
      "position": "relative",
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "borderRadius": "0.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      },
      "cursor": "pointer"
    }
  }, t("previous")), hasNext && __cssprop("button", {
    onClick: onNext,
    type: "button",
    css: {
      "marginLeft": "1.2rem",
      "position": "relative",
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "borderRadius": "0.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }
  }, t("next"))), __cssprop("div", {
    css: {
      "display": "none",
      "@media (min-width: 640px)": {
        "flex": "1 1 0%",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between"
      }
    }
  }, __cssprop("div", null, __cssprop("p", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Showing page ", __cssprop("span", {
    css: {
      "fontWeight": "500"
    }
  }, currentPage === 1 ? `${totalPages === 0 ? "0" : "1"}` : (currentPage - 1) * pageSize), " to", " ", __cssprop("span", {
    css: {
      "fontWeight": "500"
    }
  }, pageSize * currentPage > totalItems ? totalItems : pageSize * currentPage), " of", " ", __cssprop("span", {
    css: {
      "fontWeight": "500"
    }
  }, totalItems), " ", totalItems > 1 ? resourceLabel.plural : resourceLabel.singular)), __cssprop("div", null, __cssprop("nav", {
    css: {
      "position": "relative",
      "zIndex": "0",
      "display": "inline-flex",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(-1px * var(--tw-space-x-reverse))",
        "marginLeft": "calc(-1px * calc(1 - var(--tw-space-x-reverse)))"
      }
    },
    "aria-label": "Pagination"
  }, hasPrevious && __cssprop("button", {
    type: "button",
    onClick: onFirst,
    css: {
      "position": "relative",
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "0.4rem",
      "paddingRight": "0.4rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderTopLeftRadius": "0.6rem",
      "borderBottomLeftRadius": "0.6rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, t("previous")), __cssprop(ChevronDoubleLeftIcon, {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  })), hasPrevious && __cssprop("button", {
    type: "button",
    onClick: onPrevious,
    css: {
      "position": "relative",
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, t("previous")), __cssprop(ChevronLeftIcon, {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  })), GeneratePaging(currentPage, totalPages, gotoPage), hasNext && __cssprop("button", {
    type: "button",
    onClick: onNext,
    css: {
      "position": "relative",
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, t("next")), __cssprop(ChevronRightIcon, {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  })), hasNext && __cssprop("button", {
    type: "button",
    onClick: onLast,
    css: {
      "position": "relative",
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "0.4rem",
      "paddingRight": "0.4rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderTopRightRadius": "0.6rem",
      "borderBottomRightRadius": "0.6rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, t("next")), __cssprop(ChevronDoubleRightIcon, {
    css: {
      "height": "2rem",
      "width": "2rem"
    },
    "aria-hidden": "true"
  }))))));
};