import { ProtectedNavigationItem } from "../../../components/Tailwind/ProtectedComponents.js";
import { hasPermissionLevel, PermissionTypeLevel } from "../../../hooks/usePermission.js";
import { FaReceipt, FaTags, FaTicketAlt, FaUser } from "../../../../../_snowpack/pkg/react-icons/fa.js";
import { HiClipboardList, HiOfficeBuilding, HiSpeakerphone, HiUsers } from "../../../../../_snowpack/pkg/react-icons/hi.js";
import { IoMdExit, IoMdHelpCircle } from "../../../../../_snowpack/pkg/react-icons/io.js";
import { RiSettings3Fill } from "../../../../../_snowpack/pkg/react-icons/ri.js";
import { FiCalendar } from "../../../../../_snowpack/pkg/react-icons/fi.js";
import { createSelector } from "../../../../../_snowpack/pkg/reselect.js";
import { getUserRole } from "../Session/selectors.js";
import { SessionActionsTypes } from "../Session/types.js";
import reducer from "./reducers.js";
import sagas from "./sagas.js";

// Private accessors
const getStore = state => state?.app;

const DEFAULT_SEARCH = {
  resultsPerPage: 25,
  page: 0,
  query: "",
  filter: null,
  searchMethod: "fuzzy"
};
export const getSearches = state => getStore(state)?.search;
export const getFeatures = state => getStore(state)?.features;
export default {
  sagas: sagas.executers,
  reducer,
  selectors: {
    getFeatures,
    getDrawerState: state => getStore(state).showDrawer,
    getModalState: state => getStore(state).showModal,
    getModalStatus: state => getStore(state).confirmModalStatus,
    getCrumbs: state => getStore(state).crumbs,
    getProjectId: state => "",
    // todo: REVISE WHY THIS IS USED IN DELETE MODAL?
    getRememberMe: state => !!getStore(state).rememberMe,
    getRememberMeEmail: state => getStore(state)?.rememberMe,
    getSearchByName: name => createSelector(getSearches, searches => {
      return searches && searches[name] ? searches[name] : DEFAULT_SEARCH;
    }),
    getSideBarNavigationItem: (t, history) => createSelector(getFeatures, getUserRole, (features, role) => {
      const items = ProtectedNavigationItem(role, [// {
      //   label: t("dashboard-title"),
      //   kind: "dashboard",
      //   icon: HiChartPie,
      //   onClick: () => history.push("/dashboard"),
      // },
      {
        label: t("properties"),
        kind: "properties",
        icon: HiOfficeBuilding,
        onClick: () => history.push("/properties"),
        permission: {
          kind: "Building",
          permission: "view:list"
        }
      }, {
        label: t("marketing-title"),
        kind: "marketing",
        icon: HiSpeakerphone,
        onClick: () => history.push("/marketing"),
        permission: {
          kind: "Listing",
          permission: "view:list"
        }
      }, {
        label: t("tenants"),
        kind: "tenant",
        icon: HiUsers,
        onClick: () => history.push("/tenants"),
        permission: {
          kind: "Tenant",
          permission: "view:list"
        }
      }, // {
      //   label: t("communications"),
      //   kind: "communication",
      //   icon: TiMessage,
      //   onClick: () => history.push("/communications"),
      // },
      {
        label: t("leases"),
        kind: "leases",
        icon: HiClipboardList,
        onClick: () => history.push("/leases"),
        permission: {
          kind: "Lease",
          permission: "view:list"
        }
      }, {
        label: t("finances"),
        kind: "deposit",
        icon: FaReceipt,
        onClick: () => history.push("/deposits"),
        permission: {
          kind: "Deposit",
          permission: "view:list"
        }
      }, {
        label: t("projects"),
        kind: "project",
        icon: FaTags,
        onClick: () => history.push("/clients"),
        permission: {
          kind: "Project",
          permission: "view:list"
        }
      }, {
        label: t("tickets-title"),
        kind: "tickets",
        icon: FaTicketAlt,
        onClick: () => history.push("/tickets"),
        permission: {
          kind: "Ticket",
          permission: "view:list"
        }
      }, {
        label: t("calendar"),
        kind: "calendar",
        icon: FiCalendar,
        onClick: () => history.push("/calendar"),
        permission: {
          kind: "Event",
          permission: "view:list"
        }
      }]); // if (role === "Manager" || role === "Owner" || role === "Admin") {
      //   items.push(
      //     {
      //       label: t("tasks-title"),
      //       kind: "tasks",
      //       icon: BiTask,
      //       warn: tasks.totalItems,
      //       onClick: () => history.push("/tasks"),
      //     },
      //     {
      //       label: t("tickets-title"),
      //       kind: "tickets",
      //       icon: RiTodoFill,
      //       warn: tickets.totalItems,
      //       onClick: () => history.push("/tickets"),
      //     }
      //   );
      // }
      // if (features.tickets) {
      //   items.push({
      //     label: t("tickets-title"),
      //     kind: "tickets",
      //     icon: FaClipboardList,
      //     onClick: () => history.push("/tickets"),
      //   });
      // }

      return items;
    }),
    getTopBarItems: (t, history, dispatch) => createSelector(getUserRole, role => {
      const items = [{
        id: "report",
        label: t("report-title"),
        onClick: () => history.push("/reports"),
        permission: {
          kind: "Reporting",
          permission: "mutate:create"
        }
      }, {
        label: "Support",
        onClick: () => history.push("/help"),
        icon: IoMdHelpCircle,
        permission: {
          kind: "Support",
          permission: "view:page"
        }
      }];
      return ProtectedNavigationItem(role, items);
    }),
    getUserMenuItems: (t, history, dispatch) => createSelector(getUserRole, role => {
      const items = [{
        name: t("profile-menu-item"),
        description: t("profile-description"),
        onClick: () => history.push("/profile"),
        icon: FaUser
      } // {
      //   name: t("events-title"),
      //   description: t("events-description"),
      //   onClick: () => history.push("/events"),
      //   icon: FiRepeat,
      // },
      ];
      if (hasPermissionLevel(role, PermissionTypeLevel.Manager)) items.push({
        name: t("organization-menu-item"),
        description: t("organization-description"),
        onClick: () => history.push("/organization"),
        icon: RiSettings3Fill
      });
      const subItems = [{
        label: t("logout"),
        onClick: () => dispatch({
          type: SessionActionsTypes.ON_USER_LOGOUT,
          payload: null
        }),
        icon: IoMdExit
      }];
      return {
        main: items,
        alternative: subItems
      };
    })
  }
};