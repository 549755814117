import React from "../../../../_snowpack/pkg/react.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import CreateTicketSidebar from "../../forms/SideBar/CreateTicketSidebar.js";
import useI18n from "../../hooks/useI18n.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const CreateTicketPanel = ({
  onDoneSubmit,
  ticket
}) => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const activeDrawer = useSelector(Selectors.app.getDrawerState);

  const handleClose = () => {
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
  };

  const isDrawerOpen = activeDrawer === "ticket-create";
  return __cssprop(SlideOver, {
    title: ticket?.id ? t("ticket-update-title") : t("tickets-info-general-create-ticket-button"),
    isOpen: isDrawerOpen,
    onClose: handleClose
  }, __cssprop(CreateTicketSidebar, {
    ticket: ticket,
    onDoneSubmit: onDoneSubmit
  }));
};