import { css } from "../../../../_snowpack/pkg/@emotion/css.js";
import { Menu, Transition } from "../../../../_snowpack/pkg/@headlessui/react.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import { TenantActionsTypes } from "../../store/artifacts/Tenant/types.js";
import { formatPhoneNumber } from "../../utils/tenant.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { HiUserCircle } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { useHistory } from "../../../../_snowpack/pkg/react-router.js";
import { ProtectedLink } from "./ProtectedComponents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const SmallTenant = ({
  tenant
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const history = useHistory();
  return __cssprop(Menu, null, ({
    open
  }) => __cssprop(React.Fragment, null, tenant?.id ? __cssprop(Menu.Button, null, __cssprop("div", {
    css: {
      "marginLeft": "0.4rem",
      "marginRight": "0.4rem",
      "display": "inline-flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.4rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "borderRadius": "9999px",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(17, 24, 39, var(--tw-border-opacity))",
      "padding": "0.4rem",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem"
    }
  }, __cssprop(HiUserCircle, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("div", {
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between"
    }
  }, __cssprop("h3", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "textTransform": "capitalize"
    }
  }, tenant.name))))) : __cssprop("div", {
    css: {
      "marginLeft": "0.4rem",
      "marginRight": "0.4rem",
      "display": "inline-flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.4rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.4rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "borderRadius": "9999px",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "padding": "0.4rem",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem"
    }
  }, __cssprop(HiUserCircle, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), __cssprop("div", {
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between"
    }
  }, __cssprop("h3", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "textTransform": "capitalize"
    }
  }, tenant.name)))), __cssprop(Transition, {
    show: open,
    as: React.Fragment,
    enter: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
      "transitionDuration": "100ms"
    }),
    enterFrom: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": ".95",
      "--tw-scale-y": ".95",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0"
    }),
    enterTo: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1"
    }),
    leave: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "75ms"
    }),
    leaveFrom: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1"
    }),
    leaveTo: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": ".95",
      "--tw-scale-y": ".95",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0"
    })
  }, __cssprop(Menu.Items, {
    static: true,
    css: {
      "transformOrigin": "top right",
      "zIndex": "30",
      "marginTop": "0.8rem",
      "width": "32rem",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "position": "absolute"
    }
  }, __cssprop("div", {
    css: {
      "borderRadius": "0.8rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "overflow": "hidden"
    }
  }, __cssprop("div", {
    css: {
      "position": "relative",
      "display": "grid",
      "gap": "0.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "@media (min-width: 640px)": {
        "gap": "1.2rem",
        "padding": "1.2rem"
      }
    }
  }, tenant?.primaryPhone && __cssprop(Menu.Items, null, __cssprop("div", null, t("phone"), ": ", __cssprop("b", null, formatPhoneNumber(tenant.primaryPhone)))), tenant?.email && __cssprop(Menu.Items, null, __cssprop("div", null, t("email"), ": ", __cssprop("b", null, tenant.email))), tenant?.language && __cssprop(Menu.Items, null, __cssprop("div", null, t("language"), ": ", __cssprop("b", null, tenant.language === "fr" ? t("french") : t("english")))), __cssprop(Menu.Items, null, __cssprop("div", null, __cssprop(ProtectedLink, {
    permission: {
      kind: "Tenant",
      permission: "view:info"
    },
    text: t("see-details-for", {
      for: tenant.name
    }),
    action: {
      kind: "ClickAction",
      event: () => {
        if (tenant?.id) {
          dispatch({
            type: TenantActionsTypes.TENANT_INITIALIZE,
            payload: {
              kind: "WithTenantId",
              tenantId: tenant.id
            }
          });
          history.push(`/tenants/${tenant.id}`);
        }
      }
    }
  })))))))));
};