import useI18n from "../hooks/useI18n.js";
import { LEASE_BILLING } from "../utils/unit.js";
import React from "../../../_snowpack/pkg/react.js";
import { FaMoneyBillWave, FaMoneyCheckAlt } from "../../../_snowpack/pkg/react-icons/fa.js";
import { FcMoneyTransfer } from "../../../_snowpack/pkg/react-icons/fc.js";
import { HiCreditCard } from "../../../_snowpack/pkg/react-icons/hi.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
const ICON = {
  Cash: () => __cssprop(FaMoneyBillWave, null),
  Check: () => __cssprop(FaMoneyCheckAlt, null),
  CreditCard: () => __cssprop(HiCreditCard, null),
  DirectDebit: () => __cssprop(FcMoneyTransfer, null)
};
export const PaymentMethodBadge = ({
  value
}) => {
  const {
    t
  } = useI18n();

  if (!ICON[value]) {
    return __cssprop("div", {
      css: {
        "display": "flex"
      }
    }, LEASE_BILLING(t)[value]);
  }

  return __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "justifyContent": "center",
      "paddingRight": "0.8rem"
    }
  }, ICON[value]()), LEASE_BILLING(t)[value]);
};