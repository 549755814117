/* eslint-disable no-underscore-dangle */
import { useMutation } from "../../../../_snowpack/pkg/@apollo/client.js";
import { LeaseHistoryCard } from "../../components/index.js";
import LeaseViewer from "../../components/LeaseViewer.js";
import { Loader } from "../../components/Loader.js";
import { ApplicantPanel } from "../../components/Panels/ApplicantPanel.js";
import { ListingButton, ListingPanel } from "../../components/Panels/ListingPanel.js";
import { UnitPanel } from "../../components/Panels/UnitPanel.js";
import { AmenitiesTagCloud } from "../../components/Tailwind/Amenities/AmenitiesTagCloud.js";
import { Attachments } from "../../components/Tailwind/Attachments.js";
import { DataList } from "../../components/Tailwind/DataList.js";
import { EmptyState } from "../../components/Tailwind/EmptyState.js";
import { Notes } from "../../components/Tailwind/Notes.js";
import { SmallTenant } from "../../components/Tailwind/SmallTenant.js";
import TicketInfoRelatedTickets from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoRelatedTickets.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useDeleteModal, { RESOURCE } from "../../forms/useDeleteModal.js";
import { CreateCandidateV2Document } from "../../graphql/documents.js";
import { useFeatureToggle } from "../../hooks/useFeatureToggle.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction, useSelector } from "../../hooks/useRedux.js";
import { Selectors } from "../../store/index.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { LeaseActionsTypes } from "../../store/artifacts/Lease/types.js";
import { UnitActionsTypes } from "../../store/artifacts/Unit/types.js";
import { getFeatureFlagsTabs } from "../../utils/index.js";
import { UNIT_BEDROOMS, UNIT_FLOORS, UNIT_FLOORS_ENUM, UNIT_TYPES } from "../../utils/unit.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useCallback, useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { toast } from "../../../../_snowpack/pkg/react-toastify.js";
import { Card } from "../../components/Tailwind/Card.js";
import { DescriptionList } from "../../components/Tailwind/DescriptionList.js";
import { Layout } from "../../components/Tailwind/Layout.js";
import { Link } from "../../components/Tailwind/Link.js";
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedActions, ProtectedButton, ProtectedCard, ProtectedCardSection, ProtectedTab } from "../../components/Tailwind/ProtectedComponents.js";
import { PaymentDataList } from "../Payments/index.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const RenderPageContent = ({
  selectedTab,
  history,
  unitViewProps
}) => {
  switch (selectedTab) {
    case 0:
      return __cssprop(UnitView, unitViewProps);

    case 1:
      return __cssprop(PaymentDataList, null);

    default:
      return __cssprop(UnitView, unitViewProps);
  }
};

export const Unit = withBreadcrumb(({
  history,
  match,
  crumbs,
  setCrumb
}) => {
  const {
    t
  } = useI18n();
  const unitId = useSelector(Selectors.unit.getId);
  const subAddress = useSelector(Selectors.unit.getSubAddress);
  const buildingId = useSelector(Selectors.unit.getBuilding)?.id;
  const buildingAddress = useSelector(Selectors.unit.getBuildingAddress);
  const isLoading = useSelector(Selectors.status.isLoading("unit"));
  const isSubmiting = useSelector(Selectors.status.isSubmiting("unit"));
  const currentLease = useSelector(Selectors.unit.getCurrentLease);
  const paymentEnable = useFeatureToggle("payment");
  const listingIsPublished = useSelector(Selectors.listing.isPublished);
  const listingId = useSelector(Selectors.listing.getListingId);
  const isPublished = useSelector(Selectors.listing.isPublished);
  const availability = useSelector(Selectors.listing.getAvailabiltyDate);
  const [isListingPanelOpen, setIsListingPanelOpen] = useState(false);
  const [addApplicantFromApplication, {
    loading: addApplicantFromApplicationLoading
  }] = useMutation(CreateCandidateV2Document, {
    fetchPolicy: "no-cache"
  });
  const {
    id
  } = match.params;
  const role = useSelector(Selectors.session.getUserRole);
  const {
    dispatch
  } = useAction();
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    if (!id && unitId) {
      dispatch({
        type: UnitActionsTypes.UNIT_INITIALIZE,
        payload: null
      });
    } else if (id && !unitId) {
      dispatch({
        type: UnitActionsTypes.UNIT_INITIALIZE,
        payload: id
      });
    } else if (id && unitId && id !== unitId) {
      dispatch({
        type: UnitActionsTypes.UNIT_INITIALIZE,
        payload: id
      });
    }
  }, [id, unitId]);
  const onDeleteSuccess = useCallback(() => {
    if (!unitId || isLoading) return;
    history.replace(`/buildings/${buildingId}`);
  }, [buildingId, id, history, isLoading]);
  const [DeleteModal, _openDeleteModal, deleteModalAction] = useDeleteModal(id, RESOURCE.Unit, onDeleteSuccess);
  const [DeleteLeaseModal, openDeleteLeaseModal] = useDeleteModal(undefined, RESOURCE.Lease, () => dispatch({
    type: UnitActionsTypes.UNIT_INITIALIZE,
    payload: id
  }));

  const onCreateLeaseClick = pUnit => {
    dispatch({
      type: LeaseActionsTypes.LEASE_INITIALIZE_WITH_UNIT,
      payload: pUnit.id
    });
    history.push("/lease");
  };

  const onRenewLeaseClick = _id => {
    history.push(`/lease/${_id}/renewal`);
  };

  const onModifyLeaseClick = _id => {
    history.push(`/lease/${_id}`);
  };

  useEffect(() => {
    if (!isLoading && subAddress) {
      setCrumb({
        title: `${t("units")} #${subAddress}`,
        path: `/unit/${unitId}`,
        level: 2
      });
    }
  }, [isLoading, subAddress]);
  const actionArray = [{
    content: t("unit-modify-unit"),
    onAction: () => {
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: "unit"
      });
    },
    permission: {
      kind: "Unit",
      permission: "mutate:update"
    }
  }, { ...deleteModalAction,
    permission: {
      kind: "Unit",
      permission: "mutate:delete"
    },
    onAction: () => {
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
        payload: "Unit"
      });
    }
  }, {
    content: t("unit-add-lease"),
    onAction: () => onCreateLeaseClick({
      building: {
        id: buildingId
      },
      id: unitId,
      buildingAddress,
      subAddress
    }),
    permission: {
      kind: "Lease",
      permission: "mutate:create"
    }
  }]; // add a button to add an applicant to active listing

  if (listingIsPublished) actionArray.push({
    content: t("applicant-request-add-applicant"),
    onAction: () => dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: "applicant"
    }),
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:create"
    }
  });
  const action = ProtectedActions(role, actionArray);

  if (isLoading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  const onDoneSubmit = data => {
    dispatch({
      type: UnitActionsTypes.UNIT_SUBMIT,
      payload: { ...data,
        id
      }
    });
  };

  const tabs = ProtectedTab(role, getFeatureFlagsTabs([{
    content: t("unit"),
    id: "unit",
    permission: {
      kind: "Unit",
      permission: "view:info"
    }
  }], [{
    content: t("payments"),
    id: "payments",
    permission: {
      kind: "Payement",
      permission: "view:list"
    }
  }], paymentEnable));

  const onApplicantDoneSubmit = async slideOverData => {
    // https://github.com/apollographql/apollo-client/issues/5708 known issue in apollo client not being able to handle mutation errors
    try {
      // append listing id and submit
      let linkExistingUser;
      let newApplicant;

      if ("id" in slideOverData) {
        linkExistingUser = slideOverData.id;
      } else if ("firstName" in slideOverData) {
        newApplicant = slideOverData;
      }

      const response = await addApplicantFromApplication({
        variables: {
          input: {
            listingId,
            occupancyDateRequested: slideOverData.occupancyDateRequested,
            linkExistingUser,
            createNewApplicant: newApplicant ? {
              firstName: newApplicant.firstName,
              lastName: newApplicant.lastName,
              email: newApplicant.email,
              phoneInfo: {
                primaryPhone: newApplicant.primaryPhone,
                secondaryPhone: newApplicant.secondaryPhone,
                phoneIsMobile: !!newApplicant.primaryChecked,
                secondaryPhoneIsMobile: !!newApplicant.secondaryChecked
              },
              language: newApplicant.language,
              notificationEnabled: !!newApplicant.notificationEnabled,
              candidateDOB: newApplicant.candidateDOB
            } : undefined
          }
        }
      }); // redirect to applicant page if success

      if (response?.errors?.length !== 0) {
        // close drawer
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: null
        });
        history.push(`/applicant/v2/${response.data.createCandidateV2.id}`);
        toast.success(t("applicant-request-form-add-applicant-success"));
      }

      if (response.errors) {
        toast.error(t("user-form-saved-fail"));
      }
    } catch (e) {
      toast.warn(t("user-form-saved-fail"));
    }
  };

  return __cssprop(React.Fragment, null, __cssprop(UnitPanel, {
    formTitle: t("unit-modify-unit"),
    description: t("unit-info-section-description"),
    sectionTitle: t("unit-info-section-title"),
    onDoneSubmit: onDoneSubmit
  }), __cssprop(ListingPanel, {
    handleOnClose: () => setIsListingPanelOpen(false),
    isOpen: isListingPanelOpen,
    refetch: () => {
      setIsListingPanelOpen(false);
      history.go(0); // TODO: can rework to refetch on next js version
    },
    listingId: listingId,
    unitId: unitId
  }), __cssprop(ApplicantPanel, {
    formTitle: t("applicant-request-add-applicant"),
    description: t("applicant-request-add-applicant-description"),
    sectionTitle: "",
    onDoneSubmit: onApplicantDoneSubmit
  }), __cssprop(Page, {
    title: `${t("units")} #${subAddress}`,
    breadcrumbs: crumbs,
    primaryAction: __cssprop(React.Fragment, null, __cssprop(ListingButton, {
      listingId: listingId,
      isPublished: isPublished,
      availability: DateTime.fromJSDate(availability).toUTC().toISO(),
      setIsListingPanelOpen: setIsListingPanelOpen
    }), currentLease ? __cssprop(ProtectedButton, {
      grouped: true,
      permission: {
        kind: "Lease",
        permission: "mutate:create"
      },
      primary: true,
      onAction: () => onRenewLeaseClick(currentLease.id),
      loading: isSubmiting
    }, t("unit-renew-lease")) : __cssprop(ProtectedButton, {
      grouped: true,
      permission: {
        kind: "Lease",
        permission: "mutate:create"
      },
      primary: true,
      onAction: () => onCreateLeaseClick({
        building: {
          id: buildingId
        },
        id: unitId,
        buildingAddress,
        subAddress
      }),
      loading: isSubmiting
    }, t("unit-add-lease"))),
    secondaryActions: {
      content: t("widget-other"),
      actions: [action]
    },
    tabs: {
      tabs,
      onSelect: setSelectedTab,
      selected: selectedTab
    }
  }, __cssprop(RenderPageContent, {
    unitViewProps: {
      match,
      openDeleteLeaseModal
    },
    history: history,
    selectedTab: selectedTab
  }), __cssprop(DeleteModal, null), __cssprop(DeleteLeaseModal, null)));
});

const UnitInformationCard = () => {
  const {
    t
  } = useI18n();
  const bedrooms = useSelector(Selectors.unit.getBedrooms);
  const squareFootage = useSelector(Selectors.unit.getSquareFootage);
  const floor = useSelector(Selectors.unit.getFloor);
  const files = useSelector(Selectors.unit.getFiles);
  const unitType = useSelector(Selectors.unit.getUnitType);
  const waterTankInstallationDate = useSelector(Selectors.unit.getWaterTankInstallationDate);
  const subAddress = useSelector(Selectors.unit.getSubAddress);
  const buildingAddress = useSelector(Selectors.unit.getBuildingAddress);
  const buildingId = useSelector(Selectors.unit.getBuildingId);
  const project = useSelector(Selectors.unit.getProject);
  return __cssprop(ProtectedCard, {
    title: t("unit-info"),
    permission: {
      kind: "Unit",
      permission: "view:info"
    }
  }, __cssprop(Card.Section, null, __cssprop(DescriptionList.TwoColumn, null, __cssprop(DescriptionList.Item, {
    half: true,
    title: t("unit")
  }, "#", subAddress, " (", UNIT_TYPES(t)[unitType?.toUpperCase()], ")"), __cssprop(DescriptionList.Item, {
    half: true,
    title: t("building")
  }, __cssprop(Link, {
    text: buildingAddress,
    action: {
      kind: "InternalLink",
      to: `/buildings/${buildingId}`
    }
  })), __cssprop(DescriptionList.Item, {
    half: true,
    title: t("room-number")
  }, UNIT_BEDROOMS[bedrooms]), __cssprop(DescriptionList.Item, {
    half: true,
    title: t("floor")
  }, UNIT_FLOORS(t)[UNIT_FLOORS_ENUM[floor]]), __cssprop(DescriptionList.Item, {
    half: true,
    title: t("unit-size")
  }, `${squareFootage} ${t("square-foot")}`), __cssprop(DescriptionList.Item, {
    half: true,
    title: t("project")
  }, __cssprop(Link, {
    text: project?.name,
    action: {
      kind: "InternalLink",
      to: `/clients/${project?.id}`
    }
  })))), files && files?.length > 0 && __cssprop(ProtectedCardSection, {
    greyed: true,
    permission: {
      kind: "Unit",
      permission: "view:files"
    }
  }, __cssprop(Attachments, {
    visualOnly: true,
    files: files.map(file => {
      if (file?.kind === "remote") {
        return {
          fileName: file.fileName,
          fileType: file.fileType,
          url: file.url,
          filePath: file.filePath
        };
      }

      return {
        fileName: file.fileName,
        fileType: file.fileType,
        url: file.preview
      };
    })
  })));
};

const getTenantInfo = tenants => {
  const tenant = tenants.map(ten => __cssprop(SmallTenant, {
    tenant: ten
  }));
  return __cssprop("div", {
    css: {
      "display": "block"
    }
  }, tenant);
};

const seeApplication = (t, applicant) => {
  return __cssprop(Link, {
    text: t("tenant-application-link"),
    action: {
      kind: "InternalLink",
      to: `/applicant/form/${applicant.id}`
    }
  });
};

export const ApplicationsListCard = ({
  applications
}) => {
  const {
    t
  } = useI18n();

  if (!applications) {
    return null;
  }

  const rows = applications.filter(application => !!application).map(application => {
    const row = {
      items: [[getTenantInfo(application.tenants)], [application.createdAt ? DateTime.fromISO(application.createdAt).toUTC().toISODate() : null], [application.visitScheduled ? DateTime.fromISO(application.visitScheduled).toUTC().toISODate() : null], [seeApplication(t, application)]]
    };
    return row;
  });

  if (rows.length === 0) {
    return null;
  }

  const headings = [t("fullname"), t("unit-application-created-date"), t("unit-application-visit-date"), t("unit-heading-action")];
  return __cssprop(DataList, {
    collapsible: true,
    headings: headings,
    title: t("unit-application-title"),
    rows: rows,
    permission: {
      kind: "ApplicantRequest",
      permission: "view:list"
    }
  });
};

const UnitView = ({
  match,
  openDeleteLeaseModal
}) => {
  const {
    t
  } = useI18n();
  const {
    id
  } = match.params;
  const lease = useSelector(Selectors.unit.getCurrentLease);
  const leases = useSelector(Selectors.unit.getLeases);
  const rentalRequest = useSelector(Selectors.unit.getRentalRequest);
  const noteLoading = useSelector(Selectors.status.isSubmiting("note"));
  const amenities = useSelector(Selectors.unit.getAmenities);
  const notes = useSelector(Selectors.unit.getNotes);
  const tickets = useSelector(Selectors.unit.getTickets);
  const role = useSelector(Selectors.session.getUserRole);
  const {
    dispatch
  } = useAction();
  return __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop(React.Fragment, null, lease ? __cssprop(LeaseViewer, {
    kind: "tenant",
    lease: lease
  }) : __cssprop(Card, null, __cssprop(EmptyState, {
    image: "/img/noLeaseHistoryForSelectedTenant.svg",
    heading: t("unit-empty-state-no-lease")
  }, __cssprop("p", null, t("unit-empty-state-no-lease-description")))), __cssprop(ApplicationsListCard, {
    applications: rentalRequest
  }), __cssprop(LeaseHistoryCard, {
    leases: leases,
    openDeleteLeaseModal: openDeleteLeaseModal
  }))), __cssprop(Layout, {
    oneThird: true
  }, __cssprop(UnitInformationCard, null), amenities?.length !== 0 && __cssprop(AmenitiesTagCloud, {
    title: t("amenities-title"),
    list: amenities
  }), tickets.length > 0 && __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(TicketInfoRelatedTickets, {
    data: tickets
  }))), __cssprop(Notes, {
    notes: notes,
    loading: noteLoading,
    permission: {
      kind: "Unit",
      permission: "view:notes"
    },
    addNoteEvent: pNotes => {
      dispatch({
        payload: {
          resourceId: id,
          resourceType: "unit",
          actionType: UnitActionsTypes.UNIT_ADD_NOTES,
          content: pNotes.note,
          fromPage: true
        },
        type: UnitActionsTypes.UNIT_ADD_NOTES_FORMLESS
      });
    },
    hideNewNote: role === "Client" || role === "User",
    removeNoteEvent: pNotes => {
      dispatch({
        payload: {
          noteId: pNotes.id,
          actionType: UnitActionsTypes.UNIT_REMOVE_NOTES,
          fromPage: true
        },
        type: UnitActionsTypes.UNIT_REMOVE_NOTES_FORMLESS
      });
    }
  })));
};