export let TaskActionTypes;

(function (TaskActionTypes) {
  TaskActionTypes["TASK_SET_FIELD"] = "TASK_SET_FIELD";
  TaskActionTypes["TASK_SET_INITIAL_STATE"] = "TASK_SET_INITIAL_STATE";
  TaskActionTypes["TASK_CLOSE"] = "TASK_CLOSE";
  TaskActionTypes["TASK_DELETE"] = "TASK_DELETE";
  TaskActionTypes["INVOICE_SET_FILES"] = "INVOICE_SET_FILES";
})(TaskActionTypes || (TaskActionTypes = {}));

export const initialState = {
  id: null,
  title: "",
  createdBy: {},
  modifiedBy: {},
  dueDate: "",
  createdAt: null,
  modifiedAt: null,
  closedAt: null,
  assignee: {},
  invoice: {
    title: "",
    amount: null,
    description: "",
    date: "",
    files: []
  },
  ticket: null
};