import _styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import { useMutation } from "../../../../../../_snowpack/pkg/@apollo/client.js";
import { Switch } from "../../../../../../_snowpack/pkg/@headlessui/react.js";
import { ConfirmationCase, SetRentalApplicationAgentApprovalStatusDocument } from "../../../../graphql/documents.js";
import useI18n from "../../../../hooks/useI18n.js";
import React, { useCallback, useState } from "../../../../../../_snowpack/pkg/react.js";
import { toast } from "../../../../../../_snowpack/pkg/react-toastify.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const StyledSpan = _styled.span(({
  active
}) => [{
  "--tw-translate-x": "0",
  "--tw-translate-y": "0",
  "--tw-rotate": "0",
  "--tw-skew-x": "0",
  "--tw-skew-y": "0",
  "--tw-scale-x": "1",
  "--tw-scale-y": "1",
  "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
  "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  "pointerEvents": "none",
  "display": "inline-block",
  "height": "2rem",
  "width": "2rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)"
}, active ? {
  "--tw-translate-x": "2rem"
} : {
  "--tw-translate-x": "0px"
}]);

const StyledSwitch = _styled(Switch)(({
  checked,
  disabled
}) => [{
  "position": "relative",
  "display": "inline-flex",
  "flexShrink": "0",
  "height": "2.4rem",
  "width": "4.4rem",
  "borderWidth": "2px",
  "borderColor": "transparent",
  "borderRadius": "9999px",
  "transitionProperty": "background-color, border-color, color, fill, stroke",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-ring-offset-width": "2px",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
  }
}, checked ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))"
} : {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))"
}, disabled ? {
  "cursor": "not-allowed"
} : {
  "cursor": "pointer"
}]);

export const VisitApprovedToggle = props => {
  const {
    t
  } = useI18n();
  const {
    agentApproved,
    refetch,
    disabled,
    rentalApplicationId
  } = props;
  const [agentApprovedForm, toggleAgentApprovedForm] = useState(agentApproved || false);
  const [assignAgentApproved, {
    loading: agentApprovedLoading
  }] = useMutation(SetRentalApplicationAgentApprovalStatusDocument, {
    fetchPolicy: "no-cache"
  });
  const handleOnAgentApprovedToggle = useCallback(async isApproved => {
    toggleAgentApprovedForm(isApproved);

    try {
      const agentApprovedResponse = await assignAgentApproved({
        variables: {
          input: {
            rentalApplicationId,
            confirmationCase: isApproved ? ConfirmationCase.Approved : ConfirmationCase.Unset // set to null instead of setting rejection date

          }
        }
      });

      if (agentApprovedResponse.errors) {
        toast.warn(`${t("server-error")} ${agentApprovedResponse.errors}`);
      }

      if (agentApprovedResponse && agentApprovedResponse.data) {
        refetch();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("AgentApprovedSection", e);
      toast.warn(`${t("server-error")} ${e}`);
    }
  }, [rentalApplicationId]);
  return __cssprop(React.Fragment, null, __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, t("applicant-request-visit-approved")), __cssprop(StyledSwitch, {
    checked: agentApprovedForm,
    onChange: handleOnAgentApprovedToggle,
    disabled: disabled
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Use setting"), __cssprop(StyledSpan, {
    "aria-hidden": "true",
    active: agentApprovedForm
  })));
};