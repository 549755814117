/* eslint-disable import/no-cycle */
export const getCalendarState = state => state?.calendar;
export default {
  getCalendarState,
  getEvents: state => getCalendarState(state).items,
  getFormattedEvents: state => getCalendarState(state).items.map(item => {
    switch (item?.typename) {
      case "TaskGQL":
        return {
          id: item.id,
          title: item.title,
          start: item.dueDate || undefined,
          createdAt: item.createdAt,
          assignee: item.assignee,
          createdBy: item.createdBy,
          closedAt: item?.closedAt,
          modifiedAt: item?.modifiedAt,
          dueDate: item?.dueDate,
          deletedAt: item?.deletedAt,
          invoice: item?.invoice,
          ticket: item?.ticket,
          type: "Task",
          backgroundColor: "#6366f1",
          borderColor: "#6366f1",
          textColor: "#fff",
          allDay: true
        };

      case "RecurringEventGQL":
        return {
          id: item.id,
          title: item.title,
          rrule: item.RRule,
          description: item?.description,
          entity: item?.entity,
          createdAt: item.createdAt,
          type: "Recurring Ticket",
          backgroundColor: "#6b7280",
          borderColor: "#6b7280",
          textColor: "#fff",
          allDay: true
        };

      default:
        throw new Error("Unknown Event type");
    }
  })
};