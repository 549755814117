import { LocalStorage } from "../../../_snowpack/pkg/ttl-localstorage.js";
import { initFirebase } from "../services/firebase.js";
import { isValidPhoneNumber } from "../../../_snowpack/pkg/libphonenumber-js.js";
import Joi from "../../../_snowpack/pkg/@hapi/joi.js";
import { initializeIntercom } from "./vendors.js";
LocalStorage.synchronous = true;
export function omit(target, ...omitKeys) {
  if (!target) {
    return null;
  }

  return Object.keys(target).reduce((res, key) => {
    if (!omitKeys.includes(key)) {
      res[key] = target[key];
    }

    return res;
  }, {});
}
export const setLocalStorage = (key, value, expiry = null) => {
  try {
    LocalStorage.put(key, JSON.stringify(value), expiry);
  } catch (e) {// catch possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
};
export const deleteLocalStorage = key => {
  LocalStorage.removeKey(key);
};
export const getLocalStorage = (key, initialValue) => {
  try {
    // Flush Expired items before accessing new ones
    LocalStorage.runGarbageCollector();
    const value = LocalStorage.get(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
};
export const getLocalStorageAndUpdateTTL = (key, initialValue, ttl) => {
  try {
    const result = getLocalStorage(key, initialValue);
    setLocalStorage(key, result, ttl);
    return result;
  } catch (e) {
    // if error, return initial value
    return initialValue;
  }
};
export const prepareAppVendors = () => {
  initFirebase();
  initializeIntercom();
  return true;
};
export const getPhoneValidationForForm = () => {
  return {
    validate: {
      isValid: v => {
        if (v === "+1") return true;
        if (!v) return true;
        return isValidPhoneNumber(v) || false;
      }
    }
  };
};
export const getEmailValidationForForm = () => {
  return {
    validate: {
      isValid: v => {
        const schema = Joi.string().email({
          tlds: {
            allow: false
          }
        }).allow(null).allow("");
        const {
          error
        } = schema.validate(v);

        if (error) {
          return false;
        }

        return true;
      }
    }
  };
};
export const getFeatureFlagsTabs = (defaultTabs, addedTab, featureFlag) => {
  if (featureFlag) {
    return [...defaultTabs, ...addedTab];
  }

  return defaultTabs;
};