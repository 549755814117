import { c as createCommonjsModule } from './common/_commonjsHelpers-f5d70792.js';
import './common/index-8f144fe1.js';
import './common/emotion-utils.browser.esm-be032523.js';
import './common/hoist-non-react-statics.cjs-fd576625.js';
import './common/extends-e5fd4e85.js';
import './common/emotion-react.browser.esm-d1c4b102.js';
import './common/index-821eef78.js';
import './common/index-4bda1d4e.js';
import { m as manageState, S as Select } from './common/typeof-adbfc840.js';
import './common/toConsumableArray-b531af85.js';
import './common/defineProperty-655019c3.js';

var _extends_1 = createCommonjsModule(function (module) {
function _extends() {
  module.exports = _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  module.exports["default"] = module.exports, module.exports.__esModule = true;
  return _extends.apply(this, arguments);
}

module.exports = _extends;
module.exports["default"] = module.exports, module.exports.__esModule = true;
});

var index = manageState(Select);

export default index;
