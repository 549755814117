import _styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import { DatePicker } from "../../../../components/DatePicker.js";
import { Selector } from "../../../../components/Selectors/Selector.js";
import { Card } from "../../../../components/Tailwind/Card.js";
import { DescriptionList } from "../../../../components/Tailwind/DescriptionList.js";
import { DescriptionLayoutItem } from "../../../../components/Tailwind/Layout.js";
import { TextField } from "../../../../components/Tailwind/TextField.js";
import { showError } from "../../../../helpers/functions.js";
import useI18n from "../../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../../store/artifacts/Building/types.js";
import { PROVINCE } from "../../../../utils/unit.js";
import React from "../../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const StyledItems = _styled.dl(() => [{
  "display": "grid",
  "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
  "@media (min-width: 640px)": {
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "paddingTop": "2rem"
  }
}]);

const getSelecteOptions = {
  QC: {
    label: "Québec",
    value: "QC"
  },
  ON: {
    label: "Ontario",
    value: "ON"
  }
};
const options = [{
  label: "Québec",
  value: "QC"
}, {
  label: "Ontario",
  value: "ON"
}];
export const BuildingInfoSection = () => {
  const {
    t
  } = useI18n();
  const civicNumber = useSelector(Selectors.building.getCivicNumber);
  const streetName = useSelector(Selectors.building.getStreetName);
  const city = useSelector(Selectors.building.getCity);
  const sector = useSelector(Selectors.building.getSector);
  const province = useSelector(Selectors.building.getProvince);
  const postalCode = useSelector(Selectors.building.getPostalCode);
  const buildDate = useSelector(Selectors.building.getBuildDate);
  const purchaseDate = useSelector(Selectors.building.getPurchaseDate);
  const errors = useSelector(Selectors.building.getErrors);
  const {
    dispatch
  } = useAction();
  return __cssprop(DescriptionLayoutItem, {
    title: t("building-form-title"),
    description: t("building-form-description")
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(DescriptionList.TwoColumn, null, __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
      "@media (min-width: 640px)": {
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1.6rem",
        "alignItems": "flex-start"
      }
    }
  }, __cssprop(TextField, {
    id: "civicNumber",
    label: t("building-form-civic-number"),
    value: civicNumber,
    placeholder: "200",
    error: showError(errors, "civicNumber", t),
    onChange: v => {
      dispatch({
        type: BuildingActionsTypes.BUILDING_SET_FIELD,
        payload: {
          civicNumber: v
        }
      });
    }
  })), __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
      "@media (min-width: 640px)": {
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1.6rem",
        "alignItems": "flex-start"
      }
    }
  }, __cssprop(TextField, {
    id: "streetName",
    label: t("building-form-address"),
    value: streetName,
    placeholder: "Rue du Sirop",
    error: showError(errors, "streetName", t),
    onChange: v => {
      dispatch({
        type: BuildingActionsTypes.BUILDING_SET_FIELD,
        payload: {
          streetName: v
        }
      });
    }
  })), __cssprop(StyledItems, null, __cssprop(TextField, {
    id: "city",
    label: t("building-form-city"),
    value: city,
    onChange: v => {
      dispatch({
        type: BuildingActionsTypes.BUILDING_SET_FIELD,
        payload: {
          city: v
        }
      });
    },
    placeholder: "Gatineau",
    error: showError(errors, "city", t)
  })), __cssprop(StyledItems, null, __cssprop(TextField, {
    id: "postalCode",
    label: t("building-form-postal-code"),
    value: postalCode,
    placeholder: "A1B 2C3",
    onChange: v => {
      dispatch({
        type: BuildingActionsTypes.BUILDING_SET_FIELD,
        payload: {
          postalCode: v
        }
      });
    },
    error: showError(errors, "postalCode", t)
  })), __cssprop(StyledItems, null, __cssprop(TextField, {
    id: "sector",
    label: t("building-form-sector"),
    value: sector,
    onChange: v => {
      dispatch({
        type: BuildingActionsTypes.BUILDING_SET_FIELD,
        payload: {
          sector: v
        }
      });
    },
    placeholder: "Hull",
    error: showError(errors, "sector", t)
  })), __cssprop(StyledItems, null, __cssprop(Selector, {
    label: t("building-form-province"),
    options: options,
    selectedItem: getSelecteOptions[PROVINCE[province]],
    onChange: v => {
      dispatch({
        type: BuildingActionsTypes.BUILDING_SET_FIELD,
        payload: {
          province: v.value
        }
      });
    },
    error: showError(errors, "province", t)
  })), __cssprop(StyledItems, null, __cssprop(DatePicker, {
    id: "buildDate",
    defaultValue: buildDate,
    title: t("building-form-construction-date"),
    onChange: date => dispatch({
      type: BuildingActionsTypes.BUILDING_SET_FIELD,
      payload: {
        buildDate: date
      }
    }),
    error: showError(errors, "buildDate", t)
  })), __cssprop(StyledItems, null, __cssprop(DatePicker, {
    id: "purchaseDate",
    defaultValue: purchaseDate,
    title: t("building-form-acquisition-date"),
    onChange: date => dispatch({
      type: BuildingActionsTypes.BUILDING_SET_FIELD,
      payload: {
        purchaseDate: date
      }
    }),
    error: showError(errors, "purchaseDate", t)
  }))))));
};