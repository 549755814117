import "../../../_snowpack/pkg/firebase/auth.js";
import firebase from "../../../_snowpack/pkg/firebase.js";
import { v4 as uuidv4 } from "../../../_snowpack/pkg/uuid.js";
import { getCleanFileName } from "../utils/file.js";
import { getUniqueFiles } from "../store/utils/Files.js";
export const TYPES = {
  None: "",
  Images: "Images",
  Files: "Files",
  Thumbnails: "Thumbnails"
};
export const getFilePath = (resource, resourceId, type, firebaseUserId) => `simpleloyer/${type === TYPES.Images || type === TYPES.Thumbnails ? "images/" : ""}${resource}/${resourceId}/${type === TYPES.Thumbnails ? "thumbnails/" : ""}/${firebaseUserId}`;
export const getObjectFromRef = async (file, index) => {
  const storageRef = firebase.storage().ref();
  const objRef = storageRef.child(`${file.fullPath}`);
  let metadata = (await objRef.getMetadata()) || {};

  if (!metadata.customMetadata) {
    metadata = { ...metadata,
      customMetadata: {
        position: index
      }
    };
    await objRef.updateMetadata(metadata);
  }

  const url = await objRef.getDownloadURL();
  return {
    name: getCleanFileName(file.name),
    url,
    path: file.fullPath,
    metadata
  };
};
export const uploadFiles = async (files, resource, resourceId, type) => {
  if (!files || files.length === 0) {
    return [];
  }

  const userId = firebase.auth().currentUser.uid;
  const path = getFilePath(resource, resourceId, type, userId);
  const storageRef = firebase.storage().ref(path);
  const documents = files.filter(f => f && !!f.blob);
  const uploaded = await Promise.all(documents.map((file, index) => {
    const [_, name, ext] = file.name.match(/(.*)\.([^.]*?)(?=\?|#|$)/) || [];
    const fullName = `${name}-${uuidv4().substring(0, 8)}.${ext}`;
    const objRef = storageRef.child(fullName);
    return objRef.put(file.blob, {
      contentType: file.type,
      customMetadata: {
        position: `${index}`
      }
    });
  })).catch(e => console.error(`Error while uploading files. Error: `, e));

  if (!uploaded) {
    return [];
  }

  return Promise.all(uploaded.map(item => getObjectFromRef(item.ref)));
};
export const downloadFile = async url => {
  const userId = firebase.auth().currentUser.uid;
  const storageRef = firebase.storage().ref(url);
  const downloadURL = await storageRef.getDownloadURL();
  return downloadURL;
};
export async function uploadDocumentsNoneSaga(documents, resource, documentType, resourceId) {
  if (!documents || documents.length === 0) {
    return [];
  }

  const uniqueFiles = getUniqueFiles(documents);
  const docs = uniqueFiles.filter(g => {
    return g.kind === "local";
  });
  const uploadedDocs = await uploadFiles(docs.map(i => {
    return {
      blob: i.blob,
      name: i.fileName,
      size: i.size,
      type: i.fileType,
      url: i.path
    };
  }), resource, resourceId || uuidv4(), documentType);
  return [...uploadedDocs.map(u => {
    const f = uniqueFiles.filter(ff => ff.size === u.metadata.size);
    return {
      fileName: u.name,
      url: u.url,
      fileType: u.metadata.type,
      filePath: u.path,
      fileCategory: f.length !== 0 ? f[0].fileCategory : undefined
    };
  }), ...uniqueFiles.filter(g => {
    return g.kind === "remote" || !g.kind;
  }).map(g => {
    return {
      fileName: g.fileName,
      url: g.url,
      fileType: g.fileType,
      filePath: g.filePath,
      fileCategory: g.fileCategory
    };
  })];
}