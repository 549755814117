/* eslint-disable react/require-default-props */
import { useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { FilterKind, GetUsersDocument } from "../../graphql/documents.js";
import useDebounce from "../../hooks/useDebounce.js";
import useI18n from "../../hooks/useI18n.js";
import React, { useCallback, useMemo, useState } from "../../../../_snowpack/pkg/react.js";
import { Selector } from "./Selector.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const UserSelector = props => {
  const {
    isMulti,
    error,
    selected,
    label,
    defaultValue,
    onChange,
    placeholder,
    roles = []
  } = props;
  const {
    t
  } = useI18n();
  const [selectedOptions, setSelectedOptions] = useState(selected ? [{
    label: selected.label,
    value: selected.id
  }] : []);
  const [searchString, setSearchString] = React.useState("");
  const debouncedSearch = useDebounce(searchString, 500);
  const {
    loading,
    data
  } = useQuery(GetUsersDocument, {
    variables: {
      take: 100,
      query: debouncedSearch,
      skip: 0,
      roles: {
        roles,
        kind: FilterKind.Inclusive
      }
    }
  });
  const items = useMemo(() => data?.users?.items.map(i => {
    return {
      value: i.id,
      label: `${i.name}`
    };
  }) || [], [data]);
  const onSelect = useCallback(values => {
    setSelectedOptions([values]);
    onChange(values || {
      label: null,
      value: null
    });
  }, [selectedOptions, isMulti]);
  return __cssprop("div", null, label && __cssprop("span", {
    css: {
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "paddingBottom": "0.4rem"
    }
  }, label), __cssprop(Selector, {
    defaultValue: defaultValue,
    css: {
      "--tw-shadow": "0 0 #0000",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    },
    placeholder: placeholder,
    onChange: onSelect,
    onInputChange: setSearchString,
    isLoading: loading,
    selectedItem: selectedOptions,
    options: items
  }), error && __cssprop("span", {
    css: {
      "paddingTop": "0.8rem",
      "--tw-text-opacity": "1",
      "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
    }
  }, error?.errorMessage));
};