import { css as _css } from "../../../_snowpack/pkg/@emotion/react.js";
import _styled from "../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../_snowpack/pkg/react.js";
import { Player } from "../../../_snowpack/pkg/@lottiefiles/react-lottie-player.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const Container = _styled.div(({
  fullscreen
}) => [fullscreen && {
  "position": "absolute",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
  "zIndex": "20",
  "opacity": "0.6"
}, {
  "display": "flex",
  "alignItems": "center",
  "justifyItems": "center",
  "justifyContent": "center"
}, fullscreen && _css`
      height: calc(100vh - 64px);
      width: calc(100vw - 112px);
    `]);

const Animation = ({
  size
}) => __cssprop(Player, {
  autoplay: true,
  loop: true,
  src: "/animations/lf30_editor_s0zohgme.json",
  controls: false,
  style: {
    height: `${size}px`,
    width: `${size}px`,
    zIndex: 200
  }
});

export const Loader = ({
  fullscreen = false,
  size = 250
}) => {
  return __cssprop(Container, {
    fullscreen: fullscreen
  }, __cssprop(Animation, {
    size: size
  }));
};