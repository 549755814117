import { GetCompanyUsersDocument, GetProjectsDocument, GetSpacesDocument, GetTenantsDocument } from "../graphql/documents.js";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TICKET_SIDEBAR_SELECTORS = t => {
  return {
    space: {
      labelPattern: "streetName",
      query: GetSpacesDocument,
      name: ["space"],
      label: `${t("property")}`,
      requestFieldName: "space",
      extraValues: [],
      defaultValue: null
    },
    project: {
      labelPattern: "name",
      query: GetProjectsDocument,
      name: ["project"],
      requestFieldName: "project",
      label: `${t("event-sidebar-resource-project")}s`,
      extraValues: [],
      defaultValue: null
    },
    user: {
      labelPattern: "name",
      query: GetCompanyUsersDocument,
      name: ["user"],
      label: t("event-sidebar-resource-user"),
      requestFieldName: "user",
      extraValues: [],
      defaultValue: null
    },
    tenant: {
      labelPattern: "name",
      query: GetTenantsDocument,
      name: ["tenant"],
      label: t("event-sidebar-resource-users-tenants"),
      requestFieldName: "user",
      extraValues: [],
      defaultValue: null
    }
  };
};