import React, { useEffect, useState } from "../../../../../_snowpack/pkg/react.js";
import { GetProviderDocument } from "../../../graphql/documents.js";
import { useLazyQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { Loader } from "../../../components/Loader.js";
import { ProviderSideBarFormView } from "./ProviderSideBarFormView.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const ProviderSideBar = ({
  refetch,
  providerId
}) => {
  const [isReady, setIsReady] = useState(false);
  const [providerState, setProviderState] = useState({
    refetch,
    providerId,
    providerProp: null
  });
  const [loadProvider, {
    loading: queryLoading
  }] = useLazyQuery(GetProviderDocument, {
    variables: {
      id: providerId
    },
    onCompleted: providerData => {
      const {
        provider
      } = providerData;

      if (!provider) {
        return;
      } // pass values to form component


      const mappedProviderState = { ...providerState,
        providerId: provider.id,
        providerProp: { ...provider,
          email: provider.email,
          name: provider.name,
          primaryPhone: provider.primaryPhone,
          secondaryPhone: provider.secondaryPhone
        }
      };
      setProviderState(mappedProviderState);
      setIsReady(true);
    }
  });
  useEffect(() => {
    if (providerId) loadProvider(); // if providerId is passed then get Provider details
    else setIsReady(true); // component is ready to render form
  }, []);

  if (queryLoading || !isReady) {
    return __cssprop("div", {
      tw: "absolute bg-gray-50 z-10 h-full w-full opacity-60"
    }, __cssprop(Loader, null));
  }

  return __cssprop(ProviderSideBarFormView, providerState);
};
export default ProviderSideBar;