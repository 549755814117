import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const initialState = {
  id: "",
  startDate: DateTime.now(),
  endDate: DateTime.now().plus({
    year: 1
  }).minus({
    day: 1
  }),
  billDay: 1,
  monthlyPrice: 0,
  lastModifiedAt: DateTime.now(),
  cancelled: false,
  createdAt: null,
  files: [],
  notes: [],
  tenants: [],
  project: {},
  building: {},
  unit: {},
  amenities: [],
  duration: "ONE_YEAR",
  isLocked: false,
  tickets: {
    items: []
  },
  deliveryFinalizedAt: null
};
// =========== ACTIONS CREATORS =========== //
export let LeaseActionsTypes;

(function (LeaseActionsTypes) {
  LeaseActionsTypes["LEASE_SET_FIELD"] = "LEASE_SET_FIELD";
  LeaseActionsTypes["LEASE_SUBMIT"] = "LEASE_SUBMIT";
  LeaseActionsTypes["LEASE_INITIALIZE_WITH_LEASE"] = "LEASE_INITIALIZE_WITH_LEASE";
  LeaseActionsTypes["LEASE_INITIALIZE_WITH_LEASE_VIEW_ONLY"] = "LEASE_INITIALIZE_WITH_LEASE_VIEW_ONLY";
  LeaseActionsTypes["LEASE_INITIALIZE_WITH_RENEWAL"] = "LEASE_INITIALIZE_WITH_RENEWAL";
  LeaseActionsTypes["LEASE_INITIALIZE_WITH_UNIT"] = "LEASE_INITIALIZE_WITH_UNIT";
  LeaseActionsTypes["LEASE_INITIALIZE_WITH_BUILDING"] = "LEASE_INITIALIZE_WITH_BUILDING";
  LeaseActionsTypes["LEASE_CREATE_NEW_LEASE"] = "LEASE_CREATE_NEW_LEASE";
  LeaseActionsTypes["LEASE_CREATE_NEW_LEASE_EMPTY"] = "LEASE_CREATE_NEW_LEASE_EMPTY";
  LeaseActionsTypes["LEASE_ADD_FILES"] = "LEASE_ADD_FILES";
  LeaseActionsTypes["LEASE_REMOVE_FILES"] = "LEASE_REMOVE_FILES";
  LeaseActionsTypes["LEASE_CANCEL"] = "LEASE_CANCEL";
  LeaseActionsTypes["LEASE_LOAD_UNIT"] = "LEASE_LOAD_UNIT";
  LeaseActionsTypes["LEASE_LOAD_BUILDING"] = "LEASE_LOAD_BUILDING";
  LeaseActionsTypes["LEASE_CHANGE_UNIT"] = "LEASE_CHANGE_UNIT";
  LeaseActionsTypes["LEASE_CHANGE_BUILDING"] = "LEASE_CHANGE_BUILDING";
  LeaseActionsTypes["LEASE_ADD_NOTES"] = "LEASE_ADD_NOTES";
  LeaseActionsTypes["LEASE_REMOVE_NOTES"] = "LEASE_REMOVE_NOTES";
  LeaseActionsTypes["LEASE_ADD_NOTES_FORMLESS"] = "LEASE_ADD_NOTES_FORMLESS";
  LeaseActionsTypes["LEASE_REMOVE_NOTES_FORMLESS"] = "LEASE_REMOVE_NOTES_FORMLESS";
  LeaseActionsTypes["LEASE_ADD_FILES_FORMLESS"] = "LEASE_ADD_FILES_FORMLESS";
  LeaseActionsTypes["LEASE_REMOVE_FILES_FORMLESS"] = "LEASE_REMOVE_FILES_FORMLESS";
})(LeaseActionsTypes || (LeaseActionsTypes = {}));