/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import "../../../../_snowpack/pkg/react-phone-input-2/lib/bootstrap.css";
import LocalPhoneInput from "../../../../_snowpack/pkg/react-phone-input-2.js";
import Fr from "../../../../_snowpack/pkg/react-phone-input-2/lang/fr.json";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import { Container } from "./styles.js";
import { AlertIcon } from "../Icons.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
const BASE_STYLE = {
  width: "100%",
  maxHeight: "2.4rem",
  borderRadius: "3px"
};
export const PhoneInput = ({
  disabled = false,
  label = "",
  value,
  errorLabel = "",
  onChange,
  zIndex
}) => {
  const language = useSelector(Selectors.session.getUserLanguage);
  const [local, setLocal] = useState(Fr);
  useEffect(() => {
    if (language === "fr") {
      setLocal(Fr);
    } else {
      setLocal(undefined);
    }
  }, [language]);
  const inputStyle = useMemo(() => {
    if (disabled) {
      return { ...BASE_STYLE,
        backgroundColor: "#f9fafb",
        color: "#919eab"
      };
    }

    if (errorLabel) {
      return { ...BASE_STYLE,
        backgroundColor: "#fbeae5",
        borderColor: "#bf0711"
      };
    }

    return BASE_STYLE;
  }, [disabled, errorLabel]);

  const handleChange = phoneNumber => {
    /** adding the + here because phone input dont put it in the value */
    onChange(`+${phoneNumber}`);
  };

  return __cssprop(Container, null, __cssprop("div", null, __cssprop("div", {
    css: {
      "paddingBottom": "0.4rem"
    }
  }, label), __cssprop(LocalPhoneInput, {
    country: "ca",
    value: value,
    localization: local,
    onChange: handleChange,
    inputStyle: inputStyle,
    containerStyle: {
      zIndex
    },
    disabled: disabled,
    isValid: (v, c) => !errorLabel,
    inputProps: {
      autoFocus: false
    },
    countryCodeEditable: false
  })), !!errorLabel && __cssprop("div", {
    css: {
      "paddingRight": "1.2rem",
      "display": "flex",
      "alignItems": "center",
      "pointerEvents": "none"
    }
  }, __cssprop(IsError, {
    label: errorLabel
  })));
};

const IsError = ({
  label
}) => {
  return __cssprop("div", {
    css: {
      "display": "flex",
      "justifyContent": "center",
      "alignItems": "center",
      "alignContent": "center",
      "alignSelf": "center",
      "justifySelf": "center",
      "marginTop": "0.4rem"
    }
  }, __cssprop("div", {
    css: {
      "height": "2.8rem",
      "width": "2.8rem",
      "marginRight": "0.4rem"
    }
  }, __cssprop(AlertIcon, null)), __cssprop("div", {
    style: {
      color: "#bf0711"
    }
  }, label));
};