/* eslint-disable import/no-cycle */

/* eslint-disable no-underscore-dangle */
import { CancelLeaseDocument, CreateLeaseDocument, GetBuildingDocument, GetLeaseDocument, GetUnitDocument, UpdateLeaseDocument } from "../../../graphql/documents.js";
import { TYPES } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { Selectors } from "../../index.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import { all, call, getContext, put, putResolve, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { addFilesHandler, uploadDocuments } from "../Files/sagas.js";
import { addServerNotes, removeServerNotes } from "../Notes/sagas.js";
import StatusActions from "../Status/actions.js";
import { UnitActionsTypes } from "../Unit/types.js";
import { fromGetLease, toLeaseInputMapper, toUpdateLeaseInputMapper } from "./mapper.js";
import { initialState, LeaseActionsTypes } from "./types.js";

function* onLoadUnit($data) {
  const {
    payload
  } = $data;
  const client = yield getContext("client");

  try {
    yield put(StatusActions.setResourceToLoading("unit"));
    const query = client.query({
      query: GetUnitDocument,
      fetchPolicy: "no-cache",
      variables: {
        id: payload
      }
    });
    const {
      data,
      error
    } = yield query;

    if (error) {
      yield put(StatusActions.setResourceToError("unit", "generic-fetch-error-message"));
      return;
    }

    yield put(StatusActions.setResourceToIdle("unit"));
    yield put({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        unit: data.unit,
        building: data.unit.building
      }
    });
  } catch (e) {
    yield put(StatusActions.setResourceToError("unit", "generic-fetch-error-message"));
  }
}

function* onLoadBuilding($data) {
  const {
    payload
  } = $data;
  const client = yield getContext("client");

  try {
    yield put(StatusActions.setResourceToLoading("building"));
    const query = client.query({
      query: GetBuildingDocument,
      fetchPolicy: "no-cache",
      variables: {
        id: payload
      }
    });
    const {
      data,
      error
    } = yield query;

    if (error) {
      yield put(StatusActions.setResourceToError("building", "generic-fetch-error-message"));
    }

    yield put(StatusActions.setResourceToIdle("building"));
    yield put({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        building: data.building
      }
    });
  } catch (e) {
    yield put(StatusActions.setResourceToError("building", "generic-fetch-error-message"));
  }
}

function* onChangeBuilding($data) {
  yield call(onLoadBuilding, {
    payload: $data.payload
  });
  const id = yield select(Selectors.lease.getId);

  if (id) {
    return;
  }

  const building = yield select(Selectors.lease.getBuilding);
  yield put({
    type: LeaseActionsTypes.LEASE_SET_FIELD,
    payload: {
      amenities: building.amenities
    }
  });
}

function* onChangeUnit($data) {
  yield call(onLoadUnit, {
    payload: $data.payload
  });
  const id = yield select(Selectors.lease.getId);

  if (id) {
    return;
  }

  const unit = yield select(Selectors.lease.getUnit); // Unit needs to be handled first, since amenitie in units take precedence

  const amenities = [...unit.amenities, ...unit.building.amenities].reduce((acc, amenity) => {
    const found = acc.find(a => a.key === amenity.key);

    if (!found) {
      acc.push(amenity);
    }

    return acc;
  }, []);
  yield put({
    type: LeaseActionsTypes.LEASE_SET_FIELD,
    payload: {
      amenities
    }
  });
}

function* onLeaseInitializeFromBuilding($data) {
  const {
    payload
  } = $data;
  yield putResolve({
    type: LeaseActionsTypes.LEASE_SET_FIELD,
    payload: { ...initialState
    }
  });
  yield put({
    type: LeaseActionsTypes.LEASE_CHANGE_BUILDING,
    payload
  });
}

function* onLeaseInitializeFromUnit($data) {
  const {
    payload
  } = $data;
  yield putResolve({
    type: LeaseActionsTypes.LEASE_SET_FIELD,
    payload: { ...initialState
    }
  });
  yield put({
    type: LeaseActionsTypes.LEASE_CHANGE_UNIT,
    payload
  });
}

function* onCreateNewLeaseEmpty() {
  yield putResolve({
    type: LeaseActionsTypes.LEASE_SET_FIELD,
    payload: { ...initialState
    }
  });
}

function* onLeaseInitializeFromLease($data) {
  const {
    payload
  } = $data;

  if (!payload) {
    yield put({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: initialState
    });
    return;
  }

  const client = yield getContext("client");

  try {
    yield put(StatusActions.setResourceToLoading("lease"));
    const query = client.query({
      query: GetLeaseDocument,
      fetchPolicy: "no-cache",
      variables: {
        id: payload
      }
    });
    const {
      data,
      error
    } = yield query;

    if (error) {
      yield put(StatusActions.setResourceToError("lease", "generic-fetch-error-message"));
    }

    yield put(StatusActions.setResourceToIdle("lease"));
    yield put({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: { ...fromGetLease(data)
      }
    });
  } catch (e) {
    yield put(StatusActions.setResourceToError("lease", "generic-fetch-error-message"));
  }
}

function* onLeaseInitializeFromLeaseViewOnly($data) {
  yield onLeaseInitializeFromLease($data);
  yield put({
    type: LeaseActionsTypes.LEASE_SET_FIELD,
    payload: {
      isLocked: true
    }
  });
}

function* onLeaseRenewalInitialize($data) {
  const {
    payload
  } = $data;

  if (!payload) {
    yield put({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: initialState
    });
    return;
  }

  const client = yield getContext("client");

  try {
    yield put(StatusActions.setResourceToLoading("lease"));
    const query = client.query({
      query: GetLeaseDocument,
      fetchPolicy: "no-cache",
      variables: {
        id: payload
      }
    });
    const {
      data,
      error
    } = yield query;

    if (error) {
      yield put(StatusActions.setResourceToError("lease", "generic-fetch-error-message"));
    }

    yield put(StatusActions.setResourceToIdle("lease"));
    const mappedLease = fromGetLease(data);
    const draftRenewal = { ...mappedLease,
      isLocked: false,
      startDate: DateTime.fromISO(mappedLease.endDate).plus({
        day: 1
      }),
      endDate: DateTime.fromISO(mappedLease.endDate).plus({
        year: 1
      }),
      files: mappedLease.files,
      notes: mappedLease.notes
    };
    yield put({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: draftRenewal
    });
  } catch (e) {
    yield put(StatusActions.setResourceToError("lease", "generic-fetch-error-message"));
  }
}

function* updateLease(files) {
  const client = yield getContext("client");
  const lease = yield select(Selectors.lease.getLease);
  yield put(StatusActions.setResourceToSubmit("lease"));

  try {
    const query = client.query({
      query: UpdateLeaseDocument,
      fetchPolicy: "no-cache",
      variables: {
        lease: toUpdateLeaseInputMapper(lease)
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("lease", "leasing-form-saved-fail"));
      return;
    }

    yield addFilesHandler(files, data.updateLease.id, RESOURCES.Lease);
    yield addServerNotes({
      resourceId: data.updateLease.id,
      resourceType: "lease",
      notes: lease.notes
    });
    yield removeServerNotes({
      notes: lease.notes,
      resourceType: "lease"
    });
    yield put(StatusActions.setResourceToSuccess("lease", "leasing-form-saved-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("lease", "leasing-form-saved-fail"));
  }
}

function* createLease(isRenewal, files) {
  const client = yield getContext("client");
  const history = yield getContext("history");
  const lease = yield select(Selectors.lease.getLease);
  yield put(StatusActions.setResourceToSubmit("lease"));

  try {
    const query = client.query({
      query: CreateLeaseDocument,
      fetchPolicy: "no-cache",
      variables: {
        lease: toLeaseInputMapper(lease, isRenewal ? lease.id : null)
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("lease", "leasing-form-saved-fail"));
      return;
    }

    yield addFilesHandler(files, data.createLease.id, RESOURCES.Lease);
    yield addServerNotes({
      resourceId: data.createLease.id,
      resourceType: "lease",
      notes: lease.notes
    });
    yield put(StatusActions.setResourceToSuccess("lease", "leasing-form-saved-success"));
    yield put({
      type: UnitActionsTypes.UNIT_INITIALIZE,
      payload: lease.unit.id
    });
    history.push(`/unit/${lease.unit.id}`);
  } catch (e) {
    yield put(StatusActions.setResourceToError("lease", "leasing-form-saved-fail"));
  }
}

function* onLeaseSubmit($data) {
  const lease = yield select(Selectors.lease.getLease);
  const files = yield uploadDocuments(lease.files, RESOURCES.Unit, TYPES.Files);

  if ($data.payload === true) {
    yield createLease(true, files);
  } else if (lease.id) {
    yield updateLease(files);
  } else {
    yield createLease(false, files);
  }
}

function* onCancelLease($data) {
  const {
    date,
    willTerminateEarlier
  } = $data.payload;
  const client = yield getContext("client");
  const history = yield getContext("history");
  const lease = yield select(Selectors.lease.getLease);
  yield put(StatusActions.setResourceToSubmit("lease"));

  try {
    const query = client.query({
      query: CancelLeaseDocument,
      fetchPolicy: "no-cache",
      variables: {
        id: lease.id,
        terminateEarlier: willTerminateEarlier ? date : null
      }
    });
    const {
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("lease", "leasing-form-saved-fail"));
      return;
    }

    yield put(StatusActions.setResourceToSuccess("lease", "leasing-form-saved-success"));
    yield put({
      type: UnitActionsTypes.UNIT_INITIALIZE,
      payload: lease.unit.id
    });
    history.push(`/unit/${lease.unit.id}`);
  } catch (e) {
    yield put(StatusActions.setResourceToError("lease", "leasing-form-saved-fail"));
  }
}

function* onAddFiles($data) {
  const files = $data.payload;
  const lease = yield select(Selectors.lease.getLease);
  const filesUploaded = yield uploadDocuments([...lease.files, ...files], RESOURCES.Lease, TYPES.Files);
  yield addFilesHandler(filesUploaded, lease.id, RESOURCES.Lease);
  yield put({
    type: LeaseActionsTypes.LEASE_ADD_FILES,
    payload: files
  });
}

function* onRemoveFiles($data) {
  const files = $data.payload;
  const lease = yield select(Selectors.lease.getLease);
  const newFiles = lease.files.filter(file => file.fileName !== files.fileName);
  const filesUploaded = yield uploadDocuments(newFiles, RESOURCES.Lease, TYPES.Files);
  yield addFilesHandler(filesUploaded, lease.id, RESOURCES.Lease);
  yield put({
    type: LeaseActionsTypes.LEASE_REMOVE_FILES,
    payload: files
  });
}

export default {
  *executers() {
    yield all([yield takeLatest(LeaseActionsTypes.LEASE_SUBMIT, onLeaseSubmit), yield takeLatest(LeaseActionsTypes.LEASE_LOAD_BUILDING, onLoadBuilding), yield takeLatest(LeaseActionsTypes.LEASE_LOAD_UNIT, onLoadUnit), yield takeLatest(LeaseActionsTypes.LEASE_CHANGE_BUILDING, onChangeBuilding), yield takeLatest(LeaseActionsTypes.LEASE_CHANGE_UNIT, onChangeUnit), yield takeLatest(LeaseActionsTypes.LEASE_INITIALIZE_WITH_LEASE, onLeaseInitializeFromLease), yield takeLatest(LeaseActionsTypes.LEASE_INITIALIZE_WITH_LEASE_VIEW_ONLY, onLeaseInitializeFromLeaseViewOnly), yield takeLatest(LeaseActionsTypes.LEASE_INITIALIZE_WITH_RENEWAL, onLeaseRenewalInitialize), yield takeLatest(LeaseActionsTypes.LEASE_INITIALIZE_WITH_BUILDING, onLeaseInitializeFromBuilding), yield takeLatest(LeaseActionsTypes.LEASE_INITIALIZE_WITH_UNIT, onLeaseInitializeFromUnit), yield takeLatest(LeaseActionsTypes.LEASE_CREATE_NEW_LEASE_EMPTY, onCreateNewLeaseEmpty), yield takeLatest(LeaseActionsTypes.LEASE_CANCEL, onCancelLease), yield takeLatest(LeaseActionsTypes.LEASE_ADD_FILES_FORMLESS, onAddFiles), yield takeLatest(LeaseActionsTypes.LEASE_REMOVE_FILES_FORMLESS, onRemoveFiles)]);
  }

};