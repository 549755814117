function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { ExclamationIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import useI18n from "../../hooks/useI18n.js";
import { useAction } from "../../hooks/useRedux.js";
import React, { useState } from "../../../../_snowpack/pkg/react.js";
import { useDropzone } from "../../../../_snowpack/pkg/react-dropzone.js";
import { Attachments } from "./Attachments.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledDiv = _styled.div(({
  errors
}) => [{
  "display": "flex",
  "justifyContent": "center",
  "paddingLeft": "2.4rem",
  "paddingRight": "2.4rem",
  "paddingTop": "2rem",
  "paddingBottom": "2.4rem",
  "borderWidth": "2px",
  "borderStyle": "dashed",
  "borderRadius": "0.6rem"
}, errors ? {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(251, 191, 36, var(--tw-border-opacity))"
} : {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))"
}]);

export const FileDropZone = props => {
  const {
    t
  } = useI18n();
  const {
    onDropDispatch,
    files,
    disabled = false,
    inForm = false,
    onFilesDrop,
    onFilesDelete,
    onDeleteDispatch,
    highlightText = t("file-uploader-add-file"),
    description = t("file-uploader-drop-file"),
    readOnly,
    fileCategory
  } = props;
  const {
    dispatch
  } = useAction();
  const [error, setError] = useState(false);
  const onDrop = React.useCallback(acceptedFiles => {
    if (inForm) {
      const mappedFiles = acceptedFiles.map((f, idx) => {
        return {
          kind: "local",
          id: `${f.name}-${f.size}`,
          order: idx,
          path: f.path,
          blob: new Blob([f]),
          preview: URL.createObjectURL(f),
          fileName: f.name,
          fileType: f.type,
          size: f.size
        };
      });
      onFilesDrop(mappedFiles);
    } else {
      dispatch({
        type: onDropDispatch,
        payload: acceptedFiles.map((f, idx) => {
          return {
            kind: "local",
            id: `${f.name}-${f.size}`,
            order: idx,
            path: f.path,
            blob: new Blob([f]),
            preview: URL.createObjectURL(f),
            fileName: f.name,
            fileType: f.type,
            size: f.size,
            fileCategory: fileCategory || "other"
          };
        })
      });
    }
  }, [inForm, onDropDispatch, onFilesDrop]);

  const onDropRejected = e => {
    e.forEach(er => {
      if (er.errors.filter(se => se.code === "file-too-large")) {
        setError(true);
      }
    });
  };

  const onDropAccepted = () => {
    setError(false);
  };

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop,
    accept: "",
    maxSize: 10000000,
    onDropRejected,
    onDropAccepted,
    disabled
  });
  return __cssprop("div", null, __cssprop("div", {
    css: {
      "marginTop": "0.4rem",
      "@media (min-width: 640px)": {
        "gridColumn": "span 2 / span 2",
        "marginTop": "0px"
      }
    }
  }, error && __cssprop("div", {
    css: {
      "marginBottom": "2rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 251, 235, var(--tw-bg-opacity))",
      "borderLeftWidth": "4px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(251, 191, 36, var(--tw-border-opacity))",
      "padding": "1.6rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "flexShrink": "0"
    }
  }, __cssprop(ExclamationIcon, {
    css: {
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(251, 191, 36, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })), __cssprop("div", {
    css: {
      "marginLeft": "1.2rem"
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(180, 83, 9, var(--tw-text-opacity))"
    }
  }, t("file-too-large"))))), !readOnly && __cssprop(StyledDiv, _extends({
    errors: error
  }, getRootProps({
    className: "dropzone"
  })), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "textAlign": "center"
    }
  }, __cssprop("svg", {
    css: {
      "marginLeft": "auto",
      "marginRight": "auto",
      "height": "4.8rem",
      "width": "4.8rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    stroke: "currentColor",
    fill: "none",
    viewBox: "0 0 48 48",
    "aria-hidden": "true"
  }, __cssprop("path", {
    d: "M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __cssprop("div", {
    css: {
      "display": "flex",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
    }
  }, __cssprop("label", {
    htmlFor: "file-upload",
    css: {
      "position": "relative",
      "cursor": "pointer",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderRadius": "0.6rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
      },
      ":focus-within": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop("span", {
    css: disabled && {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, highlightText), __cssprop("input", getInputProps())), __cssprop("p", {
    css: {
      "paddingLeft": "0.4rem"
    }
  }, description)), __cssprop("p", {
    css: {
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("up-to-10-mb"))))), __cssprop("div", {
    css: {
      "marginTop": "1.6rem"
    }
  }, __cssprop(Attachments, {
    visualOnly: readOnly,
    handleDeleteFile: e => {
      if (onFilesDelete) {
        onFilesDelete(e);
        return;
      }

      dispatch({
        type: onDeleteDispatch,
        payload: e
      });
    },
    files: files?.map(attachement => {
      if (attachement.kind === "local") {
        return {
          fileName: attachement.fileName,
          url: attachement.preview,
          fileType: attachement.fileType
        };
      }

      return {
        fileName: attachement.fileName,
        url: attachement.url,
        fileType: attachement.fileType,
        filePath: attachement.filePath
      };
    })
  })));
};