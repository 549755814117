/* eslint-disable no-underscore-dangle */
import { FeatureType } from "../../../graphql/documents.js";
import { mapFiles } from "../../utils/Files.js";
import { mapNotes } from "../../utils/Notes.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const toBuildingInputMapper = building => {
  const {
    city,
    civicNumber,
    country,
    latitude,
    longitude,
    managementStartDate,
    postalCode,
    province,
    streetName,
    tags,
    buildDate,
    image,
    purchaseDate,
    sector,
    amenities
  } = building;
  return {
    city,
    civicNumber,
    country,
    latitude,
    longitude,
    managementStartDate,
    postalCode,
    province,
    streetName,
    tags,
    buildDate,
    image,
    purchaseDate,
    sector,
    buildingUnits: [],
    amenities: amenities.map(amenity => {
      return {
        key: FeatureType[amenity.key] || amenity.key,
        value: amenity.value || true,
        price: amenity.price,
        pricingType: amenity.pricingType
      };
    })
  };
};
export const fromGetBuilding = payload => {
  const {
    building
  } = payload;
  return { ...building,
    buildDate: building.buildDate ? DateTime.fromISO(building.buildDate).toUTC().toISODate() : null,
    managementStartDate: DateTime.fromISO(building.managementStartDate).toUTC().toISODate(),
    purchaseDate: building.purchaseDate ? DateTime.fromISO(building.purchaseDate) : null,
    units: building.units,
    project: building.project,
    notes: mapNotes(building.notes.items),
    files: mapFiles(building.files.items),
    tickets: building?.tickets
  };
};
export const fromCreateBuilding = payload => {
  const {
    id
  } = payload.addBuilding;
  return {
    id
  };
};
export const fromUpdateBuilding = payload => {
  const {
    id
  } = payload.updateBuilding;
  return {
    id
  };
};