import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { css } from "../../../../_snowpack/pkg/@emotion/css.js";
import { Dialog, Transition } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { ExclamationIcon } from "../../../../_snowpack/pkg/@heroicons/react/outline.js";
import { CheckIcon } from "../../../../_snowpack/pkg/@heroicons/react/solid.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import React, { Fragment } from "../../../../_snowpack/pkg/react.js";
import { Button } from "./Button.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const IconWrapper = _styled.div(({
  type
}) => [{
  "marginLeft": "auto",
  "marginRight": "auto",
  "alignItems": "center",
  "justifyContent": "center",
  "height": "4.8rem",
  "width": "4.8rem",
  "borderRadius": "9999px",
  "flexShrink": "0",
  "display": "flex",
  "@media (min-width: 640px)": {
    "marginLeft": "0px",
    "marginRight": "0px",
    "height": "4rem",
    "width": "4rem"
  }
}, type === "destructive" && [{
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(254, 226, 226, var(--tw-bg-opacity))"
}], type === "successful" && [{
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(209, 250, 229, var(--tw-bg-opacity))"
}]]);

const getIcon = type => {
  switch (type) {
    case "destructive":
      return __cssprop(ExclamationIcon, {
        css: {
          "height": "2.4rem",
          "width": "2.4rem",
          "--tw-text-opacity": "1",
          "color": "rgba(220, 38, 38, var(--tw-text-opacity))"
        },
        "aria-hidden": "true"
      });

    case "successful":
    default:
      return __cssprop(CheckIcon, {
        css: {
          "height": "2.4rem",
          "width": "2.4rem",
          "--tw-text-opacity": "1",
          "color": "rgba(5, 150, 105, var(--tw-text-opacity))"
        },
        "aria-hidden": "true"
      });
  }
};

export const Modal = ({
  modalName,
  title,
  description,
  buttonContent,
  type,
  onAction,
  other,
  handleSubmit = null
}) => {
  const {
    dispatch
  } = useAction();
  const modalState = useSelector(Selectors.app.getModalState);
  const {
    t
  } = useI18n();
  return __cssprop(Transition.Root, {
    show: modalState === modalName,
    as: Fragment
  }, __cssprop(Dialog, {
    as: "div",
    css: {
      "position": "fixed",
      "zIndex": "30",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "overflowY": "auto"
    },
    onClose: () => {
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
        payload: null
      });
    }
  }, __cssprop("form", {
    onSubmit: handleSubmit || null
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "flex-end",
      "justifyContent": "center",
      "minHeight": "100vh",
      "paddingTop": "1.6rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingBottom": "8rem",
      "textAlign": "center",
      "@media (min-width: 640px)": {
        "display": "block",
        "padding": "0px"
      }
    }
  }, __cssprop(Transition.Child, {
    as: Fragment,
    enter: css({
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
      "transitionDuration": "300ms"
    }),
    enterFrom: css({
      "opacity": "0"
    }),
    enterTo: css({
      "opacity": "1"
    }),
    leave: css({
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "200ms"
    }),
    leaveFrom: css({
      "opacity": "1"
    }),
    leaveTo: css({
      "opacity": "0"
    })
  }, __cssprop(Dialog.Overlay, {
    css: {
      "position": "fixed",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "--tw-bg-opacity": "0.75",
      "backgroundColor": "rgba(107, 114, 128, var(--tw-bg-opacity))",
      "transitionProperty": "opacity",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms"
    }
  })), __cssprop("span", {
    css: {
      "display": "none",
      "@media (min-width: 640px)": {
        "display": "inline-block",
        "verticalAlign": "middle",
        "height": "100vh"
      }
    },
    "aria-hidden": "true"
  }, "\u200B"), __cssprop(Transition.Child, {
    as: Fragment,
    enter: css({
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
      "transitionDuration": "300ms"
    }),
    enterFrom: css({
      "opacity": "0",
      "--tw-translate-y": "1.6rem",
      "@media (min-width: 640px)": {
        "--tw-translate-y": "0px",
        "--tw-scale-x": ".95",
        "--tw-scale-y": ".95"
      }
    }),
    enterTo: css({
      "opacity": "1",
      "--tw-translate-y": "0px",
      "@media (min-width: 640px)": {
        "--tw-scale-x": "1",
        "--tw-scale-y": "1"
      }
    }),
    leave: css({
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "200ms"
    }),
    leaveFrom: css({
      "opacity": "1",
      "--tw-translate-y": "0px",
      "@media (min-width: 640px)": {
        "--tw-scale-x": "1",
        "--tw-scale-y": "1"
      }
    }),
    leaveTo: css({
      "opacity": "0",
      "--tw-translate-y": "1.6rem",
      "@media (min-width: 640px)": {
        "--tw-translate-y": "0px",
        "--tw-scale-x": ".95",
        "--tw-scale-y": ".95"
      }
    })
  }, __cssprop("div", {
    css: {
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "display": "inline-block",
      "verticalAlign": "bottom",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderRadius": "0.8rem",
      "textAlign": "left",
      "overflow": "hidden",
      "--tw-shadow": "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "transitionProperty": "all",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "150ms",
      "@media (min-width: 640px)": {
        "marginTop": "3.2rem",
        "marginBottom": "3.2rem",
        "verticalAlign": "middle",
        "maxWidth": "51.2rem",
        "width": "100%"
      }
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "2rem",
      "paddingBottom": "1.6rem",
      "@media (min-width: 640px)": {
        "padding": "2.4rem",
        "paddingBottom": "1.6rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "display": "flex",
        "alignItems": "flex-start"
      }
    }
  }, __cssprop(IconWrapper, {
    type: type
  }, getIcon(type)), __cssprop("div", {
    css: {
      "marginTop": "1.2rem",
      "textAlign": "center",
      "width": "75%",
      "@media (min-width: 640px)": {
        "marginTop": "0px",
        "marginLeft": "1.6rem",
        "textAlign": "left"
      }
    }
  }, __cssprop(Dialog.Title, {
    as: "h3",
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, title), __cssprop("div", {
    css: {
      "marginTop": "0.8rem"
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, description)), other && __cssprop("div", {
    css: {
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem"
    }
  }, other)))), __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "1.2rem",
      "paddingBottom": "1.2rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem",
        "display": "flex",
        "flexDirection": "row-reverse"
      }
    }
  }, __cssprop(Button, {
    grouped: true,
    onAction: () => {
      if (!onAction) {
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATUS,
          payload: false
        });
      }

      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
        payload: null
      });
    }
  }, t("cancel")), __cssprop(Button, {
    submit: !!handleSubmit,
    primary: type !== "destructive",
    destructive: type === "destructive",
    onAction: () => {
      if (onAction) {
        onAction();
      } else {
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATUS,
          payload: true
        });
      }

      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
        payload: null
      });
    }
  }, buttonContent))))))));
};