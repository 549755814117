import { createReducer } from "../../helpers/actions.js";
import { initialState, StatusActionsTypes } from "./types.js";
export const setLoadingState = ({
  payload: {
    type
  }
}, state) => {
  return { ...state,
    [type]: "Loading"
  };
};
export const setErrorState = ({
  payload: {
    type
  }
}, state) => {
  return { ...state,
    [type]: "Error"
  };
};
export const setSuccessState = ({
  payload: {
    type
  }
}, state) => {
  return { ...state,
    [type]: "Success"
  };
};
export const setSubmitState = ({
  payload: {
    type
  }
}, state) => {
  return { ...state,
    [type]: "Submit"
  };
};
export const setIdleState = ({
  payload: {
    type
  }
}, state) => {
  return { ...state,
    [type]: "Idle"
  };
};
export const resetStatuses = () => {
  return { ...initialState
  };
};
export default createReducer(initialState, {
  [StatusActionsTypes.STATUS_SET_IDLE]: setIdleState,
  [StatusActionsTypes.STATUS_SET_SUCCESS]: setSuccessState,
  [StatusActionsTypes.STATUS_SET_LOADING]: setLoadingState,
  [StatusActionsTypes.STATUS_SET_ERROR]: setErrorState,
  [StatusActionsTypes.STATUS_SET_SUBMIT]: setSubmitState,
  [StatusActionsTypes.RESET_ALL_STATUS]: setSubmitState
});