import React from "../../../../../_snowpack/pkg/react.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { ListingActionsTypes } from "../../../store/artifacts/Listing/types.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const PanelNotesView = ({
  isLocked = false
}) => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const note = useSelector(Selectors.listing.getNote);
  return __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginBottom": "0.4rem"
    }
  }, t("notes-title")), __cssprop("div", {
    css: {
      "marginTop": "0.8rem",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between"
    }
  }, __cssprop("textarea", {
    name: "note",
    rows: 3,
    css: {
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
      },
      "marginTop": "0.4rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.6rem",
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    },
    defaultValue: note || "",
    onChange: e => dispatch({
      type: ListingActionsTypes.LISTING_SET_FIELD,
      payload: {
        note: e.target.value
      }
    })
  })));
};