/* eslint-disable import/no-cycle */
import { all, getContext, put, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { CreateTicketEventDocument, CreateTicketReoccurEventDocument, GetEventsDocument, UpdateTicketEventDocument } from "../../../graphql/documents.js";
import { Selectors } from "../../index.js";
import { CalendarActionTypes } from "../Calendar/types.js";
import StatusActions from "../Status/actions.js";
import { EventActionTypes } from "./types.js";

function* onInitializeEvent($data) {
  const client = yield getContext("client");
  const {
    payload
  } = $data;

  try {
    yield put(StatusActions.setResourceToLoading("event"));
    const query = client.query({
      query: GetEventsDocument,
      fetchPolicy: "no-cache"
    });
    const {
      data
    } = yield query;
    const event = data.getEvents.items.find(eventItem => eventItem.id === payload.id);
    yield put({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: { ...event
      }
    });
    yield put(StatusActions.setResourceToIdle("event"));
  } catch (e) {
    yield put(StatusActions.setResourceToIdle("event"));
    yield put(StatusActions.setResourceToError("event", "generic-fetch-error-message"));
  }
}

function* createEvent() {
  const client = yield getContext("client");
  const event = yield select(Selectors.event.getEvent);
  const resourceObject = {
    userId: event.entity?.resource.user?.userId || event.entity?.resource?.userId || event.entity?.resource.user?.id || event.entity?.resource.tenant?.userId || event.entity?.resource.tenant?.id || event.entity?.resource.tenant?.value || event.entity?.resource.user?.value || null,
    spaceId: event.entity?.resource.building?.spaceId || event.entity?.resource?.spaceId || event.entity?.resource.building?.id || event.entity?.resource.building?.value || null,
    projectId: event.entity?.resource.project?.projectId || event.entity?.resource?.projectId || event.entity?.resource.project?.id || event.entity?.resource.client?.id || null // leaseId: event.entity?.resource.lease?.leaseId || event.entity?.resource.lease?.id || event.entity?.resource?.leaseId || null,

  };
  const {
    __typename,
    ...eventData
  } = event.entity;

  try {
    const query = client.query({
      query: CreateTicketEventDocument,
      fetchPolicy: "no-cache",
      variables: {
        event: {
          title: event.title,
          description: event.description,
          RRule: event.RRule,
          entityMetadata: { ...eventData,
            resource: resourceObject
          }
        }
      }
    });
    const {
      data
    } = yield query;
    yield put({
      type: EventActionTypes.EVENT_SET_FIELD,
      payload: { ...data.createTicketEvent
      }
    });
    yield put({
      type: CalendarActionTypes.CALENDAR_ADD_ITEM,
      // eslint-disable-next-line no-underscore-dangle
      payload: { ...data.createTicketEvent,
        typename: data.createTicketEvent.__typename
      }
    });
    yield put(StatusActions.setResourceToSuccess("event", "event-form-add-event-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("event", "event-form-add-event-fail"));
  }
}

function* createRecoccuringEvent() {
  const client = yield getContext("client");
  const event = yield select(Selectors.event.getEvent);
  const ticketId = yield select(Selectors.ticket.getId);

  try {
    const query = client.query({
      query: CreateTicketReoccurEventDocument,
      fetchPolicy: "no-cache",
      variables: {
        event: {
          title: event.title,
          description: event.description,
          RRule: event.RRule,
          entityMetadata: event.entity.id
        }
      }
    });

    if (!ticketId) {
      const {
        data
      } = yield query;
      yield put({
        type: EventActionTypes.EVENT_SET_FIELD,
        payload: { ...data.createTicketReoccurEvent
        }
      });
      yield put({
        type: CalendarActionTypes.CALENDAR_ADD_ITEM,
        // eslint-disable-next-line no-underscore-dangle
        payload: { ...data.createTicketReoccurEvent,
          typename: data.createTicketReoccurEvent.__typename
        }
      });
    }

    yield put(StatusActions.setResourceToSuccess("event", "event-form-add-event-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("event", "event-form-add-event-fail"));
  }
}

function* updateEvent() {
  const client = yield getContext("client");
  const event = yield select(Selectors.event.getEvent);
  const calendarState = yield select(Selectors.calendar.getEvents);
  const isCalendarTask = calendarState.find(item => item.id === event.id);

  try {
    const resourceObject = {
      userId: event.entity?.resource.user?.userId || event.entity?.resource?.userId || event.entity?.resource.user?.id || event.entity?.resource.tenant?.userId || event.entity?.resource.tenant?.id || event.entity?.resource.tenant?.value || event.entity?.resource.user?.value || null,
      spaceId: event.entity?.resource.building?.spaceId || event.entity?.resource?.spaceId || event.entity?.resource?.space?.id || event.entity?.resource.building?.id || event.entity?.resource.building?.value || null,
      projectId: event.entity?.resource.project?.projectId || event.entity?.resource?.projectId || event.entity?.resource.project?.id || event.entity?.resource.client?.id || null // leaseId: event.entity?.resource.lease?.leaseId || event.entity?.resource.lease?.id || event.entity?.resource?.leaseId || null,

    };
    const {
      __typename,
      ...eventData
    } = event.entity;
    const query = client.query({
      query: UpdateTicketEventDocument,
      fetchPolicy: "no-cache",
      variables: {
        id: event.id,
        event: {
          title: event.title,
          description: event.description,
          RRule: event.RRule,
          entityMetadata: { ...eventData,
            resource: resourceObject
          }
        }
      }
    });
    const {
      data
    } = yield query;

    if (isCalendarTask) {
      yield put({
        type: CalendarActionTypes.CALENDAR_UPDATE_ITEM,
        // eslint-disable-next-line no-underscore-dangle
        payload: { ...data.updateTicketEvent,
          typename: data.updateTicketEvent.__typename
        }
      });
    }

    yield put(StatusActions.setResourceToSuccess("event", "event-form-update-event-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("event", "event-form-update-event-fail"));
  }
}

export default {
  *executers() {
    yield all([yield takeLatest(EventActionTypes.EVENT_INITIALIZE, onInitializeEvent), yield takeLatest(EventActionTypes.EVENT_CREATE, createEvent), yield takeLatest(EventActionTypes.EVENT_CREATE_TICKET, createRecoccuringEvent), yield takeLatest(EventActionTypes.EVENT_UPDATE, updateEvent)]);
  }

};