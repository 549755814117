import { DatePicker } from "../../../components/DatePicker.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { DescriptionLayout, DescriptionLayoutItem, Layout } from "../../../components/Tailwind/Layout.js";
import { LeasePropertyPicker } from "../../../components/Tailwind/LeasePropertyPicker.js";
import { RadioGroup } from "../../../components/Tailwind/RadioGroup.js";
import { NumberTextField } from "../../../components/Tailwind/TextField.js";
import ToggleSection from "../../../components/ToggleSection.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { LeaseActionsTypes } from "../../../store/artifacts/Lease/types.js";
import FileDropper from "../../ApplicantRequestV2/components/fileDropper.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const DetailsView = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const fullPrice = useSelector(Selectors.lease.getMonthlyPrice);
  const billingDate = useSelector(Selectors.lease.getBillDay);
  const downPaymentAmount = useSelector(Selectors.lease.getDownPaymentAmount);
  const delivered = useSelector(Selectors.lease.getDelivered);
  const files = useSelector(Selectors.lease.getFiles);
  const leases = useSelector(Selectors.lease.getUnitLeasesInTheFuture);
  return __cssprop("div", {
    css: {
      "marginTop": "1.6rem"
    }
  }, __cssprop(DescriptionLayout, null, __cssprop(DescriptionLayoutItem, {
    title: t("lease-page-form-property-title"),
    description: t("lease-page-form-property-description")
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(LeasePropertyPicker, null)))), __cssprop(DescriptionLayoutItem, {
    title: t("lease-page-form-payment-title"),
    description: t("lease-page-form-payment-description")
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop("div", {
    css: {
      "marginBottom": "1.6rem"
    }
  }, __cssprop(NumberTextField, {
    label: t("unit-lease-info-label-monthly-price"),
    thousandSeparator: true,
    suffix: "$",
    onChange: pPrice => dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        monthlyPrice: pPrice
      }
    }),
    value: fullPrice
  })), __cssprop(Layout, null, __cssprop(Layout, {
    oneHalf: true
  }, __cssprop(NumberTextField // isAllowed={({ floatValue }) => floatValue >= 0 && floatValue <= 31}
  , {
    label: t("unit-lease-info-label-bill-day"),
    allowNegative: false,
    onChange: num => dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        billDay: num
      }
    }),
    value: billingDate
  })), __cssprop(Layout, {
    oneHalf: true
  }, __cssprop(NumberTextField // isAllowed={({ floatValue }) => floatValue >= 0 && floatValue <= 31}
  , {
    label: t("applicant-request-list-down-payment-amount-received"),
    allowNegative: false,
    onChange: num => dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        downPaymentAmount: num
      }
    }),
    value: downPaymentAmount
  })))))), __cssprop(DescriptionLayoutItem, {
    title: t("lease-page-form-delivery-title"),
    description: t("lease-page-form-delivery-description")
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(ToggleSection, {
    title: t("delivery-marked-delivered"),
    active: delivered,
    handleToggle: v => dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        deliveryFinalizedAt: !delivered ? DateTime.utc() : null
      }
    })
  })))), __cssprop(DescriptionLayoutItem, {
    title: t("lease-page-form-date-title"),
    description: __cssprop(React.Fragment, null, t("lease-page-form-date-description"), leases && leases.map(l => __cssprop("div", {
      css: {
        "borderRadius": "0.6rem",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 251, 235, var(--tw-bg-opacity))",
        "padding": "1.6rem",
        "marginTop": "1.6rem"
      }
    }, __cssprop("div", {
      css: {
        "display": "flex"
      }
    }, __cssprop("div", {
      css: {
        "flexShrink": "0"
      }
    }, __cssprop("svg", {
      css: {
        "height": "2rem",
        "width": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(251, 191, 36, var(--tw-text-opacity))"
      },
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20 20",
      fill: "currentColor",
      "aria-hidden": "true"
    }, __cssprop("path", {
      fillRule: "evenodd",
      d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
      clipRule: "evenodd"
    }))), __cssprop("div", {
      css: {
        "marginLeft": "1.2rem",
        "flex": "1 1 0%",
        "@media (min-width: 768px)": {
          "display": "flex",
          "justifyContent": "space-between"
        }
      }
    }, __cssprop("p", {
      css: {
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(180, 83, 9, var(--tw-text-opacity))"
      }
    }, "There is currently a lease on this unit starting on ", DateTime.fromISO(l.startDate).toUTC().toFormat("MMMM dd, yyyy"), " until", " ", DateTime.fromISO(l.endDate).toUTC().toFormat("MMMM dd, yyyy")))))))
  }, __cssprop(DateRangePicker, null), __cssprop(LeaseDatePicker, null)), __cssprop(Card, null, __cssprop(FileDropper, {
    onDeleteDispatch: LeaseActionsTypes.LEASE_REMOVE_FILES,
    onDropDispatch: LeaseActionsTypes.LEASE_ADD_FILES,
    files: files
  }))));
};

const LeaseDatePicker = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const duration = useSelector(Selectors.lease.getDuration);
  const startDate = useSelector(Selectors.lease.getStartDate);
  const endDate = useSelector(Selectors.lease.getEndDate);
  return __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(Layout, null, __cssprop(Layout, {
    oneHalf: duration === "CUSTOM",
    fullWidth: duration === "ONE_YEAR" || !duration
  }, __cssprop(DatePicker, {
    id: "startDate",
    defaultValue: startDate,
    title: t("unit-lease-info-label-radio-start-date"),
    onChange: date => {
      dispatch({
        type: LeaseActionsTypes.LEASE_SET_FIELD,
        payload: {
          startDate: date
        }
      });

      if (duration === "ONE_YEAR") {
        dispatch({
          type: LeaseActionsTypes.LEASE_SET_FIELD,
          payload: {
            endDate: date.plus({
              year: 1
            }).minus({
              day: 1
            })
          }
        });
      }
    }
  })), duration === "CUSTOM" && __cssprop(Layout, {
    oneHalf: true
  }, __cssprop(DatePicker, {
    id: "endDate",
    defaultValue: endDate,
    title: t("unit-lease-info-label-radio-end-date"),
    onChange: date => dispatch({
      type: LeaseActionsTypes.LEASE_SET_FIELD,
      payload: {
        endDate: date
      }
    })
  })))), __cssprop(Card.Section, {
    greyed: true
  }, t("lease-page-form-date-start-on"), __cssprop("span", {
    css: {
      "fontWeight": "700"
    }
  }, startDate?.toUTC().toISODate()), t("lease-page-form-date-and-finish-on"), __cssprop("span", {
    css: {
      "fontWeight": "700"
    }
  }, endDate?.toUTC().toISODate()), t("lease-page-form-date-days", {
    days: Math.floor(endDate?.diff(startDate, "days").toObject().days)
  })));
};

const DateRangePicker = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const opt = useSelector(Selectors.lease.getDuration);
  const startDate = useSelector(Selectors.lease.getStartDate);
  const settings = [{
    id: "one-from-start",
    label: t("lease-page-form-date-one-year-start-date-title"),
    description: t("lease-page-form-date-one-year-start-date-description")
  }, {
    id: "from-range",
    label: t("lease-page-form-date-custom-start-date-title"),
    description: t("lease-page-form-date-custom-start-date-description")
  }];
  return __cssprop(RadioGroup, {
    onChange: option => {
      if (option === settings[0].id) {
        dispatch({
          type: LeaseActionsTypes.LEASE_SET_FIELD,
          payload: {
            endDate: startDate.plus({
              year: 1
            }).minus({
              day: 1
            })
          }
        });
        dispatch({
          type: LeaseActionsTypes.LEASE_SET_FIELD,
          payload: {
            duration: "ONE_YEAR"
          }
        });
      } else dispatch({
        type: LeaseActionsTypes.LEASE_SET_FIELD,
        payload: {
          duration: "CUSTOM"
        }
      });
    },
    selected: opt === "ONE_YEAR" ? settings[0] : settings[1],
    settings: settings
  });
};