export let CalendarActionTypes;

(function (CalendarActionTypes) {
  CalendarActionTypes["CALENDAR_INITIALIZE"] = "CALENDAR_INITIALIZE";
  CalendarActionTypes["CALENDAR_SET_FIELD"] = "CALENDAR_SET_FIELD";
  CalendarActionTypes["CALENDAR_SET_INITIAL_STATE"] = "CALENDAR_SET_INITIAL_STATE";
  CalendarActionTypes["CALENDAR_ADD_ITEM"] = "CALENDAR_ADD_ITEM";
  CalendarActionTypes["CALENDAR_UPDATE_ITEM"] = "CALENDAR_UPDATE_ITEM";
})(CalendarActionTypes || (CalendarActionTypes = {}));

export const initialState = {
  items: []
};