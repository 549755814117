import { useMutation } from "../../../../../../_snowpack/pkg/@apollo/client.js";
import { DatePicker } from "../../../../components/DatePicker.js";
import { SetRentalApplicationDocumentsSignatureDateDocument } from "../../../../graphql/documents.js";
import useI18n from "../../../../hooks/useI18n.js";
import { DateTime } from "../../../../../../_snowpack/pkg/luxon.js";
import React, { useCallback, useState } from "../../../../../../_snowpack/pkg/react.js";
import { toast } from "../../../../../../_snowpack/pkg/react-toastify.js";
import { ModifyButton } from "./modifyButton.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";
export const SignatureDate = props => {
  const {
    t
  } = useI18n();
  const {
    pSigDate,
    refetch,
    disabled,
    rentalApplicationId
  } = props;
  const [isEditFormSig, setIsEditFormSig] = useState(false);
  const [signatureDate, setSignatureDate] = useState();
  const [assignSignatureDate, {
    loading: signatureDateLoading
  }] = useMutation(SetRentalApplicationDocumentsSignatureDateDocument, {
    fetchPolicy: "no-cache"
  });
  const handleOnSaveSignature = useCallback(async pDate => {
    try {
      const signatureDateResponse = await assignSignatureDate({
        variables: {
          input: {
            rentalApplicationId,
            signatureDate: pDate.toISO()
          }
        }
      });

      if (signatureDateResponse.errors) {
        toast.warn(`${t("server-error")} ${signatureDateResponse.errors}`);
      }

      if (signatureDateResponse && signatureDateResponse.data) {
        setIsEditFormSig(!signatureDateResponse.data);
        refetch();
      }
    } catch (e) {
      toast.warn(`${t("server-error")} ${e}`);
    }
  }, [rentalApplicationId, assignSignatureDate]);

  if (isEditFormSig) {
    return __cssprop("div", {
      css: {
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }, __cssprop(DatePicker, {
      id: "date-picker",
      onChange: d => {
        setSignatureDate(d);
      },
      defaultValue: signatureDate || DateTime.fromISO(pSigDate).toUTC()
    }), __cssprop(ModifyButton, {
      disabled: disabled,
      loading: signatureDateLoading,
      inEdit: isEditFormSig,
      setEdit: setIsEditFormSig,
      handleOnSave: () => handleOnSaveSignature(signatureDate)
    }));
  }

  if (pSigDate) {
    return __cssprop(React.Fragment, null, __cssprop("span", {
      css: {
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "fontSize": "1.2rem",
        "lineHeight": "1.6rem",
        "fontWeight": "500"
      }
    }, t("applicant-request-list-signature-scheduled"), " : ", __cssprop("time", {
      dateTime: "2020-12-02"
    }, DateTime.fromISO(pSigDate).toISODate())), __cssprop(ModifyButton, {
      disabled: disabled,
      loading: signatureDateLoading,
      inEdit: isEditFormSig,
      setEdit: setIsEditFormSig,
      handleOnSave: () => handleOnSaveSignature(signatureDate)
    }));
  }

  return __cssprop(React.Fragment, null, __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, t("applicant-request-form-signature-date-empty")), __cssprop(ModifyButton, {
    disabled: disabled,
    loading: signatureDateLoading,
    inEdit: isEditFormSig,
    setEdit: setIsEditFormSig,
    handleOnSave: () => handleOnSaveSignature(signatureDate)
  }));
};