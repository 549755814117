import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { RadioGroup as Radios } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const RadioOption = _styled(Radios.Option)(({
  idx,
  settingsLength
}) => [idx === 0 && {
  "borderTopLeftRadius": "0.6rem",
  "borderTopRightRadius": "0.6rem"
}, idx === settingsLength - 1 && {
  "borderBottomLeftRadius": "0.6rem",
  "borderBottomRightRadius": "0.6rem"
}, {
  "position": "relative",
  "borderWidth": "1px",
  "padding": "1.6rem",
  "display": "flex",
  "cursor": "pointer",
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  }
}]);
export const RadioDescription = _styled(Radios.Description)(({
  checked
}) => [checked ? {
  "--tw-text-opacity": "1",
  "color": "rgba(67, 56, 202, var(--tw-text-opacity))"
} : {
  "--tw-text-opacity": "1",
  "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
}, {
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem"
}]);
export const RadioLabel = _styled(Radios.Label)(({
  checked
}) => [checked ? {
  "--tw-text-opacity": "1",
  "color": "rgba(49, 46, 129, var(--tw-text-opacity))"
} : {
  "--tw-text-opacity": "1",
  "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
}, {
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500"
}]);
export const StyledRadioSpan = _styled.span(({
  active,
  checked
}) => [{
  "height": "1.6rem",
  "width": "1.6rem",
  "marginTop": "0.2rem",
  "cursor": "pointer",
  "borderRadius": "9999px",
  "borderWidth": "1px",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center"
}, checked ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))",
  "borderColor": "transparent"
} : {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))"
}, active && {
  "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
  "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-offset-width": "2px",
  "--tw-ring-opacity": "1",
  "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
}]);
export const StyledRadioBg = _styled.span(({
  checked
}) => [checked ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(238, 242, 255, var(--tw-bg-opacity))",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(199, 210, 254, var(--tw-border-opacity))",
  "zIndex": "10"
} : {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
}]);
export const RadioGroup = ({
  onChange,
  settings,
  selected: initialSelection,
  error
}) => {
  const [selected, setSelected] = React.useState(initialSelection?.id || settings[0]?.id);
  const changeSelected = React.useCallback(setting => {
    onChange(setting);
    setSelected(setting);
  }, [setSelected, onChange]);
  return __cssprop(Radios, {
    value: selected,
    onChange: changeSelected
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderRadius": "0.6rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(-1px * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(-1px * var(--tw-space-y-reverse))"
      }
    }
  }, error && __cssprop("div", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(220, 38, 38, var(--tw-text-opacity))"
    }
  }, error), settings.map((setting, settingIdx) => __cssprop(RadioOption, {
    key: setting.id,
    value: setting.id,
    settingsLength: settings.length,
    idx: settingIdx
  }, ({
    active,
    checked
  }) => __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "width": "1.6rem",
      "height": "1.6rem"
    }
  }, __cssprop(StyledRadioSpan, {
    checked: checked,
    active: active,
    "aria-hidden": "true"
  }, __cssprop("span", {
    css: {
      "borderRadius": "9999px",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "width": "0.6rem",
      "height": "0.6rem"
    }
  }))), __cssprop("div", {
    css: {
      "marginLeft": "1.2rem",
      "display": "flex",
      "flexDirection": "column"
    }
  }, __cssprop(RadioLabel, {
    as: "span",
    checked: checked
  }, setting.label), setting?.description && __cssprop(RadioDescription, {
    as: "span",
    checked: checked
  }, setting.description), setting.renderChildren && __cssprop("div", null, setting.renderChildren)))))));
};