/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePicker } from "../../DatePicker.js";
import { FeatureType } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../../hooks/useRedux.js";
import { FeatureTypeInput, ReversedFeatureType } from "../../../utils/types.js";
import * as React from "../../../../../_snowpack/pkg/react.js";
import { useEffect, useState } from "../../../../../_snowpack/pkg/react.js";
import { HiOutlineCheckCircle } from "../../../../../_snowpack/pkg/react-icons/hi.js";
import Select from "../../../../../_snowpack/pkg/react-select.js";
import { NumberTextField, TextField } from "../TextField.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const getAmenitiesOptions = t => {
  return Object.entries(FeatureType).map(([key, value]) => {
    return {
      label: t(FeatureType[key]) || t(key),
      value
    };
  });
};

const ItemSearcher = ({
  onSelect,
  selected
}) => {
  const {
    t
  } = useI18n();
  const mappedSelected = selected?.map(item => {
    return {
      label: t(item.key),
      value: item.key
    };
  });
  const mappedOptions = getAmenitiesOptions(t);
  return __cssprop("div", {
    className: "aminities-picker",
    css: {
      "width": "100%"
    }
  }, __cssprop(Select, {
    id: "amenitiesPicker",
    css: {
      "--tw-shadow": "0 0 #0000",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
    },
    style: {
      width: "100%"
    },
    isClearable: false,
    isMulti: true,
    values: true,
    placeholder: t("amenities-add-placeholder"),
    options: mappedOptions,
    menuContainerStyle: {
      zIndex: 25
    },
    defaultValue: mappedSelected,
    onChange: onSelect,
    styles: {
      container: base => ({ ...base,
        zIndex: 100
      })
    }
  }));
};

const Sections = ({
  options,
  onClick
}) => {
  if (!options) {
    return null;
  }

  const categories = options.reduce((acc, value) => {
    if (!acc[value.category]) {
      return { ...acc,
        [value.category]: [value]
      };
    }

    return { ...acc,
      [value.category]: [...acc[value.category], value]
    };
  }, {});
  return __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      },
      "gap": "1.6rem",
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-divide-y-reverse": 0,
          "borderTopWidth": "calc(0px * calc(1 - var(--tw-divide-y-reverse)))",
          "borderBottomWidth": "calc(0px * var(--tw-divide-y-reverse))"
        },
        "display": "grid",
        "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
      }
    }
  }, Object.keys(categories).map((key, actionIdx) => {
    const items = categories[key];
    return __cssprop(Section, {
      key: actionIdx,
      Icon: null,
      description: null,
      showTotal: false,
      onClick: onClick,
      title: key,
      showMore: true,
      items: items
    });
  }));
};

const Section = ({
  onClick,
  items,
  Icon,
  description,
  title,
  showMore,
  showTotal
}) => {
  const lang = useSelector(Selectors.session.getUserLanguage);

  if (!items) {
    return null;
  }

  return __cssprop("div", {
    key: title,
    css: {
      "position": "relative",
      ":focus-within": {
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-inset": "inset",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      },
      "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "borderRadius": "0.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "overflow": "hidden",
      "display": "flex",
      "justifyContent": "space-between",
      "flexDirection": "column"
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "padding": "2.4rem",
      "flex": "1 1 0%",
      "height": "auto"
    }
  }, Icon && __cssprop("div", null, __cssprop("span", {
    css: {
      "borderRadius": "0.8rem",
      "display": "inline-flex",
      "padding": "1.2rem",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgba(255, 255, 255, var(--tw-ring-opacity))"
    }
  }, __cssprop(Icon, {
    css: {
      "height": "2.4rem",
      "width": "2.4rem"
    },
    "aria-hidden": "true"
  }))), __cssprop("div", {
    css: {
      "marginTop": "0.8rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.8rem",
      "fontWeight": "500"
    }
  }, __cssprop("a", {
    css: {
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      },
      "cursor": "pointer",
      "textTransform": "capitalize"
    },
    onClick: () => onClick(title),
    "aria-hidden": true
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px"
    },
    "aria-hidden": "true"
  }), title)), description && __cssprop("p", {
    css: {
      "marginTop": "0.8rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, description), __cssprop("ul", {
    css: {
      "marginTop": "1.6rem"
    }
  }, items.map(i => {
    return __cssprop("li", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "paddingBottom": "0.4rem"
      }
    }, __cssprop(HiOutlineCheckCircle, {
      css: {
        "height": "1.6rem",
        "width": "1.6rem",
        "--tw-text-opacity": "1",
        "color": "rgba(5, 150, 105, var(--tw-text-opacity))",
        "marginRight": "0.4rem"
      }
    }), i.text.find(lan => lan.locale === lang).value);
  }))), onClick && __cssprop("span", {
    css: {
      "pointerEvents": "none",
      "position": "absolute",
      "top": "2.4rem",
      "right": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(209, 213, 219, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }, __cssprop("svg", {
    css: {
      "height": "2.4rem",
      "width": "2.4rem"
    },
    xmlns: "http://www.w3.org/2000/svg",
    fill: "currentColor",
    viewBox: "0 0 24 24"
  }, __cssprop("path", {
    d: "M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
  })))), showTotal && __cssprop("div", {
    css: {
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "1.2rem",
      "paddingBottom": "1.2rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "textAlign": "right",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, "Total: 345$"));
};

const itemField = (valueType, t) => {
  switch (FeatureTypeInput[valueType]) {
    case "number":
      return (onChange, val, id, keyValue, error) => __cssprop(NumberTextField, {
        onChange: onChange,
        value: val,
        id: id,
        error: error && keyValue in error ? {
          errorMessage: error[keyValue]
        } : undefined
      });

    case "boolean": // <- Filtered, never going to happen

    case "date":
      return (onChange, val, id, keyValue, error) => {
        return __cssprop(DatePicker, {
          onChange: onChange,
          id: "waterTankInstallationDate",
          title: t("unit-info-section-form-water-tank-date")
        });
      };

    case "string":
    default:
      return (onChange, val, id, keyValue, error) => __cssprop(TextField, {
        error: error && keyValue in error ? {
          errorMessage: error[keyValue]
        } : undefined,
        onChange: onChange,
        defaultValue: val,
        id: id
      });
  }
};

const StatefulComponent = props => {
  const {
    item: {
      key,
      value,
      id
    },
    child,
    label,
    onChangeCallback,
    error
  } = props;
  const [innerValue, setInnerValue] = useState(value);
  useEffect(() => {
    onChangeCallback({
      key,
      value: value || null
    });
  }, []);

  const onChange = val => {
    setInnerValue(val);
    onChangeCallback({
      key,
      value: val
    });
  };

  return __cssprop("div", {
    key: key,
    id: id,
    css: {
      "paddingTop": "1.6rem",
      "paddingBottom": "1.6rem"
    }
  }, __cssprop("dd", {
    css: {
      "marginTop": "0.4rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "gridColumn": "span 1 / span 1",
        "marginTop": "0px"
      }
    }
  }, child(onChange, innerValue, id, key, error)));
};

const SimpleRenderer = ({
  items,
  onChangeCallback,
  error
}) => {
  const {
    t
  } = useI18n(); // did not receive api request

  if (!items || items?.length === 0) {
    return null;
  }

  const mappedItems = items.map(amenity => {
    return { ...amenity,
      label: t(FeatureType[amenity.key]) || t(amenity.key),
      value: amenity.value
    };
  });
  return __cssprop("dl", {
    css: {
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-divide-y-reverse": 0,
          "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
          "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
          "--tw-divide-opacity": "1",
          "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
        }
      }
    }
  }, mappedItems.filter(i => FeatureTypeInput[i.key] !== "boolean" && FeatureTypeInput[ReversedFeatureType[i.key]] !== "boolean").map(i => {
    return __cssprop(StatefulComponent, {
      item: i,
      key: i.key,
      error: error,
      onChangeCallback: onChangeCallback,
      label: i.label,
      child: itemField(i.key, t)
    });
  }));
};

export const AmenitiesPicker = function AmenitiesPicker({
  onSelect,
  selected
}) {
  return __cssprop(ItemSearcher, {
    onSelect: onSelect,
    selected: selected
  });
};
AmenitiesPicker.Sections = Sections;
AmenitiesPicker.SimpleRenderer = SimpleRenderer;