/* eslint-disable no-underscore-dangle */
import { createReducer } from "../../helpers/actions.js";
import sagas from "./sagas.js";
import { initialState } from "./types.js";
export const getUnit = state => state?.unit;

const getBuilding = state => getUnit(state).building;

export default {
  sagas: sagas.executers,
  reducer: createReducer(initialState, sagas.reducers),
  selectors: {
    getCurrentLease: state => getUnit(state).currentLease,
    getLeases: state => getUnit(state).leases,
    getTypename: state => getUnit(state).__typename,
    getPropertyIssue: state => getUnit(state).propertyIssue,
    getBedrooms: state => getUnit(state).bedrooms,
    getSquareFootage: state => getUnit(state).squareFootage,
    getBuilding,
    getBuildingId: state => getUnit(state).building?.id,
    getBuildingAddress: state => getUnit(state).buildingAddress,
    getId: state => getUnit(state).id,
    getSubAddress: state => getUnit(state).subAddress,
    getUnitType: state => getUnit(state).unitType,
    getAmenities: state => getUnit(state).amenities,
    getFiles: state => getUnit(state).files,
    getFloor: state => getUnit(state).floor,
    getListing: state => getUnit(state).listing,
    getNotes: state => getUnit(state).notes,
    getRentalRequest: state => getUnit(state).rentalRequest,
    getResponsible: state => getUnit(state).responsible,
    getWaterTankInstallationDate: state => getUnit(state).waterTankInstallationDate,
    getUnit: state => getUnit(state),
    getProject: state => getBuilding(state)?.project,
    getTickets: state => getUnit(state).tickets.items
  }
};