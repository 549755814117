import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { Loader } from "../../components/Loader.js";
import { GetTaskDocument } from "../../graphql/documents.js";
import { useLazyQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { mapFiles } from "../../store/utils/Files.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import { TaskInvoiceSideBarFormView } from "../sections/TaskInvoiceSideBarFormView.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const TaskInvoiceSideBar = ({
  taskId,
  refetch
}) => {
  const [isReady, setIsReady] = useState(false);
  const [taskInvoiceState, setTaskInvoiceState] = useState({
    taskId,
    refetch,
    amount: 0,
    date: DateTime.utc().toISO(),
    description: "",
    invoiceId: null,
    payer: null,
    title: "",
    filesDefault: []
  });
  const [loadTaskInvoice, {
    loading: queryLoading
  }] = useLazyQuery(GetTaskDocument, {
    variables: {
      taskId
    },
    onCompleted: taskData => {
      const {
        invoice
      } = taskData.task;

      if (!invoice) {
        setIsReady(true);
        return;
      }

      setTaskInvoiceState({ ...taskInvoiceState,
        amount: invoice.amount,
        date: invoice.date,
        description: invoice.description,
        invoiceId: invoice.id,
        payer: invoice.payer ? {
          label: invoice.payer.name,
          value: invoice.payer.id
        } : undefined,
        title: invoice.title,
        filesDefault: mapFiles(invoice.files.items)
      });
      setIsReady(true);
    }
  });
  useEffect(() => {
    if (taskId) loadTaskInvoice(); // if taskId is passed then get task invoice details
    else setIsReady(true); // component is ready to render form
  }, []);

  if (queryLoading || !isReady) {
    return __cssprop("div", {
      tw: "absolute bg-gray-50 z-10 h-full w-full opacity-60"
    }, __cssprop(Loader, null));
  }

  return __cssprop(TaskInvoiceSideBarFormView, {
    refetch: refetch,
    taskId: taskId,
    amount: taskInvoiceState.amount,
    filesDefault: taskInvoiceState.filesDefault,
    date: taskInvoiceState.date,
    description: taskInvoiceState.description,
    payer: taskInvoiceState.payer,
    invoiceId: taskInvoiceState.invoiceId,
    title: taskInvoiceState.title
  });
};