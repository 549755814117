import { ProtectedButton, ProtectedCard, ProtectedTab } from "../../components/Tailwind/ProtectedComponents.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { LeaseActionsTypes } from "../../store/artifacts/Lease/types.js";
import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { Page } from "../../components/Tailwind/Page.js";
import { EmptyUnitsList } from "./tabs/EmptyUnitsList.js";
import LeasesList from "./tabs/LeaseList.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const RenderPageContent = ({
  selectedTab
}) => {
  switch (selectedTab) {
    case 0:
      return __cssprop(LeasesList, null);

    default:
      return __cssprop(EmptyUnitsList, null);
  }
};

export const Leases = withBreadcrumb(({
  history,
  setCrumb
}) => {
  const {
    t
  } = useI18n();
  const [selectedTab, setSelectedTab] = useState(0);
  const {
    dispatch
  } = useAction();
  const role = useSelector(Selectors.session.getUserRole);
  useEffect(() => {
    setCrumb({
      title: t("leases"),
      path: "/leases",
      level: 0
    });
  }, []);

  const onCreateLeaseClick = () => {
    dispatch({
      type: LeaseActionsTypes.LEASE_CREATE_NEW_LEASE_EMPTY,
      payload: null
    });
    history.push("/lease");
  };

  const tabs = ProtectedTab(role, [{
    content: t("leases"),
    id: "lease",
    permission: {
      kind: "Lease",
      permission: "view:list"
    }
  }, {
    content: t("buildings-empty-unit"),
    id: "empty-units",
    permission: {
      kind: "Lease",
      permission: "view:list"
    }
  }]);
  return __cssprop(Page, {
    title: t("leases"),
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Lease",
        permission: "mutate:create"
      },
      primary: true,
      onAction: onCreateLeaseClick
    }, t("unit-add-lease")),
    tabs: {
      tabs,
      onSelect: setSelectedTab,
      selected: selectedTab
    }
  }, __cssprop(ProtectedCard, {
    permission: {
      kind: "Lease",
      permission: "view:list"
    }
  }, __cssprop(RenderPageContent, {
    selectedTab: selectedTab
  })));
});
export default Leases;