import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';

import axios from "../../../_snowpack/pkg/axios.js";
// NEED AUTHORIZATION TOKEN AND USER ID
export const searchMapEntries = async searchBody => {
  const params = {
    access_token: `${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_MAPBOX_TOKEN}`,
    country: "CA",
    fuzzyMatch: true,
    autocomplete: true,
    // api fills in the blank if it has to
    types: "address",
    // only addressed
    proximity: "-75.701271,45.476543" // proximity bias for Gatineau

  };
  const stringPath = new URLSearchParams(Object.entries(params)).toString();
  return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchBody}.json?${stringPath}`);
};
export const getPlacesFromLongLat = async (longitude, latitude) => {
  const params = {
    access_token: `${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_MAPBOX_TOKEN}`,
    reverseMode: "score",
    language: "fr-CA",
    limit: 1,
    types: "country,region,postcode,address"
  };
  const stringPath = new URLSearchParams(Object.entries(params)).toString();
  return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?${stringPath}`);
};