import * as React from "../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Layout = ({
  oneThird,
  oneHalf,
  twoThird,
  fullWidth,
  children
}) => {
  if (fullWidth) {
    return __cssprop("div", {
      css: {
        "display": "grid",
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1.6rem",
        "@media (min-width: 1024px)": {
          "gridColumn": "span 8 / span 8"
        }
      }
    }, children);
  }

  if (oneThird) {
    return __cssprop("div", {
      css: {
        "display": "grid",
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1.6rem",
        "@media (min-width: 1024px)": {
          "gridColumn": "span 2 / span 2"
        }
      }
    }, children);
  }

  if (oneHalf) {
    return __cssprop("div", {
      css: {
        "display": "grid",
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1.6rem",
        "@media (min-width: 1024px)": {
          "gridColumn": "span 3 / span 3"
        }
      }
    }, children);
  }

  if (twoThird) {
    return __cssprop("div", {
      css: {
        "display": "grid",
        "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
        "gap": "1.6rem",
        "@media (min-width: 1024px)": {
          "gridColumn": "span 4 / span 4"
        }
      }
    }, children);
  }

  return __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
      "gap": "1.6rem",
      "alignItems": "flex-start",
      "@media (min-width: 1024px)": {
        "gridTemplateColumns": "repeat(6, minmax(0, 1fr))",
        "gap": "3.2rem"
      }
    }
  }, children);
};
export const DescriptionLayoutItem = ({
  title,
  description,
  children
}) => {
  return __cssprop("div", {
    css: {
      "@media (min-width: 768px)": {
        "display": "grid",
        "gridTemplateColumns": "repeat(3, minmax(0, 1fr))",
        "gap": "2.4rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 768px)": {
        "gridColumn": "span 1 / span 1"
      }
    }
  }, __cssprop("div", {
    css: {
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "0px",
        "paddingRight": "0px"
      }
    }
  }, __cssprop("h3", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, title), __cssprop("p", {
    css: {
      "marginTop": "0.4rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
    }
  }, description))), __cssprop("div", {
    css: {
      "marginTop": "2rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 768px)": {
        "gridColumn": "span 2 / span 2",
        "marginTop": "0px"
      }
    }
  }, children));
};
export const DescriptionLayout = ({
  children
}) => {
  let items = children;

  if (!Array.isArray(children)) {
    items = [children];
  }

  return __cssprop("div", null, items.map((i, idx) => {
    return __cssprop("div", {
      key: idx
    }, i, idx !== items.length - 1 && __cssprop("div", {
      css: {
        "display": "none",
        "@media (min-width: 640px)": {
          "display": "block"
        }
      },
      "aria-hidden": "true"
    }, __cssprop("div", {
      css: {
        "paddingTop": "2rem",
        "paddingBottom": "2rem"
      }
    }, __cssprop("div", {
      css: {
        "borderTopWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
      }
    }))));
  }));
};