import * as Sentry from "../../../_snowpack/pkg/@sentry/react.js";
import { applyMiddleware, combineReducers, compose, createStore } from "../../../_snowpack/pkg/redux.js";
import { createMigrate, persistReducer, persistStore } from "../../../_snowpack/pkg/redux-persist.js";
import storage from "../../../_snowpack/pkg/redux-persist/lib/storage.js";
import createSagaMiddleware from "../../../_snowpack/pkg/redux-saga.js";
import { all, call, setContext, spawn } from "../../../_snowpack/pkg/redux-saga/effects.js";
import Application from "./artifacts/Application/index.js";
import Building from "./artifacts/Building/index.js";
import Deposit from "./artifacts/Deposit/index.js";
import Lease from "./artifacts/Lease/index.js";
import Listing from "./artifacts/Listing/index.js";
import Notes from "./artifacts/Notes/index.js";
import Project from "./artifacts/Project/index.js";
import Provider from "./artifacts/Provider/index.js";
import Session from "./artifacts/Session/index.js";
import Status from "./artifacts/Status/index.js";
import Tenant from "./artifacts/Tenant/index.js";
import Unit from "./artifacts/Unit/index.js";
import User from "./artifacts/User/index.js";
import Tickets from "./artifacts/Tickets/index.js";
import Tasks from "./artifacts/Tasks/index.js";
import Event from "./artifacts/Events/index.js";
import Calendar from "./artifacts/Calendar/index.js";
const migrations = {
  0: state => {
    // Clear the app state
    return { ...state,
      app: undefined,
      application: undefined,
      auth: undefined
    };
  },
  1: state => {
    // Clear the app state
    return { ...state,
      app: undefined
    };
  },
  2: state => {
    // Clear the app state
    return { ...state,
      app: undefined
    };
  }
};
const persistConfig = {
  key: "root",
  storage,
  version: 2,
  whitelist: ["app", "session"],
  migrate: createMigrate(migrations, {
    debug: true
  })
};
export const Selectors = {
  app: Application.selectors,
  unit: Unit.selectors,
  user: User.selectors,
  listing: Listing.selectors,
  building: Building.selectors,
  tenant: Tenant.selectors,
  deposit: Deposit.selectors,
  project: Project.selectors,
  lease: Lease.selectors,
  status: Status.selectors,
  session: Session.selectors,
  ticket: Tickets.selectors,
  task: Tasks.selectors,
  provider: Provider.selectors,
  calendar: Calendar.selectors,
  event: Event.selectors
}; // create our new saga monitor

const sagaMiddleware = createSagaMiddleware({
  onError: (e, info) => {
    console.error({
      message: e.message,
      source: "sagaError",
      stacktrace: info.sagaStack
    });
  }
});
const rootReducer = combineReducers({
  app: Application.reducer,
  user: User.reducer,
  listing: Listing.reducer,
  unit: Unit.reducer,
  building: Building.reducer,
  tenant: Tenant.reducer,
  deposit: Deposit.reducer,
  project: Project.reducer,
  lease: Lease.reducer,
  status: Status.reducer,
  session: Session.reducer,
  ticket: Tickets.reducer,
  task: Tasks.reducer,
  provider: Provider.reducer,
  calendar: Calendar.reducer,
  event: Event.reducer
});
export function* rootSaga(apolloClient, history, t, toast) {
  // Sets apollo client on the context of sagas for reusability
  yield setContext({
    client: apolloClient,
    history,
    t,
    toast
  });
  const sagas = [Application.sagas, User.sagas, Listing.sagas, Unit.sagas, Building.sagas, Tenant.sagas, Deposit.sagas, Project.sagas, Lease.sagas, Status.sagas, Notes.sagas, Session.sagas, Tickets.sagas, Tasks.sagas, Provider.sagas, Calendar.sagas, Event.sagas]; // The goal here is to recreate the spawn if it dies.

  yield all(sagas.map(saga => spawn(function* spawner() {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        Sentry.captureException(e, {
          level: Sentry.Severity.Error
        });
      }
    }
  })));
}
const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer, compose(applyMiddleware(sagaMiddleware)));
const persistor = persistStore(store); // then run the saga

export const startSaga = (client, history, t, toast) => sagaMiddleware.run(rootSaga, client, history, t, toast).toPromise().catch(e => {
  store.dispatch({
    type: "ERROR",
    payload: e
  });
});
export { persistor, store };