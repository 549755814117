import { DepositPanel } from "../../components/Panels/DepositPanel.js";
import { Link } from "../../components/Tailwind/Link.js";
import { Page } from "../../components/Tailwind/Page.js";
import { PaginatedDataTable } from "../../components/Tailwind/PaginatedDataTable.js";
import { ProtectedButton, ProtectedFunction } from "../../components/Tailwind/ProtectedComponents.js";
import { GetDepositsDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { DepositActionsTypes } from "../../store/artifacts/Deposit/types.js";
import { getFormattedAmount } from "../../store/artifacts/Lease/util.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { GrNotes } from "../../../../_snowpack/pkg/react-icons/gr.js";
import { batch } from "../../../../_snowpack/pkg/react-redux.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const Deposits = () => {
  const {
    t
  } = useI18n();
  const deposit = useSelector(Selectors.deposit.getId);
  const {
    dispatch
  } = useAction();

  const onDoneSubmit = data => {
    dispatch({
      type: DepositActionsTypes.DEPOSIT_SUBMIT,
      payload: { ...data
      }
    });
  };

  return __cssprop(React.Fragment, null, __cssprop(DepositPanel, {
    onDoneSubmit: onDoneSubmit,
    formTitle: deposit ? t("deposit-form-modify-deposit") : t("deposit-form-add-deposit"),
    description: "",
    sectionTitle: ""
  }), __cssprop(Page, {
    title: t("finances"),
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Deposit",
        permission: "mutate:create"
      },
      primary: true,
      onAction: () => {
        batch(() => {
          dispatch({
            type: DepositActionsTypes.DEPOSIT_INITIALIZE,
            payload: {
              kind: "NoContext"
            }
          });
          dispatch({
            type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
            payload: "deposit"
          });
        });
      }
    }, t("deposit-add"))
  }, __cssprop(DepositView, null)));
};

const DepositView = () => {
  const {
    t
  } = useI18n();
  const role = useSelector(Selectors.session.getUserRole);
  const {
    dispatch
  } = useAction();

  const renderBody = item => {
    const {
      depositBy,
      depositFor,
      depositDate,
      files
    } = item;
    const fAmount = getFormattedAmount(item.amount);
    return {
      depositBy: depositBy.name,
      depositDate: DateTime.fromISO(depositDate).toUTC().toISODate(),
      amount: __cssprop(Link, {
        action: {
          kind: "ClickAction",
          event: ProtectedFunction(role, () => {
            batch(() => {
              dispatch({
                type: DepositActionsTypes.DEPOSIT_INITIALIZE,
                payload: {
                  kind: "WithContext",
                  deposit: { ...item,
                    files: item.files.items
                  }
                }
              });
              dispatch({
                type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
                payload: "deposit"
              });
            });
          }, {
            kind: "Deposit",
            permission: "mutate:update"
          }, ProtectedFunction(role, () => {
            batch(() => {
              dispatch({
                type: DepositActionsTypes.DEPOSIT_INITIALIZE,
                payload: {
                  kind: "WithContext",
                  deposit: { ...item,
                    files: item.files.items || []
                  }
                }
              });
              dispatch({
                type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
                payload: "deposit"
              });
            });
          }, {
            kind: "Deposit",
            permission: "view:info"
          }))
        },
        text: fAmount
      }),
      project: __cssprop(Link, {
        action: {
          kind: "InternalLink",
          to: `/clients/${depositFor.id}`
        },
        text: depositFor.name
      }),
      files: __cssprop("div", {
        css: {
          "display": "flex",
          "paddingLeft": "1.2rem",
          "alignSelf": "center",
          "alignItems": "center"
        }
      }, __cssprop(GrNotes, null), __cssprop("div", {
        css: {
          "paddingLeft": "0.4rem"
        }
      }, files ? files.items.length : 0))
    };
  };

  const columns = [{
    Header: t("amount"),
    accessor: "amount",
    disableSortBy: true
  }, {
    Header: t("project"),
    accessor: "project",
    disableSortBy: true
  }, {
    Header: t("deposit-deposit-by"),
    accessor: "depositBy",
    disableSortBy: true
  }, {
    Header: t("deposit-form-date"),
    accessor: "depositDate",
    disableSortBy: true
  }, {
    Header: t("file-section-title"),
    accessor: "files",
    disableSortBy: true
  }];
  return __cssprop(PaginatedDataTable, {
    title: t("deposits"),
    query: GetDepositsDocument,
    headers: columns,
    renderItem: renderBody,
    resourceName: {
      plural: t("deposits"),
      singular: t("deposit")
    }
  });
};

export default Deposits;