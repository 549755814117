import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "../../../_snowpack/pkg/react.js";
import { Redirect, Route, useHistory } from "../../../_snowpack/pkg/react-router.js";
import useI18n from "../hooks/useI18n.js";
import { AppFrame } from "../components/Tailwind/AppFrame.js";
import { useAction, useSelector, Selectors } from "../hooks/useRedux.js";
import { NotificationPanel } from "../components/Panels/NotificationPanel.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const PrivateRoute = ({
  component: Component,
  kind,
  ...rest
}) => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const history = useHistory();
  const isLoggedIn = useSelector(Selectors.session.getIsLoggedIn);
  const sideBarNavigationItems = useSelector(Selectors.app.getSideBarNavigationItem(t, history));
  const topBarNavigationItems = useSelector(Selectors.app.getTopBarItems(t, history, dispatch));
  const userMenuNavigationItems = useSelector(Selectors.app.getUserMenuItems(t, history, dispatch));

  if (!isLoggedIn) {
    return __cssprop(Redirect, {
      to: "/"
    });
  }

  return __cssprop(React.Fragment, null, __cssprop(NotificationPanel, null), __cssprop(AppFrame, {
    appVersion: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_PACKAGE_VERSION,
    topBarNavigation: topBarNavigationItems.map(n => {
      return {
        active: false,
        onClick: n.onClick,
        label: n.label,
        icon: n.icon
      };
    }),
    sideBarNavigation: sideBarNavigationItems.map(n => {
      return {
        active: n.kind === kind,
        onClick: n.onClick,
        label: n.label,
        icon: n.icon,
        warn: n.warn
      };
    }),
    flyoutMenuNavigation: {
      main: userMenuNavigationItems.main.map(n => {
        return {
          active: false,
          onClick: n.onClick,
          label: n.name,
          description: n.description,
          icon: n.icon
        };
      }),
      alternative: userMenuNavigationItems.alternative.map(n => {
        return {
          active: false,
          onClick: n.onClick,
          label: n.label,
          icon: n.icon
        };
      })
    }
  }, __cssprop(Route, _extends({}, rest, {
    component: Component
  }))));
};

export default PrivateRoute;