import React, { useEffect } from "../../../_snowpack/pkg/react.js";
import { Switch } from "../../../_snowpack/pkg/react-router.js";
import { EmptyState } from "../components/Tailwind/EmptyState.js";
import { useAction } from "../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../store/artifacts/Application/types.js";
import ApplicantRequestFormV2 from "../views/ApplicantRequestV2/index.js";
import { BuildingFormPage } from "../views/BuildingFormPage/index.js";
import { BuildingInfo as BuildingInfoPage } from "../views/BuildingInfo/index.js";
import { Deposits as DepositsPage } from "../views/Deposits/index.js";
import { Providers as ProvidersPage } from "../views/Providers/index.js";
import { HelpPage } from "../views/Help/Help.js";
import { Lease as LeasePage } from "../views/Lease/index.js";
import { Leases as LeasesPage } from "../views/Leases/index.js";
import { Listing as ListingPage } from "../views/Listing/index.js";
import { Login as LoginPage } from "../views/Login/index.js";
import { Marketing as MarketingPage } from "../views/Marketing/index.js";
import { Organization as OrganizationPage } from "../views/Organization/index.js";
import { Profile as ProfilePage } from "../views/Profile/index.js";
import { Projects as ProjectsPage } from "../views/Projects/index.js";
import { ProjectsInfo as ProjectsInfoPage } from "../views/ProjectsInfo/index.js";
import { Properties as PropertiesPage } from "../views/Properties/index.js";
import { Registration as RegisterationPage } from "../views/registration/index.js";
import { Reports as ReportsPage } from "../views/Reports/index.js";
import { Tenants as TenantsPage } from "../views/tenants/index.js";
import { TenantsInfo as TenantInfoPage } from "../views/TenantsInfo/index.js";
import TicketInfoPage from "../views/TicketInfoPage/index.js";
import Tickets from "../views/Tickets/index.js";
import CalendarPage from "../views/Calendar/index.js";
import { Unit as UnitPage } from "../views/Unit/index.js";
import Event from "../views/Event/index.js";
import Events from "../views/Events/index.js";
import PrivateRoute from "./PrivateRoute.js";
import PublicRoute from "./PublicRoute.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const NotFoundComponent = () => {
  return __cssprop(EmptyState, {
    image: "/img/notFound.svg",
    heading: "La page ou la ressource que vous cherchez est introuvable"
  });
};

const ApplicationRouter = () => {
  const {
    dispatch
  } = useAction();
  useEffect(() => {
    dispatch({
      type: ApplicationActionsTypes.ON_LOAD,
      payload: null
    });
  }, []);
  return __cssprop(Switch, null, __cssprop(PublicRoute, {
    exact: true,
    path: "/",
    component: LoginPage
  }), __cssprop(PublicRoute, {
    path: "/registration/:rid?",
    redirect: false,
    component: RegisterationPage
  }), __cssprop(PrivateRoute, {
    kind: "none",
    path: "/profile",
    component: ProfilePage
  }), __cssprop(PrivateRoute, {
    kind: "properties",
    path: "/unit/:id",
    component: UnitPage
  }), __cssprop(PrivateRoute, {
    kind: "properties",
    path: "/buildings/:id",
    component: BuildingInfoPage
  }), __cssprop(PrivateRoute, {
    kind: "deposit",
    exact: true,
    path: "/deposits",
    component: DepositsPage
  }), __cssprop(PrivateRoute, {
    kind: "project",
    path: "/clients/:id",
    component: ProjectsInfoPage
  }), __cssprop(PrivateRoute, {
    kind: "project",
    exact: true,
    path: "/clients",
    component: ProjectsPage
  }), __cssprop(PrivateRoute, {
    kind: "tenant",
    exact: true,
    path: "/tenants",
    component: TenantsPage
  }), __cssprop(PrivateRoute, {
    kind: "marketing",
    path: "/applicant/v2/form",
    component: ApplicantRequestFormV2
  }), __cssprop(PrivateRoute, {
    kind: "marketing",
    path: "/applicant/v2/:id",
    component: ApplicantRequestFormV2
  }), __cssprop(PrivateRoute, {
    kind: "tenant",
    path: "/tenants/:id",
    component: TenantInfoPage
  }), __cssprop(PrivateRoute, {
    kind: "none",
    exact: true,
    path: "/help",
    component: HelpPage
  }), __cssprop(PrivateRoute, {
    kind: "none",
    exact: true,
    path: "/reports",
    component: ReportsPage
  }), __cssprop(PrivateRoute, {
    kind: "leases",
    exact: true,
    path: "/lease/:id?/:renewal?",
    component: LeasePage
  }), __cssprop(PrivateRoute, {
    kind: "leases",
    exact: true,
    path: "/lease/:id?/:view?",
    component: LeasePage
  }), __cssprop(PrivateRoute, {
    kind: "properties",
    exact: true,
    path: "/building",
    component: BuildingFormPage
  }), __cssprop(PrivateRoute, {
    kind: "properties",
    exact: true,
    path: "/listing",
    component: ListingPage
  }), __cssprop(PrivateRoute, {
    kind: "none",
    exact: true,
    path: "/organization",
    component: OrganizationPage
  }), __cssprop(PrivateRoute, {
    kind: "marketing",
    exact: true,
    path: "/marketing",
    component: MarketingPage
  }), __cssprop(PrivateRoute, {
    kind: "leases",
    exact: true,
    path: "/leases",
    component: LeasesPage
  }), __cssprop(PrivateRoute, {
    kind: "properties",
    exact: true,
    path: "/properties",
    component: PropertiesPage
  }), __cssprop(PrivateRoute, {
    kind: "tickets",
    exact: true,
    path: "/tickets",
    component: Tickets
  }), __cssprop(PrivateRoute, {
    kind: "tickets",
    path: "/ticket/:id?",
    component: TicketInfoPage
  }), __cssprop(PrivateRoute, {
    kind: "providers",
    exact: true,
    path: "/providers",
    component: ProvidersPage
  }), __cssprop(PrivateRoute, {
    kind: "events",
    exact: true,
    path: "/event/:id?",
    component: Event
  }), __cssprop(PrivateRoute, {
    kind: "events",
    exact: true,
    path: "/events",
    component: Events
  }), __cssprop(PrivateRoute, {
    kind: "calendar",
    exact: true,
    path: "/calendar",
    component: CalendarPage
  }), __cssprop(PrivateRoute, {
    kind: "none",
    component: NotFoundComponent
  }));
};

export default ApplicationRouter;