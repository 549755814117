import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';

import LogRocket from "../../../_snowpack/pkg/logrocket.js";
import { Integrations } from "../../../_snowpack/pkg/@sentry/tracing.js";
import * as Sentry from "../../../_snowpack/pkg/@sentry/react.js";
export const initializeSentry = () => {
  Sentry.init({
    dsn: "https://7cbbc0170f464badbf147bd7945e63c4@o476518.ingest.sentry.io/5660688",
    integrations: [new Integrations.BrowserTracing()],
    environment: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_APP_ENVIRONMENT,
    release: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_PACKAGE_VERSION,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
};
export const initializeIntercom = () => {
  if (__SNOWPACK_ENV__.SNOWPACK_PUBLIC_IS_PROD === "true") {
    LogRocket.init("yzoqb6/simpleloyer");
  }
};