/* eslint-disable no-underscore-dangle */
import { createReducer } from "../../helpers/actions.js";
import { mapFile, mapFiles, mapFileToBase64 } from "../../utils/Files.js";
import { handleRemoteData } from "../../helpers/stateHandler.js";
import { fromCreateBuilding, fromGetBuilding, fromUpdateBuilding } from "./mapper.js";
import { BuildingActionsTypes, initialState } from "./types.js";
export const setInitialState = (data, state) => {
  if (data.payload.kind === "WithBuildingId") {
    return { ...state,
      id: data.payload.buildingId
    };
  }

  if (data.payload.kind === "WithContext") {
    return { ...state,
      ...data.payload.building
    };
  }

  if (data.payload.kind === "NoContext") {
    return { ...state,
      ...initialState
    };
  }

  return state;
};
export const setField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};
export const setUnitField = (data, state) => {
  return { ...state,
    unit: { ...state.unit,
      ...data.payload
    }
  };
};
export const setBuildingDateWithMap = (data, state) => {
  return { ...state,
    ...data.payload
  };
};
export const setBuildingProjectId = (data, state) => {
  return { ...state,
    project: { ...state.project,
      ...data.payload
    }
  };
};
export const addBuildingFiles = (data, state) => ({ ...state,
  files: [...state.files, ...data.payload]
});
export const removeBuildingFiles = (data, state) => {
  return { ...state,
    files: mapFiles(state.files.filter(file => file.fileName !== data.payload.fileName))
  };
};
export const addBuildingImage = (data, state) => ({ ...state,
  image: mapFileToBase64([data.payload[0]]),
  imageResource: mapFile(data.payload[0])
});
export const removeBuildingImage = (_data, state) => {
  return { ...state,
    image: ""
  };
};
export const addBuildingTags = (data, state) => {
  return { ...state,
    tags: state.tags.includes(data.payload) ? state.tags : [...state.tags, data.payload]
  };
};
export const removeBuildingTags = (data, state) => {
  return { ...state,
    tags: state.tags.filter(tag => tag !== data.payload)
  };
};
const mapper = {
  Create: fromCreateBuilding,
  Update: fromUpdateBuilding,
  Query: fromGetBuilding
};
export const setServerBuildingState = (data, state) => {
  const handledState = handleRemoteData(state, data.payload.payload, mapper[data.payload.kind]);
  return handledState;
};
export const addBuildingNotes = (data, state) => {
  return { ...state,
    notes: [...state.notes, ...data.payload]
  };
};
export const removeBuildingNotes = (data, state) => {
  return { ...state,
    notes: state.notes.map(note => {
      if (note.id === data.payload) {
        return { ...note,
          kind: "removed"
        };
      }

      return note;
    })
  };
};
export default createReducer(initialState, {
  [BuildingActionsTypes.BUILDING_ADD_FILES]: addBuildingFiles,
  [BuildingActionsTypes.BUILDING_REMOVE_FILES]: removeBuildingFiles,
  [BuildingActionsTypes.BUILDING_ADD_IMAGE]: addBuildingImage,
  [BuildingActionsTypes.BUILDING_REMOVE_IMAGE]: removeBuildingImage,
  [BuildingActionsTypes.BUILDING_INITIALIZE]: setInitialState,
  [BuildingActionsTypes.BUILDING_SET_FIELD]: setField,
  [BuildingActionsTypes.BUILDING_SET_BUILDING_STATE]: setServerBuildingState,
  [BuildingActionsTypes.BUILDING_SET_UNIT_FIELD]: setUnitField,
  [BuildingActionsTypes.BUILDING_SET_FIELD_FROM_MAP]: setBuildingDateWithMap,
  [BuildingActionsTypes.BUILDING_ADD_TAGS]: addBuildingTags,
  [BuildingActionsTypes.BUILDING_REMOVE_TAGS]: removeBuildingTags,
  [BuildingActionsTypes.BUILDING_ADD_NOTES]: addBuildingNotes,
  [BuildingActionsTypes.BUILDING_REMOVE_NOTES]: removeBuildingNotes,
  [BuildingActionsTypes.BUILDING_SET_PROJECT_FIELD]: setBuildingProjectId
});