export let EventActionTypes;

(function (EventActionTypes) {
  EventActionTypes["EVENT_INITIALIZE"] = "EVENT_INITIALIZE";
  EventActionTypes["EVENT_UPDATE"] = "EVENT_UPDATE";
  EventActionTypes["EVENT_SET_INITIAL_STATE"] = "EVENT_SET_INITIAL_STATE";
  EventActionTypes["EVENT_SET_FIELD"] = "EVENT_SET_FIELD";
  EventActionTypes["EVENT_SET_STATE"] = "EVENT_SET_STATE";
  EventActionTypes["EVENT_CLEAR_STATE"] = "EVENT_CLEAR_STATE";
  EventActionTypes["EVENT_CREATE"] = "EVENT_CREATE";
  EventActionTypes["EVENT_CREATE_TICKET"] = "EVENT_CREATE_TICKET";
})(EventActionTypes || (EventActionTypes = {}));

export const initialState = {
  id: null,
  title: null,
  description: null,
  entity: null,
  createdAt: null,
  disabledAt: null,
  deletedAt: null,
  RRule: null,
  parsedRRule: null
};