import UnitSideBar from "../../forms/SideBar/UnitSideBar.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import React from "../../../../_snowpack/pkg/react.js";
import { ProtectedButton } from "../Tailwind/ProtectedComponents.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const UnitPanel = props => {
  const activeDrawer = useSelector(Selectors.app.getDrawerState);
  const {
    dispatch
  } = useAction();
  const {
    formTitle,
    description,
    sectionTitle,
    onDoneSubmit
  } = props;
  return __cssprop(SlideOver, {
    title: formTitle,
    isOpen: activeDrawer === "unit",
    onClose: () => dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    })
  }, __cssprop(UnitSideBar, {
    description: description,
    sectionTitle: sectionTitle,
    onDoneSubmit: onDoneSubmit
  }));
};
export const UnitButton = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const isLoading = useSelector(Selectors.status.isLoading("unit"));
  return __cssprop(ProtectedButton, {
    loading: !!isLoading,
    permission: {
      kind: "Unit",
      permission: "mutate:update"
    },
    onAction: () => {
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: "unit"
      });
    }
  }, t("unit-modify-unit"));
};