function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "../../../../_snowpack/pkg/react.js";
import useI18n from "../../hooks/useI18n.js";
import { consentKeyValue } from "../../utils/consent.js";
import { Selector } from "../Selectors/Selector.js";
import { TextField } from "./TextField.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const ConsentPicker = ({
  handleOnChange,
  selectedItems,
  fields,
  register,
  defautValueSelectedItems
}) => {
  const {
    t
  } = useI18n();
  return __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, "Consent"), __cssprop("ul", {
    css: {
      "marginTop": "0.8rem",
      "borderTopWidth": "1px",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(Selector, {
    onChange: handleOnChange,
    options: consentKeyValue(t),
    isMulti: true,
    selectedItem: selectedItems,
    defaultValue: defautValueSelectedItems
  }), fields.map((field, index) => field.cid !== "is_non_smoking" && __cssprop(React.Fragment, null, __cssprop(TextField, _extends({}, field, {
    id: field.id,
    key: field.id // important to include key with field's id

  }, register(`consent.${index}.value`))))))));
};