/* eslint-disable import/no-cycle */
import { all, getContext, put, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { DeleteTaskDocument, CloseTaskDocument } from "../../../graphql/documents.js";
import { Selectors } from "../../index.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { TYPES } from "../../../helpers/files.js";
import StatusActions from "../Status/actions.js";
import { TaskActionTypes } from "./types.js";
import { TicketActionTypes } from "../Tickets/types.js";
import { ApplicationActionsTypes } from "../Application/types.js";
import { addFilesHandler, uploadDocuments } from "../Files/sagas.js";
import { CalendarActionTypes } from "../Calendar/types.js";

function* deleteTask() {
  const client = yield getContext("client");
  const tasks = yield select(Selectors.ticket.getTasks);
  const taskState = yield select(Selectors.task.getTask);
  yield put(StatusActions.setResourceToSubmit("task"));

  try {
    client.query({
      query: DeleteTaskDocument,
      fetchPolicy: "no-cache",
      variables: {
        taskId: taskState.id
      }
    });
    yield put({
      type: TicketActionTypes.TICKET_SET_FIELD,
      payload: {
        tasks: {
          items: tasks.items.filter(task => task.id !== taskState.id)
        }
      }
    });
    yield put({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });
    yield put(StatusActions.setResourceToSuccess("task", "task-form-delete-task-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("task", "task-form-delete-task-fail"));
  }
}

function* closeTask($data) {
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("ticket"));
  const {
    taskId
  } = $data.payload;
  const tasks = yield select(Selectors.ticket.getTasks);
  const taskState = yield select(Selectors.task.getTask);
  const calendarState = yield select(Selectors.calendar.getEvents);
  const isCalendarTask = calendarState.find(item => item.id === taskState.id);

  try {
    const query = client.query({
      query: CloseTaskDocument,
      fetchPolicy: "no-cache",
      variables: {
        taskId
      }
    });
    const {
      data
    } = yield query;
    yield put({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    });

    if (tasks.items.length > 0) {
      yield put({
        type: TicketActionTypes.TICKET_CLOSE_TASK_REDUCER,
        payload: {
          taskId
        }
      });
    }

    if (isCalendarTask) {
      yield put({
        type: CalendarActionTypes.CALENDAR_UPDATE_ITEM,
        payload: data.closeTask
      });
    }

    yield put(StatusActions.setResourceToSuccess("ticket", "ticket-form-close-task-success"));
  } catch (e) {
    yield put(StatusActions.setResourceToError("ticket", "ticket-form-close-task-fail"));
  }
}

function* onTaskInvoiceSetFiles($data) {
  const filesUploaded = yield uploadDocuments($data.payload.files, RESOURCES.TaskInvoice, TYPES.Files);
  yield addFilesHandler(filesUploaded, $data.payload.invoiceId, RESOURCES.TaskInvoice);
} // function* onTaskRemoveFiles($data: any) {
//   const files = $data.payload;
//   const invoice = yield select(Selectors.task.getInvoice);
//   const newFiles = invoice.files.filter((file) => file.fileName !== files.fileName);
//   const filesUploaded: FileResourceInput[] = yield uploadDocuments(newFiles, RESOURCES.Ticket, TYPES.Files);
//   yield addFilesHandler(filesUploaded, invoice.id, RESOURCES.Ticket);
//   yield put({ type: TaskActionTypes.INVOICE_REMOVE_FILES, payload: files });
// }


export default {
  *executers() {
    yield all([yield takeLatest(TaskActionTypes.TASK_CLOSE, closeTask), yield takeLatest(TaskActionTypes.TASK_DELETE, deleteTask), yield takeLatest(TaskActionTypes.INVOICE_SET_FILES, onTaskInvoiceSetFiles)]);
  }

};