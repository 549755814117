import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const initialState = {
  id: null,
  civicNumber: null,
  postalCode: null,
  buildDate: DateTime.utc().toJSDate(),
  purchaseDate: DateTime.utc().toJSDate(),
  streetName: null,
  city: null,
  sector: null,
  province: "QC",
  country: "CA",
  managementStartDate: DateTime.utc().toJSDate(),
  latitude: null,
  longitude: null,
  tags: [],
  image: "",
  files: [],
  notes: [],
  project: null,
  amenities: [],
  units: [],
  statistics: {
    totalUnits: 0
  },
  slug: "",
  unit: null,
  tickets: {
    items: []
  },
  archivalStatus: false
};
// =========== ACTIONS CREATORS =========== //
export let BuildingActionsTypes;

(function (BuildingActionsTypes) {
  BuildingActionsTypes["BUILDING_INITIALIZE"] = "BUILDING_INITIALIZE";
  BuildingActionsTypes["BUILDING_SUBMIT"] = "BUILDING_SUBMIT";
  BuildingActionsTypes["BUILDING_SET_FIELD"] = "BUILDING_SET_FIELD";
  BuildingActionsTypes["BUILDING_SET_UNIT_FIELD"] = "BUILDING_SET_UNIT_FIELD";
  BuildingActionsTypes["BUILDING_SET_PROJECT_FIELD"] = "BUILDING_SET_PROJECT_FIELD";
  BuildingActionsTypes["BUILDING_ADD_NOTES_FORMLESS"] = "BUILDING_ADD_NOTES_FORMLESS";
  BuildingActionsTypes["BUILDING_REMOVE_NOTES_FORMLESS"] = "BUILDING_REMOVE_NOTES_FORMLESS";
  BuildingActionsTypes["BUILDING_ADD_NOTES"] = "BUILDING_ADD_NOTES";
  BuildingActionsTypes["BUILDING_REMOVE_NOTES"] = "BUILDING_REMOVE_NOTES";
  BuildingActionsTypes["BUILDING_SET_BUILDING_STATE"] = "BUILDING_SET_BUILDING_STATE";
  BuildingActionsTypes["BUILDING_SET_QUERY_STATUS"] = "BUILDING_SET_QUERY_STATUS";
  BuildingActionsTypes["BUILDING_ADD_FILES"] = "BUILDING_ADD_FILES";
  BuildingActionsTypes["BUILDING_REMOVE_FILES"] = "BUILDING_REMOVE_FILES";
  BuildingActionsTypes["BUILDING_ADD_IMAGE"] = "BUILDING_ADD_IMAGE";
  BuildingActionsTypes["BUILDING_REMOVE_IMAGE"] = "BUILDING_REMOVE_IMAGE";
  BuildingActionsTypes["BUILDING_SET_FIELD_FROM_MAP"] = "BUILDING_SET_FIELD_FROM_MAP";
  BuildingActionsTypes["BUILDING_ADD_TAGS"] = "BUILDING_ADD_TAGS";
  BuildingActionsTypes["BUILDING_REMOVE_TAGS"] = "BUILDING_REMOVE_TAGS";
})(BuildingActionsTypes || (BuildingActionsTypes = {}));