/* eslint-disable no-underscore-dangle */
import { GetUserDocument } from "../../../graphql/documents.js";
import { all, getContext, put, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import StatusActions from "../Status/actions.js";
import { initialState, UserActionsTypes } from "./types.js"; // =========== REDUCERS =========== //

const setInitialState = (data, state) => {
  if (data.payload.kind === "WithUserId") {
    return { ...state,
      id: data.payload.userId
    };
  }

  if (data.payload.kind === "WithContext") {
    return { ...state,
      ...data.payload.user
    };
  }

  if (data.payload.kind === "NoContext") {
    return { ...initialState
    };
  }

  return state;
};

const setField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};

const addUserFiles = (data, state) => ({ ...state,
  profileImage: data.payload[0]
});

const removeUserFiles = state => {
  return { ...state,
    profileImage: null
  };
};

const setServerUserState = (data, state) => {
  // updating stored state of lease with data coming in
  if (data.payload.payload.kind === "Success") {
    if (data.payload.payload.payload.user.__typename === "UserGQL") {
      const {
        id,
        email,
        firstName,
        lastName,
        name
      } = data.payload.payload.payload.user;
      return { ...state,
        // ...data.payload.payload.payload.userProfile,
        email,
        firstName,
        lastName,
        id,
        serverUser: data.payload.payload,
        name
      };
    }
  } // if error return original state


  return { ...state,
    serverUser: data.payload.payload
  };
}; // =========== SAGAS =========== //
// This action expects one of three possible inputs: lease id, context to get lease from, or no context to create a new lease.


function* onInitializeUser($data) {
  const client = yield getContext("client");
  const {
    payload
  } = $data;

  switch (payload.kind) {
    case "WithUserId":
      {
        try {
          yield put(StatusActions.setResourceToLoading("user"));
          const query = client.query({
            query: GetUserDocument,
            fetchPolicy: "no-cache",
            variables: {
              id: payload.userId
            }
          });
          const res = yield query;

          if (res.error) {
            yield put(StatusActions.setResourceToError("user", "generic-fetch-error-message"));
            return;
          }

          yield put(StatusActions.setResourceToIdle("user"));
          yield put({
            type: UserActionsTypes.USER_SET_SERVER_USER_STATUS,
            payload: {
              kind: "Query",
              payload: {
                kind: "Success",
                payload: res.data
              }
            }
          });
        } catch (e) {
          yield put(StatusActions.setResourceToError("user", "generic-fetch-error-message"));
        }

        break;
      }

    case "WithContext":
      {
        // yield put<InitializeUserState>({ type: UserActionsTypes.USER_INITIALIZE, payload: { kind: "WithContext", user: payload.user } });
        break;
      }

    case "NoContext":
    default:
      // New org creation form
      // org will be initialized by reducers
      break;
  }
} // =========== SAGAS =========== //
// function* updateExistingUser(id: string, user: MappedUserFormInput) {
//   const client: SimpleLoyerApolloClient = yield getContext("client");
//   const history = yield getContext("history");
//   yield put(StatusActions.setResourceToSubmit("user"));
//   try {
//     const query = client.query({
//       query: UpdateUserDocument,
//       fetchPolicy: "no-cache",
//       variables: {
//         user: {
//           id,
//           user: toUserInputMapper(user),
//         },
//       },
//     });
//     const { data, errors }: UnPromisify<typeof query> = yield query;
//     if (errors?.length > 0) {
//       yield put(StatusActions.setResourceToError("user", "user-form-saved-fail"));
//       yield put<InitializeUserState>({ type: UserActionsTypes.USER_INITIALIZE, payload: { kind: "WithContext", user } });
//       return;
//     }
//     yield put(StatusActions.setResourceToSuccess("user", "user-form-saved-success"));
//     yield put<InitializeUserState>({
//       type: UserActionsTypes.USER_INITIALIZE,
//       payload: { kind: "WithUserId", userId: data.updateUser.id },
//     });
//     history.push(`users/${user.id}`);
//   } catch (e) {
//     yield put(StatusActions.setResourceToError("user", "user-form-saved-fail"));
//     yield put<InitializeUserState>({ type: UserActionsTypes.USER_INITIALIZE, payload: { kind: "WithContext", user } });
//   }
// }
// function* createUser(user: MappedUserFormInput) {
//   const client: SimpleLoyerApolloClient = yield getContext("client");
//   const history = yield getContext("history");
//   yield put(StatusActions.setResourceToSubmit("user"));
//   try {
//     const query = client.query({
//       query: AddUserDocument,
//       fetchPolicy: "no-cache",
//       variables: {
//         user: toUserInputMapper(user),
//       },
//     });
//     const { data, errors }: UnPromisify<typeof query> = yield query;
//     if (errors?.length > 0) {
//       yield put(StatusActions.setResourceToError("user", "user-form-saved-fail"));
//       return;
//     }
//     yield put(StatusActions.setResourceToSuccess("user", "user-form-saved-success"));
//     yield put<InitializeUserState>({
//       type: UserActionsTypes.USER_INITIALIZE,
//       payload: { kind: "WithUserId", userId: data.addUser.id },
//     });
//     history.push(`users/${user.id}`);
//   } catch (e) {
//     yield put(StatusActions.setResourceToError("user", "user-form-saved-fail"));
//   }
// }
// function* onSubmitForm(data: SubmitUser) {
//   const user: UserState = yield select(getUser);
//   yield put<InitializeUserState>({ type: UserActionsTypes.USER_INITIALIZE, payload: { kind: "WithContext", user: data.payload } });
//   if (user.id) {
//     yield updateExistingUser(user.id, data.payload);
//   } else {
//     yield createUser(data.payload);
//   }
//   yield put<SetDrawerState>({ type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE, payload: null });
// }


export default {
  reducers: {
    [UserActionsTypes.USER_ADD_FILES]: addUserFiles,
    [UserActionsTypes.USER_REMOVE_FILES]: removeUserFiles,
    [UserActionsTypes.USER_INITIALIZE]: setInitialState,
    [UserActionsTypes.USER_SET_FIELD]: setField,
    [UserActionsTypes.USER_SET_SERVER_USER_STATUS]: setServerUserState // [UserActionsTypes.USER_SUBMIT]: (data, state) => setUserSubmitState({ kind: "Loading" }, state),
    // [UserActionsTypes.USER_SUBMIT_FAILURE]: (data, state) => setUserSubmitState({ kind: "Failure", error: data.payload }, state),

  },

  *executers() {
    yield all([// yield takeLatest(UserActionsTypes.USER_SUBMIT, onSubmitForm),
    yield takeLatest(UserActionsTypes.USER_INITIALIZE, onInitializeUser)]);
  }

};