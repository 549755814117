import { useLazyQuery } from "../../../_snowpack/pkg/@apollo/client.js";
import { GetSignedUrLforFileDocument } from "../graphql/documents.js";
import { Selectors, useSelector } from "../hooks/useRedux.js";
import FileSaver from "../../../_snowpack/pkg/file-saver.js";
import React, { useEffect, useState } from "../../../_snowpack/pkg/react.js";
import { IoFileTraySharp } from "../../../_snowpack/pkg/react-icons/io5.js";
import { DropDownActions } from "./Tailwind/DropDownActions.js";
import { ProtectedActions } from "./Tailwind/ProtectedComponents.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const RenderFileList = (file, role) => {
  const [signedUrl, SetSignedUrl] = useState(undefined);
  const [getSignedUrl, {
    loading,
    called
  }] = useLazyQuery(GetSignedUrLforFileDocument);

  const handlePrepareDownload = async path => {
    const response = await getSignedUrl({
      variables: {
        path
      }
    });
    SetSignedUrl(response.data.getSignedURLforFile);
  };

  useEffect(() => {
    if (called && !loading && signedUrl) {
      FileSaver.saveAs(signedUrl, file.fileName);
    }
  }, [loading, called, signedUrl]);
  return ProtectedActions(role, [{
    content: file.fileName,
    onAction: () => handlePrepareDownload(file.filePath),
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:create"
    }
  }]);
};

export const RenderFileDropdown = ({
  files
}) => {
  const role = useSelector(Selectors.session.getUserRole);
  return __cssprop(DropDownActions, {
    content: "files",
    actions: files.map(file => RenderFileList(file, role))
  }, __cssprop("span", {
    css: {
      "position": "relative",
      "display": "inline-block"
    }
  }, __cssprop(IoFileTraySharp, {
    css: {
      "width": "2.4rem",
      "height": "2.4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "fill": "currentColor"
    }
  }), __cssprop("span", {
    css: {
      "--tw-translate-x": "50%",
      "--tw-translate-y": "-50%",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "paddingTop": "0.4rem",
      "paddingBottom": "0.4rem",
      "fontSize": "1.2rem",
      "lineHeight": "1",
      "fontWeight": "700",
      "--tw-text-opacity": "1",
      "color": "rgba(254, 226, 226, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(220, 38, 38, var(--tw-bg-opacity))",
      "borderRadius": "9999px"
    }
  }, files.length)));
};