import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { useParams } from "../../../../_snowpack/pkg/react-router.js";
import { useSelector } from "../../../../_snowpack/pkg/react-redux.js";
import { Selectors, useAction } from "../../hooks/useRedux.js";
import { TicketActionTypes } from "../../store/artifacts/Tickets/types.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import TicketInfoMain from "../../components/TicketInfo/TicketInfoMain/TicketInfoMain.js";
import TicketInfoSidebar from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoSidebar.js";
import { TaskPanel } from "../../components/Panels/TaskPanel.js";
import { Loader } from "../../components/Loader.js";
import { Page } from "../../components/Tailwind/Page.js";
import { Layout } from "../../components/Tailwind/Layout.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import useI18n from "../../hooks/useI18n.js";
import useDeleteModal, { RESOURCE } from "../../forms/useDeleteModal.js";
import { Badge } from "../../components/Tailwind/Badge.js";
import { DeleteTicketReoccurEventDocument, TicketPriority, UpdateTicketDocument } from "../../graphql/documents.js";
import { AddEventPanel } from "../../components/Panels/AddEventPanel.js";
import { MdRepeat } from "../../../../_snowpack/pkg/react-icons/md.js";
import { useMutation } from "../../../../_snowpack/pkg/@apollo/client.js";
import { toast } from "../../../../_snowpack/pkg/react-toastify.js";
import { CreateTicketPanel } from "../../components/Panels/CreateTicketPanel.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const prioritiesBadges = t => {
  return {
    [TicketPriority.Low]: __cssprop(Badge, {
      success: true
    }, t("ticket-priority-low")),
    [TicketPriority.Medium]: __cssprop(Badge, {
      warning: true
    }, t("ticket-priority-medium")),
    [TicketPriority.High]: __cssprop(Badge, {
      critical: true
    }, t("ticket-priority-high"))
  };
};
const TicketInfoPage = withBreadcrumb(({
  history,
  setCrumb,
  crumbs
}) => {
  const {
    id
  } = useParams();
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const data = useSelector(Selectors.ticket.getTicket);
  const tags = useSelector(Selectors.ticket.getTags);
  const isLoading = useSelector(Selectors.status.getTicketStatus);
  const [DeleteModal, openDeleteModal] = useDeleteModal({
    ticketId: id
  }, RESOURCE.Ticket);
  const isClosed = Boolean(useSelector(Selectors.ticket.getClosedAt));
  const priority = useSelector(Selectors.ticket.getPriority);
  const isRecurring = useSelector(Selectors.ticket.getIsRecurring);
  const [isTaskPanelOpen, setIsTaskPanelOpen] = useState(false);
  const [unocurreRecurrentEvent] = useMutation(DeleteTicketReoccurEventDocument);
  useEffect(() => {
    if (data) {
      setCrumb({
        title: data.title,
        path: `/ticket/${data.id}`,
        level: 1
      });
    }
  }, [data]);
  useEffect(() => {
    if (id) {
      dispatch({
        type: TicketActionTypes.TICKET_INITIALIZE,
        payload: {
          kind: "WithTicketId",
          ticketId: id
        }
      });
    }

    return () => {
      dispatch({
        type: [TicketActionTypes.TICKET_SET_INITIAL_STATE]
      });
      dispatch({
        type: [ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE],
        payload: null
      });
    };
  }, [id]);

  const handleDeleteTicket = () => {
    if (isClosed) {
      openDeleteModal();
    }
  };

  const refetch = () => {
    setIsTaskPanelOpen(false);
    dispatch({
      type: TicketActionTypes.TICKET_INITIALIZE,
      payload: {
        kind: "WithTicketId",
        ticketId: id
      }
    });
  };

  const tasks = useSelector(Selectors.ticket.getTasks);
  const completedTasks = tasks?.items?.length > 0 ? tasks?.items?.filter(task => task.closedAt).length : 0;
  const canCloseTicket = completedTasks !== tasks?.items?.length || !!data.closedAt;

  const handleCreateRecurring = () => {
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: "event-create"
    });
  };

  const handleDeleteRecurring = async () => {
    const event = await unocurreRecurrentEvent({
      variables: {
        recurringEventId: id
      }
    });

    if (event?.data?.deleteTicketReoccurEvent?.id) {
      toast.success(t("task-form-delete-task-success"));
    }
  };

  const handleCloseTicket = () => {
    dispatch({
      type: TicketActionTypes.TICKET_CLOSE,
      payload: {
        ticketId: id
      }
    });
    history.push("/tickets");
  };

  const handleOpenModifySideBar = () => {
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: "ticket-create"
    });
  };

  const [updateTicket] = useMutation(UpdateTicketDocument); // just trust my any bro

  const handleSubmit = async formData => {
    const ticket = await updateTicket({
      variables: {
        ticketId: id,
        ticket: { ...formData,
          tags
        }
      }
    });

    if (ticket.data.updateTicket.id) {
      toast.success(t("ticket-form-update-ticket-success"));
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: null
      });
      refetch();
    }

    if (ticket.errors) {
      toast.warn(t("ticket-form-update-ticket-fail"));
    }
  };

  if (isLoading === "Loading") {
    return __cssprop("div", {
      css: {
        "width": "100%",
        "margin": "auto"
      }
    }, __cssprop("div", {
      css: {
        "padding": "6.4rem"
      }
    }, __cssprop(Loader, null)));
  }

  return __cssprop(React.Fragment, null, __cssprop(CreateTicketPanel, {
    ticket: data,
    onDoneSubmit: handleSubmit
  }), __cssprop(AddEventPanel, null), __cssprop(Page, {
    title: data.title,
    breadcrumbs: crumbs,
    titleMetadata: __cssprop("div", {
      css: {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "display": "flex",
        "flexDirection": "row"
      }
    }, prioritiesBadges(t)[priority], data.isRecurring && __cssprop(Badge, null, __cssprop(MdRepeat, {
      size: 25,
      color: "#6366f1"
    }))),
    primaryAction: __cssprop("div", {
      css: {
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }, __cssprop(ProtectedButton, {
      primary: true,
      onAction: handleCloseTicket,
      permission: {
        kind: "Ticket",
        permission: "mutate:create"
      },
      disabled: canCloseTicket
    }, t("tickets-info-general-close-ticket-button")), __cssprop(ProtectedButton, {
      permission: {
        kind: "Ticket",
        permission: "mutate:create"
      },
      disabled: isClosed,
      primary: true,
      onAction: () => setIsTaskPanelOpen(true)
    }, t("tickets-info-header-add-task-button"))),
    secondaryActions: {
      actions: [[{
        content: t("modify"),
        destructive: false,
        onAction: handleOpenModifySideBar,
        permission: {
          kind: "Ticket",
          permission: "mutate:update"
        }
      }, // {
      //   content: t("create-recurring-ticket"),
      //   destructive: false,
      //   onAction: handleCreateRecurring,
      //   permission: { kind: "Event", permission: "mutate:update" },
      //   disabled: isRecurring,
      // },
      // {
      //   content: "Remove recurrency",
      //   destructive: true,
      //   onAction: handleDeleteRecurring,
      //   permission: { kind: "Event", permission: "mutate:update" },
      //   disabled: !isRecurring,
      // },
      {
        content: t("ticket-delete"),
        destructive: true,
        onAction: handleDeleteTicket,
        permission: {
          kind: "Unit",
          permission: "mutate:delete"
        }
      }]],
      content: t("widget-other")
    }
  }, __cssprop(TaskPanel, {
    refetch: refetch,
    isEdit: false,
    ticket: data,
    isOpen: isTaskPanelOpen,
    handleOnClose: () => setIsTaskPanelOpen(false)
  }), __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop(TicketInfoMain, {
    data: data
  })), __cssprop(Layout, {
    oneThird: true
  }, __cssprop(TicketInfoSidebar, {
    data: data
  }))), __cssprop(DeleteModal, null)));
});
export default TicketInfoPage;