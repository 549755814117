import { useLazyQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { DatePicker } from "../../../components/DatePicker.js";
import { Button } from "../../../components/Tailwind/Button.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { ProtectedCard } from "../../../components/Tailwind/ProtectedComponents.js";
import { GetRentRollForClientDocument } from "../../../graphql/documents.js";
import { downloadFile } from "../../../helpers/files.js";
import useI18n from "../../../hooks/useI18n.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useCallback, useState } from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const RenrRollWidget = ({
  projectId
}) => {
  const {
    t
  } = useI18n();
  const [date, setDate] = useState(DateTime.now());
  const [data, setData] = useState(null);
  const [getRentRoll, {
    loading
  }] = useLazyQuery(GetRentRollForClientDocument, {
    onCompleted: ({
      statistics
    }) => {
      setData(statistics.rentRollForClient);
    }
  });
  const download = useCallback(async leasesData => {
    if (!leasesData) {
      return;
    }

    const authedDownloadURL = await downloadFile(leasesData);
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // Manage IE11+ & Edge
      window.navigator.msLaunchUri(authedDownloadURL);
    } else {
      fakeLink.setAttribute("href", authedDownloadURL);
      fakeLink.click();
    }
  }, [date]);
  const onClick = useCallback(() => {
    if (!data) {
      getRentRoll({
        variables: {
          date,
          projectId
        }
      });
      return;
    }

    download(data);
  }, [date, download, getRentRoll, data]);
  const onDateChange = useCallback(value => {
    setDate(value);
    setData(null);
  }, []);
  return __cssprop(ProtectedCard, {
    title: t("rent-roll-title"),
    permission: {
      kind: "Reporting",
      permission: "mutate:create"
    }
  }, __cssprop("div", {
    css: {
      "paddingLeft": "2.4rem",
      "paddingRight": "2.4rem",
      "marginBottom": "0.8rem",
      "maxWidth": "57.6rem",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, __cssprop("p", null, t("rent-roll-description"))), __cssprop(Card.Section, {
    greyed: true
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("div", {
    css: {
      "paddingRight": "1.2rem"
    }
  }, __cssprop(DatePicker, {
    id: "RentRollDate",
    key: "RentRollDate",
    defaultValue: date,
    onChange: onDateChange
  })), __cssprop("div", {
    css: {
      "alignSelf": "center",
      "alignItems": "center",
      "marginTop": "1.2rem"
    }
  }, __cssprop(Button, {
    onAction: onClick,
    loading: loading,
    primary: !!data
  }, data ? t("download") : t("generate"))))));
};
export default RenrRollWidget;