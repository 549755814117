import { UserSideBar } from "../../forms/SideBar/UserSideBar.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import React from "../../../../_snowpack/pkg/react.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const UserPanel = props => {
  const activeDrawer = useSelector(Selectors.app.getDrawerState);
  const {
    dispatch
  } = useAction();
  const {
    formTitle,
    description,
    sectionTitle,
    onDoneSubmit
  } = props;
  return __cssprop(SlideOver, {
    title: formTitle,
    isOpen: activeDrawer === "user",
    onClose: () => dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
      payload: null
    })
  }, __cssprop(UserSideBar, {
    description: description,
    sectionTitle: sectionTitle,
    onDoneSubmit: onDoneSubmit
  }));
};