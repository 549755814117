import React from "../../../../_snowpack/pkg/react.js";
import { TaskSideBar } from "../../forms/SideBar/TaskSideBar.js";
import useI18n from "../../hooks/useI18n.js";
import { SlideOver } from "../Tailwind/SlideOver.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const TaskPanel = ({
  refetch,
  ticket,
  isOpen,
  isEdit,
  taskId,
  handleOnClose
}) => {
  const {
    t
  } = useI18n();
  const sectionTitle = isEdit ? t("task-edit-section-title") : t("task-add-section-title");
  return __cssprop(SlideOver, {
    title: sectionTitle,
    isOpen: isOpen,
    onClose: handleOnClose
  }, __cssprop(TaskSideBar, {
    sectionTitle: sectionTitle,
    refetch: refetch,
    ticket: ticket,
    taskId: taskId
  }));
};