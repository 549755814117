import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";

/** Step Container */
export const StyledStepContainer = _styled.div(() => [{
  "width": "100%",
  "marginLeft": "auto",
  "marginRight": "auto",
  "@media (min-width: 640px)": {
    "maxWidth": "640px"
  },
  "@media (min-width: 768px)": {
    "maxWidth": "768px"
  },
  "@media (min-width: 1024px)": {
    "maxWidth": "1024px"
  },
  "@media (min-width: 1280px)": {
    "maxWidth": "1280px"
  },
  "@media (min-width: 1536px)": {
    "maxWidth": "1536px"
  },
  "maxHeight": "32rem",
  "textAlign": "center",
  "display": "flex",
  "flexDirection": "row"
}]);
/** Step Circle wraper */

export const StyledStepCircleContainer = _styled.div(() => [{
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "flex-start",
  "alignItems": "center"
}]);
/** Step Circle */

export const StyledStepCircle = _styled.div(({
  state
}) => [{
  "height": "4.8rem",
  "width": "4.8rem",
  "borderRadius": "9999px",
  "--tw-shadow": "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "top": "0px",
  "position": "relative"
}, state === "success" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(16, 185, 129, var(--tw-bg-opacity))"
}, state === "neutral" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(156, 163, 175, var(--tw-bg-opacity))"
}, state === "danger" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(220, 38, 38, var(--tw-bg-opacity))"
}, state === "warning" && {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(217, 119, 6, var(--tw-bg-opacity))"
}]);
/** Step Circle Icon */

export const StyledStepCircleIcon = _styled.div(() => [{
  "height": "2.4rem",
  "width": "100%",
  "--tw-text-opacity": "1",
  "color": "rgba(243, 244, 246, var(--tw-text-opacity))",
  "marginTop": "1.6rem",
  "display": "flex",
  "justifyContent": "center"
}]);
/** Step Circle fraction */

export const StyledStepCircleFraction = _styled.div(() => [{
  "height": "2.4rem",
  "width": "2.4rem",
  "marginTop": "0.4rem",
  "fontWeight": "500",
  "letterSpacing": "0.05em",
  "--tw-text-opacity": "1",
  "color": "rgba(0, 0, 0, var(--tw-text-opacity))"
}]);
/** Step Circle name */

export const StyledStepCircleName = _styled.div(() => [{
  "marginTop": "0.4rem",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
}]);
/** line between circle */

export const StyledCircleLine = _styled.div(() => [{
  "height": "0.2rem",
  "borderStyle": "solid",
  "width": "8rem",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))",
  "marginLeft": "0px",
  "marginRight": "0px",
  "paddingLeft": "0px",
  "paddingRight": "0px",
  "marginTop": "2.4rem"
}]);