import { mapNotes } from "../../utils/Notes.js";
import { initialState, ProviderActionTypes } from "./types.js";
export const setProviderField = (data, state) => {
  return { ...state,
    ...data.payload,
    notes: mapNotes(data.payload.notes)
  };
};
export const setProviderInitialState = state => {
  return { ...state,
    ...initialState
  };
};
export const clearState = (data, state) => {
  return { ...initialState
  };
};
export const deleteProvider = (data, state) => {
  return { ...state
  };
};
export default {
  [ProviderActionTypes.PROVIDER_SET_INITIAL_STATE]: setProviderInitialState,
  [ProviderActionTypes.PROVIDER_SET_FIELD]: setProviderField,
  [ProviderActionTypes.PROVIDER_CLEAR_STATE]: clearState,
  [ProviderActionTypes.PROVIDER_DELETE]: deleteProvider
};