function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import React, { useCallback, useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { Controller, useForm } from "../../../../_snowpack/pkg/react-hook-form.js";
import { useLazyQuery, useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { Selector } from "../../components/Selectors/Selector.js";
import { Card } from "../../components/Tailwind/Card.js";
import { ProtectedButton } from "../../components/Tailwind/ProtectedComponents.js";
import { TextField } from "../../components/Tailwind/TextField.js";
import { SimpleSelector } from "../../components/TicketInfo/TicketInfoSidebar/SimpleSelector.js";
import TicketInfoRelatedTickets from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoRelatedTickets.js";
import { GetRelatedTicketsDocument, GetTenantLeaseInfoDocument, GetUnitLeaseInfoDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { TICKET_SIDEBAR_SELECTORS } from "../../utils/ticket.js";
import { UNIT_TICKET_PRIORITY_SELECT_OPTIONS } from "../../utils/unit.js";
import { TagsSelection } from "../../views/Tickets/components/TagsSelection.js";
import { getTicketCategories } from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoSidebarGeneral.js";
import { DescriptionList } from "../../components/Tailwind/DescriptionList.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import { SmallTenant } from "../../components/Tailwind/SmallTenant.js";
import { RadioGroup } from "../../components/Tailwind/RadioGroup.js";
import { DatePicker } from "../../components/DatePicker.js";
import { UserSelector } from "../../components/Selectors/UserSelector.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const showError = (errors, id, message) => {
  if (errors[id]) {
    return {
      errorMessage: message
    };
  }

  return undefined;
};

export const typeSelectorValues = t => {
  return [{
    value: "space",
    label: t("property")
  }, {
    value: "tenant",
    label: t("tenant")
  }, {
    value: "project",
    label: t("project")
  }];
};
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);

const getResourceId = ticket => {
  if (!ticket) return "";

  if (ticket.resource.lease) {
    return ticket.resource.lease.id;
  }

  if (ticket.resource.space) {
    return ticket.resource.space.id;
  }

  if (ticket.resource.project) {
    return ticket.resource.project.id;
  }

  if (ticket.resource.tenant) {
    return ticket.resource.tenant.id;
  }

  return "";
};

const getResourceLabel = ticket => {
  if (!ticket) return ""; // should not happen for now

  if (ticket.resource.lease) {
    return "lease";
  }

  if (ticket.resource.space) {
    return ticket.resource.space.streetName ? ticket.resource.space.streetName : `${ticket.resource.space.unit.subAddress}-${ticket.resource.space.unit.buildingAddress}`;
  }

  if (ticket.resource.project) {
    return ticket.resource.project.name;
  }

  if (ticket.resource.tenant) {
    return ticket.resource.tenant.name;
  }

  return "";
};

const getResourceType = ticket => {
  if (!ticket) return "";

  if (ticket.resource.lease) {
    return "lease";
  }

  if (ticket.resource.space) {
    return "space";
  }

  if (ticket.resource.project) {
    return "project";
  }

  if (ticket.resource.tenant) {
    return "tenant";
  }

  return "";
};

export const CreateTicketSidebar = ({
  onDoneSubmit,
  ticket
}) => {
  const {
    t
  } = useI18n();
  const [typeOfResource, setTypeOfResource] = useState(getResourceType(ticket));
  const {
    control,
    handleSubmit,
    watch,
    formState: {
      errors
    }
  } = useForm({
    defaultValues: ticket ? {
      title: ticket.title,
      category: getTicketCategories(t).find(cat => cat.value === ticket.category),
      priority: UNIT_TICKET_PRIORITY_SELECT_OPTIONS.find(cat => cat.value === ticket.priority),
      resource: getResourceId(ticket)
    } : {
      type: {
        kind: "singular"
      }
    }
  });
  const resource = watch("resource");
  const [fetchRelatedTickets, {
    data: relatedTickets
  }] = useLazyQuery(GetRelatedTicketsDocument);
  useEffect(() => {
    if (typeOfResource && resource) {
      const {
        value: id
      } = resource;
      fetchRelatedTickets({
        variables: {
          resource: {
            projectIds: typeOfResource === "project" ? [id] : [],
            spaceIds: typeOfResource === "space" ? [id] : [],
            tenantIds: typeOfResource === "tenant" ? [id] : []
          }
        }
      });
    }
  }, [resource, typeOfResource]);
  const onSubmit = useCallback(data => {
    return onDoneSubmit({
      title: data.title,
      priority: data.priority.value,
      category: data.category.value,
      withTask: data.type?.kind === "singular" ? {
        task: data.type.data
      } : undefined,
      resource: {
        [`${typeOfResource === "tenant" ? "user" : typeOfResource}Id`]: data.resource?.value ? data.resource.value : data.resource
      }
    });
  }, [typeOfResource]);

  const handleChangeTypeOfResource = e => {
    setTypeOfResource(e.value);
  };

  return __cssprop("div", null, __cssprop("form", {
    onSubmit: handleSubmit(onSubmit),
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(Controller, {
    control: control,
    name: "type",
    render: ({
      field: typeField
    }) => {
      if (typeField.value?.kind === "multiple" || !!ticket) {
        return __cssprop(React.Fragment, null, __cssprop(TicketCreationOptions, {
          onChange: x => typeField.onChange({
            kind: x
          })
        }));
      }

      return __cssprop(React.Fragment, null, __cssprop(TicketCreationOptions, {
        onChange: x => typeField.onChange({
          kind: x
        })
      }), __cssprop(StyledItems, null, __cssprop(Controller, {
        name: "type.data.dueDate",
        control: control,
        rules: {
          required: true
        },
        render: ({
          field
        }) => __cssprop(DatePicker, _extends({}, field, {
          id: "dueDate",
          defaultValue: field.value,
          onChange: field.onChange,
          title: t("task-add-sidebar-due-date"),
          error: showError(errors, "type", t("field-required")),
          disabled: false
        }))
      })), __cssprop(StyledItems, null, __cssprop("h4", null, t("task-add-sidebar-user-title")), __cssprop(Controller, {
        name: "type.data.assigneeId",
        control: control,
        rules: {
          required: true
        },
        render: ({
          field,
          fieldState
        }) => __cssprop(UserSelector, {
          selected: field.value,
          onChange: v => {
            field.onChange(v.value);
          },
          defaultValue: field.value,
          placeholder: t("project-info-responsible-selector"),
          error: showError(errors, "type", t("field-required")),
          roles: []
        })
      })));
    }
  }), __cssprop(StyledItems, null, __cssprop(Controller, {
    control: control,
    name: "title",
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(TextField, _extends({
      id: "title",
      error: showError(errors, "title", t("ticket-sidebar-title-error"))
    }, field, {
      label: t("tickets-form-title"),
      placeholder: t("tickets-form-title")
    }))
  })), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("tickets-form-category")), __cssprop(Controller, {
    control: control,
    name: "category",
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      defaultValue: field.value,
      id: "language",
      options: getTicketCategories(t),
      error: showError(errors, "category", t("ticket-sidebar-category-error")),
      isClearable: false
    }))
  }))), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("tickets-form-priority")), __cssprop(Controller, {
    name: "priority",
    control: control,
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(Selector, _extends({}, field, {
      defaultValue: field.value,
      id: "priority",
      options: UNIT_TICKET_PRIORITY_SELECT_OPTIONS,
      error: showError(errors, "priority", t("ticket-sidebar-priority-error")),
      isClearable: false
    }))
  }))), __cssprop(StyledItems, null, __cssprop(TagsSelection, {
    items: ticket?.tags,
    inForm: true
  })), __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, t("tickets-resource-selector-header")), __cssprop(Controller, {
    name: "resource",
    control: control,
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => __cssprop(Selector, {
      defaultValue: typeSelectorValues(t).find(i => i.value === typeOfResource),
      options: typeSelectorValues(t),
      isClearable: false,
      onChange: handleChangeTypeOfResource,
      error: !typeOfResource && showError(errors, "resource", t("ticket-sidebar-resource-type-error"))
    })
  }))), typeOfResource && __cssprop(StyledItems, null, __cssprop("div", null, __cssprop(StyledItemsLabel, null, TICKET_SIDEBAR_SELECTORS(t)[typeOfResource].label), __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "0.4rem"
    },
    key: TICKET_SIDEBAR_SELECTORS(t)[typeOfResource].name
  }, __cssprop("div", null, __cssprop(Controller, {
    name: "resource",
    control: control,
    rules: {
      required: true,
      minLength: 1,
      min: 0
    },
    render: ({
      field
    }) => {
      return __cssprop(SimpleSelector, _extends({}, field, {
        defaultValue: {
          label: getResourceLabel(ticket),
          value: field.value
        },
        labelPattern: TICKET_SIDEBAR_SELECTORS(t)[typeOfResource].labelPattern,
        query: TICKET_SIDEBAR_SELECTORS(t)[typeOfResource].query,
        label: TICKET_SIDEBAR_SELECTORS(t)[typeOfResource].label,
        name: TICKET_SIDEBAR_SELECTORS(t)[typeOfResource].name,
        isClearable: false,
        errors: Boolean(showError(errors, "resource", t("ticket-sidebar-resource-type-error"))),
        error: showError(errors, "resource", t("ticket-sidebar-resource-type-error")),
        extraValues: TICKET_SIDEBAR_SELECTORS(t)[typeOfResource].extraValues
      }));
    }
  }))))), resource && (typeOfResource === "space" || typeOfResource === "tenant") && __cssprop(GetLeaseInfo, {
    typeOfResource: typeOfResource,
    ressourceId: resource.value
  })), relatedTickets?.tickets?.items?.length > 0 && __cssprop(Card, {
    title: "Related tickets"
  }, __cssprop(Card.Section, {
    greyed: true
  }, __cssprop(TicketInfoRelatedTickets, {
    data: relatedTickets.tickets.items
  }))), onDoneSubmit && __cssprop("div", {
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "justifyContent": "center"
    }
  }, __cssprop(ProtectedButton, {
    submit: true,
    permission: {
      kind: "Ticket",
      permission: "mutate:create"
    },
    primary: true
  }, ticket?.id ? t("modify") : t("ticket-sidebar-button-create")))));
};
export default CreateTicketSidebar;
export const GetLeaseInfo = ({
  typeOfResource,
  ressourceId
}) => {
  const {
    data,
    loading
  } = useQuery(typeOfResource === "tenant" ? GetTenantLeaseInfoDocument : GetUnitLeaseInfoDocument, {
    variables: {
      id: ressourceId
    }
  });

  if (loading || !data) {
    return null;
  }

  if (data.tenant && data?.tenant?.currentLeases?.items.length !== 0) {
    return __cssprop(LeaseDetails, {
      startDate: data?.tenant?.currentLeases?.items[0]?.lease?.startDate,
      endDate: data?.tenant?.currentLeases?.items[0]?.lease?.endDate,
      tenants: data?.tenant?.currentLeases?.items[0]?.lease?.tenants?.items
    });
  }

  if (data.unit) {
    const unitInfo = data;
    return __cssprop(LeaseDetails, {
      startDate: unitInfo?.unit?.currentLease?.startDate,
      endDate: unitInfo?.unit?.currentLease?.endDate,
      tenants: unitInfo?.unit?.currentLease?.tenants?.items
    });
  }

  return null;
};
export const LeaseDetails = ({
  startDate,
  endDate,
  tenants
}) => {
  const {
    t
  } = useI18n();

  if (!startDate || !endDate || !tenants) {
    return null;
  }

  return __cssprop(StyledItems, null, __cssprop(StyledItemsLabel, null, t("lease-viewer-lease-info")), __cssprop(DescriptionList.TwoColumn, null, __cssprop(DescriptionList.Item, {
    half: true,
    title: t("unit-lease-info-label-radio-start-date")
  }, DateTime.fromISO(startDate).toUTC().toISODate()), __cssprop(DescriptionList.Item, {
    half: true,
    title: t("unit-lease-info-label-radio-end-date")
  }, DateTime.fromISO(endDate).toUTC().toISODate())), __cssprop(StyledItemsLabel, null, t("tenants")), __cssprop(DescriptionList.TwoColumn, null, __cssprop("div", {
    css: {
      "position": "relative",
      "display": "flex",
      "alignItems": "flex-start"
    }
  }, tenants.map(tenant => {
    return __cssprop(SmallTenant, {
      tenant: tenant.tenant
    });
  }))));
};

const TicketCreationOptions = ({
  onChange
}) => {
  const {
    t
  } = useI18n();
  const settings = [{
    id: "singular",
    label: t("tickets-create-singular-title"),
    description: t("tickets-create-singular-details")
  }, {
    id: "multiple",
    label: t("tickets-create-multiple-title"),
    description: t("tickets-create-multiple-details")
  }];
  return __cssprop(RadioGroup, {
    onChange: onChange,
    selected: settings[0],
    settings: settings
  });
};