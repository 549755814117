import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useRef } from "../../../../_snowpack/pkg/react.js";
import { Dialog, Transition } from "../../../../_snowpack/pkg/@headlessui/react.js";
import { XIcon } from "../../../../_snowpack/pkg/@heroicons/react/outline.js";
import { css } from "../../../../_snowpack/pkg/@emotion/css.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const SliderContainer = _styled.div(({
  size
}) => [{
  "width": "100vw",
  "zIndex": "50"
}, size === "sm" && {
  "maxWidth": "38.4rem"
}, size === "md" && {
  "maxWidth": "44.8rem"
}, size === "lg" && {
  "maxWidth": "51.2rem"
}, size === "3xl" && {
  "maxWidth": "76.8rem"
}]);

export const SlideOver = ({
  children,
  isOpen,
  title,
  description,
  onClose,
  size = "md"
}) => {
  const closeRef = useRef();
  return __cssprop(Transition.Root, {
    show: isOpen,
    as: Fragment
  }, __cssprop(Dialog, {
    as: "div",
    css: {
      "position": "fixed",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "overflow": "hidden",
      "zIndex": "40"
    },
    onClose: () => console.log("closing"),
    initialFocus: closeRef
  }, __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "overflow": "hidden"
    }
  }, __cssprop(Transition.Child, {
    as: Fragment,
    enter: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "500ms"
    }),
    enterFrom: css({
      "opacity": "0"
    }),
    enterTo: css({
      "opacity": "0.3"
    }),
    leave: css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "500ms"
    }),
    leaveFrom: css({
      "opacity": "0.3"
    }),
    leaveTo: css({
      "opacity": "0"
    })
  }, __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(0, 0, 0, var(--tw-bg-opacity))",
      "opacity": "0.3"
    },
    onClick: onClose
  })), __cssprop("div", {
    css: {
      "position": "fixed",
      "top": "0px",
      "bottom": "0px",
      "right": "0px",
      "paddingLeft": "4rem",
      "maxWidth": "100%",
      "display": "flex",
      "@media (min-width: 640px)": {
        "paddingLeft": "6.4rem"
      }
    }
  }, __cssprop(Transition.Child, {
    as: Fragment,
    enter: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "500ms",
      "@media (min-width: 640px)": {
        "transitionDuration": "700ms"
      }
    }),
    enterFrom: css({
      "--tw-translate-x": "100%"
    }),
    enterTo: css({
      "--tw-translate-x": "0px"
    }),
    leave: css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
      "transitionDuration": "500ms",
      "@media (min-width: 640px)": {
        "transitionDuration": "700ms"
      }
    }),
    leaveFrom: css({
      "--tw-translate-x": "0px"
    }),
    leaveTo: css({
      "--tw-translate-x": "100%"
    })
  }, __cssprop(SliderContainer, {
    size: size
  }, __cssprop("div", {
    css: {
      "height": "100vh",
      "display": "flex",
      "flexDirection": "column",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "--tw-shadow": "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "overflowY": "scroll"
    }
  }, __cssprop("div", {
    css: {
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(99, 102, 241, var(--tw-bg-opacity))",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between"
    }
  }, __cssprop("div", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.8rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    }
  }, title), __cssprop("div", {
    css: {
      "marginLeft": "1.2rem",
      "height": "2.8rem",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("button", {
    ref: closeRef,
    type: "button",
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(67, 56, 202, var(--tw-bg-opacity))",
      "borderRadius": "0.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(199, 210, 254, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(255, 255, 255, var(--tw-ring-opacity))"
      }
    },
    onClick: onClose
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Close panel"), __cssprop(XIcon, {
    css: {
      "height": "2.4rem",
      "width": "2.4rem",
      "cursor": "pointer"
    },
    "aria-hidden": "true"
  })))), __cssprop("div", {
    css: {
      "marginTop": "0.4rem"
    }
  }, __cssprop("p", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(165, 180, 252, var(--tw-text-opacity))"
    }
  }, description))), __cssprop("div", {
    css: {
      "position": "relative",
      "flex": "1 1 0%",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, children))))))));
};