import "../../../_snowpack/pkg/firebase/auth.js";
export const RESOURCES = {
  Building: "building",
  Unit: "unit",
  User: "user",
  Tenants: "tenant",
  Lease: "lease",
  Renewal: "Renewal",
  Deposit: "deposit",
  Company: "company",
  Project: "project",
  Listing: "listing",
  RentalRequest: "rentalRequest",
  Ticket: "ticket",
  TicketConversation: "ticketConversation",
  TaskInvoice: "taskInvoice",
  RentalRequestV2: "rentalRequestV2"
};