function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { Badge } from "../../../components/Tailwind/Badge.js";
import { FileCategory } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { ListingActionsTypes } from "../../../store/artifacts/Listing/types.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { useDropzone } from "../../../../../_snowpack/pkg/react-dropzone.js";
import { BiImageAdd } from "../../../../../_snowpack/pkg/react-icons/bi.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const EmptyImagePlaceholder = ({
  t
}) => {
  return __cssprop("button", {
    type: "button",
    css: {
      "position": "relative",
      "display": "block",
      "width": "100%",
      "borderWidth": "2px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderStyle": "dashed",
      "borderRadius": "0.8rem",
      "padding": "4.8rem",
      "textAlign": "center",
      ":hover": {
        "--tw-border-opacity": "1",
        "borderColor": "rgba(156, 163, 175, var(--tw-border-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop(BiImageAdd, {
    css: {
      "marginLeft": "auto",
      "marginRight": "auto",
      "height": "4.8rem",
      "width": "4.8rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    }
  }), __cssprop("span", {
    css: {
      "marginTop": "0.8rem",
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("listing-add-feature-image")));
};

export const FeaturedImage = () => {
  const {
    t
  } = useI18n();
  const image = useSelector(Selectors.listing.getFeaturedPicture);
  const fullAddress = useSelector(Selectors.listing.getFullAddress);
  const published = useSelector(Selectors.listing.isPublished);
  const {
    dispatch
  } = useAction();
  const onDrop = React.useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    dispatch({
      type: ListingActionsTypes.LISTING_SET_FEATURED_IMAGE,
      payload: {
        kind: "local",
        id: `${file.name}-${file.size}`,
        order: 1,
        path: file.path,
        blob: new Blob([file]),
        preview: URL.createObjectURL(file),
        fileName: file.name,
        fileType: file.type,
        size: file.size,
        fileCategory: FileCategory.Other
      }
    });
  }, []);
  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    maxFiles: 1
  });
  return __cssprop("div", null, __cssprop("div", _extends({}, getRootProps(), {
    css: {
      "cursor": "pointer"
    }
  }), __cssprop("input", getInputProps()), !image && __cssprop(EmptyImagePlaceholder, {
    t: t
  }), image && __cssprop("div", {
    css: {
      "display": "block",
      "width": "100%",
      "--tw-aspect-w": "10",
      "position": "relative",
      "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
      "> *": {
        "position": "absolute",
        "height": "100%",
        "width": "100%",
        "top": "0",
        "right": "0",
        "bottom": "0",
        "left": "0"
      },
      "--tw-aspect-h": "7",
      "borderRadius": "0.8rem",
      "overflow": "hidden"
    }
  }, __cssprop("img", {
    src: image.kind === "local" ? image.preview : image.url,
    alt: "",
    css: {
      "objectFit": "cover"
    }
  }))), __cssprop("div", {
    css: {
      "marginTop": "1.6rem",
      "display": "flex",
      "alignItems": "flex-start",
      "justifyContent": "space-between"
    }
  }, __cssprop("div", null, __cssprop("h2", {
    css: {
      "fontSize": "1.8rem",
      "lineHeight": "2.8rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, fullAddress), __cssprop("p", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, __cssprop(Badge, {
    primary: published
  }, published ? t("published") : t("unpublished"))))));
};