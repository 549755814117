import { useMutation } from "../../../../../../_snowpack/pkg/@apollo/client.js";
import { UserSelector } from "../../../../components/Selectors/UserSelector.js";
import { ProtectedDiv } from "../../../../components/Tailwind/ProtectedComponents.js";
import { SmallTenant } from "../../../../components/Tailwind/SmallTenant.js";
import { AssignVisitAgentToApplicationDocument, UserActingPositions } from "../../../../graphql/documents.js";
import useI18n from "../../../../hooks/useI18n.js";
import React, { useCallback, useState } from "../../../../../../_snowpack/pkg/react.js";
import { toast } from "../../../../../../_snowpack/pkg/react-toastify.js";
import { ModifyButton } from "./modifyButton.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";
export const VisitAgentSelector = props => {
  const {
    t
  } = useI18n();
  const {
    visitAgent,
    rentalApplicationId,
    refetch,
    disabled
  } = props;
  const [assignVisitAgent, {
    loading: visitAgentLoading
  }] = useMutation(AssignVisitAgentToApplicationDocument, {
    fetchPolicy: "no-cache"
  });
  const [agent, setAgent] = useState(visitAgent?.id ? {
    value: visitAgent.id,
    label: visitAgent.name
  } : undefined);
  const [isEditFormAgent, setIsEditFormAgent] = useState(false);
  const handleOnSaveAgent = useCallback(async ag => {
    try {
      const visitAgentResponse = await assignVisitAgent({
        variables: {
          input: {
            rentalApplicationId,
            visitAgentId: ag.value
          }
        }
      });

      if (visitAgentResponse.errors) {
        toast.warn(`${t("generic-fetch-error-message")}`);
      }

      if (visitAgentResponse && visitAgentResponse.data) {
        setIsEditFormAgent(!visitAgentResponse.data);
        refetch();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("AgentVisitSection", e);
      toast.warn(`${t("generic-fetch-error-message")}`);
    }
  }, [rentalApplicationId, assignVisitAgent]);

  if (isEditFormAgent) {
    return __cssprop("div", {
      css: {
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "width": "100%",
        "justifyContent": "flex-start"
      }
    }, __cssprop(UserSelector, {
      selected: agent,
      onChange: setAgent,
      placeholder: t("placeholder-filter-by-client"),
      roles: [UserActingPositions.VisitAgent]
    }), __cssprop(ModifyButton, {
      disabled: disabled,
      loading: visitAgentLoading,
      inEdit: isEditFormAgent,
      setEdit: setIsEditFormAgent,
      handleOnSave: () => handleOnSaveAgent(agent)
    }));
  }

  if (visitAgent) {
    return __cssprop(React.Fragment, null, __cssprop(SmallTenant, {
      tenant: visitAgent
    }), __cssprop(ModifyButton, {
      disabled: disabled,
      loading: visitAgentLoading,
      inEdit: isEditFormAgent,
      setEdit: setIsEditFormAgent,
      handleOnSave: () => handleOnSaveAgent(agent)
    }));
  }

  return __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop("span", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "fontSize": "1.2rem",
      "lineHeight": "1.6rem",
      "fontWeight": "500"
    }
  }, t("applicant-request-form-no-visit-agent")), __cssprop(ProtectedDiv, {
    permission: {
      kind: "ApplicantRequest",
      permission: "mutate:visitAgent"
    }
  }, __cssprop(ModifyButton, {
    disabled: disabled,
    loading: visitAgentLoading,
    inEdit: isEditFormAgent,
    setEdit: setIsEditFormAgent,
    handleOnSave: () => handleOnSaveAgent(agent)
  })));
};