import React from "../../../../../../_snowpack/pkg/react.js";
import EventDescription from "./EventDescription.js";
import EventHeader from "./EventHeader.js";
import EventInterval from "./EventInterval.js";
import { jsx as __cssprop } from "../../../../../../_snowpack/pkg/@emotion/react.js";

const EventMain = () => {
  return __cssprop("div", {
    css: {
      "padding": "2.8rem",
      "display": "flex",
      "flexDirection": "column",
      "gap": "3.2rem"
    }
  }, __cssprop(EventHeader, null), __cssprop(EventDescription, null), __cssprop(EventInterval, null));
};

export default EventMain;