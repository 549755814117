import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { Link as RRDLink } from "../../../../_snowpack/pkg/react-router-dom.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const StyledA = _styled.a(({
  red
}) => [{
  "fontWeight": "500",
  "cursor": "pointer"
}, red && {
  "--tw-text-opacity": "1",
  "color": "rgba(220, 38, 38, var(--tw-text-opacity))",
  ":hover": {
    "--tw-text-opacity": "1",
    "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
  }
}, !red && {
  "--tw-text-opacity": "1",
  "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
  ":hover": {
    "--tw-text-opacity": "1",
    "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
  }
}]);

const StyledRRDLink = _styled(RRDLink)(({
  red
}) => [{
  "fontWeight": "500",
  "cursor": "pointer"
}, red && {
  "--tw-text-opacity": "1",
  "color": "rgba(220, 38, 38, var(--tw-text-opacity))",
  ":hover": {
    "--tw-text-opacity": "1",
    "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
  }
}, !red && {
  "--tw-text-opacity": "1",
  "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
  ":hover": {
    "--tw-text-opacity": "1",
    "color": "rgba(99, 102, 241, var(--tw-text-opacity))"
  }
}]);

export const Link = ({
  text,
  action,
  disabled,
  red = false,
  custom = []
}) => {
  if (disabled) {
    return __cssprop("a", {
      css: {
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
        "cursor": "not-allowed"
      }
    }, text);
  }

  switch (action.kind) {
    case "ClickAction":
      return __cssprop(StyledA, {
        red: red,
        onClick: action.event,
        "aria-hidden": "true"
      }, text);

    case "InternalLink":
      return __cssprop(StyledRRDLink, {
        red: red,
        to: action.to
      }, text);

    case "UrlAction":
    default:
      return __cssprop(StyledA, {
        red: red,
        target: action?.target || "_self",
        href: action.url
      }, text);
  }
};