import { useQuery } from "../../../_snowpack/pkg/@apollo/client.js";
import { GetBuildingsDocument } from "../graphql/documents.js";
import useI18n from "../hooks/useI18n.js";
import React, { useState, useEffect, useCallback } from "../../../_snowpack/pkg/react.js";
import Select from "../../../_snowpack/pkg/react-select.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const mapBuilding = buildings => {
  if (!buildings && buildings.length === 0) {
    return null;
  }

  return buildings.map(b => {
    return {
      value: b.id,
      label: `${b.civicNumber} ${b.streetName} (${b.postalCode})`
    };
  });
};

export const SimpleBuildingSelector = props => {
  const {
    errors,
    disabled = false,
    defaultValue = null
  } = props;
  const {
    t
  } = useI18n();
  const [buildings, setBuildings] = useState([]);
  const [buildingsById, setBuildingsById] = useState({});
  const [searchField, setSearchField] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  useQuery(GetBuildingsDocument, {
    variables: {
      take: 25,
      query: searchField,
      skip: 0
    },
    onCompleted: bData => {
      if (!bData.buildings) {
        return;
      }

      const {
        items
      } = bData.buildings;
      setBuildings(mapBuilding(items));
      setBuildingsById(items.reduce((acc, b) => ({ ...acc,
        [b.id]: b
      }), {}));
    }
  });
  useEffect(() => {
    const ids = [];
    buildings.forEach(b => {
      ids[b.value] = b.label;
    });
    const finalIds = Object.keys(ids).map(key => {
      return buildings.filter(b => b.value === key)[0];
    });
    setOptions(finalIds);
  }, [buildings, props]);
  useEffect(() => {
    if (defaultValue && buildings.length > 0) {
      const defaultOption = buildings.find(bldg => bldg.value === defaultValue);
      setSelectedOption(defaultOption);

      if (props.onChange) {
        props.onChange(buildingsById[defaultValue]);
      }
    }
  }, [buildings]);
  const onValueChange = useCallback(values => {
    setSelectedOption(values);

    if (props.onChange) {
      props.onChange(values ? buildingsById[values.value] : null);
    }
  }, [props, buildingsById]);
  return __cssprop(Select, {
    style: {
      width: "100%"
    },
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: errors ? "rgb(222, 53, 11)" : "hsl(0,0%,80%)"
      })
    },
    isClearable: true,
    isMulti: false,
    placeholder: t("buildings"),
    onChange: onValueChange,
    options: options,
    menuContainerStyle: {
      zIndex: 25
    },
    onInputChange: setSearchField,
    value: selectedOption,
    isDisabled: disabled
  });
};
export const SimpleUnitSelector = ({
  units,
  onSelect,
  errors,
  isMulti = false,
  defaultValue = null
}) => {
  const {
    t
  } = useI18n();
  const [selectedUnits, setSelectedUnits] = useState();

  if (!units || units.length === 0) {
    return null;
  }

  const options = units.map(u => {
    return {
      label: `${t("unit")} ${u.subAddress}`,
      value: u.id
    };
  });
  const defaultOption = options.find(opt => opt.value === defaultValue);

  const onSelectUnits = values => {
    if (isMulti) {
      onSelect(values.map(v => v.value));
    } else onSelect(values.value, values.label);

    setSelectedUnits(values);
  };

  return __cssprop(Select, {
    style: {
      width: "100%"
    },
    styles: {
      menu: styles => ({ ...styles,
        zIndex: 1000
      }),
      control: styles => ({ ...styles,
        borderColor: errors ? "rgb(222, 53, 11)" : "hsl(0,0%,80%)"
      }),
      valueContainer: styles => ({ ...styles,
        maxHeight: "8em",
        overflow: "auto"
      })
    },
    isClearable: true,
    isMulti: isMulti,
    placeholder: t("notification-panel-select-unit"),
    onChange: onSelectUnits,
    options: options,
    menuContainerStyle: {
      zIndex: 25
    },
    value: defaultValue ? defaultOption : selectedUnits
  });
};