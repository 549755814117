import * as React from "../../../_snowpack/pkg/react.js";
import { useCallback, useState } from "../../../_snowpack/pkg/react.js";
import useI18n from "../hooks/useI18n.js";
import { Modal } from "../components/Tailwind/Modal.js";
import { useAction } from "../hooks/useRedux.js";
import { TextField } from "../components/Tailwind/TextField.js";
import { ApplicationActionsTypes } from "../store/artifacts/Application/types.js";
import ToggleSection from "../components/ToggleSection.js";
import { Checkbox } from "../components/Checkbox/index.js";
import { Link } from "../components/Tailwind/Link.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const useSendToOwnerModal = (files, isInQueue, onSuccess) => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const [textFieldValue, setTextFieldValue] = useState("");
  const handleTextFieldChange = useCallback(value => setTextFieldValue(value), []);
  const [forceOwner, setForceOwner] = useState(false);
  const [bypassQueue, setBypassQueue] = useState(false);
  const [filesSelected, setFilesSelected] = useState([]);
  const getValues = useCallback(() => ({
    forceOwner,
    textFieldValue,
    filesSelected,
    bypassQueue
  }), [forceOwner, textFieldValue, filesSelected, bypassQueue]);

  const toggleFileSelected = (isToggleTrue, id) => {
    var index = filesSelected.indexOf(id); // doing both index and toggle check for additional safety

    if (index === -1 && isToggleTrue) {
      setFilesSelected([...filesSelected, id]);
    } else if (index > -1 && !isToggleTrue) {
      setFilesSelected([filesSelected.splice(index, 1)]);
    }
  };

  const textField = __cssprop(TextField, {
    id: "approveApplication",
    value: textFieldValue,
    onChange: handleTextFieldChange
  });

  const filesSelector = __cssprop("div", null, __cssprop("div", {
    css: {
      "lineHeight": "2.4rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "paddingBottom": "0.4rem"
    }
  }, t("applicant-confirm-approve-modal-files-header")), files.map((f, key) => {
    return __cssprop(Checkbox, {
      key: key,
      id: f.fileName,
      name: f.id,
      label: __cssprop(Link, {
        text: f.fileName,
        action: {
          kind: "UrlAction",
          url: f.url,
          target: "_blank"
        }
      }),
      onChange: event => toggleFileSelected(event, f.id),
      checked: filesSelected.indexOf(f.id) > -1,
      disabled: false
    });
  }));

  const bypassQueueToggle = __cssprop(ToggleSection, {
    title: t("applicant-confirm-bypass-queue"),
    handleToggle: event => {
      setBypassQueue(!event);
      setForceOwner(false);
    },
    needPadding: false,
    active: bypassQueue
  });

  const forceOwnerToggle = __cssprop(ToggleSection, {
    title: t("applicant-request-force-owner-approval"),
    handleToggle: event => {
      setForceOwner(!event);
      setBypassQueue(false);
    },
    needPadding: false,
    active: forceOwner
  });

  const innerElement = __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.6rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.6rem * var(--tw-space-y-reverse))"
      }
    }
  }, !forceOwner && textField, files.length > 0 && !forceOwner && filesSelector, !forceOwner && isInQueue && bypassQueueToggle, forceOwnerToggle); // return modal, open toggle,


  return [() => __cssprop(Modal, {
    modalName: "approveApplication",
    title: t("confirm-modal-title"),
    description: !forceOwner && t("applicant-confirm-approve-modal"),
    buttonContent: t("confirm-modal-confirm-button"),
    type: "successful",
    onAction: () => {
      onSuccess(true, getValues());
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
        payload: null
      });
    },
    other: innerElement
  }), () => {
    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
      payload: "approveApplication"
    });
  }];
};

export default useSendToOwnerModal;