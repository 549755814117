export const ROLE_SELECT_OPTIONS = t => [{
  label: t("user-role-section-system"),
  value: "System"
}, {
  label: t("user-role-section-owner"),
  value: "Owner"
}, {
  label: t("user-role-section-admin"),
  value: "Admin"
}, {
  label: t("user-role-section-manager"),
  value: "Manager"
}, {
  label: t("user-role-section-user"),
  value: "User"
}, {
  label: t("user-role-section-client"),
  value: "Client"
}];
export const getRoleLabel = (roleValue, t) => {
  return ROLE_SELECT_OPTIONS(t).find(role => role.value === roleValue).label;
};
export const USER_INFO_TYPE = {
  firstName: "firstName",
  lastName: "lastName",
  language: "language",
  notificationEnabled: "notificationEnabled"
};
export const USER_INFO_TYPE_DEFAULTS = {
  firstName: "",
  lastName: "",
  language: "FR",
  notificationEnabled: false
};
export const getUserInformation = (user, type) => {
  if (!user || !user.userInformation && !user.information) {
    return USER_INFO_TYPE_DEFAULTS[type];
  }

  const value = user[user.userInformation ? "userInformation" : "information"][type];
  return value || USER_INFO_TYPE_DEFAULTS[type];
};
export function getBrowserLocales() {
  const locales = navigator.languages || [navigator.language];
  return locales.map(locale => locale.trim().split(/-|_/)[0]);
}