import { a as __extends, _ as __assign } from '../common/tslib.es6-4411dcda.js';
import '../common/_commonjsHelpers-f5d70792.js';
import { r as react } from '../common/index-8f144fe1.js';
import { aC as CalendarDataProvider, aD as CalendarRoot, aE as CalendarContent, aF as CalendarApi } from '../common/main-7e1b3fa6.js';
import '../common/index-821eef78.js';
import './react/dist/vdom.js';

var FullCalendar = /** @class */ (function (_super) {
    __extends(FullCalendar, _super);
    function FullCalendar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._calendarApi = new CalendarApi();
        return _this;
    }
    FullCalendar.prototype.render = function () {
        return (react.createElement(CalendarDataProvider, { optionOverrides: this.props, calendarApi: this._calendarApi }, function (data) { return (react.createElement(CalendarRoot, { options: data.calendarOptions, theme: data.theme, emitter: data.emitter }, function (classNames, height, isHeightAuto, forPrint) { return (react.createElement("div", { className: classNames.join(' '), style: { height: height } },
            react.createElement(CalendarContent, __assign({ isHeightAuto: isHeightAuto, forPrint: forPrint }, data)))); })); }));
    };
    FullCalendar.prototype.getApi = function () {
        return this._calendarApi;
    };
    return FullCalendar;
}(react.Component));

export default FullCalendar;
