/** capitalize first letter of a word */
export function capitalizeFirstLetter(word) {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1);
}
/** format the phone number */

export function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) {
    return null;
  }

  const cleaned = phoneNumberString.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return phoneNumberString;
}