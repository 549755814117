import { useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { Loader } from "../../components/Loader.js";
import { PaymentPanel } from "../../components/Panels/PaymentPanel.js";
import { Badge } from "../../components/Tailwind/Badge.js";
import { Button } from "../../components/Tailwind/Button.js";
import { DataList } from "../../components/Tailwind/DataList.js";
import { DropDownActions } from "../../components/Tailwind/DropDownActions.js";
import { EmptyState } from "../../components/Tailwind/EmptyState.js";
import { Link } from "../../components/Tailwind/Link.js";
import { Modal } from "../../components/Tailwind/Modal.js";
import { Page } from "../../components/Tailwind/Page.js";
import { ProtectedActions } from "../../components/Tailwind/ProtectedComponents.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import { GetListingsDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { PaymentStatus } from "../../store/artifacts/Payment/types.js";
import { isRentableUnitGQL } from "../../utils/unit.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { HiUserCircle } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { useHistory } from "../../../../_snowpack/pkg/react-router.js";
import ReactTooltip from "../../../../_snowpack/pkg/react-tooltip.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const renderAddress = (dispatch, history, item) => {
  let action;

  if (isRentableUnitGQL(item)) {
    action = {
      kind: "ClickAction",
      event: () => {
        history.push(`/buildings/${item.id}`);
      }
    };
    return __cssprop(Link, {
      action: action,
      text: `${item?.civicNumber} ${item?.streetName}`
    });
  }

  action = {
    kind: "ClickAction",
    event: () => {
      history.push(`/unit/${item.id}`);
    }
  };
  return __cssprop(Link, {
    action: action,
    text: `${item?.subAddress}-${item.building?.civicNumber} ${item.building?.streetName}`
  });
};

const statusToStr = (status, t) => {
  switch (status) {
    case "Completed":
      return __cssprop(Badge, {
        primary: true
      }, t("status-completed"));

    case "Erroneous":
      return __cssprop(Badge, {
        critical: true
      }, t("status-erroneous"));

    case "Processing":
      return __cssprop(Badge, null, t("status-processing"));

    case "Tentative":
      return __cssprop(Badge, {
        warning: true
      }, t("status-tentative"));

    default:
      return __cssprop("div", {
        "data-tip": t("widget-status-other")
      }, __cssprop(Badge, null, t("widget-other")), __cssprop(ReactTooltip, {
        backgroundColor: "#3f4eae",
        type: "info"
      }));
  }
};

const displayTenant = tenants => {
  const tenantString = tenants.map(tenant => {
    return tenant.name;
  });
  return tenantString.toString().replace(/,(?=[^\s])/g, ", ");
};

const renderUser = (item, role, t) => {
  return __cssprop("div", {
    tw: "mx-1 inline-flex space-x-1 rounded-full border border-gray-300 p-1 px-2"
  }, item?.responsible?.profileImage?.url ? __cssprop("img", {
    tw: "h-5 w-5 rounded-full",
    src: item.responsible.profileImage.url,
    alt: ""
  }) : __cssprop(HiUserCircle, {
    tw: "h-5 w-5 text-gray-500",
    "aria-hidden": "true"
  }), __cssprop("div", {
    tw: "flex space-y-1 "
  }, __cssprop("div", {
    tw: "flex items-center justify-between"
  }, __cssprop("h3", {
    tw: "text-sm text-gray-900 capitalize"
  }, role === "Client" ? t("project-info-contact-person") : item?.responsible?.name))));
};

const renderActions = (dispatch, t, role) => {
  return __cssprop("div", {
    tw: "md:flex md:items-center md:justify-between"
  }, __cssprop("div", {
    tw: "flex-shrink-0 flex space-x-4"
  }, __cssprop("div", null, __cssprop(Button, {
    primary: true,
    onAction: () => {
      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
        payload: "confirmPayment"
      });
    }
  }, "Approuv\xE9")), __cssprop("div", {
    tw: ""
  }, __cssprop(DropDownActions, {
    absolute: true,
    content: t("widget-other"),
    actions: [ProtectedActions(role, [{
      content: "Voir plus",
      onAction: () => {
        dispatch({
          type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
          payload: "payment"
        });
      },
      permission: {
        kind: "Lease",
        permission: "view:lease"
      }
    }]), ProtectedActions(role, [{
      content: "Refusé",
      destructive: true,
      onAction: () => {
        console.log("test");
      },
      permission: {
        kind: "Lease",
        permission: "mutate:delete"
      }
    }])]
  }))));
};

const fakeData = [{
  unit: {
    __typename: "UnitGQL"
  },
  building: {
    civicNumber: "185",
    streetName: "Rue Aralie"
  },
  id: "6141fec4d44b7e9b920d9269",
  subAddress: "1",
  amountDue: 800,
  paymentProcessingDate: DateTime.now(),
  paymentStatus: PaymentStatus.Completed
}];
export const PaymentDataList = () => {
  const role = useSelector(Selectors.session.getUserRole);
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const history = useHistory();
  const headings = [t("building"), t("amount"), t("deposit-form-date"), t("status"), "Actions"];
  const rows = fakeData.map(item => {
    const row = {
      items: [[renderAddress(dispatch, history, item)], [`${item.amountDue}$`], [item.paymentProcessingDate.toUTC().toFormat("MMMM dd, yyyy")], [statusToStr(item.paymentStatus, t)], [renderActions(dispatch, t, role)]]
    };
    return row;
  });
  return __cssprop(React.Fragment, null, __cssprop(Modal, {
    modalName: "confirmPayment",
    title: "Confirmation",
    description: "je comprend ce que je fais",
    buttonContent: "j'accept",
    type: "successful"
  }), __cssprop(PaymentPanel, {
    formTitle: "D\xE9tails du paiement",
    description: "",
    sectionTitle: "Information du paiement"
  }), __cssprop(DataList, {
    headings: headings,
    title: t("list-heading-payments"),
    rows: rows
  }));
};
export const Payments = withBreadcrumb(() => {
  const {
    t
  } = useI18n();
  const {
    data,
    loading
  } = useQuery(GetListingsDocument, {
    variables: {
      take: 999,
      skip: 0
    }
  });

  if (loading) {
    return __cssprop(Loader, null);
  }

  if (!data) {
    return __cssprop("div", null, __cssprop(EmptyState, {
      image: "/img/noListing.svg",
      heading: t("listings-empty-state")
    }));
  }

  return __cssprop(Page, {
    title: t("payments")
  }, __cssprop(PaymentDataList, null));
});
export default Payments;