import _styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { useMutation } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { Loader } from "../../../components/Loader.js";
import { CreateProviderDocument, UpdateProviderDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { FormProvider, useForm } from "../../../../../_snowpack/pkg/react-hook-form.js";
import { toast } from "../../../../../_snowpack/pkg/react-toastify.js";
import { Button } from "../../../components/Tailwind/Button.js";
import { StringField } from "./StringField.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const StyledItems = _styled.div(() => [{
  "@media (min-width: 640px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gap": "1.6rem",
    "alignItems": "flex-start",
    "borderTopWidth": "1px",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "paddingTop": "2rem"
  }
}]);
export const StyledItemsLabel = _styled.span(() => [{
  "display": "block",
  "fontSize": "1.4rem",
  "lineHeight": "2rem",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "paddingBottom": "0.4rem"
}]);
export const ProviderSideBarFormView = props => {
  const {
    t
  } = useI18n();
  const {
    refetch,
    providerId,
    providerProp
  } = props;
  const defaultProps = {
    name: "",
    email: null,
    primaryPhone: null,
    secondaryPhone: null
  };
  const defaultProviderProps = providerProp ?? defaultProps;
  const methods = useForm({
    defaultValues: defaultProviderProps
  });
  const {
    handleSubmit
  } = methods;
  const [createProvider, {
    loading: createProviderLoading
  }] = useMutation(CreateProviderDocument, {
    fetchPolicy: "no-cache"
  });
  const [updateProvider, {
    loading: updateProviderLoading
  }] = useMutation(UpdateProviderDocument, {
    fetchPolicy: "no-cache"
  });
  const onSubmit = handleSubmit(async provider => {
    try {
      const providerInput = {
        id: providerId,
        provider: {
          firstName: provider.name,
          email: provider.email,
          primaryPhone: provider.primaryPhone,
          secondaryPhone: provider.secondaryPhone
        }
      };
      let providerSaveResponse;

      if (providerId) {
        providerSaveResponse = await updateProvider({
          variables: {
            provider: providerInput
          }
        });
      } else {// providerSaveResponse = await createProvider({
        //   variables: {
        //     provider: {
        //       organizationId:" ",
        //       provider: {
        //         ...provider,
        //       },
        //     },
        //   },
        // });
      }

      if (providerSaveResponse.errors) {
        toast.warn(t("generic-fetch-error-message"));
      }

      if (providerSaveResponse && providerSaveResponse.data) {
        toast.success(t("rental-modify-success"));
        refetch();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("ListingSection", e);
      toast.warn(t("generic-fetch-error-message"));
    }
  });
  const isLoading = createProviderLoading || updateProviderLoading;

  if (isLoading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  return __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "gap": "1.6rem"
    }
  }, __cssprop(FormProvider, methods, __cssprop("form", {
    onSubmit: onSubmit,
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }
  }, __cssprop(StyledItems, null, __cssprop(StringField, {
    title: t("provider-form-name"),
    name: "name"
  })), __cssprop(StyledItems, null, __cssprop(StringField, {
    title: t("provider-form-email"),
    name: "email"
  })), __cssprop(StyledItems, null, __cssprop(StringField, {
    title: t("provider-form-primary-phone"),
    name: "primaryPhone"
  })), __cssprop(StyledItems, null, __cssprop(StringField, {
    title: t("provider-form-secondary-phone"),
    name: "secondaryPhone"
  })), __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "row",
      "width": "100%",
      "justifyContent": "space-evenly"
    }
  }, __cssprop(Button, {
    submit: true,
    primary: true
  }, t("save")))))));
};