import { gql, useMutation } from "../../../_snowpack/pkg/@apollo/client.js";
import { Modal } from "../components/Tailwind/Modal.js";
import useI18n from "../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../hooks/useRedux.js";
import { deleteListing } from "../services/rental.js";
import { ApplicationActionsTypes } from "../store/artifacts/Application/types.js";
import { ProjectActionsTypes } from "../store/artifacts/Project/types.js";
import { TicketActionTypes } from "../store/artifacts/Tickets/types.js";
import * as React from "../../../_snowpack/pkg/react.js";
import { useCallback, useEffect, useState } from "../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../_snowpack/pkg/react-router.js";
import { toast } from "../../../_snowpack/pkg/react-toastify.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
export const RESOURCE = {
  Building: "Building",
  Project: "Project",
  Lease: "Lease",
  Note: "Note",
  Tenant: "Tenant",
  Ticket: "Ticket",
  Unit: "Unit",
  Deposit: "Deposit",
  Users: "Users",
  ProjectUser: "ProjectUser",
  Invite: "Invite"
};
export const getTranslatedResource = t => ({
  Building: {
    name: "Building",
    input: "UUID",
    // { id }
    key: "id",
    route: "/properties",
    messages: {
      action: t("delete-building"),
      warning: t("delete-building-warning"),
      lastWarning: t("delete-building-last-warning"),
      success: t("delete-building-success"),
      error: t("delete-building-error")
    },
    gql: gql`
      mutation DeleteBuilding($id: UUID!) {
        DeleteBuilding(id: $id) {
          __typename
        }
      }
    `
  },
  Project: {
    name: "Project",
    input: "UUID",
    // { id }
    key: "id",
    route: "/projects",
    messages: {
      action: t("delete-project"),
      warning: t("delete-project-warning"),
      lastWarning: t("delete-project-last-warning"),
      success: t("delete-project-success"),
      error: t("delete-project-error")
    }
  },
  Lease: {
    name: "Lease",
    input: "UUID",
    // { id }
    key: "id",
    messages: {
      action: t("delete-lease"),
      warning: t("delete-lease-warning"),
      lastWarning: t("delete-lease-last-warning"),
      success: t("delete-lease-success"),
      error: t("delete-lease-error")
    }
  },
  Note: {
    name: "Note",
    input: "UUID",
    // { id }
    key: "id",
    messages: {
      action: t("delete-note"),
      warning: t("delete-note-warning"),
      lastWarning: t("delete-note-last-warning"),
      success: t("delete-note-success"),
      error: t("delete-note-error")
    }
  },
  Tenant: {
    name: "Tenant",
    input: "UUID",
    // { id }
    key: "id",
    route: "/tenants",
    messages: {
      action: t("delete-tenant"),
      warning: t("delete-tenant-warning"),
      lastWarning: t("delete-tenant-last-warning"),
      success: t("delete-tenant-success"),
      error: t("delete-tenant-error")
    }
  },
  Unit: {
    name: "Unit",
    input: "UUID",
    // { id }
    key: "id",
    messages: {
      action: t("delete-unit"),
      warning: t("delete-unit-warning"),
      lastWarning: t("delete-unit-last-warning"),
      success: t("delete-unit-success"),
      error: t("delete-unit-error")
    }
  },
  Ticket: {
    name: "Ticket",
    input: "UUID",
    // { id }
    key: "ticketId",
    route: "/tickets",
    messages: {
      action: t("delete-ticket"),
      warning: t("delete-ticket-warning"),
      lastWarning: t("delete-ticket-last-warning"),
      success: t("delete-ticket-success"),
      error: t("delete-ticket-error")
    }
  },
  Deposit: {
    name: "Deposit",
    input: "UUID",
    // { deposits: [id] }
    key: "deposits",
    messages: {
      action: t("delete-deposit"),
      warning: t("delete-deposit-warning"),
      lastWarning: t("delete-deposit-last-warning"),
      success: t("delete-deposit-success"),
      error: t("delete-deposit-error")
    }
  },
  Users: {
    name: "Users",
    input: "UUID",
    // { users: [id] }
    key: "users",
    route: "/users",
    messages: {
      action: t("delete-user"),
      warning: t("delete-user-warning"),
      lastWarning: t("delete-user-last-warning"),
      success: t("delete-user-success"),
      error: t("delete-user-error")
    }
  },
  Invite: {
    name: "Registration",
    input: "UUID",
    // { users: [id] }
    key: "id",
    messages: {
      warning: t("registration-delete-modal-warning"),
      lastWarning: t("registration-delete-modal-last-warning"),
      success: t("registration-delete-invite-success"),
      error: t("registration-delete-invite-error")
    }
  },
  ProjectUser: {
    name: "ProjectUser",
    input: "UUID",
    // { users: [id] }
    key: "users",
    messages: {
      action: t("project-remove-modal-action"),
      warning: t("project-remove-modal-warning"),
      lastWarning: t("project-remove-modal-last-warning"),
      success: t("project-remove-modal-last-success"),
      error: t("project-remove-modal-last-error")
    },
    gql: gql`
      mutation RemoveAccess($access: ModifyAccess!) {
        RemoveAccess(access: $access) {
          id
        }
      }
    `
  }
});

const DELETE_RESOURCE = resource => gql`
  mutation delete${resource.name}($id: ${resource.input}!) {
    delete${resource.name}(id: $id){
      id
    }
  }
`;

const DeleteModal = props => {
  const {
    onSuccess,
    resource,
    extraContext = [],
    resourceId,
    adIds
  } = props;
  const projectId = useSelector(Selectors.app.getProjectId);
  const {
    t
  } = useI18n();
  const history = useHistory();
  const [deleteResource] = useMutation(resource?.gql || DELETE_RESOURCE(resource));
  const {
    dispatch
  } = useAction();
  const onDelete = useCallback(async () => {
    try {
      if (resource.name === "ProjectUser") {
        dispatch({
          type: ProjectActionsTypes.PROJECT_REMOVE_USER,
          payload: resourceId
        });
      } else if (resource.name === "ProjectUser") {
        await deleteResource({
          variables: {
            access: {
              resourceId: resourceId.projectId,
              userId: resourceId.userId,
              resourceType: "Project"
            }
          }
        });
      } else if (resource.name === "Ticket") {
        dispatch({
          type: TicketActionTypes.TICKET_DELETE,
          payload: {
            ticketId: resourceId.ticketId
          }
        });
      } else {
        if (resource.name === "Building" && adIds && adIds.length > 0) {
          await Promise.all(adIds.reduce(async (acc, ad) => {
            if (ad) {
              acc.push(deleteListing(ad, projectId));
            }

            return acc;
          }, []));
        }

        await deleteResource({
          variables: {
            [resource.key]: resourceId
          }
        });
      }

      if (onSuccess) {
        await onSuccess();
      }

      toast.success(resource.messages.success);
    } catch (e) {
      toast.error(resource.messages.error);
    }
  }, [resource, adIds, deleteResource, resourceId, onSuccess, projectId]);
  return __cssprop(Modal, {
    modalName: resource.name,
    title: resource.name === "ProjectUser" ? t("project-remove-modal-action") : t("delete"),
    description: resource.messages.warning,
    buttonContent: resource.name === "ProjectUser" ? t("project-remove-modal-action") : t("delete"),
    type: "destructive",
    onAction: async () => {
      await onDelete();

      if (resource.route) {
        history.replace(resource.route);
        return;
      }

      dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
        payload: null
      });
    }
  });
};

export const useDeleteModal = (deleteId, resourceName, onSuccess, deleteAdIds) => {
  const role = useSelector(Selectors.session.getUserRole);
  const {
    t
  } = useI18n();
  const resource = getTranslatedResource(t)[resourceName];
  const [extraContext, setExtraContext] = useState([]);
  const [resourceId, setResourceId] = useState(deleteId);
  const [adIds, setAdIds] = useState(deleteAdIds);
  const {
    dispatch
  } = useAction();
  useEffect(() => {
    if (deleteAdIds) {
      setAdIds(deleteAdIds);
    }
  }, [deleteAdIds]);
  const setResourceAndOpen = useCallback(async (id, extra) => {
    if (id) {
      await setResourceId(id);
    }

    if (extra) {
      await setExtraContext(extra);
    }

    dispatch({
      type: ApplicationActionsTypes.APPLICATION_SET_MODAL_STATE,
      payload: resource.name
    });
  }, [setResourceId, setExtraContext]);
  return [() => __cssprop(DeleteModal, {
    extraContext: extraContext,
    onSuccess: onSuccess,
    resource: resource,
    adIds: adIds,
    resourceId: resourceId
  }), setResourceAndOpen, {
    content: resource.messages.action,
    disabled: role === "Client"
  }];
};
export default useDeleteModal;