import { useQuery } from "../../../../_snowpack/pkg/@apollo/client.js";
import { GetOrganizationsDocument } from "../../graphql/documents.js";
import useI18n from "../../hooks/useI18n.js";
import React, { useState, useEffect, useCallback } from "../../../../_snowpack/pkg/react.js";
import { Selector } from "./Selector.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const OrganizationSelector = props => {
  const {
    isMulti,
    error,
    isForm = false,
    disabled,
    selectedProject,
    label,
    defaultValue
  } = props;
  const {
    t
  } = useI18n();
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(selectedProject ? [{
    label: selectedProject.name,
    value: selectedProject.id
  }] : []);
  const {
    data
  } = useQuery(GetOrganizationsDocument, {
    variables: {
      take: 1000,
      skip: 0
    }
  });
  useEffect(() => {
    if (!data) {
      return;
    }

    const filtered = data.organizations.items;
    const ids = {};
    filtered.forEach(u => {
      ids[u.id] = u.name;
    });
    const finalIds = Object.keys(ids).map(key => {
      return {
        value: key,
        label: ids[key]
      };
    });

    if (isForm && !selectedProject?.id) {
      setSelectedOptions([finalIds[0]]);
    }

    setOptions(finalIds);

    if (props.onLoad) {
      props.onLoad(finalIds);
    }
  }, [data]);
  const onChange = useCallback(values => {
    // Deposit page values are not an array so we need to check and cope.
    setSelectedOptions(Array.isArray(values) ? [...values] : [values]);

    if (props.onChange) {
      props.onChange(values);
    }
  }, [props, selectedOptions, isMulti]);
  return __cssprop(React.Fragment, null, __cssprop(Selector, {
    label: isForm || label ? isForm || t("project") : null,
    isClearable: true,
    isMulti: isMulti,
    placeholder: t("placeholder-filter-by-client"),
    onChange: onChange,
    options: options,
    selectedItem: selectedOptions,
    disabled: disabled,
    defaultValue: defaultValue,
    error: error
  }));
};