import useI18n from "../../hooks/useI18n.js";
import { Selectors, useSelector } from "../../hooks/useRedux.js";
import { DateTime } from "../../../../_snowpack/pkg/luxon.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { useCallback, useState } from "../../../../_snowpack/pkg/react.js";
import { HiUserCircle } from "../../../../_snowpack/pkg/react-icons/hi.js";
import { Button } from "./Button.js";
import { Card } from "./Card.js";
import { DropDownActions } from "./DropDownActions.js";
import { ProtectedActions, ProtectedCard } from "./ProtectedComponents.js";
import { ProfilePicture } from "./ProfilePicture.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const AddNoteSection = ({
  addNoteEvent,
  loading
}) => {
  const userImage = useSelector(Selectors.session.getUserProfileImage);
  const {
    t
  } = useI18n();
  const [note, setNote] = useState("");
  const handleChangeValue = useCallback(event => {
    const {
      value
    } = event.target;
    setNote(value);
  }, [setNote]);
  const onAction = useCallback(() => {
    addNoteEvent({
      note
    });
  }, [addNoteEvent, note]);
  return __cssprop(Card.Section, {
    greyed: true
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1.2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop("div", {
    css: {
      "flexShrink": "0"
    }
  }, userImage ? __cssprop("img", {
    css: {
      "height": "4rem",
      "width": "4rem",
      "borderRadius": "9999px"
    },
    src: userImage,
    alt: ""
  }) : __cssprop(HiUserCircle, {
    css: {
      "height": "4rem",
      "width": "4rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })), __cssprop("div", {
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%"
    }
  }, __cssprop("div", null, __cssprop("textarea", {
    value: note,
    onChange: handleChangeValue,
    rows: 3,
    css: {
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "display": "block",
      "width": "100%",
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(59, 130, 246, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(59, 130, 246, var(--tw-border-opacity))"
      },
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.6rem",
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    },
    placeholder: t("note-section-placeholder")
  })), __cssprop("div", {
    css: {
      "marginTop": "1.2rem",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "flex-end"
    }
  }, __cssprop(Button, {
    primary: true,
    loading: loading,
    onAction: onAction
  }, t("note-section-add-note"))))));
};

export const Notes = ({
  notes,
  permission,
  hideNewNote,
  addNoteEvent,
  removeNoteEvent,
  isViewOnly = false,
  loading = false
}) => {
  const {
    t
  } = useI18n();
  const user = useSelector(Selectors.session.getUser);
  const role = useSelector(Selectors.session.getUserRole);

  if (isViewOnly && !notes) {
    return null;
  }

  return __cssprop("section", {
    "aria-labelledby": "notes-title"
  }, __cssprop(ProtectedCard, {
    title: t("notes-title"),
    permission: permission,
    sectionned: true
  }, notes?.length > 0 && __cssprop(Card.Section, null, __cssprop("ul", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(3.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(3.2rem * var(--tw-space-y-reverse))"
      }
    }
  }, notes.map(note => {
    if (note.kind === "remote") {
      return __cssprop("li", {
        key: note.id
      }, __cssprop("div", {
        css: {
          "display": "flex",
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-x-reverse": 0,
            "marginRight": "calc(1.2rem * var(--tw-space-x-reverse))",
            "marginLeft": "calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))"
          }
        }
      }, __cssprop("div", {
        css: {
          "flexShrink": "0"
        }
      }, __cssprop(ProfilePicture, {
        id: note?.createdBy?.id
      })), __cssprop("div", {
        css: {
          "flex": "1 1 0%"
        }
      }, __cssprop("div", {
        css: {
          "fontSize": "1.4rem",
          "lineHeight": "2rem"
        }
      }, __cssprop("span", {
        css: {
          "fontWeight": "500",
          "--tw-text-opacity": "1",
          "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
        }
      }, note?.createdBy?.name)), __cssprop("div", {
        css: {
          "marginTop": "0.4rem",
          "fontSize": "1.4rem",
          "lineHeight": "2rem",
          "--tw-text-opacity": "1",
          "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
        }
      }, __cssprop("p", null, note.content)), __cssprop("div", {
        css: {
          "> :not([hidden]) ~ :not([hidden])": {
            "--tw-space-x-reverse": 0,
            "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
            "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
          }
        }
      }, __cssprop("span", {
        css: {
          "--tw-text-opacity": "1",
          "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
          "fontWeight": "500",
          "fontSize": "1.2rem",
          "lineHeight": "1.6rem"
        }
      }, DateTime.fromISO(note.createdAt).toRelative()))), !isViewOnly && __cssprop("div", null, __cssprop(DropDownActions, {
        content: "open",
        dots: true,
        actions: [ProtectedActions(role, [{
          content: t("delete"),
          destructive: true,
          onAction: () => {
            removeNoteEvent(note);
          },
          permission: {
            kind: permission.kind,
            permission: "mutate:delete"
          }
        }])]
      }))));
    }

    return __cssprop("li", {
      css: {
        "display": "flex",
        "flexGrow": "1"
      },
      key: note.content
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(1.2rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(1.2rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "width": "100%"
      }
    }, __cssprop("div", {
      css: {
        "flexShrink": "0"
      }
    }, __cssprop(ProfilePicture, {
      id: user.id
    })), __cssprop("div", {
      css: {
        "flex": "1 1 0%"
      }
    }, __cssprop("div", {
      css: {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }, __cssprop("span", {
      css: {
        "fontWeight": "500",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      }
    }, user.name)), __cssprop("div", {
      css: {
        "marginTop": "0.4rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "--tw-text-opacity": "1",
        "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
      }
    }, __cssprop("p", null, note.content)), __cssprop("div", {
      css: {
        "marginTop": "0.8rem",
        "fontSize": "1.4rem",
        "lineHeight": "2rem",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(0.8rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(0.8rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }, __cssprop("span", {
      css: {
        "--tw-text-opacity": "1",
        "color": "rgba(99, 102, 241, var(--tw-text-opacity))",
        "fontWeight": "500"
      }
    }, t("not-saved")))), !isViewOnly && __cssprop("div", null, __cssprop(DropDownActions, {
      content: "open",
      dots: true,
      actions: [ProtectedActions(role, [{
        content: t("delete"),
        destructive: true,
        onAction: () => {
          removeNoteEvent(note);
        },
        permission: {
          kind: permission.kind,
          permission: "mutate:delete"
        }
      }])]
    }))));
  }))), !hideNewNote && __cssprop(AddNoteSection, {
    addNoteEvent: addNoteEvent,
    loading: loading
  })));
};