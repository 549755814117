/* eslint-disable import/no-cycle */
import { createReducer } from "../../helpers/actions.js";
import reducers from "./reducers.js";
import sagas from "./sagas.js";
import selectors from "./selectors.js";
import { initialState } from "./types.js";
export const getProvider = state => state?.provider;
export default {
  sagas: sagas.executers,
  reducer: createReducer(initialState, reducers),
  selectors
};