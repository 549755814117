/* eslint-disable import/no-cycle */
import { createReducer } from "../../helpers/actions.js";
import { mapFiles } from "../../utils/Files.js";
import { initialState, ListingActionsTypes } from "./types.js";

const setInitialState = (data, state) => {
  if (data.payload.kind === "WithListingId") {
    return { ...initialState,
      id: data.payload.listingId
    };
  }

  return initialState;
};

export const setField = (data, state) => {
  return { ...state,
    ...data.payload
  };
};
export const setUnitField = (data, state) => {
  return { ...state,
    unit: { ...state.unit,
      ...data.payload
    }
  };
};

const addGalleryImages = (data, state) => ({ ...state,
  gallery: mapFiles([...state.gallery, ...data.payload], "local")
});

const removeGalleryImages = (data, state) => ({ ...state,
  gallery: state.gallery.filter(i => i.id !== data.payload)
});

const setFeaturedImage = (data, state) => ({ ...state,
  thumbnail: data.payload
});

const unsetFeaturedImage = (_data, state) => ({ ...state,
  thumbnail: null
});

export default createReducer(initialState, {
  [ListingActionsTypes.LISTING_INITIALIZE]: setInitialState,
  [ListingActionsTypes.LISTING_ADD_GALLERY_ITEMS]: addGalleryImages,
  [ListingActionsTypes.LISTING_REMOVE_GALLERY_ITEMS]: removeGalleryImages,
  [ListingActionsTypes.LISTING_SET_FEATURED_IMAGE]: setFeaturedImage,
  [ListingActionsTypes.LISTING_UNSET_FEATURED_IMAGE]: unsetFeaturedImage,
  [ListingActionsTypes.LISTING_SET_FIELD]: setField,
  [ListingActionsTypes.LISTING_SET_UNIT_FIELD]: setUnitField
});