import useI18n from "../../hooks/useI18n.js";
import { useSelector } from "../../hooks/useRedux.js";
import { Selectors } from "../../store/index.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { Alert } from "../Tailwind/Alert.js";
import { Attachments } from "../Tailwind/Attachments.js";
import { Card } from "../Tailwind/Card.js";
import { DescriptionList } from "../Tailwind/DescriptionList.js";
import { Link } from "../Tailwind/Link.js";
import { ProtectedCard, ProtectedCardSection } from "../Tailwind/ProtectedComponents.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
export const BuildingCard = () => {
  const {
    t
  } = useI18n();
  const city = useSelector(Selectors.building.getCity);
  const sector = useSelector(Selectors.building.getSector);
  const province = useSelector(Selectors.building.getProvince);
  const postalCode = useSelector(Selectors.building.getPostalCode);
  const country = useSelector(Selectors.building.getCountry);
  const buildDate = useSelector(Selectors.building.getBuildDate);
  const purchaseDate = useSelector(Selectors.building.getPurchaseDate);
  const project = useSelector(Selectors.building.getProject);
  const files = useSelector(Selectors.building.getFiles);
  return __cssprop(ProtectedCard, {
    title: t("building-info-card-building-info"),
    permission: {
      kind: "Building",
      permission: "view:info"
    }
  }, project?.managementType === "RentalOnly" && __cssprop(Card.Section, null, __cssprop(Alert, {
    type: "warning",
    title: t("building-info-only-location")
  })), __cssprop(Card.Section, null, __cssprop(DescriptionList.TwoColumn, null, __cssprop(DescriptionList.Item, {
    title: t("building-form-city")
  }, `${city} ${sector ? `(${sector})` : ""} ${province} (${postalCode}) ${country}`), __cssprop(DescriptionList.Item, {
    title: t("building-info-construction-year"),
    half: true
  }, buildDate.year), purchaseDate && __cssprop(DescriptionList.Item, {
    title: t("building-info-acquisition-date"),
    half: true
  }, purchaseDate.toFormat("MMMM yyyy")), project && __cssprop(DescriptionList.Item, {
    title: t("project"),
    half: true
  }, __cssprop(Link, {
    text: project.name,
    action: {
      kind: "InternalLink",
      to: `/clients/${project.id}`
    }
  })))), files && files?.length > 0 && __cssprop(ProtectedCardSection, {
    greyed: true,
    permission: {
      kind: "Building",
      permission: "view:files"
    }
  }, __cssprop(Attachments, {
    visualOnly: true,
    files: files.map(file => {
      return {
        fileName: file.fileName,
        fileType: file.fileType,
        url: file.kind === "remote" ? file.url : file.preview,
        filePath: file.kind === "remote" ? file.filePath : ""
      };
    })
  })));
};
export default BuildingCard;