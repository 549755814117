import { Loader } from "../../components/Loader.js";
import { ProjectPanel } from "../../components/Panels/ProjectPanel.js";
import { UserPanel } from "../../components/Panels/UserPanel.js";
import { AccountCard } from "../../components/Tailwind/AccountCard.js";
import { Card } from "../../components/Tailwind/Card.js";
import { EmptyState } from "../../components/Tailwind/EmptyState.js";
import { Notes } from "../../components/Tailwind/Notes.js";
import { ProtectedButton, ProtectedTab } from "../../components/Tailwind/ProtectedComponents.js";
import TicketInfoRelatedTickets from "../../components/TicketInfo/TicketInfoSidebar/TicketInfoRelatedTickets.js";
import { withBreadcrumb } from "../../context/Breadcrumbs/withBreadcrumbs.js";
import useDeleteModal, { RESOURCE } from "../../forms/useDeleteModal.js";
import { useFeatureToggle } from "../../hooks/useFeatureToggle.js";
import useI18n from "../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { ApplicationActionsTypes } from "../../store/artifacts/Application/types.js";
import { ProjectActionsTypes } from "../../store/artifacts/Project/types.js";
import { getFeatureFlagsTabs } from "../../utils/index.js";
import React, { useEffect, useState } from "../../../../_snowpack/pkg/react.js";
import { Layout } from "../../components/Tailwind/Layout.js";
import { Page } from "../../components/Tailwind/Page.js";
import { PaymentDataList } from "../Payments/index.js";
import RenrRollWidget from "../Reports/widgets/RentRollWidget.js";
import { ProjectBuildingList } from "./components/ProjectBuildingList.js";
import { ProjectDepositList } from "./components/ProjectDepositList.js";
import { ProjectsInformation } from "./components/ProjectsInformation.js";
import { ClientWidget } from "./components/UserWidget.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";

const RenderPageContent = ({
  selectedTab
}) => {
  switch (selectedTab) {
    case 0:
      return __cssprop(ProjectView, null);

    case 1:
      return __cssprop(PaymentDataList, null);

    default:
      return __cssprop(ProjectView, null);
  }
};

export const ProjectsInfo = withBreadcrumb(({
  history,
  match,
  setCrumb,
  crumbs
}) => {
  const {
    t
  } = useI18n();
  const {
    id
  } = match.params;
  const role = useSelector(Selectors.session.getUserRole);
  const projectId = useSelector(Selectors.project.getId);
  const loading = useSelector(Selectors.status.isLoading("project"));
  const name = useSelector(Selectors.project.getName);
  const [selectedTab, setSelectedTab] = useState(0);
  const {
    dispatch
  } = useAction();
  const paymentEnable = useFeatureToggle("payment");
  useEffect(() => {
    if (!id) {
      dispatch({
        type: ProjectActionsTypes.PROJECT_INITIALIZE,
        payload: {
          kind: "NoContext"
        }
      });
    } else {
      dispatch({
        type: ProjectActionsTypes.PROJECT_INITIALIZE,
        payload: {
          kind: "WithProjectId",
          projectId: id
        }
      });
    }
  }, []);
  useEffect(() => {
    if (!loading && name) {
      setCrumb({
        title: name,
        path: `/clients/${projectId}`,
        level: 1
      });
    }
  }, [loading, name, projectId]);
  const [DeleteModal, _openDeleteModal, deleteModalAction] = useDeleteModal(id, RESOURCE.Project);

  if (loading) {
    return __cssprop("div", {
      css: {
        "position": "absolute",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
        "zIndex": "10",
        "height": "100%",
        "width": "100%",
        "opacity": "0.6"
      }
    }, __cssprop(Loader, null));
  }

  if (!projectId) {
    return __cssprop(EmptyState, {
      image: "/img/notFound.svg",
      heading: t("project-info-no-project-found")
    });
  }

  const tabs = ProtectedTab(role, getFeatureFlagsTabs([{
    content: t("project"),
    id: "unit",
    permission: {
      kind: "Project",
      permission: "view:info"
    }
  }], [], paymentEnable));

  const onDoneSubmit = d => {
    dispatch({
      type: ProjectActionsTypes.PROJECT_SUBMIT,
      payload: { ...d
      }
    });
  };

  return __cssprop(React.Fragment, null, __cssprop(UserPanel, {
    formTitle: t("user"),
    description: "",
    sectionTitle: "",
    onDoneSubmit: () => {
      /* empty */
    }
  }), __cssprop(ProjectPanel, {
    formTitle: t("project"),
    showOrgPicker: false,
    description: "",
    sectionTitle: "",
    onDoneSubmit: onDoneSubmit
  }), __cssprop(Page, {
    title: name,
    breadcrumbs: crumbs,
    primaryAction: __cssprop(ProtectedButton, {
      permission: {
        kind: "Project",
        permission: "mutate:update"
      },
      primary: true,
      onAction: () => dispatch({
        type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
        payload: "project"
      })
    }, t("project-info-modify-project")),
    tabs: {
      tabs,
      onSelect: setSelectedTab,
      selected: selectedTab
    }
  }, __cssprop(RenderPageContent, {
    selectedTab: selectedTab
  }), __cssprop(DeleteModal, null)));
});

const ProjectView = () => {
  const {
    t
  } = useI18n();
  const role = useSelector(Selectors.session.getUserRole);
  const notes = useSelector(Selectors.project.getNotes);
  const projectId = useSelector(Selectors.project.getId);
  const tickets = useSelector(Selectors.project.getTickets);
  const {
    dispatch
  } = useAction();
  const noteLoading = useSelector(Selectors.status.isSubmiting("note"));
  return __cssprop(Layout, null, __cssprop(Layout, {
    twoThird: true
  }, __cssprop(ProjectBuildingList, null), __cssprop(ProjectDepositList, null)), __cssprop(Layout, {
    oneThird: true
  }, __cssprop(ProjectsInformation, null), __cssprop(ClientWidget, null), __cssprop(AccountCard, {
    accountInfo: {
      accountNumber: "***837",
      tenantName: "Maxime D",
      bankName: "Desjardins"
    }
  }), __cssprop(RenrRollWidget, {
    projectId: projectId
  }), tickets.length > 0 && __cssprop(Card, {
    title: t("ticket-related-title")
  }, __cssprop(Card.Section, null, __cssprop(TicketInfoRelatedTickets, {
    data: tickets
  }))), __cssprop(Notes, {
    notes: notes,
    loading: noteLoading,
    permission: {
      kind: "Project",
      permission: "view:notes"
    },
    addNoteEvent: pNotes => {
      dispatch({
        payload: {
          resourceId: projectId,
          resourceType: "project",
          actionType: ProjectActionsTypes.PROJECT_ADD_NOTES,
          content: pNotes.note,
          fromPage: true
        },
        type: ProjectActionsTypes.PROJECT_ADD_NOTES_FORMLESS
      });
    },
    hideNewNote: role === "Client" || role === "User",
    removeNoteEvent: pNotes => {
      dispatch({
        payload: {
          noteId: pNotes.id,
          actionType: ProjectActionsTypes.PROJECT_REMOVE_NOTES,
          fromPage: true
        },
        type: ProjectActionsTypes.PROJECT_REMOVE_NOTES_FORMLESS
      });
    }
  })));
};