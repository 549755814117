import { css as _css } from "../../../../_snowpack/pkg/@emotion/react.js";
import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import { Global as _globalImport } from "../../../../_snowpack/pkg/@emotion/react.js";

const _GlobalStyles = () => __cssprop(_globalImport, {
  styles: _css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  :root {
    -moz-tab-size: 4;
    tab-size: 4;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
  }

  body {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  }

  hr {
    height: 0;
    color: inherit;
  }

  abbr[title] {
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  table {
    text-indent: 0;
    border-color: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  ::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  :-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  legend {
    padding: 0;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }


  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  button {
    background-color: transparent;
    background-image: none;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.5;
  }

  body {
    font-family: inherit;
    line-height: inherit;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }

  hr {
    border-top-width: 1px;
  }

  img {
    border-style: solid;
  }

  textarea {
    resize: vertical;
  }

  input::placeholder,
  textarea::placeholder {
    color: #9ca3af;
  }

  button,
  [role="button"] {
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }


      @keyframes spin {
          to { 
            transform: rotate(360deg);
          }
        }
      @keyframes ping {
          75%, 100% { 
            transform: scale(2); opacity: 0;
          }
        }
      @keyframes pulse {
          50% { 
            opacity: .5;
          }
        }
      @keyframes bounce {
          0%, 100% { 
            transform: translateY(-25%); animationTimingFunction: cubic-bezier(0.8,0,1,1);
          }
        
          50% { 
            transform: none; animationTimingFunction: cubic-bezier(0,0,0.2,1);
          }
        }
* {
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
  }
* {
  --tw-shadow: 0 0 #0000; }

select {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
background-position: right 0.5rem center;
background-repeat: no-repeat;
background-size: 1.5em 1.5em;
padding-right: 2.5rem;
color-adjust: exact;
        }
[multiple] {
background-image: initial;
background-position: initial;
background-repeat: unset;
background-size: initial;
padding-right: 0.75rem;
color-adjust: unset;
        }
[type='file'] {
background: unset;
border-color: inherit;
border-width: 0;
border-radius: 0;
padding: 0;
font-size: unset;
line-height: inherit;
        }
[type='radio'] {
border-radius: 100%;
        }
[type='checkbox'] {
border-radius: 0px;
        }
[type='file']:focus {
outline: 1px auto -webkit-focus-ring-color;
        }
[type='radio']:checked {
background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
        }
[type='checkbox']:checked {
background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
        }
::-webkit-date-and-time-value {
min-height: 1.5em;
        }
[type='checkbox']:indeterminate {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
border-color: transparent;
background-color: currentColor;
background-size: 100% 100%;
background-position: center;
background-repeat: no-repeat;
        }
::-webkit-datetime-edit-fields-wrapper {
padding: 0;
        }
input::placeholder,textarea::placeholder {
color: #6b7280;
opacity: 1;
        }
[type='checkbox'],[type='radio'] {
appearance: none;
padding: 0;
color-adjust: exact;
display: inline-block;
vertical-align: middle;
background-origin: border-box;
user-select: none;
flex-shrink: 0;
height: 1rem;
width: 1rem;
color: #2563eb;
background-color: #fff;
border-color: #6b7280;
border-width: 1px;
        }
[type='checkbox']:focus,[type='radio']:focus {
outline: 2px solid transparent;
outline-offset: 2px;
--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
--tw-ring-offset-width: 2px;
--tw-ring-offset-color: #fff;
--tw-ring-color: #2563eb;
--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
        }
[type='checkbox']:checked,[type='radio']:checked {
border-color: transparent;
background-color: currentColor;
background-size: 100% 100%;
background-position: center;
background-repeat: no-repeat;
        }
[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus {
border-color: transparent;
background-color: currentColor;
        }
[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus {
border-color: transparent;
background-color: currentColor;
        }
[type='text']:focus,[type='email']:focus,[type='url']:focus,[type='password']:focus,[type='number']:focus,[type='date']:focus,[type='datetime-local']:focus,[type='month']:focus,[type='search']:focus,[type='tel']:focus,[type='time']:focus,[type='week']:focus,[multiple]:focus,textarea:focus,select:focus {
outline: 2px solid transparent;
outline-offset: 2px;
--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
--tw-ring-offset-width: 0px;
--tw-ring-offset-color: #fff;
--tw-ring-color: #2563eb;
--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
border-color: #2563eb;
        }
[type='text'],[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select {
appearance: none;
background-color: #fff;
border-color: #6b7280;
border-width: 1px;
border-radius: 0px;
padding-top: 0.5rem;
padding-right: 0.75rem;
padding-bottom: 0.5rem;
padding-left: 0.75rem;
font-size: 1rem;
line-height: 1.5rem;
        }`
});

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from "../../../../_snowpack/pkg/react.js";
import { toast } from "../../../../_snowpack/pkg/react-toastify.js";
import useI18n from "../../hooks/useI18n.js";
import { FaGoogle, FaMicrosoft } from "../../../../_snowpack/pkg/react-icons/fa.js";
import { Link } from "../../components/Tailwind/Link.js";
import { Card } from "../../components/Tailwind/Card.js";
import { Selectors, useAction, useSelector } from "../../hooks/useRedux.js";
import { SessionActionsTypes } from "../../store/artifacts/Session/types.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
const FEDERATED = {
  GOOGLE: {
    type: "google",
    title: "Google"
  },
  MICROSOFT: {
    type: "microsoft",
    title: "Microsoft"
  }
};

const StyledFrame = _styled.div(() => [_css`
    background-image: url(/img/ottawa.jpeg);
  `, {
  "minHeight": "100vh",
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "center",
  "paddingTop": "4.8rem",
  "paddingBottom": "4.8rem",
  "alignItems": "center",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(107, 114, 128, var(--tw-bg-opacity))",
  "paddingLeft": "1.6rem",
  "paddingRight": "1.6rem",
  "backgroundRepeat": "no-repeat",
  "backgroundSize": "cover",
  "position": "relative",
  "@media (min-width: 640px)": {
    "paddingLeft": "2.4rem",
    "paddingRight": "2.4rem"
  },
  "@media (min-width: 1024px)": {
    "paddingLeft": "3.2rem",
    "paddingRight": "3.2rem"
  }
}]);

export const Login = () => {
  const {
    t
  } = useI18n();
  const {
    dispatch
  } = useAction();
  const isConnecting = useSelector(Selectors.status.isSubmiting("user"));
  const rememberMeEmail = useSelector(Selectors.app.getRememberMeEmail);
  const rememberMe = useSelector(Selectors.app.getRememberMe);
  const [isForgot, setIsForgot] = useState(false);
  const [isRememberMeEnabled, setIsRememberMeEnabled] = useState(rememberMe);
  const [username, setUsername] = useState(rememberMeEmail);
  const [password, setPassword] = useState("");

  const handleUsername = e => setUsername(e.target.value);

  const handleChangePassword = e => setPassword(e.target.value);

  const handleForgotPassword = () => setIsForgot(c => !c);

  const onSubmitClick = useCallback(async () => {
    if (isForgot) {
      dispatch({
        type: SessionActionsTypes.ON_FORGOT_PASSWORD,
        payload: username
      });
      return;
    }

    dispatch({
      type: SessionActionsTypes.ON_USER_LOGIN,
      payload: {
        login: "password",
        username,
        password,
        rememberMe: isRememberMeEnabled
      }
    });
  }, [username, password, isForgot, isRememberMeEnabled, dispatch]);
  return __cssprop(React.Fragment, null, __cssprop(_GlobalStyles, null), __cssprop(StyledFrame, null, __cssprop("div", {
    css: {
      "position": "absolute",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(0, 0, 0, var(--tw-bg-opacity))",
      "opacity": "0.2",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "zIndex": "0"
    }
  }), __cssprop("div", {
    css: {
      "zIndex": "10"
    }
  }, __cssprop(Card, null, __cssprop(Card.Section, null, __cssprop(Heading, {
    t: t
  }), isForgot ? __cssprop(ForgotPWDView, {
    t: t,
    handleUsername: handleUsername,
    onSubmitClick: onSubmitClick
  }) : __cssprop(LoginView, {
    t: t,
    handleChangePassword: handleChangePassword,
    handleUsername: handleUsername,
    handleForgotPassword: handleForgotPassword,
    onSubmitClick: onSubmitClick,
    isRememberMeEnabled: isRememberMeEnabled,
    setIsRememberMeEnabled: setIsRememberMeEnabled,
    isConnecting: isConnecting,
    username: username
  }))))));
};

const ForgotPWDView = ({
  t,
  handleUsername,
  onSubmitClick
}) => {
  return __cssprop("div", {
    css: {
      "marginTop": "3.2rem",
      "@media (min-width: 640px)": {
        "marginLeft": "auto",
        "marginRight": "auto",
        "width": "100%",
        "maxWidth": "44.8rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingTop": "3.2rem",
      "paddingBottom": "3.2rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "@media (min-width: 640px)": {
        "borderRadius": "0.8rem",
        "paddingLeft": "4rem",
        "paddingRight": "4rem"
      }
    }
  }, __cssprop("form", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", null, __cssprop("label", {
    htmlFor: "username",
    css: {
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, t("login-title-reset")), __cssprop("div", {
    css: {
      "marginTop": "0.4rem"
    }
  }, __cssprop("input", {
    id: "username",
    name: "username",
    type: "email",
    autoComplete: "email",
    onChange: handleUsername,
    required: true,
    css: {
      "appearance": "none",
      "display": "block",
      "width": "100%",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "::placeholder": {
        "--tw-placeholder-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-placeholder-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }
  }))), __cssprop("div", null, __cssprop("button", {
    onClick: onSubmitClick,
    type: "button",
    css: {
      "width": "100%",
      "display": "flex",
      "justifyContent": "center",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(67, 56, 202, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, t("login-next"))))));
};

const LoginView = ({
  t,
  handleUsername,
  handleChangePassword,
  handleForgotPassword,
  onSubmitClick,
  isConnecting,
  isRememberMeEnabled,
  setIsRememberMeEnabled,
  username
}) => {
  return __cssprop("div", {
    css: {
      "marginTop": "3.2rem",
      "paddingLeft": "2.4rem",
      "paddingRight": "2.4rem",
      "@media (min-width: 640px)": {
        "marginLeft": "auto",
        "marginRight": "auto",
        "width": "100%",
        "maxWidth": "44.8rem"
      }
    }
  }, __cssprop("form", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(2.4rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(2.4rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", null, __cssprop("label", {
    htmlFor: "username",
    css: {
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, t("login-email")), __cssprop("div", {
    css: {
      "marginTop": "0.4rem"
    }
  }, __cssprop("input", {
    id: "username",
    name: "username",
    type: "email",
    autoComplete: "email",
    onChange: handleUsername,
    value: username,
    required: true,
    css: {
      "appearance": "none",
      "display": "block",
      "width": "100%",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "::placeholder": {
        "--tw-placeholder-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-placeholder-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }
  }))), __cssprop("div", null, __cssprop("label", {
    htmlFor: "password",
    css: {
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, t("login-password")), __cssprop("div", {
    css: {
      "marginTop": "0.4rem"
    }
  }, __cssprop("input", {
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    required: true,
    onChange: handleChangePassword,
    css: {
      "appearance": "none",
      "display": "block",
      "width": "100%",
      "paddingLeft": "1.2rem",
      "paddingRight": "1.2rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "::placeholder": {
        "--tw-placeholder-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-placeholder-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(99, 102, 241, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "1.4rem",
        "lineHeight": "2rem"
      }
    }
  }))), __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "width": "50%"
    }
  }, __cssprop("input", {
    id: "rememberMe",
    name: "rememberMe",
    type: "checkbox",
    onChange: () => setIsRememberMeEnabled(!isRememberMeEnabled),
    checked: isRememberMeEnabled,
    css: {
      "height": "1.6rem",
      "width": "1.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(79, 70, 229, var(--tw-text-opacity))",
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      },
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.4rem"
    }
  }), __cssprop("label", {
    htmlFor: "rememberMe",
    css: {
      "marginLeft": "0.8rem",
      "display": "block",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("login-remember-me"))), __cssprop("div", {
    css: {
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "textAlign": "right",
      "width": "50%"
    }
  }, __cssprop(Link, {
    text: t("login-forgot-password"),
    action: {
      kind: "ClickAction",
      event: handleForgotPassword
    }
  }))), __cssprop("div", null, __cssprop("button", {
    disabled: isConnecting,
    onClick: onSubmitClick,
    type: "button",
    css: {
      "width": "100%",
      "display": "flex",
      "justifyContent": "center",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(79, 70, 229, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(67, 56, 202, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, isConnecting ? __cssprop("svg", {
    css: {
      "animation": "spin 1s linear infinite",
      "height": "2rem",
      "width": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    },
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24"
  }, __cssprop("circle", {
    css: {
      "opacity": "0.25"
    },
    cx: "12",
    cy: "12",
    r: "10",
    stroke: "currentColor",
    strokeWidth: "4"
  }), __cssprop("path", {
    css: {
      "opacity": "0.75"
    },
    fill: "currentColor",
    d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
  })) : t("login-connection")))), __cssprop("div", {
    css: {
      "marginTop": "2.4rem"
    }
  }, __cssprop("div", {
    css: {
      "position": "relative"
    }
  }, __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("div", {
    css: {
      "width": "100%",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))"
    }
  })), __cssprop("div", {
    css: {
      "position": "relative",
      "display": "flex",
      "justifyContent": "center",
      "fontSize": "1.4rem",
      "lineHeight": "2rem"
    }
  }, __cssprop("span", {
    css: {
      "paddingLeft": "0.8rem",
      "paddingRight": "0.8rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, t("login-continue-with")))), __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "display": "grid",
      "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
      "gap": "1.2rem"
    }
  }, __cssprop(FederatedButton, {
    federated: FEDERATED.GOOGLE,
    icon: __cssprop(FaGoogle, {
      size: "1.4em"
    }),
    disabled: isConnecting
  }), __cssprop(FederatedButton, {
    federated: FEDERATED.MICROSOFT,
    icon: __cssprop(FaMicrosoft, {
      size: "1.4em"
    }),
    disabled: isConnecting
  }))), __cssprop("div", {
    css: {
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "2.4rem",
      "paddingBottom": "2.4rem",
      "textAlign": "center",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      }
    }
  }, t("legal-disclosure-1"), " ", __cssprop(Link, {
    text: t("term-of-service"),
    action: {
      kind: "UrlAction",
      url: t("term-of-service-url")
    }
  }), " ", t("legal-disclosure-2"), " ", __cssprop(Link, {
    text: t("privacy-policy"),
    action: {
      kind: "UrlAction",
      url: t("privacy-policy-url")
    }
  })));
};

const Heading = ({
  t
}) => {
  return __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "marginLeft": "auto",
        "marginRight": "auto",
        "width": "100%",
        "maxWidth": "44.8rem"
      }
    }
  }, __cssprop("img", {
    css: {
      "marginLeft": "auto",
      "marginRight": "auto",
      "height": "11.2rem",
      "width": "auto"
    },
    src: "/img/icon.svg",
    alt: "Workflow"
  }), __cssprop("h2", {
    css: {
      "marginTop": "0.4rem",
      "textAlign": "center",
      "fontSize": "3rem",
      "lineHeight": "3.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, t("login-welcome"), " ", __cssprop("span", {
    css: {
      "fontWeight": "800"
    }
  }, "SimpleLoyer")), __cssprop("p", {
    css: {
      "marginTop": "0.8rem",
      "textAlign": "center",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
    }
  }, t("or"), " ", __cssprop(Link, {
    text: t("login-sign-up-account"),
    action: {
      kind: "ClickAction",
      event: () => {
        toast.warn("Bientôt disponible / Available soon");
      }
    }
  })));
};

const FederatedButton = ({
  federated,
  disabled,
  icon
}) => {
  const {
    dispatch
  } = useAction();
  const onFederatedClick = useCallback(async () => {
    dispatch({
      type: SessionActionsTypes.ON_USER_LOGIN,
      payload: {
        login: federated.type
      }
    });
  }, [dispatch]);
  return __cssprop("button", {
    disabled: disabled,
    type: "button",
    onClick: onFederatedClick,
    css: {
      "width": "100%",
      "display": "inline-flex",
      "justifyContent": "center",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.6rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, federated.title), icon);
};

export default Login;