import { Link } from "../../../components/Tailwind/Link.js";
import { PaginatedDataTable } from "../../../components/Tailwind/PaginatedDataTable.js";
import { SmallTenant } from "../../../components/Tailwind/SmallTenant.js";
import { withBreadcrumb } from "../../../context/Breadcrumbs/withBreadcrumbs.js";
import { GetLeasesDocument } from "../../../graphql/documents.js";
import useI18n from "../../../hooks/useI18n.js";
import { useAction } from "../../../hooks/useRedux.js";
import { BuildingActionsTypes } from "../../../store/artifacts/Building/types.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import * as React from "../../../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../../../_snowpack/pkg/react-router.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const renderAddress = (dispatch, history, item) => {
  let action;

  if (!item?.subAddress && item.buildingId) {
    action = {
      kind: "ClickAction",
      event: () => {
        dispatch({
          type: BuildingActionsTypes.BUILDING_INITIALIZE,
          payload: {
            buildingId: item.buildingId,
            kind: "WithBuildingId"
          }
        });
        history.push(`/buildings/${item.buildingId}`);
      }
    };
    return __cssprop(Link, {
      action: action,
      text: item?.buildingAddress
    });
  }

  action = {
    kind: "ClickAction",
    event: () => {
      history.push(`/unit/${item.id}`);
    }
  };
  return __cssprop(Link, {
    action: action,
    text: `${item?.subAddress}-${item?.buildingAddress}`
  });
};

const getFormatedEndDate = (t, leaseEndDate) => {
  const endDate = DateTime.fromISO(leaseEndDate);
  const differenceInDays = Math.ceil(endDate.diff(DateTime.now(), "days").toObject().days);

  if (differenceInDays === 0) {
    return t("lease-end-today");
  }

  if (differenceInDays < 0) {
    return t("lease-ended");
  }

  return t("days-remaining", {
    days: differenceInDays
  });
};

const getTenantInfo = tenants => {
  const tenant = tenants.map(ten => __cssprop(SmallTenant, {
    tenant: ten.tenant
  }));
  return __cssprop("div", {
    css: {
      "display": "block"
    }
  }, tenant);
};

export const LeasesList = withBreadcrumb(() => {
  const {
    t
  } = useI18n();
  const history = useHistory();
  const [status, setSatus] = React.useState("active");

  const onTabChange = value => {
    setSatus(value);
  };

  const {
    dispatch
  } = useAction();
  const LeaseTabs = [{
    value: "active",
    label: t("tenant-tab-active")
  }, {
    value: "toRenew",
    label: t("tab-to-renew")
  }, {
    value: "doNotRenew",
    label: t("tab-cancelled")
  }, {
    value: "all",
    label: t("tab-all-leases")
  }];

  const renderBody = item => {
    return {
      buildingAddress: renderAddress(dispatch, history, item.unit),
      project: __cssprop(Link, {
        action: {
          kind: "InternalLink",
          to: `/clients/${item.project?.id}`
        },
        text: item.project?.name
      }),
      tenantInfo: getTenantInfo(item?.tenants?.items),
      endDate: getFormatedEndDate(t, item.endDate)
    };
  };

  const columns = [{
    Header: t("building-form-address"),
    accessor: "buildingAddress",
    disableSortBy: true
  }, {
    Header: t("project"),
    accessor: "project",
    disableSortBy: true
  }, {
    Header: t("name"),
    accessor: "tenantInfo",
    disableSortBy: true
  }, {
    Header: t("days-left"),
    accessor: "endDate"
  }];
  return __cssprop(PaginatedDataTable, {
    title: t("leases"),
    query: GetLeasesDocument,
    headers: columns,
    renderItem: renderBody,
    resourceName: {
      plural: t("leases"),
      singular: t("leases")
    },
    extraVariables: {
      status
    },
    tabs: LeaseTabs,
    onTabChange: onTabChange
  });
});
export default LeasesList;