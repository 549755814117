/* eslint-disable import/no-cycle */
import { AddNoteDocument, RemoveNoteDocument } from "../../../graphql/documents.js";
import { BuildingActionsTypes } from "../Building/types.js";
import { LeaseActionsTypes } from "../Lease/types.js";
import { ProjectActionsTypes } from "../Project/types.js";
import { TenantActionsTypes } from "../Tenant/types.js";
import { UnitActionsTypes } from "../Unit/types.js";
import { mapNotes } from "../../utils/Notes.js";
import { all, getContext, put, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import StatusActions from "../Status/actions.js";
import { TicketActionTypes } from "../Tickets/types.js";

const getAddPutType = resourceType => {
  switch (resourceType) {
    case "building":
      return BuildingActionsTypes.BUILDING_ADD_NOTES_FORMLESS;

    case "lease":
      return LeaseActionsTypes.LEASE_ADD_NOTES_FORMLESS;

    case "ticket":
      return TicketActionTypes.TICKET_ADD_NOTES_FORMLESS;

    default:
      return null;
    // this should never occur
  }
};

const getRemovePutType = resourceType => {
  switch (resourceType) {
    case "building":
      return BuildingActionsTypes.BUILDING_REMOVE_NOTES_FORMLESS;

    case "lease":
      return LeaseActionsTypes.LEASE_REMOVE_NOTES_FORMLESS;

    case "ticket":
      return TicketActionTypes.TICKET_REMOVE_NOTES_FORMLESS;

    default:
      return null;
    // this should never occur
  }
};

export function* addServerNote($data) {
  const {
    payload
  } = $data;
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("note"));
  const query = client.query({
    query: AddNoteDocument,
    fetchPolicy: "no-cache",
    variables: {
      resourceId: payload.resourceId,
      resourceType: payload.resourceType,
      note: {
        content: payload.content
      }
    }
  });
  const {
    data,
    errors
  } = yield query;

  if (errors?.length > 0) {
    yield put(StatusActions.setResourceToError("note", "notes-added-error"));
    return;
  }

  yield put(StatusActions.setResourceToIdle("note"));

  if (payload.fromPage) {
    yield put({
      type: payload.actionType,
      payload: mapNotes([data.addNote])
    });
  }
}
export function* removeServerNote($data) {
  const {
    payload
  } = $data;
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("note"));
  const query = client.query({
    query: RemoveNoteDocument,
    fetchPolicy: "no-cache",
    variables: {
      noteId: payload.noteId
    }
  });
  const {
    data,
    errors
  } = yield query;

  if (errors?.length > 0) {
    yield put(StatusActions.setResourceToError("note", "notes-delete-error"));
    return;
  }

  yield put(StatusActions.setResourceToIdle("note"));

  if (payload.fromPage) {
    yield put({
      type: payload.actionType,
      payload: data.removeNote.id
    });
  }
}
export function* addServerNotes($data) {
  const {
    notes,
    resourceId,
    resourceType
  } = $data;
  if (notes.length === 0) return;

  for (let index = 0; index < notes.length; index += 1) {
    const note = notes[index];

    if (note.kind === "local") {
      yield put({
        type: getAddPutType(resourceType),
        payload: {
          resourceId,
          resourceType,
          content: note.content
        }
      });
    }
  }
}
export function* removeServerNotes($data) {
  const {
    notes,
    resourceType
  } = $data;
  if (notes.length === 0) return;

  for (let index = 0; index < notes.length; index += 1) {
    const note = notes[index];

    if (note.kind === "removed") {
      yield put({
        type: getRemovePutType(resourceType),
        payload: {
          resourceType,
          noteId: note.id
        }
      });
    }
  }
}
export default {
  *executers() {
    yield all([yield takeLatest(TenantActionsTypes.TENANT_ADD_NOTES_FORMLESS, addServerNote), yield takeLatest(TenantActionsTypes.TENANT_REMOVE_NOTES_FORMLESS, removeServerNote), yield takeLatest(LeaseActionsTypes.LEASE_ADD_NOTES_FORMLESS, addServerNote), yield takeLatest(LeaseActionsTypes.LEASE_REMOVE_NOTES_FORMLESS, removeServerNote), yield takeLatest(UnitActionsTypes.UNIT_ADD_NOTES_FORMLESS, addServerNote), yield takeLatest(UnitActionsTypes.UNIT_REMOVE_NOTES_FORMLESS, removeServerNote), yield takeLatest(BuildingActionsTypes.BUILDING_ADD_NOTES_FORMLESS, addServerNote), yield takeLatest(BuildingActionsTypes.BUILDING_REMOVE_NOTES_FORMLESS, removeServerNote), yield takeLatest(ProjectActionsTypes.PROJECT_ADD_NOTES_FORMLESS, addServerNote), yield takeLatest(ProjectActionsTypes.PROJECT_REMOVE_NOTES_FORMLESS, removeServerNote), yield takeLatest(TicketActionTypes.TICKET_ADD_NOTES_FORMLESS, addServerNote), yield takeLatest(TicketActionTypes.TICKET_REMOVE_NOTES_FORMLESS, removeServerNote)]);
  }

};