import _styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import * as React from "../../../../_snowpack/pkg/react.js";
import { BreadCrumbs } from "./BreadCrumbs.js";
import { DropDownActions } from "./DropDownActions.js";
import { Tabs } from "./Tabs.js";
import { jsx as __cssprop } from "../../../../_snowpack/pkg/@emotion/react.js";
const profile = {
  name: "Ricardo Cooper",
  email: "ricardo.cooper@example.com",
  avatar: "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  backgroundImage: "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  fields: [["Phone", "(555) 123-4567"], ["Email", "ricardocooper@example.com"], ["Title", "Senior Front-End Developer"], ["Team", "Product Development"], ["Location", "San Francisco"], ["Sits", "Oasis, 4th floor"], ["Salary", "$145,000"], ["Birthday", "June 8, 1990"]]
};

const WithBanner = ({
  titleMetadata,
  title,
  thumbnail,
  banner
}) => {
  return __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingBottom": "2rem"
    }
  }, __cssprop("div", null, __cssprop("div", {
    css: {
      "height": "12.8rem",
      "width": "100%",
      "objectFit": "cover",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "overflow": "hidden",
      "@media (min-width: 1024px)": {
        "height": "19.2rem"
      }
    }
  }, banner)), __cssprop("div", {
    css: {
      "maxWidth": "102.4rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "2.4rem",
        "paddingRight": "2.4rem"
      },
      "@media (min-width: 1024px)": {
        "paddingLeft": "3.2rem",
        "paddingRight": "3.2rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "marginTop": "-4.8rem",
      "@media (min-width: 640px)": {
        "marginTop": "-6.4rem",
        "display": "flex",
        "alignItems": "flex-end",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(2rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(2rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "height": "12.8rem"
      }
    }
  }, thumbnail && __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("img", {
    css: {
      "height": "9.6rem",
      "width": "9.6rem",
      "borderRadius": "9999px",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgba(255, 255, 255, var(--tw-ring-opacity))",
      "@media (min-width: 640px)": {
        "height": "12.8rem",
        "width": "12.8rem"
      }
    },
    src: thumbnail,
    alt: ""
  })), __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "@media (min-width: 640px)": {
        "flex": "1 1 0%",
        "minWidth": "0px",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "flex-end",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(2.4rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(2.4rem * calc(1 - var(--tw-space-x-reverse)))"
        },
        "paddingBottom": "0.4rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "minWidth": "0px",
      "flex": "1 1 0%",
      "@media (min-width: 640px)": {
        "display": "none"
      },
      "@media (min-width: 768px)": {
        "display": "block"
      }
    }
  }, __cssprop("h1", {
    css: {
      "fontSize": "2.4rem",
      "lineHeight": "3.2rem",
      "fontWeight": "700",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, title), titleMetadata && __cssprop("div", {
    css: {
      "paddingTop": "0.4rem"
    }
  }, titleMetadata)), __cssprop("div", {
    css: {
      "marginTop": "2.4rem",
      "display": "flex",
      "flexDirection": "column",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.2rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.2rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "flexDirection": "row",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(0px * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(0px * var(--tw-space-y-reverse))",
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(1.6rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(1.6rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }
  }, __cssprop("button", {
    type: "button",
    css: {
      "display": "inline-flex",
      "justifyContent": "center",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "borderRadius": "0.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(236, 72, 153, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop("span", null, "Message")), __cssprop("button", {
    type: "button",
    css: {
      "display": "inline-flex",
      "justifyContent": "center",
      "paddingLeft": "1.6rem",
      "paddingRight": "1.6rem",
      "paddingTop": "0.8rem",
      "paddingBottom": "0.8rem",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "1.4rem",
      "lineHeight": "2rem",
      "fontWeight": "500",
      "borderRadius": "0.6rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(236, 72, 153, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop("span", null, "Call"))))), __cssprop("div", {
    css: {
      "display": "none",
      "marginTop": "2.4rem",
      "minWidth": "0px",
      "flex": "1 1 0%",
      "@media (min-width: 640px)": {
        "display": "block"
      },
      "@media (min-width: 768px)": {
        "display": "none"
      }
    }
  }, __cssprop("h1", {
    css: {
      "fontSize": "2.4rem",
      "lineHeight": "3.2rem",
      "fontWeight": "700",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, profile.name))));
};

const PageContainer = _styled.div(({
  enclosedHeight
}) => [{
  "position": "relative",
  "width": "100%",
  "marginLeft": "auto",
  "marginRight": "auto",
  "height": "100%",
  "@media (min-width: 640px)": {
    "paddingLeft": "1.6rem",
    "paddingRight": "1.6rem"
  },
  "@media (min-width: 768px)": {
    "paddingLeft": "2.4rem",
    "paddingRight": "2.4rem"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "0px",
    "paddingRight": "0px"
  }
}, enclosedHeight && {
  "height": "100%",
  "display": "flex",
  "flexDirection": "column"
}]);

const StyledContentContainer = _styled.div(({
  fullWidth,
  enclosedHeight,
  breadcrumbs
}) => [{
  "overflowY": "scroll"
}, enclosedHeight && {
  "display": "flex",
  "flexDirection": "column",
  "overflow": "hidden"
}, fullWidth ? {
  "padding": "0px",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-y-reverse": 0,
    "marginTop": "calc(0px * calc(1 - var(--tw-space-y-reverse)))",
    "marginBottom": "calc(0px * var(--tw-space-y-reverse))"
  }
} : {
  "padding": "4.8rem",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-y-reverse": 0,
    "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
    "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
  },
  "marginBottom": "2.4rem"
}, breadcrumbs ? `height: calc(100vh - 49px - 64px);` : `height: calc(100vh - 64px);`]);

export const Page = props => {
  const {
    title,
    breadcrumbs,
    children,
    tabs,
    primaryAction,
    banner,
    thumbnail,
    fullPageHeader,
    titleMetadata,
    secondaryActions,
    fullWidth,
    enclosedHeight
  } = props;

  if (banner) {
    return __cssprop(React.Fragment, null, __cssprop("div", {
      css: {
        "overflow": "hidden",
        "display": "flex",
        "width": "100%",
        "overflowX": "auto",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }, breadcrumbs && __cssprop(BreadCrumbs, {
      pages: breadcrumbs
    }), __cssprop("div", {
      css: {
        "flex": "1 1 0%",
        "display": "flex",
        "flexDirection": "column"
      }
    }, __cssprop("main", {
      css: {
        "flex": "1 1 0%",
        "overflowY": "hidden",
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px"
        },
        "width": "100%"
      }
    }, __cssprop("div", {
      css: {
        "position": "relative",
        "width": "100%",
        "marginLeft": "auto",
        "marginRight": "auto"
      }
    }, __cssprop("div", {
      css: {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-y-reverse": 0,
          "marginTop": "calc(2rem * calc(1 - var(--tw-space-y-reverse)))",
          "marginBottom": "calc(2rem * var(--tw-space-y-reverse))"
        }
      }
    }, __cssprop(WithBanner, props), __cssprop("div", {
      css: {
        "position": "relative",
        "width": "100%",
        "marginLeft": "auto",
        "marginRight": "auto",
        "paddingBottom": "1.6rem",
        "@media (min-width: 768px)": {
          "paddingLeft": "3.2rem",
          "paddingRight": "3.2rem"
        },
        "@media (min-width: 1280px)": {
          "paddingLeft": "0px",
          "paddingRight": "0px"
        }
      }
    }, __cssprop(StyledContentContainer, {
      fullWidth: fullWidth,
      enclosedHeight: enclosedHeight,
      breadcrumbs: breadcrumbs
    }, tabs && __cssprop(Tabs, {
      selected: tabs.selected,
      tabs: tabs.tabs,
      onSelect: tabs.onSelect
    }), children))))))));
  }

  if (fullPageHeader) {
    return __cssprop("div", {
      css: {
        "overflow": "hidden",
        "display": "flex",
        "width": "100%",
        "overflowX": "auto",
        "height": "100%",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }, __cssprop("div", {
      css: {
        "flex": "1 1 0%",
        "display": "flex",
        "flexDirection": "column"
      }
    }, __cssprop("main", {
      css: {
        "flex": "1 1 0%",
        "overflowY": "auto",
        ":focus": {
          "outline": "2px solid transparent",
          "outlineOffset": "2px"
        },
        "width": "100%"
      }
    }, __cssprop("div", {
      css: {
        "position": "relative",
        "paddingBottom": "12.8rem",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
      }
    }, __cssprop("div", {
      css: {
        "position": "absolute",
        "top": "0px",
        "right": "0px",
        "bottom": "0px",
        "left": "0px"
      }
    }, __cssprop("img", {
      css: {
        "width": "100%",
        "height": "100%",
        "objectFit": "cover"
      },
      src: fullPageHeader.backgroundImage,
      alt: ""
    }), __cssprop("div", {
      css: {
        "position": "absolute",
        "top": "0px",
        "right": "0px",
        "bottom": "0px",
        "left": "0px",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))",
        "mixBlendMode": "multiply"
      },
      "aria-hidden": "true"
    })), __cssprop("div", {
      css: {
        "position": "relative",
        "maxWidth": "128rem",
        "marginLeft": "auto",
        "marginRight": "auto",
        "paddingTop": "9.6rem",
        "paddingBottom": "9.6rem",
        "paddingLeft": "1.6rem",
        "paddingRight": "1.6rem",
        "@media (min-width: 640px)": {
          "paddingTop": "12.8rem",
          "paddingBottom": "12.8rem",
          "paddingLeft": "2.4rem",
          "paddingRight": "2.4rem"
        },
        "@media (min-width: 1024px)": {
          "paddingLeft": "3.2rem",
          "paddingRight": "3.2rem"
        }
      }
    }, __cssprop("h1", {
      css: {
        "fontSize": "3.6rem",
        "lineHeight": "4rem",
        "fontWeight": "800",
        "letterSpacing": "-0.025em",
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
        "@media (min-width: 768px)": {
          "fontSize": "4.8rem",
          "lineHeight": "1"
        },
        "@media (min-width: 1024px)": {
          "fontSize": "6rem",
          "lineHeight": "1"
        }
      }
    }, fullPageHeader.title), __cssprop("p", {
      css: {
        "marginTop": "2.4rem",
        "maxWidth": "76.8rem",
        "fontSize": "2rem",
        "lineHeight": "2.8rem",
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    }, fullPageHeader.description))), breadcrumbs && __cssprop(BreadCrumbs, {
      pages: breadcrumbs
    }), __cssprop(PageContainer, {
      enclosedHeight: enclosedHeight
    }, __cssprop(StyledContentContainer, {
      fullWidth: fullWidth,
      enclosedHeight: enclosedHeight,
      breadcrumbs: breadcrumbs
    }, __cssprop("div", {
      css: {
        "@media (min-width: 768px)": {
          "display": "flex",
          "alignItems": "center",
          "justifyContent": "space-between"
        }
      }
    }, __cssprop("div", {
      css: {
        "display": "flex",
        "alignItems": "center",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(2rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(2rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }, thumbnail && __cssprop("div", {
      css: {
        "flexShrink": "0"
      }
    }, __cssprop("div", {
      css: {
        "position": "relative"
      }
    }, __cssprop("img", {
      css: {
        "height": "6.4rem",
        "width": "6.4rem",
        "borderRadius": "9999px"
      },
      src: thumbnail,
      alt: ""
    }), __cssprop("span", {
      css: {
        "position": "absolute",
        "top": "0px",
        "right": "0px",
        "bottom": "0px",
        "left": "0px",
        "--tw-shadow": "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
        "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
        "borderRadius": "9999px"
      },
      "aria-hidden": "true"
    }))), __cssprop("div", {
      css: {
        "paddingTop": "0.6rem"
      }
    }, __cssprop("div", {
      css: {
        "flex": "1 1 0%",
        "minWidth": "0px"
      }
    }, __cssprop("h1", {
      css: {
        "fontSize": "3rem",
        "lineHeight": "3.6rem",
        "fontWeight": "800",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      }
    }, title), titleMetadata && __cssprop("div", {
      css: {
        "paddingTop": "0.4rem"
      }
    }, titleMetadata)))), __cssprop("div", {
      css: {
        "marginTop": "1.6rem",
        "display": "flex",
        "@media (min-width: 768px)": {
          "marginTop": "0px",
          "marginLeft": "1.6rem"
        }
      }
    }, secondaryActions && __cssprop(DropDownActions, {
      content: secondaryActions.content,
      actions: secondaryActions.actions
    }), primaryAction && __cssprop("div", {
      css: {
        "marginLeft": "1.2rem"
      }
    }, primaryAction))), tabs && __cssprop(Tabs, {
      selected: tabs.selected,
      tabs: tabs.tabs,
      onSelect: tabs.onSelect
    }), children)))));
  }

  return __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "overflow": "hidden",
      "display": "flex",
      "width": "100%",
      "overflowX": "auto",
      "height": "100%",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "display": "flex",
      "flexDirection": "column"
    }
  }, __cssprop("main", {
    css: {
      "flex": "1 1 0%",
      "overflowY": "hidden",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      },
      "width": "100%"
    }
  }, breadcrumbs && __cssprop(BreadCrumbs, {
    pages: breadcrumbs
  }), __cssprop(PageContainer, {
    enclosedHeight: enclosedHeight
  }, __cssprop(StyledContentContainer, {
    enclosedHeight: enclosedHeight,
    fullWidth: fullWidth,
    breadcrumbs: breadcrumbs
  }, __cssprop("div", {
    css: {
      "@media (min-width: 768px)": {
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(2rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, thumbnail && __cssprop("div", {
    css: {
      "flexShrink": "0"
    }
  }, __cssprop("div", {
    css: {
      "position": "relative"
    }
  }, __cssprop("img", {
    css: {
      "height": "6.4rem",
      "width": "6.4rem",
      "borderRadius": "9999px"
    },
    src: thumbnail,
    alt: ""
  }), __cssprop("span", {
    css: {
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "--tw-shadow": "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "borderRadius": "9999px"
    },
    "aria-hidden": "true"
  }))), __cssprop("div", {
    css: {
      "paddingTop": "0.6rem"
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "minWidth": "0px"
    }
  }, __cssprop("h1", {
    css: {
      "fontSize": "3rem",
      "lineHeight": "3.6rem",
      "fontWeight": "800",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, title), titleMetadata && __cssprop("div", {
    css: {
      "paddingTop": "0.4rem"
    }
  }, titleMetadata)))), __cssprop("div", {
    css: {
      "marginTop": "1.6rem",
      "display": "flex",
      "@media (min-width: 768px)": {
        "marginTop": "0px",
        "marginLeft": "1.6rem"
      }
    }
  }, secondaryActions && secondaryActions.actions[0].length > 0 && __cssprop(DropDownActions, {
    content: secondaryActions.content,
    actions: secondaryActions.actions
  }), primaryAction && __cssprop("div", {
    css: {
      "marginLeft": "1.2rem",
      "display": "flex"
    }
  }, primaryAction))), tabs && __cssprop(Tabs, {
    selected: tabs.selected,
    tabs: tabs.tabs,
    onSelect: tabs.onSelect
  }), children))))));
};