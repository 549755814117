import _styled from "../../../_snowpack/pkg/@emotion/styled.js";
export const ApplicantScore = _styled.dd(({
  score
}) => [score >= 0 && score < 30 ? {
  "--tw-text-opacity": "1",
  "color": "rgba(239, 68, 68, var(--tw-text-opacity))"
} : {}, score >= 30 && score < 50 ? {
  "--tw-text-opacity": "1",
  "color": "rgba(255, 165, 0, var(--tw-text-opacity))"
} : {}, score >= 50 && score < 70 ? {
  "--tw-text-opacity": "1",
  "color": "rgba(245, 158, 11, var(--tw-text-opacity))"
} : {}, score >= 70 && score <= 100 ? {
  "--tw-text-opacity": "1",
  "color": "rgba(16, 185, 129, var(--tw-text-opacity))"
} : {}]);