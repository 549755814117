import { FeatureType } from "../app/graphql/documents.js";
const generic = {
  "clear-all": "Tout effacer",
  "server-error": "Le serveur a répondu avec le message d'erreur suivant:",
  "data-not-found": "Nous n'avons pas pu trouver de données",
  "change-filters-settings": "Essayez de changer vos filtres si vous ne trouvez rien",
  "change-filters": "Change filters",
  attachments: "Pièces jointes",
  "settings-title": "Paramêtres",
  building: "Bâtiment",
  phone: "Téléphone",
  phones: "Téléphones",
  email: "Courriel",
  emails: "Courriels",
  delete: "Supprimer",
  add: "Ajouter",
  cancel: "Annuler",
  buildings: "Bâtiments",
  unit: "Unité",
  units: "Unités",
  project: "Client",
  projects: "Clients",
  applicants: "Candidats",
  "applicants-v2": "Candidats V2",
  applicant: "Candidat",
  deposit: "Dépôt",
  deposits: "Dépôts",
  finances: "Finances",
  tenant: "Locataire",
  tenants: "Locataires",
  role: "Rôle",
  calendar: "Calendrier",
  user: "Utilisateur",
  users: "Utilisateurs",
  urgent: "Urgent",
  activate: "Activer",
  deactivate: "Désactiver",
  zoomIn: "Agrandire",
  zoomOut: "Réduire",
  "see-details-for": "Voir les détails pour {{for}}",
  "list-pagination": "Page {{currentPage}} de {{totalPage}}",
  "management-type": "Type de gestion",
  "sort-by": "Trier par",
  "your-company": "Votre compagnie",
  "primary-phone": "Téléphone principal",
  "secondary-phone": "Téléphone secondaire",
  "first-name": "Prénom",
  "last-name": "Nom de famille",
  language: "Langage",
  submit: "Soumettre",
  save: "Sauvegarder",
  modify: "Modifier",
  password: "Mot de passe",
  "password-confirm": "Retaper le mot de passe",
  heating: "Chauffage",
  "room-number": "Nombre de pièces",
  floor: "Étage",
  floors: "Étages",
  next: "Suivant",
  previous: "Précédent",
  "square-foot": "pieds carré",
  size: "Grandeur",
  "unit-size": "Grandeur de l'unité",
  "water-tank-installation": "Installation du chauffe-eau",
  "toast-warning": "Il semble avoir des champs en erreur dans votre formulaire, veuillez vérifier celui-ci et essayer à nouveau.",
  download: "Télécharger",
  "prepare-download": "Préparer le téléchargement",
  generate: "Générer",
  name: "Nom",
  names: "Noms",
  lease: "Bail",
  leases: "Baux",
  renewal: "Renouvellement",
  renewals: "Renouvellements",
  credit: "Crédit",
  day: "Jours",
  "date-birth": "Date de naissance",
  fullname: "Nom complet",
  status: "Statut",
  view: "Visualiser",
  yearly: "Annuel",
  monthly: "Mensuel",
  biweekly: "Bihebdomadaire",
  weekly: "Hebdomadaire",
  salary: "Salaire",
  "unsaved-changed": "Modifications non enregistrées",
  hide: "Cacher",
  today: "Aujourd'hui",
  "place-holder-address": "123 Rue du Sirop, Gatineau QC (A1B 2C3)",
  to: "au ",
  reason: "Raison",
  interior: "Intérieur",
  exterior: "Extérieur",
  garage: "Garage",
  true: "Vrai",
  false: "Faux",
  select: "Choisir",
  properties: "Propriétés",
  property: "Propriété",
  "lease-ended": "Terminé",
  "lease-end-today": "Termine aujourd'hui",
  rooms: "Chambres",
  room: "Chambre",
  or: "ou",
  and: "et",
  month: "Mois",
  months: "Mois",
  published: "Publié",
  unpublished: "Non publié",
  fr: "Français",
  en: "Anglais",
  "not-saved": "Non Sauvegarder",
  "to-maximized-ads": "Pour améliorer la qualité de l'annonce, nous recommandons les modifications suivante:",
  "add-pin-to-building": "Ajouter un marqueur sur la carte pour le bâtiment",
  none: "Aucune",
  "field-required": "Ce champ est obligatoire",
  "file-too-large": "Le fichier est trop volumineux",
  "up-to-10-mb": "Jusqu'à 10 Mo",
  added: "ajouté",
  modified: "modifié",
  deleted: "supprimé",
  "added-feminine": "ajoutée",
  "modified-feminine": "modifiée",
  "deleted-feminine": "supprimée",
  "list-pagination-showing": "Afficher",
  "list-pagination-to": "sur",
  "list-pagination-of": "de",
  "days-left": "Jours restants",
  "lease-number": "Numéro de bail",
  "lease-total-amount": "Montant total du bail",
  "search-result-select-placeholder": "Résulat de recherche",
  management: "Gestion",
  rental: "Location",
  "generic-fetch-error-message": "Une erreur s'est produite lors de la récupération des données souhaitées, veuillez réessayer ou contacter nous si l'erreur persiste.",
  current: "Courant",
  ended: "Terminé",
  canceled: "Annulé",
  restricted: "Limitée",
  agent: "Agent",
  french: "Français",
  english: "Anglais",
  delivery: "Livraison",
  deliveries: "Livraisons",
  post: "Publier",
  description: "Description",
  task: "Tâche",
  downpayment: "Acompte reçus",
  "total-downpayment": "Acompte Total"
};
const emptyStates = {
  "empty-state-buildings-heading": "Aucun bâtiments trouvés",
  "empty-state-buildings-description": "Essayez un autre résultat de recherche ou ajoutez un nouveau bâtiment au système",
  "empty-state-past-tenant-description": "Aucun locataire précédent trouvé."
};
export const fr = { ...generic,
  ...emptyStates,
  "tasks-title": "Tâches",
  audit: "Événement",
  audits: "Événements",
  "legal-disclosure-1": "En vous connectant à la plateforme, vous reconnaissez accepter nos",
  "legal-disclosure-2": "et notre",
  "term-of-service": "conditions d'utilisation",
  "privacy-policy": "politique relative à la vie privée",
  "term-of-service-url": "https://simpleloyer.ca/fr/terms_of_services",
  "privacy-policy-url": "https://simpleloyer.ca/fr/privacy_policy",
  "login-email": "Courriel",
  "login-password": "Mot de passe",
  "login-connection": "Connexion",
  "login-next": "Suivant",
  "login-password-reset": "Réinitialiser votre mot de passe",
  "login-back-to-main": "Retourner à la page de connexion",
  "login-federated-login": "Connectez-vous avec l'un de vos comptes sur une des plateformes suivantes:",
  "login-title-connect": "Connectez-vous avec votre compte SimpleLoyer",
  "login-title-reset": "Réinitialiser le mot de passe",
  "login-error-email": "Veuillez entrer un courriel",
  "login-error-password": "Veuillez entrer un mot de passe",
  "login-success-password-reset": "Le courriel de réinitialisation a été envoyé!",
  "login-error-password-reset": "Nom d'uttilisateur invalide, veuillez réessayer ou contacter le support.",
  "login-error": "Nom d'uttilisateur ou mot de passe invalide,  veuillez réessayer ou contacter le support.",
  "building-info-no-building-found": "Ce bâtiment est introuvable. Veuillez retourner et essayer de nouveau.",
  "building-info-add-unit": "Ajouter une unité",
  "building-info-add-lease": "Ajouter un nouveau bail",
  "building-info-renew-lease": "Renouveler le bail",
  "building-info-occupied-unit": "Unités occupées",
  "building-info-unit-to-renew": "Unités à renouveler",
  "building-info-unit": "Unité",
  "building-info-empty-unit": "Cette unité est vide",
  "building-info-empty-unit-future-lease": "Cette unité est présentement vide mais sera occupée à partir du",
  "building-info-lease-not-renew": "Le bail de cette unité ne sera pas renouvelé et teminera le",
  "building-info-add-unit-empty-state": "Ajouter une unité à votre bâtiment",
  "building-info-not-unit-found": "Une fois que vous aurez des unités, vous pourrez gérer vos bails et vos locataires ici.",
  "building-info-card-building-info": "Information du bâtiment",
  "building-info-modify-building": "Modifier le bâtiment",
  "building-info-construction-year": "Année de construction",
  "building-info-acquisition-date": "Date d'acquisition",
  "delete-building": "Supprimer le bâtiment",
  "delete-building-warning": "Ce bâtiment sera supprimé du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-building-last-warning": "Supprimer ce bâtiment",
  "delete-building-success": "Le bâtiment a été suprimé avec succès.",
  "delete-building-error": "Une erreur est survenue lors de la suppression de ce bâtiment. Veuillez essayer à nouveau.",
  "delete-project": "Supprimer le client",
  "delete-project-warning": "Ce client sera supprimé du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-project-last-warning": "Supprimer ce client",
  "delete-project-success": "Le client a été supprimé avec succès.",
  "delete-project-error": "Une erreur est survenue lors de la suppression de ce client. Veuillez essayer à nouveau.",
  "delete-lease": "Supprimer le bail",
  "delete-lease-warning": "Ce bail sera supprimé du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-lease-last-warning": "Supprimer ce bail",
  "delete-lease-success": "Le bail a été supprimé avec succès.",
  "delete-lease-error": "Une erreur est survenue lors de la suppression de ce bail. Veuillez essayer à nouveau.",
  "delete-contract": "Supprimer le bail",
  "delete-contract-warning": "Ce bail sera supprimé du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-contract-last-warning": "Supprimer ce bail",
  "delete-contract-success": "Le bail a été supprimé avec succès.",
  "delete-contract-error": "Une erreur est survenue lors de la suppression de ce bail. Veuillez essayer à nouveau.",
  "delete-note": "Supprimer la note",
  "delete-note-warning": "Cette note sera supprimée du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-note-last-warning": "Supprimer cette note",
  "delete-note-success": "La note a été supprimée avec succès.",
  "delete-note-error": "Une erreur est survenue lors de la suppression de cette note. Veuillez essayer à nouveau.",
  "delete-tenant": "Supprimer le locataire",
  "delete-tenant-warning": "Ce locataire sera supprimé du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-tenant-last-warning": "Supprimer ce locataire",
  "delete-tenant-success": "Le locataire a été supprimé avec succès.",
  "delete-tenant-error": "Une erreur est survenue lors de la suppression de ce locataire. Veuillez essayer à nouveau.",
  "delete-unit": "Supprimer l'unité",
  "delete-unit-warning": "Cette unité sera supprimée du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-unit-last-warning": "Supprimer cette unité",
  "delete-unit-success": "L'unité a été supprimée avec succès.",
  "delete-unit-error": "Une erreur est survenue lors de la suppression de cette unité. Veuillez essayer à nouveau.",
  "delete-deposit": "Supprimer les dépôts",
  "delete-deposit-warning": "Ce dépôt sera supprimé du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-deposit-last-warning": "Supprimer ces dépôt(s)",
  "delete-deposit-success": "Le dépôt a été supprimé avec succès.",
  "delete-deposit-error": "Une erreur est survenue lors de la suppression de ce dépôt. Veuillez essayer à nouveau.",
  "delete-user": "Supprimer l'utilisateur",
  "delete-user-warning": "Cet utilisateur sera supprimé du système ainsi que toutes les ressources qui y sont attachées.",
  "delete-user-last-warning": "Supprimer cet utilisateur",
  "delete-user-success": "L'utilisateur a été supprimé avec succès.",
  "delete-user-error": "Une erreur est survenue lors de la suppression de cet utilisateur. Veuillez essayer à nouveau.",
  "delete-banner-perm-action": "Cette action est permanente",
  "buildings-empty-unit": "Unités vides",
  "buildings-renew-unit": "Unités à renouveler",
  "buildings-units": "Unités",
  "buildings-add-building": "Ajouter un nouveau bâtiment",
  "project-add-project": "Ajouter un nouveau Client",
  "project-info-no-project-found": "Ce client est introuvable. Veuillez retourner et essayer de nouveau.",
  "project-info-see-deposit": "Voir le dépôt de {{amount}}$ by {{user}}",
  "project-info-modify-project": "Modifier le client",
  "project-info-list-buildings": "Liste des bâtiments",
  "project-info-list-deposit": "Liste des dépôts",
  "project-info-add-deposit": "Ajouter un nouveau dépôt",
  "project-info": "Information du client",
  "project-info-no-contact-save": "Aucun contact enregistré",
  "project-info-no-user-associated": "Il n'y a aucun utilisateur associé à ce projet.",
  "project-info-responsible-selector": "Recherche d'un utilisateur",
  "project-info-responsible-selector-empty-state-title": "Vous n'avez pas encore ajouté d'utilisateur",
  "project-info-responsible-selector-empty-state-description": "Vous pouvez rechercher et ajouter des utilisateur pour le client à l'aide du champ de recherche ci-haut.",
  "project-info-responsible-selector-empty-state-send-invite": "Envoyer une invitation par courriel:",
  "project-info-contact-person": "Personne contact",
  "managment-location": "Location",
  "management-management": "Gestion",
  "dashboard-title": "Tableau de bord",
  search: "Recherche",
  "report-title": "Rapports",
  "placeholder-filter-by-client": "Filtrer par client...",
  "widget-published-on": "Publié le",
  "widget-display-units": "Unités en affichage",
  "widget-no-listings-found": "Aucune annonce trouvée.",
  "widget-no-upcoming-event-found": "Aucun événement trouvé.",
  "widget-status-other": "Cette unité contient plus de 3 bails inscrient au registre dans les prochains 150 jours.",
  "widget-other": "Autre",
  "widget-unit-upcoming-events-title": "Événements à venir dans vos unités (150 jours)",
  "widget-tab-all": "Tous",
  "widget-tab-empty-unit": "Unité vide",
  "widget-tab-lease-to-renew": "Bail à renouveler",
  "widget-tab-lease-not-renew": "Bail non renouvelé",
  "widget-tab-empty-unit-plurial": "Unités vides",
  "widget-tab-lease-to-renew-plurial": "Baux à renouveler",
  "widget-tab-lease-not-renew-plurial": "Baux non renouvelés",
  "widget-table-row-lease-to-renew": "Bail à renouveler - Finis dans {{days}} jours",
  "widget-table-new-unit": "Nouvelle unité",
  "widget-table-row-lease-end-on": "Bail finissant le {{date}}",
  "widget-title": "Unités nécessitant votre attention",
  "widget-empty-table": "Aucune unité trouvée.",
  "active-lease-title": "Rapport des baux actifs",
  "active-lease-description": "Générez un rapport pour les baux considérés comme actifs à une date donnée.",
  "leases-to-be-renewed-title": "Rapport sur les baux à renouveler",
  "leases-to-be-renewed-description": "Générez un rapport pour les baux qui vont être renouvelés à une date donnée.",
  profile: "Profile",
  setting: "Réglage",
  logout: "Déconnexion",
  "deposit-viewer-title": "Information sur le dépôt",
  "deposit-viewer-card-title": "Montant déposé le {{date}}",
  "deposit-accessibility-label": "Voir le dépôt de {{amount}}$ {{accessibilityInfo}}",
  "deposit-by": "par {{name}}",
  "deposit-add": "Ajouter un nouveau dépôt",
  "deposit-enter-by": "Entré par",
  "deposit-enter-deposit-by": "Entré et déposé par",
  "deposit-deposit-by": "Déposée par",
  "widget-new-entry": "Nouvelle entrée",
  "widget-tenant-leave": "Locataire quitte",
  "widget-renewing-lease": "Bail renouvelé",
  "widget-process-of-renewal": "En processus de renouvellement.",
  "widget-occupied": "Occupé",
  "dashboard-unit-vacant-since": "Vacant depuis {{day}} jours",
  "profile-toast-success": "Votre profil a été sauvegardé.",
  "profile-toast-error": "Une erreur est survenue lors de la sauvegarde du profil. Veuillez essayer à nouveau.",
  "profile-info-shared-company": "Les informations de votre utilisateur pour cette compagnie sont partagées avec les autres membres de {{companyName}}.",
  "profile-modify-profil": "Modifier votre profil",
  "profile-profil-image": "Image de profil",
  "profile-about-you": "À propos de vous",
  "profile-shared-to-all-user": "Votre information sera partagée avec tous les différents comptes d'usagers que vous avez ci-dessous.",
  "profile-notification": "Je ne souhaite pas recevoir de courriels du système",
  "profile-system-notification": "Notification du Système",
  "profile-language-changed": "La langue du système est changée à ",
  "open-registration": "Commencer l'inscription",
  "registration-welcome-title": "Bienvenue sur SimpleLoyer 🎉",
  "registration-complete-registration-title": "Complétez votre inscription",
  "registration-use-external-platform": "Utiliser l'un de vos comptes sur une plateforme externe",
  "registration-complete-registration": "Compléter votre inscription simplement en vous connectant à SimpleLoyer avec l'un de vos comptes déjà existants sur une plateforme externe",
  "registration-create-standard-account": "Créer un compte standard",
  "registration-register-stardard-account": "Compléter votre inscription en vous enregistrant avec un courriel et un mot de passe standard",
  "registration-with-standard-account": "S'inscrire avec un compte standard",
  "registration-invited-by-org": "Vous avez été invité par <0>{{name}}</0> pour participer à la gestion de bâtiments de <1>{{managementName}}</1>",
  "registration-invited-by-project": "Vous avez été invité par <0>{{name}}</0> pour superviser le projet de <1>{{managementName}}</1>",
  "consent-title": "Consenter à nos politiques d'utilisation",
  "consent-description": "Vous reconnaissez avoir lu et pris compte de nos ",
  "consent-connector": "et notre ",
  "registration-complete-registration-with-option": "Pour finaliser la création de votre compte, vous devez d'abord accepter nos conditions d'utilisation puis choisir l'une de nos deux options pour créer votre compte",
  "registration-error-request": "Il semble y avoir un problème avec la requête",
  "registration-make-sure-option-completed": "Veuillez vous assurez que toutes les options suivantes ont bien été complétées: ",
  "registration-make-sure-option-completed-external-platform": "Avoir bien complété toutes les étapes de connexion avec la plateforme externe (si vous avez choisi une plateforme externe)",
  "registration-valid-email": "Veuillez fournir une adresse courriel valide et existante",
  "registration-invitation-invalid": "Cette invitation est invalide",
  "registration-invitation-invalid-description": "Il semble que cette invitation soit incorrecte. Si vous avez déja complété votre inscription, vous pouvez vous connectez au système.",
  "registration-back-to-login-page": "Retour à la page de connexion",
  "registration-welcome": `Bonjour <0>{{name}}</0>,`,
  "registration-change-language": "Changer la langue",
  "registration-resend-email-success": "Le courriel a été envoyé",
  "registration-resend-email-error-send-to-quick": "Vous devez attendre 30 minute avant chaque envoie de courriel",
  "registration-resend-email-error": "Une erreur s'est produite. Lors de la tentative de renvoi du couriel, veuillez réessayer ou contacter le support via l'outil de chat",
  "rental-inclusion-title": "Inclusions",
  "rental-inclusion-features": "Fonctionnalités",
  "rental-inclusion-appliances": "Électroménagers",
  "rental-inclusion-counter-type": "Type de comptoirs",
  "rental-inclusion-yard-access": "Accès au terrain",
  "rental-inclusion-balcony": "Balcon privé inclus",
  "rental-inclusion-blinds": "Stores inclus",
  "rental-inclusion-furnished": "Meubles inclus",
  "rental-inclusion-washer-dryer": "Contient prise pour laveuse/sécheuse",
  "rental-inclusion-oven": "Four",
  "rental-inclusion-dishwasher": "Lave-vaiselle",
  "rental-inclusion-fridge": "Réfrigérateur",
  "rental-inclusion-drying-machine": "Sécheuse",
  "rental-inclusion-washing-machine": "Laveuse",
  "rental-inclusion-microwave": "Micro-ondes",
  "rental-parking-title": "Stationnement",
  "rental-parking-amount-ext": "Nombre de stationnement extérieur",
  "rental-parking-amount-int": "Nombre de stationnement intérieur",
  "rental-parking-amount-gar": "Nombre de stationnement garage",
  "rental-parking-amount-invalid": "Le nombre de stationnement est invalide",
  "rental-services-title": "Services",
  "rental-services-electricity": "Éléctricité",
  "rental-services-internet": "Internet",
  "rental-services-water": "Eau",
  "rental-services-heating": "Chauffage",
  "rental-services-cable": "Cable",
  "rental-services-ac": "Air climatisé",
  "rental-unit-detail-title": "L'unité",
  "rental-unit-detail-required": "Détails requis",
  "rental-unit-detail-disposition": "Disposition",
  "rental-unit-detail-bedrooms": "Qté. de chambres",
  "rental-unit-detail-sqrft": "Pieds carrés",
  "rental-unit-detail-qty-bathrooms": "Qté. Salle de bain",
  "rental-unit-detail-qty-half-bathrooms": "Qté. pièces d'eau",
  "rental-unit-detail-disabled-friendly": "L'unité est adaptée pour les personnes handicapées",
  "rental-unit-detail-smoking": "Fumeur permis",
  "rental-unit-detail-animals": "Animaux permis",
  "rental-unit-detail-error-bedrooms": "Le nombre de chambre est invalide",
  "rental-unit-detail-error-disposition": "La disposition est invalide",
  "rental-unit-detail-error-sqrft": "Le nombre de pieds carrés est invalide",
  "rental-unit-detail-error-bathrooms": "Le nombre de salle de bain est invalide",
  "rental-unit-detail-error-half-bathrooms": "Le nombre de salle d'eau est invalide",
  "rental-detail-title": "Détails de l'annonce",
  "rental-detail-description-title-french": "Description de l'annonce en français",
  "rental-detail-description-title-english": "Description de l'annonce en anglais",
  "rental-detail-monthly-price": "Prix affiché",
  "rental-detail-lease-status": "Disponibilité du bail",
  "rental-detail-lease-now": "Dès maintenant ({{date}})",
  "rental-detail-lease-end": "Aussitôt que le bail actuel se termine ({{date}})",
  "rental-detail-lease-manual": "Choisir une date manuellement",
  "rental-detail-error-price": "Le prix par mois est invalide",
  "rental-detail-error-lease-end": "La date est invalide",
  "rental-detail-error-lease-manual": "La date est invalide",
  "rental-detail-error-description": "Une description doit être fournie",
  "rental-detail-error-description-invalid": "La description est invalide",
  "tenant-accessibility-label": "Informations sur le locataire pour {{name}}",
  "tenant-add": "Ajouter un locataire",
  "tenant-sort-asc": "Nom croissant",
  "tenant-sort-desc": "Nom décroissant",
  "tenant-tab-all": "Tous",
  "tenant-tab-active": "Actifs",
  "tenant-tab-future": "À venir",
  "tenant-tab-past": "Historique",
  "tenant-tab-candidate": "Candidats",
  "tenant-tab-invitees": "Invitations en attente",
  "tenant-info-empty-state": "Ce locataire est introuvable. Veuillez retourner et essayer de nouveau.",
  "tenant-info-modify": "Modifier le locataire",
  "tenant-info-no-lease": "Il n'y a pas encore de bail",
  "tenant-info-no-lease-description": "Ce locataire n'a pas encore de bail à afficher. Veuillez lier le locataire avec un bail pour visualiser plus d'informations",
  "tenant-info-lease-history": "Historique des logements",
  "tenant-info-heading-housing": "Logement",
  "tenant-info-heading-entry-date": "Date d'entrée",
  "tenant-info-heading-end-date": "Date de fin",
  "tenant-info-title": "Information du locataire",
  "tenant-info-active-lease": "Bail actif",
  "tenant-info": "Information du locataire",
  "unit-fill-technical-sheet": "Remplir la fiche technique",
  "unit-create-technical-sheet": "Créer une fiche technique",
  "unit-modify-technical-sheet": "Modifier la fiche technique",
  "unit-empty-state-unit-not-found": "Cette unité est introuvable. Veuillez retourner et essayer de nouveau.",
  "unit-add-lease": "Ajouter un nouveau bail",
  "unit-modify-unit": "Modifier l'unité",
  "unit-modify-active-lease": "Modifier le bail actif",
  "unit-empty-state-no-lease": "Il n'y a pas encore de bail",
  "unit-empty-state-no-lease-description": `Cette unité n'a pas encore de bail.`,
  "unit-info": "Information sur l'unité",
  "unit-see-lease": "Voir le bail",
  "unit-modify-lease": "Modifier le bail",
  "unit-delete-lease": "Supprimer le bail",
  "unit-tenant-history": "Historique des locataires",
  "unit-heading-name": "Nom(s)",
  "unit-heading-entry-date": "Date d'entrée",
  "unit-heading-end-date": "Date de fin",
  "unit-heading-action": "Action",
  "user-added-the": "Ajouté le : {{date}}",
  "user-added-by": "Ajouté par : {{name}}",
  "user-invited-at": "Invité le : {{date}}",
  "user-add": "Ajouter un utilisateur",
  "user-tab-employee": "Employées",
  "user-tab-registrant": "Régistrant",
  "file-viewer-modify": "Modifier",
  "file-viewer-no-file": "Vous n'avez pas de fichiers attachés à cette ressource présentement.",
  "lease-viewer-title": "Bail du ",
  "lease-viewer-payment-method": "Mode de paiement",
  "lease-viewer-start-lease": "Début du bail",
  "lease-viewer-end-lease": "Fin du bail",
  "lease-viewer-day-before-renew": "Jours restants avant le renouvellement",
  "lease-viewer-day-remaining": "{{date}} jours restant",
  "lease-viewer-lease-info": "Information du bail",
  "lease-viewer-monthy-rent": "Loyer mensuel",
  "lease-viewer-lease-not-renew": "Ce bail ne sera pas renouvelé et teminera le {{date}}.",
  "management-for": "Gestion pour",
  "management-building-title": "Gestion de bâtiment",
  "management-building-description": "Ce bâtiment est géré par une entreprise.",
  "management-building-since": "Gestion du bâtiment depuis le",
  "management-tenant-title": "Gestion du locataire",
  "management-tenant-description": "Ce locataire est géré par une entreprise.",
  "management-tenant-since": "Gestion du locataire depuis le",
  "map-search-address": "Rechercher une adresse",
  "map-text-container-no-result": "Aucun résultat trouvé. Veuillez faire une recherche.",
  "map-automatic-marker": "Permettre de positionner le marqueur avec la correction automatique",
  "notes-no-notes-found": "Vous n'avez pas de notes attachées à cette ressource présentement.",
  "notes-added-error": "Une erreur est survenue lors de la sauvegarde de cette note. Veuillez essayer à nouveau.",
  "notes-delete-error": "Une erreur est survenue lors de la suppression de cette note. Veuillez essayer à nouveau.",
  "notes-added-success": "Votre note à été sauvegardé.",
  "notes-deleted-success": "Votre note à été supprimé.",
  "notes-title": "Notes",
  "rental-publish-ad": "Publier l'annonce",
  "rental-remove-ad": "Retirer l'annonce",
  "rental-ad-no-longer-publised": "L'annonce n'est pas publiée",
  "rental-publised-since": "L'annonce est présentement publiée et active",
  "rental-ad-status": "Statut de l'annonce",
  "rental-ad-not-created": "Vous n'avez pas créé la fiche de l'annonce.",
  "building-form-title": "Information du bâtiment",
  "building-form-description": "Veuillez fournir les informations de base du bâtiment. Nous utilisons ces valeurs pour permettre la capture automatique de certains champs dans la création d'une unité ou d'un bail.",
  "building-form-civic-number": "Numéro civique",
  "building-form-address": "Adresse",
  "building-form-city": "Ville",
  "building-form-postal-code": "Code postal",
  "building-form-sector": "Secteur",
  "building-form-province": "Province",
  "building-form-country": "Country",
  "building-form-construction-date": "Date de construction",
  "building-form-acquisition-date": "Date d'acquisition",
  "building-form-error-civic": "Le numéro civique est requis",
  "building-form-error-address": "L'adresse est requise",
  "building-form-error-city": "La ville est requise",
  "building-form-error-postal-code": "Le code postal est invalide ou requis ex: a1b 2c3",
  "building-form-error-province": "La province est invalide",
  "building-form-error-sector": "Le secteur est invalide",
  "building-form-error-build-date": "La date est invalide ou requise",
  "building-form-error-purchase-date": "La date est invalide",
  "project-form-error-name": "Le nom de votre client est requis",
  "project-form-error-contact-name": "Le nom de la personne à contacter est requis",
  "project-form-error-phone": "Le numéro de téléphone ou le courriel est requis",
  "project-form-error-email": "Le numéro de téléphone ou le courriel est requis",
  "project-form-error-management-type": "Cette type de gestion est invalide ",
  "project-form-title": "Information du client",
  "project-form-description": "Veuillez fournir les informations du client.",
  "project-form-name": "Nom du client",
  "project-form-placeholder-my-company": "Ma compagnie",
  "project-form-legal-name": "Nom légal",
  "project-form-management-type": "Type de gestion",
  "project-parent-company": "Compagnie qui gère le client",
  "project-form-contact-name": "Nom de la personne contact",
  "project-form-primary-phone": "Téléphone primaire du client",
  "project-form-email": "Courriel de la compagnie",
  "project-form-fax": "Adresse Fax du client",
  "provider-form-name": "Nom",
  "provider-form-email": "E-mail",
  "provider-form-primary-phone": "Numéro de téléphone principal",
  "provider-form-secondary-phone": "Numéro de téléphone secondaire",
  "provider-parent-company": "Propriétaire de ce fournisseur",
  "provider-form-update-provider-success": "Le fournisseur est mis à jour avec succès.",
  "provider-form-update-provider-fail": "Une erreur s'est produite lors de la mise à jour du fournisseur. Veuillez réessayer.",
  "provider-form-add-provider-success": "Le fournisseur est créé avec succès.",
  "provider-form-add-provider-fail": "Une erreur s'est produite lors de la création du fournisseur. Veuillez réessayer.",
  "company-form-title": "Gestion du bâtiment",
  "company-form-description": "Utiliser ce champ pour changez la compagnie qui est responsable de la gestion du bâtiment. Si vous n’êtes pas dans une entreprise de gestion, vous pouvez simplement garder la sélection automatique.",
  "company-form-error-management-company": "Un responsable est requis",
  "company-form-error-start-date": "La date est invalide",
  "company-form-loading": "Chargement en cours",
  "company-form-start-date": "Date de début de la gestion (optionnel)",
  "contract-form-error-date": "La date est invalide",
  "contract-form-title": "Information sur le contrat",
  "contract-form-description": "Veuillez choisir les dates de début et de fin pour votre entente avec votre client.",
  "contract-form-start-date": "Date de début",
  "contract-form-end-date": "Date de fin",
  "deposit-form-error-amount": "Un montant positif est requis",
  "deposit-form-error-deposit-date": "La date est invalide",
  "deposit-form-error-destination": "Ce client est invalide",
  "deposit-form-title": "Information du dépôt",
  "deposit-form-description": "Veuillez fournir les informations de base du dépôt.",
  "deposit-form-amount": "Montant",
  "deposit-form-date": "Date",
  "ticket-form-add-ticket-success": "Le nouveau billet a été créé avec succès.",
  "ticket-form-add-ticket-fail": "Une erreur s'est produite lors de la création du billet. Veuillez réessayer.",
  "ticket-form-add-ticket-conversation-success": "La nouvelle conversation est créée avec succès.",
  "ticket-form-add-ticket-conversation-fail": "Une erreur s'est produite lors de la création de la conversation. Veuillez réessayer.",
  "ticket-form-update-ticket-success": "Le billet a été mis à jour avec succès.",
  "ticket-form-update-ticket-fail": "Une erreur s'est produite lors de la mise à jour du billet. Veuillez réessayer.",
  "ticket-form-close-task-success": "La tâche a été fermée avec succès.",
  "ticket-form-close-task-fail": "Une erreur s'est produite lors de la fermeture de la tâche. Veuillez réessayer.",
  "ticket-form-close-ticket-success": "Le billet a été clôturé avec succès.",
  "ticket-form-close-ticket-fail": "Une erreur s'est produite lors de la fermeture du billet. Veuillez réessayer.",
  "ticket-form-delete-ticket-success": "Le billet a été supprimé avec succès.",
  "ticket-form-delete-task-fail": "Une erreur s'est produite lors de la suppression du billet. Veuillez réessayer.",
  "delete-ticket-warning": "Ce billet sera supprimé du système, ainsi que toute ressource qui lui est attachée.",
  "delete-ticket": "Supprimer ce billet",
  "delete-ticket-last-warning": "Supprimer ce billet",
  "delete-ticket-success": "Le billet a été supprimé avec succès.",
  "delete-ticket-error": "Une erreur s'est produite lors de la suppression de ce billet. Veuillez réessayer.",
  "tickets-table-status": "Statut",
  "tickets-status-badge-deleted": "Supprimé",
  "tickets-status-badge-closed": "Fermé",
  "tickets-status-badge-opened": "Ouvert",
  "ticket-no-tasks-label": "Aucune tâche ajoutée",
  "ticket-conversation-header": "Conversation",
  "ticket-priority-high": "Haute",
  "ticket-priority-medium": "Moyenne",
  "ticket-priority-low": "Basse",
  "ticket-related-title": "Billet Associé",
  "task-form-add-task-success": "La nouvelle tâche a été créée avec succès.",
  "task-form-add-task-fail": "Une erreur s'est produite lors de la création de la tâche. Veuillez réessayer.",
  "task-form-update-task-success": "La tâche a été mise à jour avec succès.",
  "task-form-update-task-fail": "Une erreur s'est produite lors de la mise à jour de la tâche. Veuillez réessayer.",
  "task-form-add-invoice-success": "La nouvelle facture a été créée avec succès.",
  "task-form-add-invoice-fail": "Une erreur s'est produite lors de la création de la facture. Veuillez réessayer.",
  "task-form-update-invoice-success": "La facture a été mise à jour avec succès.",
  "task-form-update-invoice-fail": "Une erreur s'est produite lors de la mise à jour de la facture. Veuillez réessayer.",
  "task-form-invoice-payer": "Facturer",
  "task-form-close-task-success": "La tâche a été fermée avec succès.",
  "task-form-close-task-fail": "Une erreur s'est produite lors de la fermeture de la tâche. Veuillez réessayer.",
  "task-form-delete-task-success": "La tâche a été supprimée avec succès.",
  "task-form-delete-task-fail": "Une erreur s'est produite lors de la suppression de la tâche. Veuillez réessayer.",
  "task-add-section-title": "Ajouter une nouvelle tâche",
  "task-edit-section-title": "Modifier la tâche",
  "task-edit-close-task": "Fermer la tâche",
  "task-add-sidebar-title-label": "Titre de la tâche",
  "task-add-sidebar-title-placeholder": "Title...",
  "task-add-sidebar-title-error": "Le titre doit comporter au moins 1 caractères",
  "task-add-sidebar-due-date": "Date d'échéance",
  "task-add-sidebar-user-title": "Responsable",
  "task-add-sidebar-button-delete": "Effacer",
  "task-add-sidebar-button-add": "Ajouter",
  "task-add-sidebar-button-edit": "Éditer",
  "task-add-sidebar-button-create": "Créer",
  "task-add-sidebar-invoice-title-label": "Titre de la facture",
  "task-add-sidebar-invoice-title-placeholder": "Titre...",
  "task-add-sidebar-invoice-amount-label": "Montant de la facture",
  "task-add-sidebar-invoice-amount-placeholder": "Montant...",
  "task-add-sidebar-invoice-amount-error": "Veuillez saisir un montant de facture valide",
  "task-add-sidebar-invoice-description-label": "Description de la facture",
  "task-add-sidebar-invoice-description-placeholder": "La description...",
  "task-add-sidebar-invoice-date-title": "Date",
  "task-invoice-title": "Facture",
  "file-section-title": "Fichiers",
  "file-section-description": "Vous pouvez ajouter des fichiers au bail pour conserver des artefacts tel que bail papier en référence.",
  "file-section-error": "Une photo est requise",
  "file-section-single-image-title": "Image",
  "file-section-single-image-description": "L'image que vous ajoutez à votre bâtiment sera utilisée pour vous aidez à visualiser vos bâtiments.",
  "file-section-single-image-description-rental": "La vignette que vous souhaitez ajouter à votre annonce.",
  "file-section-banner-description": "Une photo est requise pour afficher une annonce",
  "file-section-multiple-image-title": "Galerie d'images",
  "file-section-multiple-image-description": "Les images que vous souhaitez ajouter à votre annonce.",
  "rental-form-ad-saved": "Votre annonce a été sauvegardée.",
  "rental-form-ad-error": "Une erreur est survenue lors de la sauvegarde de votre annonce. Veuillez essayer à nouveau.",
  "rental-form-modify-ad": "Modifier l'annonce",
  "rental-form-add-ad": "Ajouter une annonce à l'unité",
  "rental-form-image-section-title": "Vignette de l'annonce",
  "rental-form-contact-for-ad": "Contacts pour l'annonce",
  "note-section-title": "Notes",
  "note-section-placeholder": "Spécifier le contenu de votre note ici...",
  "note-section-button-remove-note": "Retirer la note",
  "note-section-description": "Voici les notes au dossier du locataire.",
  "note-section-add-note": "Ajouter une note",
  "note-section-empty-state-title": "Vous n'avez pas encore ajouté de notes",
  "note-section-empty-state-description": "Il semblerait qu'il n'y ait pas encore de notes! Veuillez en attacher à la ressource pour pouvoir les consulter.",
  "responsible-selector-title": "Responsables du bâtiment ",
  "responsible-selector-description": "Vous pouvez ajouter des responsables qui sont liés au bâtiment pour faciliter votre gestion",
  "responsible-selector-error": "Un responsable est requis",
  "responsible-selector-search-manager": "Recherche d'un responsable",
  "responsible-selector-empty-state-title": "Vous n'avez pas encore ajouté de responsables",
  "responsible-selector-empty-state-description": "Vous pouvez rechercher et ajouter des responsables pour le bâtiment à l'aide du champ de recherche ci-haut.",
  "tags-section-title": "Mots clés",
  "tags-section-description": `Ajoutez des mots clés pour faciliter la recherche et l'organisation de vos bâtiments Ex. "Complexe Sunrise", "Région 1A", "Sur le lac", ...`,
  "tags-section-placeholder": "Ajouter un tag",
  "tenant-selector-title": "Sélection des locataires",
  "tenant-selector-description": "Vous pouvez choisir parmi vos locataires déjà inclus dans le système pour les ajouter au bail.",
  "tenant-selector-error": "Un locataire est requis",
  "tenant-selector-search-tenant": "Recherche d'un locataire",
  "tenant-selector-empty-state-title": "Vous n'avez pas encore ajouté de locataires",
  "tenant-selector-empty-state-description": "Vous pouvez rechercher et ajouter des locataires à l'aide du champ de recherche ci-haut.",
  "unit-lease-info-monthly-price-invalid": "Le prix par mois est invalide",
  "unit-lease-info-bill-day-invalid": "La journée de facturation est invalide",
  "unit-lease-info-payment-method-invalid": "Le type de paiement est invalide",
  "unit-lease-info-date-invalid": "La date est invalide",
  "unit-lease-info-section-title": "Information du bail",
  "unit-lease-info-section-description": "Vous pouvez choisir la durée de votre bail ainsi que les différentes options de communication automatique par rapport au déneigement.",
  "unit-lease-info-card-title-info": "Informations financières",
  "unit-lease-info-card-description-duration": "Durée du bail",
  "unit-lease-info-label-monthly-price": "Prix mensuel",
  "unit-lease-info-label-bill-day": "Jour de facturation",
  "unit-lease-info-label-bill-day-help-text": "ex: 1 signifie que le locataire sera facturé le premier du mois.",
  "unit-lease-info-label-payment-method": "Méthode de paiement",
  "unit-lease-info-label-do-not-renew": "Ne pas renouveler le bail",
  "unit-lease-info-label-radio-create-1year-lease": "Créer un bail d'une durée de 1 an",
  "unit-lease-info-label-radio-start-date": "Date du début de bail",
  "unit-lease-info-label-radio-end-date": "Date de la fin bail",
  "unit-lease-info-label-radio-choose-manual-date": "Choisir manuellement le début et la fin du bail",
  "unit-info-section-title": "Information de l'unité",
  "unit-info-section-description": "Voici les informations de l'unité que vous souhaitez ajouter.",
  "unit-info-section-error-unit-type": "Le type d'appartement est invalide",
  "unit-info-section-error-sub-address": "L'adresse de l'unité est requise",
  "unit-info-section-error-bedroom": "Le nombre de chambre est invalide",
  "unit-info-section-error-floor": "L'étage est invalide",
  "unit-info-section-error-square-footage": "Le nombre de pieds carrés est invalide",
  "unit-info-section-error-heating-type": "Le type de chauffage est invalide",
  "unit-info-section-error-water-tank-date": "La date est invalide",
  "unit-info-section-form-unit-type": "Type d'unité",
  "unit-info-section-form-sub-address": "Numéro de l'unité",
  "unit-info-section-form-sub-address-placeholder": "ex: 1, A",
  "unit-info-section-form-floor": "Étage",
  "unit-info-section-form-bedroom-number": "Nombre de pièces",
  "unit-info-section-form-square-foot": "Espace habitable de l'unité",
  "unit-info-section-form-square-foot-suffix": "Pieds carrés",
  "unit-info-section-form-heating-type": "Type de chauffage",
  "unit-info-section-form-water-tank-date": "Date d'installation du chauffe-eau",
  "unit-selector-title": "Sélection de l'unité",
  "unit-selector-description": "Choisir l'unité à laquelle vous voulez ajouter le bail.",
  "unit-selector-error": "Une unité est requise ou l'unité choisie est invalide",
  "unit-selector-no-unit": "Aucune unité",
  "unit-selector-selected-building": "Votre bâtiment sélectionné",
  "unit-selector-selected-building-title": "Sélection du bâtiment",
  "unit-selector-selected-building-description": "Choisir le bâtiment et l'unité à laquelle vous voulez ajouter le bail.",
  "user-info-error-first-name": "Le prénom de l'utilisateur est requis",
  "user-info-error-last-name": "Le nom de l'utilisateur est requis",
  "user-info-error-secondary-phone": "Le numéro de téléphone secondaire de l'utilisateur est invalide",
  "user-info-error-phone-or-email-required": "Le numéro de téléphone ou le courriel de l'utilisateur est requis",
  "user-info-error-email-required": "Le courriel est requis",
  "user-info-error-phone-required": "Le numéro de téléphone est requis",
  "user-info-error-phone-invalid": "Le numéro de téléphone est invalide (ex: 222-333-5555)",
  "user-info-error-email-invalid": "Le courriel est invalide (ex: john@simpleloyer.ca)",
  "user-info-form-first-name": "Prénom",
  "user-info-form-first-name-placeholder": "John",
  "user-info-form-last-name": "Nom",
  "user-info-form-last-name-placeholder": "Doe",
  "user-info-form-primary-phone": "Téléphone principal",
  "user-info-form-primary-phone-placeholder": "123-456-7894",
  "user-info-form-cellphone": "Cellulaire",
  "user-info-form-secondary-phone": "Téléphone secondaire",
  "user-info-form-secondary-phone-placeholder": "123-456-7894",
  "user-info-form-email-placeholder": "jsmith@gmail.com",
  "user-preference-error-language": "Les préférences choisies sont invalides",
  "user-preference-error-notification": "Le choix est invalide",
  "user-preference-form-language": "Langage",
  "user-preference-form-notification": "NOTIFICATIONS DU SYSTÈME",
  "user-preference-form-notification-label": "Cet usager ne souhaite pas recevoir de notifications du système.",
  "building-form-modify-building": "Modifier le bâtiment",
  "building-form-add-building": "Ajouter un nouveau bâtiment",
  "building-form-add-building-success": "Votre bâtiment a été sauvegardé.",
  "building-form-add-building-fail": "Une erreur est survenue lors de la sauvegarde du bâtiment. Veuillez essayer à nouveau.",
  "project-form-add-success": "Votre client a été sauvegardé.",
  "project-form-add-fail": "Une erreur est survenue lors de la sauvegarde du client. Veuillez essayer à nouveau.",
  "project-form-add": "Ajouter un nouveau client",
  "project-form-modify": "Modifier le client",
  "deposit-form-add-success": "Votre dépôt a été sauvegardé.",
  "deposit-form-add-fail": "Une erreur est survenue lors de la sauvegarde du dépôt. Veuillez essayer à nouveau.",
  "deposit-form-delete-success": "Votre dépôt a été supprimé.",
  "deposit-form-delete-fail": "Une erreur est survenue lors de la suppression du dépôt. Veuillez essayer à nouveau.",
  "deposit-form-modify-deposit": "Modifier le dépôt",
  "deposit-form-add-deposit": "Ajouter un nouveau dépôt",
  "deposit-form-deposit-destination": "Destination du dépôt",
  "deposit-form-deposit-destination-description": "Veuillez choisir la compagnie qui reçoit le dépôt.",
  "deposit-form-deposit-chose-company": "Utilisateur qui fait le dépôt",
  "deposit-form-deposit-chose-company-description": "Vous pouvez ajouter l'utilisateur qui fait le dépôt",
  "file-uploader-image-supported-file": "n'est pas pris en charge. Le type de fichier doit être .gif, .jpg, .png ou .svg.",
  "file-uploader-image-supported-file-banner-title": "Les images suivantes n'ont pas pu être téléchargées:",
  "file-uploader-file-supported-file": "n'est pas pris en charge. Le type de fichier doit être .pdf.",
  "file-uploader-file-supported-file-banner-title": "Le fichier suivant n'a pas pu être téléchargé:",
  "file-uploader-no-file-attached": "Vous n'avez pas de fichiers attachés à cette ressource présentement.",
  "file-uploader-add-image": "Ajouter une image",
  "file-uploader-drop-single-image": "ou déposer une images à mettre en ligne",
  "file-uploader-drop-image": "ou déposer les images à mettre en ligne",
  "file-uploader-add-file": "Ajouter un fichier",
  "file-uploader-drop-file": "ou déposer les fichiers à mettre en ligne",
  "file-form-saved-success": "Votre fichier a été sauvegardé.",
  "file-form-saved-fail": "Une erreur est survenue lors de la sauvegarde de vos fichiers. Veuillez essayer à nouveau.",
  "file-form-title": "Gérer les fichiers",
  "file-form-description": "Vous pouvez modifier les fichiers de la ressource choisie si vous les déposez dans l'encadrement ci-joint.",
  "leasing-form-saved-success": "Votre bail a été sauvegardé.",
  "leasing-form-saved-fail": "Une erreur est survenue lors de la sauvegarde du bail. Veuillez essayer à nouveau.",
  "leasing-form-modify-lease": "Modifier le bail",
  "leasing-form-add-lease": "Ajouter un nouveau bail",
  "tenant-form-saved-success": "Le locataire a été sauvegardé.",
  "tenant-form-saved-fail": "Une erreur est survenue lors de la sauvegarde du locataire. Veuillez essayer à nouveau.",
  "tenant-form-modify-tenant": "Modifier un locataire",
  "tenant-form-user-info-title": "Informations personnelles",
  "tenant-form-user-info-description": "Voici les informations de contact afin de pouvoir communiquer avec ce locataire.",
  "tenant-form-user-preference-title": "Préférence du locataire",
  "tenant-form-user-preference-description": "Choisissez les préférences que le système utilisera lorsqu'il communiquera avec le locataire",
  "unit-form-saved-success": "Votre unité a été sauvegardé.",
  "unit-form-saved-fail": "Une erreur est survenue lors de la sauvegarde de l'unité. Veuillez essayer à nouveau.",
  "unit-form-modify-unit": "Modifier cette unité",
  "unit-form-add-unit": "Ajouter une unité à un bâtiment",
  "user-form-saved-success": "L'utilisateur a été ajouté.",
  "user-form-saved-fail": "Une erreur est survenue lors de la sauvegarde de l'utilisateur. Veuillez essayer à nouveau.",
  "user-form-modify-user": "Afficher l'utilisateur",
  "user-form-add-user": "Ajouter un utilisateur",
  "user-form-banner-title-register-not-complete": "Cet utilisateur n'a pas encore complété son inscription!",
  "user-form-banner-description-register-not-complete": "Vous pouvez fournir à l'utilisateur le lien suivant pour finaliser son inscription: ",
  "user-form-company-selection-title": "Sélection de la compagnie",
  "user-form-company-selection-description": "Choisissez la compagnie pour laquelle vous souhaitez que l'utilisateur soit ajouté.",
  "user-form-user-info-title": "Informations personnelles",
  "user-form-user-info-description": "Voici les informations de contact de l'utilisateur.",
  "user-form-user-role-title": "Permissions de l'utilisateur",
  "user-form-user-role-description": "Vous assignez ici un rôle à l'utilisateur afin de donner à ce dernier des permissions pour faire des actions dans le système. Rôles: Gestionnaire (Accès à toutes les actions), Employée (Accès aux actions standards). Observateur (Peut seulement visualiser l'information)",
  "user-form-user-preference-title": "Préférence de l'utilisateur",
  "user-form-user-preference-description": "Choisissez les préférences que le système utilisera lorsqu'il communiquera avec cet utilisateur",
  "user-role-section-error": "Le rôle choisi est invalide ou requis",
  "leasing-form-billing-cash": "Argent Comptant",
  "leasing-form-billing-check": "Chèque",
  "leasing-form-billing-credit-card": "Carte de crédit",
  "leasing-form-billing-direct-debit": "Dépot direct",
  "leasing-form-billing-eTransfer": "Transfert éléctronique",
  "unit-form-unit-type-apartment": "Appartement",
  "unit-form-unit-type-condo": "Condo",
  "unit-form-unit-type-house": "Maison",
  "unit-form-unit-type-studio": "Studio",
  "unit-form-unit-type-room": "Chambre unique",
  "unit-form-unit-type-other": "Autre",
  "unit-form-unit-type-commercial": "Commercial",
  "unit-form-heating-type-electricity": "Électricité",
  "unit-form-heating-type-gas": "Gaz",
  "unit-form-heating-type-oil": "Huile",
  "unit-form-heating-type-other": "Autre",
  "unit-form-floor-basement": "Sous-sol",
  "unit-form-floor-semi-basement": "Semi sous-sol",
  "unit-form-floor-ground": "Rez-de-chaussée",
  "unit-form-floor-first": "Première étage",
  "unit-form-floor-second": "Deuxième étage",
  "unit-form-floor-third": "Troisième étage",
  "unit-form-floor-fourth": "Quatrième étage",
  "unit-form-floor-fifth": "Cinquième étage",
  "unit-form-floor-sixth": "Sixième étage",
  "unit-form-floor-seventh": "Septième étage",
  "unit-form-floor-eighth": "Huitième étage",
  "unit-form-floor-ninth": "Neuvième étage",
  "unit-form-floor-tenth": "Dixième étage",
  "rental-inclusion-counter-melamine": "Mélamine",
  "rental-inclusion-counter-granite": "Granite",
  "rental-inclusion-counter-quartz": "Quartz",
  "rental-inclusion-counter-other": "Autre",
  "rental-inclusion-counter-unspecified": "Non spécifié",
  "user-role-section-system": "Système",
  "user-role-section-owner": "Propriétaire",
  "user-role-section-admin": "Administrateur",
  "user-role-section-manager": "Gestionnaire",
  "user-role-section-user": "Employé",
  "user-role-section-client": "Observateur",
  "applicant-request-title": "Candidats",
  "applicant-request-title-new": "Nouvelle Application",
  "applicant-request-tab-active-approved": "Actifs et approuvés",
  "applicant-request-for": "Application pour",
  "applicant-request-tab-completed": "Complétés",
  "applicant-request-tab-canceled": "Annulés",
  "applicant-request-add-applicant": "Ajouter un nouveau candidat",
  "applicant-request-heading-application-state": "États des applications",
  "applicant-request-heading-application-date": "Date d'application",
  "project-remove-modal-action": "Retirer l'accès",
  "project-remove-modal-warning": "Cet utilisateur va perdre ses accès au client ainsi que toutes les ressources qui y sont attachées.",
  "project-remove-modal-last-warning": "Retirer les accès",
  "project-remove-modal-last-success": "Les accès ont bien été retirer avec succès.",
  "project-remove-modal-last-error": "Une erreur est survenue lors de la suppression des accès de cet utilisateur. Veuillez essayer à nouveau.",
  "applicant-request-form-applicant-detail": "Détails du candidat",
  "applicant-request-form-add-applicant": "Ajouter un nouveau candidat",
  "applicant-request-form-modify-applicant": "Modifier un candidat",
  "applicant-request-form-no-visit-agent": "Aucun agent pour le moment",
  "applicant-request-form-visit-date-empty": "Aucune date de visite prévue",
  "applicant-request-form-signature-date-empty": "Aucune date de signature prévue",
  "applicant-request-form-down-payment-amount-empty": "Aucun acompte versé",
  "applicant-request-form-visit-date-completed-empty": "Visite non terminée",
  "applicant-request-add-applicant-fail": "Une erreur est survenue lors de la sauvegarde de votre application. Veuillez essayer à nouveau.",
  "applicant-request-add-applicant-validation-fail": "Certain champs sont manquant pour sauvegarded l'application. Veuillez les remplir.",
  "applicant-request-form-add-applicant-success": "Votre application a été sauvegardé",
  "applicant-request-form-add-applicant-status-updated": "Le status a été sauvegardé",
  "applicant-request-form-section-information-title": "Information du candidat",
  "applicant-request-form-section-information-subtitle": "Données personnelles et candidature.",
  "applicant-request-form-section-information-description": "Description",
  "applicant-request-form-section-information-nas": "NAS (Optionelle)",
  "applicant-request-form-section-information-nas-invalid": "Le nas est invalide ex: (123 123 123)",
  "applicant-request-form-section-information-name-required": "Le nom est requis",
  "applicant-request-form-section-information-date-invalid": "La date de naissance est invalide",
  "applicant-request-form-section-information-phone-invalid": "Le numéro de téléphone est invalide ex: (123-123-1234)",
  "applicant-request-form-section-information-email-invalid": "Le courriel est invalide ex: abc@gmail.com",
  "applicant-request-form-section-information-details-spouse": "Conjoint",
  "applicant-request-form-section-information-details-roommate": "Coloc",
  "applicant-request-form-section-information-details-children": "Enfants",
  "applicant-request-form-section-information-details-dog": "Chien",
  "applicant-request-form-section-information-details-cat": "Chat",
  "applicant-request-form-section-information-details-employer-name": "Nom du Gérant",
  "applicant-request-form-section-information-details-employer-phone": "Téléphone du Gérant",
  "applicant-request-form-section-information-details-number-resident": "Nombre de résident dans l'unité",
  "applicant-request-form-section-information-details-number-parking": "Nombre de stationnement demandés",
  "applicant-request-form-section-information-details-number-resident-invalid": "Le nombre de résident est invalide",
  "applicant-request-form-section-information-details-number-parking-invalid": "Le nombre de stationnement est invalide",
  "applicant-request-form-section-information-address-title": "Adresse actuelle",
  "applicant-request-form-section-information-address-rent": "Loyer",
  "applicant-request-form-section-information-address-occupation-period": "Période d'occupation",
  "applicant-request-form-section-information-address-landlord": "(Optionnel) Propriétaire",
  "applicant-request-form-section-information-address-light": "Éclairé",
  "applicant-request-form-section-information-job-employe": "Employeur",
  "applicant-request-form-section-information-job-full-time": "Temps plein",
  "applicant-request-form-section-information-job-part-time": "Temps partiel",
  "applicant-request-form-section-information-job-permanent": "Permanant",
  "applicant-request-form-section-information-job-contractual": "Contractuel",
  "applicant-request-form-section-information-job-start-date-employment": "Employé depuis",
  "applicant-request-form-section-information-job-number-of-hours": "Nombre d'heure par semaine",
  "applicant-request-form-section-information-job-number-of-hours-required": "Nombre d'heure par semaine est requis",
  "applicant-request-form-section-information-details-salary-required": "Le salaire est requis",
  "applicant-request-form-section-information-details-salary-interval": "Paiement du salaire",
  "applicant-request-form-section-button-approve-candidate": "Approver le candidat",
  "applicant-request-form-section-button-refuse-candidate": "Refuser la demande",
  "applicant-request-form-section-button-finalise-request": "Finaliser la demande",
  "applicant-form-is-new-user": "Nouveau formulaire d'utilisateur",
  "confirm-modal-title": "Confirmer",
  "confirm-modal-body": "Êtes-vous sûr de vouloir créer un locataire sans courriel ni téléphone?",
  "confirm-modal-confirm-button": "Confirmer",
  "applicant-request-form-section-choice-applicant-address": "Adresse du candidat",
  "applicant-request-form-section-choice-applicant-address-help-text": "Recueillir les détails de l'adresse physique actuelle de l'utilisateur.",
  "applicant-request-form-section-choice-applicant-status": "Status du candidat",
  "applicant-request-form-section-choice-applicant-status-help-text": "Recueillir le statut de vie du candidat.",
  "applicant-request-form-section-choice-applicant-job": "Travail du candidat",
  "applicant-request-form-section-choice-applicant-job-help-text": "Recueillez les détails du poste du candidat tels que le salaire, le superviseur, etc.",
  "applicant-request-form-section-request-status": "Status de la requête",
  "applicant-request-form-section-appicant-issues": "Problèmes avec le candidat",
  "applicant-request-form-section-appicant-issues-not-fix": "Vous ne pouvez pas finaliser la demande tant que les problèmes suivants n'ont pas été résolus:",
  "applicant-request-form-section-appicant-issues-tenant-info-not-completed": "Informations sur le locataire non renseignées",
  "applicant-request-form-section-appicant-issues-no-building-selected": "Vous n'avez pas sélectionné de bâtiment",
  "applicant-request-form-section-finalize-request": "Finaliser la demande",
  "applicant-request-form-section-select-building": "Choisir un Bâtiment",
  "applicant-request-form-section-create-lease": "Créer le bail",
  "applicant-request-form-section-wating-for-unit-selected": "En attente de la sélection d'une unité.",
  "applicant-request-form-section-wating-for-building-selected": "En attente de la sélection d'un bâtiment",
  "applicant-request-list-down-payment-amount-received": "Montant reçu",
  "applicant-request-list-intern-approved": "Approuvé à l'interne",
  "applicant-request-list-owner-approved": "Approuvé par le propriétaire ",
  "applicant-request-force-owner-approval": "Forcer l'approbation du propriétaire",
  "applicant-request-list-visit-scheduled": "Visite programmée",
  "applicant-request-list-signature-scheduled": "Signature programmée",
  "applicant-request-list-down-payment-amount-received-as-down-payment": "Montant reçu à titre d'acompte",
  "applicant-request-list-visit-completed": "Visite terminée",
  "applicant-request-visit-approved": "Approuvé par l'agent?",
  "applicant-request-situation-detail-title": "Détails de la situation du candidat",
  "applicant-request-current-employer": "Employeur actuel",
  "applicant-request-form-banner-fulfilled": "Cette ressource est complétée. Vous ne pouvez plus la modifier.",
  "applicant-request-form-banner-cancelled": "Cette ressource a été annulée. Vous ne pouvez plus la modifier.",
  "applicant-request-empty-state": "Il y a aucun candidat pour le moment",
  "registration-delete-invite-success": "L'invitation a été supprimer",
  "registration-delete-invite-error": "Une erreur s'est produite. Lors de la tentative de suppression de l'invitation, veuillez réessayer ou contacter le support via l'outil de chat",
  "registration-resend-email-content": "Renvoyer l'invitation par e-mail",
  "registration-delete-invite-content": "Supprimer l'invitation",
  "registration-delete-modal-warning": "Cet invitation sera supprimer et l'invitation ne sera plus valide pour s'inscrire.",
  "registration-delete-modal-last-warning": "Supprimer l'invitation",
  "rental-video-link-title": "Video pour l'annonce",
  "rental-video-link-description": "La video que vous souhaitez ajouter à votre l'annonce.",
  "rental-video-link-video-field": "Lien du vidéo",
  "rental-video-link-error-video-link": "Le lien du vidéo est invalide",
  "rental-video-link-place-holder": "https://www.youtube.com/watch?v=oHg5SJYRHA0",
  "unit-application-title": "Liste des applications",
  "unit-application-created-date": "Date de création",
  "unit-application-visit-date": "Date de visite",
  "tenant-application-title": "Application au logement",
  "tenant-application-description": "Ce locataire à été créer à partir d'une application effectué le {{date}}",
  "tenant-application-link": "Voir les détails de l'application",
  "tenant-application-empty-state": "Il y a aucune application pour le moment",
  "lease-viewer-status-active": "Actif",
  "lease-viewer-status-de-not-renew": "Annulé",
  "unit-status-tenant-changing-status": "Il y aura un changement de locataire à la fin du bail actif.",
  "tickets-title": "Billets",
  "tickets-add": "Ajouter un nouveau Billet",
  "tickets-form-title": "Titre",
  "tickets-form-due-date": "Date d'échéance",
  "tickets-form-completed-date": "Date d'achèvement",
  "tickets-form-assigned-to": "Assigné à",
  "tickets-form-unit": "Unité",
  "tickets-form-tenant": "Contact locataire",
  "tickets-form-note": "Noter",
  "tickets-form-tags": "Mots clés",
  "tickets-form-property": "Propriété",
  "tickets-tab-all": "Tous",
  "tickets-tab-active": "Actif",
  "tickets-tab-resolved": "Résolu",
  "tickets-assigned": "est assigné",
  "ticket-detail-title": "Information du Billet",
  "ticket-detail-description": "Description",
  "ticket-detail-opened-by": "ouvert par",
  "ticket-detail-comments": "commentaires",
  "ticket-detail-created": "Créé le",
  "ticket-detail-assignees": "responsables",
  "ticket-detail-labels": "Étiquettes",
  "ticket-detail-edit": "Modifier",
  "ticket-confirm-delete": "Êtes-vous sûr de vouloir supprimer ce ticket?",
  "ticket-todo": "À faire",
  "ticket-doing": "En cours",
  "ticket-done": "Terminé",
  "ticket-filter-close": "Inclure les tâches fermées",
  "task-add-bill": "Ajouter une facture",
  "task-assign-to": "Assigné à {{assigned}}",
  "task-billed-for": "Facturé pour {{bill}}$",
  "task-due": "Dû le {{date}}",
  // FIXME: Translate the following props
  // TODO: Translate the following props
  // Start:
  "ticket-delete": "Supprimer le ticket",
  "tickets-edit": "Modifier le ticket",
  "tickets-status-filter": "Par statut",
  "tickets-form-origin": "Origine",
  "tickets-form-category": "Catégorie",
  "tickets-form-priority": "Priorité",
  "tickets-form-tenant-email": "Courriel du locataire",
  "ticket-add-summary": "Résumé du ticket",
  "ticket-add-origin": "Origine du ticket",
  "ticket-add-category": "Catégorie de ticket",
  "ticket-add-priority": "Priorité des tickets",
  "ticket-add-tenant-email": "Courriel du locataire",
  "tickets-table-title": "Titre",
  "tickets-table-resource": "Ressource",
  "tickets-table-category": "Catégorie",
  "tickets-table-priority": "Priorité",
  "tickets-table-modified": "Modifié",
  "tickets-table-created": "Date créée",
  "tickets-table-tasks": "Tâches",
  "tickets-table-conversations": "Conversations",
  "ticket-singular": "Billet",
  "ticket-plural": "Billets",
  "ticket-info-summary-placeholder": "Résumé du ticket...",
  "tickets-conversation-plural": "messages",
  "tickets-conversation-singular": "message",
  "tickets-conversation-none": "Pas de messages",
  "tickets-info-header-add-task-button": "Ajouter une tâche",
  "tickets-info-general-create-ticket-button": "Créer un ticket",
  "tickets-info-general-close-ticket-button": "Fermer le ticket",
  "tickets-info-general-completed-tasks": " tâches terminées",
  "tickets-info-general-created-by": "Créé par ",
  "tickets-info-general-parent-ticket": "Ticket: ",
  "tickets-info-general-origin": "Origine ",
  "tickets-info-general-opened-on": "Ouvert le ",
  "tickets-info-general-modified-on": "Dernière modification le ",
  "tickets-info-general-closed-on": "Fermé le ",
  "tickets-info-general-due-to": "À cause de ",
  "tickets-resource-selector-header": "Type de ressource",
  "tickets-info-general-deleted-on": "Supprimé le ",
  "ticket-sidebar-button-create": "Créer",
  "ticket-sidebar-category-error": "Choisir une catégorie!",
  "ticket-sidebar-resource-type-error": "Sélectionnez la ressource!",
  "ticket-sidebar-priority-error": "Sélectionnez la priorité!",
  "ticket-sidebar-title-error": "Le titre doit comporter au moins 6 caractères!",
  "events-title": "Événements",
  "events-description": "Gérer les événements récurrents",
  "event-form-add-event-success": "Le nouvel événement a été créé avec succès.",
  "event-form-add-event-fail": "Une erreur s'est produite lors de la création de l'événement. Veuillez réessayer.",
  "event-form-update-event-success": "L'événement a été mis à jour avec succès.",
  "event-form-update-event-fail": "Une erreur s'est produite lors de la mise à jour de l'événement. Veuillez réessayer.",
  "event-interval-badge-hourly": "toutes les heures",
  "event-interval-badge-daily": "journalier",
  "event-interval-badge-weekly": "hebdomadaire",
  "event-interval-badge-monthly": "mensuel",
  "event-interval-badge-yearly": "annuel",
  "events-page-title": "Événements",
  "events-page-create-button": "Créer un évènement",
  "events-page-edit-button": "Modifier un évènement",
  "events-list-end-period-first-part": "Après ",
  "events-list-end-period-second-part": " times",
  "events-list-end-period-never": "Jamais",
  "events-list-no-limit": "Sans limites",
  "events-list-column-title": "Titre",
  "events-list-column-interval": "Intervalle",
  "events-list-column-frequency": "La fréquence",
  "events-list-column-start": "Commencer à",
  "events-list-column-end": "Fini à",
  "events-list-plural": "événements",
  "events-list-singular": "événement",
  "event-sidebar-title-label": "Titre",
  "event-sidebar-title-placeholder": "Titre...",
  "event-sidebar-title-error": "Titre doit comporter au moins 6 symboles",
  "event-sidebar-category-label": "Catégorie",
  "event-sidebar-category-error": "Choisir une catégorie",
  "event-sidebar-priority-label": "Priorité",
  "event-sidebar-priority-error": "Sélectionnez la priorité",
  "event-sidebar-resource-type-label": "Type de ressource",
  "event-sidebar-resource-type-error": "Sélectionnez la ressource",
  "event-sidebar-resource-space": "Bâtiments",
  "event-sidebar-resource-user": "Utilisateur",
  "event-sidebar-resource-users-tenants": "Utilisateurs/Locataires",
  "event-sidebar-resource-project": "Projet",
  "event-sidebar-resource-lease": "Bail",
  "providers-title": "Fournisseurs",
  "providers-description": "Gérer les fournisseurs",
  "providers-preferred-contact-method": "Méthode de contact préférée",
  "providers-sidebar-preferred-contact-error": "Sélectionnez la méthode de contact préférée",
  "provider-add-new-provider": "Ajouter un nouveau fournisseur",
  "provider-list-no-files": "Aucun fichier",
  "provider-list-column-name": "Nom",
  "provider-list-column-email": "Email",
  "provider-list-column-phone": "Téléphone",
  "provider-list-column-notes": "Notes",
  "provider-list-column-files": "Fichiers",
  "provider-list-singular": "fournisseur",
  "provider-list-plural": "fournisseurs",
  "provider-sidebar-section-title-edit": "Modifier le fournisseur",
  "provider-sidebar-section-title-create": "Créer un fournisseur",
  "provider-sidebar-name-label": "Nom",
  "provider-sidebar-name-placeholder": "Nom...",
  "provider-sidebar-name-error": "Le nom doit comporter au moins 6 caractères",
  "provider-sidebar-email-label": "Email",
  "provider-sidebar-email-placeholder": "Email...",
  "provider-sidebar-email-error": "Email invalide",
  "provider-sidebar-phone-label": "Téléphoner",
  // FIXME: End
  "renewal-form-title": "Sélectionnez votre prix de renouvellement",
  "renewal-form-description": "Prix ​​actuel: ",
  "renewal-simple-form-title": "Renouveler le bail",
  "renewal-simple-form-description": `Vous pouvez modifier le prix du renouvellement. Pour d'autres modifications sur le bail, sélectionnez "Activer le mode avancé".`,
  "renewal-simple-section-title": "Information du bail",
  "renewal-form-price-pourcentage-title": "Utiliser l'augmentation en pourcentage",
  "renewal-form-price-pourcentage-description": "Le nouveau prix sera calculé à partir de l'ancien prix et du pourcentage",
  "renewal-form-price-fix-title": "Entrer manuellement le montant",
  "renewal-form-price-fix-description": "Entrez le nouveau prix au complet",
  "renewal-form-price-field-pourcentage": "Augmentation en pourcentage",
  "renewal-form-price-field-manual": "Nouveau prix",
  "renewal-form-price-renewal": "Prix du renouvellement: ",
  "unit-renew-lease": "Renouveler le bail",
  "renewal-form-date-title": "Sélectionnez vos dates de renouvellement",
  "renewal-form-date-description": "Actuellement du ",
  "renewal-form-date-extend": "Prolonger d'un an",
  "renewal-form-price-increase": "Augmentation:",
  "renewal-form-date-manual": "Choisissez manuellement les dates",
  "renewal-form-date-renew-form": "Renouvellement du ",
  "renewal-form-modal-title": "Renouveler le bail",
  "renewal-form-toggle-title": "Sélectionnez le type de renouvellement",
  "renewal-form-toggle-description": "Le mode avancé permet l'ajout de notes et fichiers",
  "renewal-form-toggle-simple": "Simplifié",
  "renewal-form-toggle-advance": "Avancé",
  "renewal-form-toggle-activate-simple": "Activer le mode simplifié",
  "renewal-form-toggle-activate-advance": "Activer le mode avancé",
  "renewal-form-toggle-current-mode": "Le bail est présentement en mode:",
  "renewal-submit-proposal": "Envoyer la proposition au locataire",
  "renewal-force-renewal": "Forcer le renouvellement sans confirmation",
  "renewal-cancel-proposal": "Annuler le renouvellement et expirer le bail",
  "renewal-confirm-force-renewal": "Êtes-vous sûr de vouloir renouveler le bail sans confirmation du locataire?",
  "renewal-confirm-cancel": "Êtes-vous sûr de vouloir mettre fin à ce bail?",
  "renewal-form-saved-success": "La proposition de renouvellement a été envoyée au(x) locataire(s)",
  "renewal-form-renewed-success": "Le bail a été renouvelé",
  "renewal-form-saved-fail": "Une erreur s'est produite lors de l'enregistrement du renouvellement. Veuillez réessayer.",
  "renewal-cancel-saved-success": "Le bail a expiré",
  "renewal-cancel-saved-fail": "Échec de l'expiration du bail",
  "tab-to-renew": "À renouveler",
  "tab-active-renewals": "Renouvellements actifs",
  "renewal-status-created": "Renouvellement créé",
  "renewal-status-email-sent": "Proposition de renouvellement envoyée",
  "renewal-status-email-opened": "Proposition de renouvellement Ouvert",
  "renewal-status-email-failed": "Échec de l'e-mail",
  "renewal-form-renewal-details": "Détails du renouvellement",
  "upcoming-renewal": "Renouvellement à venir",
  "upcoming-lease": "Renouvellement à bail",
  "renewal-pending-tenant-approval": "Renouvellement en attente, état :",
  "upcoming-modification": "Modification à venir",
  "tab-renewal-sent": "Renouvellement envoyé",
  "tab-cancelled": "Annulé",
  "tab-all-leases": "Tous les baux",
  "days-remaining": "{{days}} Jours restant au bail",
  "lease-status": "État du bail: {{leaseStatus}}",
  "applicant-request-lease-lock": "Verrouiller",
  "applicant-request-lease-un-lock": "Déverrouiller",
  "applicant-request-lease-confirm-modal": "Est-ce que vous êtes sûr de vouloir modifier le bail?",
  "applicant-request-cosigner": "Cette application demande un cosignataire",
  "applicant-request-option-bad-credit-score": "Mauvais score de crédit",
  "applicant-request-option-listing-no-longer-available": "Listing non disponible",
  "applicant-request-list-owner-pending": "Propriétaire en attente",
  "applicant-request-form-section-button-approve-candidate-by-owner": "Envoyer au propriétaire",
  "applicant-request-form-section-button-resend-to-owner": "Renvoyer au propriétaire",
  "applicant-request-option-refusal-of-visit": "Refusé par l'agent de visite",
  "applicant-request-option-refusal-of-owner": "Refusé par le propriétaire",
  "applicant-request-option-refusal-of-pass-restricted": "Passe d'application à été restreint",
  "applicant-request-option-other": "Autre",
  "applicant-request-refused-on": "Cette demande a été refusé le",
  "applicant-request-completed-on": "Cette demande a été complété le",
  "applicant-entry-date-requested": "Date de possesion demandé",
  "applicant-sent-to-landlord-date": "Date d'envoi au propriétaire",
  "applicant-landlord-approved-date": "Propriétaire agréé à",
  "applicant-landlord-rejected-date": "Date de rejet du propriétaire",
  "applicant-agent-assigned-date": "Date d'affectation de l'agent de visite",
  "applicant-invite-code": "Code d'invite",
  "applicant-invite-code-tooltip": "Envoyez ce code pour inviter de nouveaux candidats. Cliquez pour copier",
  "applicant-status-pass-expired": "Laissez-passer expiré",
  "applicant-status-completed": "Complété",
  "applicant-status-pending-credit-check": "En attente du résultat de la vérification de crédit",
  "applicant-status-payment-pending": "Paiement en attente",
  "applicant-status-payment-succeeded-credit-check-unsent": "Paiement réussi Vérification de crédit non envoyée",
  "applicant-status-error": "Erreur",
  "applicant-status-manually-added": "Ajouté manuellement",
  "applicantion-step-pm-accepted": "Dossier accepté",
  "applicantion-step-visit-accepted": "Visite concluante",
  "applicantion-step-owner-accepted": "Approuvé par le propriétaire",
  "applicantion-step-documents-uploaded": "Documents",
  "applicant-ranking": "Classement des candidats",
  "applicant-ranking-tooltip": "Les candidats seront soumis au propriétaire en fonction du classement dans cette file d'attente",
  "applicant-ranking-submitted-to-owner": "Soumis au propriétaire",
  "applicant-ranking-submission-date": "Date de soumission",
  "applicant-ranking-waiting-list": "File d'attente des candidats (sera soumise au propriétaire en fonction du rang)",
  "applicant-ranking-remaining": "Candidats restants",
  "applicant-ranking-score": "Score",
  "applicant-ranking-listing-header": "Toutes les applications sur cette liste",
  refuse: "Refuser",
  reopen: "Rouvrir",
  score: "Score",
  received: "Reçus",
  consents: "Consentements",
  "application-consent-maximum_number_of_pets": "Animaux",
  "application-consent-maximum_number_of_tenants": "Nombre de personnes au logement",
  "application-consent-minimum_salary": "Revenu annuel",
  "application-consent-parking_spots_included_in_rent": "Stationnement Disponible",
  "application-consent-credit_check": "Vérification au crédit",
  "application-consent-terms_of_services": "Conditions d'utilisation",
  "application-consent-is_non_smoking": "Non fumeur",
  "applicant-request-cancel-reason": "La raison du refus",
  "applicant-request-enter-name": "Entrer un nom",
  "applicant-request-add-applicant-tab": "Ajouter un autre candidat",
  "applicant-request-external": "Ajouté de l'externe",
  "applicant-request-internal": "Ajouté de l'interne",
  "weekly-actions-title": "Actions des 7 derniers jours",
  "widget-chart-no-data-found": "Aucune donnée présente pour la période.",
  "PropertyUnion!": "Bâtiment",
  RentalRequest: "Candidats",
  Project: "Clients",
  Deposit: "Dépôts",
  maintenance: "Entretien",
  prevent: "Préventif",
  management: "Gestion",
  rental: "Location",
  coproperty: "Co-Propriété",
  administration: "Administration",
  "payment-issue": "Problème de paiement",
  payment_issue: "Problème de paiement",
  other: "Autre",
  Tenant: "Locataires",
  Unit: "Unités",
  Organization: "Organization",
  User: "Utilisateurs",
  Lease: "Baux",
  Registration: "Invitations",
  "audit-updated": "Mise à jour",
  "audit-created": "à créer cette ressource",
  "audit-title": "Derniers évènements",
  "change-from": " à été changer de ",
  "audit-to": " à ",
  "hide-detail": "Cacher les détails",
  "view-detail": "Voir les détails",
  "primary-phone-mobile": "Téléphone principale est mobile",
  "secondary-phone-mobile": "Téléphone secondaire est mobile",
  "user-preference-notification-label": "Notifications du système",
  "change-from-tag": "Les mots clés ont été modifié pour: ",
  "all-tags-been-deleted": "Toutes les mots clés ont été supprimé",
  "profile-menu-item": "Votre profile",
  "profile-description": "Gérer vos informations de contacts et vos paramètres de notifications",
  "organization-menu-item": "Votre organization",
  "organization-add": "Ajouter une organisation",
  "organization-description": "Gérer vos paramètres d'affaires, utilisateurs, fournisseurs, intégrations et autre",
  "organization-tab-team-members": "Employées",
  "organization-tab-pending-invites": "Invitations en attentes",
  "organization-tab-settings": "Paramètres",
  "settings-label-name": "Nom",
  "settings-label-address": "Adresse",
  "settings-label-email": "Courriel",
  "settings-label-phone": "Téléphone",
  "settings-amount-day-renewal-label": "Nombre de jours avant l'échéance d'un bail pour le renouvellement",
  "settings-automatically-unpublish-label": "Retirer automatiquement une annonce lorsqu'un bail est remplis",
  "settings-new-applicant-label": "Recevoir des notifications de demandes de nouveaux candidats par Courriel",
  "settings-listing-url-label": "URL pour le site web d'annonces personalisées",
  "settings-feature-client-management-label": "Gestion de clients",
  "settings-feature-deposit-tracking-label": "Suivit des dépôts",
  "settings-information-header": "Informations sur l'organisation",
  "settings-information-description": "Configurez la manière dont vos informations sont affichées pour vos utilisateurs et locataires.",
  "settings-header": "Paramètres",
  "settings-description": "Configurez la manière dont le système interagit avec vos données.",
  "settings-features-header": "Fonctionnalités",
  "settings-features-description": "Activez certaines fonctionnalités qui changeront le fonctionnement de SimpleLoyer dans votre organisation",
  "settings-updated": "Vos changements on été sauvegardés",
  "building-added-responsible": "Un responsables du bâtiment a été ajouté: ",
  "applicant-confirm-finalize-modal": "Est ce que vous êtes sûr de vouloir finaliser la demande?",
  "applicant-confirm-delete-modal": "Est ce que vous êtes sûr de vouloir supprimer se candidat de la demande?",
  "applicant-confirm-approve-modal": "Vous pouvez ajouter une note supplémentaire pour le propriétaire et sélectionner les fichiers à joindre",
  "applicant-confirm-approve-modal-files-header": "Fichiers à joindre à un e-mail",
  "applicant-confirm-invite-with-email": "Invitez par courriel",
  "applicant-confirm-bypass-queue": "Contourner la file d'attente",
  "building-management-date": "Date de gestion",
  "listings-title": "Annonce",
  "amenities-add-placeholder": "Ajouter des commodités...",
  "amenities-title": "Commodités",
  "amenities-description": "Choisissez les commodités disponibles pour cette ressource. Vos choix seront utilisés automatiquement lorsque vous créerez des baux et des annonces en lien avec cette ressource.",
  "amenities-add-unit": "Ajouter des commodités à l'unité",
  "amenities-add-description": "Rechercher dans la boite ci-dessous pour des éléments à ajouté",
  "amenities-add-building": "Ajouter des commodités au bâtiment",
  "amenities-empty-state": "Il n'y a pas de commodités pour cette ressource",
  "listings-singular": "Annonce",
  "listings-plurial": "Annonces",
  "listings-heading-list": "Annonces publiées",
  "listings-add": "Ajouter une annonce",
  "marketing-title": "Annonces & Candidats",
  "listings-empty-state": "Aucune annonce est publié pour le moment",
  "listings-available-on": "Disponible le",
  "help-page-title": "Support",
  "help-page-description": "Vous avez des questions ou commentaires ? Laissez notre équipe s’en charger.",
  "help-page-card-1-title": "Messagerie instantanée et courriel",
  "help-page-card-1-description": "Écrivez à notre équipe pour obtenir du support en ligne. Nous répondons entre 10 am et 4 pm heure normale de l’est.",
  "help-page-card-1-action": "Nous contacter",
  "help-page-card-2-title": "Base de connaissance",
  "help-page-card-2-description": "Trouvez vos réponses à l’aide de nos articles construits pour vous aider à utiliser la plateforme Simple Loyer.",
  "help-page-card-2-action": "Lire nos articles",
  "help-page-faq-title": "Foire aux questions",
  "help-page-faq-item-1-q": "Qu’est-ce que Simple Loyer?",
  "help-page-faq-item-1-awnser": "Simple Loyer est un logiciel de gestion immobilière. En utilisant Simple Loyer, vous pourrez gérer vos bâtiments, vos locataires, les candidats pour vos unités vides et plus encore.",
  "help-page-faq-item-2-q": "Comment est-ce que je peux m’inscrire?",
  "help-page-faq-item-2-awnser": "Simple Loyer n’est pas encore ouvert pour le grand public, nous offrons l’accès à la plateforme en exclusivité à nos clients partenaires. Vous pouvez communiquer avec nous si vous souhaitez devenir un de nos partenaires. Si vous êtes propriétaire et que votre gestionnaire utilise Simple Loyer, demandez à votre gestionnaire de vous envoyer une invitation.",
  "help-page-faq-item-3-q": "Je souhaite proposer une nouvelle fonctionnalité.",
  "help-page-faq-item-3-awnser": "Nous aimons entendre vos idées! Écrivez-nous à support@simpleloyer.ca.",
  "help-page-faq-item-4-q": "J’ai invité le mauvais utilisateur à se joindre à une page client.",
  "help-page-faq-item-4-awnser": "Vous pouvez retirer l’accès de l’utilisateur invité par erreur en naviguant dans la page du client en question, puis en cliquant sur «Retirer l’accès» dans la section utilisateur se trouvant à droite sous l’information du client.",
  "help-page-faq-item-5-q": "Je souhaite réactiver un candidat qui s’était désisté.",
  "help-page-faq-item-5-awnser": "La fonctionnalité pour réactiver un candidat sera implantée sous peu. Pour l’instant, il vous faudra créer ce candidat à nouveau.",
  "help-page-faq-item-6-q": "Pourquoi est-ce que mon gestionnaire me demande de me créer un compte et de me connecter à Simple Loyer?",
  "help-page-faq-item-6-awnser": "C’est une excellente nouvelle, cela signifie que votre gestionnaire utilise la plateforme Simple Loyer pour le suivi de vos immeubles. Grâce à Simple Loyer, vous serez en mesure de suivre les actions de votre gestionnaire en lien avec vos bâtiments.",
  "help-page-faq-item-7-q": "J’engage un gestionnaire pour mes immeubles, est-ce que je peux utiliser Simple Loyer?",
  "help-page-faq-item-7-awnser": "Simple Loyer est un outil principalement pour les gestionnaires. Vous pouvez vous inscrire pour si vous souhaitez avoir un outil de gestion, mais vous n’utiliserez pas la plateforme à son plein potentiel. Recommandez plutôt Simple Loyer à votre gestionnaire. Vous aurez alors accès gratuitement à la plateforme pour suivre les actions de votre gestionnaire.",
  "help-page-faq-item-8-q": "Le gestionnaire de mes immeubles utilise Simple Loyer, est-ce que je dois payer pour avoir accès moi aussi?",
  "help-page-faq-item-8-awnser": "Non, votre gestionnaire s’occupe de cette partie, vous pouvez demander à votre gestionnaire de vous inviter pour suivre les actions en lien avec vos bâtiments.",
  "login-remember-me": "Se souvenir de moi",
  "login-forgot-password": "Mot de passe oublié?",
  "login-continue-with": "Ou continuer avec",
  "login-welcome": "Bienvenue sur",
  "login-sign-up-account": "inscrivez-vous gratuitement !",
  "rental-publish-success": "L'annonce à été publié avec succès",
  "rental-publish-error": "Une erreur est survenue lors de la publication de votre annonce. Veuillez essayer à nouveau.",
  "rental-unpublish-success": "L'annonce à été retiré avec succès",
  "rental-unpublish-error": "Une erreur est survenue lors de la retrait de votre annonce. Veuillez essayer à nouveau.",
  "lease-page-title": "Créer un bail",
  "lease-page-action": "Créer un bail",
  "lease-page-tab-detail": "Détails",
  "lease-page-tab-tenant": "Locataires ({{number}})",
  "lease-page-tab-amenities": "Commodités ({{number}})",
  "lease-page-form-property-title": "Propriété",
  "lease-page-form-property-description": "Sélectionnez l'unité et le bâtiment auquel vos locataires appartiendront",
  "lease-page-form-delivery-title": "Livraison",
  "lease-page-form-delivery-description": "Choisissez comment vous voulez que ce bail soit traité dans le système",
  "lease-page-form-payment-title": "Paiement",
  "lease-page-form-payment-description": "Déterminez comment le locataire vous paiera pour la durée de ce bail",
  "lease-page-form-date-title": "Date",
  "lease-page-form-date-description": "Fixez la date de début du bail et sa durée",
  "lease-page-form-date-one-year-start-date-title": "1 an à compter de la date de début",
  "lease-page-form-date-one-year-start-date-description": "Votre bail se terminera exactement un an après la date de début initiale",
  "lease-page-form-date-custom-start-date-title": "Dates de début et de fin personnalisées",
  "lease-page-form-date-custom-start-date-description": "La sélection de cette option vous permettra de sélectionner une plage pour votre date de début et votre date de fin",
  "lease-page-form-date-start-on": "Votre bail débutera le ",
  "lease-page-form-date-and-finish-on": " et terminera le ",
  "lease-page-form-date-days": " ({{days}}) Jours",
  "lease-page-amenities-select-title": "Sélectionnez vos commodités",
  "lease-page-amenities-select-description": "Choisissez les commodités que vous souhaitez inclure dans ce bail. Vous pouvez éventuellement attribuer un prix aux équipements que vous attribuez en cliquant sur les sections ci-dessous",
  "lease-page-form-tenant-remove": "Retirer",
  "lease-page-form-tenant-select-placeholder": "Chercher un locataire du système",
  "lease-page-form-tenant-add-tenant": "Créer un nouveau locataire",
  "lease-page-form-property-select-title": "Sélectionnez un bâtiment",
  "lease-page-form-property-select-placeholder": "Choisissez un bâtiment",
  "lease-page-form-property-select-select-unit": "Sélectionnez une unité",
  "lease-page-details-pane-selected": "{{number}} Choisi",
  "lease-page-details-addons": "Ajout",
  "lease-page-details-lease-detail": "Détails du bail",
  "lease-page-details-pricing": "Prix",
  "lease-payee-checkbox": "Débiteur",
  "lease-form-cancel-lease": "Annulé le bail",
  "lease-form-cancel-lease-description": "Cette action annulera le bail à la date de fin du bail",
  "lease-form-cancel-lease-checkbox-label": "L'annulation aura lieu plus tôt",
  "rental-publish-ad-description": "En publiant l'annonce elle sera visible par tout le monde sur votre site web.",
  "rental-remove-ad-description": "Cette annonce ne sera plus accessible au public.",
  "rental-modify-success": "L'annonce à été modifié avec succès",
  "listing-add-feature-image": "Ajouter une image en vedette",
  "listing-information": "Informations",
  "listing-description": "Descriptions",
  "admin-dashboard-title": "Tableau de bord administrateur",
  organization: "organisation",
  organizations: "organisations",
  "organization-form-saved-success": "L'organisation a été enregistrée.",
  "organization-form-saved-fail": "Une erreur s'est produite lors de l'enregistrement de l'organisation. Veuillez réessayer.",
  "organization-form-modify": "Modifier l'organisation",
  "organization-form-create": "Ajouter une nouvelle organisation",
  "organization-form-main-header": "Profil de l'organisation",
  "organization-form-main-description": "Configurer la façon dont le profil est affiché pour les utilisateurs et les locataires",
  "organization-form-address-header": "Adresse de l'organisation",
  "organization-form-address-description": "Modifier l'adresse associée à cette organisation",
  "organization-form-phone-header": "Organisation Contact",
  "organization-form-phone-description": "Modifier les coordonnées associées à cette organisation",
  "organization-form-settings-header": "Paramètres de l'organisation",
  "organization-form-settings-description": "Modifier les paramètres associés à cette organisation",
  "login-error-org-missing": "Échec de la récupération des organisations",
  "notification-panel-title": "Avertissez vos locataires",
  "notification-panel-subtitle": "Choisissez le filtre qui avertira les locataires par courriel.",
  "notification-panel-cat-clients": "Clients",
  "notification-panel-cat-tags": "Mots clés",
  "notification-panel-cat-buildings": "Bâtiment",
  "notification-panel-cat-tenants": "Locataires",
  "notification-panel-select-unit": "Toutes les unités",
  "notification-panel-placeholder-clients": "Sélectionnez un client...",
  "notification-panel-placeholder-tags": "Tapez quelque chose et appuyez sur Entrée...",
  "notification-panel-rich-text-title": "Contenue du courriel",
  "notification-panel-action": "Continuer",
  "notification-panel-select-clients": "Filtrer les locataires par client",
  "notification-panel-select-buildings": "Filtrer les locataires par bâtiment",
  "notification-panel-select-tags": "Filtrer les locataires par mot clé de bâtiment",
  "notification-panel-select-tenants": "Sélectionnez une liste de locataires",
  "notification-menu-action-title": "Envoyer un message",
  "notification-success": "Le message a été envoyer à {{tenant}} personnes",
  "notification-failure": "Une erreur est survenue lors de l'envoie du message",
  "notification-empty-state": "Aucune communication n'a été envoyée pour le moment",
  "notification-choose-language": "Choisissez la langue du message",
  communication: "Communication",
  communications: "Communications",
  CommunicationResult: "Communications",
  "communication-list-title": "Liste des communications",
  "communication-heading-communication-type": "Type de messages",
  "communication-heading-subject": "Sujets",
  "communication-heading-message": "Messages",
  "communication-heading-status": "Statuts",
  "communication-heading-sended-date": "Date d'envoie",
  "communication-heading-type-selection": "Ressource selectionnés",
  "communication-heading-user-who-send-message": "Envoyé par",
  "communication-type-custom": "Message personnalisé",
  "communication-type-template": "Message prédéfinie",
  "communication-status-opened": "Ouvert",
  "communication-status-delivered": "Livré",
  "communication-status-processed": "Traité",
  "communication-status-unsent": "Non envoyé",
  "communication-affected-people": "Personnes affectées :",
  "communication-subject-required": "Un sujet est requis",
  "user-form-modify": "Modifier l'utilisateur",
  "user-form-create": "Ajouter un utilisateur",
  "user-form-main-header": "Profil de l'utilisateur",
  "user-form-main-description": "Modifier les détails du profil utilisateur",
  "user-form-permissions-header": "Autorisations utilisateur",
  "user-form-permissions-description": "Modifier les niveaux d'accès pour cet utilisateur",
  "audit-note": "Une note à été ",
  "audit-files": "Un fichier à été ",
  payments: "Paiements",
  payment: "Paiement",
  amount: "Montant",
  "status-tentative": "Tentative",
  "status-erroneous": "Erroné",
  "status-processing": "Traitement",
  "status-completed": "Complété",
  "list-heading-payments": "Liste des paiements",
  "panel-title": "Information du paiement",
  "panel-payment-activity": "Activité des paiements",
  "panel-payment-created": "Paiement créé",
  "panel-payment-accepted": "Paiement accepté",
  "panel-payment-type": "Type de paiement",
  "panel-payment-type-automatic": "Paiement automatique",
  account: "Compte",
  "account-person-name": "Nom du titulaire du compte",
  "account-add-account": "Ajouter un compte",
  "account-finish-by": "Compte {{bankName}} finissant par {{accountNumber}}",
  "account-form-title": "Ajoutez un compte",
  "account-form-section-title": "Veuillez entrer les informations du compte",
  "account-form-transit-number": "Numéro de transit",
  "account-form-institution-number": "Numéro d'institution",
  "account-form-bank-account-number": "Numéro de compte",
  "account-field-invalid": "Le champ est invalide",
  "no-account-linked": "Aucun compte n'a été lié",
  "lease-page-tab-history": "Historique",
  "lease-status-active": "Actif",
  "lease-status-not-renewed": "Non renouvelé",
  "lease-status-request-in-flight": "Request In-Flight",
  "lease-status-renewal-request-awaiting-client-approval": "Demande de renouvellement en attente d'approbation du client",
  "lease-status-display-active": "Le bail est actif",
  "lease-status-display-not-renewed": "Le bail ne sera pas renouvelé",
  "lease-status-display-request-in-flight": "Le bail est RequestInFlight",
  "lease-status-display-renewal-request-awaiting-client-approval": "Le bail est en demande de renouvellement en attente d'approbation du client",
  "building-info-only-location": "Vous géré uniquement la location pour ce client.",
  "occupation-title-widget": "Occupancy rate per unit",
  "applicant-request-form-section-information-address-occupation-date": "Date d'occupation demandée",
  "applicant-title-widget": "Candidate status",
  "applicant-cancel": "Candidat refuser",
  "lease-viewer-new-item": "Nouveau",
  "applicant-approved": "Approved candidate",
  "applicant-approved-by-owner": "Owner approved candidate",
  "applicant-visit-scheduled": "Scheduled visit",
  "occupation-180-days": "180 Days",
  "occupation-90-days": "90 Days",
  "occupation-60-days": "60 Days",
  "occupation-30-days": "30 Days",
  "occupation-vacant": "Vacant Units",
  "lease-renewal-title-widget": "Lease renewal sent",
  "lease-renewal-seen-not-answered": "Renewal seen in last 30 days but not answered",
  "lease-renewal-unseen": "Renewal still unseen",
  "lease-renewal-not-renrewing": "Tenant will not renew",
  "lease-renewal-will-renew": "Tenant will renew",
  "ticket-title-widget": "Ticket",
  "ticket-pending": "Pending",
  "ticket-unanswered": "New unanswered ",
  "ticket-unsolved": "Unsolved",
  "source-application-title-widget": "Source of application",
  "source-application-intern": "Intern",
  "source-application-external-website": "External website",
  "source-application-external-facebook": "Facebook",
  "source-application-external-kijiji": "Kijiji",
  "listings-title-widget": "Number of applicant and Listings",
  "listings-listing-created": "Number of listings",
  "listings-number-applicant": "Number of applicant",
  "listings-number": "Number of",
  "listings-date": "Date",
  "selector-label": "Modèles d'e-mails",
  "selector-label-snow-removal": "Déneigement",
  "selector-label-air-conditioning": "Air climatisé",
  "selector-label-de-ice": "Déglaçage",
  "selector-label-exterminator": "Extermination",
  "selector-label-office-closing": "Fermeture des bureaux temps des fêtes",
  "selector-label-rental-agent": "Agent d’immeuble",
  "selector-label-smoke-detector": "Détecteur de fumée",
  "applicant-request-status-active": "Actif",
  "applicant-request-status-approved": "Approuvé",
  "applicant-request-status-fulfilled": "Complété",
  "applicant-request-status-incomplete": "Incomplète",
  "applicant-request-status-cancelled": "Annulé",
  "applicant-request-status-denied": "Refusé",
  "no-hit": "Aucun succès",
  "lease-state-needs-renewal": "Besoin de renouvellement",
  "lease-state-initial": "Initiale",
  "lease-state-archived": "archivé",
  "choose-option": "Veuillez choisir une option",
  "applicant-request-form-section-finalize-description": "Entrez les détails du bail",
  "applicant-request-form-section-finalize-success": "La finalisation s'est terminée avec succès",
  "applicant-request-form-section-finalize-failiure": "Une erreur s'est produite lors de la finalisation de cette application. Veuillez réessayer.",
  "applicant-request-form-section-finalize-conflict": "Il y a un autre bail sur cette unité avec une durée qui se chevauche",
  "applicant-request-form-section-unique-constraint": "Il y a déjà un candidat avec cet email ajouté",
  "applicant-request-form-section-delete-last": "Impossible de supprimer uniquement le candidat sur candidature.",
  "applicant-request-add-applicant-description": "Créez manuellement un candidat ou sélectionnez un utilisateur existant pour la liste active sur cette unité",
  [FeatureType.FurnitureCommodes]: "Commodes",
  [FeatureType.SmallDogsAllowed]: "Petits chiens acceptés",
  [FeatureType.LargeDogsAllowed]: "Grands chiens acceptés",
  [FeatureType.CatsAllowed]: "Chats acceptés",
  [FeatureType.SmokingAllowed]: "Autorisation de fumer",
  [FeatureType.ExtensionsGasHeating]: "Chauffage au gaz",
  [FeatureType.UndergroundGarage]: "Garage souterrain",
  [FeatureType.StreetParking]: "Stationnement Extérieur",
  [FeatureType.CoveredGarage]: "Garage couvert",
  [FeatureType.GarageParkingSingle]: "Garage stationnement simple",
  [FeatureType.GarageParkingDouble]: "Garage stationnement double",
  [FeatureType.GarageParkingTriple]: "Garage stationnement triple",
  [FeatureType.ExtensionsCentralAirconditioning]: "Climatisation centrale",
  [FeatureType.ExtensionsCableInternet]: "Câble internet",
  [FeatureType.ExtensionsElectricHeating]: "Chauffage électrique",
  [FeatureType.ExtensionsFiberInternet]: "Internet fibre",
  [FeatureType.DisabledAccess]: "Accès handicapés",
  [FeatureType.YardAccess]: "Accès cour",
  [FeatureType.BuildingElevator]: "Ascenseur du bâtiment",
  [FeatureType.BuildingGym]: "Accès salle de sport",
  [FeatureType.BuildingPool]: "Accès piscine ",
  [FeatureType.BuildingTerrace]: "Accès terrasse",
  [FeatureType.AppliancesJacuzzi]: "Accès Jacuzzi",
  [FeatureType.ExtensionsBalcony]: "Accès balcon",
  [FeatureType.ExtensionsCable]: "Câble inclus",
  [FeatureType.ExtensionsElectricity]: "Électricité inclus",
  [FeatureType.ExtensionsPortableAirconditioning]: "Climatisation portable inclus",
  [FeatureType.ExtensionsWater]: "Eau inclus",
  [FeatureType.ExtensionsWaterTank]: "Date d'installation du chauffe-eau",
  [FeatureType.ExtensionsCounterType]: "Type de comptoirs",
  [FeatureType.ExtensionsBlinds]: "Stores inclus",
  [FeatureType.LaundryRoomBuilding]: "Accès buanderie",
  [FeatureType.WasherDryerOutlet]: "Sortie laveuse sécheuse",
  [FeatureType.AppliancesLockerStorageSpace]: "Espace de rangement inclus",
  [FeatureType.AppliancesFurnitureLeaseAndIncludedInPrice]: "Meubles électroménagers en location est inclus dans le prix",
  [FeatureType.AppliancesStove]: "Poêle inclus",
  [FeatureType.AppliancesOven]: "Four inclus",
  [FeatureType.AppliancesMicrowave]: "Micro-ondes inclus",
  [FeatureType.AppliancesDishwasher]: "Lave-vaisselle inclus",
  [FeatureType.AppliancesRefrigerator]: "Réfrigérateur inclus",
  [FeatureType.AppliancesWasher]: "Laveuse inclus",
  [FeatureType.AppliancesDryer]: "Sècheuse inclus",
  [FeatureType.FurnitureNumberOfTables]: "Nombre de tables",
  [FeatureType.FurnitureNumberOfChairs]: "Nombre de chaises",
  [FeatureType.FurnitureNumberOfChestsOfDrawers]: "Nombre de commodes",
  [FeatureType.FurnitureNumberOfCouch]: "Nombre de canapé",
  [FeatureType.FurnitureNumberOfArmchair]: "Nombre de fauteuil",
  [FeatureType.FurnitureNumberOfBed]: "Nombre de lit",
  "consent-value-pet": "Animal autorisé",
  "consent-value-tenant": "Nombre limité de locataire",
  "consent-value-salary": "Revenu annuel minimum",
  "consent-value-parking": "Stationnement limiter",
  "consent-value-credit": "Vérification d'enquête de crédit",
  "consent-value-terms-service": "Accepter condition d'utilisation",
  "consent-value-smoking": "Interdiction de fumer",
  "consent-value-pet-input": "Nombre d'animaux autorisé",
  "consent-value-tenant-input": "Nombre de locataire autorisé",
  "consent-value-salary-input": "Revenu annuel minimum requis",
  "consent-value-parking-input": "Nombre de stationnement autorisé",
  "consent-value-credit-input": "Vérification d'enquete de crédit requis",
  "consent-value-terms-service-input": "Accepter condition d'utilisation requis",
  "consent-value-smoking-input": "Interdiction de fumer",
  "applicants-v2-file-tab-other": "Autre",
  "applicants-v2-file-tab-lease": "Bail",
  "applicants-v2-file-tab-insurance": "Assurance",
  "applicants-v2-file-tab-utility": "Utilitaire",
  "applicants-v2-file-tab-building_regulation": "Règlement d'imeuble",
  "applicants-v2-file-tab-signature_required": "À signer",
  "applicants-v2-file-tab-check": "Chèque",
  "applicant-request-progress-visit-accepted": "Visite acceptée",
  "applicant-request-progress-approved-internally": "Approuvé à l'interne",
  "applicant-request-progress-owner-approved": "Approuvé par le client",
  "applicant-request-progress-documents-uploaded": "Documents téléversés",
  "applicant-request-no-progress": "Aucun progrès",
  "applicant-request-progress-placeholder": "Progrès",
  "delivery-edit-title": "Modifier la livraison",
  "delivery-key-status": "Status clé",
  "delivery-key-delivered": "Clés livrées",
  "delivery-key-not-delivered": "Clés non livrées",
  "delivery-document-delivered": "Document livré",
  "delivery-document-not-delivered": "Document non livré",
  "delivery-document-status": "Status document",
  "delivery-downpayment-not-delivered": "Aucun acompte versé",
  "delivery-downpayment-partial": "Acompte partiellement reçus",
  "delivery-downpayment-received": "Acompte reçus",
  "delivery-marked-delivered": "Marquer comme livré",
  "delivery-form-success": "Votre livraison à été sauvegardé.",
  "delivery-form-error": "Une erreur est survenue lors de la sauvegarde de la livraison. Veuillez essayer à nouveau.",
  "lease-agent": "Agent de location",
  "applicant-request-form-visit-agent-title": "Agent de visite",
  "previous-tenant": "Locataire précédent",
  "applicant-request-list-lease-doc-sent": "Documents de bail envoyés au locataire",
  "ticket-empty-state-title": "Il n'y a pas encore de tâches",
  "ticket-empty-state-description": "Ajouter une tâche pour suivre les destinataires, la facturation et les dates d'échéance",
  "ticket-empty-state-cta": "Ajouter une tâche",
  "ticket-empty-state-cta-id": "Créer d'abord un ticket",
  "task-status": "État de la tâche",
  "calendar-filters": "Filtres de calendrier",
  "ticket-manual": "Manuel",
  "ticket-email": "Courriel",
  "ticket-recurring-event": "Événement récurrent",
  "ticket-user-submission": "Soumission de l'utilisateur",
  "type-entity": "Type d'entité",
  "type-ticket": "Type de billet",
  "event-title": "Event title",
  "create-recurring-ticket": "Créer un billet récurrent",
  "recurring-on": "Récurrent le {{date}}",
  "ticket-update-title": "Mettre à jour le ticket",
  "tickets-create-singular-title": "Créer une tâche",
  "tickets-create-singular-details": "Vous voulez créer une tâche et immédiatement l'assigner a un responsable",
  "tickets-create-multiple-title": "Créer un ticket avec multiples tâches",
  "tickets-create-multiple-details": "Vous avez un dossier à régler avec possiblement plusieurs tâches",
  "rent-roll-title": "Registre des loyer",
  "rent-roll-description": "Le registre des loyers indique le taux de location pour chacune des propriétés du propriétaire.",
  "applicant-request-button-choose-applicant": "Choisissez ce candidat",
  "applicant-request-button-has-been-choosen": "Ce candidat a été choisi pour cette unité",
  "applicant-request-status": "choisi"
};
export default fr;