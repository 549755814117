import { useLazyQuery } from "../../../../../_snowpack/pkg/@apollo/client.js";
import { DatePicker } from "../../../components/DatePicker.js";
import { Button } from "../../../components/Tailwind/Button.js";
import { Card } from "../../../components/Tailwind/Card.js";
import { ProtectedCard } from "../../../components/Tailwind/ProtectedComponents.js";
import { GetActiveLeaseDocument } from "../../../graphql/documents.js";
import { downloadFile } from "../../../helpers/files.js";
import useI18n from "../../../hooks/useI18n.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React, { useCallback, useState } from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";
export const ActiveLeaseWidget = () => {
  const {
    t
  } = useI18n();
  const [date, setDate] = useState(DateTime.now());
  const [leases, setLeases] = useState(null);
  const [getLease, {
    loading
  }] = useLazyQuery(GetActiveLeaseDocument, {
    onCompleted: ({
      statistics
    }) => {
      setLeases(statistics.activeLeases);
    }
  });
  const download = useCallback(async leasesData => {
    if (!leasesData) {
      return;
    }

    const authedDownloadURL = await downloadFile(leasesData);
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // Manage IE11+ & Edge
      window.navigator.msLaunchUri(authedDownloadURL);
    } else {
      fakeLink.setAttribute("href", authedDownloadURL);
      fakeLink.click();
    }
  }, [date]);
  const onClick = useCallback(() => {
    if (!leases) {
      getLease({
        variables: {
          date
        }
      });
      return;
    }

    download(leases);
  }, [date, download, getLease, leases]);
  const onDateChange = useCallback(value => {
    setDate(value);
    setLeases(null);
  }, []);
  return __cssprop(ProtectedCard, {
    title: t("active-lease-title"),
    permission: {
      kind: "Reporting",
      permission: "mutate:create"
    }
  }, __cssprop(Card.Section, null, t("active-lease-description")), __cssprop(Card.Section, {
    greyed: true
  }, __cssprop("div", {
    css: {
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "paddingRight": "1.2rem"
    }
  }, __cssprop(DatePicker, {
    id: "DashboardActiveLease",
    key: "DashboardActiveLease",
    defaultValue: date,
    onChange: onDateChange
  })), __cssprop("div", {
    css: {
      "alignSelf": "center",
      "alignItems": "center"
    }
  }, __cssprop(Button, {
    onAction: onClick,
    loading: loading,
    primary: !!leases
  }, leases ? t("download") : t("generate"))))));
};
export default ActiveLeaseWidget;