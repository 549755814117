import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';

import React, { useEffect, useMemo } from "../../../_snowpack/pkg/react.js";
import { ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache, Observable } from "../../../_snowpack/pkg/@apollo/client.js";
import { onError } from "../../../_snowpack/pkg/@apollo/client/link/error.js";
import { regenerateToken } from "../services/authentication.js";
import { SentryLink } from "../../../_snowpack/pkg/apollo-link-sentry.js";
import { getCurrentTimeInSeconds } from "../helpers/date.js";
import { Selectors, useAction, useSelector } from "../hooks/useRedux.js";
import { SessionActionsTypes } from "../store/artifacts/Session/types.js";
import { ApplicationActionsTypes } from "../store/artifacts/Application/types.js";
import introspection from "../graphql/possible-types.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
const typePolicies = {
  UserGQL: {
    keyFields: ["name", "id"]
  },
  FileResourceGQL: {
    keyFields: ["id", "fileName"]
  },
  NotesGQL: {
    keyFields: ["id"]
  },
  RentalRequestV2GQL: {
    keyFields: ["id"]
  },
  UnitGQL: {
    keyFields: ["id"]
  }
};
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore"
  },
  query: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  }
};
export const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    typePolicies
  }),
  defaultOptions
});
let isInitialized = false;
export const WithContextApolloProvider = ({
  children
}) => {
  const {
    dispatch
  } = useAction();
  const token = useSelector(Selectors.session.getToken);
  const time = useSelector(Selectors.session.getLastRequestTime);
  const authLink = useMemo(() => {
    return new ApolloLink((operation, forward) => {
      if (!token) {
        return forward(operation);
      }

      if (time > 0 && getCurrentTimeInSeconds() - time >= 7200) {
        console.info("Terminating session for inactivity");
        dispatch({
          type: SessionActionsTypes.ON_USER_LOGOUT,
          payload: null
        });
        return forward(operation);
      }

      operation.setContext(({
        headers
      }) => ({
        headers: { ...headers,
          authorization: token ? `Bearer ${token}` : ""
        }
      }));
      return forward(operation);
    });
  }, [dispatch, token, time]);
  const errorLink = useMemo(() => {
    return onError(({
      graphQLErrors,
      operation,
      forward
    }) => {
      if (graphQLErrors) {
        dispatch({
          type: ApplicationActionsTypes.SERVER_ERRORS,
          payload: graphQLErrors
        });
        const idTokenError = graphQLErrors.find(er => {
          return er?.extensions?.code === "UNAUTHENTICATED" && (er?.extensions?.response).statusCode === 401;
        });

        if (idTokenError) {
          return new Observable(observer => {
            regenerateToken().then(newToken => {
              // Our token is behind a TTL. If we dont get a response here, then we exit the user, his session has expired.
              // If we do have a token, then it means, his firebase token expired during his browsing time. We have to refresh and extend his journey
              if (token) {
                dispatch({
                  type: SessionActionsTypes.SESSION_SET_FIELD,
                  payload: {
                    token: newToken
                  }
                });
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: { ...oldHeaders,
                    authorization: `Bearer ${newToken}`
                  }
                });
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                };
                forward(operation).subscribe(subscriber);
              } else {
                console.info("Terminating session"); // dispatch({ type: SessionActionsTypes.ON_USER_LOGOUT, payload: null });
              }
            }).catch(error => {
              observer.error(error);
            });
          });
        }
      }

      return null;
    });
  }, [token]);
  const links = useMemo(() => from([new SentryLink({
    setTransaction: true
  }), errorLink, authLink, new HttpLink({
    uri: `${__SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL}/api`
  })]), [authLink]);
  useEffect(() => {
    client.setLink(links);
  }, [links]);

  if (!isInitialized) {
    // This is a work arround to be able to access Appolo Client outside of this class
    client.setLink(links);
    isInitialized = true;
  }

  return __cssprop(ApolloProvider, {
    client: client
  }, children);
};
export default WithContextApolloProvider;