function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import { Modal } from "../components/Tailwind/Modal.js";
import React, { useCallback, useMemo, useState } from "../../../_snowpack/pkg/react.js";
/**
 * Utility function to generate unique number per component instance
 */

import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const generateModalKey = (() => {
  let count = 0; // eslint-disable-next-line no-plusplus

  return () => `${++count}`;
})();

function useModal() {
  const key = useMemo(generateModalKey, []);
  const [isShown, setShown] = useState(false);
  const showModal = useCallback(() => setShown(true), []);
  const hideModal = useCallback(() => setShown(false), []);
  const getModal = useCallback(props => {
    const onClose = () => {
      if (props.onModalClose) props.onModalClose();
      hideModal();
    };

    return __cssprop(Modal, _extends({
      type: "destructive",
      key: key,
      open: isShown,
      onClose: onClose,
      large: true,
      title: props.title
    }, props), __cssprop("div", {
      className: "greyed-background"
    }, props.children));
  }, [isShown, hideModal]); // eslint-disable-next-line @typescript-eslint/no-empty-function

  return [getModal, showModal, hideModal];
}

export default useModal;