import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
export const findPaymentMethodForLease = ({
  tenants
}) => tenants?.items?.find(t => t.isPayee)?.paymentMethod; // should move this to somewhere more generic

export const getFormattedAmount = amount => (amount || 0).toLocaleString("fr-CA", {
  style: "currency",
  currency: "CAD"
});
export const getFormattedPriceForLease = ({
  monthlyPrice
}) => getFormattedAmount(monthlyPrice);
export const checkIfLeaseIsLocked = lease => {
  const isAfter = DateTime.fromISO(lease.startDate) > DateTime.now();

  if (isAfter) {
    return false;
  } // locked if created today


  return !lease.createdAt ? false : Math.floor(DateTime.fromISO(lease.createdAt).diff(DateTime.now(), "days").days) < 0;
};