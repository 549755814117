/* eslint-disable no-underscore-dangle */
import { TYPES } from "../../../helpers/files.js";
import { RESOURCES } from "../../../hooks/useFiles.js";
import { mapFiles } from "../../utils/Files.js";
import { mapNotes } from "../../utils/Notes.js";
import { all, getContext, put, select, takeLatest } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { AddProjectDocument, GetSingleProjectDocument, PropertyManagementContractType, UpdateProjectDocument } from "../../../graphql/documents.js";
import { ApplicationActionsTypes } from "../Application/types.js";
import { addFilesHandler, uploadDocuments } from "../Files/sagas.js";
import StatusActions from "../Status/actions.js";
import { getProject } from "./index.js";
import { initialState, ProjectActionsTypes } from "./types.js";

// =========== REDUCERS =========== //
const setInitialState = (data, state) => {
  if (data.payload.kind === "WithProjectId") {
    return { ...state,
      id: data.payload.projectId
    };
  }

  if (data.payload.kind === "WithContext") {
    return { ...state,
      ...data.payload.project
    };
  }

  if (data.payload.kind === "NoContext") {
    return initialState;
  }

  return state;
};

const setField = (data, state) => {
  return { ...state,
    ...data.payload
  };
}; // =========== NOTES =========== //


const addProjectNotes = (data, state) => ({ ...state,
  notes: [...state.notes, ...data.payload]
});

const removeProjectNotes = (data, state) => {
  return { ...state,
    notes: state.notes.filter(note => note.id !== data.payload)
  };
};

const toProjectInputMapper = project => {
  const {
    contactName,
    email,
    managementType,
    name,
    legalName,
    phoneInfo
  } = project.project;
  return {
    managementType: PropertyManagementContractType[managementType],
    name,
    contactName,
    legalName,
    email,
    phoneInfo
  };
}; // =========== SAGAS =========== //


function* updateExistingProject(id, project, files) {
  const client = yield getContext("client");
  yield put(StatusActions.setResourceToSubmit("project"));

  try {
    const query = client.query({
      query: UpdateProjectDocument,
      fetchPolicy: "no-cache",
      variables: {
        project: {
          id,
          project: toProjectInputMapper(project)
        }
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("project", "project-form-add-fail"));
    } else {
      yield addFilesHandler(files, data.updateProject.id, RESOURCES.Project);
      yield put({
        type: ProjectActionsTypes.PROJECT_SET_FIELD,
        payload: data.updateProject
      });
      yield put({
        type: ProjectActionsTypes.PROJECT_INITIALIZE,
        payload: {
          kind: "WithProjectId",
          projectId: data.updateProject.id
        }
      });
      yield put(StatusActions.setResourceToSuccess("project", "project-form-add-success"));
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("project", "project-form-add-fail"));
  }
}

function* createProject(project, files) {
  const client = yield getContext("client");
  const history = yield getContext("history");
  yield put(StatusActions.setResourceToSubmit("project"));

  try {
    const query = client.query({
      query: AddProjectDocument,
      fetchPolicy: "no-cache",
      variables: {
        project: toProjectInputMapper(project),
        parentOrgId: project.parentOrgId
      }
    });
    const {
      data,
      errors
    } = yield query;

    if (errors?.length > 0) {
      yield put(StatusActions.setResourceToError("project", "project-form-add-fail"));
    } else {
      yield addFilesHandler(files, data.addProject.id, RESOURCES.Project);
      yield put(StatusActions.setResourceToSuccess("project", "project-form-add-success"));
      history.push(`clients/${data.addProject.id}`);
    }
  } catch (e) {
    yield put(StatusActions.setResourceToError("project", "project-form-add-fail"));
  }
}

function* onSubmitForm(data) {
  const project = yield select(getProject);
  const files = yield uploadDocuments(data.payload.project.files || [], RESOURCES.Project, TYPES.Files);
  yield put({
    type: ProjectActionsTypes.PROJECT_INITIALIZE,
    payload: {
      kind: "WithContext",
      project: data.payload
    }
  });

  if (project.id) {
    yield updateExistingProject(project.id, data.payload, files);
  } else {
    yield createProject(data.payload, files);
  }

  yield put({
    type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
    payload: null
  });
}

function* onInitializeProject($data) {
  const client = yield getContext("client");
  const {
    payload
  } = $data;

  switch (payload.kind) {
    // case "WithContext":
    // State is already changed from reducer.
    case "WithProjectId":
      {
        yield put(StatusActions.setResourceToLoading("project"));
        const query = client.query({
          query: GetSingleProjectDocument,
          fetchPolicy: "no-cache",
          variables: {
            id: payload.projectId
          }
        });
        const {
          data,
          error
        } = yield query;

        if (error) {
          yield put(StatusActions.setResourceToError("project", "generic-fetch-error-message"));
        }

        yield put({
          type: ProjectActionsTypes.PROJECT_SET_FIELD,
          payload: { ...data.project,
            notes: mapNotes(data.project.notes.items),
            files: mapFiles(data.project.files.items)
          }
        });
        yield put(StatusActions.setResourceToIdle("project"));
        break;
      }

    case "NoContext":
    default:
      console.log("exiting here"); // State is already changed from reducer.

      break;
  }
}

function* onAddNewClient() {
  yield put({
    type: ProjectActionsTypes.PROJECT_INITIALIZE,
    payload: {
      kind: "NoContext"
    }
  });
  yield put({
    type: ApplicationActionsTypes.APPLICATION_SET_DRAWER_STATE,
    payload: "project"
  });
}

export default {
  reducers: {
    [ProjectActionsTypes.PROJECT_INITIALIZE]: setInitialState,
    [ProjectActionsTypes.PROJECT_SET_FIELD]: setField,
    [ProjectActionsTypes.PROJECT_ADD_NOTES]: addProjectNotes,
    [ProjectActionsTypes.PROJECT_REMOVE_NOTES]: removeProjectNotes
  },

  *executers() {
    yield all([yield takeLatest(ProjectActionsTypes.PROJECT_ADD_NEW, onAddNewClient), yield takeLatest(ProjectActionsTypes.PROJECT_INITIALIZE, onInitializeProject), yield takeLatest(ProjectActionsTypes.PROJECT_SUBMIT, onSubmitForm)]);
  }

};