import { SetFilesDocument } from "../../../graphql/documents.js";
import { uploadFiles } from "../../../helpers/files.js";
import { getUniqueFiles } from "../../utils/Files.js";
import { getContext, put } from "../../../../../_snowpack/pkg/redux-saga/effects.js";
import { v4 as uuidv4 } from "../../../../../_snowpack/pkg/uuid.js";
import StatusActions from "../Status/actions.js"; // its returning the correct array of files for the backend

export function* uploadDocuments(documents, resource, documentType, resourceId) {
  if (!documents || documents.length === 0) {
    return [];
  }

  const uniqueFiles = getUniqueFiles(documents);
  const docs = uniqueFiles.filter(g => {
    return g.kind === "local";
  });
  const uploadedDocs = yield uploadFiles(docs.map(i => {
    return {
      blob: i.blob,
      name: i.fileName,
      size: i.size,
      type: i.fileType,
      url: i.path
    };
  }), resource, resourceId || uuidv4(), documentType);
  return [...uploadedDocs.map(u => {
    const file = uniqueFiles.find(f => f.size === u.metadata.size); // not perfect but what are the odds that 2 file has the EXACT same byte number

    return {
      fileName: u.name,
      url: u.url,
      fileType: u.metadata.type,
      filePath: u.path,
      fileCategory: file.fileCategory
    };
  }), ...uniqueFiles.filter(g => {
    return g.kind === "remote";
  }).map(g => ({
    fileName: g.fileName,
    url: g.url,
    fileType: g.fileType,
    filePath: g.filePath,
    fileCategory: g.fileCategory
  }))];
}
export function* addFilesHandler(fileInput, resourceId, resourceType) {
  const client = yield getContext("client");
  const query = client.query({
    query: SetFilesDocument,
    fetchPolicy: "no-cache",
    variables: {
      files: {
        resourceId,
        resourceType,
        files: fileInput
      }
    }
  });
  const {
    errors
  } = yield query;

  if (errors?.length > 0) {
    yield put(StatusActions.setResourceToError("file", "file-form-saved-fail"));
    return;
  }

  yield put(StatusActions.setResourceToIdle("file"));
}