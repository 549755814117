import { DatePicker } from "../../../components/DatePicker.js";
import { DescriptionList } from "../../../components/Tailwind/DescriptionList.js";
import { NumberTextField, TextField } from "../../../components/Tailwind/TextField.js";
import useI18n from "../../../hooks/useI18n.js";
import { Selectors, useAction, useSelector } from "../../../hooks/useRedux.js";
import { ListingActionsTypes } from "../../../store/artifacts/Listing/types.js";
import { DateTime } from "../../../../../_snowpack/pkg/luxon.js";
import React from "../../../../../_snowpack/pkg/react.js";
import { jsx as __cssprop } from "../../../../../_snowpack/pkg/@emotion/react.js";

const PriceField = () => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const price = useSelector(Selectors.listing.getPrice);
  return __cssprop(DescriptionList.Item, {
    title: t("unit-lease-info-label-monthly-price"),
    inline: true
  }, __cssprop(NumberTextField, {
    thousandSeparator: true,
    suffix: "$",
    onChange: pPrice => dispatch({
      type: ListingActionsTypes.LISTING_SET_FIELD,
      payload: {
        price: pPrice
      }
    }),
    value: price
  }));
};

const DateField = () => {
  const {
    dispatch
  } = useAction();
  const availability = useSelector(Selectors.listing.getAvailabiltyDate);
  return __cssprop(DescriptionList.Item, {
    title: "Date",
    inline: true
  }, __cssprop("div", {
    css: {
      "marginTop": "1.6rem"
    }
  }, __cssprop(DatePicker, {
    id: "availability",
    defaultValue: DateTime.fromJSDate(availability).isValid ? DateTime.fromJSDate(availability) : availability // if the availability come from the form is already a DateTime but TS dosent know..
    ,
    onChange: date => dispatch({
      type: ListingActionsTypes.LISTING_SET_FIELD,
      payload: {
        availability: date
      }
    })
  })));
};

const VideoField = () => {
  const {
    dispatch
  } = useAction();
  const {
    t
  } = useI18n();
  const videoUrl = useSelector(Selectors.listing.getVideoUrl);
  return __cssprop(DescriptionList.Item, {
    title: t("rental-video-link-title"),
    inline: true
  }, __cssprop(TextField, {
    id: "videoUrl",
    onChange: url => dispatch({
      type: ListingActionsTypes.LISTING_SET_FIELD,
      payload: {
        videoLink: url
      }
    }),
    defaultValue: videoUrl,
    placeholder: t("rental-video-link-place-holder")
  }));
};

export const DetailsView = () => {
  const {
    t
  } = useI18n();
  return __cssprop("div", {
    css: {
      "marginTop": "3.2rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      "paddingBottom": "0.8rem",
      "marginBottom": "0.4rem"
    }
  }, t("listing-information")), __cssprop(DescriptionList, null, __cssprop(PriceField, null), __cssprop(DateField, null), __cssprop(VideoField, null)));
};