export let TicketActionTypes;

(function (TicketActionTypes) {
  TicketActionTypes["TICKET_INITIALIZE"] = "TICKET_INITIALIZE";
  TicketActionTypes["TICKET_ADD_CONVERSATION"] = "TICKET_ADD_CONVERSATION";
  TicketActionTypes["TICKET_CREATE"] = "TICKET_CREATE";
  TicketActionTypes["TICKET_UPDATE"] = "TICKET_UPDATE";
  TicketActionTypes["TICKET_SET_FIELD"] = "TICKET_SET_FIELD";
  TicketActionTypes["TICKET_SET_INITIAL_STATE"] = "TICKET_SET_INITIAL_STATE";
  TicketActionTypes["TICKET_CLOSE"] = "TICKET_CLOSE";
  TicketActionTypes["TICKET_DELETE"] = "TICKET_DELETE";
  TicketActionTypes["TICKET_ADD_NOTES_FORMLESS"] = "TICKET_ADD_NOTES_FORMLESS";
  TicketActionTypes["TICKET_REMOVE_NOTES_FORMLESS"] = "TICKET_REMOVE_NOTES_FORMLESS";
  TicketActionTypes["TICKET_ADD_NOTES"] = "TICKET_ADD_NOTES";
  TicketActionTypes["TICKET_REMOVE_NOTES"] = "TICKET_REMOVE_NOTES";
  TicketActionTypes["TICKET_ADD_FILES_FORMLESS"] = "TICKET_ADD_FILES_FORMLESS";
  TicketActionTypes["TICKET_REMOVE_FILES_FORMLESS"] = "TICKET_REMOVE_FILES_FORMLESS";
  TicketActionTypes["TICKET_ADD_FILES"] = "TICKET_ADD_FILES";
  TicketActionTypes["TICKET_REMOVE_FILES"] = "TICKET_REMOVE_FILES";
  TicketActionTypes["TICKET_CONVERSATION_ADD_FILES_FORMLESS"] = "TICKET_CONVERSATION_ADD_FILES_FORMLESS";
  TicketActionTypes["TICKET_CONVERSATION_REMOVE_FILES_FORMLESS"] = "TICKET_CONVERSATION_REMOVE_FILES_FORMLESS";
  TicketActionTypes["TICKET_CLOSE_TASK_REDUCER"] = "TICKET_CLOSE_TASK_REDUCER";
  TicketActionTypes["TICKET_CONVERSATION_REMOVE_FILES"] = "TICKET_CONVERSATION_REMOVE_FILES";
})(TicketActionTypes || (TicketActionTypes = {}));

export const initialState = {
  id: null,
  title: "",
  createdBy: null,
  modifiedBy: null,
  category: null,
  priority: null,
  origin: null,
  resource: {
    space: null,
    project: null,
    tenant: null,
    lease: null
  },
  tasks: {
    items: []
  },
  createdAt: null,
  modifiedAt: null,
  closedAt: null,
  deletedAt: null,
  files: [],
  notes: [],
  conversations: {
    items: []
  },
  tags: [],
  isRecurring: null,
  __typename: null
};